
import { AppToken } from './auth.service'

export class User {

  constructor(public userName: string,
    public token: AppToken[],
    public _tokenExpireDate: Date,
    public email: string
  ) { }



}

export class App {
  constructor(public app: number, public displayName: string, public role: number, public isAdmin: boolean) { }
}
