import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, Subject, throwError } from 'rxjs';
import { Globals } from '../globals';
import { EntityBase } from './entity.base'


@Injectable({
  providedIn: 'root'
})
export abstract class EntityService<T extends EntityBase> {
  public readonly APIUrl = this.globals.APP_URL + this.getResourceUrl();

  itemList = new Subject<T[]>();
  itemArray = new Array<T>();

  totalItems: number = 0;

  selectedItem: T = null;

  detailActive = '';
  listActive = 'active';

  constructor(protected globals: Globals,
    protected router: Router,
    protected http: HttpClient) { }

  abstract getResourceUrl(): string;
  abstract getEditRoute(id: number): any[];

  getItems(search: string, pageSize: number, page: number) {
    let params = new HttpParams();
    params = params.append('search', search);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('page', page.toString());
    this.http
      .get<T[]>(this.APIUrl, {
        params: params,
        observe: 'response',
      })
      .subscribe((respData) => {
        this.totalItems = parseInt(respData.headers.get('x-total-count'));
        this.itemArray = respData.body.slice();
        this.itemList.next(this.itemArray);
      });
  }

  getItemsRaw(search: string, pageSize: number, page: number) {
    let params = new HttpParams();
    params = params.append('search', search);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('page', page.toString());
    return this.http
      .get<T[]>(this.APIUrl, {
        params: params,
        observe: 'response',
      });
  }

  getItem(id: number, item: T) {
    this.selectedItem = Object.assign(
      item,
      this.itemArray.find((p) => p.id === id)
    );
    return this.selectedItem;
  }

  addItem(p: T) {
    this.postItem(p).subscribe((respData) => {
      this.itemArray.push(respData);
      this.selectedItem = respData;
      this.router.navigate(this.getEditRoute(this.selectedItem.id));
    });
  }

  
  updateItem(p: T) {
    this.putItem(p).subscribe((respData) => {
      this.selectedItem = respData;
      const idx = this.itemArray.findIndex((x) => x.id === p.id);
      this.itemArray[idx] = respData;
      this.router.navigate(this.getEditRoute(this.selectedItem.id));
    });
  }

  putItem(p: T) {
    return this.http.put<T>(this.APIUrl, p).pipe(
      catchError(this.handleError));
  }

  postItem(p: T) {
    return this.http.post<T>(this.APIUrl, p).pipe(
      catchError(this.handleError));
  }

  readItem(id: number) {
    const url = this.APIUrl + '/' + id;
    return this.http.get<T>(url).pipe(
      catchError(this.handleError));
  }

  deleteItem(id: number) {
    const url = this.APIUrl + '/' + id;
    return this.http.delete(url).pipe(
      catchError(this.handleError));
  }

  getNextNumber(table: string) {
    const url = this.globals.APP_URL + '/numberrange/' + table + '/next-number';
    return this.http.get<T>(url).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: any): Observable<never> {
    console.error('An error occurred', error);
    return throwError(error);
  }

}
