import {DatePipe} from '@angular/common';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, NgForm} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Globals, KVP_GROUPS} from 'src/app/globals';
import {CustomerSelectComponent} from 'src/app/masterdata/customer/customer-select/customer-select.component';
import {CustomerService} from 'src/app/masterdata/customer/customer.service';
import {ProductSelectComponent} from 'src/app/masterdata/products/product-select/product-select.component';
import {Contact} from 'src/app/shared/contact.model';
import {Product} from 'src/app/shared/product.model';
import {ContactService} from '../../../shared/contact.service';
import {InvoiceItem} from '../invoice-item.model';
import {Invoice, InvoiceSearchParam} from '../invoice.model';
import {InvoiceService} from '../invoice.service';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {style} from '@angular/animations';
import {KvpService} from '../../../shared/kvp.service';
import {ContactSelectComponent} from "../../../shared/contact-select/contact-select.component";
import {PaymentTermsService} from "../../../settings/paymentterms/payment-terms.service";
import {PaymentTerms} from "../../../shared/payment-terms.model";

pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-invoice-edit',
  templateUrl: './invoice-edit.component.html',
  styleUrls: ['./invoice-edit.component.css'],
  providers: [DialogService, MessageService]
})
export class InvoiceEditComponent implements OnInit {
  invoice: Invoice = new Invoice();
  availablePaymentMethods: string[] = ['auf Rechnung', 'Bankeinzug', 'Kreditkarte', 'bar'];

  paymentMethods: any;

  newItemText: string = '<Neuen Artikel wählen>';
  newItem: InvoiceItem;
  statuses: SelectItem[];
  id: number;
  customer: Contact = new Contact();
  product: InvoiceItem = new InvoiceItem();
  ref: DynamicDialogRef;
  editMode: Boolean = false;

  pdfMake: any;

  doAll: Boolean = false;
  isLoading: Boolean = false;

  terms: PaymentTerms = new PaymentTerms();

  invoiceDate: Date = new Date();
  deliveryDate: Date = new Date();

  @ViewChild('headerData') headerForm: NgForm;
  @ViewChild('InvoiceItemData') invoiceItemForm: NgForm;
  @ViewChild('footerData') footerForm: NgForm;

  constructor(
    public dialogService: DialogService,
    private contactService: ContactService,
    private route: ActivatedRoute,
    private router: Router,
    public globals: Globals,
    private invoiceServcice: InvoiceService,
    private confirmationService: ConfirmationService,
    private kvpService: KvpService,
    private paymentTermService: PaymentTermsService,
    public datepipe: DatePipe,
  ) {
  }

  get Total(): { name: string, amount: number }[] {
    let incl: number = 0;
    let excl: number = 0;
    let vat: number = 0;
    let discount: number = 0;
    this.invoice.invoiceItems.forEach((item) => {
      incl += item.PriceIncl ? item.PriceIncl : 0;
      excl += item.PriceExcl ? item.PriceExcl : 0;
      vat += item.VatAmount ? item.VatAmount : 0;
      discount += item.DiscountAmount ? item.DiscountAmount : 0;
    })

    this.invoice.total = Math.round(incl * 100);
    this.invoice.netSum = Math.round(excl * 100);
    this.invoice.taxSum = Math.round(vat * 100);

    return [{
      name: 'Gesamtsumme exkl.',
      amount: excl
    }/*,{name:'Gesamt-Rabatt', amount:discount}*/, {name: 'Gesamt-MwSt.', amount: vat}, {
      name: 'Gesamtsumme inkl.',
      amount: incl
    }];
  }


  ngOnInit(): void {
    this.invoice.paymentMethode = "bar";
    // const postion:number = this.invoice.invoiceItems.length + 1;

    this.kvpService.readGroup(KVP_GROUPS.PAY_METHOD).subscribe(resp => {
      if (resp.length > 0) {
        this.paymentMethods = resp;
        this.invoice.paymentMethode = resp[0].key;
      }
    })

    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.editMode = params['id'] != null;

      if (!this.editMode) {
        this.onNewInvoice();
      } else {
        this.onShowInvoice();
      }


    });

  }

  onShowInvoice() {
    this.invoiceServcice.readInvoice(this.id).subscribe((i: Invoice) => {
      this.invoice = new Invoice();
      Object.assign(this.invoice, i);
      let j = 0;
      for (j = 0; j < this.invoice.invoiceItems.length; j++) {
        let item = new InvoiceItem();
        Object.assign(item, this.invoice.invoiceItems[j])
        this.invoice.invoiceItems[j] = item;
      }
      this.invoiceDate = new Date(this.invoice.invoiceDate2 * 1000);
      this.deliveryDate = new Date(this.invoice.deliveryDate2 * 1000);

      if (this.invoice.contactId > 0) {
        this.readContact(this.invoice.contactId);
      }

      this.readItems();


    });
  }

  readItems() {
    this.addNewItem();
  }

  readContact(id: number) {
    this.contactService.readContact(id).subscribe((c) => {
      Object.assign(this.customer, c);

    })
  }

  readTerms(id: number) {
    this.paymentTermService.readItem(id).subscribe((t) => {
      Object.assign(this.terms, t);
      console.log(this.terms);
      this.invoice.paymentDays = this.terms.dueDays;
      this.invoice.paymentMethode = this.terms.paymentMethod;
      this.invoice.footerText = this.terms.printText;
      this.invoice.skonto = this.terms.cashDiscount;
      this.invoice.skontoDays = this.terms.cashDiscountDays;
    });
  }

  onNewInvoice() {
    this.invoiceServcice.getNextNumber().subscribe((r: { id: string }) => {
      this.invoiceDate = new Date();
      this.deliveryDate = new Date();
      this.invoice.invoiceID = r.id;
    });

    this.addNewItem();
  }

  addNewItem() {
    this.newItem = new InvoiceItem();
    this.newItem.Text = this.newItemText
    // this.newItem.position = this.invoice.invoiceItems.length + 1;
    this.invoice.invoiceItems.push(this.newItem);
  }

  onKeyPress(event: KeyboardEvent, index: number) {
    const element = <HTMLInputElement>event.target;
    // const control= new FormControl(input);
    console.log(event);
    console.log('onKeyPress: key ="' + event.key + '", ' + index + ' == ' + this.invoice.invoiceItems.length + ' && ' + this.invoice.invoiceItems[index].isNew + ' && ' + element.value);
    if (event.key && index + 1 === this.invoice.invoiceItems.length && this.invoice.invoiceItems[index].isNew) {
      this.invoice.invoiceItems[index].isNew = false;

      if (this.invoice.invoiceItems[index].Position === "") {
        this.invoice.invoiceItems[index].Position = ((index + 1) * 10).toString();
      }
      if (!this.invoice.invoiceItems[index].Quantity || this.invoice.invoiceItems[index].Quantity == 0) {
        this.invoice.invoiceItems[index].Quantity = 1;
      }

      this.addNewItem();
    }
  }

  onFocus(event: FocusEvent) {
    (<HTMLInputElement>event.target).select();
  }

  onSelectCustomer() {
    this.ref = this.dialogService.open(ContactSelectComponent, {
      data: {
        role: 'members,customer'
      },
      header: 'Rechnungsemfänger auswählen',
      width: '60%',
      contentStyle: {'max-height': '650px', overflow: 'auto'},
      baseZIndex: 10000,
    });
    this.ref.onClose.subscribe((c: Contact) => {
      if (c) {
        this.customer = c;
        this.invoice.setCustomer(this.customer);
        if (this.customer.paymentTermsId > 0) {
          this.readTerms(this.customer.paymentTermsId);
        }
      }
    });
  }

  onAddPosition() {

  }

  onAddComment() {

  }


  onDeleteItem(index: number) {
    this.invoice.invoiceItems.splice(index, 1);
    if (index === this.invoice.invoiceItems.length) {
      this.addNewItem();
    }
  }

  onRowEditInit(item: InvoiceItem) {

  }

  onRowEditSave(item: InvoiceItem) {

  }

  onRowEditCancel(item: InvoiceItem) {

  }

  onSave() {
    this.invoice.invoiceDate2 = Math.round(this.invoiceDate.getTime() / 1000);
    this.invoice.deliveryDate2 = Math.round(this.deliveryDate.getTime() / 1000);
    this.invoice.invoiceDate = null;
    this.invoice.deliveryDate = null;
    if (this.editMode) {
      this.invoiceServcice.updateInvoice(this.invoice, false);
    } else {
      console.log(this.invoice);
      this.invoiceServcice.addInvoice(this.invoice, false);
    }
  }

  onPrint() {
    if (this.doAll) {
      this.createAll();
    } else {
      this.invoice.invoiceDate2 = Math.round(this.invoiceDate.getTime() / 1000);
      this.invoice.deliveryDate2 = Math.round(this.deliveryDate.getTime() / 1000);
      if (this.editMode) {
        this.invoiceServcice.printInvoice(this.invoice);
      }
    }
  }

  createAll() {
    this.confirmationService.confirm({
      message: 'Möchten Sie wirklich ' + this.invoiceServcice.totalInvoices + ' Rechnungen erzeugen?',
      header: 'Automatisch Rechnungen erzeugen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      key: 'confirm',
      rejectVisible: true,
      icon: 'pi pi-question-circle',
      accept: () => {
        this.isLoading = true;
        this.invoiceServcice.createAll().subscribe(r => {
          this.isLoading = false
          if (r && r.value.length > 0) {
            this.confirmationService.confirm({
              message: r.value,
              header: 'Fehler bei der Rechnungserzeugung!',
              acceptLabel: 'OK',
              key: 'info',
              rejectVisible: false,
              accept: () => {
                console.log('log');
              }
            });
          }
        }, error => {
          this.isLoading = false;
        })
      },
    });
  }

  onSelectProduct(index: number) {
    this.ref = this.dialogService.open(ProductSelectComponent, {
      data: {
        product: this.product,
      },
      header: 'Artikel auswählen',
      width: '60%',
      contentStyle: {'max-height': '650px', overflow: 'auto'},
      baseZIndex: 10000,
    });
    this.ref.onClose.subscribe((data: Product) => {
      if (data) {

        this.invoice.invoiceItems[index].Product = data;
        if (this.invoice.invoiceItems[index].Position === "") {
          this.invoice.invoiceItems[index].Position = ((index + 1) * 10).toString();
        }
        if (!this.invoice.invoiceItems[index].Quantity || this.invoice.invoiceItems[index].Quantity == 0) {
          this.invoice.invoiceItems[index].Quantity = 1;
        }

        console.log(this.invoice.invoiceItems[index]);
        console.log('index: ' + index + ', length: ' + this.invoice.invoiceItems.length);
        if (index === this.invoice.invoiceItems.length - 1) {
          this.addNewItem();
        }
      }
    });
  }

  Back() {
    this.router.navigate(['jobs', 'invoices']);

  }

  Delete() {

  }

  Save() {
    this.onSave();
  }

}
