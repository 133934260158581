
<div class="surface-50 px-4 py-8 md:px-6 lg:px-8">
    <div class="font-bold text-5xl text-900 mb-3">Aktuelle Videos </div>
    <div class="text-700 line-height-3 text-xl mb-6">
        Hier finden Sie aktuelle Erklärvideos rund um Headwater. Alle Videos zu Headwater finden Sie auf unserem <a href="https://www.youtube.com/channel/UCUop_5Xy-RquiNfsCLih_8w" target="_blank" rel="noopener noreferrer">Youtube Kanal</a>.
    </div>

    <div class="flex flex-column lg:flex-row gap-5">
        <div class="shadow-2 border-round-xl" style="background: linear-gradient(15deg, #F91616, #F97316); border-left: 10px solid transparent;">
            <div class="p-4 border-round surface-card h-full">
                <div class="flex mb-3">
                    <p-avatar image="assets/img/rtws_avatar.jpg" shape="circle"></p-avatar>
                    <div class="ml-2">
                        <div class="text-xs font-bold text-900 mb-1">Reisinger Technology GmbH</div>
                        <div class="text-xs flex align-items-center text-700">
                            <span>9 Juli, 2022</span>
                            <div class="border-circle surface-300 mx-2" style="width: 0.25rem;height: 0.25rem;"></div>
                            <span>2:28 min</span>
                        </div>
                    </div>
                </div>
                <div class="text-xl text-900 font-medium mb-3 line-height-3">Kurze Einführung in Headwater</div>
                <div>
                    <iframe src="https://www.youtube.com/embed/eJiCKQi3naw"></iframe>
                </div>
            </div>
        </div>

        <div class="shadow-2 border-round-xl" style="background: linear-gradient(15deg, #CC63F1, #6366F1); border-left: 10px solid transparent;">
            <div class="p-4 border-round surface-card h-full">
                <div class="flex mb-3">
                    <p-avatar image="assets/img/rtws_avatar.jpg" shape="circle"></p-avatar>
                    <div class="ml-2">
                        <div class="text-xs font-bold text-900 mb-1">Reisinger Technology GmbH</div>
                        <div class="text-xs flex align-items-center text-700">
                            <span>10 Juli, 2022</span>
                            <div class="border-circle surface-300 mx-2" style="width: 0.25rem;height: 0.25rem;"></div>
                            <span>1:46 min</span>
                        </div>
                    </div>
                </div>
                <div class="text-xl text-900 font-medium mb-3 line-height-3">Mitglieder importieren</div>
                <div>
                    <iframe src="https://www.youtube.com/embed/GehG0Ic9Dho"></iframe>
                </div>
            </div>
        </div>

        <div class="shadow-2 border-round-xl" style="background: linear-gradient(15deg, #06D465, #06B6D4); border-left: 10px solid transparent;">
            <div class="p-4 border-round surface-card h-full">
                <div class="flex mb-3">
                    <p-avatar image="assets/img/rtws_avatar.jpg" shape="circle"></p-avatar>
                    <div class="ml-2">
                        <div class="text-xs font-bold text-900 mb-1">Reisinger Technology GmbH</div>
                        <div class="text-xs flex align-items-center text-700">
                            <span>11 Juli, 2022</span>
                            <div class="border-circle surface-300 mx-2" style="width: 0.25rem;height: 0.25rem;"></div>
                            <span>1:30 min</span>
                        </div>
                    </div>
                </div>
                <div class="text-xl text-900 font-medium mb-3 line-height-3">Wasserzähler importieren</div>
                <div >
                    <iframe src="https://www.youtube.com/embed/4TahIY3ydio"></iframe>
                </div>
            </div>
        </div>
    </div>
    <div class="flex flex-column lg:flex-row gap-5 my-5">
        <div class="shadow-2 border-round-xl" style="background: linear-gradient(15deg, #06D465, #06B6D4); border-left: 10px solid transparent;">
            <div class="p-4 border-round surface-card h-full">
                <div class="flex mb-3">
                    <p-avatar image="assets/img/rtws_avatar.jpg" shape="circle"></p-avatar>
                    <div class="ml-2">
                        <div class="text-xs font-bold text-900 mb-1">Reisinger Technology GmbH</div>
                        <div class="text-xs flex align-items-center text-700">
                            <span>11 Juli, 2022</span>
                            <div class="border-circle surface-300 mx-2" style="width: 0.25rem;height: 0.25rem;"></div>
                            <span>2:01 min</span>
                        </div>
                    </div>
                </div>
                <div class="text-xl text-900 font-medium mb-3 line-height-3">Wasseranschlüsse importieren</div>
                <div>
                    <iframe src="https://www.youtube.com/embed/eeNa_6ZIqQA"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
