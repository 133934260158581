


import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { Globals } from "../globals";
import { EntityService } from "../shared/entity.service";
import { NeedsUnit } from "../shared/needs-unit.model";


@Injectable({ providedIn: 'root' })
export class NeedsUnitService extends EntityService<NeedsUnit> {

  constructor(protected globals: Globals,
    protected router: Router,
    protected http: HttpClient) {
    super(globals, router, http);

  }

  getResourceUrl(): string {
    return 'needunit';
  }

  getEditRoute(id: number): any[] {
    return ['wap-module', 'wap', id]
  }

  getAll(wapId: number) {
    let url = this.APIUrl;
    let params = new HttpParams();
    params = params.append('wapid', wapId);
    return this.http.get<NeedsUnit[]>(url, { params: params });
  }
}
