<p-toast position="bottom-right" key="tl"></p-toast>
<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-dialog [(visible)]="display" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
          [style]="{width: '50vw'}">
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
            <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle"
                  style="width:32px;height:32px">
                <i class="pi pi-info text-lg"></i>
            </span>
      <span class="font-medium text-2xl text-900">{{ dialogTitle }}</span>
    </div>
  </ng-template>

  <div class="grid formgrid p-fluid">

    <div class="field mb-3 col-6">
      <label class="font-medium text-900">Transaktionstyp</label>
      <p-dropdown [options]="globals.transactionTypes" optionLabel="name" optionValue="value" appendTo="body"
                  [(ngModel)]="template.transactionType"></p-dropdown>
    </div>


    <div class="field mb-3 col-6">
      <label for="transactionOption" class="font-medium text-900">Art</label>

      <div>
        <p-selectButton id="transactionOption" class="full-width-select-button"
                        [options]="transactionOption"
                        optionLabel="label" optionValue="value" [(ngModel)]="option">

        </p-selectButton>
      </div>

    </div>


    <div class="field mb-3 col-6">
      <label class="font-medium text-900">Konto</label>
      <p-dropdown [options]="accounts" optionLabel="name" optionValue="id" appendTo="body"
                  [(ngModel)]="template.accountId"></p-dropdown>
    </div>
    <div class="field mb-3 col-6">
      <label class="font-medium text-900">Kostenstelle</label>
      <p-dropdown id="costCenters" [options]="costCenters" [(ngModel)]="template.costCenter" optionLabel="key"
                  optionValue="key" appendTo="body"></p-dropdown>
    </div>

    <div class="field mb-3 col-12">
      <label class="font-medium text-900">Text</label>
      <input id="name" type="text" pInputText autocomplete="off" [(ngModel)]="template.text">
    </div>


    <div class="field mb-3 col-12">
      <label class="font-medium text-900">Kontakt/Adresse</label>

      <p-inputGroup>
        <button type="button" pButton icon="pi pi-times" class="p-button-danger" (click)="onRemoveContact()"></button>
        <input type="text" name="owner" placeholder="" readonly pInputText [(ngModel)]="contactString"/>
        <button type="button" pButton icon="pi pi-search" class="p-button-success" (click)="onSelectContact()"></button>
      </p-inputGroup>

    </div>

    <div class="field mb-3 col-6">
      <label class="font-medium text-900">Steuersatz 1</label>
      <p-inputNumber id="tax1" [ngClass]="{'neg-value': !template.isIncoming, 'pos-value': template.isIncoming}"
                     [minFractionDigits]="2" suffix=" %" [(ngModel)]="tax1"></p-inputNumber>
    </div>

    <div class="field mb-3 col-6">
      <label class="font-medium text-900">Steuersatz 2</label>
      <p-inputNumber id="tax2" [ngClass]="{'neg-value': !template.isIncoming, 'pos-value': template.isIncoming}"
                     [minFractionDigits]="2" suffix=" %" [(ngModel)]="tax2"></p-inputNumber>
    </div>

    <div class="col-12">
      <hr/>
    </div>
    <div class="field col-12">
      <label class="font-medium text-900">Schlagwörter (mit ';' getrennt)</label>
      <input id="tags" type="text" pInputText autocomplete="off" [(ngModel)]="template.tags">
    </div>

  </div>

  <ng-template pTemplate="footer">
    <div class=" surface-border">
      <button pButton pRipple icon="pi pi-times" (click)="display = false" label="Abbrechen" class="mr-2"></button>
      <button pButton pRipple icon="pi pi-check" (click)="onSave()" label="Speichern"></button>
    </div>
  </ng-template>
</p-dialog>

<div class="surface-card  pt-4 pl-2 pr-2 shadow-2 border-round mb-2">
  <div class="surface-section p-4">
    <div class="grid">
      <div class="col-12 lg:col-2">
        <div class="mr-l">
          <div class="text-xl font-medium text-900 mb-3">Buchungsvorlagen</div>
          <p class="m-0 p-0 text-600 line-height-3 mr-3">
            Hier können wiederkehrende Buchungen als Vorlage gespeichert werden. So können Sie diese bei Bedarf schnell
            und einfach wieder verwenden.
          </p>

        </div>
      </div>

      <div class="col-12 lg:col-10">
        <div class="flex justify-content-between align-items-center mb-3">
          <div class="ml-0">
            <input pInputText type="text" name="searchTramsactopmTemplate" placeholder="Suchtext"
                   aria-label="text" aria-describedby="basic-addon2" [(ngModel)]="searchString"
                   (keydown.enter)="readTemplates()"/>

          </div>

          <div class="ml-4">
            <button pButton pRipple label="Suchen" icon="pi pi-search" (click)="onSearch()"></button>
          </div>


          <div class="ml-auto">
            <button pButton pRipple label="Hinzufügen" icon="pi pi-plus" (click)="onNew()"></button>
          </div>
        </div>

        <p-table [value]="entities" selectionMode="single" [(selection)]="template" styleClass="p-datatable-striped"
                 (onRowSelect)="onRowSelect($event)">
          <ng-template pTemplate="header">
            <tr>
              <th>Typ</th>
              <th>Text</th>
              <th>Kostenstelle</th>
              <th>Art</th>
              <th>MwSt.1</th>
              <th>MwSt.2</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-entity>
            <tr [pSelectableRow]="entity">
              <td>{{ globals.transactionTypes[entity.transactionType].name }}</td>
              <td>{{ entity.text }}</td>
              <td>{{ entity.costCenter }}</td>
              <td>{{ entity.isIncoming && entity.isOutgoing ? "Ein-/Ausgang" : entity.isIncoming ? "Eingang" : "Ausgang" }}</td>
              <td>{{ entity.taxRate1 > 0 ? entity.taxRate1 / 100 + '%' : '--' }}</td>
              <td>{{ entity.taxRate2 > 0 ? entity.taxRate2 / 100 + '%' : '--' }}</td>
              <td style="text-align:right;">
                <p-button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                          (click)="onEdit(entity)"></p-button>
                <p-button icon="pi pi-trash" class="p-button-rounded p-button-success"
                          (click)="onDelete(entity)"></p-button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>


</div>

