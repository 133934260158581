export class Counterlist {
  wapId: number;
  accessPointId: string;
  address: string;
  city: string;
  owner: string;
  gaugeId: number;
  gaugeSerial: string;
  lastCount: number;
  readingDate: string;
  currentCount: number;
  readingDate2: number = 0;
  status: number = 0;

  public constructor() {
    this.wapId = 0;
    this.accessPointId = "";
    this.address = "";
    this.city = "";
    this.owner = "";
    this.gaugeId = 0;
    this.gaugeSerial = '';
    this.lastCount = 0;
    this.readingDate = "";
    this.currentCount = 0;
  }
}
