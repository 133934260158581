import {Component, OnInit} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {CreateUsageData} from '../create-usage.model';
import {WapService} from '../wap.service';

@Component({
  selector: 'app-wap-create-usage',
  templateUrl: './wap-create-usage.component.html',
  styleUrls: ['./wap-create-usage.component.css'],
  providers: [DialogService, MessageService],
})
export class WapCreateUsageComponent implements OnInit {


  isFlatRate: boolean = true;
  isPartPayment: boolean = false;
  isFixedAmount: boolean = false;
  amount: number = 50;

  rangeDates: Date[] = new Array();


  constructor(private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private wapService: WapService) {

  }

  ngOnInit(): void {
    var someDate = new Date(); // add arguments as needed
    someDate.setTime(someDate.getTime() - 30 * 24 * 60 * 60 * 1000);
    this.rangeDates.push(someDate);
    this.rangeDates.push(new Date());
    this.rangeDates[0].setHours(0, 0, 0, 0); // next midnight
    this.rangeDates[1].setHours(23, 59, 59, 999); // next midnight
  }

  onCreate() {
    this.confirmationService.confirm({
      message: 'Möchten Sie den Verbrauch wirklich erzeugen?',
      header: 'Verbrauch erzeugen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      rejectVisible: true,
      key: 'confirm',
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        console.log("create usage");
        var usageData = new CreateUsageData();
        usageData.isFlatRate = this.isFlatRate;
        usageData.isPartPayment = this.isPartPayment;
        usageData.amount = this.amount;
        usageData.isFixedAmount = this.isFixedAmount;
        usageData.from2 = Math.round(this.rangeDates[0].getTime() / 1000);
        usageData.to2 = Math.round(this.rangeDates[1].getTime() / 1000);
        this.wapService.createUsage(usageData).subscribe(resp => {
          this.addInfoMessage("Verbrauch für " + resp.toString() + " Anschlüsse erzeugt.");
          console.log(usageData);
        });

      },
    });
  }

  addErrorMessage(message: string) {
    this.messageService.add({key: 'tl', severity: 'error', summary: 'Fehler', detail: message});
  }

  addInfoMessage(message: string) {
    this.messageService.add({key: 'tl', severity: 'info', summary: 'Information', detail: message});
  }

}
