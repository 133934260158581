<div class="surface-card  pt-4 pl-2 pr-2  shadow-2 border-round mb-2">

  <div class="surface-section px-4">
    <div class="grid">
      <div class="col-12 lg:col-2">
        <div class="mr-l">
          <div class="text-xl font-medium text-900 mb-3">SEPA Lastschrift</div>
          <p class="m-0 p-0 text-600 line-height-3 mr-3">
            Hier kann eine Liste für das SEPA Lastschriftverfahren erstellt werden.
            <br><br>
            Nachdem eine Rechnungsliste erzeugt wurde, kann diese als CSV exportiert werden und als SEPA Vorlage
            verwendet werden.
            <br><br>
            Es werden nur Rechnungen berücksichtigt, die gebucht/erzeugt und noch nicht bezahlt sind.
          </p>

        </div>
      </div>

      <div class="col-12 lg:col-10">

        <div class="grid">
          <div clasS="col-12">
            <div class="input-group mb-3 ml-0">
              <input type="text" name="search" class="form-control input-lg" placeholder="Suchtext"
                     autocomplete="off" [(ngModel)]="searchString"
                     aria-label="Recipient's username" aria-describedby="basic-addon2" (keyup.enter)="onSearch()"
              />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="onSearch()">
                  Suchen
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-3 ml-2">
            <div class="form-group">
              <label>Datum von</label>
              <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false"
                          [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="from" (keyup.enter)="onSearch()">
              </p-calendar>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>bis</label>
              <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false"
                          [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="to" (keyup.enter)="onSearch()">
              </p-calendar>
            </div>
          </div>
        </div>

        <hr/>
        <div class="flex justify-content-between align-items-center mb-3">
          <div class="text-xl font-medium text-900 mb-3">
            {{ this.title }}
          </div>

          <div class="ml-auto">
            <button *ngIf="selectedInvoices.length > 0" pButton pRipple label="SEPA Lastschrift Liste erzeugen"
                    icon="pi pi-check-square"
                    [disabled]="selectedInvoices.length <= 0"
                    (click)="downloadInvoices()"></button>
          </div>
        </div>


        <div class="mb-2">
          <p-table [value]="invoices" styleClass="p-datatable-striped" [(selection)]="selectedInvoices"
                   (selectionChange)="onSelectAllChange($event)"
                   dataKey="id"

          >
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 4rem">
                  <p-tableHeaderCheckbox/>
                </th>
                <th>Re-Nr.</th>
                <th>Re-Dat.</th>
                <th>Kunde</th>
                <th>IBAN</th>
                <th>Betrag</th>
                <th>Mandatsreferenz</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-invoice>
              <tr [pSelectableRow]="invoice">
                <td>
                  <p-tableCheckbox [value]="invoice"/>
                </td>
                <td>{{ invoice.invoiceID }}</td>
                <td>{{ invoice.invoiceDate * 1000 | date:'dd.MM.YYYY' }}</td>
                <td> {{ invoice.recipient }}</td>
                <td> {{ invoice.iban }}</td>
                <td> {{ invoice.total / 100 | currency:'EUR' }}</td>
                <td> {{ invoice.directDebitMandate }}</td>
              </tr>
            </ng-template>

          </p-table>
        </div>
      </div>

    </div>
  </div>

</div>
