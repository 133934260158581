<div class="row">
  <div class="col-md-12">
    <div class="card">
      <br />

      <form (ngSubmit)="onSubmit(f)" #f="ngForm">
        <div class="px-4">
          <div class="input-group mb-3">
            <input
              type="text"
              name="searchProduct"
              class="form-control input-lg"
              placeholder="Suchtext"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              ngModel
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                [disabled]="!f.valid"
                type="submit"
              >
                Suchen
              </button>
            </div>
          </div>
        </div>
      </form>

      <p-table [value]="products" selectionMode="single" [(selection)]="selectedProduct" dataKey="id" responsiveLayout="scroll"
               (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th>Artikelnummer</th>
            <th>Bezeichnung</th>
            <th>Produktgruppe</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr [pSelectableRow]="product">
            <td>{{ product.productID }}</td>
            <td>{{ product.description }}</td>
            <td>{{ product.productGroup }}</td>
          </tr>
        </ng-template>
      </p-table>
      <p-paginator class="my-paginator" [rows]="10" [totalRecords]="totalProducts" pageLinkSize="3"
                   (onPageChange)="paginate($event)"></p-paginator>

  </div>
</div>
