import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../../globals';
import { TasksService } from '../../services/tasks/tasks.service';
import { Contact } from '../../shared/contact.model';
import { KvpService } from '../../shared/kvp.service';
import { Task } from '../../shared/task.model';

@Component({
  selector: 'app-mytasks',
  templateUrl: './mytasks.component.html',
  styleUrls: ['./mytasks.component.css']
})
export class MytasksComponent implements OnInit, OnChanges {

  @Input('member') member: Contact;

  items: Task[];
  selectedItem: Task;

  constructor(
    private taskService: TasksService,
    private router: Router
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    for (let propName in changes) {
      if (propName === 'member') {
        this.update();
      }
    }
  }
  ngOnInit(): void {
    
  }

  update() {
    if (this.member) {
      this.taskService.getMyTasks(this.member.id).subscribe(t => {
        this.items = t;
      });
    }
  }

  onSelected2() {
    this.taskService.selectedItem = this.selectedItem;
    this.router.navigate(['services', 'tasks', this.selectedItem.id]);
  }

  onAddItem() {
    this.router.navigate(['services', 'tasks', 'new']);
  }

}
