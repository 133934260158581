<div class="mb-3 flex align-items-center justify-content-between">
    <span class="text-xl font-medium text-900">Kunden</span>
    <button pButton pRipple label="Hinzufügen" icon="pi pi-plus" (click)="New()"></button>
</div>

<form (ngSubmit)="onSubmit(f)" #f="ngForm">
    <div class="px-0">
        <div class="input-group mb-3">
            <input type="text" name="searchCustomer" class="form-control input-lg" placeholder="Suchtext"
                   aria-label="Recipient's username" aria-describedby="basic-addon2" ngModel />
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" [disabled]="!f.valid" type="submit">
                    Suchen
                </button>
            </div>
        </div>
    </div>
</form>

<!--
  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Nummer</th>
          <th scope="col">Name</th>
          <th scope="col">Addresse</th>
        </tr>
      </thead>
      <tbody class="table-users-body">
        <tr *ngFor="let customer of customers" style="cursor: pointer" (click)="onSelected(customer)">
          <td class="table-users-id">{{ customer.contactID }}</td>
          <td class="table-users-name">{{
            customer.orgName
              ? customer.orgName
              : customer.firstName + " " + customer.lastName
          }}</td>
          <td class="table-users-name">{{ customer.address1 }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
    -->

<p-table [value]="customers" selectionMode="single" [(selection)]="selectedCustomer" dataKey="id" responsiveLayout="scroll"
         (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped">
    <ng-template pTemplate="header">
        <tr>
            <th>Nummer</th>
            <th>Name</th>
            <th>Adresse</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-customer>
        <tr [pSelectableRow]="customer">
            <td class="table-users-id">{{ customer.contactID }}</td>
            <td class="table-users-name">
                {{
                customer.orgName
                ? customer.orgName
                : customer.firstName + " " + customer.lastName
                }}
            </td>
            <td class="table-users-name">{{ customer.address1 }}</td>
        </tr>
    </ng-template>
</p-table>


<p-paginator class="my-paginator" [rows]="10" [totalRecords]="totalCustomers" pageLinkSize="3"
             (onPageChange)="paginate($event)"></p-paginator>

