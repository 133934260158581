<p-toast position="bottom-right" key="tl"></p-toast>

<div class="surface-card p-4 shadow-2 border-round">
  <div class="mb-3 flex align-items-center justify-content-between">
    <span class="text-xl font-medium text-900">Meine Organisation</span>
    <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="onSubmit()"></button>
  </div>

  <div>
    <ul class="surface-card p-0 mx-2 list-none flex overflow-x-auto select-none my-3">
      <li class="px-0">
        <a pRipple
           class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150"
           [ngClass]="{'tab-selected': active1 === 0, 'text-700 border-transparent no-ul': active1 !== 0}"
           (click)="active1 = 0">
          <i class="pi pi-book mr-2"></i>
          <span class="font-medium">Allgemeine Daten</span>
        </a>
      </li>
      <li class="flex align-items-center">
        <div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
      </li>
      <li class="px-3">
        <a pRipple
           class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150"
           [ngClass]="{'tab-selected': active1 === 1, 'text-700 border-transparent no-ul': active1 !== 1}"
           (click)="active1 = 1">
          <i class="pi pi-euro mr-2"></i>
          <span class="font-medium">Zahlungsinformationen</span>
        </a>
      </li>
      <li class="flex align-items-center">
        <div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
      </li>
      <li class="px-3">
        <a pRipple
           class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150"
           [ngClass]="{'tab-selected': active1 === 2, 'text-700 border-transparent no-ul': active1 !== 2}"
           (click)="active1 = 2">
          <i class="pi pi-server mr-2"></i>
          <span class="font-medium">E-Mail Versand</span>
        </a>
      </li>

    </ul>
  </div>


  <div class="border-2surface-border">
    <form #organizationForm="ngForm" autocomplete="off">

      <div class="mx-2" *ngIf="active1 == 0">

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" class="form-control" id="name" name="name" placeholder="Name"
                     [(ngModel)]="settings.name" required>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Adresse</label>
              <input type="text" name="address1" placeholder="Zeile 1" class="form-control"
                     [(ngModel)]="settings.address1"/>
            </div>
            <div class="form-group">
              <input type="text" name="address2" placeholder="Zeile 2" class="form-control"
                     [(ngModel)]="settings.address2"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>PLZ</label>
              <input type="text" name="zip" placeholder="PLZ" class="form-control" [(ngModel)]="settings.zip"/>
            </div>
          </div>
          <div class="col-md-8">
            <div class="form-group">
              <label>Ort</label>
              <input type="text" name="city" placeholder="Ort" class="form-control" [(ngModel)]="settings.city"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Telefon</label>
              <input type="text" name="phone" placeholder="Telefon" class="form-control"
                     [(ngModel)]="settings.phone"/>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Fax</label>
              <input type="text" name="fax" placeholder="Fax" class="form-control" [(ngModel)]="settings.fax"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Email</label>
              <input type="text" name="mail" placeholder="mail@beispiel.at" class="form-control"
                     [(ngModel)]="settings.mail" email/>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Web</label>
              <input type="text" name="web" placeholder="www.meine-wg.at" class="form-control"
                     [(ngModel)]="settings.web"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Absenderadresse</label>
              <input type="text" name="addressHeader" placeholder="Absender: Meine Adresse" class="form-control"
                     [(ngModel)]="settings.addressHeader"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Absenderadresse 2</label>
              <input type="text" name="addressHeader2" placeholder="E Österreichische Post AG Eco Brief"
                     class="form-control"
                     [(ngModel)]="settings.addressHeader2"/>
            </div>
          </div>
        </div>

        <input #FileSelectInputDialog type="file" hidden ([ngModel])="imageName" (change)="onFileSelected($event)"
               accept="image/*"/>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>
                Logo
                <a class="add-user-button mx-2" *ngIf="imageSrc!=null" (click)="onRemoveImage()">
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"/>
                  </svg>
                </a>
              </label>

              <img [src]="imageSrc" class="equipment-img" *ngIf="imageSrc!=null"/>
              <a class="btn btn--primary mr-2" (click)="onSelectImage()" *ngIf="imageSrc==null"
                 style="min-width: 160px;">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M19,19H5V5H19M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M13.96,12.29L11.21,15.83L9.25,13.47L6.5,17H17.5L13.96,12.29Z"/>
                </svg>
                <span>Auswählen ...</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="active1 == 1" class="mx-2">


        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Bankname</label>
              <input type="text" name="bankname" placeholder="Bankname" class="form-control"
                     [(ngModel)]="settings.bankname"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>IBAN</label>
              <input type="text" name="iban" placeholder="AT__" class="form-control" [(ngModel)]="settings.iban"/>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>BIC</label>
              <input type="text" name="bic" placeholder="BIC" class="form-control" [(ngModel)]="settings.bic"/>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>UID-Nummer</label>
              <input type="text" name="uid" placeholder="ATU" class="form-control" [(ngModel)]="settings.uid"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Creditor-ID (Lastschrift)</label>
              <input type="text" name="creditorId" class="form-control" [(ngModel)]="settings.creditorId"/>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Steuernummer</label>
              <input type="text" name="taxNumber" class="form-control" [(ngModel)]="settings.taxNumber"/>
            </div>
          </div>
        </div>

      </div>

      <div class="mx-2" *ngIf="active1 == 2">

        <div clasS="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>E-Mail Adresse/Anmeldename</label>
              <input type="text" name="mail" placeholder="mail@beispiel.at" class="form-control"
                     [(ngModel)]="mailServer.email" email autocomplete="off"/>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Name mit der E-Mail verschickt werden soll</label>
              <input type="text" name="name" class="form-control" autocomplete="off"
                     [(ngModel)]="mailServer.name"/>
            </div>
          </div>

        </div>

        <div clasS="row">


          <div class="col-md-3">
            <div class="form-group">
              <label>Serveradresse</label>
              <input type="text" name="server" class="form-control"
                     [(ngModel)]="mailServer.server" autocomplete="off"/>
            </div>
          </div>


          <div class="col-md-3">
            <div class="form-group">
              <label>Port (TLS)</label>
              <input type="text" name="port_tls" placeholder="587" class="form-control"
                     [(ngModel)]="mailServer.port_tls"/>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>Port (SSL)</label>
              <input type="text" name="port_ssl" placeholder="465" class="form-control"
                     [(ngModel)]="mailServer.port_ssl"/>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>Max. erlaubte E-Mails/Tag</label>
              <input type="number" name="maxSend" class="form-control"
                     [(ngModel)]="mailServer.maxMailsPerDay"/>
            </div>
          </div>


        </div>


        <hr/>
        <div class="row">


          <div class="col-md-6">
            <div class="form-group">
              <label>Kennwort</label>
              <input name="pwd1" type="password" class="form-control" placeholder="Neues Kennwort"
                     autocomplete="new-password"
                     [(ngModel)]="password1" (ngModelChange)="onPasswordChange()"/>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Kennwort bestätigen</label>
              <input name="pwd2" type="password" class="form-control" placeholder="Neues Kennwort"
                     autocomplete="new-password"
                     [(ngModel)]="password2" (ngModelChange)="onPasswordChange()"/>
            </div>
          </div>
        </div>

        <div class="row justify-content-center" *ngIf="badPwd">
          <div class="col-md-12">
            <div class="text-center">
              <div class="alert alert-danger alert-dismissible fade show">
                <strong>Fehler!</strong> Kennwort ist nicht identischt.
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center" *ngIf="pwdRequired">
          <div class="col-md-12">
            <div class="text-center">
              <div class="alert alert-warning alert-dismissible fade show">
                <strong>Achtung!</strong> Kennwort muss eingegeben werden.
              </div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <button pButton pRipple label="Zugang testen" icon="pi pi-verified" (click)="onTestEmail()"></button>
        </div>

      </div>


    </form>
  </div>
</div>
