import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';

@Component({
  selector: 'app-listedit-start',
  templateUrl: './listedit-start.component.html',
  styleUrls: ['./listedit-start.component.css']
})
export class ListeditStartComponent implements OnInit {

  @Input('text') text: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.data.subscribe((data: Data) => {
      this.text = data['displayText'] ? data['displayText'] : 'Bitte wähle eine Element aus der Liste!'
    });
  }

}
