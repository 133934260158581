import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MaintenancePlanService } from '../maintenance-plan.service';

@Component({
  selector: 'app-maintenance-plan',
  templateUrl: './maintenance-plan.component.html',
  styleUrls: ['./maintenance-plan.component.css']
})
export class MaintenancePlanComponent implements OnInit {

  constructor(private router: Router, public entityService: MaintenancePlanService) { }

  ngOnInit(): void {
    this.entityService.detailActive = '';
    this.entityService.listActive = 'active';
  }

  onListSelected() {
    this.router.navigate(['services', 'maintenance', 'plan']);
    this.entityService.detailActive = '';
    this.entityService.listActive = 'active';
  }

  onDetailSelected() {

  }

  onAddEquipment() {
    this.router.navigate(['services', 'maintenance', 'plan', 'new']);
    this.entityService.detailActive = 'active';
    this.entityService.listActive = '';
  }
}
