import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'app-under-construction',
  templateUrl: './under-construction.component.html',
  styleUrl: './under-construction.component.css'
})
export class UnderConstructionComponent {

  constructor(private router: Router, private _location: Location) { }

  goToHome() {
    this.router.navigate(['home']);
  }

  goBack() {
    this._location.back();
  }

}
