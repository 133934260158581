import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Globals } from '../globals';


@Injectable({ providedIn: 'root' })
export class ObjectService {

    constructor(private http: HttpClient, private globals: Globals,) { }

    save<T>(name: string, o: T, encrypt: boolean = false) {
      let params = new HttpParams();
      params = params.append('encrypt', encrypt.toString());
      const url = this.globals.APP_URL + 'object/' + name;
      return this.http.post<T>(url, o, {params: params});
    }

    load<T>(name: string, encrypt: boolean = false) {
      let params = new HttpParams();
      params = params.append('encrypt', encrypt.toString());
      const url = this.globals.APP_URL + 'object/' + name;
      return this.http.get<T>(url, {params: params});
    }
}
