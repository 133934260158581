import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDataService } from '../../shared/dataservice.interface';
import { CustomerService } from './customer.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {

  dataService: IDataService;

  constructor(private router: Router, public customerService: CustomerService) { }

  ngOnInit(): void {
    this.customerService.detailActive = '';
    this.customerService.listActive = 'active';
  }

  onAddCustomer() {
    this.router.navigate(['masterdata', 'customers', 'new']);
    this.customerService.detailActive = 'active';
    this.customerService.listActive = '';
  }

  onListSelected() {
    this.router.navigate(['masterdata', 'customers']);
    this.customerService.detailActive = '';
    this.customerService.listActive = 'active';
  }

  onActivate(componentRef: Component) {
    this.dataService = componentRef as IDataService;
  }

  onDetailSelected() {
    if (this.customerService.selectedCustomer && this.customerService.selectedCustomer.id) {
      this.router.navigate([
        'masterdata',
        'customers',
        this.customerService.selectedCustomer.id,
        'edit',
      ]);
      this.customerService.detailActive = 'active';
      this.customerService.listActive = '';
    } else {
      this.onAddCustomer();
    }
  }

}
