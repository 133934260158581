import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Globals } from '../../../globals';
import { Invoice, InvoiceSearchParam } from '../invoice.model';
import { InvoiceService } from '../invoice.service';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceListComponent implements OnInit, OnDestroy {
  subInvoice: Subscription;
  invoices: Invoice[];
  isLoading = false;
  totalInvoices: number = 1;
  
  selectedInvoice: Invoice;

  

  constructor(public invoiceService: InvoiceService, private router: Router, public globals: Globals) { }

  ngOnInit() {
    if (this.invoiceService.searchParam == null || this.invoiceService.searchParam.isDebit) {
      this.invoiceService.searchParam = new InvoiceSearchParam();
      this.invoiceService.searchParam.isDebit = false;
      this.invoiceService.invoiceArray = new Array();
      this.invoiceService.totalInvoices = 0;
    }

    this.totalInvoices = this.invoiceService.totalInvoices;
    this.invoices = this.invoiceService.invoiceArray;

    if (!this.invoices?.length) this.isLoading = true;

    this.subInvoice = this.invoiceService.invoices.subscribe((m) => {
      this.invoices = m;
      this.totalInvoices = this.invoiceService.totalInvoices;
      this.isLoading = false;

          });

     this.invoiceService.getInvoices(15, 1);

  }

  ngOnDestroy() {
    this.subInvoice.unsubscribe();
  }

  onSelected(i: Invoice) {
    this.selectedInvoice = i;
    if (this.selectedInvoice.isBooked) {
      this.router.navigate(['jobs', 'invoices', i.id, 'view']);
    }
    else {
      this.router.navigate(['jobs', 'invoices', i.id, 'edit']);
    }
  }

  onSelected2(e) {
    this.invoiceService.selectedInvoice = this.selectedInvoice;
    if (this.selectedInvoice.isBooked) {
      this.router.navigate(['jobs', 'invoices', this.selectedInvoice.id, 'view']);
    }
    else {
      this.router.navigate(['jobs', 'invoices', this.selectedInvoice.id, 'edit']);
    }
  }

  onCreateInvoice() {
    this.router.navigate(['jobs', 'invoices', 'new']);
  }

  onSubmit(form: NgForm) {
    this.invoiceService.searchString = form.value.search;
    this.invoiceService.getInvoices(15, 1);
    //form.reset();
  }

  paginate(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    this.invoiceService.getInvoices(15, event.page + 1);
  }

  New() {
    this.onCreateInvoice();
  }
}
