<div class="mb-3 flex align-items-center justify-content-between">
  <span class="text-xl font-medium text-900">Dokumente</span>
  <button pButton pRipple label="Hinzufügen" icon="pi pi-plus" (click)="New()"></button>
</div>

<form (ngSubmit)="onSubmit(form)" #form="ngForm">
  <div class="card-body card-sidebar-menu">
    <div class="row">
      <div class="col-md-12">

        <div class="input-group mb-3">
          <input type="text" name="search_dms" placeholder="Suchtext" class="form-control" [(ngModel)]="searchString"
                 autocomplete="off"/>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary"
                    [disabled]="!form.valid"
                    type="submit">
              Suchen
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Dokumententyp </label><br/>
          <p-dropdown [options]="categories" optionLabel="key" [(ngModel)]="selectedCategory"
                      name="searchCategory"></p-dropdown>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label>Tags</label>
          <input type="text" name="tags-dms" placeholder="" class="form-control" [(ngModel)]="searchTags"/>
        </div>
      </div>
    </div>


  </div>
</form>


<p-table [value]="docs" selectionMode="single" [(selection)]="selectedDocument" dataKey="id" responsiveLayout="scroll"
         (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped">
  <ng-template pTemplate="header">
    <tr>
      <th>Name</th>
      <th>Typ</th>
      <th>Pfad</th>
      <th>Erstellt</th>
      <th>Größe</th>
      <th>Freigabe</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-doc>
    <tr [pSelectableRow]="doc">
      <td>{{ doc.name }}</td>
      <td>{{ doc.docType }}</td>
      <td>{{ doc.folderName }}</td>
      <td>{{ doc.created * 1000 | customDate }}</td>
      <td>{{ doc.fileSize | filesize }}</td>
      <td>
        <div *ngIf="doc.externalLink === ''"></div>
        <div *ngIf="doc.externalLink !== ''">
          <a href="{{doc.externalLink}}" target="_blank"><i class="pi pi-external-link"></i></a>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-paginator class="my-paginator"
             [rows]="15"
             [totalRecords]="totalDocuments"
             pageLinkSize="3"
             (onPageChange)="paginate($event)"></p-paginator>

