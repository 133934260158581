import {Reading, ReadingViewModel} from "./reading.model";


export class WaterUsage {
  id: number;

  wapID: number;

  from: string;
  to: string;

  usage: number;
  freeUsage: number;

  isPartPayment: boolean;
  isCleared: boolean;

  isBilled: boolean;

  isNeedsUnit: boolean;
  isCounter: boolean;

  invoiceId: number

  from2: number;
  to2: number;

  readings: Reading[];

  constructor() {
    this.id = 0;

    this.wapID = 0;

    this.from = "01.01.1970";
    this.to = "01.01.1970";
    this.usage = 0;
    this.freeUsage = 0;

    this.isPartPayment = false;
    this.isCleared = false;
    this.isBilled = false;

    this.from2 = 0;
    this.to2 = 0;

    this.readings = [];

    this.isNeedsUnit = false;
    this.isCounter = true;
    this.invoiceId = 0;

  }
}
