<div class="row">
    <!--
            <div class="col-md-2">
                <div class="card">

                    <div class="card-body card-sidebar-menu my-4">
                        <ul class="card-sidebar-menu-list">
                            <li class="card-sidebar-menu-list-item">
                                <a class="card-sidebar-menu-list-link"
                                   [routerLink]="['/services/counterlist/edit']"
                                   routerLinkActive="active">Aktuelle Zählerstände</a>
                            </li>

                            <li class="card-sidebar-menu-list-item">
                                <a class="card-sidebar-menu-list-link"
                                   [routerLink]="['/services/counterlist/import']"
                                   routerLinkActive="active">Zählerstände importieren</a>
                            </li>

                            <li class="card-sidebar-menu-list-item">
                                <a class="card-sidebar-menu-list-link"
                                   [routerLink]="['/services/counterlist/message']"
                                   routerLinkActive="active">Ablesenachricht senden</a>
                            </li>


                        </ul>
                    </div>
                </div>
            </div>
    -->
    <div class="col-md-12">
        <router-outlet></router-outlet>
    </div>
</div>
