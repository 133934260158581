<div class="min-h-screen flex surface-ground">
  <div id="app-sidebar"
       class="h-screen hidden lg:block flex-shrink-0 fixed lg:sticky left-0 top-0 z-1 border-right-1 border-top-1 surface-border w-full md:w-auto">


    <div class="flex h-full">

      <div class="flex flex-column surface-0 py-2 overflow-y-auto flex-shrink-0 flex-grow-1 md:flex-grow-0"
           style="width:280px">
        <div class="overflow-y-auto">
          <ul class="list-none p-2 m-0">
            <li>
              <div class="p-3 text-500 font-medium">VERWALTUNG</div>
            </li>
            <li>
              <a pRipple
                 class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                 [routerLink]="['/wap-module/wap']" routerLinkActive="settings-item-selected">
                <i class="pi pi-filter mr-2"></i>
                <span class="font-medium">Wasseranschlüsse</span>
              </a>
            </li>

            <li>
              <a pRipple
                 class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                 [routerLink]="['/wap-module/wap-counter-list']" routerLinkActive="settings-item-selected">
                <i class="pi pi-bars mr-2"></i>
                <span class="font-medium">Zählerliste</span>
              </a>
            </li>


          </ul>

          <ul class="list-none p-2 m-0 border-top-1 surface-border">
            <li>
              <div class="p-3 text-500 font-medium">ZÄHLERSTÄNDE</div>
            </li>


            <li>
              <a pRipple
                 class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                 [routerLink]="['/wap-module/counterlist/list']" routerLinkActive="settings-item-selected">
                <i class="pi pi-list mr-2"></i>
                <span class="font-medium">Aktuelle Zählerstände</span>
              </a>
            </li>
            <li>
              <a pRipple
                 class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                 [routerLink]="['/wap-module/counterlist/import']" routerLinkActive="settings-item-selected">
                <i class="pi pi-file-import mr-2"></i>
                <span class="font-medium">Zählerstände importieren</span>
              </a>
            </li>
            <li>
              <a pRipple
                 class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                 [routerLink]="['/wap-module/counterlist/counter-card']" routerLinkActive="settings-item-selected">
                <i class="pi pi-file mr-2"></i>
                <span class="font-medium">Zählerkarte erzeugen</span>
              </a>
            </li>
            <li>
              <a pRipple
                 class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                 [routerLink]="['/wap-module/counterlist/message']" routerLinkActive="settings-item-selected">
                <i class="pi pi-send mr-2"></i>
                <span class="font-medium">Ablesenachricht senden</span>
              </a>
            </li>


          </ul>

          <ul class="list-none p-2 m-0 border-top-1 surface-border">
            <li>
              <div class="p-3 text-500 font-medium">ABRECHNUNG</div>
            </li>


            <li>
              <a pRipple
                 class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                 [routerLink]="['/wap-module/wap-create-usage']" routerLinkActive="settings-item-selected"
              >
                <i class="pi pi-percentage mr-2"></i>
                <span class="font-medium">Verbrauch erzeugen</span>
              </a>
            </li>

            <li>
              <a pRipple
                 class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                 [routerLink]="['/wap-module/wap-reporting']" routerLinkActive="settings-item-selected"
              >
                <i class="pi pi-info-circle mr-2"></i>
                <span class="font-medium">Auswertung</span>
              </a>
            </li>


          </ul>

        </div>


      </div>
    </div>
  </div>

  <div class="min-h-screen flex flex-column relative flex-auto pl-2">


    <router-outlet></router-outlet>


  </div>

</div>
