import {EntityBase} from "../shared/entity.base";
import {TransactionType} from "./transaction.model";

export class TransactionTemplate extends EntityBase {
  transactionType: TransactionType = TransactionType.Normal;
  text: string = "";
  accountId: number = 0;
  costCenter: string = "";
  contactId: number = 0;
  isIncoming: boolean = false;
  isOutgoing: boolean = false;
  taxRate1: number = 0;
  taxRate2: number = 0;
  tags: string = "";
}
