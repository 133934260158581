import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';

import { Globals } from '../../globals';
import { Waterbill } from '../../shared/waterbill.model';


@Injectable({ providedIn: 'root' })
export class WaterbillService {

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router
  ) { }

  waterbills: Waterbill[];
  selectedWaterbill: Waterbill;

  getWaterbills() {
    const url = this.globals.APP_URL + 'wap/waterbills';

    return this.http.get<Waterbill[]>(url);
    
  }

}
