import {Component, OnInit} from '@angular/core';
import {Globals} from '../globals';
import {Contact} from '../shared/contact.model';
import {ContactService} from '../shared/contact.service';
import {KvpService} from '../shared/kvp.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  isLoading: boolean;

  mappedContact: Contact;
  ownerName: string = "";
  // 48.319858, 13.505624
  center: google.maps.LatLngLiteral = {lat: 48.319858, lng: 13.505624};
  zoom = 14;
  options: google.maps.MapOptions = {
    streetViewControl: false, // Disable Street View
    styles: [
      {
        featureType: 'poi',
        stylers: [{visibility: 'off'}]
      }
    ]
  };

  constructor(private globals: Globals,
              private settingsService: KvpService,
              private contactService: ContactService
  ) {
  }

  ngOnInit(): void {
    this.readUserMember();
  }

  readUserMember() {
    this.isLoading = true;
    this.settingsService.getUserMember(this.globals.currentUser.email).subscribe(resp => {
      if (resp && resp.contactId > 0) {
        this.contactService.readContact(resp.contactId).subscribe(c => {
          if (c) {
            this.mappedContact = c;
            this.ownerName = c.orgName ? c.orgName : c.firstName + ' ' + c.lastName;
          }
          this.isLoading = false;
        })
      } else {
        this.isLoading = false;
      }
    })
  }

}
