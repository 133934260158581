export class Reading {
  id: number;

  gaugeID: number;

  readingDate: string;

  count: number;

  prevCount: number;
  billed: boolean;

  readingDate2: number;

  constructor() {
    this.id = 0;

    this.gaugeID = 0;

    this.readingDate = null;
    this.count = 0;
    this.prevCount = 0;
    this.billed = false;
  }


}

export class ReadingViewModel {
  id: number;

  gaugeID: number;
  gaugeSerial: string;

  readingDate: number;

  count: number;

  prevCount: number;
  billed: boolean;

  selected: boolean;


  constructor() {
    this.id = 0;

    this.gaugeID = 0;
    this.gaugeSerial = "";

    this.readingDate = 0;
    this.count = 0;
    this.prevCount = 0;
    this.billed = false;

    this.selected = true;
  }

  public toReading(): Reading {
    let r = new Reading();
    r.id = this.id;
    r.gaugeID = this.gaugeID;
    r.readingDate2 = this.readingDate;
    r.readingDate = "01.01.1970";
    r.count = this.count;
    r.prevCount = this.prevCount;
    r.billed = this.billed;
    return r;
  }


}
