<div class="surface-card  pt-4 pl-2 pr-2  shadow-2 border-round mb-2">

  <div class="surface-section px-4">
    <div class="grid">
      <div class="col-12 lg:col-2">
        <div class="mr-l">
          <div class="text-xl font-medium text-900 mb-3">E-Mail Rechnungsversand</div>
          <p class="m-0 p-0 text-600 line-height-3 mr-3">
            Hier können Rechnungen per E-Mail an Mitglieder und Kunden versenden. Wählen Sie dazu die Rechnungen aus,
            die Sie per E-Mail versenden möchten.
            <br><br>
            Die Rechnungen werden dann über den <a href="/settings/organization">E-Mail-Zugang</a> Ihrer Organisation an
            die Empfänger
            versendet.
          </p>

        </div>
      </div>

      <div class="col-12 lg:col-10">

        <div class="grid">
          <div clasS="col-12">
            <div class="input-group mb-3 ml-0">
              <input type="text" name="search" class="form-control input-lg" placeholder="Suchtext"
                     autocomplete="off"
                     aria-label="Recipient's username" aria-describedby="basic-addon2"/>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary">
                  Suchen
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-3 ml-2">
            <div class="form-group">
              <label>Datum von</label>
              <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false"
                          [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="from">
              </p-calendar>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>bis</label>
              <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false"
                          [ngModelOptions]="{standalone: true}"
                          [(ngModel)]="to">
              </p-calendar>
            </div>
          </div>
        </div>

        <hr/>
        <div class="flex justify-content-between align-items-center mb-3">


          <div class="ml-auto">
            <button pButton pRipple label="Rechnungen versenden" icon="pi pi-send"></button>
          </div>
        </div>


        <p-table>
          <ng-template pTemplate="header">
            <tr>
              <th>Re-Nr.</th>
              <th>Re-Dat.</th>
              <th>Kunde</th>
            </tr>
          </ng-template>

        </p-table>
      </div>

    </div>
  </div>

</div>
