import { Component, OnInit } from '@angular/core';
import { Globals } from '../../../globals';

@Component({
  selector: 'app-counter-list-message',
  templateUrl: './counter-list-message.component.html',
  styleUrls: ['./counter-list-message.component.css']
})
export class CounterListMessageComponent implements OnInit {

  sendSMS: boolean;
  sendEmail: boolean;
  addLink: boolean;
  allowImage: boolean;
  onlyMissing: boolean;

  validUntil: Date;

  message: string;
  subject: string;

  constructor(public globals: Globals) { }

  ngOnInit(): void {
  }

  onSend() {

  }
}
