import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Globals } from '../globals';

import { Contact } from '../shared/contact.model';
import { ContactService } from '../shared/contact.service';
import { MemberListViewModel } from './memberlist.viewmodel';

@Injectable({ providedIn: 'root' })
export class MemberService {
  members = new Subject<Contact[]>();
  memberArray = new Array<Contact>();

  totalMembers: number = 0;

  selectedMember = new Contact();

  constructor(private contacts: ContactService, private router: Router, private globals: Globals, private http: HttpClient) {
    this.selectedMember.role = "Member";
  }

  getNextNumber() {
    const url = this.globals.APP_URL + 'numberrange/members/next-number';
    return this.http.get<{ id: string }>(url);
  }

  getMemberList() {
    const url = this.globals.APP_URL + 'contacts/memberlist';
    return this.http.get<MemberListViewModel[]>(url);
  }

  getMembers(search: string, pageSize: number, page: number) {
    this.contacts
      .readContacts('member', search, pageSize, page)
      .subscribe((respData) => {
        this.totalMembers = parseInt(respData.headers.get('x-total-count'));
        this.memberArray = respData.body.slice();
        this.members.next(this.memberArray);
      });
  }

  addMember(c: Contact) {
    this.contacts.addContact(c).subscribe((respData) => {
      this.selectedMember = respData;
      this.memberArray.push(respData);
      this.router.navigate(['members-module', 'members', this.selectedMember.id]);
    });
  }

  updateMember(c: Contact) {
    this.contacts.updateContact(c).subscribe((respData) => {
      this.selectedMember = respData;
      const idx = this.memberArray.findIndex((x) => x.id === c.id);
      this.memberArray[idx] = respData;
      this.router.navigate(['members-module', 'members', this.selectedMember.id]);
    });
  }

  getMember(id: number): Contact {
    this.selectedMember = Object.assign(new Contact(), this.memberArray.find((m) => m.id === id))
    return this.selectedMember;
  }

  deleteMember(id: number) {
    return this.contacts.deleteContact(id);
  }

  removeContactFromList(c: Contact) {
    const index = this.memberArray.findIndex(mem => mem.id == c.id);
    if (index > -1) {
      this.memberArray.splice(index, 1);
      this.members.next(this.memberArray);
    }
  }

  readMember(id: number) {
    return this.contacts.readContact(id);
  }
}
