import {DatePipe} from '@angular/common';
import {ViewChild} from '@angular/core';
import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Globals} from '../../../globals';
import {MemberSelectComponent} from '../../../members/member-select/member-select.component';
import {Contact} from '../../../shared/contact.model';
import {KvpService} from '../../../shared/kvp.service';
import {Task} from '../../../shared/task.model';
import {TasksService} from '../tasks.service';
import {ContactSelectComponent} from "../../../shared/contact-select/contact-select.component";

@Component({
  selector: 'app-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.css'],
  providers: [DialogService],
})
export class TaskEditComponent implements OnInit {

  @ViewChild('dueData', {static: true}) dueForm: NgForm;

  dueDate: Date = new Date();
  reminderDate: Date = new Date();

  task: Task = new Task();

  id: number;
  editMode: boolean;

  ref: DynamicDialogRef;

  constructor(private kvpService: KvpService,
              public globals: Globals,
              private route: ActivatedRoute,
              private router: Router,
              public datepipe: DatePipe,
              public dialogService: DialogService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private entityService: TasksService) {

  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.editMode = params['id'] != 'new';
      this.initForm();
    });
  }

  initForm() {
    if (this.editMode) {
      this.task = this.entityService.getItem(this.id);
      if (this.task.dueDate2 > this.globals.MIN_DATE)
        this.dueDate = new Date(this.task.dueDate2 * 1000);
      else
        this.dueDate = null;

      if (this.task.reminderDate2 > this.globals.MIN_DATE)
        this.reminderDate = new Date(this.task.reminderDate2 * 1000);
      else
        this.reminderDate = null;

    } else {
      this.task = new Task();
      this.dueDate = null;
      this.reminderDate = null;
    }
  }

  onSave() {
    this.task.dueDate = this.datepipe.transform(new Date(), 'MM.dd.yyyy');
    this.task.reminderDate = this.datepipe.transform(new Date(), 'MM.dd.yyyy');
    if (this.dueDate)
      this.task.dueDate2 = Math.round(this.dueDate.getTime() / 1000);
    else
      this.task.dueDate2 = this.globals.MIN_DATE;

    if (this.reminderDate)
      this.task.reminderDate2 = Math.round(this.reminderDate.getTime() / 1000);
    else
      this.task.reminderDate2 = this.globals.MIN_DATE;

    if (this.editMode) {
      this.entityService.updateItem(this.task);
      this.messageService.add({key: 'tl', severity: 'info', summary: 'Aufgabe wurde gespeichert.'});
    } else {
      this.entityService.addItem(this.task);
      this.messageService.add({key: 'tl', severity: 'info', summary: 'Neue Aufgabe wurde erstellt.'});
    }
  }

  onDelete() {
    if (this.editMode) {
      this.confirmationService.confirm({
        message: 'Möchten Sie diese Aufgabe wirklich löschen?',
        header: 'Aufgabe löschen',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        key: 'confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.entityService.deleteItem(this.task.id);

        },
      });

    } else {
      this.initForm();
    }
  }

  onSelectMember() {
    this.ref = this.dialogService.open(ContactSelectComponent, {
      data: {
        role: 'members,customer,supplier'
      },
      header: 'Verantwortlichen auswählen',
      width: '75%',
      contentStyle: {'max-height': '650px', overflow: 'auto'},
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((c: Contact) => {
      if (c) {
        this.task.contactId = c.id;
        this.task.who = c.contactType == 'person' ? c.firstName + ' ' + c.lastName : c.orgName;
      }
    });
  }

  Back() {
    this.router.navigate(['services', 'tasks']);
  }

  Delete() {
    this.onDelete();
  }

  Save() {
    this.onSave();
  }

}
