import {Component} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {CalendarModule} from "primeng/calendar";
import {RippleModule} from "primeng/ripple";
import {TableModule} from "primeng/table";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-send-email-invoices',
  standalone: true,
  imports: [
    ButtonModule,
    CalendarModule,
    RippleModule,
    TableModule,
    FormsModule
  ],
  templateUrl: './send-email-invoices.component.html',
  styleUrl: './send-email-invoices.component.css'
})
export class SendEmailInvoicesComponent {

  searchString: string = '';

  from: Date = new Date();
  to: Date = new Date();

  onSearch() {
    console.log('searching for: ', this.searchString);
  }
}
