import { timestamp } from 'rxjs/operators';

export class Product {
  id: number;

  productID: string;

  description: string;
  productText: string;
  productGroup: string;

  unit: string;

  netSalesPrice: number;
  netPurchasePrice: number;
  tax: number;

  orderNumber: string;
  vendorID: number;

  field1: string;
  field2: string;

  manageStock: boolean;

  constructor() {
    this.productID = "";
    this.description = "";
    this.productGroup = "";
    this.productText = "";
    this.unit = "";

    this.netSalesPrice = 0;
    this.netPurchasePrice = 0;
    this.tax = 0;

    this.orderNumber = "";
    this.vendorID = 0;

    this.field1 = "";
    this.field2 = "";

    this.manageStock = false;
  }
}

export class ProductImport extends Product {
  public supplierId: string;

  constructor() {
    super();
    this.supplierId = '';
  }
}

export class IdResponse {
  id: string;
}
