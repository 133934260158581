import { DatePipe } from '@angular/common';
import { OnChanges, SimpleChange } from '@angular/core';
import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Attachment } from '../../../shared/attachment.model'
import { MyDocument } from '../../../shared/document.model';
import { DmsSelectComponent } from '../dms-select/dms-select.component';
import { DmsService } from '../dms.service';

class EditItem {
  value: Attachment;
  file: File;
  isNew: boolean;
  isChanged: boolean;
  isNewDoc: boolean;
  isRemoved: boolean;
}

@Component({
  selector: 'app-dms-attachments',
  templateUrl: './dms-attachments.component.html',
  styleUrls: ['./dms-attachments.component.css'],
  providers: [DialogService]
})
export class DmsAttachmentsComponent implements OnInit, OnChanges {

  @ViewChild('FileSelectInputDialog') FileSelectInputDialog: ElementRef;

  @Input('tableName') tableName: string = "";
  @Input('PK') pk: number = 0;
  @Input('title') title: string = "Anhänge";
  @Input('titleNew') titleNew: string = "Anhang hinzufügen";
  @Input('docType') docType: string = "Anhang";

  fileName: string;

  ref: DynamicDialogRef;

  isLoading: boolean;

  previewDialog: boolean;

  attachments: EditItem[];

  isPdf: boolean;
  isImage: boolean;

  zoomScale: string = "page-fit";
  pdfSrc: string;
  imgSrc: any;

  doc: MyDocument;

  previewTitle: string = "";

  constructor(public dialogService: DialogService,
    private sanitizer: DomSanitizer,
    public datepipe: DatePipe,
    private router: Router,
    private dmsService: DmsService  ) { }

  ngOnInit(): void {
    this.attachments = [];
    this.isLoading = false;
    console.log("PK " + this.pk.toString());
    if (this.pk > 0) {
      this.loadAttachments();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.pk;
    if (currentItem.currentValue) {
      if (currentItem.previousValue > 0 && currentItem.currentValue > 0) {
        this.loadAttachments();
      }
    }

  }

  loadAttachments() {
    console.log("Load attachments for '" + this.tableName + "': id= " + this.pk.toString());
    this.attachments = [];
    this.dmsService.getLink(this.tableName, this.pk).subscribe((resp) => {
      resp.forEach((a) => {
        let item = new EditItem();
        item.isChanged = false;
        item.isNew = false;
        item.isNewDoc = false;
        item.isRemoved = false;
        item.file = null;
        item.value = a;
        this.attachments.push(item);
      })
    });
  }

  onSaveAttachments() {
    this.save();
  }

  onDeleteAttachment(idx : number) {
    //this.attachments.splice(idx, 1);
    this.attachments[idx].isRemoved = true;
  }


  onAddAttachment() {
    if (this.pk > 0) {
      const elem: HTMLElement = this.FileSelectInputDialog.nativeElement;
      elem.click();
    }
  }

  onAddLink() {
    if (this.pk > 0) {
      this.ref = this.dialogService.open(DmsSelectComponent, {
        data: {
          role: 'supplier'
        },
        header: 'Dokument auswählen',
        width: '75%',
        //contentStyle: { 'max-height': '650px', overflow: 'auto' },
        baseZIndex: 10000,
      });

      this.ref.onClose.subscribe((doc: MyDocument) => {
        if (doc) {
          console.log(doc);

          var selectedFile: EditItem = new EditItem();
          selectedFile.value = new Attachment();
          selectedFile.isNew = true;
          selectedFile.isNewDoc = false;
          selectedFile.value.name = doc.name;
          selectedFile.value.tablePK = this.tableName;
          selectedFile.value.pk = this.pk;
          selectedFile.value.documentId = doc.id;
          selectedFile.value.name = doc.name;
          selectedFile.file = null;

          this.attachments.push(selectedFile);
        }
      });
    }
  }

  onFileSelected(e) {
    var selectedFile: EditItem = new EditItem();
    selectedFile.value = new Attachment();
    selectedFile.isNew = true;
    selectedFile.isNewDoc = true;
    selectedFile.value.name = e.target.files[0].name;
    selectedFile.value.tablePK = this.tableName;
    selectedFile.value.pk = this.pk;
    selectedFile.file = e.target.files[0];

    this.attachments.push(selectedFile);
  }

  save() {
    if (this.pk > 0) {
      this.isLoading = true;
      console.log(this.attachments);

      this.attachments.forEach((a) => {
        if (a.isNew && !a.isNewDoc) {
          this.dmsService.addLink(a.value);
        }
        else if (a.isNew && a.isNewDoc && a.file != null) {
          let doc = new MyDocument();
          doc.docType = this.docType;
          doc.name = a.value.name;
          doc.documentDate2 = Math.round(new Date().getTime()/1000); // this.datepipe.transform(new Date(), 'MM.dd.yyyy')
          doc.documentID = '';
          this.dmsService.uploadDocument(doc, a.file).subscribe((resp) => {
            console.log(resp);
            a.value.documentId = resp.id;
            this.dmsService.addLink(a.value);
          });
        }
        else if (!a.isNew && !a.isNewDoc && a.isRemoved && a.value.id > 0) {
          this.dmsService.deleteLink(a.value.id).subscribe((resp) => {

          });
        }
      });

    }



  }

  onSelect(item: EditItem) {





    this.dmsService.readDocument(item.value.documentId).subscribe(d => {
      //this.router.navigate(['services', 'dms', 'postbox', item.value.documentId])
      if (d.path.toUpperCase().includes(".PDF")) {
        this.isPdf = true;
        this.isImage = false;
      }
      else if (d.path.toUpperCase().includes(".PNG") || d.path.toUpperCase().includes(".JPG") || d.path.toUpperCase().includes(".JPEG") || d.path.toUpperCase().includes(".GIF")) {
        this.isImage = true;
        this.isPdf = false;
      }
      else {
        this.isPdf = false;
        this.isImage = false;
      }


      this.previewTitle = d.name;
      this.dmsService.downloadDocument(item.value.documentId).subscribe(resp => {
        console.log(resp.body);
        var downloadURL = URL.createObjectURL(resp.body);
        console.log(downloadURL);
        if (this.isPdf) {
          this.pdfSrc = downloadURL;
        }
        else if (this.isImage) {
          this.imgSrc = this.sanitizer.bypassSecurityTrustUrl(downloadURL);

        }
        this.previewDialog = true;
      });
    });


  }


}
