import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {DatePipe, DecimalPipe, CurrencyPipe} from '@angular/common';
import {HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateService} from '@ngx-translate/core';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {GoogleMapsModule} from '@angular/google-maps';

import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {MembersComponent} from './members/members.component';
import {EditMemberComponent} from './members/edit-member/edit-member.component';
import {MemberStartComponent} from './members/member-start/member-start.component';
import {MemberListComponent} from './members/member-list/member-list.component';
import {MemberSelectComponent} from './members/member-select/member-select.component';
import {SettingsComponent} from './settings/settings.component';
import {WaterAccessPointComponent} from './water-access-point/water-access-point.component';
import {AuthComponent} from './auth/auth.component';
import {AuthInterceptorService} from './auth/auth-interceptor.service';
import {AppUserService} from './auth/appuser.service'
import {CanDeactivateGuard} from './auth/candeactivate.guard';

import {AppRoutingModule} from './app-routing.module';
import {MasterdataComponent} from './masterdata/masterdata.component';
import {LoadingSpinnerComponent} from './shared/loading-spinner/loading-spinner.component';
import {WapListComponent} from './water-access-point/wap-list/wap-list.component';
import {WapStartComponent} from './water-access-point/wap-start/wap-start.component';
import {WapEditComponent} from './water-access-point/wap-edit/wap-edit.component';
import {WapReportingComponent} from "./water-access-point/wap-reporting/wap-reporting.component";
import {HomeComponent} from './home/home.component';

import {CalendarModule} from 'primeng/calendar';
import {ButtonModule} from 'primeng/button';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {DialogModule} from 'primeng/dialog';
import {PaginatorModule} from 'primeng/paginator';
import {StepsModule} from 'primeng/steps';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {FileUploadModule} from 'primeng/fileupload';
import {TimelineModule} from "primeng/timeline";
import {CardModule} from "primeng/card";
import {InputNumberModule} from 'primeng/inputnumber';
import {RadioButtonModule} from 'primeng/radiobutton';
import {PanelModule} from 'primeng/panel';
import {InputTextModule} from 'primeng/inputtext';
import {ToolbarModule} from 'primeng/toolbar';
import {SplitButtonModule} from 'primeng/splitbutton';
import {StyleClassModule} from 'primeng/styleclass';
import {CheckboxModule} from 'primeng/checkbox';
import {TagModule} from 'primeng/tag';
import {CarouselModule} from 'primeng/carousel';
import {TreeModule} from 'primeng/tree';
import {MessageService, TreeNode} from 'primeng/api';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {Badge, BadgeModule} from 'primeng/badge';
import {AutoFocus, AutoFocusModule} from 'primeng/autofocus';
import {AccordionModule} from 'primeng/accordion';
import {AvatarModule} from 'primeng/avatar'
import {InputTextareaModule} from 'primeng/inputtextarea';
import {SelectButtonModule} from 'primeng/selectbutton';
import {EditorModule} from 'primeng/editor';
import {Menubar, MenubarModule} from 'primeng/menubar';
import {ImageModule} from 'primeng/image';
import {QRCodeModule} from 'angularx-qrcode';
import {InputGroupModule} from "primeng/inputgroup";


import {OrganizationSettingsComponent} from './settings/organization-settings/organization-settings.component';
import {AccessSettingsComponent} from './settings/access-settings/access-settings.component';
import {GaugesComponent} from './masterdata/gauges/gauges.component';
import {SupplierComponent} from './masterdata/supplier/supplier.component';
import {GaugeListComponent} from './masterdata/gauges/gauge-list/gauge-list.component';
import {GaugeEditComponent} from './masterdata/gauges/gauge-edit/gauge-edit.component';
import {GaugeSelectComponent} from './masterdata/gauges/gauge-select/gauge-select.component';
import {ProductsComponent} from './masterdata/products/products.component';
import {ProductListComponent} from './masterdata/products/product-list/product-list.component';
import {ProductEditComponent} from './masterdata/products/product-edit/product-edit.component';
import {ProductSelectComponent} from './masterdata/products/product-select/product-select.component';
import {CustomerComponent} from './masterdata/customer/customer.component';
import {CustomerEditComponent} from './masterdata/customer/customer-edit/customer-edit.component';
import {CustomerListComponent} from './masterdata/customer/customer-list/customer-list.component';
import {CustomerService} from './masterdata/customer/customer.service';
import {CustomerSelectComponent} from './masterdata/customer/customer-select/customer-select.component';
import {JobsComponent} from './jobs/jobs.component';
import {InvoiceService} from './jobs/billing/invoice.service';
import {InvoiceComponent} from './jobs/billing/invoice.component';
import {ListeditStartComponent} from './shared/listedit-start/listedit-start.component';
import {InvoiceListComponent} from './jobs/billing/invoice-list/invoice-list.component';
import {InvoiceEditComponent} from './jobs/billing/invoice-edit/invoice-edit.component';
import {SubwapEditComponent} from './water-access-point/wap-edit/subwap-edit/subwap-edit.component';
import {ServicesComponent} from './services/services.component';
import {DmsComponent} from './services/dms/dms.component';
import {MaintenanceComponent} from './services/maintenance/maintenance.component';
import {TasksComponent} from './services/tasks/tasks.component';
import {WapUsageComponent} from './water-access-point/wap-usage/wap-usage.component';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ConfirmationService} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import {DmsListComponent} from './services/dms/dms-list/dms-list.component';
import {DmsStartComponent} from './services/dms/dms-start/dms-start.component';
import {DmsEditComponent} from './services/dms/dms-edit/dms-edit.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {WaterbillComponent} from './jobs/waterbill/waterbill.component';
import {WaterbillListComponent} from './jobs/waterbill/waterbill-list/waterbill-list.component';
import {WaterbillCreateComponent} from './jobs/waterbill/waterbill-create/waterbill-create.component';
import {CustomDatePipe} from './shared/custom.datepipe';
import {FileSizePipe} from './shared/file-size.pipe';
import {BooleanYesnoConverter} from './shared/boolean.yesno.pipe';
import {ContactNameConverter} from './shared/contact.name.pipe';
import {InvoiceViewComponent} from './jobs/billing/invoice-view/invoice-view.component';
import {SupplierListComponent} from './masterdata/supplier/supplier-list/supplier-list.component';
import {SupplierEditComponent} from './masterdata/supplier/supplier-edit/supplier-edit.component';
import {SupplierService} from './masterdata/supplier/supplier.service';
import {TaskEditComponent} from './services/tasks/task-edit/task-edit.component';
import {TaskListComponent} from './services/tasks/task-list/task-list.component';
import {TaskStartComponent} from './services/tasks/task-start/task-start.component';
import {TasksService} from './services/tasks/tasks.service';
import {MytasksComponent} from './home/mytasks/mytasks.component';
import {EquipmentComponent} from './masterdata/equipment/equipment.component';
import {EquipmentService} from './masterdata/equipment/equipment.service';
import {EquipmentListComponent} from './masterdata/equipment/equipment-list/equipment-list.component';
import {EquipmentEditComponent} from './masterdata/equipment/equipment-edit/equipment-edit.component';
import {KvpSettingsComponent} from './shared/kvp-settings/kvp-settings.component';
import {ContactSelectComponent} from './shared/contact-select/contact-select.component';
import {
  AccessSettingsListComponent
} from './settings/access-settings/access-settings-list/access-settings-list.component';
import {
  AccessSettingsEditComponent
} from './settings/access-settings/access-settings-edit/access-settings-edit.component';
import {MaintenancePlanComponent} from './services/maintenance/maintenance-plan/maintenance-plan.component';
import {
  MaintenancePlanListComponent
} from './services/maintenance/maintenance-plan/maintenance-plan-list/maintenance-plan-list.component';
import {
  MaintenancePlanEditComponent
} from './services/maintenance/maintenance-plan/maintenance-plan-edit/maintenance-plan-edit.component';
import {MaintenancePlanService} from './services/maintenance/maintenance-plan.service';
import {MaintenanceWorkService} from './services/maintenance/maintenance-work.service';
import {EquipmentSelectComponent} from './masterdata/equipment/equipment-select/equipment-select.component';
import {
  MaintenanceWorkEditComponent
} from './services/maintenance/maintenance-work/maintenance-work-edit/maintenance-work-edit.component';
import {
  MaintenanceWorkListComponent
} from './services/maintenance/maintenance-work/maintenance-work-list/maintenance-work-list.component';
import {
  MaintenanceWorkHistoryComponent
} from './services/maintenance/maintenance-work/maintenance-work-history/maintenance-work-history.component';
import {DebitListComponent} from './jobs/debit/debit-list/debit-list.component';
import {DebitEditComponent} from './jobs/debit/debit-edit/debit-edit.component';
import {DebitComponent} from './jobs/debit/debit.component';
import {NumericDirective} from './shared/numeric.directive';
import {DmsSelectComponent} from './services/dms/dms-select/dms-select.component';
import {DmsAttachmentsComponent} from './services/dms/dms-attachments/dms-attachments.component';
import {WaterbillSettingsComponent} from './settings/waterbill-settings/waterbill-settings.component';
import {
  WaterbillSettingsEditComponent
} from './settings/waterbill-settings/waterbill-settings-edit/waterbill-settings-edit.component';
import {
  WaterbillSettingsListComponent
} from './settings/waterbill-settings/waterbill-settings-list/waterbill-settings-list.component'
import {WaterbillSettingsService} from './settings/waterbill-settings/waterbill-settings.service'
import {EntityService} from './shared/entity.service'
import {WaterbillSetting} from './settings/waterbill-settings/waterbill-settings.model';
import {EntitySelectComponent} from './shared/entity-select/entity-select.component';
import {DmsPreviewComponent} from './services/dms/dms-preview/dms-preview.component';
import {CounterListComponent} from './services/counter-list/counter-list.component';
import {CounterListEditComponent} from './services/counter-list/counter-list-edit/counter-list-edit.component';
import {CounterListImportComponent} from './services/counter-list/counter-list-import/counter-list-import.component';
import {CounterListMessageComponent} from './services/counter-list/counter-list-message/counter-list-message.component';
import {DataImportComponent} from './settings/data-import/data-import.component';
import {AccountsComponent} from './settings/accounts/accounts.component';
import {AccountEditComponent} from './settings/accounts/account-edit/account-edit.component';
import {AccountListComponent} from './settings/accounts/account-list/account-list.component';
import {AccountService} from './settings/accounts/account.service';
import {FinanceComponent} from './finance/finance.component';
import {MywapsComponent} from './home/mywaps/mywaps.component';
import {MyinvoicesComponent} from './home/myinvoices/myinvoices.component';
import {PaymenttermsComponent} from './settings/paymentterms/paymentterms.component';
import {PaymentTermsService} from './settings/paymentterms/payment-terms.service';
import {NumberrangeComponent} from './settings/numberrange/numberrange.component'
import {NumberRangeService} from './settings/numberrange/number-range.service';
import {PageNotFoundComponent} from './shared/page-not-found/page-not-found.component';
import {TransactionsComponent} from './finance/transactions/transactions.component';
import {CashBookComponent} from './finance/cash-book/cash-book.component';
import {OpenInvoicesComponent} from './finance/open-invoices/open-invoices.component';
import {DmsArchiveComponent} from './services/dms/dms-archive/dms-archive.component';
import {DmsRecycleBinComponent} from './services/dms/dms-recycle-bin/dms-recycle-bin.component';
import {FinancialYearsComponent} from './settings/financial-years/financial-years.component';
import {FinancialYearsService} from './settings/financial-years/financial-years.service';
import {FolderService} from './services/dms/folder.service'
import {TransactionService} from './finance/transaction.service';
import {AppHelpComponent} from './app-help/app-help.component';
import {TicketsComponent} from './app-help/tickets/tickets.component';
import {FaqComponent} from './app-help/faq/faq.component';
import {VideosComponent} from './app-help/videos/videos.component';
import {SubscriptionComponent} from './app-help/subscription/subscription.component';
import {LicenseComponent} from './app-help/license/license.component';
import {TermsComponent} from './app-help/terms/terms.component';
import {TicketListComponent} from './app-help/tickets/ticket-list/ticket-list.component';
import {TicketEditComponent} from './app-help/tickets/ticket-edit/ticket-edit.component'
import {TicketService} from './app-help/tickets/ticket.service';
import {BalancingComponent} from './finance/balancing/balancing.component';
import {MonthlyClosingsComponent} from './finance/monthly-closings/monthly-closings.component';
import {TaxReportComponent} from './finance/tax-report/tax-report.component';
import {SummaryComponent} from './finance/summary/summary.component';
import {WapCounterListComponent} from './water-access-point/wap-counter-list/wap-counter-list.component'
import {NeedsUnitService} from './water-access-point/needs-unit.service';
import {WapCreateUsageComponent} from './water-access-point/wap-create-usage/wap-create-usage.component';
import {DmsEditorComponent} from './services/dms/dms-editor/dms-editor.component';
import {MemberExportListComponent} from './members/member-export-list/member-export-list.component';
import {DmsFileSharingComponent} from './services/dms/dms-file-sharing/dms-file-sharing.component';
import {UserRolesComponent} from './settings/user-roles/user-roles.component';
import {UnderConstructionComponent} from "./shared/under-construction/under-construction.component";
import {RippleModule} from "primeng/ripple";
import {CascadeSelectModule} from "primeng/cascadeselect";
import {TreeTableModule} from "primeng/treetable";
import {DocDbService} from "./shared/doc-db.service";
import {GeneralFunctionService} from "./shared/general-function.service";
import {AppstatsComponent} from "./home/appstats/appstats.component";
import {OpenInvoicesSelectComponent} from "./finance/open-invoices/open-invoices-select/open-invoices-select.component";
import {BackupComponent} from "./settings/backup/backup.component";
import {CounterListCardComponent} from "./services/counter-list/counter-list-card/counter-list-card.component";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MembersComponent,
    EditMemberComponent,
    MemberStartComponent,
    MemberListComponent,
    MemberSelectComponent,
    SettingsComponent,
    WaterAccessPointComponent,
    AuthComponent,
    MasterdataComponent,
    LoadingSpinnerComponent,
    WapListComponent,
    WapStartComponent,
    WapEditComponent,
    HomeComponent,
    ProductsComponent,
    CustomerComponent,
    ProductListComponent,
    ProductEditComponent,
    OrganizationSettingsComponent,
    AccessSettingsComponent,
    GaugesComponent,
    SupplierComponent,
    GaugeListComponent,
    GaugeEditComponent,
    GaugeSelectComponent,
    ProductSelectComponent,
    CustomerEditComponent,
    CustomerListComponent,
    CustomerSelectComponent,
    JobsComponent,
    InvoiceComponent,
    ListeditStartComponent,
    InvoiceListComponent,
    InvoiceEditComponent,
    SubwapEditComponent,
    ServicesComponent,
    DmsComponent,
    MaintenanceComponent,
    TasksComponent,
    WapUsageComponent,
    DmsListComponent,
    DmsStartComponent,
    DmsEditComponent,
    WaterbillComponent,
    WaterbillListComponent,
    WaterbillCreateComponent,
    CustomDatePipe,
    FileSizePipe,
    BooleanYesnoConverter,
    ContactNameConverter,
    InvoiceViewComponent,
    SupplierListComponent,
    SupplierEditComponent,
    TaskEditComponent,
    TaskListComponent,
    TaskStartComponent,
    MytasksComponent,
    EquipmentComponent,
    EquipmentListComponent,
    EquipmentEditComponent,
    KvpSettingsComponent,
    ContactSelectComponent,
    AccessSettingsListComponent,
    AccessSettingsEditComponent,
    MaintenancePlanComponent,
    MaintenancePlanListComponent,
    MaintenancePlanEditComponent,
    EquipmentSelectComponent,
    MaintenanceWorkEditComponent,
    MaintenanceWorkHistoryComponent,
    DebitListComponent,
    DebitEditComponent,
    DebitComponent,
    NumericDirective,
    DmsSelectComponent,
    DmsAttachmentsComponent,
    WaterbillSettingsComponent,
    WaterbillSettingsEditComponent,
    WaterbillSettingsListComponent,
    EntitySelectComponent,
    DmsPreviewComponent,
    CounterListComponent,
    CounterListEditComponent,
    CounterListImportComponent,
    CounterListMessageComponent,
    DataImportComponent,
    AccountsComponent,
    AccountEditComponent,
    AccountListComponent,
    FinanceComponent,
    MywapsComponent,
    MyinvoicesComponent,
    PaymenttermsComponent,
    NumberrangeComponent,
    PageNotFoundComponent,
    MaintenanceWorkListComponent,
    TransactionsComponent,
    CashBookComponent,
    OpenInvoicesComponent,
    DmsArchiveComponent,
    DmsRecycleBinComponent,
    FinancialYearsComponent,
    AppHelpComponent,
    TicketsComponent,
    FaqComponent,
    VideosComponent,
    SubscriptionComponent,
    LicenseComponent,
    TermsComponent,
    TicketListComponent,
    TicketEditComponent,
    BalancingComponent,
    MonthlyClosingsComponent,
    TaxReportComponent,
    SummaryComponent,
    WapCounterListComponent,
    WapCreateUsageComponent,
    DmsEditorComponent,
    MemberExportListComponent,
    DmsFileSharingComponent,
    UserRolesComponent,
    UnderConstructionComponent,
    AppstatsComponent,
    OpenInvoicesSelectComponent,
    WapReportingComponent,
    BackupComponent,
    CounterListCardComponent
  ],
  bootstrap: [AppComponent],
  exports: [
    CustomDatePipe
  ], imports: [BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    GoogleMapsModule,
    FormsModule,
    AppRoutingModule,
    DynamicDialogModule,
    PaginatorModule,
    CalendarModule,
    StepsModule,
    TableModule,
    ToastModule,
    FileUploadModule,
    ConfirmPopupModule,
    DropdownModule,
    MultiSelectModule,
    NgxExtendedPdfViewerModule,
    ConfirmDialogModule,
    TimelineModule,
    CardModule,
    InputNumberModule,
    RadioButtonModule,
    ButtonModule,
    PanelModule,
    InputTextModule,
    ToolbarModule,
    SplitButtonModule,
    StyleClassModule,
    DialogModule,
    CheckboxModule,
    TagModule,
    CarouselModule,
    TreeModule,
    BreadcrumbModule,
    BadgeModule,
    AutoFocusModule,
    AccordionModule,
    AvatarModule,
    InputTextareaModule,
    SelectButtonModule,
    EditorModule,
    MenubarModule,
    QRCodeModule,
    RippleModule,
    CascadeSelectModule,
    TreeTableModule,
    InputGroupModule], providers: [
    TranslateService,
    CustomerService,
    SupplierService,
    EquipmentService,
    DocDbService,
    InvoiceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    CanDeactivateGuard,
    TasksService,
    DatePipe,
    DecimalPipe,
    CurrencyPipe,
    ConfirmationService,
    AppUserService,
    MaintenancePlanService,
    MaintenanceWorkService,
    WaterbillSettingsService,
    AccountService,
    PaymentTermsService,
    NumberRangeService,
    FinancialYearsService,
    FolderService,
    TransactionService,
    MessageService,
    TicketService,
    NeedsUnitService,
    GeneralFunctionService,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
}
