import {Component, OnInit} from '@angular/core';
import {Globals} from '../../globals';
import {WapService} from '../wap.service';
import {WapCounterListViewModel} from '../wapcounterlistviewmodel.model';
import {WapCounterSearch} from '../wapcountersearch.model';
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-wap-counter-list',
  templateUrl: './wap-counter-list.component.html',
  styleUrls: ['./wap-counter-list.component.css']
})
export class WapCounterListComponent implements OnInit {

  searchParam: WapCounterSearch = new WapCounterSearch();

  constructor(public wapService: WapService, private globals: Globals) {
  }

  items: WapCounterListViewModel[] = new Array();

  cols: any[];
  selectedColumns: any[];

  exportColumns: any[];

  ngOnInit(): void {
    this.cols = [
      {sort: 1, field: 'accessPointID', header: 'Anschluss'},
      {sort: 2, field: 'address1', header: 'Adresse'},
      {sort: 3, field: 'city', header: 'Ort'},
      {sort: 4, field: 'owner', header: 'Eigentümer'},
      {sort: 5, field: 'phoneNumber', header: 'Telefon'},
      {sort: 6, field: 'supplyZone', header: 'Versorgungsgebiet'},
      {sort: 7, field: 'pipeNumber', header: 'Leitung'},
      {sort: 8, field: 'gaugeSerial', header: 'Zähler'},
      {sort: 9, field: 'lastCount', header: 'Stand'},
      {sort: 10, field: 'calibrationDate', header: 'Eichdatum'},
      {sort: 11, field: 'subGaugeSerial', header: 'Sub-Zähler'},
      {sort: 12, field: 'subLastCount', header: 'Sub-Stand'},

    ];

    this.selectedColumns = this.cols.slice();

    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  onSearch() {
    this.items = new Array();
    this.wapService.getWapCounterList(this.searchParam).subscribe(r => {
      this.items = r;
    })
  }

  onPrint() {
    this.exportPdf();
  }

  onSave() {
    var csv = this.generateCSV(this.items, this.selectedColumns, ";");
    var BOM = "\uFEFF";
    var csvData = BOM + csv;
    var blob = new Blob([csvData], {type: 'text/csv;charset=utf-8'});
    var url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', "zaehlerliste_" + this.globals.getCurrentTimestamp() + ".csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    console.log(csv);
  }

  exportPdf() {
    console.log("test2");
    // map items to new array, convert date to string
    var items = this.items.map(item => {
      return {
        accessPointID: item.accessPointID,
        owner: item.owner,
        phoneNumber: item.phoneNumber,
        address1: item.address1,
        zip: item.zip,
        city: item.city,
        pipeNumber: item.pipeNumber,
        supplyZone: item.supplyZone,
        gaugeSerial: item.gaugeSerial,
        lastCount: item.lastCount,
        calibrationDate: this.globals.getGermanDateString(new Date(item.calibrationDate)),
        subGaugeSerial: item.subGaugeSerial,
        subLastCount: item.subLastCount
      };
    });

    this.exportColumns = this.selectedColumns.map(col => ({title: col.header, dataKey: col.field}));
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        //@ts-ignore
        const doc = new jsPDF.default('landscape'); //.default(0, 0);
        //@ts-ignore
        doc.autoTable(this.exportColumns, items);


        doc.save('zaehlerliste_' + this.globals.getCurrentTimestamp() + '.pdf');
      })
    })
  }

  generateCSV(data: any[], cols: { field: string, header: string }[], delimiter: string = ','): string {
    const headers = cols.map(col => col.header).join(delimiter);

    const rows = data.map(item => {
      const values = cols.map(col => {
        const value = item[col.field];
        if (value instanceof Date) {
          return this.globals.getGermanDateString(value);
        }

        return value;
      })
        .join(delimiter);
      return values;
    });

    return `${headers}\n${rows.join('\n')}`;
  }

  customSort(event) {
    console.log(event);
    var o1 = 1;
    var o2 = -1;

    if (event.order === -1) {
      o1 = -1;
      o2 = 1;
    }

    this.items.sort((a, b) => a[event.field] > b[event.field] ? o1 : o2);

  }

  onPrintForm() {
    // Create gauge ids from items
    var gaugeIds = this.items.map(i => i.gaugeId);
    this.wapService.counterChangeForm(gaugeIds).subscribe((respo) => {
      var file = new Blob([respo], {type: 'application/pdf'})
      var fileURL = URL.createObjectURL(file);

      //window.open(fileURL);
      var a = document.createElement('a');
      a.href = fileURL;
      a.target = '_self';
      a.download = 'Zaehler_Tauschformular.pdf';
      document.body.appendChild(a);
      a.click();
    });
  }
}
