import { EntityBase } from './entity.base'; // Import the appropriate base class and any required decorators/converter

export class NeedsUnit extends EntityBase {
  wapID: number;
  name: string;
  quantity: number;
  lastChanged: Date;
  active: boolean;

  lastChanged2: number;

  constructor() {
    super();
    this.wapID = 0;
    this.name = '';
    this.quantity = 0;
    this.lastChanged = new Date();
    this.active = true;
    this.lastChanged2 = 0;
  }
}
