
<div class="row">

</div>
<div class="row">
  <div class="card" *ngIf="pdfSrc != null || imgSrc != null">
    <div class="card-header d-md-flex justify-content-between align-items-center">
      <h6>Vorschau</h6>

    </div>
    <hr *ngIf="isPdf" />


    <div *ngIf="isPdf">
        <ngx-extended-pdf-viewer [src]="pdfSrc" useBrowserLocale="true" height="80vh" [showHandToolButton]="true">
        </ngx-extended-pdf-viewer>
    </div>


    <div *ngIf="isImage">
      <img [src]="imgSrc" class="dms-img" />
    </div>
  </div>
</div>
