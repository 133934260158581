import {ModulePermissionModel} from "./modulePermissionModel";
import {TreeNode} from "primeng/api";

export class RoleModel{
  userId: number;

  roleName: string;
  permissions: any[];

  constructor(user, name, permissions: TreeNode[]) {
    this.userId = user;
    this.roleName = name;
    this.permissions = permissions;
  }
}
