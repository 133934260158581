import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppUserService } from '../../auth/appuser.service';
import { IDataService } from '../../shared/dataservice.interface';

@Component({
  selector: 'app-access-settings',
  templateUrl: './access-settings.component.html',
  styleUrls: ['./access-settings.component.css']
})
export class AccessSettingsComponent implements OnInit {

  dataService: IDataService;

  constructor(private router: Router, public entityService: AppUserService) { }

  ngOnInit(): void {
    this.entityService.detailActive = '';
    this.entityService.listActive = 'active';
  }

  onListSelected() {
    this.router.navigate(['settings', 'access']);
    this.entityService.detailActive = '';
    this.entityService.listActive = 'active';
  }

  onDetailSelected() {

  }

  onAddUser() {
    this.router.navigate(['settings', 'access', 'new']);
    this.entityService.detailActive = 'active';
    this.entityService.listActive = '';
  }



  onActivate(componentRef: Component) {
    this.dataService = componentRef as IDataService;
  }

}
