import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

import { Contact } from '../../shared/contact.model';
import { MemberService } from '../member.service';

@Component({
  selector: 'app-member-select',
  templateUrl: './member-select.component.html',
  styleUrls: ['./member-select.component.css'],
})
export class MemberSelectComponent implements OnInit, OnDestroy {

  subMember: Subscription;
  members: Contact[];
  isLoading = false;
  totalMembers: number = 1;
  searchString: string = '';

  constructor(private memberService: MemberService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.members = this.memberService.memberArray;
    this.totalMembers = this.memberService.totalMembers;

    if (!this.members?.length)
      this.isLoading = true;

    this.subMember = this.memberService.members.subscribe(m => {
      this.members = m;
      this.totalMembers = this.memberService.totalMembers;
      this.isLoading = false;
    });

    if (!this.members?.length)
      this.memberService.getMembers("", 20, 1);
  }

  ngOnDestroy() {
    this.subMember.unsubscribe();
  }

  onSubmit(form: NgForm) {
    this.searchString = form.value.searchMember;
    this.memberService.getMembers(this.searchString, 20, 1);
    //form.reset();
  }

  paginate(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    this.memberService.getMembers(this.searchString, 10, event.page + 1);
  }

  onSelected(c: Contact) {
    this.ref.close(c);
  }

}
