<div class="my-2">

  <div class="px-0">
    <div class="input-group mb-3">
      <input type="text" name="searchProduct" class="form-control input-lg" placeholder="Suchtext" [autofocus]="true"
             aria-label="Recipient's username" aria-describedby="basic-addon2" [(ngModel)]="searchString"
             (keydown.enter)="onRead()"/>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="onRead()">
          Suchen
        </button>
      </div>
    </div>
  </div>


  <p-table [value]="entities" selectionMode="single" [(selection)]="template" styleClass="p-datatable-striped"
           (onRowSelect)="onRowSelect($event)">
    <ng-template pTemplate="header">
      <tr>
        <th>Typ</th>
        <th>Text</th>
        <th>Kostenstelle</th>
        <th>Art</th>
        <th>MwSt.1</th>
        <th>MwSt.2</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-entity>
      <tr [pSelectableRow]="entity">
        <td>{{ globals.transactionTypes[entity.transactionType].name }}</td>
        <td>{{ entity.text }}</td>
        <td>{{ entity.costCenter }}</td>
        <td>{{ entity.isIncoming && entity.isOutgoing ? "Ein-/Ausgang" : entity.isIncoming ? "Eingang" : "Ausgang" }}</td>
        <td>{{ entity.taxRate1 > 0 ? entity.taxRate1 / 100 + '%' : '--' }}</td>
        <td>{{ entity.taxRate2 > 0 ? entity.taxRate2 / 100 + '%' : '--' }}</td>

      </tr>
    </ng-template>


  </p-table>

</div>
