import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Globals, KVP_GROUPS} from '../../globals';
import {Account} from '../../settings/accounts/account.model';
import {AccountService} from '../../settings/accounts/account.service';
import {KvpService} from '../../shared/kvp.service';
import {Transaction, TransactionType} from '../transaction.model';
import {TransactionService} from '../transaction.service';
import {BalanceData} from '../balanceData.model';
import {KvpSetting} from "../../shared/kvpsetting.model";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {
  TransactionTemplateSelectComponent
} from "../transaction-template/transaction-template-select/transaction-template-select.component";
import {TransactionTemplate} from "../transaction-template.model";

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css'],
  providers: [DialogService]
})
export class TransactionsComponent implements OnInit {

  items: Account[];
  origItems: Account[];
  destAccountId: number = 0;
  selectedItem: Account;
  searchString: string = '';

  accountSummary: BalanceData = new BalanceData();

  isLoading = true;

  rangeDates: Date[] = new Array();

  transactions: Transaction[] = new Array();
  selectedTransaction: Transaction = new Transaction();

  display: boolean = false;
  dialogTitle: string = '';
  editMode: boolean = false;
  taxRate1: number = 0.0;
  taxRate2: number = 0.0;
  taxAmount1: number = 0.0;
  taxAmount2: number = 0.0;

  secondVat: boolean = false;

  costCenters: any;

  amount: number = 0;

  transactionOption: any[];
  transactionType: any = false;

  transactionDate: Date = new Date();

  lastTransactionDate: number = 0;
  minDate: Date = new Date();

  ref: DynamicDialogRef | undefined;

  constructor(private router: Router,
              public entityService: AccountService,
              private transactionService: TransactionService,
              private confirmationService: ConfirmationService,
              private dialogService: DialogService,
              private messageService: MessageService,
              public globals: Globals,
              private kvpService: KvpService
  ) {
    this.transactionOption = [{label: 'Eingang', value: false}, {label: 'Ausgang', value: true}];
  }

  ngOnInit(): void {
    this.kvpService.readGroup(KVP_GROUPS.COST_CENTER).subscribe(resp => {

      if (resp.length > 0) {
        // for some reason we have to remove the group property
        this.costCenters = resp.map(({group, ...rest}) => rest);
      } else {
        this.costCenters = new Array();
      }
    });

    this.entityService.getItemsRaw('', 100, 0).subscribe(a => {
      this.origItems = a.body;
      this.isLoading = false;
      if (this.origItems.length > 0) {
        this.getAccountDetails();
        if (this.origItems.length == 1)
          this.onSelect(this.origItems[0]);
        else {
          let item = this.origItems.find(i => i.accountType === 'Bankkonto');
          if (item)
            this.onSelect(item);
          else
            this.onSelect(this.items[0]);
        }
      }
    });


    var someDate = new Date(); // add arguments as needed
    someDate.setTime(someDate.getTime() - 30 * 24 * 60 * 60 * 1000);
    this.rangeDates.push(someDate);
    this.rangeDates.push(new Date());
    this.rangeDates[0].setHours(0, 0, 0, 0); // next midnight
    this.rangeDates[1].setHours(23, 59, 59, 999); // next midnight
  }

  getAccountDetails() {
    this.origItems.forEach(a => {
      this.transactionService.getAccountSummary(a.id).subscribe(s => {
        a.currentBalance = s.balance;
      })
    })
  }

  onSelect(item) {

    this.items = this.origItems.filter(i => (i !== item));

    this.selectedItem = item;

    this.transactionService.getLastMonthEndClosingDate(this.selectedItem.id).subscribe(d => {
      this.lastTransactionDate = d;
      this.minDate = new Date(d * 1000);
    });


    this.readTransactions();

    this.transactionService.getAccountSummary(this.selectedItem.id).subscribe(r => {
      this.accountSummary = Object.assign(new BalanceData(), r);
      console.log(this.accountSummary);
    });
    /*
    if (this.selectedItem.accountType === 'Kassa') {
      this.router.navigate(['finance', 'cash-book']);
    }*/
  }


  readTransactions() {
    this.transactionService.search(this.searchString, this.rangeDates[0], this.rangeDates[1], this.selectedItem.id).subscribe(resp => {
      this.transactions = resp.slice();
      console.log(this.transactions);
    });
  }

  onNew(template: TransactionTemplate | null) {
    this.selectedTransaction = new Transaction();
    this.transactionType = true;
    if (template) {
      this.selectedTransaction.text = template.text;
      this.selectedTransaction.transactionType = template.transactionType;
      this.selectedTransaction.costCenter = template.costCenter;
      this.transactionType = !template.isIncoming;
      this.taxRate1 = template.taxRate1 / 100;
      this.taxRate2 = template.taxRate2 / 100;
      this.secondVat = template.taxRate2 > 0 && template.taxRate1 > 0 && template.taxRate1 != template.taxRate2;
    } else {
      this.secondVat = false;
      this.amount = 0;
      this.taxRate1 = 20;
      this.taxRate2 = 0;
      this.taxAmount1 = 0;
      this.taxAmount2 = 0;
    }
    this.dialogTitle = 'Transaktion hinzufügen';

    this.editMode = false;
    this.display = true;
  }

  onNewTemplate() {
    this.ref = this.dialogService.open(TransactionTemplateSelectComponent, {
      header: 'Vorlage auswählen',
      data: {inputOnly: false, outputOnly: false},
      width: '50%',
      height: '60%',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      maximizable: false
    });

    this.ref.onClose.subscribe((t: TransactionTemplate) => {
      if (t)
        this.onNew(t);
    });

  }

  onSearch() {
    this.readTransactions();
  }

  onEdit(t: Transaction) {
    console.log(t);
    this.selectedTransaction = t;
    this.transactionType = this.selectedTransaction.amount < 0;
    this.transactionDate = new Date(this.selectedTransaction.transactionDate2 * 1000);
    this.dialogTitle = 'Transaktion ändern';
    this.editMode = true;
    this.amount = this.selectedTransaction.amount / 100;
    this.taxRate1 = this.selectedTransaction.taxRate1 / 100;
    this.taxRate2 = this.selectedTransaction.taxRate2 / 100;
    this.taxAmount1 = this.selectedTransaction.taxAmount1 / 100;
    this.taxAmount2 = this.selectedTransaction.taxAmount2 / 100;
    this.calculateTax();
    this.display = true;
  }

  onSave() {

    if (this.transactionDate == null || this.transactionDate < this.minDate) {
      this.messageService.add({key: 'tl', severity: 'error', summary: 'Ungültiges Buchungsdatum.'});
      return;
    }

    this.display = false;
    this.selectedTransaction.amount = Math.round(this.amount * 100);
    this.selectedTransaction.taxRate1 = Math.round(this.taxRate1 * 100);
    this.selectedTransaction.taxRate2 = Math.round(this.taxRate2 * 100);
    this.selectedTransaction.taxAmount1 = Math.round(this.taxAmount1 * 100);
    this.selectedTransaction.taxAmount2 = Math.round(this.taxAmount2 * 100);
    this.selectedTransaction.transactionDate2 = Math.round(this.transactionDate.getTime() / 1000);

    if (this.editMode) {
      this.transactionService.putItem(this.selectedTransaction).subscribe(() => {
        this.messageService.add({key: 'tl', severity: 'info', summary: 'Buchung gespeichert.'});
        this.readTransactions();
      });
    } else if (this.selectedTransaction.transactionType == TransactionType.Rebooking) {
      this.onRebook();
    } else {

      if (!this.secondVat) {
        this.selectedTransaction.taxRate2 = 0;
        this.selectedTransaction.taxAmount2 = 0;
      }

      this.selectedTransaction.accountId = this.selectedItem.id;

      if (this.transactionType && this.selectedTransaction.amount > 0)
        this.selectedTransaction.amount *= -1;

      if (!this.transactionType && this.selectedTransaction.amount < 0)
        this.selectedTransaction.amount *= -1;

      if (!this.transactionType && this.selectedTransaction.taxAmount1 < 0)
        this.selectedTransaction.taxAmount1 *= -1;
      if (!this.transactionType && this.selectedTransaction.taxAmount2 < 0)
        this.selectedTransaction.taxAmount2 *= -1;

      if (this.selectedTransaction.text === "")
        this.selectedTransaction.text = this.globals.transactionTypeStrings[this.selectedTransaction.transactionType];

      console.log(this.selectedTransaction);
      this.transactionService.postItem(this.selectedTransaction).subscribe((e) => {
        this.readTransactions();
        this.messageService.add({key: 'tl', severity: 'info', summary: 'Neue Buchung erstellt.'});
      });

    }

  }

  onRebook() {
    if (this.destAccountId > 0 && this.selectedItem.id != this.destAccountId) {
      var t2 = new Transaction();
      t2.transactionType = TransactionType.Rebooking;

      if (this.selectedTransaction.text == "") {
        this.selectedTransaction.text = "Umbuchung";
      }
      t2.text = this.selectedTransaction.text;

      this.selectedTransaction.transactionDate2 = this.transactionDate.getTime() / 1000;
      t2.transactionDate2 = this.transactionDate.getTime() / 1000;

      this.selectedTransaction.accountId = this.selectedItem.id;
      t2.accountId = this.destAccountId;


      if ((this.transactionType && this.selectedTransaction.amount > 0) || (!this.transactionType && this.selectedTransaction.amount < 0))
        this.selectedTransaction.amount *= -1;
      t2.amount = this.selectedTransaction.amount * -1;

      t2.financialYearId = this.selectedTransaction.financialYearId;
      t2.costCenter = this.selectedTransaction.costCenter;

      this.transactionService.rebook(this.selectedTransaction, t2).subscribe(r => {
        this.messageService.add({key: 'tl', severity: 'info', summary: 'Neue Umbuchung erstellt.'});
      });

    }
  }

  isSalesTax(): boolean {
    return this.selectedTransaction.transactionType == TransactionType.Normal || this.selectedTransaction.transactionType == TransactionType.ManualCorrection;
  }

  onDelete(t: Transaction) {

    this.confirmationService.confirm({
      message: 'Möchten Sie diese Transaktion wirklich löschen?',

      header: 'Transaktion löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      rejectVisible: true,
      key: 'confirm',
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        this.transactionService.deleteItem(t.id).subscribe(() => {
          this.messageService.add({key: 'tl', severity: 'info', summary: 'Buchung gelöscht.'});
          this.readTransactions();
        });
      },
    });
  }

  calculateTax() {
    if (!this.secondVat) {
      var b = Math.abs(this.amount);
      var n = b - Math.abs(this.taxAmount1);
      this.taxRate1 = Math.round(b / n * 100) - 100;
    }
  }

  calculateSalesTax() {
    if (!this.secondVat) {
      this.taxAmount1 = Math.round(this.amount / (100 + this.taxRate1) * this.taxRate1 * 100) / 100;
    }
  }

  onInputAmount() {
    this.calculateSalesTax();
  }

  onInputTax() {
    this.calculateSalesTax();
  }

}
