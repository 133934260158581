<div class="row">
  <div class="col-md-12" [hidden]="activeIndex != 1">
    <div class="card">
      <br />

      <form (ngSubmit)="onSubmit(f)" #f="ngForm">
        <div class="px-4">
          <div class="input-group mb-3">
            <input type="text" name="search" class="form-control input-lg" placeholder="Suchtext"
              aria-label="Recipient's username" aria-describedby="basic-addon2" ngModel />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" [disabled]="!f.valid" type="submit">
                Suchen
              </button>
            </div>
          </div>
        </div>
      </form>

      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Nummer</th>
              <th scope="col">Type</th>
              <th scope="col">Zählerstand</th>
            </tr>
          </thead>
          <tbody class="table-users-body">
            <tr *ngFor="let gauge of gauges" style="cursor: pointer" (click)="onSelected(gauge)">
              <td class="table-users-id">{{ gauge.serial }}</td>
              <td class="table-users-name">{{ gauge.description }}</td>
              <td class="table-users-name">{{ gauge.installCount }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p-paginator class="my-paginator" [rows]="10" [totalRecords]="totalGauges" pageLinkSize="3"
        (onPageChange)="paginate($event)"></p-paginator>
    </div>
  </div>

  <div class="col-md-12" [hidden]="activeIndex != 0">
    <div class="card">
      <div class="card-header d-md-flex justify-content-between align-items-center">
        <h5 class="card-title">Alten Zählerstand erfassen</h5>
      </div>
      <div class="card-body card-sidebar-menu">
        <form #oldData="ngForm">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Ablesedatum</label>
                <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="true" [disabled]="firstCounter"
                  [locale]="globals.de" name="readingDate" [(ngModel)]="readingDate">
                </p-calendar>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>Zählerstand neu</label>
                <input type="text" name="newcounts" placeholder="" class="form-control" [readonly]="firstCounter"
                  ngModel />
              </div>
            </div>

            <div class="col-md-5">
              <div class="form-group">
                <a class="btn btn--primary mr-2 align-top" style="margin-top: 30px" (click)="onNext()">
                  <span>Weiter</span>
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M17,12L12,17V14H8V10H12V7L17,12M3,19V5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19M5,19H19V5H5V19Z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Seriennummer</label>
                <input type="text" name="serial" placeholder="" readonly="true" class="form-control" ngModel />
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>Bisheriger Zählerstand</label>
                <input type="text" name="counterCounts" placeholder="" readonly="true" class="form-control" ngModel />
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>Letztes Ablesedatum</label>
                <input type="text" name="lastReadingDate" placeholder="" readonly="true" class="form-control" ngModel />
              </div>
            </div>
          </div>

          <div class="row" *ngIf="firstCounter">
            <div class="col-md-12">
              <div class="text-center">
                <div class="alert alert-warning alert-dismissible fade show">
                  <strong>Schritt überspringen!</strong> Es ist noch kein Zähler
                  vorhanden.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="col-md-12" [hidden]="activeIndex != 2">
    <div class="card">
      <div class="card-header d-md-flex justify-content-between align-items-center">
        <h5 class="card-title">Neuer Zähler</h5>
      </div>
      <div class="card-body card-sidebar-menu">
        <form #newData="ngForm">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Seriennummer</label>
                <input type="text" name="serial" placeholder="" readonly="true" class="form-control" ngModel />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Einbaudatum</label>
                <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="true" [locale]="globals.de"
                  name="installDate" [(ngModel)]="installDate">
                </p-calendar>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>Zählerstand neu</label>
                <input type="text" name="newcounts" placeholder="" class="form-control" ngModel />
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <a class="btn btn--next mr-2 align-top" style="margin-top: 30px" (click)="onSave()">
                  <span style="margin-right: 1rem">Jetzt tauschen</span>
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M15 6.5V9H11V11H15V13.5L18.5 10L15 6.5M9 10.5L5.5 14L9 17.5V15H13V13H9V10.5Z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h6 class="card-title">Alter Zähler</h6>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Seriennummer</label>
                <input type="text" name="oldSerial" placeholder="" readonly="true" class="form-control" ngModel />
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>Bisheriger Zählerstand</label>
                <input type="text" name="oldCounterCounts" placeholder="" readonly="true" class="form-control"
                  ngModel />
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>Letztes Ablesedatum</label>
                <input type="text" name="lastReadingDate" placeholder="" readonly="true" class="form-control" ngModel />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="text-center">
                <div class="alert alert-warning alert-dismissible fade show">
                  <strong>Achtung!</strong> Ein Zählertausch kann nicht
                  rückgängig gemacht werden.
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="row" my-5>
  <div class="col-md-12">
    <br />
    <br />
    <p-steps class="my-steps" [model]="items" [activeIndex]="activeIndex" [readonly]="false"></p-steps>
  </div>
</div>
