<div class="mb-3 flex align-items-center justify-content-between">
  <span class="text-xl font-medium text-900">Abrechnungsarten</span>
  <button pButton pRipple label="Hinzufügen" icon="pi pi-plus" (click)="New()"></button>
</div>

<form (ngSubmit)="onSubmit(f)" #f="ngForm">
  <div class="px-0">
    <div class="input-group mb-3">
      <input type="text" name="searchWaterbillSettings" class="form-control input-lg" placeholder="Suchtext"
             aria-label="text" aria-describedby="basic-addon2" [(ngModel)]="searchString"/>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" [disabled]="!f.valid" type="submit">
          Suchen
        </button>
      </div>
    </div>
  </div>
</form>

<!--
<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Zahlungsart</th>
      </tr>
    </thead>
    <tbody class="table-users-body">
      <tr *ngFor="let item of items" style="cursor: pointer" (click)="onSelected(item)">
        <td class="table-settings-wert">{{ item.name }}</td>
        <td class="table-settings-edit">{{ item.paymentMethod }}</td>
      </tr>
    </tbody>
  </table>
</div>
  -->

<p-table [value]="items" selectionMode="single" [(selection)]="selectedItem" dataKey="id" responsiveLayout="scroll"
         (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped">
  <ng-template pTemplate="header">
    <tr>
      <th>Name</th>
      <th>Zahlungsart</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr [pSelectableRow]="item">
      <td class="table-users-id">{{ item.name }}</td>
      <td class="table-users-name">{{ item.paymentMethod }}</td>
    </tr>
  </ng-template>
</p-table>


<p-paginator class="my-paginator" [rows]="10" [totalRecords]="totalItems" pageLinkSize="3"
             (onPageChange)="paginate($event)"></p-paginator>
