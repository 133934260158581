import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { Contact } from 'src/app/shared/contact.model';
import { CustomerService } from '../customer.service';

@Component({
  selector: 'app-customer-select',
  templateUrl: './customer-select.component.html',
  styleUrls: ['./customer-select.component.css']
})
export class CustomerSelectComponent implements OnInit, OnDestroy {
  subCustomer: Subscription;
  customers: Contact[];
  totalCustomers: Number = 0;
  isLoading = false;
  searchString: string = '';

  constructor(
    private customerService: CustomerService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.customers = this.customerService.customerArray;
    this.totalCustomers = this.customerService.totalCustomers;

    if (!this.customers?.length) this.isLoading = true;

    this.subCustomer = this.customerService.customers.subscribe((m) => {
      this.customers = m;
      this.totalCustomers = this.customerService.totalCustomers;
      this.isLoading = false;
    });

    if (!this.customers?.length) this.customerService.getContacts('', 10, 1);
  }

  ngOnDestroy() {
    this.subCustomer.unsubscribe();
  }

  onSubmit(form: NgForm) {
    this.searchString = form.value.searchCustomer;
    this.customerService.getContacts(this.searchString, 20, 1);
    //form.reset();
  }

  onSelected(c: Contact) {
    this.ref.close({customer:c});
  }

  paginate(event) {
    this.customerService.getContacts(this.searchString, 10, event.page + 1);
  }
}
