import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {KvpService} from 'src/app/shared/kvp.service';
import {Globals} from 'src/app/globals';
import {UntypedFormControl, NgForm} from '@angular/forms';
import {OrganizationSettings} from '../../shared/organization-settings.model';
import {ObjectService} from '../../shared/object.service';
import {DmsService} from '../../services/dms/dms.service';
import {DomSanitizer} from '@angular/platform-browser';
import {MyDocument} from '../../shared/document.model';
import {MessageService} from 'primeng/api';
import {MailServerModel} from "../../shared/mail-server.model";
import {SendMessageService} from "../../shared/send-message.service";
import {DocDbService} from "../../shared/doc-db.service";
import {error} from "protractor";


@Component({
  selector: 'app-organization-settings',
  templateUrl: './organization-settings.component.html',
  styleUrls: ['./organization-settings.component.css']
})
export class OrganizationSettingsComponent implements OnInit {

  OrgQuery = {
    "selector": {
      "table": {
        "$eq": "OrganizationSettings"
      },
    },
    "limit": 100,
  };


  @ViewChild('FileSelectInputDialog') FileSelectInputDialog: ElementRef;

  settings: OrganizationSettings = new OrganizationSettings();

  mailServer: MailServerModel = new MailServerModel();

  selectedFile: File;
  imageName: string;
  imageSrc: any;

  password1: string = '';
  password2: string = '';

  badPwd: boolean;
  pwdRequired: boolean;

  active1: number = 0;

  @ViewChild('organizationForm') settingsForm: NgForm;

  constructor(private kvpService: KvpService,
              private globals: Globals,
              private objService: ObjectService,
              private messageService: MessageService,
              private sanitizer: DomSanitizer,
              private sendMessageService: SendMessageService,
              private dmsService: DmsService,
              private docDbService: DocDbService
  ) {
  }

  ngOnInit(): void {


    this.docDbService.queryItems<OrganizationSettings>(JSON.stringify(this.OrgQuery)).subscribe(resp => {
      if (resp && resp.length > 0) {
        this.settings = Object.assign(new OrganizationSettings(), resp[0]);
        console.log(this.settings);
        this.initForm();
      } else {
        this.docDbService.postItem(this.settings, this.settings.table).subscribe(resp => {
          this.settings = Object.assign(new OrganizationSettings(), resp[0]);
          this.initForm();
        });

      }
    });

    this.sendMessageService.getServerConfig().subscribe(resp => {
      this.mailServer = Object.assign(new MailServerModel(), resp);
    }, error => {
      this.mailServer = new MailServerModel();
    });


  }

  initForm() {
    if (this.settings.logoDocId > 0) {
      this.dmsService.readDocument(this.settings.logoDocId).subscribe((resp) => {
        if (resp != null && resp.path != null && resp.path != '') {
          this.dmsService.downloadDocument(resp.id).subscribe((r2) => {
            var downloadURL = URL.createObjectURL(r2.body);
            this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(downloadURL);
          }, () => {
            this.onRemoveImage();
          });
        } else {
          this.onRemoveImage();
        }
      });
    }
  }

  onSubmit() {
    if (this.settingsForm.dirty && !this.settingsForm.invalid) {


      if (this.settings.logoDocId == 0 && this.imageSrc != null) {
        var doc = new MyDocument();
        doc.name = "Organization-Logo";

        this.dmsService.addInternalDocument(doc, this.selectedFile).subscribe((resp) => {
          this.settings.logoDocId = resp.id;
          this.doSave();
        });
      } else if (this.settings.logoDocId != 0 && this.imageSrc == null) {
        this.dmsService.deleteInternalDocument(this.settings.logoDocId).subscribe((resp) => {
          this.settings.logoDocId = 0;
          this.doSave();
        });
      } else {
        this.doSave();
      }
    }

    this.doSaveMail();
  }

  doSaveMail() {
    this.sendMessageService.putServerConfig(this.mailServer).subscribe(resp => {
      this.messageService.add({key: 'tl', severity: 'info', summary: 'E-Mail Einstellungen wurden gespeichert.'});
    }, error => {
      this.messageService.add({key: 'tl', severity: 'error', summary: 'Fehler beim Speichern.'});
    });
  }

  doSave() {

    if (this.password1 != '' && this.password1 == this.password2) {
      this.mailServer.password = this.password1;
    }

    this.docDbService.putItem<OrganizationSettings>(this.settings, this.settings.table).subscribe(resp => {
      this.settings = resp;
      this.messageService.add({key: 'tl', severity: 'info', summary: 'Daten wurden gespeichert.'});
    }, error => {
      this.messageService.add({key: 'tl', severity: 'error', summary: 'Fehler beim Speichern.'});
      console.log(error);

    });

  }

  onRemoveImage() {
    console.log("remove image");
    this.imageSrc = null;
    this.settings.logoDocId = 0;
    this.markFormAsDirty();

  }

  markFormAsDirty() {
    for (var eachControl in this.settingsForm.controls) {
      (<UntypedFormControl>this.settingsForm.controls[eachControl]).markAsDirty();
    }
  }

  onSelectImage() {
    const elem: HTMLElement = this.FileSelectInputDialog.nativeElement;
    elem.click();
  }

  onFileSelected(e) {
    this.selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = (e: any) => {
      this.imageSrc = e.target.result;
      this.markFormAsDirty();
    };
    reader.readAsDataURL(this.selectedFile);
    //this.imageSrc = e.target.files[0];
  }

  onPasswordChange() {
    if (this.password1 != '' || this.password2 != '') {
      this.badPwd = this.password1 != this.password2;
    } else {
      this.badPwd = false;
    }
  }

  onTestEmail() {
    this.sendMessageService.testConnection().subscribe(resp => {
      this.messageService.add({
        key: 'tl',
        severity: 'info',
        summary: 'E-Mail wurde versendet. Prüfen Sie Ihr Postfach.'
      });
    });
  }
}
