<div class="mb-3 flex align-items-center justify-content-between">
  <span class="text-xl font-medium text-900">Ausgangsrechnungen</span>
  <div>
    
  </div>
  <button pButton pRipple label="Hinzufügen" icon="pi pi-plus" (click)="New()"></button>
</div>


<form (ngSubmit)="onSubmit(f)" #f="ngForm">
  <div class="">
    <div class="input-group mb-3">
      <input type="text" name="search" class="form-control input-lg" placeholder="Suchtext"
             autocomplete="off"
             aria-label="Recipient's username" aria-describedby="basic-addon2" ngModel/>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" [disabled]="!f.valid" type="submit">
          Suchen
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label>Datum von</label>
          <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="true" [locale]="globals.de"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="invoiceService.searchParam.from">
          </p-calendar>
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label>bis</label>
          <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="true" [locale]="globals.de"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="invoiceService.searchParam.to">
          </p-calendar>
        </div>
      </div>

      <div class="col-md-2 col-btn">
        <div class="form-check">

          <input type="checkbox" class="form-check-input" name="booked"
                 [(ngModel)]="invoiceService.searchParam.isBooked" id="3">
          <label class="form-check-label" for="3">Gebucht</label>
        </div>

      </div>

      <div class="col-md-2 col-btn">
        <div class="form-check">

          <input type="checkbox" class="form-check-input" name="payed" [(ngModel)]="invoiceService.searchParam.isPayed"
                 id="4">
          <label class="form-check-label" for="4">Bezahlt</label>
        </div>

      </div>


    </div>


  </div>
</form>

<br/>


<p-table [value]="invoices" selectionMode="single" [(selection)]="selectedInvoice" dataKey="id"
         responsiveLayout="scroll"
         (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped">
  <ng-template pTemplate="header">
    <tr>
      <th>Re-Nr.</th>
      <th>Re-Dat.</th>
      <th>Kunde</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-invoice>
    <tr [pSelectableRow]="invoice">
      <td class="table-users-id">{{ invoice.invoiceID }}</td>
      <td class="table-users-id">{{ invoice.invoiceDate2 * 1000 | customDate }}</td>
      <td class="table-users-name">
        {{
          invoice.orgName || invoice.firstName ?
            invoice.orgName
              ? invoice.orgName
              : invoice.firstName + ((invoice.firstName && invoice.lastName) ? " " : "") + invoice.lastName
            : invoice.completeAddress
        }}
      </td>
    </tr>
  </ng-template>
</p-table>

<p-paginator class="my-paginator" [rows]="15" [totalRecords]="totalInvoices" pageLinkSize="3"
             (onPageChange)="paginate($event)"></p-paginator>
