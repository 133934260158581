export class Attachment {
  id: number;

  tablePK: string;
  pk: number;
  documentId: number;
  name: string;

  constructor() {
    this.id = 0;

    this.tablePK = "";
    this.pk = 0;
    this.documentId = 0;
    this.name = "";
  }
}
