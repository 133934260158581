<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-dialog [(visible)]="display" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <div class="flex align-items-center">
            <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style="width:32px;height:32px">
                <i class="pi pi-info text-lg"></i>
            </span>
            <span class="font-medium text-2xl text-900">Bearbeiten</span>
        </div>
    </ng-template>

    <div class="grid formgrid p-fluid">

        <div class="field mb-3 col-6">
            <label for="name" class="font-medium text-900">Bezeichung</label>
            <input id="name" type="text" pInputText [(ngModel)]="selectedEntity.name" autocomplete="off">
        </div>

        <div class="field mb-3 col-6">
            <label for="name" class="font-medium text-900">Tabelle</label>
            <input id="name" type="text" pInputText [(ngModel)]="selectedEntity.targetTable" readonly (focus)="false">
        </div>

        <div class="field mb-3 col-12 md:col-6">
            <label for="nr_next" class="font-medium text-900">Nächste Nummer</label>
            <p-inputNumber id="nr_next" [(ngModel)]="selectedEntity.nextNumber"></p-inputNumber>
        </div>

        <div class="field mb-3 col-12 md:col-3">
            <label for="nr_digit" class="font-medium text-900">Anzahl Stellen</label>
            <p-inputNumber id="nr_digit" [(ngModel)]="selectedEntity.digits"></p-inputNumber>
        </div>
        <div class="field mb-3 col-12 md:col-3 px-5">
            <label for="nr_pad" class="font-medium text-900"> </label>
            <div class="flex align-items-center py-2">
                <p-checkbox inputId="privacy" [binary]="true" [(ngModel)]="selectedEntity.zeroPadding"></p-checkbox>
                <span class="ml-2 text-900">Mit "0" auffüllen</span>
            </div>
        </div>

        <div class="field mb-3 col-12 md:col-6">
            <label for="nr_prefix" class="font-medium text-900">Prefix</label>
            <input id="nr_prefix" type="text" pInputText [(ngModel)]="selectedEntity.prefix">
        </div>
        <div class="field mb-3 col-12 md:col-6">
            <label for="nr_postfix" class="font-medium text-900">Postfix</label>
            <input id="nr_postfix" type="text" pInputText [(ngModel)]="selectedEntity.postfix">
        </div>


    </div>

    <ng-template pTemplate="footer">
        <div class=" surface-border">
            <button pButton pRipple icon="pi pi-times" (click)="display = false" label="Abbrechen"></button>
            <button pButton pRipple icon="pi pi-check" (click)="onSave()" label="Speichern"></button>
        </div>
    </ng-template>
</p-dialog>

<div class="surface-card p-4 shadow-2 border-round">
    <div class="mb-3 flex align-items-center justify-content-between">
        <span class="text-xl font-medium text-900">Nummernkreise</span>
    </div>

    <p-table [value]="entities" selectionMode="single" [(selection)]="selectedEntity" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Tabelle</th>
                <th>Freie Nummer</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-entity>
            <tr>
                <td>{{entity.id}}</td>
                <td>{{entity.name}}</td>
                <td>{{entity.targetTable}}</td>
                <td>{{entity.nextNumber}}</td>
                <td style="text-align:right;">
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="onEdit(entity)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>

</div>
