import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Globals } from '../../../globals';
import { KvpService } from '../../../shared/kvp.service';
import { Task } from '../../../shared/task.model';
import { TasksService } from '../tasks.service';


@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css']
})
export class TaskListComponent implements OnInit {

  isLoading: boolean;
  
  searchString: string = "";
  items: Task[];
  selectedItem: Task;
  totalItems: number;

  constructor(private kvpService: KvpService, private globals: Globals, private router: Router, private tasks: TasksService) { }

  ngOnInit(): void {
    this.tasks.itemList.subscribe(d => {
      this.items = d;
      this.isLoading = false;
      this.totalItems = this.tasks.totalItems;
    });

    if (!this.items?.length) {
      this.tasks.getItems('', 10, 1);
    }
    else {
      this.items = this.tasks.itemArray;
      this.totalItems = this.tasks.totalItems;
    }
  }

  onSubmit(form: NgForm) {
    this.searchString = form.value.search_task;
    this.tasks.getItems(this.searchString, 10, 1);
  }

  onAddItem() {
    this.router.navigate(['services', 'tasks', 'new']);
  }

  
  onSelected2(event) {
    this.tasks.selectedItem = this.selectedItem;
    this.router.navigate(['services', 'tasks', this.selectedItem.id]);
  }

  paginate(event) {
    this.tasks.getItems(this.searchString, 10, event.page + 1);
  }

  New() {
    this.onAddItem();
  }
}
