<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="mb-3 flex align-items-center justify-content-between">
    <div>
        <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
        <span class="text-xl font-medium text-900 mx-3">Ticket bearbeiten</span>
    </div>
    <div>
        <button *ngIf="editMode" pButton pRipple label="Löschen" icon="pi pi-trash" (click)="Delete()" class="mx-3"></button>
        <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="Save()"></button>
    </div>
</div>

<form #f="ngForm">
    <div class="row">

        <div class="col-md-8">
            <div class="form-group">
                <label>Betreff</label>
                <input type="text" name="shortName" class="form-control" [(ngModel)]="entity.ticket_subject" required autocomplete="off"
                       placeholder="Um was geht es?" />
            </div>

        </div>
        <div class="col-md-4">
            <label>Kategorie</label>
            <p-dropdown [options]="entityService.TicketCategory" name="category" [(ngModel)]="entity.ticket_category"></p-dropdown>
        </div>

        <div class="col-md-12">
            <div class="form-group">

                <label>Beschreibung</label>
                <textarea name="description" rows="5" pInputTextarea [(ngModel)]="entity.ticket_description" style="height: 200px; width:100% !important;" [autoResize]="true"
                          placeholder="Geben Sie hier eine genaue Beschreibung Ihres Problems ein."></textarea>
            </div>
        </div>


    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label>Erstellt</label>

                <input type="text" name="created" class="form-control" [value]="entity.created_date | customDate" required autocomplete="off" readonly [autofocus]="false" />
            </div>
        </div>



        <div class="col-md-6">
            <label>Status</label>
            <input type="text" name="status" class="form-control" [(ngModel)]="entity.ticket_status" required autocomplete="off" readonly [autofocus]="false" />
        </div>

    </div>





</form>

<br>

<div class="surface-card p-4 pt-2 my-2 shadow-2 border-round p-fluid" >
  <span class="text-xl font-medium text-600 ">Nachrichten</span>
<div class="surface-card border-round p-fluid"  *ngFor="let ticket of entity.messages">

  <div class="grid formgrid p-fluid py-2">
    <div class="field col-12 md:col-6">
      <label for="name">Von: {{ticket.name}}</label>
    </div>
    <div class="field col-12 md:col-6">
      <label for="name">Erstellt {{ticket.created_date | customDate}}</label>
    </div>

      <textarea  rows="5" type="text" pInputTextarea [(ngModel)]="ticket.message" style="height: 100px; width:100% !important;"></textarea>

  </div>
</div>
</div>

