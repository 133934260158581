
export class BalanceData {
  accountId: number = 0;
  finanicalYearId: number = 0;   // Reference to financial year
  financialYear: number;
  startBalance: number = 0;
  balance: number = 0;

  constructor() {

  }
}
