import {Component, OnInit} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Globals, KVP_GROUPS} from '../../globals';
import {Account} from '../../settings/accounts/account.model';
import {AccountService} from '../../settings/accounts/account.service';
import {Transaction, TransactionBalancingViewModel, TransactionType} from '../transaction.model';
import {Invoice, InvoiceSearchParam} from '../../jobs/billing/invoice.model';
import {InvoiceService} from '../../jobs/billing/invoice.service';
import {TransactionService} from "../transaction.service";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {
  TransactionTemplateSelectComponent
} from "../transaction-template/transaction-template-select/transaction-template-select.component";
import {TransactionTemplate} from "../transaction-template.model";
import {KvpService} from "../../shared/kvp.service";
import {OpenInvoicesSelectComponent} from "../open-invoices/open-invoices-select/open-invoices-select.component";

@Component({
  selector: 'app-balancing',
  templateUrl: './balancing.component.html',
  styleUrls: ['./balancing.component.css'],
  providers: [DialogService, ConfirmationService]
})
export class BalancingComponent implements OnInit {
  isLoading: boolean = true;

  items: Account[];
  selectedItem: Account;

  debitSearchParam: InvoiceSearchParam;
  billingSearchParam: InvoiceSearchParam;
  debit: Invoice[];
  billing: Invoice[];
  billingLoaded: boolean = false;
  debitLoaded: boolean = false;

  templates: TransactionTemplate[] = [];

  delimiter: string = ";";

  useTab: boolean = false;

  csvMap: number[] = [0, 0, 0, 0, 0];

  ref: DynamicDialogRef | undefined;

  dialogTitle: string = '';
  display: boolean = false;
  selectedTransaction: Transaction = new Transaction();
  editMode: boolean = false;
  costCenters: any;
  transactionOption: any[];
  transactionType: any = false;
  secondVat: boolean = false;
  amount: number = 0.0;
  destAccountId: number = 0;

  currentViewModel: TransactionBalancingViewModel;

  constructor(public entityService: AccountService,
              public invoiceService: InvoiceService,
              public transactionService: TransactionService,
              public globals: Globals,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private kvpService: KvpService,
              private dialogService: DialogService,) {

    this.transactionOption = [{label: 'Eingang', value: false}, {label: 'Ausgang', value: true}];

    this.debitSearchParam = new InvoiceSearchParam();
    this.debitSearchParam.isDebit = true;
    this.debitSearchParam.isOpen = true;
    this.debitSearchParam.isPrinted = true;
    this.debitSearchParam.isBooked = true;

    this.billingSearchParam = new InvoiceSearchParam();
    this.billingSearchParam.isDebit = false;
    this.billingSearchParam.isOpen = true;
    this.billingSearchParam.isPrinted = true;
    this.billingSearchParam.isBooked = true;

    this.debit = new Array();
    this.billing = new Array();
  }

  ngOnInit(): void {

    this.kvpService.readGroup(KVP_GROUPS.COST_CENTER).subscribe(resp => {

      if (resp.length > 0) {
        // for some reason we have to remove the group property
        this.costCenters = resp.map(({group, ...rest}) => rest);
      } else {
        this.costCenters = [];
      }
    });

    this.entityService.getItemsRaw('', 100, 0).subscribe(a => {
      console.log(a);
      this.items = a.body;
      this.isLoading = false;
      this.selectedItem = this.items.length > 0 ? this.items[0] : new Account();
      this.createImportMap();
    });

    this.invoiceService.readInvoices(100, 0, this.debitSearchParam).subscribe(resp => {
      this.debit = resp.body.slice();
      this.debitLoaded = true;
    });

    this.invoiceService.readInvoices(100, 0, this.billingSearchParam).subscribe(resp => {
      this.billing = resp.body.slice();
      this.billingLoaded = true;
    });

    this.readTemplates();

  }

  readTemplates() {
    this.transactionService.getTemplates().subscribe(resp => {
      this.templates = resp;
    });
  }

  onSelect() {
    this.createImportMap();
  }

  createImportMap() {
    var s = this.selectedItem.csvImportMap.split(';');
    this.csvMap = new Array();
    s.forEach(s => {
      if (s.length > 0)
        this.csvMap.push(Number.parseInt(s));
    });
    for (var i = this.csvMap.length; i < 5; ++i) {
      this.csvMap.push(-1);
    }
  }

  onImport() {
    this.transactionService.isBalancingStart = false;
    this.importBookings();
  }

  Back() {
    this.confirmationService.confirm({
      message: 'Möchten Sie wirklich zum Import zurückgehen? <br><br>Nicht gespeicherte Buchungen gehen verloren, bereits gespeicherte werden nicht rückgängig gemacht!',

      header: 'Zurück zum Import?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      rejectVisible: true,
      key: 'confirm',
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        this.transactionService.isBalancingStart = true;

      },
    });

  }

  Save() {
  }

  importBookings() {
    const del = this.useTab ? '\t' : this.delimiter;
    const lines: string[] = this.transactionService.balancingImportText.split('\n');

    let im: Transaction[] = new Array();

    console.log(new Date(this.selectedItem.completedUntil * 1000));

    if (lines) {
      for (let i = 0; i < lines.length; ++i) {
        if (lines[i].length > 0) {
          const fields = lines[i].split(del);

          let t = new TransactionBalancingViewModel();

          t.transactionDate2 = this.getTransactionDate(fields);

          t.isValidDate = t.transactionDate2 > this.selectedItem.completedUntil;

          t.importText = this.getTransactionText(fields);
          t.text = this.getTransactionText(fields);


          t.amount = this.getAmount(fields);
          t.iban = this.extractIBAN(t.importText);
          t.bic = '';

          if (t.importText.toLowerCase().includes("zinsen")) {
            t.transactionType = TransactionType.Interest;
          }

          if (t.importText.toLowerCase().includes("finanzamt")) {
            t.transactionType = TransactionType.TaxOffice;
          }

          if (t.importText.toLowerCase().includes("kontoführung") || t.importText.toLowerCase().includes("entgelt") || t.importText.toLowerCase().includes("gebühr") || t.importText.toLowerCase().includes("gebuehr")) {
            t.transactionType = TransactionType.Fee;
          }

          var inv = this.findInvoice(t);
          if (inv != null) {
            this.setInvoice(t, inv);
          }

          let template = this.findTemplate(t.text);
          if (template) {
            this.setTemplate(t, template);
          }

          if (!t.isValidDate) {
            t.text = "Buchungsdatum liegt vor dem letzten Buchungsabschluss!";
          }
          im.push(t);
        }
      }
    }

    this.transactionService.importItems = im;
    console.log(this.transactionService.importItems);
  }

  findTemplate(text: string): TransactionTemplate {
    for (const t of this.templates) {
      var pattern = t.tags?.split(';');  // split by semicolon
      if (pattern && pattern.length > 0 && this.containsPattern(text, pattern)) {
        return t;
      }
    }

    return null;
  }

  containsPattern(source: string, patterns: string[]): boolean {
    // Convert the source string to lowercase for case-insensitive comparison
    const lowerCaseSource = source.toLowerCase();

    // Check each pattern in the array
    for (const pattern of patterns) {
      // Convert the pattern to lowercase for case-insensitive comparison
      if (pattern.length > 0 && lowerCaseSource.includes(pattern.toLowerCase())) {
        return true; // Return true if a pattern is found in the source string
      }
    }

    return false; // Return false if no patterns are found in the source string
  }

  setTemplate(t: TransactionBalancingViewModel, template: TransactionTemplate) {
    t.transactionTemplateId = template.id;
    t.infoText = 'Vorlage: ' + template.text;
    t.transactionType = template.transactionType;
    t.costCenter = template.costCenter;
    t.taxRate1 = template.taxRate1;
    t.taxRate2 = template.taxRate2;

    if (t.taxRate1 > 0 && t.taxRate2 > 0) {
      t.taxSum = 0;
      t.taxAmount1 = 0.0;
      t.taxAmount2 = 0.0;
    } else if (t.taxRate1 > 0) {
      var tax = 1 + t.taxRate1 / 10000;
      var net = Math.round(t.amount / tax * 100) / 100;
      t.taxAmount1 = Math.round((t.amount - net) * 100) / 100;
      t.taxSum = t.taxAmount1;
    } else if (t.taxRate2 > 0) {
      var tax = 1 + t.taxRate2 / 10000;
      var net = Math.round(t.amount / tax * 100) / 100;
      t.taxAmount2 = Math.round((t.amount - net) * 100) / 100;
      t.taxSum = t.taxAmount2;
    }
  }

  findInvoice(t: Transaction): Invoice {

    var list = t.amount < 0 ? this.debit : this.billing;

    for (const inv of list) {
      if (t.importText.toLowerCase().includes(inv.invoiceID.toLowerCase()))
        return inv;
    }

    return null;
  }

  setInvoice(t: TransactionBalancingViewModel, inv: Invoice) {
    t.transactionType = TransactionType.Normal;
    t.invoiceId = inv.id;
    t.invoiceNumber = inv.invoiceID;

    t.taxRate1 = inv.taxRate1;
    t.taxRate2 = inv.taxRate2;
    t.taxAmount1 = inv.taxAmount1;
    t.taxAmount2 = inv.taxAmount2;

    t.invoiceTotal = inv.total;

    t.infoText = 'OP: ' + inv.invoiceID;
    if (t.amount != inv.total) {
      t.infoText += ' (€' + inv.total.toFixed(2) + ')';
      let p = 1 - t.amount / inv.total;
      t.taxAmount1 = Math.round(t.taxAmount1 - t.taxAmount1 * p);
      t.taxAmount2 = Math.round(t.taxAmount2 - t.taxAmount2 * p);
    }

    t.taxSum = (t.taxAmount1 + t.taxAmount2) / 100.0;
  }

  getTransactionDate(fields: string[]): number {

    if (this.csvMap[0] > 0 && fields.length > this.csvMap[0] - 1) {
      return this.globals.parseDate(fields[this.csvMap[0] - 1], "dd-mm-yyyy").getTime() / 1000;
    }
    return new Date().getTime() / 1000;
  }

  getTransactionText(fields: string[]): string {

    if (this.csvMap[1] > 0 && fields.length > this.csvMap[1] - 1) {
      let txt = fields[this.csvMap[1] - 1];
      txt = txt.replace('"', '');
      return txt;
    }
    return "";
  }

  getAmount(fields: string[]): number {
    if (this.csvMap[2] > 0 && fields.length > this.csvMap[2] - 1) {
      let n = Number.parseFloat(fields[this.csvMap[2] - 1].toString().replace(',', '.'));
      return n;
    }
    return 0;
  }

  extractIBAN(inputString): string {
    const ibanPattern = /(AT)\d{18}|(DE)\d{20}/;
    const matches = inputString.match(ibanPattern);
    return matches ? matches[0] : "";
  }

  extractBIC(inputString): string {
    //const bicPattern = /[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?/;
    var bicPattern = /[A-Za-z]{4}[A-Za-z]{2}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?/;


    const matches = inputString.match(bicPattern);
    return matches ? matches[0] : "";
  }

  onDelete(t: TransactionBalancingViewModel) {

    this.confirmationService.confirm({
      target: event.target as EventTarget,
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      message: 'Möchten Sie diese Buchungszeile löschen?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const index = this.transactionService.importItems.indexOf(t);
        if (index > -1) {
          this.transactionService.importItems.splice(index, 1);
        }
      }
    });


  }

  onLink(t: TransactionBalancingViewModel) {
    this.ref = this.dialogService.open(OpenInvoicesSelectComponent, {
      header: 'Offenen Posten auswählen',
      data: {inputOnly: t.amount < 0, outputOnly: t.amount > 0},
      width: '50%',
      height: '60%',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      maximizable: false
    });

    this.ref.onClose.subscribe((inv: Invoice) => {
      if (inv) {
        this.setInvoice(t, inv);
      }

    });
  }

  onAddTemplate(t: TransactionBalancingViewModel) {

    this.ref = this.dialogService.open(TransactionTemplateSelectComponent, {
      header: 'Vorlage auswählen',
      data: {inputOnly: t.amount > 0, outputOnly: t.amount < 0},
      width: '50%',
      height: '60%',
      contentStyle: {overflow: 'auto'},
      baseZIndex: 10000,
      maximizable: false
    });

    this.ref.onClose.subscribe((template: TransactionTemplate) => {
      if (t) {
        this.setTemplate(t, template);
      }

    });
  }

  onResolveLink(item: TransactionBalancingViewModel) {
    item.invoiceId = 0;
    item.transactionTemplateId = 0;
    item.infoText = '';
    item.invoiceNumber = '';
    item.transactionType = TransactionType.Normal;
  }

  onAddTransaction(item: TransactionBalancingViewModel) {
    this.display = true;
    this.currentViewModel = item;
    this.selectedTransaction = new Transaction();
    this.selectedTransaction.amount = item.amount;
    this.selectedTransaction.text = item.text;
    this.amount = item.amount;
    this.selectedTransaction.transactionDate2 = item.transactionDate2;
    this.transactionType = this.amount < 0;
  }

  onInputAmount() {

  }

  isSalesTax() {
    return true;
  }

  onInputTax() {

  }

  onSave() {
    this.display = false;

    const index = this.transactionService.importItems.indexOf(this.currentViewModel);
    if (index > -1) {
      this.transactionService.importItems.splice(index, 1);
    }
  }

  onConfirm(item: TransactionBalancingViewModel) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      message: 'Möchten Sie diese Buchungszeile buchen?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onBookEntry(item);
      }
    });
  }

  onConfirmAll() {
    this.confirmationService.confirm({
      message: 'Möchten Sie wirklich alle erkannten Einträge aus der Liste buchen?',

      header: 'Alle buchen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      rejectVisible: true,
      key: 'confirm',
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        this.transactionService.importItems.filter(t => t.isKnownTransaction()).forEach(item => {
          this.onBookEntry(item);
        });

      },
    });
  }

  onBookEntry(item: TransactionBalancingViewModel | Transaction) {
    var t = this.assignOnlyExisting<Transaction>(new Transaction(), item);
    t.amount = Math.round(t.amount * 100);
    t.taxAmount1 = Math.round(t.taxAmount1 * 100);
    t.taxAmount2 = Math.round(t.taxAmount2 * 100);
    t.accountId = this.selectedItem.id;

    this.transactionService.postItem(t).subscribe(resp => {
      this.messageService.add({key: 'tl', severity: 'info', summary: 'Buchung wurde verbucht.'});
      const index = this.transactionService.importItems.indexOf(item);
      if (index > -1) {
        this.transactionService.importItems.splice(index, 1);
      }
    }, error => {
      this.messageService.add({key: 'tl', severity: 'error', summary: 'Buchung konnte nicht verbucht werden.'});
    });
  }


  assignOnlyExisting<T>(target: T, source: Partial<T>): T {
    Object.keys(target).forEach(key => {
      if (source.hasOwnProperty(key)) {
        (target as any)[key] = (source as any)[key];
      }
    });
    return target;
  }

}

