<div class="min-h-screen flex surface-ground">
  <div id="app-sidebar"
       class="h-screen hidden lg:block flex-shrink-0 fixed lg:sticky left-0 top-0 z-1 border-right-1 border-top-1 surface-border w-full md:w-auto select-none fixed">


    <div class="flex flex-column h-full">

      <div class="flex flex-column surface-0 py-2 overflow-y-auto flex-shrink-0 flex-grow-1 md:flex-grow-0"
           style="width:280px">
        <div class="overflow-y-auto">
          <ul class="list-none p-2 m-0">
            <li>
              <div pRipple class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                   pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
                   leaveActiveClass="slideup">
                <span class="font-medium">ALLGEMEINES</span>
                <i class="pi pi-chevron-down"></i>
              </div>
              <ul class="list-none p-0 m-0 overflow-hidden">
                <li>
                  <a pRipple
                     class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                     [routerLink]="['/settings/organization']" routerLinkActive="settings-item-selected">
                    <i class="pi pi-home mr-2"></i>
                    <span class="font-medium">Organisation</span>
                  </a>
                </li>

                <li>
                  <a pRipple
                     class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                     [routerLink]="['/settings/paymentterms']" routerLinkActive="settings-item-selected">
                    <i class="pi pi-money-bill mr-2"></i>
                    <span class="font-medium">Zahlungsbedingungen</span>
                  </a>
                </li>
                <li>
                  <a pRipple
                     class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                     [routerLink]="['/settings/accounts']" routerLinkActive="settings-item-selected">
                    <i class="pi pi-wallet mr-2"></i>
                    <span class="font-medium">Konten</span>
                  </a>
                </li>
                <li>
                  <a pRipple
                     class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                     [routerLink]="['/settings/financial-years']" routerLinkActive="settings-item-selected">
                    <i class="pi pi-calendar mr-2"></i>
                    <span class="font-medium">Wirtschaftsjahre</span>
                  </a>
                </li>
                <li>
                  <a pRipple
                     class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                     [routerLink]="['/settings/waterbillsettings']" routerLinkActive="settings-item-selected">
                    <i class="pi pi-book mr-2"></i>
                    <span class="font-medium">Wasserabrechnungsarten</span>
                  </a>
                </li>
                <li>
                  <a pRipple
                     class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                     [routerLink]="['/settings/numberrange']" routerLinkActive="settings-item-selected">
                    <i class="pi pi-sort-numeric-down mr-2"></i>
                    <span class="font-medium">Nummernkreise</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>


          <ul class="list-none p-2 m-0 border-top-1 surface-border">
            <li>
              <div pRipple class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                   pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
                   leaveActiveClass="slideup">
                <span class="font-medium">ZUGANGSDATEN</span>
                <i class="pi pi-chevron-down"></i>
              </div>
              <ul class="list-none p-0 m-0 overflow-hidden hidden">

                <li>
                  <a pRipple
                     class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                     [routerLink]="['/settings/roles']" routerLinkActive="settings-item-selected">
                    <i class="pi pi-id-card mr-2"></i>
                    <span class="font-medium">Organe/Rollen</span>
                  </a>
                </li>
                <li *ngIf="globals.currentApp.isAdmin">
                  <a pRipple
                     class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                     [routerLink]="['/settings/access']" routerLinkActive="settings-item-selected">
                    <i class="pi pi-users mr-2"></i>
                    <span class="font-medium">Benutzer</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <ul class="list-none p-2 m-0 border-top-1 surface-border">

            <li>
              <div pRipple class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                   pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
                   leaveActiveClass="slideup">
                <span class="font-medium">LISTEN</span>
                <i class="pi pi-chevron-down"></i>
              </div>
              <ul class="list-none p-0 m-0 overflow-hidden hidden">


                <li>
                  <a pRipple
                     class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                     [routerLink]="['/settings/productgroups']" routerLinkActive="settings-item-selected">
                    <i class="pi pi-list mr-2"></i>
                    <span class="font-medium">Produktgruppen</span>
                  </a>
                </li>
                <li>
                  <a pRipple
                     class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                     [routerLink]="['/settings/needsunit']" routerLinkActive="settings-item-selected">
                    <i class="pi pi-list mr-2"></i>
                    <span class="font-medium">Bedarfseinheiten</span>
                  </a>
                </li>
                <li>
                  <a pRipple
                     class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                     [routerLink]="['/settings/doctypes']" routerLinkActive="settings-item-selected">
                    <i class="pi pi-list mr-2"></i>
                    <span class="font-medium">Dokumententypen</span>
                  </a>
                </li>
                <li>
                  <a pRipple
                     class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                     [routerLink]="['/settings/equtypes']" routerLinkActive="settings-item-selected">
                    <i class="pi pi-list mr-2"></i>
                    <span class="font-medium">Anlagearten</span>
                  </a>
                </li>
                <li>
                  <a pRipple
                     class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                     [routerLink]="['/settings/tags']" routerLinkActive="settings-item-selected">
                    <i class="pi pi-list mr-2"></i>
                    <span class="font-medium">Tags (Kennzeichen)</span>
                  </a>
                </li>
                <li>
                  <a pRipple
                     class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                     [routerLink]="['/settings/paymentmethods']" routerLinkActive="settings-item-selected">
                    <i class="pi pi-list mr-2"></i>
                    <span class="font-medium">Zahlungsarten</span>
                  </a>
                </li>
                <li>
                  <a pRipple
                     class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                     [routerLink]="['/settings/costcenter']" routerLinkActive="settings-item-selected">
                    <i class="pi pi-list mr-2"></i>
                    <span class="font-medium">Kostenstellen</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>


          <ul class="list-none p-2 m-0 border-top-1 surface-border">
            <li>

              <div pRipple class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer"
                   pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown" leaveToClass="hidden"
                   leaveActiveClass="slideup">
                <span class="font-medium">WERKZEUGE</span>
                <i class="pi pi-chevron-down"></i>
              </div>
              <ul class="list-none p-0 m-0 overflow-hidden hidden">
                <li>
                  <a pRipple
                     class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                     [routerLink]="['/settings/import']" routerLinkActive="settings-item-selected">
                    <i class="pi pi-upload mr-2"></i>
                    <span class="font-medium">Daten importieren</span>
                  </a>
                </li>

                <li>
                  <a pRipple
                     class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                     [routerLink]="['/settings/backup']" routerLinkActive="settings-item-selected">
                    <i class="pi pi-database mr-2"></i>
                    <span class="font-medium">Datensicherung</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>


      </div>
    </div>
  </div>

  <div id="sidebar-panel" class="min-h-screen flex flex-column relative flex-auto pl-2">


    <router-outlet></router-outlet>


  </div>

</div>
