
<div class="card">

  <br />

  <form (ngSubmit)="onSubmit(form)" #form="ngForm">
    <div class="card-body card-sidebar-menu">
      <div class="row">
        <div class="col-md-12">

          <div class="input-group mb-3">
            <input type="text" name="search_dms" placeholder="Suchtext" class="form-control" [(ngModel)]="searchString" />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary"
                      [disabled]="!form.valid"
                      type="submit">
                Suchen
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Dokumententyp </label><br />
            <p-dropdown [options]="categories" optionLabel="key" [(ngModel)]="selectedCategory" name="searchCategory"></p-dropdown>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label>Tags</label>
            <input type="text" name="tags-dms" placeholder="" class="form-control" [(ngModel)]="searchTags" />
          </div>
        </div>
      </div>




    </div>
  </form>

  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Type</th>
        </tr>
      </thead>
      <tbody class="table-users-body">
        <tr *ngFor="let doc of documents"
            style="cursor: pointer"
            [class.highlighted]="doc === selectedDocument"
            (click)="onSelected(doc)">
          <td class="table-users-name">{{ doc.name }}</td>
          <td class="table-users-name">{{ doc.docType }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <p-paginator class="my-paginator"
               [rows]="10"
               [totalRecords]="totalDocuments"
               pageLinkSize="3"
               (onPageChange)="paginate($event)"></p-paginator>
</div>
