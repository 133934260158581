import {DocBase} from "./doc.base";

export class OrganizationSettings extends DocBase {
  name: string;
  address1: string;
  address2: string;
  zip: string;
  city: string;
  phone: string;
  fax: string;
  mail: string;
  web: string;
  addressHeader: string;
  addressHeader2: string;

  bankname: string;
  iban: string;
  bic: string;
  uid: string;

  logoDocId: number;

  creditorId: string;

  taxNumber: string;


  constructor() {
    super();

    this.table = 'OrganizationSettings';

    this.name = '';
    this.address1 = '';
    this.address2 = '';
    this.zip = '';
    this.city = '';
    this.phone = '';
    this.fax = '';
    this.mail = '';
    this.bankname = '';
    this.iban = '';
    this.bic = '';
    this.uid = '';
    this.logoDocId = 0;
    this.creditorId = '';
    this.taxNumber = '';
    this.web = '';
    this.addressHeader = 'Abs: ';
    this.addressHeader2 = 'E Österreichische Post AG Eco Brief';
  }
}
