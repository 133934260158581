import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs'
import { Contact } from '../shared/contact.model'
import { MemberService } from './member.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.css']
})
export class MembersComponent implements OnInit, OnDestroy {

  members: Contact[];
  isLoading = false;

  constructor(private memberService: MemberService, private router: Router) { }

  ngOnInit(): void {

  }

  ngOnDestroy() {

  }


}
