

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Globals } from '../globals';
import { AppUser } from './appuser.model'

@Injectable({ providedIn: 'root' })
export class AppUserService {

  itemList = new Subject<AppUser[]>();
  itemArray = new Array<AppUser>();

  totalItems: number = 0;

  selectedItem: AppUser = null;

  detailActive = '';
  listActive = 'active';

  documentId: number;

  constructor(private globals: Globals,
    private router: Router,
    private http: HttpClient) {

  }

  getItems(search: string, pageSize: number, page: number) {
    const url = this.globals.AUTH_URL + 'users';
    let params = new HttpParams();
    params = params.append('search', search);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('page', page.toString());
    this.http
      .get<AppUser[]>(url, {
        params: params,
        observe: 'response',
      })
      .subscribe((respData) => {
        this.totalItems = parseInt(respData.headers.get('x-total-count'));
        this.itemArray = respData.body.slice();
        this.itemList.next(this.itemArray);
      });
  }

  updateItem(p: AppUser) {
    const url = this.globals.AUTH_URL + 'Users';
    this.http.put<AppUser>(url, p).subscribe((respData) => {
      this.selectedItem = respData;
      const idx = this.itemArray.findIndex((x) => x.id === p.id);
      this.itemArray[idx] = respData;
      this.router.navigate([
        'settings',
        'access',
        this.selectedItem.id,
      ]);
    });
  }

  addItem(p: AppUser) {
    const url = this.globals.AUTH_URL + 'Users';
    this.http.post<AppUser>(url, p).subscribe((respData) => {

      this.itemArray.push(respData);
      this.selectedItem = respData;
      this.router.navigate(['settings', 'access', this.selectedItem.id]);

    });
  }

  readItem(id: number) {
    const url = this.globals.AUTH_URL + 'Users/' + id;
    return this.http.get<AppUser>(url);
  }


  getItem(id: number) {
    //this.selectedProduct = this.productArray.find((p) => p.id === id);
    this.selectedItem = Object.assign(
      new AppUser(),
      this.itemArray.find((p) => p.id === id)
    );
    return this.selectedItem;
  }

  deleteItem(id: number) {
    const url = this.globals.AUTH_URL + 'Users/' + id;
    return this.http.delete(url).subscribe(() => {
      this.itemArray = this.itemArray.filter(item => item.id !== this.selectedItem.id);
      this.selectedItem = null;
      this.itemList.next(this.itemArray);
      this.router.navigate(['settings', 'access']);
    });
  }

}
