

export class AppUser {
  id: number;
  userName: string;
  email: string;
  password: string;
  isConfirmed: boolean;
  role: number;
  isAdmin: boolean;

  constructor() {
    this.id = 0;
    this.userName = '';
    this.email = '';
    this.password = '';
    this.isConfirmed = false;
    this.role = 1;
    this.isAdmin = false;
  }
}
