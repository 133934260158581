export class Wap {
  public id: number;

  public accessPointID: string;
  public active: boolean;
  public description: string;
  public yearCreated: number;
  public drainAccess: boolean;
  public gpsCoord: string;

  public address1: string;
  public zip: string;
  public city: string;

  public ownerContactID: number;
  public invoiceContactID: number;

  public gaugeID: number;

  public parentWapID: number;

  public fragment: number;

  public waterbillTermsID1: number;
  public waterbillTermsID2: number;

  public propertyNumber: string;

  public pipeNumber: string;

  public supplyZone: string;

  public propertyID: number;

  public isFlatRate: boolean;

  public isQuota: boolean;

  public quota: number;

  public isPool: boolean;

  public poolSize: number;

  public gpsCoordId: number;

  constructor() {
    this.accessPointID = "";
    this.active = true;
    this.description = "";
    this.yearCreated = new Date().getUTCFullYear();
    this.drainAccess = false;
    this.gpsCoord = "";

    this.address1 = "";
    this.zip = "";
    this.city = "";

    this.ownerContactID = 0;
    this.invoiceContactID = 0;

    this.gaugeID = 0;

    this.parentWapID = 0;
    this.fragment = 1;

    this.waterbillTermsID1 = 0;
    this.waterbillTermsID2 = 0;

    this.propertyNumber = '';
    this.pipeNumber = '';
    this.supplyZone = '';

    this.propertyID = 0;

    this.isFlatRate = false;

    this.isQuota = false;
    this.quota = 0;
    this.isPool = false;
    this.poolSize = 0;
    this.gpsCoordId = 0;
  }

}

export class WapImport extends Wap {
  public memberId: string;
  public gaugeSerial: string;

  public needText1: string;
  public needQuantity1: number;

  public needText2: string;
  public needQuantity2: number;

  public needText3: string;
  public needQuantity3: number;

  public needText4: string;
  public needQuantity4: number;

  public needText5: string;
  public needQuantity5: number;

  constructor() {
    super();
    this.memberId = '';
    this.gaugeSerial = '';

    this.needText1 = '';
    this.needQuantity1 = 0;

    this.needText2 = '';
    this.needQuantity2 = 0;

    this.needText3 = '';
    this.needQuantity3 = 0;

    this.needText4 = '';
    this.needQuantity4 = 0;

    this.needText5 = '';
    this.needQuantity5 = 0;

  }
}

export class WapViewModel {
  id: number = 0;
  accessPointID: string = "";
  ownerContactID: number;
  ownerName: string = "";
  address: string = "";
  city: string = "";
  isFlatRate: boolean = false;

  constructor(wap: Wap) {
    this.id = wap.id;
    this.accessPointID = wap.accessPointID;
    this.ownerContactID = wap.ownerContactID;
    this.address = wap.address1;
    this.city = wap.city;
    this.isFlatRate = wap.isFlatRate;
  }
}

