<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-toast position="bottom-right" key="tl"></p-toast>
<div class="mb-3 flex align-items-center justify-content-between">
  <div>
    <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
    <span class="text-xl font-medium text-900 mx-3">Lieferant</span>
  </div>
  <div>
    <button pButton pRipple label="Löschen" icon="pi pi-trash" (click)="Delete()" class="mx-3"></button>
    <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="Save()"></button>
  </div>
</div>

<div>
  <ul class="surface-card p-0 m-0 list-none flex overflow-x-auto select-none my-3">
    <li class="px-0">
      <a pRipple
         class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150"
         [ngClass]="{'tab-selected': active1 === 0, 'text-700 border-transparent no-ul': active1 !== 0}"
         (click)="active1 = 0">
        <i class="pi pi-book mr-2"></i>
        <span class="font-medium">Allgemeine Daten</span>
      </a>
    </li>
    <li class="flex align-items-center">
      <div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
    </li>
    <li class="px-3">
      <a pRipple
         class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150"
         [ngClass]="{'tab-selected': active1 === 1, 'text-700 border-transparent no-ul': active1 !== 1}"
         (click)="active1 = 1">
        <i class="pi pi-euro mr-2"></i>
        <span class="font-medium">Zahlungsinformationen</span>
      </a>
    </li>
    <li class="flex align-items-center">
      <div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
    </li>
    <li class="px-3">
      <a pRipple
         class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150"
         [ngClass]="{'tab-selected': active1 === 2, 'text-700 border-transparent no-ul': active1 !== 2}"
         (click)="active1 = 2">
        <i class="pi pi-comment mr-2"></i>
        <span class="font-medium">Sonstiges</span>
      </a>
    </li>

  </ul>
</div>

<div *ngIf="active1 == 0">


  <form #contactData="ngForm">
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label>Nummer</label>
          <input type="text" name="memberid" placeholder="Ihre ID" [(ngModel)]="contact.contactID"
                 class="form-control"/>
        </div>
      </div>
      <div class="col-md-2 col-btn">
        <div class="form-check">

          <input type="checkbox" class="form-check-input" name="active" [(ngModel)]="contact.active" id="2">
          <label class="custom-control-label" for="2">Aktiv</label>

        </div>
      </div>
      <div class="col-md-5">
        <div class="form-group">
          <label>Email</label>
          <input type="text" name="email" placeholder="Email" [(ngModel)]="contact.email" email
                 class="form-control"/>
        </div>
      </div>
      <!-- <div class="col-md-2">
      <div class="form-group">
          <label>Stimmen</label>
          <input type="text" readonly="true" name="votes" class="form-control" ngModel />
      </div>
  </div> -->
    </div>
    <div class="row">

      <div class="col-md-5" *ngIf="contact.contactType == 'person'">
        <div class="form-group">
          <label>Vorname</label>
          <input type="text" name="firstName" placeholder="Vorname" class="form-control"
                 [(ngModel)]="contact.firstName"/>
        </div>
      </div>
      <div class="col-md-5" *ngIf="contact.contactType == 'person'">
        <div class="form-group">
          <label>Nachname</label>
          <input type="text" name="lastName" placeholder="Nachname" class="form-control"
                 [(ngModel)]="contact.lastName"/>
        </div>
      </div>
      <div class="col-md-10" *ngIf="contact.contactType == 'company'">
        <div class="form-group">
          <label>Organisation</label>
          <input type="text" name="organization" placeholder="Organisation/Firma"
                 class="form-control" [(ngModel)]="contact.orgName"/>
        </div>
      </div>
      <div class="col-md-2">
        <label for="contactType">Kontaktart</label>
        <div class="">
          <p-selectButton name="contactType" [options]="contactOptions" optionLabel="label" optionValue="value"
                          [(ngModel)]="contact.contactType" class="full-width-select-button"></p-selectButton>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Name 2</label>
          <input type="text" name="name2" placeholder="Name Zusatz" class="form-control" [(ngModel)]="contact.name2"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Adresse</label>
          <input type="text" name="address1" placeholder="Zeile 1" class="form-control"
                 [(ngModel)]="contact.address1"/>
        </div>
        <div class="form-group">
          <input type="text" name="address2" placeholder="Zeile 2" class="form-control"
                 [(ngModel)]="contact.address2"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label>PLZ</label>
          <input type="text" name="zip" placeholder="PLZ" class="form-control" [(ngModel)]="contact.zip"/>
        </div>
      </div>
      <div class="col-md-9">
        <div class="form-group">
          <label>Ort</label>
          <input type="text" name="city" placeholder="Ort" class="form-control" [(ngModel)]="contact.city"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label>Handy</label>
          <input type="text" name="mobile" placeholder="Handy" class="form-control"
                 [(ngModel)]="contact.mobile"/>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Telefon</label>
          <input type="text" name="phone" placeholder="Telefon" class="form-control"
                 [(ngModel)]="contact.telephone"/>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Fax</label>
          <input type="text" name="fax" placeholder="Fax" class="form-control" [(ngModel)]="contact.fax"/>
        </div>
      </div>
    </div>

  </form>
</div>


<div *ngIf="active1 == 1">


  <form #paymentData="ngForm">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Bankname</label>
          <input type="text" name="bankname" placeholder="Bankname" class="form-control"
                 [(ngModel)]="contact.bankeName"/>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Zahlungsbedingung</label>
          <p-dropdown name="paymentTerms" [options]="paymentTermsService.itemArray" placeholder="Bitte auswählen"
                      [(ngModel)]="contact.paymentTermsId" optionLabel="name" optionValue="id"></p-dropdown>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>IBAN</label>
          <input type="text" name="iban" placeholder="AT__" class="form-control"
                 [(ngModel)]="contact.iban"/>
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label>BIC</label>
          <input type="text" name="bic" placeholder="BIC" class="form-control" [(ngModel)]="contact.bic"/>
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label>UID-Nummer</label>
          <input type="text" name="uid" placeholder="ATU" class="form-control" [(ngModel)]="contact.vat"/>
        </div>
      </div>
    </div>
    <div class="row">

      <div class="col-md-4 col-btn">
        <div class="form-check">
          <input type="checkbox" class="form-check-input" name="isEInvoice" [(ngModel)]="contact.isEInvoice" id="3">
          <label class="custom-control-label" for="3">Elektronischer Rechnungsversand</label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Emailadresse für Rechnungsversand</label>
          <input type="text" name="emailInvoice" placeholder="" [(ngModel)]="contact.emailInvoice"
                 class="form-control"/>
        </div>
      </div>
    </div>


  </form>

</div>

<div *ngIf="active1 == 2" class="surface-section">
  <form #otherData="ngForm">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Bemerkungen</label>
          <textarea type="text" name="notes" class="form-control"
                    style="resize: none; height: 100px;" rows="3" [(ngModel)]="contact.notes"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Freifeld 1</label>
          <input type="text" name="field1" class="form-control" [(ngModel)]="contact.field1"/>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label>Freifeld 2</label>
          <input type="text" name="field2" placeholder="" class="form-control" [(ngModel)]="contact.field2"/>
        </div>
      </div>

    </div>


  </form>
</div>




