import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Globals, KVP_GROUPS } from '../../../globals';
import { DmsService } from '../../../services/dms/dms.service';
import { Equipment } from '../../../shared/equipment.model';
import { KvpService } from '../../../shared/kvp.service';
import { KvpSetting } from '../../../shared/kvpsetting.model';
import { EquipmentService } from '../equipment.service';
import { MyDocument } from '../../../shared/document.model';
import { DialogService } from 'primeng/dynamicdialog';
import { IDataService } from '../../../shared/dataservice.interface';
import {ConfirmationService, MessageService} from 'primeng/api';

@Component({
  selector: 'app-equipment-edit',
  templateUrl: './equipment-edit.component.html',
  styleUrls: ['./equipment-edit.component.css'],
  providers: [DialogService],
})
export class EquipmentEditComponent implements OnInit, OnDestroy, IDataService {

  @ViewChild('FileSelectInputDialog') FileSelectInputDialog: ElementRef;

  equipment: Equipment = new Equipment();
  afa: number;

  equipmentTypes: any[];
  selectedType: any;

  imageName: string;
  imageSrc: any;

  editMode: boolean;
  id: number;

  selectedFile: File;



  constructor(
    public entityService: EquipmentService,
    private route: ActivatedRoute,
    private kvpService: KvpService,
    private globals: Globals,
    private sanitizer: DomSanitizer,
    private router: Router,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private dmsService: DmsService,
    private messageService: MessageService,
    ) { }

  ngOnInit(): void {
    this.kvpService.readGroup(KVP_GROUPS.EQU_TYPES).subscribe(resp => {
      this.equipmentTypes = resp.map(({ group, ...rest }) => rest);
      this.initForm();
    });

    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.editMode = params['id'] != 'new';
      this.initForm();
    });


  }

  ngOnDestroy(): void {

  }

  initForm() {
    if (this.editMode) {
      this.equipment = this.entityService.getItem(this.id);
      if (this.equipment.equipmentType != "" && this.equipmentTypes != null) {
        var idx = this.equipmentTypes.findIndex((i) => i.key === this.equipment.equipmentType)
        if (idx >= 0) {
          this.selectedType = this.equipmentTypes[idx];
        }
      }

      if (this.equipment.documentId > 0) {
        this.dmsService.readDocument(this.equipment.documentId).subscribe((resp) => {
          this.dmsService.downloadDocument(resp.id).subscribe((r2) => {
            var downloadURL = URL.createObjectURL(r2.body);
            this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(downloadURL);
          });

        });
      }

      this.updateAfa();
    }
    else {
      this.equipment = new Equipment();
    }
  }

  updateAfa() {
    if (this.equipment && this.equipment.equipmentLife > 0) {
      this.afa = Math.round(this.equipment.replacementPrice == 0 ? this.equipment.purchasePrice / this.equipment.equipmentLife : this.equipment.replacementPrice / this.equipment.equipmentLife);
    }
  }

  onSelectImage() {
    console.log("select image");
    const elem: HTMLElement = this.FileSelectInputDialog.nativeElement;

    elem.click();

  }

  onRemoveImage() {
    this.imageSrc = null;
  }

  onFileSelected(e) {
    this.selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = (e: any) => {
      this.imageSrc = e.target.result;
    };
    reader.readAsDataURL(this.selectedFile);
    //this.imageSrc = e.target.files[0];
  }



  onSave() {
    if (this.selectedType && this.selectedType.value != "Keine Auswahl") {
      this.equipment.equipmentType = this.selectedType.key;
    }
    else {
      this.equipment.equipmentType = "";
    }

    if (this.equipment.documentId == 0 && this.imageSrc != null) {
      var doc = new MyDocument();
      doc.name = "EqImage";

      this.dmsService.addInternalDocument(doc, this.selectedFile).subscribe((resp) => {
        this.equipment.documentId = resp.id;
        this.doSave();
      });
    }
    else if (this.equipment.documentId != 0 && this.imageSrc == null) {
      this.dmsService.deleteInternalDocument(this.equipment.documentId).subscribe((resp) => {
        this.equipment.documentId = 0;
        this.doSave();
      });
    }
    else {
      this.doSave();
    }
  }

  doSave() {
    if (this.editMode) {
      this.entityService.updateItem(this.equipment);
      this.messageService.add({ key: 'tl', severity: 'info', summary: 'Anlage wurden gespeichert.' });
    }
    else {
      this.entityService.addItem(this.equipment);
      this.messageService.add({ key: 'tl', severity: 'info', summary: 'Neue Anlage angelegt.' });
    }
    this.updateAfa();
  }

  onDelete() {
    this.confirmationService.confirm({
      message: 'Möchten Sie den Anlagenteil wirklich löschen?',
      header: 'Anlagenteil löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      rejectVisible: true,
      key: 'confirm',
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        this.entityService.deleteItem(this.equipment.id).subscribe(() => {
          this.entityService.itemArray = new Array();
          this.router.navigate(['masterdata', 'equipment']);
        });
      },
    });
  }

  New() {

  }
  Copy() {

  };
  Save() {
    this.onSave();
  };
  Delete() {

    this.onDelete();
  }

  Back() {
    this.router.navigate(['masterdata', 'equipment']);
  }

}
