import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpParams, HttpHeaders } from '@angular/common/http'
import { take, exhaustMap } from 'rxjs/operators'

import { AuthService } from './auth.service'

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

    constructor(private auth: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return this.auth.user.pipe(
            take(1),
            exhaustMap(user => {
                if (!user) {
                    return next.handle(req);
                }

                let newParams = new HttpParams({ fromString: req.params.toString() });
                newParams = newParams.append('app', this.auth.appId.toString());

                let h = new HttpHeaders().set('Authorization', 'Bearer ' + user.token[0].token);
                if (req.headers && req.headers.get('content-type') == null)
                    h.set('content-type', 'application/json')

                const modifiedReq = req.clone({
                    params: newParams,
                    headers: h //new HttpHeaders()//.set('content-type', 'application/json')
                    //.set('Authorization', 'Bearer ' + user._token[0].token)
                });
                return next.handle(modifiedReq);
            })
        );

    }
}
