import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { PaymentTermsService } from '../../../settings/paymentterms/payment-terms.service';
import { Contact } from '../../../shared/contact.model';
import { IDataService } from '../../../shared/dataservice.interface';
import { SupplierService } from '../supplier.service';

@Component({
  selector: 'app-supplier-edit',
  templateUrl: './supplier-edit.component.html',
  styleUrls: ['./supplier-edit.component.css'],
  providers: [DialogService],
})
export class SupplierEditComponent implements OnInit, OnDestroy, IDataService {


  id: number;
  contact: Contact;
  editMode = false;

  active1: number = 0;

  contactOptions: any[];


  constructor(
    private supplierService: SupplierService,
    public paymentTermsService: PaymentTermsService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
  ) {
    this.supplierService.listActive = '';
    this.supplierService.detailActive = 'active';
    this.contactOptions = [{ label: 'Person', value: 'person' }, { label: 'Organisation', value: 'company' }];
  }

  ngOnInit(): void {
    this.contact = this.supplierService.selectedSupplier;

    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.editMode = params['id'] != 'new';
      this.initForm();
    });
  }

  ngOnDestroy(): void {

  }

  initForm() {
    if (this.editMode) {
      this.contact = this.supplierService.getSupplier(this.id);
      if (this.contact.contactType != null && this.contact.contactType == '') {
        this.contact.contactType = this.contact.orgName != '' ? 'company' : 'person';
      }
    } else {
      this.contact = new Contact();
      this.contact.role = 'Supplier';
      this.contact.contactType = 'company';
      this.contact.active = true;
      this.supplierService.getNextNumber().subscribe(r => {
        this.contact.contactID = r.id;
      });
    }
  }

  onSave() {

    if (this.editMode) {
      this.supplierService.updateSupplier(this.contact);
      this.messageService.add({ key: 'tl', severity: 'info', summary: 'Lieferant wurden gespeichert.' });
    } else {
      this.supplierService.addSupplier(this.contact);
      this.messageService.add({ key: 'tl', severity: 'info', summary: 'Neuer Lieferant angelegt.' });
    }
  }

  onDelete() {
    this.confirmationService.confirm({
      message: 'Möchten Sie diesen Lieferanten wirklich löschen?',
      header: 'Lieferanten löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      key: 'confirm',
      rejectVisible: true,
      icon: 'pi pi-exclamation-triangle',
      accept: () => setTimeout(() => {
        this.supplierService.deleteSupplier(this.contact.id).subscribe((r) => {
          console.log(r);
          if (r && r.value.length > 0) {
            this.confirmationService.confirm({
              message: r.value,
              header: 'Fehler beim Löschen!',
              acceptLabel: 'OK',
              key: 'info',
              rejectVisible: false,
              accept: () => {
                console.log('log');
              }
            });
          } else {
            this.supplierService.supplierArray = new Array();
            this.router.navigate(['masterdata', 'suppliers']);
          }
        });
      }),
    });
}

  New() {

  }
  Copy() {

  };
  Save() {
    this.onSave();
  };
  Delete() {
    this.onDelete();
  }

  Back() {
    this.router.navigate(['masterdata', 'suppliers']);
  }

}
