import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Globals } from '../../globals';

import { Contact } from '../../shared/contact.model';
import { ContactService } from '../../shared/contact.service';
import {MessageService} from "primeng/api";

@Injectable()
export class SupplierService {
  suppliers = new Subject<Contact[]>();
  supplierArray = new Array<Contact>();

  totalSuppliers: number = 0;

  selectedSupplier = new Contact();

  detailActive = '';
  listActive = 'active';

  isCopy = false;

  constructor(private contacts: ContactService, private router: Router, private globals: Globals,
              private http: HttpClient,
              private messageService: MessageService,
  ) {
    this.selectedSupplier.role = "Supplier";
  }

  getContacts(search: string, pageSize: number, page: number) {
    this.contacts
      .readAllContacts(search, pageSize, page)
      .subscribe((respData) => {
        this.totalSuppliers = parseInt(respData.headers.get('x-total-count'));
        console.log(this.totalSuppliers);
        this.supplierArray = respData.body.slice();
        this.suppliers.next(this.supplierArray);
      });
  }

  getSuppliers(search: string, pageSize: number, page: number) {
    this.contacts
      .readContacts('supplier', search, pageSize, page)
      .subscribe((respData) => {
        this.totalSuppliers = parseInt(respData.headers.get('x-total-count'));
        console.log(this.totalSuppliers);
        this.supplierArray = respData.body.slice();
        this.suppliers.next(this.supplierArray);
      });
  }

  addSupplier(c: Contact) {
    this.contacts.addContact(c).subscribe((respData) => {
      console.log(respData);
      this.selectedSupplier = respData;
      this.supplierArray.push(respData);
      this.router.navigate(['masterdata', 'suppliers', this.selectedSupplier.id]);
    });
  }

  updateSupplier(c: Contact) {
    this.contacts.updateContact(c).subscribe((respData) => {
      this.selectedSupplier = respData;
      const idx = this.supplierArray.findIndex((x) => x.id === c.id);
      this.supplierArray[idx] = respData;
      this.router.navigate(['masterdata', 'suppliers', this.selectedSupplier.id]);
    });
  }

  getSupplier(id: number): Contact {
    this.selectedSupplier = Object.assign(
      new Contact(),
      this.supplierArray.find((m) => m.id === id)
    );
    return this.selectedSupplier;
  }

  deleteSupplier(id: number) {
    return this.contacts.deleteContact(id);
  }

  removeContactFromList(c: Contact) {
    const index = this.supplierArray.findIndex(mem => mem.id == c.id);
    if (index > -1) {
      this.supplierArray.splice(index, 1);
      this.suppliers.next(this.supplierArray);
    }
  }

  getNextNumber() {
    const url = this.globals.APP_URL + 'numberrange/supplier/next-number';
    return this.http.get<{ id: string }>(url);
  }

}

