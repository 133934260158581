import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Globals } from '../globals';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  @ViewChild('form1') form1: NgForm;

  constructor(public globals: Globals) { }

  ngOnInit(): void {
  }

  onSaveOrganizationSettings() : void {
  
    console.log(this.form1);

    this.form1.reset(this.form1.value);
  }

}
