<div class="surface-card  pt-4 pl-2 shadow-2 border-round mb-2" *ngIf="!isLoading">
  <div class="surface-section p-4">
    <div class="grid">
      <div class="col-12 lg:col-2">
        <div class="text-xl font-medium text-900 mb-3">Umsatzsteuer</div>
        <p class="m-0 p-0 text-600 line-height-3 mr-3">
          Hier wird aus den Buchungen die Umsatzsteuer für den gewählten Zeitraum berechnet und angezeigt.
        </p>

      </div>

      <div class="col-12 lg:col-10">
        <div class="grid formgrid p-fluid">
          <div class="field mb-3 col-12 md:col-4">
            <label class="font-medium text-900">Zeitraum auswählen</label>
            <p-dropdown [options]="timePeriods" optionLabel="val" optionValue="key" appendTo="body"
                        [(ngModel)]="selectedTimePeriod"
                        (onChange)="onTimePeriodChanged()">

            </p-dropdown>
          </div>
          <div class="field mb-3 col-12 md:col-4">
            <label class="font-medium text-900">Von:</label>
            <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="startDate"></p-calendar>
          </div>
          <div class="field mb-3 col-12 md:col-4">
            <label class="font-medium text-900">Bis:</label>
            <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="endDate"></p-calendar>
          </div>


        </div>

        <div class="grid flex-column p-fluid mr-2 mt-2">
          <div class="flex justify-content-between align-items-center">

            <div></div>
            <div></div>

            <div class="ml-auto">
              <button pButton pRipple label="Berechnen" icon="pi pi-calculator" (click)="onSearch()"></button>
            </div>

          </div>
        </div>


        <div>
          <hr class="my-3">
          <label class="font-bold text-900 mb-3">Berechnung der Umsatzsteuer</label>
          <div class="grid">

            <div class="field col-12 md:col-6 m-0">
              <label class="font-medium text-600 m-0">Gesamtbetrag der Bemessungsgrundlage</label>
            </div>
            <div class="field col-12 md:col-6 m-0">
              <input pInputText type="text" name="totalRevenue" class="form-control"
                     value="{{ taxReport.totalNetRevenue / 100 | currency: 'EUR' }}" [readOnly]="true"/>
            </div>

            <div class="field col-12 md:col-6 m-0">
              <label class="font-medium text-600 m-0">Davon steuerfrei mit Vorsteuerabzug</label>
            </div>
            <div class="field col-12 md:col-6 m-0">
              <input pInputText type="text" name="totalTaxFree" class="form-control"
                     value="{{ taxReport.taxFreeRevenue / 100 | currency: 'EUR' }}" [readOnly]="true"/>
            </div>

            <div class="field col-12 md:col-6 m-0">
              <label class="font-bold text-600 m-0">Gesamtbetrag</label>
            </div>
            <div class="field col-12 md:col-6 m-0">
              <input pInputText type="text" name="totalTaxFree" class="form-control"
                     value="{{ taxReport.totalRevenue / 100 | currency: 'EUR' }}"
                     [readOnly]="true"/>
            </div>

            <div class="field col-12 md:col-6 m-0">
              <label class="font-medium text-600 m-0">Davon sind zu versteuern mit</label>
            </div>

            <div class="field col-12 md:col-6 m-0">
              <p-table [value]="taxReport.taxEntries">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Steuersatz</th>
                    <th>Bemessungsgrundlage</th>
                    <th>Umsatzsteuer</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-taxRate>
                  <tr>
                    <td>{{ taxRate.taxRate / 100 }} %</td>
                    <td>{{ taxRate.totalAmount / 100 | currency: 'EUR' }}</td>
                    <td>{{ taxRate.taxAmount / 100 | currency: 'EUR' }}</td>
                  </tr>
                </ng-template>


              </p-table>
            </div>
            <div class="field col-12 md:col-12 m-0">
              <hr class="m-0">
            </div>
          </div>


          <label class="font-bold text-900 mb-3">Berechnung der abziehbaren Vorsteuer</label>
          <div class="grid">

            <div class="field col-12 md:col-6 m-0">
              <label class="font-medium text-600 m-0">Gesamtbetrag der abziehbaren Vorsteuer</label>
            </div>
            <div class="field col-12 md:col-6 m-0">
              <input pInputText type="text" name="totalRevenue" class="form-control"
                     value="{{ taxReport.totalInputTax / 100 | currency: 'EUR' }}" [readOnly]="true"/>
            </div>

            <div class="field col-12 md:col-12 m-0">
              <hr class="m-0">
            </div>

            <div class="field col-12 md:col-6 m-0">
              <label class="font-bold text-900 m-0" *ngIf="taxReport.totalSalesTax > taxReport.totalInputTax">Vorauszahlung
                (Zahllast)</label>
              <label class="font-bold text-900 m-0" *ngIf="taxReport.totalSalesTax <= taxReport.totalInputTax">Überschuss
                (Gutschrift)</label>
            </div>

            <div class="field col-12 md:col-6 m-0">
              <input pInputText type="text" name="totalRevenue" class="form-control"
                     value="{{ taxReport.taxAmount / 100 | currency: 'EUR' }}"
                     [readOnly]="true"/>
            </div>

            <div class="field col-12 md:col-12 m-0">
              <hr class="m-0">
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
