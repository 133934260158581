<p-confirmDialog header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmPopup key="popup">

</p-confirmPopup>
<p-toast position="bottom-right" key="tl"></p-toast>
<div class="mb-3 flex align-items-center justify-content-between">
  <div>
    <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
    <span class="text-xl font-medium text-900 mx-3">Wasserzähler</span>
  </div>
  <div>
    <button pButton pRipple label="Löschen" icon="pi pi-trash" (click)="Delete()" class="mx-3"></button>
    <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="Save()"></button>
  </div>
</div>


<div class="row">
  <div class="col-md-6">
    <div class="form-group">
      <label>Seriennummer</label>
      <input type="text" name="serial" class="form-control" [(ngModel)]="gauge.serial" required
             [readOnly]="!gauge.isStock"/>
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Bezeichnung</label>
      <input type="text" name="description-gauge" class="form-control" [(ngModel)]="gauge.description"/>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-3">
    <div class="form-group">
      <label>Nächstes Eichdatum</label>
      <p-calendar class="my-datepicker" view="month" dateFormat="mm.yy" [yearNavigator]="true" yearRange="2020:2050"
                  [readonlyInput]="false" [locale]="globals.de" name="calibDate" [(ngModel)]="calibDate"
                  [disabled]="!gauge.isStock"
                  (onSelect)="onSelect($event)">
      </p-calendar>
    </div>
  </div>


  <div class="col-md-6">
    <div class="form-group">
      <label>Zählerstand neu</label>
      <input type="text" name="counter" class="form-control" [(ngModel)]="gauge.installCount"
             [readOnly]="!gauge.isStock"/>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <div class="form-group">
      <label>Zählertyp</label>
      <p-inputGroup>
        <input type="text" name="type" class="form-control" value="{{ productName }}"/>
        <button type="button" pButton icon="pi pi-search" class="p-button-success" (click)="onSelectProduct()"></button>
      </p-inputGroup>
    </div>
  </div>


  <div class="col-md-6">
    <div class="form-group">
      <label>Lieferant</label>
      <p-inputGroup>
        <input type="text" name="vendor" readonly class="form-control" [ngModel]="supplierName"/>
        <button type="button" pButton icon="pi pi-search" class="p-button-success"
                (click)="onSelectSupplier()"></button>
      </p-inputGroup>
    </div>
  </div>


</div>

<div class="row mt-3" *ngIf="gauge.isActive">

  <hr>

  <div class="col-md-3">
    <div class="form-group">
      <label>Einbaudatum</label>
      <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" disabled="true"
                  [readonlyInput]="true" name="installDate" [(ngModel)]="installDate"
      >
      </p-calendar>
    </div>
  </div>
  <div class="col-md-3">
    <div class="form-group">
      <label>Aktueller Zählerstand</label>
      <p-inputGroup>
        <input type="number" name="counter" class="form-control" [(ngModel)]="gauge.lastCount"
               [readOnly]="counterReadOnly"/>
        <button *ngIf="counterReadOnly"
                type="button" pButton icon="pi pi-lock" class="p-button-success"
                (click)="onUnlockCounter($event)"></button>
        <button *ngIf="!counterReadOnly"
                type="button" pButton icon="pi pi-lock-open" class="p-button-success"
                (click)="onUnlockCounter($event)"></button>
      </p-inputGroup>
    </div>
  </div>

  <div class="col-md-6">
    <div class="form-group">
      <label>Wasseranschluss</label>
      <input type="text" name="wap" class="form-control" [(ngModel)]="wapName"
             [ngStyle]="{'cursor': wap.gaugeID > 0 ? 'pointer' : 'auto'} " (click)="onSelectWap()"
             [readOnly]="true"/>
    </div>
  </div>


</div>


<br/>
