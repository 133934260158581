<p-confirmPopup></p-confirmPopup>
<div class="surface-card p-4 shadow-2 border-round">
  <div class="mb-3 flex align-items-center justify-content-between">
    <div>
      <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
      <span class="text-xl font-medium text-900 mx-3">Abrechnung</span>
    </div>

  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header d-md-flex justify-content-between align-items-center">
          <h5 class="card-title">Offene Zählerstände</h5>
        </div>


        <div class="card-body card-sidebar-menu">

          <div class="row" *ngIf="hasReadings">
            <div class="col-md-9">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th scope="col">Datum</th>
                    <th scope="col">Zähler</th>
                    <th scope="col">Menge</th>
                    <th scope="col">Auswahl</th>
                  </tr>
                  </thead>
                  <tbody class="table-users-body">
                  <tr *ngFor="let reading of readings; let i = index">
                    <td class="table-settings-wert">{{ reading.readingDate * 1000 | customDate }}</td>
                    <td class="table-settings-wert">{{ reading.gaugeSerial }}</td>
                    <td class="table-settings-wert">{{ reading.count - reading.prevCount }}</td>
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" [id]="i" checked="readings[i].selected"
                               (change)="readings[i].selected = !readings[i].selected">
                        <label class="custom-control-label" [for]="i"></label>
                      </div>
                    </td>
                    <td (click)="onDeleteReading(i, $event)">
                      <div class="del-table-button">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" target="_parent"
                                d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"/>
                        </svg>
                      </div>
                    </td>
                  </tr>

                  </tbody>
                </table>

              </div>
            </div>

            <div class="col-md-3 align-self-end my-3">
              <a class="btn btn--primary" (click)="onCopyReadings($event)">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"/>
                </svg>
                <span>Übernehmen</span>
              </a>

            </div>
          </div>
          <div class="row justify-content-center" *ngIf="!hasReadings">
            <div class="col-md-8">
              <div class="text-center">
                <div class="alert alert-warning alert-dismissible fade show">
                  <strong>Achtung!</strong> Keine offene Zählerstände vorhanden. Nur Teilrechnung erlaubt.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="card">
        <div class="card-header d-md-flex justify-content-between align-items-center">
          <h5 class="card-title">Wasserabrechnung erstellen</h5>
        </div>

        <div class="card-body card-sidebar-menu">
          <form #billingData="ngForm">
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label>Von</label>
                  <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="true" [locale]="globals.de"
                              [ngModelOptions]="{standalone: true}" [(ngModel)]="fromDate">
                  </p-calendar>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label>Bis</label>
                  <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="true" [locale]="globals.de"
                              [ngModelOptions]="{standalone: true}" [(ngModel)]="toDate">
                  </p-calendar>
                </div>
              </div>

              <div class="col-md-2 col-btn">
                <div class="form-group">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" name="part" ngModel id="partinvoice"
                           [disabled]="!hasReadings" [checked]="isPart" (change)="isPart = !isPart"/>
                    <label class="custom-control-label" for="partinvoice">Teilrechnung</label>
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label>Menge [m³]</label>
                  <input type="number" name="sum" placeholder="" class="form-control" [(ngModel)]="sum"
                         [disabled]="!isPart"/>
                </div>
              </div>

              <div class="col-md-1">
                <div class="form-group">
                  <label>Frei</label>
                  <input type="number" name="free" placeholder="" class="form-control" [(ngModel)]="free"/>
                </div>
              </div>

              <div class="col-md-2 align-self-end col-btn">
                <div class="form-group">
                  <a class="btn btn--primary" (click)="onCreateUsage($event)">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M2,16H10V14H2M18,14V10H16V14H12V16H16V20H18V16H22V14M14,6H2V8H14M14,10H2V12H14V10Z"/>
                    </svg>
                    <span>Erstellen</span>
                  </a>
                </div>
              </div>

            </div>


          </form>
          <div class="row justify-content-center" *ngIf="!validUsage">
            <div class="col-md-10">
              <div class="text-center">
                <div class="alert alert-warning alert-dismissible fade show">
                  <strong>Ungültige Mengen!</strong> Menge muss größer 0 sein und Freimenge kleiner als Menge.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="card">
        <div class="card-header d-md-flex justify-content-between align-items-center">
          <h5 class="card-title">Letzten Abrechnungen</h5>
        </div>

        <div class="card-body card-sidebar-menu">
          <div class="row">
            <div class="col-md-10">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th scope="col">Von</th>
                    <th scope="col">bis</th>
                    <th scope="col">Teilrechnung</th>
                    <th scope="col">Menge</th>
                    <th scope="col">Frei</th>
                    <th scope="col">Rechnung</th>
                  </tr>
                  </thead>
                  <tbody class="table-users-body">
                  <tr *ngFor="let usage of usages; let i = index">
                    <td class="table-usage-value">{{ usage.from2 * 1000 | customDate }}</td>
                    <td class="table-usage-value">{{ usage.to2 * 1000 | customDate }}</td>

                    <td class="table-usage-value">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="u2-{{usage.id}}"
                               [checked]="usage.isPartPayment" disabled>
                        <label class="custom-control-label" for="uw-{{usage.id}}"></label>
                      </div>
                    </td>

                    <td class="table-usage-value">{{ usage.usage }}</td>
                    <td class="table-usage-value">{{ usage.freeUsage }}</td>
                    <td>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="u2-{{usage.id}}"
                               [checked]="usage.isBilled" disabled>
                        <label class="custom-control-label" for="u2-{{usage.id}}"></label>
                      </div>
                    </td>
                    <td (click)="onDeleteUsage(i, $event)">
                      <div class="del-table-button" *ngIf="!usage.isBilled">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" target="_parent"
                                d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"/>
                        </svg>
                      </div>
                    </td>
                  </tr>

                  </tbody>
                </table>

              </div>
            </div>


          </div>

        </div>


      </div>
    </div>
  </div>
</div>
