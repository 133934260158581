
<div class="surface-section px-4 py-6 md:px-6 lg:px-8">
    <div class="text-600 text-sm mb-3">
        <span>Für die Nutzung der Software </span>
        <span class="font-bold">Headwater</span>
        <span> von </span>
        <span class="font-bold">Reisinger Technology GmbH</span>
    </div>
    <div class="border-blue-600 font-medium text-2xl p-3 mb-3 text-900" style="border-left: 8px solid">Lizenzvereinbarung</div>
    <div class="line-height-3 text-xl text-700 mb-5">
        Bitte lesen Sie die folgende Software-Lizenzvereinbarung sorgfältig durch, bevor Sie die Anwendung nutzen. Mit der Nutzung unserer Webanwendung stimmen Sie den unten aufgeführten Bedingungen zu.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">1. Lizenzerteilung</div>
    <div class="line-height-3 text-lg text-700 mb-4">
        Wir gewähren Ihnen hiermit eine nicht-exklusive, nicht-übertragbare Lizenz zur Nutzung unserer Webanwendung <span class="font-bold">Headwater</span>. Die Lizenz berechtigt Sie zur Nutzung der Anwendung für Ihre eigene interne Geschäftstätigkeit und nicht für eine Weitergabe oder einen Verkauf an Dritte.
</div>

    <div class="line-height-3 text-xl text-700 mb-5">2. Einschränkungen der Nutzung</div>
    <div class="line-height-3 text-lg text-700 mb-4">
        Sie dürfen die Anwendung nicht dekompilieren, disassemblieren oder zurückentwickeln. Sie dürfen auch nicht versuchen, den Quellcode der Anwendung zu modifizieren oder abzuleiten. Es ist Ihnen untersagt, die Anwendung für illegale Zwecke zu nutzen oder die Anwendung auf eine Weise zu verwenden, die gegen geltende Gesetze oder Vorschriften verstößt.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">3.	Eigentumsrechte</div>
    <div class="line-height-3 text-lg text-700 mb-4">
        Alle Rechte an der Webanwendung <span class="font-bold">Headwater</span>, einschließlich aller darin enthaltenen Technologien und geistigen Eigentumsrechte, liegen bei uns. Sie erkennen an, dass Sie keine Eigentumsrechte an der Anwendung oder an Teilen davon erwerben.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">4.	Haftungsbeschränkung</div>
    <div class="line-height-3 text-lg text-700 mb-4">
        Wir übernehmen keine Verantwortung für Verluste oder Schäden, die durch die Nutzung unserer Webanwendung <span class="font-bold">Headwater</span> entstehen. Wir übernehmen insbesondere keine Haftung für direkte, indirekte, spezielle, zufällige oder Folgeschäden oder für Schäden, die durch den Verlust von Daten oder Informationen entstehen.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">5.	Support und Wartung</div>
    <div class="line-height-3 text-lg text-700 mb-4">
        Wir bieten Support und Wartung für unsere Webanwendung <span class="font-bold">Headwater</span> an. Dieser Service wird separat angeboten und kann von Ihnen erworben werden.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">6.	Änderungen an der Vereinbarung</div>
    <div class="line-height-3 text-lg text-700 mb-4">
        Wir behalten uns das Recht vor, diese Lizenzvereinbarung für unsere Webanwendung <span class="font-bold">Headwater</span> jederzeit zu ändern. Wir werden Sie über Änderungen per E-Mail oder über unsere Webseite informieren.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">7.	Anwendbares Recht</div>
    <div class="line-height-3 text-lg text-700 mb-4">
        Diese Vereinbarung unterliegt den Gesetzen des Landes, in dem unser Unternehmen seinen Hauptsitz hat.
    </div>
</div>

