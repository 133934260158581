import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Globals, KVP_GROUPS} from '../../../globals';
import {ProductSelectComponent} from '../../../masterdata/products/product-select/product-select.component';
import {ProductService} from '../../../masterdata/products/product.service';
import {DmsService} from '../../../services/dms/dms.service';
import {IDataService} from '../../../shared/dataservice.interface';
import {KvpService} from '../../../shared/kvp.service';
import {Product} from '../../../shared/product.model';
import {WaterbillSetting} from '../waterbill-settings.model';
import {WaterbillSettingsService} from '../waterbill-settings.service';

@Component({
  selector: 'app-waterbill-settings-edit',
  templateUrl: './waterbill-settings-edit.component.html',
  styleUrls: ['./waterbill-settings-edit.component.css'],
  providers: [DialogService],
})
export class WaterbillSettingsEditComponent implements OnInit, IDataService {

  editMode: boolean;
  id: number;

  entity: WaterbillSetting;

  paymentMethods: any[];
  selectedPaymentMethod: any;

  waterProduct: string;
  quotaProduct: string;
  feeProduct: string;
  addProduct: string;

  ref: DynamicDialogRef;

  constructor(public globals: Globals,
              private kvpService: KvpService,
              private route: ActivatedRoute,
              private router: Router,
              private confirmationService: ConfirmationService,
              private productService: ProductService,
              private entityService: WaterbillSettingsService,
              private messageService: MessageService,
              public dialogService: DialogService) {
  }

  ngOnInit(): void {

    this.entity = new WaterbillSetting();

    this.kvpService.readGroup(KVP_GROUPS.PAY_METHOD).subscribe(resp => {
      this.paymentMethods = resp;
      if (this.paymentMethods.length > 0) {
        this.selectedPaymentMethod = this.paymentMethods[0].key;
      }
    });

    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.editMode = params['id'] != 'new';
      this.initForm();
    });

  }

  initForm(): void {
    if (this.editMode) {
      this.entity = this.entityService.getItem(this.id, new WaterbillSetting());
      this.readProducts();
    } else {
      this.entity = new WaterbillSetting();
    }
  }

  readProducts() {
    if (this.entity.waterProductId > 0) {
      this.productService.readProduct(this.entity.waterProductId).subscribe((resp) => {
        this.waterProduct = resp.description;
      });
    }

    if (this.entity.feeProductId > 0) {
      this.productService.readProduct(this.entity.feeProductId).subscribe((resp) => {
        this.feeProduct = resp.description;
      });
    }

    if (this.entity.addProductId > 0) {
      this.productService.readProduct(this.entity.addProductId).subscribe((resp) => {
        this.addProduct = resp.description;
      });
    }

    if (this.entity.exeedQuotaProductId > 0) {
      this.productService.readProduct(this.entity.exeedQuotaProductId).subscribe((resp) => {
        this.quotaProduct = resp.description;
      });
    }
  }


  onSave() {
    if (this.editMode) {
      this.entityService.updateItem(this.entity);
      this.messageService.add({key: 'tl', severity: 'info', summary: 'Abrechnungsdaten wurden gespeichert.'});
    } else {
      this.entityService.addItem(this.entity);
      this.messageService.add({key: 'tl', severity: 'info', summary: 'Abrechnungsdaten wurden erstellt.'});
    }
  }

  onDelete() {
    this.confirmationService.confirm({
      message: 'Möchten Sie diese Abrechnungsart wirklich löschen?',
      header: 'Abrechnungsart löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      rejectVisible: true,
      key: 'confirm',
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        this.entityService.deleteItem(this.entity.id).subscribe(() => {
          this.entityService.itemArray = new Array();
          this.router.navigate(['settings', 'waterbillsettings']);
          this.messageService.add({key: 'tl', severity: 'info', summary: 'Abrechnungsdaten wurden gelöscht.'});
        });
      },
    });
  }

  onSelectProduct(t: number) {
    this.ref = this.dialogService.open(ProductSelectComponent, {
      header: 'Produkt auswählen',
      width: '70%',
      contentStyle: {'max-height': '650px', overflow: 'auto'},
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((c: Product) => {
      if (c) {
        switch (t) {
          case 1:
            this.waterProduct = c.description;
            this.entity.waterProductId = c.id;
            break;
          case 2:
            this.feeProduct = c.description;
            this.entity.feeProductId = c.id;
            this.entity.feeCount = 1;
            break;
          case 3:
            this.addProduct = c.description;
            this.entity.addProductId = c.id;
            this.entity.addCount = 1;
            break;
          case 4:
            this.quotaProduct = c.description;
            this.entity.exeedQuotaProductId = c.id;
            break;
        }
      }
    });
  }

  New() {
  }

  Copy() {
  };

  Save() {
    this.onSave();
  };

  Delete() {
    this.onDelete();
  };

  Back() {
    this.router.navigate(['settings', 'waterbillsettings'])
  }


}
