import {Component, OnInit} from '@angular/core';
import {TransactionService} from "../../transaction.service";
import {TransactionTemplate} from "../../transaction-template.model";
import {ButtonModule} from "primeng/button";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {Globals} from "../../../globals";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RippleModule} from "primeng/ripple";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";

@Component({
  selector: 'app-transaction-template-select',
  standalone: true,
  imports: [
    ButtonModule,
    SharedModule,
    TableModule,
    InputTextModule,
    ReactiveFormsModule,
    RippleModule,
    FormsModule
  ],
  templateUrl: './transaction-template-select.component.html',
  styleUrl: './transaction-template-select.component.css'
})
export class TransactionTemplateSelectComponent implements OnInit {

  searchString: string = '';
  entities: TransactionTemplate[] = new Array();
  template: TransactionTemplate = new TransactionTemplate();

  constructor(private transactionService: TransactionService,
              private ref: DynamicDialogRef,
              public config: DynamicDialogConfig,
              public globals: Globals) {
  }

  ngOnInit(): void {
    console.log(this.config.data);
    this.readTemplates(this.config.data.inputOnly, this.config.data.outputOnly);
  }

  onRead() {
    this.readTemplates(this.config.data.inputOnly, this.config.data.outputOnly);
  }

  readTemplates(onlyInputs: boolean, onlyOutputs: boolean) {
    this.transactionService.getTemplates(this.searchString).subscribe(resp => {
      if (onlyInputs) {
        this.entities = resp.filter(t => t.isIncoming);
      }
      if (onlyOutputs) {
        this.entities = resp.filter(t => t.isOutgoing);
      }

      if (!onlyInputs && !onlyOutputs)
        this.entities = resp;
    });
  }

  onSearch() {
    this.readTemplates(this.config.data.inputOnly, this.config.data.outputOnly);
  }

  onRowSelect(event: any) {
    this.ref.close(event.data);
  }

  onCancel() {
    this.ref.close();
  }
}
