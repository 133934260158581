<div class="mb-3 flex align-items-center justify-content-between">
    <span class="text-xl font-medium text-900">Anlagen</span>
    <button pButton pRipple label="Hinzufügen" icon="pi pi-plus" (click)="New()"></button>
</div>

<form (ngSubmit)="onSubmit(f)" #f="ngForm">
    <div class="px-0">
        <div class="input-group mb-3">
            <input type="text" name="searchEquipment" class="form-control input-lg" placeholder="Suchtext"
                   aria-label="text" aria-describedby="basic-addon2" [(ngModel)]="searchString" />
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" [disabled]="!f.valid" type="submit">
                    Suchen
                </button>
            </div>
        </div>
    </div>
</form>


<p-table [value]="items" selectionMode="single" [(selection)]="selectedItem" dataKey="id" responsiveLayout="scroll"
         (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped">
    <ng-template pTemplate="header">
        <tr>
            <th>Bezeichnung</th>
            <th>Typ</th>
            <th>Beschreibung</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr [pSelectableRow]="item">
            <td>{{ item.shortText }}</td>
            <td>{{ item.equipmentType }}</td>
            <td>{{ item.description }}</td>
        </tr>
    </ng-template>
</p-table>
<p-paginator class="my-paginator" [rows]="10" [totalRecords]="totalItems" pageLinkSize="3"
             (onPageChange)="paginate($event)"></p-paginator>

