import {DatePipe} from '@angular/common';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Globals} from '../../../globals';
import {ObjectService} from '../../../shared/object.service';
import {Counterlist} from '../../../water-access-point/counterlist.model';
import {WapService} from '../../../water-access-point/wap.service';
import {CounterListItem} from '../counter-list-item'
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-counter-list-import',
  templateUrl: './counter-list-import.component.html',
  styleUrls: ['./counter-list-import.component.css'],
  providers: [MessageService]
})
export class CounterListImportComponent implements OnInit {

  @ViewChild('FileSelectInputDialog') FileSelectInputDialog: ElementRef;
  fileName: string;

  isComment: boolean;
  createMissing: boolean;

  text: string;
  readingDate: Date;

  list: Counterlist[];
  storedItems: CounterListItem[];

  items: CounterListItem[];

  useTab: boolean = false;
  delimiter: string = ';';

  constructor(
    private wapService: WapService,
    public globals: Globals,
    private objService: ObjectService,
    private messageService: MessageService,
    public datepipe: DatePipe) {

  }

  ngOnInit(): void {
    this.wapService.getCounterList(false, false, false).subscribe((respo) => {
      this.list = respo;

      this.objService.load<Array<CounterListItem>>('counter-list').subscribe((resp) => {
        this.storedItems = resp;
      });

    });
  }

  onSelectFile() {
    const elem: HTMLElement = this.FileSelectInputDialog.nativeElement;
    elem.click();
  }

  onFileSelected(e) {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = (event) => {
      this.text = event.target.result.toString();
    };
    reader.readAsText(file);
  }

  onCheckInput() {
    const del = this.useTab ? '\t' : this.delimiter;
    this.items = [];
    if (this.text && this.text.length > 0) {
      let rows: string[] = null;

      if (this.isComment)
        rows = this.text.slice(this.text.indexOf("\n") + 1).split("\n");
      else
        rows = this.text.split("\n");

      rows.forEach(s => {
        if (s.length > 0) {
          let cols = s.split(del);
          let item = new CounterListItem(cols[0], parseInt(cols[1]), cols.length > 2 ? this.globals.parseDate(cols[2], 'dd mm yyyy') : null);
          if (this.readingDate) {
            item.date = this.readingDate;
          }
          var gauge = this.list.find(i => i.gaugeSerial === item.serial);
          if (!gauge) {
            item.state = 'Unbekannter Zähler';
          } else {
            var entry = this.storedItems.find(s => s.serial === item.serial);
            if (entry && entry.count > 0) {
              item.state = 'Zählerstand erfasst';
            }
          }

          var cpy = this.items.find(i => i.serial === item.serial);
          if (cpy) {
            item.state = 'Bereits vorhanden';
          }

          this.items.push(item);
        }
      });
    } else {
      this.messageService.add({
        key: 'tl',
        severity: 'error',
        summary: 'Fehler',
        detail: 'Es wurden keine Daten ausgewählt'
      });
    }
  }

  onImport() {
    if (this.items && this.items.length > 0) {
      let counter = 0;
      this.items.forEach(item => {
        var gauge = this.list.find(i => i.gaugeSerial === item.serial);
        if (gauge) {
          counter = counter + 1;
          let entry = this.storedItems.find(s => s.serial === item.serial);
          if (entry) {
            entry.count = item.count;
            entry.date = item.date;
          } else {
            this.storedItems.push(new CounterListItem(item.serial, item.count, item.date));
          }
        }
      });
      this.objService.save<Array<CounterListItem>>('counter-list', this.storedItems).subscribe((resp) => {
        this.messageService.add({
          key: 'tl',
          severity: 'info',
          summary: 'Information',
          detail: 'Es wurden ' + counter + ' Zählerstände importiert.'
        });
      });
    } else {
      this.messageService.add({
        key: 'tl',
        severity: 'error',
        summary: 'Fehler',
        detail: 'Es wurden keine Zählerstände importiert.'
      });
    }
  }

  ab2str(buf) {
    return String.fromCharCode.apply(null, new Uint16Array(buf));
  }

  deleteItem(item) {
    var idx = this.items.indexOf(item);
    this.items = this.items.slice(0, idx).concat(this.items.slice(idx + 1));
  }
}
