import {DatePipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ConfirmationService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {Observable, Subject} from 'rxjs';
import {Globals} from '../../../globals';
import {ObjectService} from '../../../shared/object.service';
import {Counterlist} from '../../../water-access-point/counterlist.model';
import {WapService} from '../../../water-access-point/wap.service';
import {CounterListItem} from '../counter-list-item';

@Component({
  selector: 'app-counter-list-edit',
  templateUrl: './counter-list-edit.component.html',
  styleUrls: ['./counter-list-edit.component.css'],
  providers: [DialogService]
})
export class CounterListEditComponent implements OnInit {

  showOnlyMissing: boolean;

  items: Counterlist[];
  origItems: string;

  cols: any[];

  exportColumns: any[];

  constructor(private wapService: WapService,
              private globals: Globals,
              private objService: ObjectService,
              private router: Router,
              private confirmationService: ConfirmationService,
              public datepipe: DatePipe) {
  }

  ngOnInit(): void {

    this.cols = [
      {field: 'accessPointID', header: 'Wasseranschluss'},
      {field: 'address', header: 'Adresse'},
      {field: 'city', header: 'Ort'},
      {field: 'owner', header: 'Eigentümer'},
      {field: 'gaugeSerial', header: 'Zählernummer'},
      {field: 'lastCount', header: 'Alter Zählerstand'},

      {field: 'currentCount', header: 'Zählerstand'},
      {field: 'readingDate', header: 'Ablesedatum'},

    ];

    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));

    this.loadList();
  }

  canDeactivate(): Observable<boolean> | boolean {
    console.log('canDeactivate');
    var list = JSON.stringify(this.items);
    if (list != this.origItems) {
      const subject = new Subject<boolean>();
      this.confirmationService.confirm({
        message: 'Zählerliste wurde verändert. Änderungen verwerfen?',
        header: 'Warnung',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        key: 'confirm',
        rejectVisible: true,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          subject.next(true);
        },
        reject: () => {
          subject.next(false);
        }

      });
      return subject.asObservable();
    }
    return true;
  }

  loadList() {
    this.wapService.getCounterList(false, false, false).subscribe((respo) => {
      this.items = respo;

      this.objService.load<Array<CounterListItem>>('counter-list').subscribe((resp) => {
        this.mergeList(resp);
        this.origItems = JSON.stringify(this.items);
      });

    });
  }

  mergeList(list: Array<CounterListItem>) {
    this.items.forEach(i => {


      var item = list.find(li => li.serial == i.gaugeSerial);
      if (item && item.count > 0) {
        i.currentCount = item.count;
        i.readingDate = this.datepipe.transform(item.date, 'dd.MM.yyyy');
      }

      if (i.currentCount > 0 && i.lastCount == i.currentCount)
        i.status = 1;
      else if (i.lastCount > i.currentCount && i.currentCount > 0)
        i.status = 2;
      else if (i.currentCount == 0)
        i.status = 3;
      else if (i.lastCount < i.currentCount)
        i.status = 4;

    });
  }

  clearList() {
    this.items.forEach(i => {
      i.currentCount = 0;
      i.readingDate = '';
    });
  }

  onSave() {
    var cl = new Array<CounterListItem>(); // new CounterListItem[this.items.length];

    this.items.forEach(i => {
      var date: Date;
      date = null;
      if (i.readingDate && i.readingDate != '') {
        date = this.globals.parseDate(i.readingDate, 'dd mm yyyy');
      }
      let item = new CounterListItem(i.gaugeSerial, i.currentCount, date);
      cl.push(item);
    })

    this.objService.save<Array<CounterListItem>>('counter-list', cl).subscribe((resp) => {
      console.log("saved")
      this.loadList();
    });

  }

  onClear() {
    var cl = new Array<CounterListItem>(); // new CounterListItem[this.items.length];

    this.items.forEach(i => {
      let item = new CounterListItem(i.gaugeSerial, 0, null);
      cl.push(item);
    })

    this.objService.save<Array<CounterListItem>>('counter-list', cl).subscribe((resp) => {
      this.clearList();
    });
  }

  onImport() {
    var li = new Array();
    var fail = false;
    this.items.forEach((item) => {
      try {
        if (item.currentCount > item.lastCount) {
          var o = Object.assign(new Counterlist(), item);
          //o.readingDate = this.globals.transformDateToDatabase(item.readingDate);
          o.readingDate2 = Math.round(new Date(item.readingDate).getTime() / 1000);
          li.push(o);
        }
      } catch {
        fail = true;
      }
    });

    if (!fail && li.length == this.items.length) {
      this.wapService.sendCounterList(li).subscribe((e) => {
        console.log("sent " + e.toString() + " items");
        this.loadList();
      });
    } else {
      console.log(li);
      this.confirmationService.confirm({
        message: 'Liste ist nicht vollständig. Fortfahren?',
        header: 'Fehler in Liste',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        key: 'confirm',
        rejectVisible: true,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.wapService.sendCounterList(li).subscribe((e) => {
            console.log("sent " + e.toString() + " items");
            this.loadList();
          });

        }
      });

    }

    //this.items.forEach((item) => {
    //  item.readingDate = this.globals.transformDateFromDatabase(item.readingDate);
    //});
  }

  onPrint() {
    this.exportPdf();
  }

  exportPdf() {
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        //@ts-ignore
        const doc = new jsPDF.default("l", 0, 0);
        doc.setLineWidth(0.5);
        if (!this.showOnlyMissing) {
          //@ts-ignore
          doc.autoTable(this.exportColumns, this.items, {
            tableLineColor: [0, 0, 0],
            tableLinewidth: 1.5
          });
        } else {
          var list = this.items.reduce((ids, item) => {
            if (item.currentCount == 0) {
              ids.push(item);
            }
            return ids;
          }, []);

          //@ts-ignore
          doc.autoTable(this.exportColumns, list);
        }
        doc.save('zaehlerliste.pdf');
      })
    })
  }

  selectItem(item) {
    console.log(item);
    this.router.navigate(['wap-module', 'wap', item.wapId, 'edit']);
  }

}
