<div class="surface-card  pt-4 pl-2 shadow-2 border-round mb-2" *ngIf="!isLoading">
    <div class="mb-3 flex align-items-center justify-content-between">
        <div>
            <span class="text-xl font-medium text-900 mx-3">Ein-/Ausgaben</span>
        </div>


    </div>

    <div class="grid formgrid p-fluid">
        
    </div>
</div>
