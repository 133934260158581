import {Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import {Router} from "@angular/router";
import {Globals} from "../globals";

@Injectable({
  providedIn: 'root'
})
export class DocDbService {

  private readonly APIUrl = this.globals.APP_URL + "DocDb";

  constructor(protected globals: Globals,
              protected router: Router,
              protected http: HttpClient) {
  }

  queryItems<T>(query: string) {
    let url = this.APIUrl + '/query';
    let params = new HttpParams();
    params = params.append('query', query);
    return this.http.get<T[]>(url, {params: params});
  }

  putItem<T>(p: T, table: string) {
    let url = this.APIUrl + '/' + table;
    return this.http.put<T>(url, p);
  }

  postItem<T>(p: T, table: string) {
    let url = this.APIUrl + '/' + table;
    return this.http.post<T>(url, p);
  }

  readItem<T>(id: string) {
    const url = this.APIUrl + '/' + id;
    return this.http.get<T>(url);
  }

  deleteItem<T>(id: string | null, rev: string | null) {
    const url = this.APIUrl + '/' + id + '/' + rev;
    return this.http.delete(url);
  }
}
