import {EntityBase} from "../../shared/entity.base";

export class WaterbillSetting extends EntityBase {

  name: string;
  header: string;
  footer: string;

  waterProductId: number;
  freeUsage: number;
  waterDiscount: number;

  feeProductId: number;
  feeCount: number;
  feeDiscount: number;

  addProductId: number;
  addCount: number;
  addDiscount: number;

  skonto: number;
  skontoDays: number;
  paymentDays: number;
  paymentMethod: string;

  printGaugeData: boolean;

  exeedQuotaProductId: number;
  exeedQuotaDiscount: number;

  public constructor() {
    super();

    this.name = "";
    this.header = "";
    this.footer = "";

    this.waterProductId = 0;
    this.freeUsage = 0;
    this.waterDiscount = 0;


    this.feeCount = 0;
    this.feeDiscount = 0;
    this.feeProductId = 0;

    this.addCount = 0;
    this.addDiscount = 0;
    this.addProductId = 0;

    this.paymentDays = 0;
    this.paymentMethod = "";
    this.skonto = 0;
    this.skontoDays = 0;

    this.printGaugeData = false;

    this.exeedQuotaDiscount = 0;
    this.exeedQuotaProductId = 0;

  }

}
