import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs';

import { Globals } from '../../globals';
import { Product, IdResponse, ProductImport } from '../../shared/product.model';

@Injectable({ providedIn: 'root' })
export class ProductService {
  products = new Subject<Product[]>();
  productArray = new Array<Product>();

  totalProducts: number = 0;

  selectedProduct: Product = null;

  detailActive = '';
  listActive = 'active';

  isCopy = false;

  constructor(
    private globals: Globals,
    private router: Router,
    private http: HttpClient
  ) {}

  getNewId() {
    const url = this.globals.APP_URL + 'products/newid';
    return this.http.get<IdResponse>(url, {
      observe: 'response',
    });
  }

  getNextNumber() {
    const url = this.globals.APP_URL + 'numberrange/products/next-number';
    return this.http.get<{ id: string }>(url);
  }

  getProducts(search: string, pageSize: number, page: number) {
    const url = this.globals.APP_URL + 'products';
    let params = new HttpParams();
    params = params.append('search', search);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('page', page.toString());
    this.http
      .get<Product[]>(url, {
        params: params,
        observe: 'response',
      })
      .subscribe((respData) => {
        this.totalProducts = parseInt(respData.headers.get('x-total-count'));
        this.productArray = respData.body.slice();
        this.products.next(this.productArray);
      });
  }

  updateProduct(p: Product) {
    const url = this.globals.APP_URL + 'products';
    this.http.put<Product>(url, p).subscribe((respData) => {
      this.selectedProduct = respData;
      const idx = this.productArray.findIndex((x) => x.id === p.id);
      this.productArray[idx] = respData;
      this.router.navigate([
        'masterdata',
        'products',
        this.selectedProduct.id
      ]);
    });
  }

  addProduct(p: Product) {
    const url = this.globals.APP_URL + 'products';
    this.http.post<Product>(url, p).subscribe((respData) => {
      this.selectedProduct = respData;
      this.productArray.push(respData);
      this.router.navigate([
        'masterdata',
        'products',
        this.selectedProduct.id
      ]);
    });
  }

  readProduct(id: number) {
    const url = this.globals.APP_URL + 'products/' + id;
    return this.http.get<Product>(url);
  }

  getProduct(id: number) {
    //this.selectedProduct = this.productArray.find((p) => p.id === id);
    this.selectedProduct = Object.assign(
      new Product(),
      this.productArray.find((p) => p.id === id)
    );
    return this.selectedProduct;
  }

  deleteProduct(id: number) {
    const url = this.globals.APP_URL + 'products/' + id;
    return this.http.delete < { 'value':'' }>(url);
  }

  importProducts(p: ProductImport[]) {
    const url = this.globals.APP_URL + 'products/import';
    return this.http.post<ProductImport[]>(url, p);
  }
}
