import {DatePipe} from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {Globals} from "../globals";
import {EntityService} from "../shared/entity.service";
import {TaxReport, TaxReportEntry, Transaction} from './transaction.model'
import {MonthEndClosing} from './monthEndClosing.model'
import {BalanceData} from './balanceData.model'
import {TransactionTemplate} from "./transaction-template.model";


@Injectable({providedIn: 'root'})
export class TransactionService extends EntityService<Transaction> {

  balancingImportText: string = '';
  isBalancingStart: boolean = true;
  importItems: Transaction[] = new Array();

  constructor(protected globals: Globals,
              protected router: Router,
              private datePipe: DatePipe,
              protected http: HttpClient) {
    super(globals, router, http);

  }

  getResourceUrl(): string {
    return 'transactions';
  }

  getEditRoute(id: number): any[] {
    return ['finance/transactions']
  }

  search(searchString: string, from: Date, to: Date, accountId: number) {
    let url = this.APIUrl + '/search';
    let params = new HttpParams();
    params = params.append('search', searchString);
    params = params.append('from', this.datePipe.transform(from, 'dd.MM.yyyy'));
    params = params.append('to', this.datePipe.transform(to, 'dd.MM.yyyy'));
    params = params.append('account', accountId);
    return this.http.get<Transaction[]>(url, {params: params});
  }

  rebook(t1: Transaction, t2: Transaction) {
    var arr = new Array();
    arr.push(t1);
    arr.push(t2);

    let url = this.APIUrl + '/rebook';
    return this.http.post(url, arr);
  }

  getNextMonthEndClosing(accountId: number) {
    let url = this.APIUrl + '/next-month-end-closing';
    let params = new HttpParams();
    params = params.append('account', accountId);
    return this.http.get<MonthEndClosing>(url, {params: params});
  }

  getLastMonthEndClosingDate(accountId: number) {
    let url = this.APIUrl + '/last-month-end-closing';
    let params = new HttpParams();
    params = params.append('account', accountId);
    return this.http.get<number>(url, {params: params});
  }

  getMonthEndClosings(accountId: number) {
    let url = this.APIUrl + '/month-end-closings';
    let params = new HttpParams();
    params = params.append('account', accountId);
    return this.http.get<MonthEndClosing[]>(url, {params: params});
  }

  createMonthEndClosing(mec: MonthEndClosing) {
    let url = this.APIUrl + '/month-end-closing';
    return this.http.post<MonthEndClosing>(url, mec);
  }

  updateMonthEndClosing(mec: MonthEndClosing) {
    let url = this.APIUrl + '/month-end-closing';
    return this.http.put<MonthEndClosing>(url, mec);
  }

  getAccountSummary(accountId: number) {
    let url = this.APIUrl + '/account-summary';
    let params = new HttpParams();
    params = params.append('account', accountId);
    return this.http.get<BalanceData>(url, {params: params});
  }

  getTaxReport(from: Date, to: Date) {
    let url = this.APIUrl + '/tax-report';
    let params = new HttpParams();
    params = params.append('from', Math.round(from.getTime() / 1000).toString());
    params = params.append('to', Math.round(to.getTime() / 1000).toString());
    return this.http.get<TaxReport>(url, {params: params});
  }

  getTemplates(searchString: string = '') {
    let url = this.APIUrl + '/templates';
    let params = new HttpParams();
    params = params.append('search', searchString);
    return this.http.get<TransactionTemplate[]>(url, {params: params});
  }

  postTemplate(t: TransactionTemplate) {
    let url = this.APIUrl + '/templates';
    return this.http.post<TransactionTemplate>(url, t);
  }

  putTemplate(t: TransactionTemplate) {
    let url = this.APIUrl + '/templates';
    return this.http.put<TransactionTemplate>(url, t);
  }

  deleteTemplate(id: number) {
    let url = this.APIUrl + '/templates/' + id;
    return this.http.delete(url);
  }
}
