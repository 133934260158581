<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-toast position="bottom-right" key="tl"></p-toast>

<p-dialog [(visible)]="display" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '60vw'}">
    <ng-template pTemplate="header">
        <div class="flex align-items-center">
            <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style="width:32px;height:32px">
                <i class="pi pi-info text-lg"></i>
            </span>
            <span class="font-medium text-2xl text-900">{{title}}</span>
        </div>
    </ng-template>

    <div class="grid formgrid p-fluid">

        <div class="field mb-3 col-6">
            <span class="font-medium text-600">Rechnungsnummer: </span>
            <label class="font-medium text-900">{{selectedEntity.invoiceID}}</label>

        </div>

        <div class="field mb-3 col-6">
            <span class="font-medium text-600">Name: </span>
            <label class="font-medium text-900">
                {{
                selectedEntity.orgName || selectedEntity.firstName ?
                selectedEntity.orgName
                ? selectedEntity.orgName
                : selectedEntity.firstName + ((selectedEntity.firstName && selectedEntity.lastName) ? " " : "") + selectedEntity.lastName
                : selectedEntity.completeAddress.substring(0, selectedEntity.completeAddress.indexOf('\n'))
                }}
            </label>

        </div>

        <div class="field mb-3 col-6">
            <span class="font-medium text-600">Rechnungsdatum: </span>
            <label class="font-medium text-900">{{selectedEntity.invoiceDate | customDate}}</label>

        </div>

        <div class="field mb-3 col-6">
            <span class="font-medium text-600">Rechnungsbetrag: </span>
            <label class="font-medium text-900">{{selectedEntity.total | currency:'EUR':'symbol':'1.2-2'}}</label>

        </div>


        <div class="field mb-3 col-12 md:col-4">
            <label for="end" class="font-medium text-900">Buchungsdatum</label>
            <p-calendar id="end" appendTo="body" dateFormat="dd.mm.yy" [(ngModel)]="payDate"></p-calendar>
        </div>

        <div class="field mb-3 col-12 md:col-4">
            <label for="payDay" class="font-medium text-900">Zahlbetrag</label>
            <p-inputNumber id="payDay" [(ngModel)]="amount" mode="currency" currency="EUR" locale="de-DE"></p-inputNumber>
        </div>

        <div class="field mb-3 col-4">
            <label for="name" class="font-medium text-900">Konto</label>
            <p-dropdown [options]="accounts" [(ngModel)]="accountId" optionLabel="name" optionValue="id" appendTo="body"></p-dropdown>
        </div>

        <div class="field mb-3 col-8">
            <label for="name" class="font-medium text-900">Buchungstext</label>
            <input id="name" type="text" pInputText [(ngModel)]="text" autocomplete="off">
        </div>
        <div class="field mb-3 col-4">
            <label for="name" class="font-medium text-900">Kostenstelle</label>
            <p-dropdown id="costCenters" [options]="costCenters" [(ngModel)]="costCenter" optionLabel="key" optionValue="key" appendTo="body"></p-dropdown>
        </div>

    </div>

    <ng-template pTemplate="footer">
        <div class=" surface-border">
            <button pButton pRipple icon="pi pi-times" (click)="display = false" label="Abbrechen"></button>
            <button pButton pRipple icon="pi pi-check" (click)="onSave()" label="Jetzt buchen"></button>
        </div>
    </ng-template>
</p-dialog>

<div class="surface-card  pt-4 pl-2 shadow-2 border-round mb-2" *ngIf="!isLoading">
    <div class="mb-3 flex align-items-center justify-content-between">
        <div>
            <span class="text-xl font-medium text-900 mx-3">Offene Posten</span>
        </div>

        <div>
        </div>
    </div>

    <div>
        <ul class="surface-card p-0 m-0 list-none flex overflow-x-auto select-none my-3">
            <li class="px-2">
                <a pRipple class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150"
                   [ngClass]="{'tab-selected': active1 === 0, 'text-700 border-transparent no-ul': active1 !== 0}" (click)="active1 = 0">
                    <i class="pi pi-chevron-circle-down mr-2"></i>
                    <span class="font-medium">Eingangsrechnungen</span>
                </a>
            </li>
            <li class="flex align-items-center">
                <div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
            </li>
            <li class="px-3">
                <a pRipple class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150"
                   [ngClass]="{'tab-selected': active1 === 1, 'text-700 border-transparent no-ul': active1 !== 1}" (click)="active1 = 1">
                    <i class="pi pi-chevron-circle-up mr-2"></i>
                    <span class="font-medium">Ausgangsrechnungen</span>
                </a>
            </li>


        </ul>
    </div>


    <div *ngIf="active1 == 0" class="p-4">
        <p-table [value]="debit" dataKey="id" responsiveLayout="scroll"
                 styleClass="p-datatable-striped">
            <ng-template pTemplate="header">
                <tr>
                    <th>Re-Nr.</th>
                    <th>Lieferant</th>
                    <th>Re-Dat.</th>
                    <th style="text-align:right;">Betrag</th>
                    <th style="text-align:right;">Fällig</th>

                    <th style="text-align:right;" class="px-4">Löschen/Buchen</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-debitBill>
                <tr [pSelectableRow]="debitBill">
                    <td class="table-users-id">{{ debitBill.invoiceID }}</td>
                    <td class="table-users-name">
                        {{
                        debitBill.orgName || debitBill.firstName ?
                        debitBill.orgName
                        ? debitBill.orgName
                        : debitBill.firstName + ((debitBill.firstName && debitBill.lastName) ? " " : "") + debitBill.lastName
                        : debitBill.completeAddress.substring(0, debitBill.completeAddress.indexOf('\n'))
                        }}
                    </td>
                    <td class="table-users-id">{{ debitBill.invoiceDate | customDate }}</td>
                    <td class="table-users-id" style="text-align:right;">{{ debitBill.total | currency:'EUR' }}</td>
                    <td class="table-users-id" style="text-align:right;">{{ debitBill.dueDate | customDate }}</td>


                    <td style="text-align:right;">
                        <p-button icon="pi pi-trash" class="p-button-rounded p-button-success mr-2"></p-button>
                      <p-button icon="pi pi-check-circle" class="p-button-rounded p-button-success mr-2" (click)="onBook(debitBill)"></p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </div>

    <div *ngIf="active1 == 1" class="p-4">
        <p-table [value]="billing" dataKey="id" responsiveLayout="scroll"
                 styleClass="p-datatable-striped">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="invoiceID">Re-Nr. <p-sortIcon field="invoiceID"></p-sortIcon></th>
                    <th>Kunde</th>
                    <th pSortableColumn="invoiceDate">Re-Dat. <p-sortIcon field="invoiceDate"></p-sortIcon></th>
                    <th pSortableColumn="total" style="text-align:right;">Betrag <p-sortIcon field="total"></p-sortIcon></th>
                    <th pSortableColumn="dueDate" style="text-align:right;">Fällig <p-sortIcon field="dueDate"></p-sortIcon></th>

                    <th style="text-align:right;" class="px-4">Löschen/Buchen</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-billingBill>
                <tr [pSelectableRow]="billingBill">
                    <td class="table-users-id">{{ billingBill.invoiceID }}</td>
                    <td class="table-users-name">
                        {{
                        billingBill.orgName || billingBill.firstName ?
                        billingBill.orgName
                        ? billingBill.orgName
                        : billingBill.firstName + ((billingBill.firstName && billingBill.lastName) ? " " : "") + billingBill.lastName
                        : billingBill.completeAddress.substring(0, billingBill.completeAddress.indexOf('\n'))
                        }}
                    </td>
                    <td class="table-users-id">{{ billingBill.invoiceDate | customDate }}</td>
                    <td class="table-users-id" style="text-align:right;">{{ billingBill.total | currency:'EUR' }}</td>
                    <td class="table-users-id" style="text-align:right;">{{ billingBill.dueDate | customDate }}</td>

                    <td style="text-align:right;">
                      <p-button icon="pi pi-trash" class="p-button-rounded p-button-success mr-2" (click)="onDelete(billingBill)"></p-button>
                      <p-button icon="pi pi-check-circle" class="p-button-rounded p-button-success mr-2" (click)="onBook(billingBill)"></p-button>
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </div>
</div>


