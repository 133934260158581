import {Component, OnInit, ViewChild} from '@angular/core';
import {DatePipe} from '@angular/common';
import {NgForm} from '@angular/forms';
import {MemberService} from '../member.service';
import {Contact} from '../../shared/contact.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Globals} from '../../globals';
import {WapService} from '../../water-access-point/wap.service';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PaymentTermsService} from '../../settings/paymentterms/payment-terms.service';
import {PaymentTerms} from '../../shared/payment-terms.model';
import {Observable, Subject} from "rxjs";

@Component({
  selector: 'app-edit-member',
  templateUrl: './edit-member.component.html',
  styleUrls: ['./edit-member.component.css'],
})
export class EditMemberComponent implements OnInit {
  @ViewChild('contactData', {static: true}) contactForm: NgForm;
  @ViewChild('paymentData', {static: true}) paymentForm: NgForm;
  @ViewChild('otherData', {static: true}) otherForm: NgForm;

  id: number;
  contact: Contact;
  editMode = false;

  active1: number = 0;

  wapCount: number = 0;

  paymentTerms: PaymentTerms[] = new Array();
  paymentTermsId: number = 0;

  memberSince: Date;
  sepaDate: Date;

  contactOptions: any[];

  fullName: string = '';

  origData: string = '';

  constructor(
    private memberService: MemberService,
    private route: ActivatedRoute,
    private router: Router,
    public globals: Globals,
    public datepipe: DatePipe,
    private confirmationService: ConfirmationService,
    public wapService: WapService,
    private messageService: MessageService,
    public paymentTermsService: PaymentTermsService
  ) {
    this.contactOptions = [{label: 'Person', value: 'person'}, {label: 'Organisation', value: 'company'}];
  }

  ngOnInit(): void {
    this.contact = this.memberService.selectedMember;


    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.editMode = params['id'] != 'new';

      this.initForm();
    });


  }

  initForm() {
    if (this.editMode) {
      this.memberService.readMember(this.id).subscribe(resp => {

        this.contact = Object.assign(new Contact, resp);

        if (this.contact.contactType != null && this.contact.contactType == '') {
          this.contact.contactType = this.contact.orgName != '' ? 'company' : 'person';
        }
        var date2 = new Date(this.contact.mandateIssued2 * 1000);
        if (date2 > new Date(1901, 1, 1))
          this.sepaDate = date2;
        else
          this.sepaDate = null;
        var date = new Date(this.contact.created2 * 1000);

        if (date > new Date(1901, 1, 1))
          this.memberSince = date;
        else
          this.memberSince = null;

        this.paymentTermsId = this.contact.paymentTermsId;
        this.readWapCount();

        this.origData = JSON.stringify(this.contact);
      });

    } else {
      this.sepaDate = null;
      this.contact = new Contact();
      this.contact.role = 'Member';
      this.contact.active = true;
      this.contact.created = this.datepipe.transform(new Date(), 'MM.dd.yyyy');
      this.contact.mandateIssued = this.datepipe.transform(new Date(), 'MM.dd.yyyy');

      this.memberSince = new Date();
      this.contact.created2 = Math.round(this.memberSince.getTime() / 1000);
      this.memberService.getNextNumber().subscribe(r => {
        this.contact.contactID = r.id;
        this.fillForm();
        this.origData = JSON.stringify(this.contact);
      })

    }
  }

  readWapCount() {
    this.wapService.getWapCountByMember(this.contact.id).subscribe((resp) => {
      this.wapCount = resp.body;
    });
  }

  fillForm() {
    if (this.contact) {
      var date = new Date(this.contact.created2 * 1000); // this.globals.parseDate(this.contact.created, "mm dd yyyy");
      var date2 = new Date(this.contact.mandateIssued2 * 1000);

      this.contactForm.setValue({
        memberid: this.contact.contactID,
        active: this.contact.active,
        firstName: this.contact.firstName,
        lastName: this.contact.lastName,
        email: this.contact.email,
        email2: this.contact.email2,
        organization: this.contact.orgName,
        address1: this.contact.address1,
        address2: this.contact.address2,
        zip: this.contact.zip,
        city: this.contact.city,
        phone: this.contact.telephone,
        phone2: this.contact.telephone2,
        mobile: this.contact.mobile,
        fax: this.contact.fax,

        wapCount: 0
      });


      if (date > new Date(1801, 1, 1))
        this.memberSince = date;
      else
        this.memberSince = null;
      if (date2 > new Date(1901, 1, 1))
        this.sepaDate = date2;
      else
        this.sepaDate = null;


      this.paymentForm.setValue({
        bankname: this.contact.bankeName,
        iban: this.contact.iban,
        bic: this.contact.bic,
        uid: this.contact.vat,
        emailInvoice: this.contact.emailInvoice,
        isEInvoice: this.contact.isEInvoice,
        isDebit: this.contact.isDebit,
        sepaDirectDebit: this.contact.directDebitMandate,
        paymentTerms: 0
      });

      this.otherForm.setValue({
        notes: this.contact.notes,
        field1: this.contact.field1,
        field2: this.contact.field2,
      });

      this.paymentTermsId = this.contact.paymentTermsId;
      this.readWapCount();
    } else {
      this.router.navigate(['members']);
    }
  }


  onSave() {

    if (this.sepaDate)
      this.contact.mandateIssued2 = Math.round(this.sepaDate.getTime() / 1000);

    if (this.memberSince)
      this.contact.created2 = Math.round(this.memberSince.getTime() / 1000);

    if (!this.contact.active) {
      this.contact.active = false;
    }

    if (this.editMode) {
      this.memberService.updateMember(this.contact);
      this.messageService.add({key: 'tl', severity: 'info', summary: 'Mitgliedsdaten wurden gespeichert.'});
    } else {
      this.memberService.addMember(this.contact);
      this.messageService.add({key: 'tl', severity: 'info', summary: 'Neues Mitglied wurde angelegt.'});
    }
    this.origData = JSON.stringify(this.contact);
  }

  onDelete() {
    this.confirmationService.confirm({
      message: 'Möchten Sie dieses Mitglied wirklich löschen?',
      header: 'Mitglied löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      key: 'confirm',
      rejectVisible: true,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.memberService.deleteMember(this.contact.id).subscribe((r) => {
          if (r && r.value.length > 0) {
            this.confirmationService.confirm({
              message: r.value,
              header: 'Fehler beim Löschen!',
              acceptLabel: 'OK',
              key: 'info',
              rejectVisible: false,
              accept: () => {
                console.log('log');
              }
            });
          } else {
            this.memberService.removeContactFromList(this.contact);
            this.contact = null;
            this.router.navigate(['members-module', 'members']);
          }
        });
      },
    });
  }

  onNavigateToWaps() {
    if (this.wapCount > 0 && this.contact != null) {
      this.router.navigate(['wap-module', 'wap'], {queryParams: {memberid: this.contact.id}});
    }
  }

  Back() {
    this.router.navigate(['members-module', 'members']);
  }

  Delete() {
    this.onDelete();
  }

  Save() {
    if (this.sepaDate)
      this.contact.mandateIssued = this.datepipe.transform(this.sepaDate, 'MM.dd.yyyy');
    this.contact.created = this.datepipe.transform(this.memberSince, 'MM.dd.yyyy');

    var currentData = JSON.stringify(this.contact);
    if (currentData != this.origData)
      this.onSave();
    else
      this.messageService.add({key: 'tl', severity: 'info', summary: 'Mitgliedsdaten wurden nicht verändert.'});
  }

  canDeactivate(): Observable<boolean> | boolean {

    if (this.contact == null)
      return true;

    if (this.sepaDate)
      this.contact.mandateIssued = this.datepipe.transform(this.sepaDate, 'MM.dd.yyyy');

    if (this.memberSince)
      this.contact.created = this.datepipe.transform(this.memberSince, 'MM.dd.yyyy');

    var currentData = JSON.stringify(this.contact);
    if (currentData != this.origData) {
      const subject = new Subject<boolean>();
      this.confirmationService.confirm({
        message: 'Mitgliedsdaten wurde verändert. Änderungen verwerfen?',
        header: 'Warnung',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        key: 'confirm',
        rejectVisible: true,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          subject.next(true);
        },
        reject: () => {
          subject.next(false);
        }

      });
      return subject.asObservable();
    }
    return true;
  }
}
