
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'yesnoConverter'
})
export class BooleanYesnoConverter  implements PipeTransform {
  transform(value: any): any {
    return value ? 'Ja' : 'Nein';
  }
}
