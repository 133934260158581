import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppUser } from '../../../auth/appuser.model';
import { AppUserService } from '../../../auth/appuser.service';

@Component({
  selector: 'app-access-settings-list',
  templateUrl: './access-settings-list.component.html',
  styleUrls: ['./access-settings-list.component.css']
})
export class AccessSettingsListComponent implements OnInit {

  subItem: Subscription;
  items: AppUser[];
  selectedItem: AppUser;
  isLoading = false;

  searchString: string = '';

  totalItems: number;

  constructor(private router: Router, public entityService: AppUserService) { }

  ngOnInit(): void {
    this.entityService.detailActive = '';
    this.entityService.listActive = 'active';

    this.totalItems = this.entityService.totalItems;
    this.items = this.entityService.itemArray;

    if (!this.items?.length) this.isLoading = true;

    this.subItem = this.entityService.itemList.subscribe(d => {
      this.items = d;
      this.isLoading = false;
      this.totalItems = this.entityService.totalItems;
    });

    if (!this.items?.length) this.entityService.getItems('', 10, 1);
  }

  ngOnDestroy() {
    this.subItem.unsubscribe();
  }

  onSubmit(form: NgForm) {
    this.entityService.getItems(this.searchString, 10, 1);
  }

  New() {
    this.router.navigate(['settings', 'access', 'new']);
  }


  paginate(event) {
    this.entityService.getItems(this.searchString, 10, event.page + 1);
  }

  
  onSelected2(event) {
    this.entityService.selectedItem = this.selectedItem;
    this.router.navigate(['settings', 'access', this.selectedItem.id]);

    this.entityService.detailActive = 'active';
    this.entityService.listActive = '';
  }


}
