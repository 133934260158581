import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {MembersComponent} from './members/members.component';
import {MemberStartComponent} from './members/member-start/member-start.component';
import {WaterAccessPointComponent} from './water-access-point/water-access-point.component';
import {WapStartComponent} from './water-access-point/wap-start/wap-start.component';
import {WapEditComponent} from './water-access-point/wap-edit/wap-edit.component';
import {WapListComponent} from './water-access-point/wap-list/wap-list.component';
import {SubwapEditComponent} from './water-access-point/wap-edit/subwap-edit/subwap-edit.component';
import {SettingsComponent} from './settings/settings.component';
import {MasterdataComponent} from './masterdata/masterdata.component';
import {AuthComponent} from './auth/auth.component';
import {AuthGuard} from './auth/auth.guard';
import {EditMemberComponent} from './members/edit-member/edit-member.component';
import {HomeComponent} from './home/home.component';
import {ProductsComponent} from './masterdata/products/products.component';
import {ProductListComponent} from './masterdata/products/product-list/product-list.component';
import {CustomerComponent} from './masterdata/customer/customer.component';
import {ProductEditComponent} from './masterdata/products/product-edit/product-edit.component';
import {OrganizationSettingsComponent} from './settings/organization-settings/organization-settings.component';
import {AccessSettingsComponent} from './settings/access-settings/access-settings.component';

import {GaugesComponent} from './masterdata/gauges/gauges.component';
import {GaugeListComponent} from './masterdata/gauges/gauge-list/gauge-list.component';
import {GaugeEditComponent} from './masterdata/gauges/gauge-edit/gauge-edit.component';
import {CustomerListComponent} from './masterdata/customer/customer-list/customer-list.component';
import {CustomerEditComponent} from './masterdata/customer/customer-edit/customer-edit.component';
import {JobsComponent} from './jobs/jobs.component';
import {InvoiceComponent} from './jobs/billing/invoice.component';
import {ListeditStartComponent} from './shared/listedit-start/listedit-start.component';
import {InvoiceEditComponent} from './jobs/billing/invoice-edit/invoice-edit.component';
import {ServicesComponent} from './services/services.component';
import {DmsComponent} from './services/dms/dms.component';
import {DmsFileSharingComponent} from './services/dms/dms-file-sharing/dms-file-sharing.component'
import {MaintenanceComponent} from './services/maintenance/maintenance.component';
import {TasksComponent} from './services/tasks/tasks.component';
import {WapUsageComponent} from './water-access-point/wap-usage/wap-usage.component';
import {DmsEditComponent} from './services/dms/dms-edit/dms-edit.component';
import {DmsStartComponent} from './services/dms/dms-start/dms-start.component';
import {WaterbillComponent} from './jobs/waterbill/waterbill.component';
import {WaterbillCreateComponent} from './jobs/waterbill/waterbill-create/waterbill-create.component';
import {InvoiceViewComponent} from './jobs/billing/invoice-view/invoice-view.component';
import {SupplierComponent} from './masterdata/supplier/supplier.component';
import {SupplierListComponent} from './masterdata/supplier/supplier-list/supplier-list.component';
import {SupplierEditComponent} from './masterdata/supplier/supplier-edit/supplier-edit.component';
import {TaskStartComponent} from './services/tasks/task-start/task-start.component';
import {TaskEditComponent} from './services/tasks/task-edit/task-edit.component';
import {EquipmentComponent} from './masterdata/equipment/equipment.component';
import {EquipmentListComponent} from './masterdata/equipment/equipment-list/equipment-list.component';
import {EquipmentEditComponent} from './masterdata/equipment/equipment-edit/equipment-edit.component';
import {KvpSettingsComponent} from './shared/kvp-settings/kvp-settings.component';
import {KVP_GROUPS} from './globals';
import {
  AccessSettingsListComponent
} from './settings/access-settings/access-settings-list/access-settings-list.component';
import {
  AccessSettingsEditComponent
} from './settings/access-settings/access-settings-edit/access-settings-edit.component';
import {MaintenancePlanComponent} from './services/maintenance/maintenance-plan/maintenance-plan.component';
import {
  MaintenancePlanListComponent
} from './services/maintenance/maintenance-plan/maintenance-plan-list/maintenance-plan-list.component';
import {
  MaintenancePlanEditComponent
} from './services/maintenance/maintenance-plan/maintenance-plan-edit/maintenance-plan-edit.component';
import {
  MaintenanceWorkEditComponent
} from './services/maintenance/maintenance-work/maintenance-work-edit/maintenance-work-edit.component';
import {
  MaintenanceWorkListComponent
} from './services/maintenance/maintenance-work/maintenance-work-list/maintenance-work-list.component';
import {
  MaintenanceWorkHistoryComponent
} from './services/maintenance/maintenance-work/maintenance-work-history/maintenance-work-history.component';
import {DebitComponent} from './jobs/debit/debit.component';
import {DebitEditComponent} from './jobs/debit/debit-edit/debit-edit.component';
import {WaterbillSettingsComponent} from './settings/waterbill-settings/waterbill-settings.component';
import {
  WaterbillSettingsListComponent
} from './settings/waterbill-settings/waterbill-settings-list/waterbill-settings-list.component';
import {
  WaterbillSettingsEditComponent
} from './settings/waterbill-settings/waterbill-settings-edit/waterbill-settings-edit.component';
import {CounterListComponent} from './services/counter-list/counter-list.component';
import {CounterListEditComponent} from './services/counter-list/counter-list-edit/counter-list-edit.component';
import {CounterListImportComponent} from './services/counter-list/counter-list-import/counter-list-import.component';
import {CounterListMessageComponent} from './services/counter-list/counter-list-message/counter-list-message.component';
import {DataImportComponent} from './settings/data-import/data-import.component';
import {AccountsComponent} from './settings/accounts/accounts.component';
import {AccountListComponent} from './settings/accounts/account-list/account-list.component';
import {AccountEditComponent} from './settings/accounts/account-edit/account-edit.component';
import {FinanceComponent} from './finance/finance.component';
import {PaymenttermsComponent} from './settings/paymentterms/paymentterms.component';
import {DmsListComponent} from './services/dms/dms-list/dms-list.component';
import {TaskListComponent} from './services/tasks/task-list/task-list.component';
import {InvoiceListComponent} from './jobs/billing/invoice-list/invoice-list.component';
import {DebitListComponent} from './jobs/debit/debit-list/debit-list.component';
import {WaterbillListComponent} from './jobs/waterbill/waterbill-list/waterbill-list.component';
import {MemberListComponent} from './members/member-list/member-list.component';
import {CanDeactivateGuard} from './auth/candeactivate.guard';
import {NumberrangeComponent} from './settings/numberrange/numberrange.component';
import {PageNotFoundComponent} from './shared/page-not-found/page-not-found.component';
import {TransactionsComponent} from './finance/transactions/transactions.component';
import {CashBookComponent} from './finance/cash-book/cash-book.component';
import {OpenInvoicesComponent} from './finance/open-invoices/open-invoices.component';
import {DmsArchiveComponent} from './services/dms/dms-archive/dms-archive.component';
import {DmsRecycleBinComponent} from './services/dms/dms-recycle-bin/dms-recycle-bin.component';
import {FinancialYearsComponent} from './settings/financial-years/financial-years.component';
import {AppHelpComponent} from './app-help/app-help.component';
import {TicketsComponent} from './app-help/tickets/tickets.component';
import {FaqComponent} from './app-help/faq/faq.component';
import {VideosComponent} from './app-help/videos/videos.component';
import {SubscriptionComponent} from './app-help/subscription/subscription.component';
import {LicenseComponent} from './app-help/license/license.component';
import {TermsComponent} from './app-help/terms/terms.component';
import {TicketListComponent} from './app-help/tickets/ticket-list/ticket-list.component';
import {TicketEditComponent} from './app-help/tickets/ticket-edit/ticket-edit.component';
import {BalancingComponent} from './finance/balancing/balancing.component';
import {MonthlyClosingsComponent} from './finance/monthly-closings/monthly-closings.component';
import {TaxReportComponent} from './finance/tax-report/tax-report.component';
import {SummaryComponent} from './finance/summary/summary.component';
import {WapCounterListComponent} from './water-access-point/wap-counter-list/wap-counter-list.component';
import {WapCreateUsageComponent} from './water-access-point/wap-create-usage/wap-create-usage.component';
import {DmsEditorComponent} from './services/dms/dms-editor/dms-editor.component';
import {MemberExportListComponent} from './members/member-export-list/member-export-list.component';
import {UserRolesComponent} from './settings/user-roles/user-roles.component';
import {UnderConstructionComponent} from "./shared/under-construction/under-construction.component";
import {TransactionTemplateComponent} from "./finance/transaction-template/transaction-template.component";
import {DunningsComponent} from "./finance/dunnings/dunnings.component";
import {WorktimeComponent} from "./services/maintenance/worktime/worktime.component";
import {SepaDirectDebitComponent} from "./jobs/billing/sepa-direct-debit/sepa-direct-debit.component";
import {SendEmailInvoicesComponent} from "./jobs/billing/send-email-invoices/send-email-invoices.component";
import {WapReportingComponent} from "./water-access-point/wap-reporting/wap-reporting.component";
import {BackupComponent} from "./settings/backup/backup.component";
import {FileSharingComponent} from "./public/file-sharing/file-sharing.component";
import {CounterListCardComponent} from "./services/counter-list/counter-list-card/counter-list-card.component";


const appRoutes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  {
    path: 'members-module',
    component: MembersComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'members', pathMatch: 'full'},
      {
        path: 'members',
        children: [
          {path: '', component: MemberListComponent},
          {path: ':id', component: EditMemberComponent, canDeactivate: [CanDeactivateGuard]},
        ],
      },
      {path: 'export-list', component: MemberExportListComponent},
      {path: 'send', component: UnderConstructionComponent}
    ]
  },


  {
    path: 'wap-module',
    component: WaterAccessPointComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'wap', pathMatch: 'full'},
      {
        path: 'wap',
        children: [
          {path: '', component: WapListComponent},
          {path: ':id/edit', component: WapEditComponent, canDeactivate: [CanDeactivateGuard]},
          {path: ':id/usage', component: WapUsageComponent},
          {path: ':id/childs', component: WapListComponent},
          {path: ':id/childs/new', component: SubwapEditComponent},
          {path: ':id/childs/:cid/edit', component: SubwapEditComponent},
          {path: 'edit', component: WapEditComponent},
          {path: 'new', component: WapEditComponent},
          {path: 'usage', component: WapUsageComponent},
        ],
      },
      {
        path: 'counterlist',
        children: [
          {path: '', redirectTo: 'list', pathMatch: 'full'},
          {path: 'list', component: CounterListEditComponent, canDeactivate: [CanDeactivateGuard]},
          {path: 'import', component: CounterListImportComponent},
          {path: 'message', component: CounterListMessageComponent},
          {path: 'counter-card', component: CounterListCardComponent}
        ]
      },
      {path: 'wap-counter-list', component: WapCounterListComponent},
      {path: 'wap-create-usage', component: WapCreateUsageComponent},
      {path: 'wap-reporting', component: WapReportingComponent}
    ]
  },

  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'organization', pathMatch: 'full'},
      {path: 'organization', component: OrganizationSettingsComponent},
      {
        path: 'productgroups',
        component: KvpSettingsComponent,
        data: {
          title: 'Produktgruppen',
          titleNew: 'Neue Produktgruppe',
          kvp_type: KVP_GROUPS.PRODUCTGROUP,
          newTag: 'Name'
        }
      },
      {
        path: 'needsunit',
        component: KvpSettingsComponent,
        data: {
          title: 'Bedarfseinheiten',
          titleNew: 'Neue Bedarfseinheit',
          kvp_type: KVP_GROUPS.NEEDS_UNIT,
          newTag: 'BE'
        }
      },
      {
        path: 'tags',
        component: KvpSettingsComponent,
        data: {title: 'Kennzeichen/Tags', titleNew: 'Neuer Tag', kvp_type: KVP_GROUPS.TAGS, newTag: 'Tagname'}
      },
      {
        path: 'paymentmethods',
        component: KvpSettingsComponent,
        data: {
          title: 'Zahlungsarten',
          titleNew: 'Neue Zahlungsart',
          kvp_type: KVP_GROUPS.PAY_METHOD,
          newTag: 'Zahlungsart'
        }
      },
      {path: 'paymentterms', component: PaymenttermsComponent},
      {path: 'numberrange', component: NumberrangeComponent},
      {path: 'roles', component: UserRolesComponent},
      {path: 'financial-years', component: FinancialYearsComponent},
      {
        path: 'costcenter',
        component: KvpSettingsComponent,
        data: {
          title: 'Kostenstellen',
          titleNew: 'Neue Kostenstelle',
          kvp_type: KVP_GROUPS.COST_CENTER,
          newTag: 'Kostenstelle'
        }
      },
      {
        path: 'doctypes',
        component: KvpSettingsComponent,
        data: {title: 'Dokumententypen', titleNew: 'Neuer Dokumententyp', kvp_type: KVP_GROUPS.DOC_TYPES, newTag: 'Typ'}
      },
      {
        path: 'equtypes',
        component: KvpSettingsComponent,
        data: {
          title: 'Arten von Anlagenteilen',
          titleNew: 'Neue Anlagenart',
          kvp_type: KVP_GROUPS.EQU_TYPES,
          newTag: 'Neuer Typ'
        }
      },
      {
        path: 'access', component: AccessSettingsComponent,
        children: [
          {path: '', component: AccessSettingsListComponent},
          {path: ':id', component: AccessSettingsEditComponent},
        ],
      },
      {
        path: 'waterbillsettings', component: WaterbillSettingsComponent,
        children: [
          {path: '', component: WaterbillSettingsListComponent,},
          {path: ':id', component: WaterbillSettingsEditComponent, pathMatch: 'full'},
        ],
      },
      {
        path: 'accounts', component: AccountsComponent,
        children: [
          {path: '', component: AccountListComponent},
          {path: ':id', component: AccountEditComponent},
        ],
      },
      {
        path: 'import', component: DataImportComponent,
      },
      {
        path: 'backup', component: BackupComponent
      }
    ],
  },
  {
    path: 'masterdata',
    component: MasterdataComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'products', pathMatch: 'full'},
      {
        path: 'products',
        component: ProductsComponent,
        children: [
          {path: '', component: ProductListComponent},
          {path: ':id', component: ProductEditComponent},
        ],
      },
      {
        path: 'customers',
        component: CustomerComponent,
        children: [
          {path: '', component: CustomerListComponent},
          {path: ':id', component: CustomerEditComponent},
        ],
      },
      {
        path: 'suppliers',
        component: SupplierComponent,
        children: [
          {path: '', component: SupplierListComponent},
          {path: ':id', component: SupplierEditComponent},
        ],
      },
      {
        path: 'equipment',
        component: EquipmentComponent,
        children: [
          {path: '', component: EquipmentListComponent},
          {path: ':id', component: EquipmentEditComponent},
        ],
      },
      // { path: 'customer', component: CustomerComponent },
      {
        path: 'gauges',
        component: GaugesComponent,
        children: [
          {path: '', component: GaugeListComponent},
          {path: ':id', component: GaugeEditComponent},
        ],
      },
    ],
  },

  {
    path: 'jobs',
    component: JobsComponent,
    children: [
      {path: '', redirectTo: 'invoices', pathMatch: 'full'},
      {
        path: 'invoices', component: InvoiceComponent, children: [
          {path: '', component: InvoiceListComponent},
          {path: ':id/edit', component: InvoiceEditComponent},
          {path: ':id/view', component: InvoiceViewComponent},
          {path: 'new', component: InvoiceEditComponent}
        ]
      },
      {
        path: 'waterbill', component: WaterbillComponent, children: [
          {path: '', component: ListeditStartComponent},
          {path: 'create', component: WaterbillCreateComponent},
        ]
      },

      {
        path: 'debit', component: DebitComponent, children: [
          {path: '', component: DebitListComponent},
          {path: ':id', component: DebitEditComponent},
        ]
      },
      {
        path: 'sepa-direct-debit', component: SepaDirectDebitComponent
      },
      {
        path: 'send-invoices', component: SendEmailInvoicesComponent
      }

    ]
  },
  {
    path: 'finance',
    component: FinanceComponent,
    children: [
      {path: '', redirectTo: 'transactions', pathMatch: 'full'},
      {path: 'transactions', component: TransactionsComponent},
      {path: 'cash-book', component: CashBookComponent},
      {path: 'open-invoices', component: OpenInvoicesComponent},
      {path: 'balancing', component: BalancingComponent},
      {path: 'monthly-closings', component: MonthlyClosingsComponent},
      {path: 'tax-report', component: TaxReportComponent},
      {path: 'summary', component: SummaryComponent},
      {path: 'templates', component: TransactionTemplateComponent},
      {path: 'dunnings', component: DunningsComponent}
    ]
  },

  {
    path: 'services',
    component: ServicesComponent,
    children: [
      {path: '', redirectTo: 'maintenance', pathMatch: 'full'},
      {
        path: 'dms', component: DmsComponent, children: [

          {path: 'editor', component: DmsEditorComponent},
          {
            path: 'postbox', component: DmsComponent, children: [
              {path: '', component: DmsListComponent},
              {path: ':id', component: DmsEditComponent},
            ]
          },

          {path: 'archive', component: DmsArchiveComponent},
          {path: 'file-sharing', component: DmsFileSharingComponent},
          {path: 'recycle-bin', component: DmsRecycleBinComponent},
        ]
      },
      {
        path: 'maintenance', component: MaintenanceComponent, children: [
          {path: '', redirectTo: 'work', pathMatch: 'full'},
          {
            path: 'plan', component: MaintenancePlanComponent, children: [
              {path: '', component: MaintenancePlanListComponent},
              {path: ':id', component: MaintenancePlanEditComponent},
            ],
          },
          {
            path: 'work', component: MaintenancePlanComponent, children: [
              {path: '', component: MaintenanceWorkListComponent},
              {path: ':id', component: MaintenanceWorkEditComponent},
            ]
          },
          {
            path: 'history', component: MaintenanceWorkHistoryComponent, children: []
          },
          {
            path: 'worktime', component: WorktimeComponent, children: []
          }
        ]
      },
      {
        path: 'tasks', component: TasksComponent, children: [
          {path: '', component: TaskListComponent},
          {path: ':id', component: TaskEditComponent},
        ]
      },
      {
        path: 'counterlist', component: CounterListComponent, children: [
          {path: '', redirectTo: 'edit', pathMatch: 'full'},
          {path: 'edit', component: CounterListEditComponent},
          {path: 'import', component: CounterListImportComponent},
          {path: 'message', component: CounterListMessageComponent}
        ]
      }

    ]
  },
  {
    path: 'help',
    component: AppHelpComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: 'tickets', pathMatch: 'full'},
      {
        path: 'tickets', component: TicketsComponent, children: [
          {path: '', component: TicketListComponent},
          {path: ':id', component: TicketEditComponent},
        ]
      },
      {path: 'faq', component: FaqComponent},
      {path: 'videos', component: VideosComponent},

      {path: 'subscription', component: SubscriptionComponent},
      {path: 'license', component: LicenseComponent},
      {path: 'terms', component: TermsComponent},
    ]
  },
  {
    path: 'public', children: [
      {
        path: 'share',
        children: [
          {path: ':id', component: FileSharingComponent},
        ]
      }
    ]
  },
  {path: 'login', component: AuthComponent},
  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
