<p-dialog
  appendTo="body"
  [modal]="true"
  header="Neue Rolle erstellen"
  [(visible)]="showNewRoleDialog">
  <input type="text" placeholder="Name" pInputText [(ngModel)]="newRoleName"  />
  <p-button style="margin-left: 10px" (click)="onAddRoleClicked()">Hinzufügen</p-button>
</p-dialog>

<p-dialog
  appendTo="body"
  [modal]="true"
  header="{{dialogTitle}} bearbeiten"
  [(visible)]="showEditRoleDialog"
  (close)="onCanceledClicked()"
[style]="{'width': '90%', 'height': '65%'}">
  <p-treeTable [value]="editRole.permissions" style="width: 100%">
    <ng-template pTemplate="header">
      <tr>
        <th>Name</th>
        <th style="text-align: right">Zugriff</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr>
        <td>
          <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
          {{rowData.name}}
        </td>
        <td style="text-align: right">
          <p-checkbox *ngIf="isParent(rowData)" [(ngModel)]="rowData.access" [binary]="true"></p-checkbox>
          <p-dropdown *ngIf="!isParent(rowData)" [options]="dropdownOptions" optionValue="value" optionLabel="label" [(ngModel)]="rowData.access"></p-dropdown>
        </td>
      </tr>
    </ng-template>
  </p-treeTable>
  <ng-template pTemplate="footer">
    <div class=" surface-border">
      <button pButton pRipple icon="pi pi-times" (click)="onCanceledClicked()" label="Abbrechen"></button>
      <button pButton pRipple icon="pi pi-check" (click)="onSaveClicked()" label="Speichern"></button>
    </div>
  </ng-template>
</p-dialog>

<p-table [value]="rolesList">
  <ng-template pTemplate="header">
    <tr>
      <th>Rolle</th>
      <th  style="text-align:right;">
        <button pButton pRipple icon="pi pi-plus-circle" class="p-button-rounded p-button-success mr-2" (click)="onNewRoleClicked()"></button>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-role>
    <tr>
      <td>
      {{role.roleName}}
      </td>
      <td style="text-align:right;">
        <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2" (click)="onEditRoleClicked(role)"></button>
      </td>
    </tr>

  </ng-template>
</p-table>
