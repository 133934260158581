<div class="surface-card  pt-4 pl-2 shadow-2 border-round mb-2">
    <div class="mb-3 flex align-items-center justify-content-between">
        <div>
            <span class="text-xl font-medium text-900 mx-3">Mitgliederliste erstellen</span>
        </div>
    </div>

    <div class="grid formgrid p-fluid mx-2">
        <div class="field mb-3 col-12 ">
            <label>Titel</label>
            <input type="text" name="title" placeholder="" class="form-control" [(ngModel)]="title" />
        </div>
    </div>

    <div class="field col-12 mx-2 border-bottom-1 surface-border " *ngIf="columns.length > 0">Zusätzlich Spalten</div>
    <ul class="list-none ml-2">
        <li class="flex align-items-center py-1 px-2  surface-border flex-wrap" *ngFor="let c of columns; let i=index">
            <div class="text-500 w-6 md:w-2 font-medium">Spalte {{i+1}}</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                <input type="text" name="title" placeholder="" class="form-control" [(ngModel)]="c.name" />
            </div>
            <div class="w-6 md:w-2 flex justify-content-end">
                <button pButton pRipple label="Löschen" icon="pi pi-trash" class="mx-3" (click)="onDeleteColumn(i)"></button>
            </div>
        </li>
    </ul>




    <div class="flex align-items-center justify-content-between pb-3">
        <div>
            <button pButton pRipple label="Spalte hinzufügen" icon="pi pi-plus-circle" (click)="onAddColumn()" class="mx-3"></button>
        </div>
        <div></div>
        <div>
            <button pButton pRipple label="Liste erzeugen" icon="pi pi-list" (click)="onCreateList()" class="mx-3"></button>
        </div>
    </div>
</div>


<div class="surface-card  pt-4 pl-2 shadow-2 border-round mb-2" *ngIf="memberList.length > 0">
    <div class="mb-3 flex align-items-center justify-content-between">
        <div>
            <span class="text-xl font-medium text-900 mx-3">{{title}} ({{memberList.length}})</span>
        </div>
        <div>
        </div>
        <div>
            <button pButton pRipple label="Drucken" icon="pi pi-print" (click)="onPrint()" class="mx-3"></button>
            <button pButton pRipple label="CSV Speichern" icon="pi pi-save" (click)="onSave()" class="mx-3"></button>
        </div>

    </div>

    <div class="p-4">
        <p-table [value]="memberList" dataKey="wapId" responsiveLayout="scroll" [scrollable]="true" styleClass="p-datatable-striped"
                 (sortFunction)="customSort($event)" [customSort]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="memberID">Nummer<p-sortIcon field="memberID"></p-sortIcon></th>
                    <th pSortableColumn="name">Name<p-sortIcon field="name"></p-sortIcon></th>
                    <th pSortableColumn="address">Adresse<p-sortIcon field="address"></p-sortIcon></th>
                    <th pSortableColumn="wapCount">Anzahl<p-sortIcon field="wapCount"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td>{{item.memberID}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.address}}</td>
                    <td>{{item.wapCount}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

