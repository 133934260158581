<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="mb-3 flex align-items-center justify-content-between">
  <div>
    <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
    <span class="text-xl font-medium text-900 mx-3">Konto</span>
  </div>
  <div>
    <button pButton pRipple label="Löschen" icon="pi pi-trash" (click)="Delete()" class="mx-3"></button>
    <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="Save()"></button>
  </div>
</div>

<form #f="ngForm">
  <div class="row">

    <div class="col-md-6">
      <div class="form-group">
        <label>Bezeichnung</label>
        <input type="text" name="shortName" class="form-control" [(ngModel)]="entity.name" required autocomplete="off"/>
      </div>

    </div>

    <div class="col-md-3">
      <div class="form-group">

        <label>Kategorie</label>
        <p-dropdown [options]="globals.accountTypes" name="selectedType" [(ngModel)]="entity.accountType"></p-dropdown>
      </div>
    </div>


  </div>

  <div class="row">
    <div class="col-md-12">
      <div class="form-group">
        <label>Bankname</label>
        <input type="text" name="bankname" placeholder="Bankname" class="form-control"
               [(ngModel)]="entity.bankName" autocomplete="off"/>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>IBAN</label>
        <input type="text" name="iban" placeholder="AT__" class="form-control" [(ngModel)]="entity.iban"
               autocomplete="off"/>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>BIC</label>
        <input type="text" name="bic" placeholder="BIC" class="form-control" [(ngModel)]="entity.bic"
               autocomplete="off"/>
      </div>
    </div>

    <div class="col-md-3">
      <div class="form-group">
        <label>Kontonummer</label>
        <input type="text" name="accountNumber" class="form-control" [(ngModel)]="entity.accountNumber"
               autocomplete="off"/>

      </div>

    </div>

  </div>

  <br/>

  <h5 class="card-title">Kontostand</h5>

  <div class="row mt-2">
    <div class="col-md-2">
      <div class="form-group">
        <label>Geschäftsjahr</label>
        <input type="number" name="fiscalYear" class="form-control" [(ngModel)]="entity.fiscalYear" readonly
               autocomplete="off"/>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>Kontostand Beginn</label>
        <input type="text" name="startBalance" class="form-control"
               value="{{ entity.startBalance / 100 | currency:'EUR' }}" readonly
               autocomplete="off"/>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>Aktueller Kontostand</label>
        <input type="text" name="currentBalance" class="form-control"
               [ngClass]="{'neg-value': entity.currentBalance < 0, 'pos-value': entity.currentBalance >= 0}"
               value="{{ entity.currentBalance / 100 | currency:'EUR' }}"
               readonly
               autocomplete="off"/>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>Buchungsabschluss</label>
        <input type="text" name="completedUntil" class="form-control"
               value="{{ entity.completedUntil * 1000 | customDate }}"
               readonly
               autocomplete="off"/>
      </div>
    </div>
  </div>

  <br/>

  <p-panel header="CSV-Importeinstellungen (Spaltennummern)" [toggleable]="true" [collapsed]="true">
    <div class="row mt-2">
      <div class="col-md-2">
        <div class="form-group">
          <label>Buchungsdatum</label>
          <input type="number" name="map0" class="form-control" [(ngModel)]="csvMap[0]" autocomplete="off"/>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Buchungstext</label>
          <input type="number" name="map1" class="form-control" [(ngModel)]="csvMap[1]" autocomplete="off"/>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Betrag</label>
          <input type="number" name="map2" class="form-control" [(ngModel)]="csvMap[2]" autocomplete="off"/>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>IBAN</label>
          <input type="number" name="map3" class="form-control" [(ngModel)]="csvMap[3]" autocomplete="off"/>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>BIC</label>
          <input type="number" name="map4" class="form-control" [(ngModel)]="csvMap[4]" autocomplete="off"/>
        </div>
      </div>
    </div>
  </p-panel>

</form>





