import { Component } from '@angular/core';

@Component({
  selector: 'app-dms-recycle-bin',
  templateUrl: './dms-recycle-bin.component.html',
  styleUrls: ['./dms-recycle-bin.component.css']
})
export class DmsRecycleBinComponent {
  isLoading : boolean = false;
}
