
import { EntityBase } from '../shared/entity.base'

export class WaterAccessPointInfo extends EntityBase {
  wapID: number;
  created: Date = new Date();
  livingSpace: number;
  otherSpace: number;
  businessSpace: number;
  payedFee: number;
  isBusiness: boolean;
  adults: number;
  childs: number;
  text: string = '';
  active: boolean = true;
}


