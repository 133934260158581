export class Contact {
  public id: number;
  public role: string;
  public contactID: string;
  public active: boolean;
  public firstName: string;
  public lastName: string;
  public orgName: string;
  public name2: string;
  public address1: string;
  public address2: string;
  public zip: string;
  public city: string;
  public telephone: string;
  public telephone2: string;
  public mobile: string;
  public mobile2: string;
  public fax: string;
  public email: string;
  public email2: string;
  public web: string;

  public notes: string;
  public field1: string;
  public field2: string;
  public field3: string;
  public field4: string;

  public bankeName: string;
  public iban: string;
  public bic: string;
  public vat: string;
  public isDebit: boolean;

  public created: string;

  public emailInvoice: string;
  public isEInvoice: boolean;

  public directDebitMandate: string;
  public mandateIssued: string;

  public paymentTermsId: number;

  public contactType: string;

  public parentContactId: number;

  public titleBefore : string = '';
  public titleAfter: string = '';

  public created2 : number;
  public mandateIssued2 : number;


  constructor() {
    this.role = '';

    this.contactID = '';
    this.active = true;

    this.firstName = '';
    this.lastName = '';
    this.orgName = '';
    this.name2 = '';

    this.address1 = '';
    this.address2 = '';
    this.zip = '';
    this.city = '';
    this.telephone = '';
    this.telephone2 = '';
    this.mobile = '';
    this.mobile2 = '';
    this.fax = '';
    this.email = '';
    this.email2 = '';
    this.web = '';


    this.notes = '';
    this.field1 = '';
    this.field2 = '';
    this.field3 = '';
    this.field4 = '';

    this.bankeName = '';
    this.iban = '';
    this.bic = '';
    this.vat = '';
    this.isDebit = false;

    this.emailInvoice = '';
    this.isEInvoice = false;

    this.directDebitMandate = '';
    this.mandateIssued = null;

    this.paymentTermsId = 0;

    this.contactType = 'person';

    this.parentContactId = 0;



  }

  get DiplayName() {
    return this.orgName != "" ? this.orgName : this.firstName + ' ' + this.lastName;
  }

  get AddressString(): string {
    var address = this.DiplayName;

    if (this.address1 != null && this.address1 != '') {
      address = address + '\n' + this.address1;
    }

    if (this.address2 != null && this.address2 != '') {
      address = address + '\n' + this.address2;
    }

    if (this.zip != null && this.city != null) {
      address = address + '\n' + this.zip + ' ' + this.city;
    }

    return address;
  }
}
