<div class="mb-3 flex align-items-center justify-content-between">
    <span class="text-xl font-medium text-900">Lieferanten</span>
    <button pButton pRipple label="Hinzufügen" icon="pi pi-plus" (click)="New()"></button>
</div>

<form (ngSubmit)="onSubmit(f)" #f="ngForm">
    <div class="px-0">
        <div class="input-group mb-3">
            <input type="text" name="searchSupplier" class="form-control input-lg" placeholder="Suchtext"
                   aria-label="Recipient's username" aria-describedby="basic-addon2" [(ngModel)]="searchString" />
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" [disabled]="!f.valid" type="submit">
                    Suchen
                </button>
            </div>
        </div>
    </div>
</form>

<p-table [value]="suppliers" selectionMode="single" [(selection)]="selectedSupplier" dataKey="id" responsiveLayout="scroll"
         (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped">
    <ng-template pTemplate="header">
        <tr>
            <th>Nummer</th>
            <th>Name</th>
            <th>Adresse</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-supplier>
        <tr [pSelectableRow]="supplier">
            <td class="table-users-id">{{ supplier.contactID }}</td>
            <td class="table-users-name">
                {{
                supplier.orgName
                ? supplier.orgName
                : supplier.firstName + " " + supplier.lastName
                }}
            </td>
            <td class="table-users-name">{{ supplier.address1 }}</td>
        </tr>
    </ng-template>
</p-table>

<p-paginator class="my-paginator" [rows]="10" [totalRecords]="totalSuppliers" pageLinkSize="3"
             (onPageChange)="paginate($event)"></p-paginator>

