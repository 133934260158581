import { Component } from '@angular/core';

@Component({
  selector: 'app-dms-file-sharing',
  templateUrl: './dms-file-sharing.component.html',
  styleUrls: ['./dms-file-sharing.component.css']
})
export class DmsFileSharingComponent {
  isLoading : boolean = false;  
}
