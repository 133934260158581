<div class="mb-3 flex align-items-center justify-content-between">
    <span class="text-xl font-medium text-900">{{ title }}</span>
    <button *ngIf="showAddButton" pButton pRipple label="Hinzufügen" icon="pi pi-plus" (click)="New()"></button>
</div>

<form (ngSubmit)="onSubmit(f)" #f="ngForm">
    <div class="px-0">
        <div class="input-group mb-3">
            <input type="text" name="searchMaintainWork" class="form-control input-lg" placeholder="Suchtext"
                   aria-label="text" aria-describedby="basic-addon2" [(ngModel)]="searchString" />
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" [disabled]="!f.valid" type="submit">
                    Suchen
                </button>
            </div>
        </div>
    </div>
</form>



<p-table [value]="items" selectionMode="single" [(selection)]="selectedItem" dataKey="id" responsiveLayout="scroll"
         (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped">
    <ng-template pTemplate="header">
        <tr>
            <th>Anlage/Typ</th>
            <th>Beschreibung</th>
            <th>Intervall</th>
            <th>Letzte Durchführung</th>
            <th>Fällig am</th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr [pSelectableRow]="item">
            <td class="td.table-col-25" *ngIf="!item.isTask">{{ item.equipmentName }}</td>
          <td class="td.table-col-25" *ngIf="item.isTask">* Aufgabe *</td>
            <td class="td.table-col-25">{{ item.text }}</td>
            <td class="td.table-col-25">{{ item.intervall }}</td>
          <td class="td.table-col-25"><div *ngIf="item.lastDone > -5364662400">{{ item.lastDone * 1000 | customDate }}</div></td>
            <td class="td.table-col-25">{{ item.dueDate * 1000  | customDate }}</td>
            <td class="td.table-col-25">
                <p-tag *ngIf="item.dueDate < today" value="Überfällig" [rounded]="true" severity="danger"></p-tag>
            </td>
</tr>
    </ng-template>
</p-table>

<p-paginator class="my-paginator" [rows]="10" [totalRecords]="totalItems" pageLinkSize="3"
             (onPageChange)="paginate($event)"></p-paginator>

