import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { Equipment } from '../../../shared/equipment.model';
import { EquipmentService } from '../equipment.service';

@Component({
  selector: 'app-equipment-select',
  templateUrl: './equipment-select.component.html',
  styleUrls: ['./equipment-select.component.css']
})
export class EquipmentSelectComponent implements OnInit, OnDestroy {

  subEntity: Subscription;
  items: Equipment[];
  totalItems: Number = 0;
  isLoading = false;
  searchString: string = '';

  constructor(
    private entityService: EquipmentService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig  ) { }

  ngOnInit(): void {
    this.items = this.entityService.itemArray;
    this.totalItems = this.entityService.totalItems;

    if (!this.items?.length) this.isLoading = true;

    this.subEntity = this.entityService.itemList.subscribe((m) => {
      this.items = m;
      this.totalItems = this.entityService.totalItems;
      this.isLoading = false;
    });

    if (!this.items?.length) this.entityService.getItems('', 10, 1);
  }

  ngOnDestroy(): void {
    this.subEntity.unsubscribe();
  }

  onSubmit(form: NgForm) {
    this.searchString = form.value.search;
    this.entityService.getItems(this.searchString, 20, 1);
  }

  onSelected(equ: Equipment) {
    this.ref.close(equ);
  }

  paginate(event) {
    this.entityService.getItems(this.searchString, 10, event.page + 1);
  }

}
