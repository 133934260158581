


export class SupportTicketMessage {
  name: string = "";
  message: string = "";
  created_date: Date;
  isNew: boolean = true;
}

export class SupportTicket  {
  _id: string | null;
  _rev: string | null;

  table: string = "SupportTicket";

  customer_name: string = "";
  customer_email: string = "";

  app: string = "headwater";

  ticket_subject: string = "";
  ticket_description: string = "";
  ticket_status: string = "";
  ticket_category: string = "";

  created_date: Date = new Date();

  messages: SupportTicketMessage[] = new Array();

  hasNewMessage(): boolean {
    let isNew = false;

    this.messages.forEach(m => {
      isNew = isNew || m.isNew;
    })

    return isNew;
  }

}

