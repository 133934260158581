import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Globals } from '../../../globals';
import { Waterbill } from '../../../shared/waterbill.model';

import { WaterbillService } from '../waterbill.service'

@Component({
  selector: 'app-waterbill-list',
  templateUrl: './waterbill-list.component.html',
  styleUrls: ['./waterbill-list.component.css']
})
export class WaterbillListComponent implements OnInit, OnDestroy {

  sub: Subscription;

  title: string = "Offene Wasserabrechnungen";
  isLoading = false;

  waterbills: Waterbill[];
  selectedWaterbill: Waterbill;

  constructor(private global: Globals, private waterbillService: WaterbillService,
              private router: Router  ) { }

  ngOnDestroy(): void {
    if (this.sub)
      this.sub.unsubscribe();
  }

  ngOnInit(): void {

  this.sub = this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (e.url == '/jobs/waterbill')
          this.readWaterbills();
      }
      console.log(e);
    });

    this.readWaterbills();
  }

  onSubmit(form: NgForm) {
    const value = form.value;

    this.readWaterbills();
  }

  readWaterbills() {
    this.selectedWaterbill = null;
    this.waterbillService.getWaterbills().subscribe((r) => {
      this.waterbills = r;
      this.waterbillService.waterbills = r;
      console.log(r);
    });
  }

  onSelected(w: Waterbill) {
    this.selectedWaterbill = w;
    this.waterbillService.selectedWaterbill = w;
    this.router.navigate(['jobs', 'waterbill', 'create'], { queryParams: { wapid: this.selectedWaterbill.wapId } })

  }

  onSelected2(event) {
    this.waterbillService.selectedWaterbill = this.selectedWaterbill;
    this.router.navigate(['jobs', 'waterbill', 'create'], { queryParams: { wapid: this.selectedWaterbill.wapId } })

  }

}
