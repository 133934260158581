import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { WaterbillSetting } from '../waterbill-settings.model';
import { WaterbillSettingsService } from '../waterbill-settings.service';

@Component({
  selector: 'app-waterbill-settings-list',
  templateUrl: './waterbill-settings-list.component.html',
  styleUrls: ['./waterbill-settings-list.component.css']
})
export class WaterbillSettingsListComponent implements OnInit, OnDestroy {

  totalItems: number;
  searchString: string = '';

  subItem: Subscription;
  items: WaterbillSetting[];
  selectedItem: WaterbillSetting;
  isLoading = false;

  constructor(private router: Router, public entityService: WaterbillSettingsService) { }

  ngOnInit(): void {
    this.entityService.detailActive = '';
    this.entityService.listActive = 'active';

    this.totalItems = this.entityService.totalItems;
    this.items = this.entityService.itemArray;

    if (!this.items?.length) this.isLoading = true;

    this.subItem = this.entityService.itemList.subscribe(d => {
      this.items = d;
      this.isLoading = false;
      this.totalItems = this.entityService.totalItems;
    });

    if (!this.items?.length) this.entityService.getItems('', 10, 1);
  }

  ngOnDestroy(): void {
    this.subItem.unsubscribe();
  }

  onSubmit(form) {
    this.entityService.getItems(this.searchString, 10, 1);
  }

  paginate(event) {
    this.entityService.getItems(this.searchString, 10, event.page + 1);
  }

  onSelected(entity) {
    this.selectedItem = entity;
    this.router.navigate(['settings', 'waterbillsettings', this.selectedItem.id, 'edit']);

    this.entityService.detailActive = 'active';
    this.entityService.listActive = '';
  }

  onSelected2(event) {
    this.entityService.selectedItem = this.selectedItem;
    this.router.navigate(['settings', 'waterbillsettings', this.selectedItem.id]);

    this.entityService.detailActive = 'active';
    this.entityService.listActive = '';
  }

  New() {
    this.selectedItem = new WaterbillSetting();
    this.router.navigate(['settings', 'waterbillsettings', 'new']);
  }
}
