import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DatePipe} from '@angular/common';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {NgForm} from '@angular/forms';
import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog';

import {GaugeService} from '../gauge.service';
import {Gauge} from 'src/app/shared/gauge.model';

import {ProductSelectComponent} from '../../products/product-select/product-select.component';
import {Product} from 'src/app/shared/product.model';
import {ProductService} from '../../products/product.service';
import {Globals} from 'src/app/globals';
import {ConfirmationService, MessageService} from 'primeng/api';
import {IDataService} from '../../../shared/dataservice.interface';
import {ContactSelectComponent} from "../../../shared/contact-select/contact-select.component";
import {Contact} from "../../../shared/contact.model";
import {ContactService} from "../../../shared/contact.service";
import {WapService} from "../../../water-access-point/wap.service";
import {Wap} from "../../../shared/wap.model";
import {WebReplyMessage} from "../../../shared/web-reply-message.model";

@Component({
  selector: 'app-gauge-edit',
  templateUrl: './gauge-edit.component.html',
  styleUrls: ['./gauge-edit.component.css'],
  providers: [DialogService, ConfirmationService],
})
export class GaugeEditComponent implements OnInit, OnDestroy, IDataService {

  editMode = false;

  de: any;

  id: number;
  gauge: Gauge = new Gauge();
  newId: string = '';

  productName: string;
  productId: number;

  supplierName: string = '';

  calibDate: Date = null;
  installDate: Date = null;

  counterReadOnly: boolean = true;

  wap: Wap = new Wap();
  wapName: string = '';

  constructor(
    private router: Router,
    public gaugeService: GaugeService,
    public productService: ProductService,
    private route: ActivatedRoute,
    public datepipe: DatePipe,
    public dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private contactService: ContactService,
    public globals: Globals,
    private messageService: MessageService,
    private wapService: WapService
  ) {
    this.gaugeService.listActive = '';
    this.gaugeService.detailActive = 'active';
  }

  ref: DynamicDialogRef;

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.editMode = params['id'] != 'new';
      this.initForm();
    });
  }

  ngOnDestroy(): void {

  }

  fillForm() {

    this.productId = this.gauge.productId;
    this.productName = '';
    if (this.gauge.productId != 0) {
      this.productService.readProduct(this.gauge.productId).subscribe((c) => {
        this.productId = c.id;
        this.productName = c.description;
      });
    }

    if (this.gauge.calibrationDate2 > this.globals.MIN_DATE)
      this.calibDate = new Date(this.gauge.calibrationDate2 * 1000);
    else
      this.calibDate = null;

    if (this.gauge.installDate2 > this.globals.MIN_DATE)
      this.installDate = new Date(this.gauge.installDate2 * 1000);
    else
      this.installDate = null;
  }

  getSupplierName() {
    if (this.gauge.vendorId > 0) {
      this.contactService.readContact((this.gauge.vendorId)).subscribe((c) => {
        this.supplierName = c.orgName;
      });
    } else
      this.supplierName = '';
  }

  getWap() {
    this.wap = new Wap();
    this.wapName = '';
    if (this.gauge.wapID > 0) {
      this.wapService.readWap(this.gauge.wapID).subscribe((c) => {
        this.wap = c;
        this.wapName = c.accessPointID + ' - ' + c.address1;
      }, (error) => {
        this.messageService.add({
          key: 'tl',
          severity: 'error',
          summary: 'Wasseranschluss konnte nicht gelesen werden.'
        });
      });
    } else {
      this.wapService.findByGaugeId(this.gauge.id).subscribe((c) => {
        if (c != null) {
          this.wap = c;
          this.wapName = c.accessPointID + ' - ' + c.address1;
        }
      }, (error) => {
        this.messageService.add({
          key: 'tl',
          severity: 'error',
          summary: 'Wasseranschluss konnte nicht gelesen werden.'
        });
      });
    }
  }

  initForm() {
    if (this.editMode) {

      this.gaugeService.readGauge(this.id).subscribe((res) => {
        this.gauge = res;
        this.fillForm();
        this.getSupplierName();
        this.getWap();
      });

    } else {
      if (!this.gaugeService.isCopy) {
        this.gauge = new Gauge();
      } else {
        this.gauge = Object.assign(
          new Gauge(),
          this.gaugeService.selectedGauge
        );

        this.gauge.id = 0;
        this.gauge.serial = this.gauge.serial + ' - Kopie';
      }
      this.gaugeService.isCopy = false;
      this.fillForm();

    }
  }

  onCopy() {
    this.gaugeService.isCopy = true;
    this.router.navigate(['masterdata', 'gauges', 'new']);
    this.gaugeService.detailActive = 'active';
    this.gaugeService.listActive = '';
  }

  onSave() {
    this.gaugeService.isCopy = false;
    if (this.calibDate)
      this.gauge.calibrationDate2 = Math.round(this.calibDate.getTime() / 1000);
    else
      this.gauge.calibrationDate2 = this.globals.MIN_DATE;
    this.gauge.productId = this.productId;
    this.gauge.installDate = null;
    this.gauge.installDate2 = this.globals.MIN_DATE;

    if (this.editMode) {
      this.saveGauge();
    } else {
      this.addGauge();
    }
  }

  saveGauge() {
    this.gaugeService.saveGauge(this.gauge).subscribe((res) => {
      this.messageService.add({key: 'tl', severity: 'info', summary: 'Zähler wurde gespeichert.'});
      this.gauge = res;
      this.gaugeService.selectedGauge = res;
      this.router.navigate(['masterdata', 'gauges', this.gauge.id]);
    }, (error) => {
      this.messageService.add({key: 'tl', severity: 'error', summary: 'Zähler konnte nicht gespeichert werden.'});
    });
  }

  addGauge() {
    this.gauge.calibrationDate = null;
    this.gaugeService.postGauge(this.gauge).subscribe((res) => {

      this.messageService.add({key: 'tl', severity: 'info', summary: 'Neuer Zähler angelegt.'});
      this.gauge = res;
      this.gaugeService.selectedGauge = res;
      this.router.navigate(['masterdata', 'gauges', this.gauge.id]);
    }, (error) => {
      var msg: WebReplyMessage = error.error;
      this.messageService.add({key: 'tl', severity: 'error', summary: 'Fehler! ' + msg.message});
    });
  }

  onSelectProduct() {
    this.ref = this.dialogService.open(ProductSelectComponent, {
      header: 'Produkt auswählen',
      width: '70%',
      contentStyle: {'max-height': '650px', overflow: 'auto'},
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((c: Product) => {
      if (c) {
        if (!this.gauge.description || this.gauge.description === '') {
          this.gauge.description = c.description;
          this.fillForm();
        }

        this.productId = c.id;
        this.productName = c.description;
      }
    });
  }

  onSelectSupplier() {
    this.ref = this.dialogService.open(ContactSelectComponent, {
      data: {role: 'supplier'},
      header: 'Lieferant auswählen',
      width: '70%',
      contentStyle: {'max-height': '650px', overflow: 'auto'},
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((c: Contact) => {
      if (c) {

        this.gauge.vendorId = c.id;
        this.getSupplierName();
      }
    });
  }

  onDelete() {
    this.confirmationService.confirm({
      message: 'Möchten Sie den Zähler wirklich löschen?',
      header: 'Zähler löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      rejectVisible: true,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.gaugeService.deleteGauge(this.gauge.id).subscribe(() => {
          this.gaugeService.gaugeArray = new Array();
          this.router.navigate(['masterdata', 'gauges']);
        });
      },
    });
  }

  onSelect(e: any) {

  }

  New() {

  }

  Copy() {
    this.onCopy();
  };

  Save() {
    this.onSave();
  };

  Delete() {
    this.onDelete();
  }

  Back() {
    this.router.navigate(['masterdata', 'gauges']);
  }

  onSelectWap() {
    this.router.navigate(['wap-module', 'wap', this.wap.id, 'edit']);
  }

  onUnlockCounter(event: Event) {
    if (this.counterReadOnly) {
      this.confirmationService.confirm({
        target: event.target as EventTarget,
        key: 'popup',
        message: 'Möchten Sie das Feld wirklich entsperren?',
        icon: 'pi pi-info-circle',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        acceptButtonStyleClass: 'p-button-danger p-button-sm',
        accept: () => {
          this.counterReadOnly = false;
        },
      });

    } else {
      this.counterReadOnly = true;
    }
  }
}
