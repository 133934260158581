import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Globals} from '../../globals';
import {Equipment} from '../../shared/equipment.model';
import {MaintenancePlanEntry} from './maintenance.planentry.model'
import {MaintenanceWorkResp} from "./maintenance-work-resp.model";

@Injectable({providedIn: 'root'})
export class MaintenancePlanService {

  itemList = new Subject<MaintenancePlanEntry[]>();
  itemArray = new Array<MaintenancePlanEntry>();

  totalItems: number = 0;

  selectedItem: MaintenancePlanEntry = null;

  detailActive = '';
  listActive = 'active';

  documentId: number;

  constructor(private globals: Globals,
              private router: Router,
              private http: HttpClient) {

  }

  getItems(search: string, pageSize: number, page: number) {
    const url = this.globals.APP_URL + 'maintenanceplan';
    let params = new HttpParams();
    params = params.append('search', search);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('page', page.toString());

    this.http
      .get<MaintenancePlanEntry[]>(url, {
        params: params,
        observe: 'response',
      })
      .subscribe((respData) => {
        this.totalItems = parseInt(respData.headers.get('x-total-count'));
        this.itemArray = respData.body.slice();
        this.itemList.next(this.itemArray);
      });
  }

  updateItem(p: MaintenancePlanEntry) {
    const url = this.globals.APP_URL + 'maintenanceplan';
    this.http.put<MaintenancePlanEntry>(url, p).subscribe((respData) => {
      this.selectedItem = respData;
      const idx = this.itemArray.findIndex((x) => x.id === p.id);
      this.itemArray[idx] = respData;
      this.router.navigate([
        'services',
        'maintenance',
        'plan',
        this.selectedItem.id
      ]);
    });
  }

  addItem(p: MaintenancePlanEntry) {
    const url = this.globals.APP_URL + 'maintenanceplan';
    this.http.post<MaintenancePlanEntry>(url, p).subscribe((respData) => {

      this.itemArray.push(respData);
      this.selectedItem = respData;
      this.router.navigate(['services', 'maintenance', 'plan', this.selectedItem.id]);

    });
  }

  postItem(p: MaintenancePlanEntry) {
    const url = this.globals.APP_URL + 'maintenanceplan';
    return this.http.post<MaintenancePlanEntry>(url, p);
  }

  putItem(p: MaintenancePlanEntry) {
    const url = this.globals.APP_URL + 'maintenanceplan';
    return this.http.put<MaintenancePlanEntry>(url, p);
  }

  readItem(id: number) {
    const url = this.globals.APP_URL + 'maintenanceplan/' + id;
    return this.http.get<MaintenancePlanEntry>(url);
  }


  getItem(id: number) {
    //this.selectedProduct = this.productArray.find((p) => p.id === id);
    this.selectedItem = Object.assign(
      new MaintenancePlanEntry(),
      this.itemArray.find((p) => p.id === id)
    );
    return this.selectedItem;
  }

  deleteItem(id: number) {
    const url = this.globals.APP_URL + 'maintenanceplan/' + id;
    return this.http.delete(url);
  }

  addResp(resp: MaintenanceWorkResp) {
    const url = this.globals.APP_URL + 'maintenanceplan/resp';
    return this.http.post<MaintenanceWorkResp>(url, resp);
  }

  updateResp(resp: MaintenanceWorkResp) {
    const url = this.globals.APP_URL + 'maintenanceplan/resp';
    return this.http.put<MaintenanceWorkResp>(url, resp);
  }

  deleteResp(id: number) {
    const url = this.globals.APP_URL + 'maintenanceplan/resp/' + id;
    return this.http.delete(url);
  }

  getRespForWorkEntry(id: number) {
    const url = this.globals.APP_URL + 'maintenanceplan/resp/' + id;
    return this.http.get<MaintenanceWorkResp[]>(url);
  }

}
