import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Contact } from '../../../shared/contact.model';
import { IDataService } from '../../../shared/dataservice.interface';
import { SupplierService } from '../supplier.service';

@Component({
  selector: 'app-supplier-list',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SupplierListComponent implements OnInit, OnDestroy, IDataService {

  subSupplier: Subscription;
  suppliers: Contact[];
  isLoading = false;

  selectedSupplier: Contact;

  searchString: string = '';
  totalSuppliers: number = 1;

  constructor(
    private supplierService: SupplierService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.supplierService.detailActive = '';
    this.supplierService.listActive = 'active';

    this.totalSuppliers = this.supplierService.totalSuppliers;
    this.suppliers = this.supplierService.supplierArray;

    if (!this.suppliers?.length) this.isLoading = true;

    this.subSupplier = this.supplierService.suppliers.subscribe((m) => {
      this.suppliers = m;
      this.totalSuppliers = this.supplierService.totalSuppliers;
      this.isLoading = false;
      if (m.length) {
        this.supplierService.selectedSupplier = m[0];
      }
    });

    if (!this.suppliers?.length) this.supplierService.getSuppliers('', 10, 1);
  }

  ngOnDestroy() {
    this.subSupplier.unsubscribe();
  }



  onSelected2(event) {
    this.supplierService.selectedSupplier = this.selectedSupplier;
    this.router.navigate(['masterdata', 'suppliers', event.data.id]);

    this.supplierService.detailActive = 'active';
    this.supplierService.listActive = '';
  }

  onAddMember() {
    this.router.navigate(['masterdata', 'suppliers', 'new']);
  }

  onSubmit(form: NgForm) {
    this.searchString = form.value.searchSupplier;
    this.supplierService.getSuppliers(this.searchString, 10, 1);
    //form.reset();
  }

  paginate(event) {
    this.supplierService.getSuppliers(this.searchString, 10, event.page + 1);
  }

  New() {
    this.onAddMember();
  }
  Copy() {
    console.log("copy");
  };
  Save() {
    console.log("save");
  };
  Delete() {
    console.log("delete");
  };

}
