export abstract class DocBase {
  _id: string | null;
  _rev: string | null;

  table: string;

  constructor() {
    this._id = null;
    this._rev = null;

    this.table = '';
  }
}
