import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {DatePipe} from '@angular/common';
import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog';


import {MemberSelectComponent} from '../../members/member-select/member-select.component';
import {GaugeSelectComponent} from '../../masterdata/gauges/gauge-select/gauge-select.component';
import {Wap, WapViewModel} from 'src/app/shared/wap.model';
import {WapService} from '../wap.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {NgForm} from '@angular/forms';
import {Contact} from 'src/app/shared/contact.model';
import {ContactService} from 'src/app/shared/contact.service';
import {Gauge} from 'src/app/shared/gauge.model';
import {Globals, KVP_GROUPS} from 'src/app/globals';
import {GaugeService} from 'src/app/masterdata/gauges/gauge.service';
import {Observable, Subject, Subscription} from 'rxjs';
import {Reading, ReadingViewModel} from 'src/app/shared/reading.model';
import {ContactSelectComponent} from '../../shared/contact-select/contact-select.component';
import {WaterbillSettingsService} from '../../settings/waterbill-settings/waterbill-settings.service';
import {EntitySelectComponent} from '../../shared/entity-select/entity-select.component';
import {EntityBase} from '../../shared/entity.base';
import {WaterbillSetting} from '../../settings/waterbill-settings/waterbill-settings.model';
import {ConfirmationService, MessageService} from 'primeng/api';
import {WaterAccessPointInfo} from '../wapinfo.model';
import {WaterUsage} from '../../shared/usage.model';
import {NeedsUnit} from '../../shared/needs-unit.model';
import {KvpService} from '../../shared/kvp.service';
import {NeedsUnitService} from '../needs-unit.service';
import {WaterAccessPointOwner} from '../wapowner.model';
import {WapOwnerViewModel} from '../wapowner.viewmodel';

@Component({
  selector: 'app-wap-edit',
  templateUrl: './wap-edit.component.html',
  styleUrls: ['./wap-edit.component.css'],
  providers: [DialogService, MessageService],
})
export class WapEditComponent implements OnInit, OnDestroy {
  id: number;
  wap: Wap;
  editMode = false;

  displayInfoDialog: boolean;
  selectedInfo: WaterAccessPointInfo = new WaterAccessPointInfo();
  wapInfo: WaterAccessPointInfo[] = new Array();
  infoDate: Date = new Date();

  wapOwner: WapOwnerViewModel[] = new Array();

  displayNeedsDialog: boolean;
  selectedNeed: NeedsUnit = new NeedsUnit();
  needs: NeedsUnit[];
  needsList: any;
  totalNeeds: number = 0;

  isLoading: boolean = true;

  ownerId: number = 0;
  ownerName: string = '';
  ownerAddress: string = '';

  active1: number = 0;

  invoiceId: number = 0;
  invoiceName: string = '';

  invoiceAddress: string = '';

  waterbillTemplateName1: string = '';
  waterbillTemplateName2: string = '';

  readingDate: Date = new Date();

  subWap: Subscription;

  showCounterAlert: boolean;
  hasReadings: boolean = false;

  subCounterTitle: string = 'Subzähler';

  counterType: string = '';
  counterCounts: number = 0;
  newcounts: number = 0;
  serial: string = '';

  isGauge: boolean;
  gauge: Gauge;

  usages: WaterUsage[];

  origData: string = '';

  lastReading: ReadingViewModel = new ReadingViewModel();

  constructor(
    public dialogService: DialogService,
    public wapService: WapService,
    private router: Router,
    private route: ActivatedRoute,
    private contactService: ContactService,
    public globals: Globals,
    public datepipe: DatePipe,
    private gaugeService: GaugeService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public waterbillSettingsService: WaterbillSettingsService,
    private kvpService: KvpService,
    private needsService: NeedsUnitService
  ) {
  }

  ref: DynamicDialogRef;

  ngOnInit(): void {


    this.isGauge = false;
    this.gauge = null;

    this.wap = null; // this.wapService.selectedWap;

    this.readingDate = new Date();

    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.editMode = params['id'] != null;

      if (this.wap || !this.editMode) {
        if (this.wapService.wapArray.length == 0) {
          this.wap = new Wap();
          this.wapService.wapArray.push(new WapViewModel(this.wap));
        }
        this.initForm();
      } else {
        this.wapService.readWap(this.id).subscribe(w => {
          if (w == null) {
            this.router.navigate(['wap-module', 'wap']);
          } else {
            console.log(w);
            this.wap = w;
            this.wapService.selectedWap = w;
            this.initForm();
          }
        });
      }
    });

    this.wapService.wap.subscribe(w => {
      //this.initForm();
      this.wap = w;
      if (w.gaugeID != 0) {
        this.gaugeService.readGauge(w.gaugeID).subscribe(g => {
          this.isGauge = g.id != 0;
          this.gauge = g;
          this.fillGauge(g);
        });
      }
      this.getReadings();

    });

    this.kvpService.readGroup(KVP_GROUPS.NEEDS_UNIT).subscribe(resp => {
      if (resp.length > 0) {
        this.needsList = resp.map(({group, ...rest}) => rest);
        ;
      }
    });


  }

  ngOnDestroy(): void {
    if (this.subWap) {
      this.subWap.unsubscribe();
    }
  }

  getReadings() {
    this.wapService.getReadings(this.wap).subscribe(resp => {
      this.hasReadings = resp.length > 0;
      console.log(resp);
      if (resp.length > 0)
        this.lastReading = resp.filter(r => r.count === this.gauge.lastCount)[0];
      else
        this.lastReading = new ReadingViewModel();
    });

  }


  fillForm() {

    if (this.wap.ownerContactID) {
      this.contactService
        .readContact(this.wap.ownerContactID)
        .subscribe((c) => {
          this.ownerId = c.id;
          this.ownerName = c.orgName
            ? c.orgName
            : c.firstName + ' ' + c.lastName;
          this.ownerAddress = c.zip + ' ' + c.city + ', ' + c.address1;
        });
    } else {
      this.ownerName = '';
      this.ownerId = 0;
      this.ownerAddress = '';
    }

    if (this.wap.invoiceContactID) {
      this.contactService
        .readContact(this.wap.invoiceContactID)
        .subscribe((c) => {
          this.invoiceId = c.id;
          this.invoiceName = c.orgName
            ? c.orgName
            : c.firstName + ' ' + c.lastName;
          this.invoiceAddress = c.zip + ' ' + c.city + ', ' + c.address1;
        });
    } else {
      this.invoiceId = 0;
      this.invoiceName = '';
      this.invoiceAddress = '';
    }

    this.waterbillTemplateName1 = '';
    if (this.wap.waterbillTermsID1 > 0) {

      this.waterbillSettingsService.readItem(this.wap.waterbillTermsID1).subscribe((resp) => {
        this.waterbillTemplateName1 = resp.name;
      });

    }

    this.waterbillTemplateName2 = '';
    if (this.wap.waterbillTermsID2 > 0) {

      this.waterbillSettingsService.readItem(this.wap.waterbillTermsID2).subscribe((resp) => {
        this.waterbillTemplateName2 = resp.name;
      });

    }
  }

  fillGauge(g: Gauge) {

    this.counterType = g.description;
    this.counterCounts = g.lastCount;
    this.newcounts = g.lastCount;
    this.serial = g.serial;
  }

  initForm() {
    if (this.editMode) {
      this.wapService.readWap(this.id).subscribe(resp => {
        this.wap = resp;
        this.fillForm();

        this.origData = JSON.stringify(this.wap);

        //this.wap = this.wapService.getWap(this.id);
        //this.fillForm();
        this.readWapInfos();
        this.readWapOwners();

        if (this.wap.gaugeID != 0) {
          this.gaugeService.readGauge(this.wap.gaugeID).subscribe(g => {
            this.isGauge = g.id != 0;
            this.gauge = g;
            this.fillGauge(g);

            this.getReadings();
          });
        } else {
          this.isGauge = false;
        }


        this.wapService.getChilds(this.wap).subscribe(r => {
          console.log(r);
          if (r.length > 0) {
            this.subCounterTitle = 'Subzähler (' + r.length.toString() + ')';
          } else {
            this.subCounterTitle = 'Subzähler';
          }
        });

      });

      this.isLoading = false;


    } else {
      this.wap = new Wap();
      this.wapService.getNextNumber().subscribe(resp => {
        this.wap.accessPointID = resp.id;
        this.fillForm();
        this.isLoading = false;
      });

    }
  }

  onSave() {
    this.wap.ownerContactID = this.ownerId;
    this.wap.invoiceContactID = this.invoiceId;

    if (!this.wap.active) {
      this.wap.active = false;
    }
    if (!this.wap.drainAccess) {
      this.wap.drainAccess = false;
    }

    if (this.editMode) {
      this.wapService.updateWap(this.wap);
      this.addInfoMessage("Wasseranschluss gespeichert");
    } else {
      this.wapService.addWap(this.wap);
      this.addInfoMessage("Neuer Wasseranschluss erstellt");
    }
    this.origData = JSON.stringify(this.wap);
  }

  onSaveCounter() {
    if (this.isGauge) {
      if (this.counterCounts >= this.newcounts && this.hasReadings) {
        this.showCounterAlert = true;
      } else {
        let reading = new Reading();
        reading.gaugeID = this.gauge.id;
        reading.billed = false;
        reading.prevCount = this.counterCounts;
        reading.count = this.newcounts;
        if (reading.prevCount > reading.count)
          reading.prevCount = reading.count;
        reading.readingDate2 = Math.round(this.readingDate.getTime() / 1000);
        this.showCounterAlert = false;

        this.wapService.addReading(reading, this.wap).subscribe(resp => {
          this.fillGauge(resp);
          this.hasReadings = true;
        });

      }
    }
  }

  onSelectMember() {
    this.ref = this.dialogService.open(ContactSelectComponent, {
      data: {
        role: 'members,customer'
      },
      header: 'Eigentümer auswählen',
      width: '75%',
      contentStyle: {'height': '800px', overflow: 'auto', 'resizeable': 'false'},
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((c: Contact) => {
      if (c) {
        this.ownerId = c.id;
        this.ownerName = c.orgName ? c.orgName : c.firstName + ' ' + c.lastName;
        this.ownerAddress = c.zip + ' ' + c.city + ', ' + c.address1;

        if (this.wap.address1 == '') {
          this.wap.address1 = c.address1;
          this.wap.zip = c.zip;
          this.wap.city = c.city;
        }
      }
    });
  }

  onSelectInvoice() {
    this.ref = this.dialogService.open(ContactSelectComponent, {
      data: {
        role: 'members,customer'
      },
      header: 'Rechnungsadresse auswählen',
      width: '75%',
      contentStyle: {'height': '800px', overflow: 'auto'},
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((c: Contact) => {
      if (c) {
        this.invoiceId = c.id;
        this.invoiceName = c.orgName
          ? c.orgName
          : c.firstName + ' ' + c.lastName;
        this.invoiceAddress = c.zip + ' ' + c.city + ', ' + c.address1;

        if (this.wap.address1 == '') {
          this.wap.address1 = c.address1;
          this.wap.zip = c.zip;
          this.wap.city = c.city;
        }
      }
    });
  }

  onSelectTemplate(n: number) {

    this.ref = this.dialogService.open(EntitySelectComponent, {

      data: {
        entityService: this.waterbillSettingsService,
        columnNames: ['Name', 'Zahlungsart'],
        dataNames: [{'name': 'name', 'class': 'table-col-50'}, {'name': 'paymentMethod', 'class': 'table-col-50'}]
      },
      header: 'Abrechnungsvorlage auswählen',
      width: '50%',
      contentStyle: {'max-height': '650px', overflow: 'auto'},
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((c: WaterbillSetting) => {
      if (c) {
        if (n == 1) {
          this.waterbillTemplateName1 = c.name;
          this.wap.waterbillTermsID1 = c.id;
        } else if (n == 2) {
          this.wap.waterbillTermsID2 = c.id;
          this.waterbillTemplateName2 = c.name;
        }
      }
    });
  }

  onSelectCounter() {
    this.ref = this.dialogService.open(GaugeSelectComponent, {
      data: {
        wap: this.wap,
      },
      header: 'Wasserzähler tauschen',
      width: '60%',
      contentStyle: {'max-height': '650px', overflow: 'auto'},
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe(data => {
      setTimeout(() => {
        this.wapService.changeGauge(this.wap, data);
      });
    });
  }

  onSubCounter() {
    this.router.navigate(['wap-module', 'wap', this.wap.id, 'childs']);
  }

  onPay() {
    this.router.navigate(['wap-module', 'wap', this.wap.id, 'usage']);
  }

  onDelete() {
    this.confirmationService.confirm({
      message: 'Möchten Sie den Wasseranschluss wirklich löschen?',
      header: 'Wasseranschluss löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      key: 'confirm',
      rejectVisible: true,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.wapService.deleteWap(this.wap.id).subscribe((r) => {
          console.log(r);
          if (r && r.value.length > 0) {
          } else {
            this.wapService.removeWapFromList(this.wap);
            this.wapService.selectedWap = null;
            this.wap = null;
            this.router.navigate(['wap-module', 'wap']);
          }
        });
      },
    });
  }

  Back() {
    this.router.navigate(['wap-module', 'wap']);
  }

  Delete() {
    this.onDelete();
  }

  Save() {
    this.onSave();
  }

  readWapInfos() {
    this.wapService.getWapInfo(this.wap).subscribe(r => {
      this.wapInfo = r;
    });
  }

  readWapOwners() {
    this.wapService.getWapOwner(this.wap).subscribe(r => {
      this.wapOwner = r;
      console.log(this.wapOwner);
    });
  }

  onSaveInfo() {
    this.displayInfoDialog = false;
    this.selectedInfo.created = this.infoDate;

    if (this.selectedInfo.id == 0) {
      this.wapService.addWapInfo(this.wap, this.selectedInfo).subscribe(r => {
        this.addInfoMessage('Neuer Eintrag wurde hinzugefügt');
        this.readWapInfos();
      });
    } else {
      this.wapService.updateWapInfo(this.wap, this.selectedInfo).subscribe(r => {
        this.addInfoMessage('Eintrag wurde gespeichert');
      });
    }
  }

  onAddInfo() {
    this.selectedInfo = new WaterAccessPointInfo();
    this.displayInfoDialog = true;
  }

  onEditInfo(e) {
    this.displayInfoDialog = true;
    this.selectedInfo = e;

  }

  onDeleteInfo(e) {
    this.confirmationService.confirm({
      message: `'Möchten Sie diesen Eintrag wirklich löschen?'`,
      header: 'Information löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      rejectVisible: true,
      key: 'confirm',
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        this.wapService.deleteWapInfo(e.id, this.wap).subscribe(() => {
          this.readWapInfos();
        });
        this.addInfoMessage('Eintrag wurde gelöscht');
      },
    });
  }

  addErrorMessage(message: string) {
    this.messageService.add({key: 'tl', severity: 'error', summary: 'Fehler', detail: message});
  }

  addInfoMessage(message: string) {
    this.messageService.add({key: 'tl', severity: 'info', summary: 'Information', detail: message});
  }

  onTabSelected(tab: number) {
    this.active1 = tab;
    if (tab == 1 && this.usages == null) {
      this.getUsages();
    } else if (tab == 2 && this.needs == null) {
      this.readNeeds();
    }
  }

  getUsages() {
    this.wapService.getUsages(this.wap).subscribe(resp => {
      this.usages = resp;
      this.usages.forEach(r => {
        r.from = new Date(r.from).toLocaleString('de-AT', {year: 'numeric', month: '2-digit', day: '2-digit'});
        r.to = new Date(r.to).toLocaleString('de-AT', {year: 'numeric', month: '2-digit', day: '2-digit'});
      })
    });

  }

  showDialog() {
    this.displayNeedsDialog = true;
    this.selectedNeed = new NeedsUnit();
    this.selectedNeed.wapID = this.wap.id;
  }

  onSaveNeeds() {
    this.displayNeedsDialog = false;
    this.selectedNeed.lastChanged = new Date();
    this.selectedNeed.wapID = this.wap.id;

    if (this.selectedNeed.id == 0) {
      this.needsService.postItem(this.selectedNeed).subscribe(resp => {
        this.readNeeds();
      });
    } else {
      this.needsService.putItem(this.selectedNeed).subscribe(resp => {
        this.readNeeds();
      });
    }
  }

  readNeeds() {
    this.needsService.getAll(this.wap.id).subscribe(r => {
      this.needs = r;
      this.totalNeeds = this.getTotalNeeds();
    });


  }

  // Calculate total quantity of active needs
  getTotalNeeds() {
    let total = 0;
    if (this.needs) {
      this.needs.forEach((r) => {
        if (r.active) {
          total += r.quantity;
        }
      });
    }
    return total;
  }

  onEditNeed(e) {
    this.displayNeedsDialog = true;
    this.selectedNeed = e;
  }

  onDeleteNeed(e) {
    this.confirmationService.confirm({
      message: `'Möchten Sie den Bedarf '${e.name}' wirklich löschen?'`,
      header: 'Bedarf löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      rejectVisible: true,
      key: 'confirm',
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        this.needsService.deleteItem(e.id).subscribe(() => {
          this.readNeeds();
        });
      },
    });
  }

  onRemoveInvoice() {
    this.wap.invoiceContactID = 0;
    this.invoiceName = '';
    this.invoiceAddress = '';
    this.invoiceId = 0;
  }

  onRemoveOwner() {
    this.wap.ownerContactID = 0;
    this.ownerName = '';
    this.ownerAddress = '';
    this.ownerId = 0;
  }


  canDeactivate(): Observable<boolean> | boolean {
    var currentData = JSON.stringify(this.wap);
    if (this.wap && currentData != this.origData) {
      const subject = new Subject<boolean>();
      this.confirmationService.confirm({
        message: 'Anschlussdaten wurde verändert. Änderungen verwerfen?',
        header: 'Warnung',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        key: 'confirm',
        rejectVisible: true,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          subject.next(true);
        },
        reject: () => {
          subject.next(false);
        }

      });
      return subject.asObservable();
    }
    return true;
  }

  onShowGauge() {
    this.router.navigate(['masterdata', 'gauges', this.wap.gaugeID]);
  }
}
