import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IDataService } from '../../../shared/dataservice.interface';
import { Equipment } from '../../../shared/equipment.model';
import { EquipmentService } from '../equipment.service';

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.css']
})
export class EquipmentListComponent implements OnInit, OnDestroy, IDataService {

  subItem: Subscription;
  items: Equipment[];
  selectedItem: Equipment;
  isLoading = false;

  searchString: string = '';

  totalItems: number;

  constructor(private router: Router, public entityService: EquipmentService) { }
   

  ngOnInit(): void {
    this.entityService.detailActive = '';
    this.entityService.listActive = 'active';

    this.totalItems = this.entityService.totalItems;
    this.items = this.entityService.itemArray;

    if (!this.items?.length) this.isLoading = true;

    this.subItem = this.entityService.itemList.subscribe(d => {
      this.items = d;
      this.isLoading = false;
      this.totalItems = this.entityService.totalItems;
    });

    if (!this.items?.length) this.entityService.getItems('', 10, 1);
  }

  ngOnDestroy() {
    this.subItem.unsubscribe();
  }

  onSubmit(form: NgForm) {
    this.entityService.getItems(this.searchString, 10, 1);
  }


  paginate(event) {
    this.entityService.getItems(this.searchString, 10, event.page + 1);
  }

  

  onSelected2(event) {
    this.entityService.selectedItem = this.selectedItem;
    this.router.navigate(['masterdata', 'equipment', this.selectedItem.id]);

    this.entityService.detailActive = 'active';
    this.entityService.listActive = '';
  }

  New() {
    this.router.navigate(['masterdata', 'equipment', 'new']);
  }
  Save() {
    throw new Error('Method not implemented.');
  }
  Delete() {
    throw new Error('Method not implemented.');
  }
  Copy() {
    throw new Error('Method not implemented.');
  }

}
