<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmDialog key="info" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-toast position="bottom-right" key="tl"></p-toast>
<div class="mb-3 flex align-items-center justify-content-between">
    <div>
        <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
        <span class="text-xl font-medium text-900 mx-3">Anlage</span>
    </div>
    <div>
        <button pButton pRipple label="Löschen" icon="pi pi-trash" (click)="Delete()" class="mx-3"></button>
        <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="Save()"></button>
    </div>
</div>

<form #f="ngForm">
    <div class="row">
        <div class="col-md-5">
            <div class="form-group">
                <label>Bezeichnung</label>
                <input type="text" name="shortName" class="form-control" [(ngModel)]="equipment.shortText" required />
            </div>

        </div>

        <div class="col-md-3">
            <div class="form-group">
                <label>Kategorie</label>
                <p-dropdown [options]="equipmentTypes" name="selectedType" optionLabel="key" [(ngModel)]="selectedType"></p-dropdown>
            </div>
        </div>

        <div class="col-md-2">
            <div class="form-group">
                <label>Seriennummer</label>
                <input type="text" name="equSerial" class="form-control" [(ngModel)]="equipment.serial" />
            </div>

        </div>



        <div class="col-md-2 col-btn">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" name="active" [(ngModel)]="equipment.active" id="2">
                <label class="form-check-label" for="2">Aktiv</label>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-md-8">
            <div class="form-group">
                <label>Beschreibung</label>
                <textarea type="text" name="equipmentText" class="form-control" style="resize: none; height: 200px" rows="6"
                          [(ngModel)]="equipment.description"></textarea>
            </div>
        </div>

        <div class="col-md-4">
            <div class="form-group">
                <label>
                    Bild
                    <a class="add-user-button mx-2" *ngIf="imageSrc!=null" (click)="onRemoveImage()">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
                        </svg>
                    </a>
                </label>

                <img [src]="imageSrc" class="equipment-img" *ngIf="imageSrc!=null" />
                <a class="btn btn--primary mr-2" (click)="onSelectImage()" *ngIf="imageSrc==null" style="min-width: 160px;">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M19,19H5V5H19M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M13.96,12.29L11.21,15.83L9.25,13.47L6.5,17H17.5L13.96,12.29Z" />
                    </svg>
                    <span>Auswählen ...</span>
                </a>
            </div>
        </div>
    </div>
    <input #FileSelectInputDialog type="file" hidden ([ngModel])="imageName" (change)="onFileSelected($event)" accept="image/*" />
    <div class="row">

        <div class="col-md-2">
            <div class="form-group">
                <label>Baujahr</label>
                <input type="number" name="buildyear" class="form-control" [(ngModel)]="equipment.yearPurchased" />
            </div>

        </div>

        <div class="col-md-3">
            <div class="form-group">
                <label>Anschaffungskosten</label>
                <input type="number" name="equPrice" class="form-control" [(ngModel)]="equipment.purchasePrice" />
            </div>

        </div>

        <div class="col-md-2">
            <div class="form-group">
                <label>Nutzungsdauer</label>
                <input type="number" name="equUsage" class="form-control" [(ngModel)]="equipment.equipmentLife" />
            </div>

        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label>Wiederbeschaffungskosten</label>
                <input type="number" name="equPrice" class="form-control" [(ngModel)]="equipment.replacementPrice" />
            </div>

        </div>

        <div class="col-md-2">
            <div class="form-group">
                <label>Kalkulatorische AfA</label>
                <input type="number" name="equAfa" class="form-control" [(ngModel)]="afa" readonly />
            </div>

        </div>
    </div>
</form>

<div class="mx-0">
    <hr />
    <app-dms-attachments #attachments titleNew="Datei hinzufügen" title="Dokumente" tableName="Equipment" [PK]="id"></app-dms-attachments>
</div>

