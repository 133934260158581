<!-- Default form login -->

<div class="text-center">
    <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
</div>
<div class="row>" *ngIf="!isLoading">
    <div class="col-md-4 mx-auto text-center">

        <div class="card">

            <form class="text-center border border-light p-5" #authForm="ngForm" (ngSubmit)="onLogin(authForm)">

                <img src="../../assets/img/logo3.png" class="img-fluid mb-5" alt="Logo">

                <div class="alert alert-danger" *ngIf="error">
                    <p>{{ error }}</p>
                </div>

                <!-- Email -->
                <input type="email" id="defaultLoginFormEmail" class="form-control mb-4" placeholder="E-mail"
                    name="email" ngModel required email>

                <!-- Password -->
                <input type="password" id="defaultLoginFormPassword" class="form-control mb-4" placeholder="Passwort"
                    name="password" ngModel required>




                <!-- Sign in button -->
                <button class="btn btn--primary mr-2 my-4" type="submit" [disabled]="!authForm.valid">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M10,17V14H3V10H10V7L15,12L10,17M10,2H19A2,2 0 0,1 21,4V20A2,2 0 0,1 19,22H10A2,2 0 0,1 8,20V18H10V20H19V4H10V6H8V4A2,2 0 0,1 10,2Z" />
                    </svg>
                    <span>Anmelden</span>
                </button>



            </form>
        </div>
    </div>
</div>
<!-- Default form login -->
