import {Product} from 'src/app/shared/product.model';

export class InvoiceItem {

  invoiceId: number;
  orderNumber: number;

  position: string;              // Invoice-Item-ID: is uniqe for an item in an invoice.
  productId: number;            // Product id if from product

  text: string;

  unit: string;

  quantity: number;

  pricePerUnit: number;

  discount: number;

  tax: number;
  taxAmount: number;

  netAmount: number;


  isNew: boolean;
  private product?: Product;      // currently selected Product

  constructor() {
    this.invoiceId = 0;
    this.orderNumber = 0;


    this.position = '';
    this.productId = 0;
    this.text = '';
    this.quantity = 1;
    this.pricePerUnit = 0.0;
    this.discount = 0.0;
    this.tax = 0.0;
    this.taxAmount = 0.0;
    this.netAmount = 0.0;

    this.quantity = 1;
    this.unit = '';

    this.discount = 0;

    this.isNew = true;
  }

  get Position() {
    return this.position;
  }

  set Position(value: string) {
    this.position = value;
  }

  get Text() {
    return this.text;
  }

  set Text(value: string) {
    this.text = value;
  }

  get Quantity() {
    return this.quantity;
  }

  set Quantity(value: number) {
    this.quantity = value;
  }

  get Unit() {
    return this.unit;
  }

  set Unit(value: string) {
    this.unit = value;
  }

  get ListPricePerUnitExcl() {
    return this.pricePerUnit / 100;
  }

  set ListPricePerUnitExcl(value: number) {
    this.pricePerUnit = Math.round(value * 100);
  }

  get ListPricePerUnitIncl() {
    return this.pricePerUnit === null ? null : this.pricePerUnit * (1 + this.tax);
  }

  get ListPriceExcl() {
    return this.ListPricePerUnitExcl * this.quantity
  }

  get ListPriceIncl() {
    return this.ListPricePerUnitIncl === null || this.quantity === null ? null : this.ListPricePerUnitIncl * this.quantity;
  }

  get PricePerUnitExcl() {
    return this.ListPricePerUnitExcl + this.DiscountAmountPerUnit;
  }

  get PricePerUnitIncl() {
    return this.PricePerUnitExcl === null ? null : this.PricePerUnitExcl + this.VatAmountPerUnit
  }

  get PriceExcl() {
    return this.PricePerUnitExcl * this.quantity;
  }

  get PriceIncl() {
    return this.PricePerUnitIncl === null || this.quantity === null ? null : this.PricePerUnitIncl * this.quantity;
  }

  get Tax() {
    return this.tax / 100;
  }

  set Tax(t: number) {
    this.tax = t * 100;
  }

  get Discount() {
    return this.discount / 100;
  }

  set Discount(d: number) {
    if (d > 0)
      d = d * -1;
    this.discount = d * 100;
  }

  get VatPercentage() {
    return this.tax === null ? null : this.tax;
  }

  set VatPercentage(value: number) {
    this.tax = value === null ? null : value;
  }

  get VatAmountPerUnit() {
    return this.PricePerUnitExcl * this.Tax / 100.0;
  }

  get VatAmount() {
    if (this.VatAmountPerUnit == 0 || this.quantity == 0)
      return 0;
    else
      return this.VatAmountPerUnit * this.quantity;
  }


  get DiscountFactor() {
    return this.discount;
  }

  get DiscountPercentage() {
    return this.discount === null ? null : this.discount * 100.0;
  }

  set DiscountPercentage(value: number) {
    this.discount = value === null ? null : value / 100.0;
  }

  get DiscountAmountPerUnit() {
    return this.pricePerUnit === null ? null : this.ListPricePerUnitExcl * this.Discount / 100;
  }

  get DiscountAmount() {
    return this.DiscountAmountPerUnit === null || this.quantity === null ? null : this.DiscountAmountPerUnit * this.quantity;
  }

  get Product() {
    return this.product;
  }

  set Product(value: Product) {
    this.product = value;
    this.text = '';
    this.text += this.product.productID ? this.product.productID + ': ' + this.product.productText + '\r\n' : '';
    //this.description += this.product.orderNumber ? /*"Bestellnr.: " + */this.product.orderNumber + ',\r\n' :'';
    //this.description += this.product.description ? this.product.description + ',\r\n' :'';
    this.text += this.product.description + '\r\n';
    //this.description += this.product.productID ? "Artikelnummer: " + this.product.productID + ',\r\n' :'';

    this.pricePerUnit = this.product.netSalesPrice;
    this.tax = this.product.tax;
    this.unit = this.product.unit;

  }

  get Rows() {
    let lines = (this.Text.match(/\n/g) || '').length + 1
    if (!this.text.endsWith('\n'))
      lines = lines + 1;
    return lines;
  }
}
