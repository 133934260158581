<div class="mb-3 flex align-items-center justify-content-between">
    <span class="text-xl font-medium text-900">Aufgaben</span>
    <button pButton pRipple label="Hinzufügen" icon="pi pi-plus" (click)="New()"></button>
</div>



<form (ngSubmit)="onSubmit(form)" #form="ngForm">
    <div class="card-body card-sidebar-menu">
        <div class="row">
            <div class="col-md-12">

                <div class="input-group mb-3">
                    <input type="text" name="search_task" placeholder="Suchtext" class="form-control" ngModel />
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary"
                                [disabled]="!form.valid"
                                type="submit">
                            Suchen
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</form>


<p-table [value]="items" selectionMode="single" [(selection)]="selectedItem" dataKey="id" responsiveLayout="scroll"
         (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped">
    <ng-template pTemplate="header">
        <tr>
            <th>Aufgabe</th>
            <th>Verantwortlich</th>
            <th>Fällig am</th>
            <th>Erledigt</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr [pSelectableRow]="item">
            <td class="table-users-id">{{ item.title  }}</td>
            <td class="table-users-id">{{ item.who }}</td>
            <td class="table-users-id">{{ item.dueDate2 * 1000 | customDate  }}</td>
            <td class="table-users-id">{{ item.done | yesnoConverter  }}</td>

        </tr>
    </ng-template>
</p-table>


<p-paginator class="my-paginator"
             [rows]="10"
             [totalRecords]="totalItems"
             pageLinkSize="3"
             (onPageChange)="paginate($event)"></p-paginator>

