export class MyDocument {
  id: number;
  name: string;
  documentID: string;
  path: string;
  docType: string;
  tags: string;
  virtualPath: string;
  private: boolean;
  internal: boolean;
  userId: number;
  folderId: number;

  created2: number;
  documentDate2: number;


  constructor() {
    this.name = '';
    this.documentID = '';
    this.path = '';
    this.docType = '';
    this.tags = '';
    this.virtualPath = '';
    this.private = false;
    this.internal = false;
    this.userId = 0;
    this.folderId = 0;
  }
}
