import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {Globals} from "../../../globals";
import {InvoiceService} from "../../../jobs/billing/invoice.service";
import {FormsModule} from "@angular/forms";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {TransactionTemplate} from "../../transaction-template.model";
import {Invoice, InvoiceSearchParam} from "../../../jobs/billing/invoice.model";
import {AppModule} from "../../../app.module";

@Component({
  selector: 'app-open-invoices-select',
  standalone: false,
  templateUrl: './open-invoices-select.component.html',
  styleUrl: './open-invoices-select.component.css'
})
export class OpenInvoicesSelectComponent implements OnInit {

  searchString: string = '';
  entities: Invoice[] = [];
  invoice: Invoice = new Invoice();

  searchParam: InvoiceSearchParam;

  constructor(private invoiceService: InvoiceService,
              private ref: DynamicDialogRef,
              public config: DynamicDialogConfig,
              public globals: Globals) {
  }

  ngOnInit(): void {
    this.searchParam = new InvoiceSearchParam();
    this.searchParam.isDebit = this.config.data.inputOnly;
    this.readInvoices(this.config.data.inputOnly, this.config.data.outputOnly);
  }

  readInvoices(inputOnly: boolean, outputOnly: boolean) {
    this.searchParam.isOpen = false;
    this.searchParam.isPayed = false;
    this.searchParam.isPrinted = true;
    this.searchParam.isBooked = true;
    this.invoiceService.readInvoices(1000, 1, this.searchParam).subscribe(resp => {
      this.entities = resp.body;
    });
  }

  onRead() {
    this.readInvoices(this.config.data.inputOnly, this.config.data.outputOnly);
  }

  onSearch() {
    this.readInvoices(this.config.data.inputOnly, this.config.data.outputOnly);
  }

  onRowSelect(event: any) {
    this.ref.close(event.data);
  }

  onCancel() {
    this.ref.close();
  }
}
