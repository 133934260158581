import {Component} from '@angular/core';
import {TransactionService} from "../transaction.service";
import {TaxReport} from "../transaction.model";

@Component({
  selector: 'app-tax-report',
  templateUrl: './tax-report.component.html',
  styleUrls: ['./tax-report.component.css']
})
export class TaxReportComponent {
  isLoading: boolean = false;

  timePeriods: { key: number; val: string }[] = [
    {key: 1, val: "Aktuelles Monat"},
    {key: 2, val: "Letztes Monat"},
    {key: 3, val: "Aktuelles Quartal"},
    {key: 4, val: "Letztes Quartal"},
    {key: 5, val: "Letzten 30 Tage"},
    {key: 6, val: "Aktuelles Jahr"},
    {key: 7, val: "Letztes Jahr"}
  ];

  selectedTimePeriod: any = 1;

  startDate: Date;
  endDate: Date;

  taxReport: TaxReport = new TaxReport();

  constructor(private transactionService: TransactionService) {
    this.selectedTimePeriod = 1;
    this.updateDateRange(this.selectedTimePeriod);
  }

  onTimePeriodChanged() {
    this.updateDateRange(this.selectedTimePeriod);
    console.log(this.startDate, this.endDate);
  }

  updateDateRange(selectedPeriod: number) {
    const now = new Date();
    const start = new Date();
    const end = new Date();

    switch (selectedPeriod) {
      case 1: // Aktuelles Monat
        start.setDate(1);
        start.setHours(0, 0, 0, 0);
        end.setMonth(start.getMonth() + 1, 0);
        end.setHours(23, 59, 59, 999);
        break;
      case 2: // Letztes Monat
        start.setMonth(start.getMonth() - 1, 1);
        start.setHours(0, 0, 0, 0);
        end.setMonth(start.getMonth() + 1, 0);
        end.setHours(23, 59, 59, 999);
        break;
      case 3: // Aktuelles Quartal
        const currentQuarterStartMonth = Math.floor(now.getMonth() / 3) * 3;
        start.setMonth(currentQuarterStartMonth, 1);
        start.setHours(0, 0, 0, 0);
        end.setMonth(currentQuarterStartMonth + 3, 0);
        end.setHours(23, 59, 59, 999);
        break;
      case 4: // Letztes Quartal
        const lastQuarterStartMonth = Math.floor((now.getMonth() - 3) / 3) * 3;
        start.setMonth(lastQuarterStartMonth, 1);
        start.setHours(0, 0, 0, 0);
        end.setMonth(lastQuarterStartMonth + 3, 0);
        end.setHours(23, 59, 59, 999);
        break;
      case 5: // Letzten 30 Tage
        start.setDate(start.getDate() - 30);
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        break;
      case 6: // Aktuelles Jahr
        start.setMonth(0, 1);
        start.setHours(0, 0, 0, 0);
        end.setMonth(11, 31);
        end.setHours(23, 59, 59, 999);
        break;
      case 7: // Letztes Jahr
        start.setFullYear(now.getFullYear() - 1, 0, 1);
        start.setHours(0, 0, 0, 0);
        end.setFullYear(now.getFullYear() - 1, 11, 31);
        end.setHours(23, 59, 59, 999);
        break;
    }

    this.startDate = start;
    this.endDate = end;
  }

  onSearch() {
    this.transactionService.getTaxReport(this.startDate, this.endDate).subscribe((data) => {
      this.taxReport = data;
    });
  }
}
