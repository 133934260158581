import {DatePipe} from '@angular/common';
import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core'
import {App, User} from './auth/user.model';
import {TransactionType} from './finance/transaction.model';
import {PaymentTermsService} from './settings/paymentterms/payment-terms.service';

export const KVP_GROUPS = {
  PRODUCTGROUP: 'Produktgruppe',
  ORGANIZATION: 'Organisation',
  TAGS: 'TAGS',
  DOC_TYPES: 'DOC_TYPE',
  EQU_TYPES: 'EQU_TYPE',
  PAY_METHOD: 'PAY_METHOD',
  COST_CENTER: 'COST_CENTER',
  NEEDS_UNIT: 'NEEDS_UNIT',
}

@Injectable({providedIn: 'root'})
export class Globals {
  APP_NAME = 'headwater';
  AUTH_URL = 'https://auth.rtws.at/';
  //AUTH_URL = 'https://localhost:5001/';
  //APP_URL = 'https://localhost:6001/';
  APP_URL = 'https://headwater.rtws.at/';

  // Some epoch time constants
  MIN_DATE = -5364662400;   // 1800-01-01
  ONE_HOUR: number = 3600;
  ONE_DAY: number = 86400;
  ONE_WEEK: number = 604800;
  THIRTY_DAYS: number = 2592000;

  de: any;

  currentApp: App;
  currentUser: User;

  serviceIntevals: any[];

  accountTypes: any[];

  transactionTypeStrings: any[];
  transactionTypes: any[];

  constructor(
    private datepipe: DatePipe,
  ) {


    this.serviceIntevals = [{key: 'Jährlich'}, {key: 'Halbjährlich'}, {key: 'Vierteljährlich'}, {key: 'Monatlich'}, {key: 'Wöchentlich'}, {key: 'Nach Bedarf'}, {key: '3-jährlich'}, {key: '5-jährlich'}];

    this.accountTypes = ['Kredit', 'Kassa', 'Bankkonto', 'Sparbuch'];

    this.transactionTypeStrings = ['Überweisung', 'Umbuchung', 'Zinsen', 'Gebühren', 'Finanzamt', 'Korrekturbuchung'];

    this.transactionTypes = [
      {name: this.transactionTypeStrings[TransactionType.Normal], value: TransactionType.Normal},
      {name: this.transactionTypeStrings[TransactionType.Rebooking], value: TransactionType.Rebooking},
      {name: this.transactionTypeStrings[TransactionType.Interest], value: TransactionType.Interest},
      {name: this.transactionTypeStrings[TransactionType.Fee], value: TransactionType.Fee},
      {name: this.transactionTypeStrings[TransactionType.TaxOffice], value: TransactionType.TaxOffice},
      {name: this.transactionTypeStrings[TransactionType.ManualCorrection], value: TransactionType.ManualCorrection}
    ]
  }

  parseDate(input, format): Date {
    format = format || 'yyyy-mm-dd'; // default format
    var parts = input.match(/(\d+)/g),
      i = 0, fmt = {};
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function (part) {
      fmt[part] = i++;
    });

    return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']]);
  }

  transformDateToDatabase(from: string): string {
    return this.datepipe.transform(this.parseDate(from, 'dd.mm.yyyy'), 'MM.dd.yyyy');
  }

  transformDateFromDatabase(from: string): string {
    return this.datepipe.transform(this.parseDate(from, 'mm.dd.yyyy'), 'dd.MM.yyyy');
  }

  round2(num: number): number {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  createHttpParamsFromObject(obj: any): HttpParams {
    let params = new HttpParams();
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
        const value = obj[key];
        if (value instanceof Date) {
          // Convert date to ISO string format
          params = params.set(key, value.toISOString());
        } else {
          params = params.set(key, value.toString());
        }
      }
    }
    return params;
  }

  getCurrentTimestamp(): string {
    const now = new Date();
    const date = now.toISOString().slice(0, 10); // Extract date portion
    const time = now.toTimeString().slice(0, 8); // Extract time portion
    return `${date} ${time}`;
  }

  getGermanDateString(date) {
    const options = {day: '2-digit', month: '2-digit', year: 'numeric'};
    return date.toLocaleDateString('de-DE', options);
  }

}


