import { Component } from '@angular/core';

@Component({
  selector: 'app-file-sharing',
  standalone: true,
  imports: [],
  templateUrl: './file-sharing.component.html',
  styleUrl: './file-sharing.component.css'
})
export class FileSharingComponent {

}
