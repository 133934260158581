

export class CounterListItem
{
  public serial: string;
  public count: number;
  public date: Date;
  public state: string;

  constructor(s: string, c: number, d: Date) {
    this.serial = s;
    this.count = c;
    this.date = d;
    this.state = '';
  }
}
