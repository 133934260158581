import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ConfirmationService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {Globals, KVP_GROUPS} from '../../../globals';
import {IDataService} from '../../../shared/dataservice.interface';
import {KvpService} from '../../../shared/kvp.service';
import {Account} from '../account.model';
import {AccountService} from '../account.service';
import {TransactionService} from '../../../finance/transaction.service';
import {CurrencyPipe} from "@angular/common";

@Component({
  selector: 'app-account-edit',
  templateUrl: './account-edit.component.html',
  styleUrls: ['./account-edit.component.css'],
  providers: [DialogService],
})
export class AccountEditComponent implements OnInit, IDataService {

  editMode: boolean;
  id: number;

  selectedType: any;

  entity: Account;

  csvMap: number[] = [0, 0, 0, 0, 0];

  constructor(public globals: Globals,
              private kvpService: KvpService,
              private route: ActivatedRoute,
              private router: Router,
              private confirmationService: ConfirmationService,
              private entityService: AccountService,
              private transactionService: TransactionService
  ) {
  }

  ngOnInit(): void {
    this.entity = new Account();

    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.editMode = params['id'] != 'new';
      this.initForm();
    });
  }

  initForm(): void {
    if (this.editMode) {
      this.entity = this.entityService.getItem(this.id, new Account());
      var s = this.entity.csvImportMap.split(';');
      this.csvMap = new Array();
      s.forEach(s => {
        if (s.length > 0)
          this.csvMap.push(Number.parseInt(s));
      });
      for (var i = this.csvMap.length; i < 5; ++i) {
        this.csvMap.push(-1);
      }

      this.transactionService.getAccountSummary(this.entity.id).subscribe(a => {
        this.entity.fiscalYear = a.financialYear;
        this.entity.startBalance = a.startBalance;
        this.entity.currentBalance = a.balance;
      });

      console.log(this.csvMap);
    } else {
      this.entity = new Account();
    }

    console.log(this.entity);
  }

  onSave() {
    this.entity.csvImportMap = this.csvMap.join(';');
    if (this.editMode) {
      this.entityService.updateItem(this.entity);
    } else {
      this.entityService.addItem(this.entity);
    }
  }

  onDelete() {
    this.confirmationService.confirm({
      message: 'Möchten Sie das Konto wirklich löschen?',
      header: 'Konto löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      rejectVisible: true,
      key: 'confirm',
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        this.entityService.deleteItem(this.entity.id).subscribe(() => {
          this.entityService.itemArray = new Array();
          this.router.navigate(['settings', 'accounts']);
        });
      },
    });
  }

  New() {
  }

  Copy() {
  };

  Save() {
    console.log("Save");
    this.onSave();
  };

  Delete() {
    this.onDelete();
  };

  Back() {
    this.router.navigate(['settings', 'accounts']);
  }
}
