import {Component, OnInit, OnDestroy} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Subscription} from 'rxjs';

import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog';

import {Product} from '../../../shared/product.model';
import {ProductService} from '../product.service';

@Component({
  selector: 'app-product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.css'],
  providers: [DialogService],
})
export class ProductSelectComponent implements OnInit, OnDestroy {
  subProduct: Subscription;
  products: Product[];
  isLoading = false;
  selectedProduct: Product;
  searchString: string = '';
  totalProducts: number = 1;

  constructor(
    private productService: ProductService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
  }

  ngOnInit(): void {
    this.products = this.productService.productArray;

    if (!this.products?.length) this.isLoading = true;

    this.subProduct = this.productService.products.subscribe((m) => {
      this.totalProducts = this.productService.totalProducts;
      this.products = m;
      this.isLoading = false;
    });

    if (!this.products?.length) this.productService.getProducts('', 10, 1);
  }

  ngOnDestroy() {
    this.subProduct.unsubscribe();
  }

  onSubmit(form: NgForm) {
    this.searchString = form.value.searchProduct;
    this.productService.getProducts(this.searchString, 20, 1);
    //form.reset();
  }

  onSelected2(event) {
    this.ref.close(this.selectedProduct);
  }

  paginate(event) {
    this.productService.getProducts(this.searchString, 10, event.page + 1);
  }
}
