import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Globals } from '../globals';
import { KvpSetting } from './kvpsetting.model';
import { UserMember } from './usermember.model';

@Injectable({ providedIn: 'root' })
export class KvpService {
  constructor(private http: HttpClient, private globals: Globals) { }

  readGroup(group: string) {
    const url = this.globals.APP_URL + 'settings';
    let params = new HttpParams();
    params = params.append('group', group);
    return this.http.get<KvpSetting[]>(url, {
      params: params,
    });
  }

  addSettings(s: KvpSetting) {
    const url = this.globals.APP_URL + 'settings';
    return this.http.post<KvpSetting>(url, s);
  }

  updateSettings(s: KvpSetting) {
    const url = this.globals.APP_URL + 'settings';
    return this.http.put<KvpSetting>(url, s);
  }

  deleteSettings(s: KvpSetting) {
    const url = this.globals.APP_URL + 'settings/' + s.id.toString();
    return this.http.delete(url);
  }

  setUserMember(um: UserMember) {
    const url = this.globals.APP_URL + 'settings/usermember';
    return this.http.post<UserMember>(url, um);
  }

  getUserMember(email: string) {
    const url = this.globals.APP_URL + 'settings/usermember';
    let params = new HttpParams();
    params = params.append('email', email);
    return this.http.get<UserMember>(url, {
      params: params,
    });
  }
}
