import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Globals, KVP_GROUPS } from '../../../globals';
import { MyDocument } from '../../../shared/document.model';
import { KvpService } from '../../../shared/kvp.service';
import { KvpSetting } from '../../../shared/kvpsetting.model';
import { DmsService } from '../dms.service';

@Component({
  selector: 'app-dms-select',
  templateUrl: './dms-select.component.html',
  styleUrls: ['./dms-select.component.css']
})
export class DmsSelectComponent implements OnInit {

  isLoading: boolean;
  categories: any[];
  selectedCategory: string;
  searchString: string = "";
  searchTags: string = "";
  documents: MyDocument[];
  selectedDocument: MyDocument;
  totalDocuments: number;

  constructor(private kvpService: KvpService,
    private globals: Globals,
    private router: Router,
    public ref: DynamicDialogRef,
    private dms: DmsService) { }

  ngOnInit(): void {
    this.kvpService.readGroup(KVP_GROUPS.DOC_TYPES).subscribe(resp => {
      this.categories = resp.map(({ group, ...rest }) => rest);;
    });

    this.dms.documents.subscribe(d => {
      this.documents = d;
      this.isLoading = false;
      this.totalDocuments = this.dms.totalDocuments;
      console.log(this.totalDocuments);
    });
  }


  onSubmit(form: NgForm) {
    this.dms.getDocuments(this.searchString, 10, 1);
  }

  onSelected(doc: MyDocument) {
    this.ref.close(doc);
  }

  paginate(event) {
    this.dms.getDocuments(this.searchString, 10, event.page + 1);
  }

}
