<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle">

</p-confirmDialog>
<p-toast position="bottom-right" key="tl"></p-toast>

<div class="text-center">
  <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
</div>

<p-dialog [(visible)]="display" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
          [style]="{width: '50vw'}">
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
            <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle"
                  style="width:32px;height:32px">
                <i class="pi pi-info text-lg"></i>
            </span>
      <span class="font-medium text-2xl text-900">{{ dialogTitle }}</span>
    </div>
  </ng-template>

  <div class="formgrid grid">
    <div class="field mb-3 col-6" [hidden]="editMode">
      <label for="transactionTypes" class="font-medium text-900">Transaktionstyp</label>
      <p-dropdown [options]="globals.transactionTypes" [(ngModel)]="selectedTransaction.transactionType"
                  optionLabel="name" optionValue="value" appendTo="body"></p-dropdown>
    </div>
    <div class="field mb-3 col-6" [hidden]="editMode || selectedTransaction.transactionType != 1">
      <label for="destAccount" class="font-medium text-900">Konto</label>
      <p-dropdown [options]="items" optionLabel="name" optionValue="id" appendTo="body"
                  [(ngModel)]="destAccountId"></p-dropdown>
    </div>
    <div class="field mb-3 col-6" [hidden]="editMode || selectedTransaction.transactionType == 1">
    </div>


    <div class="field mb-3 col-6">
      <div style="width: 100%;">
        <label class="font-medium text-900 mb-2">Art</label>


        <p-selectButton [options]="transactionOption" [(ngModel)]="transactionType"
                        optionLabel="label" optionValue="value" [disabled]="editMode" class="full-width-select-button">

        </p-selectButton>
      </div>

    </div>
    <div class="field mb-3 col-6">
      <label for="costCenters" class="font-medium text-900">Kostenstelle</label>
      <p-dropdown id="costCenters" [options]="costCenters" optionLabel="key" optionValue="key" appendTo="body"
                  [(ngModel)]="selectedTransaction.costCenter"></p-dropdown>
    </div>
    <div class="field col-4 p-fluid">

      <label for="transactionDate" class="font-medium text-900">Buchungsdatum</label>

      <p-calendar id="transactionDate" class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false"
                  [locale]="globals.de"
                  [ngModelOptions]="{standalone: true}" appendTo="body"
                  [minDate]="minDate"
                  [(ngModel)]="transactionDate">
      </p-calendar>

    </div>
    <div class="flex flex-column  mb-3 col-8">
      <label for="name" class="font-medium text-900 mb-2">Buchungstext</label>
      <input id="name" type="text" pInputText [(ngModel)]="selectedTransaction.text" autocomplete="off">
    </div>

    <div class="field col-4 p-fluid mb-3">

      <label for="amount" class="font-medium text-900">Betrag</label>
      <p-inputNumber [ngClass]="{'neg-value': transactionType, 'pos-value': !transactionType}" id="amount"
                     [minFractionDigits]="2" [(ngModel)]="amount"
                     (onInput)="onInputAmount()"></p-inputNumber>
    </div>

    <div class="field mb-3 col-4 p-fluid">
      <div *ngIf="isSalesTax()" class="flex flex-column">
        <label for="tax" class="font-medium text-900 mb-2">MwSt. [%]</label>
        <p-inputNumber id="tax" [ngClass]="{'neg-value': transactionType, 'pos-value': !transactionType}"
                       [minFractionDigits]="2" suffix=" %" [(ngModel)]="taxRate1"
                       (onInput)="onInputTax()"></p-inputNumber>
      </div>
    </div>
    <div class="field mb-3 col-4 p-fluid">
      <div *ngIf="isSalesTax()" class="flex flex-column">
        <label for="salesTax" class="font-medium text-900  mb-2">MwSt. [EUR]</label>
        <p-inputNumber [ngClass]="{'neg-value': transactionType, 'pos-value': !transactionType}" id="salesTax"
                       [minFractionDigits]="2" [(ngModel)]="taxAmount1"></p-inputNumber>
      </div>
    </div>
    <div class="col-4 mb-3">
      <div *ngIf="isSalesTax()" class="flex flex-column">
        <p-checkbox label="2. Steuersatz" [(ngModel)]="secondVat" [binary]="true"></p-checkbox>
      </div>
    </div>
    <div class="field col-4 mb-3 p-fluid">
      <div *ngIf="secondVat" class="flex flex-column">
        <label for="tax" class="font-medium text-900 mb-2">2. MwSt. [%]</label>
        <p-inputNumber id="tax2" [ngClass]="{'neg-value': transactionType, 'pos-value': !transactionType}"
                       [minFractionDigits]="2" suffix=" %" [(ngModel)]="taxRate2"
                       (onInput)="onInputTax()"></p-inputNumber>
      </div>
    </div>
    <div class="field col-4 mb-3 p-fluid">
      <div *ngIf="secondVat" class="flex flex-column">
        <label for="salesTax" class="font-medium text-900 mb-2">2. MwSt. [EUR]</label>
        <p-inputNumber [ngClass]="{'neg-value': transactionType, 'pos-value': !transactionType}" id="salesTax2"
                       [minFractionDigits]="2" [(ngModel)]="taxAmount2"></p-inputNumber>
      </div>
    </div>


    <div class="flex flex-column  mb-3 col-6" hidden>
      <label for="iban" class="font-medium text-900 mb-2">IBAN</label>
      <input id="iban" type="text" pInputText [(ngModel)]="selectedTransaction.iban" autocomplete="off">
    </div>
    <div class="flex flex-column  mb-3 col-6" hidden>
      <label for="bic" class="font-medium text-900 mb-2">BIC</label>
      <input id="bic" type="text" pInputText [(ngModel)]="selectedTransaction.bic" autocomplete="off">
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class=" surface-border">
      <button pButton pRipple icon="pi pi-times" (click)="display = false" label="Abbrechen"></button>
      <button pButton pRipple icon="pi pi-check" (click)="onSave()" label="Speichern"></button>
    </div>
  </ng-template>

</p-dialog>


<div class="surface-card  pt-2 shadow-2 border-round mb-2" *ngIf="!isLoading">


  <p-carousel [value]="origItems" [numVisible]="4" [numScroll]="1">
    <ng-template let-item pTemplate="item">

      <div class="surface-card shadow-2 p-3 m-2 mt-4 border-round cursor-pointer"
           [ngClass]="{'border-2 border-blue-500' : item===selectedItem}" (click)="onSelect(item)">
        <div class="flex justify-content-between">
          <div>
            <span class="block text-500 font-medium ">{{ item.accountType }}</span>
            <div class="text-900 font-medium text-xl">{{ item.name }}</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
               style="width:2.5rem;height:2.5rem">
            <i *ngIf="item.accountType === 'Kredit'" class="pi pi-credit-card text-blue-500 text-xl"></i>
            <i *ngIf="item.accountType === 'Kassa'" class="pi pi-money-bill text-blue-500 text-xl"></i>
            <i *ngIf="item.accountType === 'Bankkonto'" class="pi pi-briefcase text-blue-500 text-xl"></i>
            <i *ngIf="item.accountType === 'Sparbuch'" class="pi pi-book text-blue-500 text-xl"></i>
          </div>
        </div>
        <div>
          <span class="text-green-500 font-medium text-2xl"
                [ngClass]="{'text-red-500' : item.currentBalance < 0}">{{ item.currentBalance / 100 | currency:'EUR' }}</span>
        </div>

        <span *ngIf="item.accountType != 'Kassa'" class="text-500">IBAN: {{ item.iban }}</span>
        <span *ngIf="item.accountType == 'Kassa'" class="text-500">Bargeld</span>
      </div>

    </ng-template>
  </p-carousel>
</div>

<div *ngIf="selectedItem != null && !isLoading" class="surface-card p-4 shadow-2 border-round">

  <div class="flex justify-content-between align-items-center mb-3">
        <span>
            <span class="font-medium text-500">Gewählt:  </span>
            <span class="font-medium text-800 text-xl">{{ selectedItem.name }}</span>
        </span>

    <div class="ml-6">
      <input pInputText type="text" name="search" placeholder="Suchtext"
             aria-label="text" aria-describedby="basic-addon2" [(ngModel)]="searchString"/>

    </div>

    <div class="mx-4">
      <span>Zeitraum von: </span>
      <p-calendar [(ngModel)]="rangeDates[0]" [readonlyInput]="false" inputId="from" dateFormat="dd.mm.yy"
                  (keyup.enter)="onSearch()"></p-calendar>
    </div>

    <div class="mx-4">
      <span>bis: </span>
      <p-calendar [(ngModel)]="rangeDates[1]" [readonlyInput]="false" inputId="to" dateFormat="dd.mm.yy"
                  (keyup.enter)="onSearch()"></p-calendar>
    </div>

    <div class="ml-4">
      <button pButton pRipple label="Suchen" icon="pi pi-search" (click)="onSearch()"></button>
    </div>


    <div class="ml-auto">
      <button pButton pRipple icon="pi pi-plus" (click)="onNew(null)"></button>
      <button pButton pRipple label="Aus Vorlage" icon="pi pi-code" (click)="onNewTemplate()"></button>
    </div>

  </div>

  <p-table [value]="transactions" styleClass="p-datatable-striped" responsiveLayout="scroll" dataKey="id">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 5rem"></th>
        <th>Buchungsdatum</th>
        <th>Buchungstext</th>
        <th style="text-align:right;">Betrag</th>
        <th style="width: 10rem"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-transaction let-expanded="expanded">
      <tr [pSelectableRow]="transaction">
        <td>
          <button type="button" pButton pRipple [pRowToggler]="transaction"
                  class="p-button-text p-button-rounded p-button-plain"
                  [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
        </td>
        <td>{{ transaction.transactionDate2 * 1000 | customDate }}</td>
        <td>{{ transaction.text }}</td>
        <td style="text-align:right;"
            [style.color]="transaction.amount < 0 ? 'red' :  'green' ">{{ transaction.amount / 100 | currency:'EUR' }}
        </td>
        <td style="text-align:right;">
          <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                  *ngIf="transaction.transactionDate2 >= lastTransactionDate"
                  (click)="onEdit(transaction)"></button>
          <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-success"
                  *ngIf="transaction.transactionDate2 >= lastTransactionDate"
                  (click)="onDelete(transaction)"></button>
        </td>
      </tr>

    </ng-template>
    <ng-template pTemplate="rowexpansion" let-transaction>
      <tr>
        <td colspan="7">
          <div class="flex flex-column relative flex-auto pl-8">

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Type</label>
                  <input type="text" name="transactionType" class="form-control"
                         value="{{globals.transactionTypeStrings[transaction.transactionType]}}" readonly/>
                </div>

              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Kostenstelle</label>
                  <input type="text" name="costCenter" class="form-control" [(ngModel)]="transaction.costCenter"
                         readonly/>
                </div>

              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Rechnungsnummer</label>
                  <input type="text" name="invoiceNumber" class="form-control" [(ngModel)]="transaction.invoiceNumber"
                         readonly/>
                </div>

              </div>
            </div>

          </div>
        </td>
      </tr>
    </ng-template>

  </p-table>


</div>



