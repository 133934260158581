import {EntityBase} from "../../shared/entity.base";


export class FileShare extends EntityBase {
  description: string = "";
  downloadToken: string = "";
  accessKey: string = "";
  documentId: number;
  isOneTimeAccess: boolean = false;
  active: boolean = true;
  accessCount: number = 0;
  expirationDate2: number = 0;
  created2: number = 0;


}
