<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmDialog key="info" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-toast position="bottom-right" key="tl"></p-toast>

<div class="text-center">
    <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
</div>

<p-dialog [(visible)]="displayNeedsDialog" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '20vw'}">
    <ng-template pTemplate="header">
        <div class="flex align-items-center">
            <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style="width:32px;height:32px">
                <i class="pi pi-info text-lg"></i>
            </span>
            <span class="font-medium text-2xl text-900">Hinzufügen</span>
        </div>
    </ng-template>

    <div class="grid formgrid p-fluid">

        <div class="field mb-3 col-12 md:col-12">
            <label for="needName" class="font-medium text-900">Name</label>
            <p-dropdown id="needName" [options]="needsList" [(ngModel)]="selectedNeed.name" optionLabel="key" optionValue="key" appendTo="body"></p-dropdown>
        </div>


        <div class="field mb-3 col-12 md:col-12">
            <label for="name" class="font-medium text-900">Menge</label>
            <p-inputNumber [(ngModel)]="selectedNeed.quantity" mode="decimal" locale="de-DE" [minFractionDigits]="2"></p-inputNumber>

        </div>


        <div class="field mb-3 col-12 md:col-12 ml-2">
            <label for="nr_pad" class="font-medium text-900"> </label>
            <div class="flex align-items-center">
                <p-checkbox inputId="privacy" [binary]="true" [(ngModel)]="selectedNeed.active"></p-checkbox>
                <span class="ml-2 text-900">Aktiv</span>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <div class=" surface-border">
            <button pButton pRipple icon="pi pi-times" (click)="displayNeedsDialog = false" label="Abbrechen"></button>
            <button pButton pRipple icon="pi pi-check" (click)="onSaveNeeds()" label="Speichern"></button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="displayInfoDialog" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}">
    <ng-template pTemplate="header">
        <div class="flex align-items-center">
            <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style="width:32px;height:32px">
                <i class="pi pi-info text-lg"></i>
            </span>
            <span class="font-medium text-2xl text-900">Information hinzufügen</span>
        </div>
    </ng-template>

    <div class="grid formgrid p-fluid">

      <div class="field mb-3 col-12 md:col-4 mt-5">

        <div class="flex align-items-center">
          <p-checkbox inputId="privacy" [binary]="true" [(ngModel)]="selectedInfo.active"></p-checkbox>
          <span class="ml-2 text-900">Aktiv</span>
        </div>
      </div>

        <div class="field mb-3 col-12 md:col-4">
            <label for="created2" class="font-medium text-900">Erstellt</label>
            <p-calendar id="created2" appendTo="body" dateFormat="dd.mm.yy" [(ngModel)]="infoDate"></p-calendar>
        </div>



        <div class="field mb-3 col-12 md:col-4 mt-5">

            <div class="flex align-items-center">
                <p-checkbox inputId="privacy" [binary]="true" [(ngModel)]="selectedInfo.isBusiness"></p-checkbox>
                <span class="ml-2 text-900">Gewerblich</span>
            </div>
        </div>

        <div class="field mb-3 col-12 md:col-4">
            <label for="livingSpace" class="font-medium text-900">Wohnfläche [m²]</label>
            <p-inputNumber id="livingSpace" [(ngModel)]="selectedInfo.livingSpace" [useGrouping]="false"></p-inputNumber>
        </div>
        <div class="field mb-3 col-12 md:col-4">
            <label for="otherSpace" class="font-medium text-900">Sonstige Fläche [m²]</label>
            <p-inputNumber id="livingSpace" [(ngModel)]="selectedInfo.otherSpace" [useGrouping]="false"></p-inputNumber>
        </div>
        <div class="field mb-3 col-12 md:col-4">
            <label *ngIf="selectedInfo.isBusiness" for="businessSpace" class="font-medium text-900">Gewerbliche Fläche [m²]</label>
            <p-inputNumber *ngIf="selectedInfo.isBusiness" id="businessSpace" [(ngModel)]="selectedInfo.businessSpace" [useGrouping]="false"></p-inputNumber>
        </div>

        <div class="field mb-3 col-12 md:col-4">
            <label for="payedFee" class="font-medium text-900">Anschlussgebühren</label>
            <p-inputNumber id="childs" [(ngModel)]="selectedInfo.payedFee" [minFractionDigits]="2" [useGrouping]="false"></p-inputNumber>
        </div>
        <div class="field mb-3 col-12 md:col-4">
            <label for="adults" class="font-medium text-900">Bewohner Erwachsene</label>
            <p-inputNumber id="adults" [(ngModel)]="selectedInfo.adults" [useGrouping]="false"></p-inputNumber>
        </div>
        <div class="field mb-3 col-12 md:col-4">
            <label for="childs" class="font-medium text-900">Kinder</label>
            <p-inputNumber id="childs" [(ngModel)]="selectedInfo.childs" [useGrouping]="false"></p-inputNumber>
        </div>

        <div class="field mb-3 col-12">
            <label for="text" class="font-medium text-900">Text</label>
            <input id="text" type="text" pInputText [(ngModel)]="selectedInfo.text" autocomplete="off">
        </div>


    </div>

    <ng-template pTemplate="footer">
        <div class=" surface-border">
            <button pButton pRipple icon="pi pi-times" (click)="displayInfoDialog = false" label="Abbrechen"></button>
            <button pButton pRipple icon="pi pi-check" (click)="onSaveInfo()" label="Speichern"></button>
        </div>
    </ng-template>
</p-dialog>



<div class="surface-card p-4 shadow-2 border-round" *ngIf="!isLoading">
    <div class="mb-3 flex align-items-center justify-content-between">
        <div>
            <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
            <span class="text-xl font-medium text-900 mx-3">Wasseranschluss {{wap.accessPointID}}: {{wap.description}}</span>
        </div>
        <div>
            <button pButton pRipple label="Löschen" icon="pi pi-trash" (click)="Delete()" class="mx-3"></button>
            <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="Save()"></button>
        </div>
    </div>

    <div>
        <ul class="surface-card p-0 m-0 list-none flex overflow-x-auto select-none my-3">
            <li class="px-0">
                <a pRipple class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150"
                   [ngClass]="{'tab-selected': active1 === 0, 'text-700 border-transparent no-ul': active1 !== 0}" (click)="active1 = 0">
                    <i class="pi pi-book mr-2"></i>
                    <span class="font-medium">Allgemeine Daten</span>
                </a>
            </li>
            <li class="flex align-items-center">
                <div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
            </li>
            <li class="px-3">
                <a pRipple class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150"
                   [ngClass]="{'tab-selected': active1 === 1, 'text-700 border-transparent no-ul': active1 !== 1}" (click)="onTabSelected(1)">
                    <i class="pi pi-list mr-2"></i>
                    <span class="font-medium">Verbrauchsdaten</span>
                </a>
            </li>

            <li class="flex align-items-center">
                <div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
            </li>
            <li class="px-3">
                <a pRipple class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150"
                   [ngClass]="{'tab-selected': active1 === 2, 'text-700 border-transparent no-ul': active1 !== 2}" (click)="onTabSelected(2)">
                    <i class="pi pi-box mr-2"></i>
                    <span class="font-medium">Bedarfseinheiten</span>
                </a>
            </li>

            <li class="flex align-items-center">
                <div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
            </li>
            <li class="px-3">
                <a pRipple class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150"
                   [ngClass]="{'tab-selected': active1 === 3, 'text-700 border-transparent no-ul': active1 !== 3}" (click)="active1 = 3">
                    <i class="pi pi-tags mr-2"></i>
                    <span class="font-medium">Sonstige Informationen</span>
                </a>
            </li>

            <li class="flex align-items-center">
                <div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
            </li>
            <li class="px-3">
                <a pRipple class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150"
                   [ngClass]="{'tab-selected': active1 === 4, 'text-700 border-transparent no-ul': active1 !== 4}" (click)="active1 = 4">
                    <i class="pi pi-history mr-2"></i>
                    <span class="font-medium">Verlauf Eigentümer</span>
                </a>
            </li>
          <li class="flex align-items-center">
            <div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
          </li>

          <li class="px-3">
            <a pRipple class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150"
               [ngClass]="{'tab-selected': active1 === 5, 'text-700 border-transparent no-ul': active1 !== 5}" (click)="active1 = 5">
              <i class="pi pi-book mr-2"></i>
              <span class="font-medium">Dokumente</span>
            </a>
          </li>

        </ul>
    </div>

    <div *ngIf="active1 == 0" class="row">
        <div class="col-md-12">


            <div class="card-body card-sidebar-menu">
                <form>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Nummer</label>
                                <input type="text" name="wapid" placeholder="Ihre ID" [(ngModel)]="wap.accessPointID" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>Baujahr</label>
                                <input type="text" name="created" placeholder="1900" [(ngModel)]="wap.yearCreated" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>GPS Koordinaten</label>
                                <input type="text" name="gps" placeholder="" [(ngModel)]="wap.gpsCoord" class="form-control" />
                            </div>
                        </div>
                        <div class="col-md-2 col-btn">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" name="drain" [(ngModel)]="wap.drainAccess" id="2">
                                <label class="form-check-label" for="2">Kanalanschluss</label>
                            </div>
                        </div>
                        <div class="col-md-1  col-btn">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" name="active" [(ngModel)]="wap.active" id="1">
                                <label class="form-check-label" for="2">Aktiv</label>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Beschreibung</label>
                                <input type="text" name="description" placeholder="" class="form-control" [(ngModel)]="wap.description" />
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Liegenschaft/Parzellen</label>
                                <input type="text" name="propertyId" placeholder="" class="form-control" [(ngModel)]="wap.propertyNumber" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Versorgungsgebiet</label>
                                <input type="text" name="supplyZone" placeholder="" class="form-control" [(ngModel)]="wap.supplyZone" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>Leitungsnummer</label>
                                <input type="text" name="pipeId" placeholder="" class="form-control" [(ngModel)]="wap.pipeNumber" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <div class="form-group">
                                <label>Adresse</label>
                                <input type="text" name="address1" placeholder="Adresse" class="form-control" [(ngModel)]="wap.address1" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label>PLZ</label>
                                <input type="text" name="zip" placeholder="PLZ" class="form-control" [(ngModel)]="wap.zip" />
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group">
                                <label>Ort</label>
                                <input type="text" name="city" placeholder="Ort" class="form-control" [(ngModel)]="wap.city" />
                            </div>
                        </div>


                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Eigentümer</label>
                                      <p-inputGroup>
                                        <button type="button" pButton icon="pi pi-times" class="p-button-danger" (click)="onRemoveOwner()"></button>
                                        <input type="text" name="owner" placeholder="" readonly class="form-control"
                                               value="{{ ownerName }}" />
                                        <button type="button" pButton icon="pi pi-search" class="p-button-success" (click)="onSelectMember()"></button>
                                      </p-inputGroup>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label>
                                    Rechnungsadresse

                                </label>

                                      <p-inputGroup>
                                        <button type="button" pButton icon="pi pi-times" class="p-button-danger" (click)="onRemoveInvoice()"></button>
                                        <input type="text" name="invoice" placeholder="" readonly class="form-control"
                                               value="{{ invoiceName }}" />

                                        <button type="button" pButton icon="pi pi-search" class="p-button-success" (click)="onSelectInvoice()"></button>
                                      </p-inputGroup>

                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-12">
                                        <input type="text" name="owner" placeholder="" readonly class="form-control"
                                               value="{{ ownerAddress }}" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-12">
                                        <input type="text" name="invoice" placeholder="" readonly class="form-control"
                                               value="{{ invoiceAddress }}" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Abrechnungsvorlage 1</label>

                                      <p-inputGroup>
                                        <input type="text" name="waterbillTemplateName1" placeholder="" readonly class="form-control"
                                               value="{{ waterbillTemplateName1  }}" />
                                        <button type="button" pButton icon="pi pi-search" class="p-button-success" (click)="onSelectTemplate(1)"></button>
                                      </p-inputGroup>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Abrechnungsvorlage 2</label>

                                      <p-inputGroup>
                                        <input type="text" name="waterbillTemplateName2" placeholder="" readonly class="form-control"
                                               value="{{ waterbillTemplateName2  }}" />
                                        <button type="button" pButton icon="pi pi-search" class="p-button-success" (click)="onSelectTemplate(2)"></button>
                                      </p-inputGroup>

                            </div>
                        </div>
                    </div>

                  <div class="row">
                    <div class="col-md-3 col-btn">
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" name="isQuota" [(ngModel)]="wap.isQuota">
                        <label class="form-check-label" for="2">Kontingent aktivieren</label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Kontingent [m³]/Jahr</label>
                        <input type="text" name="contingent" placeholder=""  class="form-control" [(ngModel)]="wap.quota" />
                      </div>
                    </div>

                    <div class="col-md-3 col-btn">
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" name="isPool" [(ngModel)]="wap.isPool">
                        <label class="form-check-label" for="2">Pool</label>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Größe [m³]</label>
                        <input type="text" name="poolSize" placeholder=""  class="form-control" [(ngModel)]="wap.poolSize" />
                      </div>
                    </div>
                  </div>
                </form>
            </div>


            <br />

            <p-panel header="Hauptwasserzähler" [toggleable]="false">
                <ng-template pTemplate="icons">
                    <a class="btn btn--primary mr-2" (click)="onSelectCounter()" style="margin-right: 10px;">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M15 6.5V9H11V11H15V13.5L18.5 10L15 6.5M9 10.5L5.5 14L9 17.5V15H13V13H9V10.5Z" />
                        </svg>
                        <span>Tauschen</span>
                    </a>

                    <a class="btn btn--primary mr-2" (click)="onSubCounter()" style="margin-right: 10px;">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M2 14H8V20H2M16 8H10V10H16M2 10H8V4H2M10 4V6H22V4M10 20H16V18H10M10 16H22V14H10" />
                        </svg>
                        <span>{{ subCounterTitle }}</span>
                    </a>


                    <a class="btn btn--primary" (click)="onPay()">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M7.07,11L7,12L7.07,13H17.35L16.5,15H7.67C8.8,17.36 11.21,19 14,19C16.23,19 18.22,17.96 19.5,16.33V19.12C18,20.3 16.07,21 14,21C10.08,21 6.75,18.5 5.5,15H2L3,13H5.05L5,12L5.05,11H2L3,9H5.5C6.75,5.5 10.08,3 14,3C16.5,3 18.8,4.04 20.43,5.71L19.57,7.75C18.29,6.08 16.27,5 14,5C11.21,5 8.8,6.64 7.67,9H19.04L18.19,11H7.07Z" />
                        </svg>
                        <span>Abrechnen</span>
                    </a>

                </ng-template>



                <div class="card-body card-sidebar-menu">
                    <form>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Zählertyp</label>
                                    <input type="text" name="counterType" placeholder="" readonly class="form-control" [(ngModel)]="counterType" />
                                </div>
                            </div>

                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>Zählerstand alt</label>
                                    <input type="number" name="counterCounts" placeholder="" readonly class="form-control" [(ngModel)]="counterCounts" />
                                </div>
                            </div>

                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Letztes Ablesedatum</label>
                              <input type="text" name="lastReading" placeholder="" readonly class="form-control" value="{{ lastReading.readingDate * 1000 | customDate }}" />
                            </div>
                          </div>

                        </div>

                        <div class="row justify-content-center" *ngIf="showCounterAlert">
                            <div class="col-md-11">
                                <div class="text-center">
                                    <div class="alert alert-warning alert-dismissible fade show">
                                        <strong>Ungültiger Zählerstand!</strong> Zählerstand neu muss größer als Zählerstand alt sein.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Seriennummer</label>
                                    <input type="text" name="serial" placeholder="" readonly class="form-control" [(ngModel)]="serial" [ngStyle]="{'cursor': wap.gaugeID > 0 ? 'pointer' : 'auto'} "
                                           (click)="onShowGauge()" />
                                </div>
                            </div>

                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Zählerstand neu</label>
                              <input type="text" name="newcounts" [readonly]="!isGauge" placeholder="" class="form-control" [(ngModel)]="newcounts" />
                            </div>
                          </div>

                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Neues Ablesedatum</label>
                              <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="true"
                                          [ngModelOptions]="{standalone: true}"
                                          [disabled]="!isGauge"
                                          [(ngModel)]="readingDate">
                              </p-calendar>
                            </div>
                          </div>


                          <div class="col-md-2">
                            <div class="form-group my-4">
                              <a class="btn btn--primary" (click)="onSaveCounter()" style="height:33px;">
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M17 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V7L17 3M19 19H5V5H16.17L19 7.83V19M12 12C10.34 12 9 13.34 9 15S10.34 18 12 18 15 16.66 15 15 13.66 12 12 12M6 6H15V10H6V6Z" />
                                </svg>
                                <span>Speichern</span>
                              </a>
                            </div>
                          </div>

                        </div>
                    </form>
                </div>


            </p-panel>

        </div>
    </div>

    <div *ngIf="active1 == 1" class="row">

        <div class="surface-card">
            <div class="mb-3 flex align-items-center justify-content-between">
                <span class="text-l font-medium text-900">Letzten Abrechnungen</span>
            </div>

            <p-table [value]="usages" selectionMode="single" styleClass="p-datatable-striped">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Von</th>
                        <th>bis</th>
                        <th>Teilrechnung</th>
                        <th>Menge</th>
                        <th>Frei</th>
                        <th>Rechnung</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-entity>
                    <tr>
                        <td>{{entity.from2 * 1000 | customDate }}</td>
                        <td>{{entity.to2 * 1000 | customDate }}</td>
                        <td *ngIf="entity.isPartPayment">
                            <i class="pi pi-check"></i>
                        </td>
                        <td *ngIf="!entity.isPartPayment">
                            <i class="pi pi-times"></i>
                        </td>
                        <td>{{entity.usage}}</td>
                        <td>{{entity.freeUsage}}</td>

                        <td *ngIf="entity.isBilled">
                            <i class="pi pi-check"></i>
                        </td>
                        <td *ngIf="!entity.isBilled">
                            <i class="pi pi-times"></i>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

        </div>

    </div>

    <div *ngIf="active1 == 2" class="row">

        <div class="surface-card">
            <div class="mb-3 flex align-items-center justify-content-between ">
                <div>
                    <p-checkbox inputId="privacy" [binary]="true" [(ngModel)]="wap.isFlatRate"></p-checkbox>
                    <span class="text-l font-medium text-900 ml-3">Bedarfseinheiten aktivieren</span>
                </div>
                <button *ngIf="wap.isFlatRate" pButton pRipple label="Hinzufügen" icon="pi pi-plus" (click)="showDialog()"></button>
            </div>

            <p-table *ngIf="wap.isFlatRate" [value]="needs" selectionMode="single" styleClass="p-datatable-striped">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Name</th>
                        <th>Menge</th>
                        <th>Letzte Änderung</th>
                        <th>Aktiv</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-entity>
                    <tr>
                        <td>{{entity.name}}</td>
                        <td>{{entity.quantity | number: '1.2'}}</td>
                        <td>{{entity.lastChanged | customDate}}</td>
                        <td>{{entity.active}}</td>
                        <td style="text-align:right;">
                            <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="onEditNeed(entity)"></button>
                            <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="onDeleteNeed(entity)"></button>
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="summary">
                    <div class="flex align-items-center justify-content-between">
                        Gesamt {{ totalNeeds | number: '1.2' }} BE
                    </div>
                </ng-template>
            </p-table>

        </div>

    </div>

    <div *ngIf="active1 == 3" class="row">
        <div class="col-md-12">


            <div class="card-body card-sidebar-menu">
                <div class="mb-3 flex align-items-center justify-content-between">

                    <div></div>
                    <div></div>
                    <button pButton pRipple label="Hinzufügen" icon="pi pi-plus" (click)="onAddInfo()"></button>
                </div>

                <p-table [value]="wapInfo" selectionMode="single" styleClass="p-datatable-striped">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Erstellt</th>
                            <th>Text</th>
                            <th>Anschlussgebühr</th>
                            <th>Wohnfläche</th>
                            <th>Sonstige Fläche</th>

                            <th>Aktiv</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-entity>
                        <tr>
                            <td>{{entity.created | customDate}}</td>
                            <td>{{entity.text}}</td>
                            <td>{{entity.payedFee  | currency:'EUR'}}</td>
                            <td>{{entity.livingSpace}}</td>
                            <td>{{entity.otherSpace}}</td>

                            <td>{{entity.active}}</td>
                            <td style="text-align:right;">
                                <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="onEditInfo(entity)"></button>
                                <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="onDeleteInfo(entity)"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>


            </div>
        </div>
    </div>

    <div *ngIf="active1 == 4" class="row">
        <div class="col-md-12">


            <div class="card-body card-sidebar-menu">


                <p-table [value]="wapOwner" styleClass="p-datatable-striped">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Änderung</th>
                            <th>Name</th>
                            <th>Adresse</th>
                            <th>Von</th>
                            <th>Bis</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-entity>
                        <tr>
                            <td>{{entity.addressType}}</td>
                            <td>{{entity.name}}</td>
                            <td>{{entity.address}}</td>
                            <td>{{entity.startDate}}</td>
                            <td>{{entity.endDate}}</td>
                        </tr>
                    </ng-template>
                </p-table>


            </div>
        </div>
    </div>

  <div *ngIf="active1 == 5">
    <div class="form-group">
      <div class="row">
        <app-dms-attachments #attachments titleNew="Dokument hinzufügen" title="Dokumente" tableName="WaterAccessPoint" [PK]="id"></app-dms-attachments>
      </div>
    </div>
  </div>
