<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="min-h-screen flex flex-column relative flex-auto pl-2">
  <div class="surface-card p-4 shadow-2 border-round">
    <div class="mb-3 flex align-items-center justify-content-between">
      <div>
        <span class="text-xl font-medium text-900">Aktuelle Zählerliste</span>
      </div>
      <div>
        <button pButton pRipple label="Neue Liste" icon="pi pi-table" (click)="onClear()" class="mx-3"></button>
        <button pButton pRipple label="Übertragen" icon="pi pi-upload" (click)="onImport()" class="mx-3"></button>
      </div>
      <div>
        <button pButton pRipple label="Drucken" icon="pi pi-print" (click)="onPrint()" class="mx-3"></button>
        <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="onSave()"></button>
      </div>
    </div>


    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body" style="height: calc(100vh - 400px);">
            <div class="row">
              <div class="col-md-12 col-btn">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" name="showOnlyMissing" [(ngModel)]="showOnlyMissing"
                         id="2">
                  <label class="form-check-label" for="2">Nur fehlende Zählerstände anzeigen</label>
                </div>

              </div>
            </div>

            <br/>
            <div class="row">
              <p-table [value]="items" dataKey="wapId" responsiveLayout="scroll" [scrollable]="true"
                       scrollHeight="calc(100vh - 530px)" styleClass="p-datatable-striped"
                       [globalFilterFields]="['gaugeSerial']" selectionMode="single">
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="accessPointID">Wasseranschluss
                      <p-sortIcon field="accessPointID"></p-sortIcon>
                    </th>
                    <th pSortableColumn="city">Ort
                      <p-sortIcon field="city"></p-sortIcon>
                    </th>
                    <th pSortableColumn="address">Adresse
                      <p-sortIcon field="address"></p-sortIcon>
                    </th>

                    <th pSortableColumn="owner">Eigentümer
                      <p-sortIcon field="owner"></p-sortIcon>
                    </th>
                    <th pSortableColumn="gaugeSerial">Zählernummer
                      <p-sortIcon field="gaugeSerial"></p-sortIcon>
                    </th>
                    <th pSortableColumn="lastCount" style="text-align: right">Alter Zählerstand
                      <p-sortIcon field="lastCount"></p-sortIcon>
                    </th>
                    <th pSortableColumn="currentCount" style="text-align: right">Zählerstand
                      <p-sortIcon field="currentCount"></p-sortIcon>
                    </th>
                    <th pSortableColumn="readingDate">Ablesedatum
                      <p-sortIcon field="readingDate"></p-sortIcon>
                    </th>
                    <th pSortableColumn="status">Status
                      <p-sortIcon field="status"></p-sortIcon>
                    </th>
                    <th style="width: 5rem"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                  <tr style="height:40px"
                      *ngIf="!showOnlyMissing || (item.currentCount == 0 || item.currentCount < item.lastCount)">
                    <td>{{ item.accessPointID }}</td>
                    <td>{{ item.city }}</td>
                    <td>{{ item.address }}</td>
                    <td>{{ item.owner }}</td>
                    <td>{{ item.gaugeSerial }}</td>
                    <td style="text-align: right">{{ item.lastCount | number:'1.2' }}</td>
                    <td pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-inputNumber mode="decimal" [allowEmpty]="false" name="count" class="form-control p-0"
                                         [(ngModel)]="item.currentCount" style="width:80px"></p-inputNumber>
                        </ng-template>
                        <ng-template pTemplate="output">
                          <div style="text-align: right">
                            {{ item.currentCount | number: '1.2' }}
                          </div>
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input pInputText type="text" name="count" class="form-control p-0"
                                 [(ngModel)]="item.readingDate" style="width:80px"/>
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{ item.readingDate }}
                        </ng-template>
                      </p-cellEditor>
                    </td>
                    <td>
                      <p-tag *ngIf="item.currentCount > 0 && item.lastCount == item.currentCount" value="Übertragen"
                             [rounded]="true" severity="primary"></p-tag>
                      <p-tag *ngIf="item.lastCount > item.currentCount && item.currentCount > 0" value="Fehler"
                             [rounded]="true" severity="danger"></p-tag>
                      <p-tag *ngIf="item.currentCount == 0" value="Fehlt" [rounded]="true" severity="warning"></p-tag>
                      <p-tag *ngIf="item.lastCount < item.currentCount" value="Neu" [rounded]="true"
                             severity="success"></p-tag>
                    </td>
                    <td>
                      <button type="button" pButton pRipple icon="pi pi-search" (click)="selectItem(item)"></button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
