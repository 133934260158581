
export class UserMember {
  id: number;

  email: string;

  contactId: number;

  public constructor() {
    this.email = "";
    this.contactId = 0;
  }
}
