import {EntityBase} from "../shared/entity.base";

export class MonthEndClosing extends EntityBase {
  public accountId: number = 0;           // Reference to account
  public financialYearId: number = 0;     // Reference to financial year
  public year: number = 0;       // Financial year
  public month: number = 0;               // Month 1-12
  public balance: number = 0;             // Balance by end of month
  public earnings: number = 0;            // Always positive or zero value
  public expenses: number = 0;            // Always negative or zero value
  public salesTax: number = 0;            // The tax balance for this month - Positive or negative value (negative you get tax back, positive you have to pay tax)
  public completed: boolean = false;      // You may not change it anymore and you can final accept it
  public accepted: boolean = false;       // Final acceptance of the month-end closing - no changes possible anymore
}
