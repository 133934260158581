<div class="row">
  <div class="col-md-12">
    <div class="card">

      <br>

      <form (ngSubmit)="onSubmit(f)" #f="ngForm">
        <div class="px-4">
          <div class="input-group mb-3">
            <input type="text" name="searchMember" class="form-control input-lg" placeholder="Suchtext"
              aria-label="Recipient's username" aria-describedby="basic-addon2" ngModel>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" [disabled]="!f.valid" type="submit">Suchen</button>
            </div>
          </div>
        </div>
      </form>


      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Nummer</th>
              <th scope="col">Name</th>
              <th scope="col">Adresse</th>


            </tr>
          </thead>
          <tbody class="table-users-body">
            <tr *ngFor="let member of members" style="cursor: pointer;" (click)="onSelected(member)">
              <td class="table-users-id">{{ member.contactID }}</td>
              <td class="table-users-name">
                {{ member.orgName ? member.orgName : member.firstName + ' ' + member.lastName}}</td>
              <td class="table-users-name">{{ member.address1}}</td>

            </tr>
          </tbody>
        </table>
      </div>

      <p-paginator class="my-paginator" [rows]="10" [totalRecords]="totalMembers" pageLinkSize="3"
        (onPageChange)="paginate($event)"></p-paginator>
    </div>
  </div>
</div>
