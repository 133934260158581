import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, Observable, Subject, throwError } from "rxjs";
import { Globals } from "../../globals";
import { SupportTicket } from './ticket.model'

@Injectable({ providedIn: 'root' })
export class TicketService {

  TicketStatus: string[] = ['1 - Erstellt', '2 - In Bearbeitung', '3 - Erledigt'];
  TicketCategory: string[] = ['Hilfe', 'Fehler', 'Wunsch/Vorschlag'];

  private readonly APIUrl = this.globals.APP_URL + 'tickets/SupportTicket' ;

  constructor(protected globals: Globals,
    protected router: Router,
    protected http: HttpClient) {
  

  }

  get(search: string) {
    let url = this.APIUrl + '/query';
    let params = new HttpParams();
    params = params.append('query', search);
    return this.http.get<SupportTicket[]>(url, { params: params });
  }

  putItem(p: SupportTicket) {
    return this.http.put<SupportTicket>(this.APIUrl, p).pipe(
      catchError(this.handleError));
  }

  postItem(p: SupportTicket) {
    return this.http.post<SupportTicket>(this.APIUrl, p).pipe(
      catchError(this.handleError));
  }

  readItem(id: string) {
    const url = this.APIUrl + '/' + id;
    return this.http.get<SupportTicket>(url).pipe(
      catchError(this.handleError));
  }

  deleteItem(id: string | null, rev: string | null) {
    const url = this.APIUrl + '/' + id + '/' + rev;
    return this.http.delete(url).pipe(
      catchError(this.handleError));
  }

  private handleError(error: any): Observable<never> {
    console.error('An error occurred', error);
    return throwError(error);
  }
}
