

import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Globals } from '../../globals';
import { Equipment } from '../../shared/equipment.model';
import { MaintenancePlanEntryViewModel } from './maintenance.planentry.model';
import { MaintenanceWorkEntry, MaintenanceWorkEntryViewModel } from './maintenance.workentry.model'

@Injectable({ providedIn: 'root' })
export class MaintenanceWorkService {

  itemList = new Subject<MaintenanceWorkEntryViewModel[]>();
  itemArray = new Array<MaintenanceWorkEntryViewModel>();

  selectedItem: MaintenanceWorkEntry = null;
  totalItems: number;

  constructor(private globals: Globals,
    private router: Router,
    private http: HttpClient,
    public datepipe: DatePipe
  ) {

  }

  getItems(search: string, from: Date, to: Date) {
    const url = this.globals.APP_URL + 'maintenancework';
    let params = new HttpParams();
    params = params.append('search', search);
    params = params.append('from', this.datepipe.transform(from, 'dd.MM.yyyy'));
    params = params.append('to', this.datepipe.transform(to, 'dd.MM.yyyy'));

    this.http
      .get<MaintenanceWorkEntryViewModel[]>(url, {
        params: params,
        observe: 'response',
      })
      .subscribe((respData) => {
        this.totalItems = parseInt(respData.headers.get('x-total-count'));
        this.itemArray = respData.body.slice();
        this.itemList.next(this.itemArray);
      });
  }

  readPlan(search: string) {
    const url = this.globals.APP_URL + 'maintenancework/plan';
    let params = new HttpParams();
    params = params.append('search', search);
    return this.http.get<MaintenancePlanEntryViewModel[]>(url, { params: params });
  }

  addItem(p: MaintenanceWorkEntry) {
    const url = this.globals.APP_URL + 'maintenancework';

    this.http.post<MaintenanceWorkEntry>(url, p).subscribe((respData) => {

      this.selectedItem = respData;
      this.router.navigate(['services', 'maintenance', 'work', this.selectedItem.id], );

    });
  }

  getItem(id: number) {
    //this.selectedProduct = this.productArray.find((p) => p.id === id);

    var wi = Object.assign(
      new MaintenanceWorkEntryViewModel(),
      this.itemArray.find((p) => p.id === id));

    this.selectedItem = Object.assign(
      new MaintenanceWorkEntry(),
      this.itemArray.find((p) => p.id === id)
    );
    return wi;
  }

  readItem(id: number) {
    const url = this.globals.APP_URL + 'maintenancework/' + id;
    return this.http.get<MaintenanceWorkEntry>(url);
  }

  updateItem(p: MaintenanceWorkEntry) {
    const url = this.globals.APP_URL + 'maintenancework';
    this.http.put<MaintenanceWorkEntry>(url, p).subscribe((respData) => {
      this.selectedItem = respData;
      this.router.navigate([
        'services',
        'maintenance',
        'work',
        this.selectedItem.id,
      ]);
    });
  }

}
