import { EntityBase } from "./entity.base";

export class NumberRange extends EntityBase {
  name: string = '';
  targetTable: string = '';

  prefix: string = '';
  postfix: string = '';

  zeroPadding: boolean = false;
  digits: Number = 0;
  nextNumber: Number = 1;
}
