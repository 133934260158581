import { Gauge } from '../shared/gauge.model';
import { Reading } from '../shared/reading.model';

export class GaugeChange {
  oldGauge: Gauge;
  lastReading: Reading;

  newGauge: Gauge;
  firstReading: Reading;
}
