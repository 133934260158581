import {Component, OnInit} from '@angular/core';
import {GeneralFunctionService} from "../../shared/general-function.service";
import {DocumentViewModel} from "../../services/dms/document.viewmodel";
import {DmsService} from "../../services/dms/dms.service";

@Component({
  selector: 'app-backup',
  templateUrl: './backup.component.html',
  styleUrl: './backup.component.css'
})
export class BackupComponent implements OnInit {

  showBackupDialog: boolean = false;
  backupRunning: boolean = false;

  docs: DocumentViewModel[] = [];

  selectedDocument: DocumentViewModel;
  totalDocuments: number;

  constructor(private generalFunctionService: GeneralFunctionService, private dms: DmsService) {
  }

  ngOnInit(): void {
    this.readBackups();
  }

  readBackups() {
    this.docs = [];
    this.dms.getByType('Backup').subscribe(d => {
      this.docs = d.slice();
    });
  }

  onShowBackupDialog() {
    this.showBackupDialog = true;
  }

  onCreateBackup() {
    this.backupRunning = true;
    this.generalFunctionService.createBackup().subscribe(() => {
      this.backupRunning = false;
      this.showBackupDialog = false;
      this.readBackups();
    });
  }

  onSelected2(event) {

  }

  paginate(event) {

  }
}
