<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-toast position="bottom-right" key="tl"></p-toast>


<div class="surface-card  pt-4 pl-2 shadow-2 border-round mb-2">
  <div class="mb-3 flex align-items-center justify-content-between">
    <div>
      <span class="text-xl font-medium text-900 mx-3">Verbrauch erzeugen</span>
    </div>


  </div>

  <div class="grid formgrid p-fluid">
    <div class="field col-12 md:col-12 m-0">
      <div class="flex flex-wrap gap-3 p-4">
        <div class="flex align-items-center">
          <p-radioButton id="withCounter" [value]="false" [(ngModel)]="isFlatRate"
                         inputId="ingredient1"></p-radioButton>
          <label for="withCounter" class="ml-2">Abrechnung mit Zähler</label>
        </div>

        <div class="flex align-items-center">
          <p-radioButton id="withoutCounter" [value]="true" [(ngModel)]="isFlatRate"
                         inputId="ingredient2"></p-radioButton>
          <label for="withoutCounter" class="ml-2">Pauschale Abrechnung</label>
        </div>


      </div>

    </div>

    <div class="field col-12 md:col-12 m-0">
      <hr/>
    </div>

    <div *ngIf="isFlatRate" class="field mb-3 col-2 md:col-2 ml-4 mt-4">
      <p-checkbox inputId="privacy" [binary]="true" [(ngModel)]="isPartPayment" [readonly]="!isFlatRate"></p-checkbox>
      <span class="ml-2 text-900">Teilrechnung</span>
    </div>
    <div *ngIf="!isFlatRate" class="field mb-3 col-2 md:col-2 ml-4 mt-4">
      <p-checkbox inputId="privacy" [binary]="true" [ngModel]="true" [readonly]="!isFlatRate"></p-checkbox>
      <span class="ml-2 text-900">Teilrechnung</span>
    </div>

    <div class="field mb-3 col-3 md:col-3 ml-4" *ngIf="isPartPayment || !isFlatRate">
      <label for="percentage">Teilbetrag</label>
      <p-inputNumber id="percentage" [useGrouping]="false" [(ngModel)]="amount" [minFractionDigits]="2" [min]="0"
                     [max]="100"></p-inputNumber>
    </div>

    <div class="field mb-3 col-2 md:col-2 ml-4 mt-4" *ngIf="isPartPayment || !isFlatRate">
      <p-checkbox inputId="privacy" [binary]="true" [(ngModel)]="isFixedAmount"></p-checkbox>
      <span class="ml-2 text-900">Prozentuell zur letzten Abrechnung</span>
    </div>


    <div class="field col-12 md:col-12 m-0">
      <hr/>
    </div>

    <div class="field col-4 md:col-4 m-0 mb-4">
      <div class="flex justify-content-between align-items-center mb-3">
        <div class="mx-4">
          <span>Zeitraum von: </span>
          <p-calendar [(ngModel)]="rangeDates[0]" [readonlyInput]="false" inputId="from"
                      dateFormat="dd.mm.yy"></p-calendar>
        </div>

        <div class="mx-4">
          <span>bis: </span>
          <p-calendar [(ngModel)]="rangeDates[1]" [readonlyInput]="false" inputId="to"
                      dateFormat="dd.mm.yy"></p-calendar>
        </div>

        <div class="ml-auto mt-3">
          <button pButton pRipple label="Erzeugen" icon="pi pi-star" (click)="onCreate()"></button>
        </div>
      </div>
    </div>
  </div>
</div>
