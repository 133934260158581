import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Contact } from '../../shared/contact.model';
import { Wap, WapViewModel } from '../../shared/wap.model';
import { WapService } from '../../water-access-point/wap.service';

@Component({
  selector: 'app-mywaps',
  templateUrl: './mywaps.component.html',
  styleUrls: ['./mywaps.component.css']
})
export class MywapsComponent implements OnInit, OnChanges {

  @Input('member') member: Contact;
  ownerName: string = "";
  waps: WapViewModel[];
  selectedWap: Wap;

  constructor(public wapService: WapService,
    private router: Router  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    for (let propName in changes) {
      if (propName === 'member') {
        this.update();
      }
    }
  }

  ngOnInit(): void {
  }

  update() {
    if (this.member) {
      this.wapService.getWaps('', 5, 1, this.member.id).subscribe(w => {
        this.waps = w;
      });
    }
  }

  onSelected2(event) {

    this.wapService.selectedWap = this.selectedWap;
    //this.onNavigateToWaps();

    this.router.navigate(['wap-module', 'wap', this.selectedWap.id, 'edit']);



  }

  onNavigateToWaps() {
    if (this.waps.length > 0 && this.member != null) {
      this.router.navigate(['wap-module', 'wap'], { queryParams: { memberid: this.member.id } });
    }
  }
}
