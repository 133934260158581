<p-dialog [(visible)]="showBackupDialog" appendTo="body" [modal]="true"
          [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
          [style]="{width: '50vw'}">
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
            <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle"
                  style="width:32px;height:32px">
                <i class="pi pi-info text-lg"></i>
            </span>
      <span class="font-medium text-2xl text-900">Datensicherung erstellen</span>
    </div>
  </ng-template>

  <div class="grid formgrid p-fluid" *ngIf="!backupRunning">
    <p class="m-5 p-0 text-600 line-height-3 mr-3">
      <strong>Wichtig:</strong> Stellen Sie sicher dass keine weiteren Benutzer mit der Datenbank verbunden sind. Es
      werden alle Verbindungen zur Datenbank getrennt.
      Dies kann zu unerwarteten Fehlern und Datenverlust führen.
    </p>


  </div>
  <div class="flex justify-content-center align-items-center" *ngIf="backupRunning">
    <p>
      Datensicherung wird erstellt. Bitte warten...

      <app-loading-spinner></app-loading-spinner>
    </p>
  </div>
  <div class="flex justify-content-center align-items-center mx-5" *ngIf="backupRunning">

    Bitte schließen Sie dieses Fenster nicht. Es wird automatisch geschlossen, wenn die Datensicherung abgeschlossen
    ist.

  </div>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-between align-items-center" *ngIf="!backupRunning">
      <button pButton pRipple label="Abbrechen" icon="pi pi-times" (click)="showBackupDialog = false"></button>
      <button pButton pRipple label="Sicherung erstellen" icon="pi pi-save" (click)="onCreateBackup()"></button>
    </div>
  </ng-template>
</p-dialog>


<div class="surface-card  pt-1 pl-1 shadow-2 border-round mb-2">
  <div class="surface-section p-4">
    <div class="grid">
      <div class="col-12 lg:col-2">
        <div class="text-xl font-medium text-900 mb-3">Datensicherung</div>
        <p class="m-0 p-0 text-600 line-height-3 mr-3">
          Hier kann die Sicherung und Wiederherstellung der Datenbank durchgeführt werden. Sicherungen werden am
          internen DMS
          gespeichert.
          Wir empfehlen, regelmäßig Sicherungen durchzuführen und die Dateien herunterzuladen und an einem sicheren Ort
          zu speichern.
        </p>
        <p class="m-0 p-0 text-600 line-height-3 mr-3">
          <strong>Wichtig:</strong> Die Sicherung enthält alle Daten, die in der Datenbank gespeichert sind. Das
          Wiederherstellen einer Sicherung überschreibt alle vorhandenen Daten. Es werden alle offenen Verbindungen zur
          Datenbank getrennt.
        </p>

      </div>

      <div class="col-12 lg:col-10">
        <div class="grid formgrid p-fluid">

          <div class="col-16 lg:col-6 ">
            <div class="flex justify-content-between align-items-center">

              <div></div>
              <div></div>

              <div class="ml-auto">
                <button pButton pRipple label="Datensicherung erstellen ..." icon="pi pi-save"
                        (click)="onShowBackupDialog()"></button>
              </div>

            </div>
          </div>
        </div>

        <br/>
        <hr/>
        <div class="text-l font-medium text-900 mt-5 ml-2">Vorhandene Datensicherungen</div>

        <p-table [value]="docs" selectionMode="single" [(selection)]="selectedDocument" dataKey="id"
                 responsiveLayout="scroll"
                 (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped">
          <ng-template pTemplate="header">
            <tr>
              <th>Name</th>
              <th>Typ</th>
              <th>Pfad</th>
              <th>Erstellt</th>
              <th>Größe</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-doc>
            <tr [pSelectableRow]="doc">
              <td>{{ doc.name }}</td>
              <td>{{ doc.docType }}</td>
              <td>{{ doc.folderName }}</td>
              <td>{{ doc.created * 1000 | customDate }}</td>
              <td>{{ doc.fileSize | filesize }}</td>
            </tr>
          </ng-template>
        </p-table>

      </div>


    </div>
  </div>
</div>
