
<div class="surface-card px-4 py-8 md:px-6 lg:px-8">
    <div class="grid">


        <div class="col-12 lg:col-6">
            <div class="h-full pl-3">
                <div class="surface-card shadow-2 p-5" style="border-radius: 6px">
                    <div class="flex flex-column justify-content-between md:flex-row">
                        <div class="flex-grow-1 lg:w-min">
                            <div class="text-900 font-bold text-2xl mb-2">Profi - AKTION</div>
                            <div class="text-500 text-xl">Professionelle Version</div>
                            <div class="text-500 text-xl">
                                1,50€ / Wasseranschluss / Jahr
                                <div class="text-500 text-xl">
                                    + 100€ Bereitstellungsgebühr / Jahr
                                </div>
                                </div>
                                <div class="text-500 text-sm">
                                  (Preise netto +20% MwSt.)
                                </div>
                            </div>

                        <div class="flex align-items-center w-auto lg:w-12rem lg:justify-content-end mt-3 lg:mt-0">
                            <span class="font-bold text-4xl text-900">1,50€</span>
                            <span class="text-700 text-xl ml-2 line-height-3">pro Jahr</span>
                        </div>
                    </div>
                    <hr class="my-5 mx-0 border-top-1 border-bottom-none surface-border" />
                    <div class="text-900 font-medium text-xl pb-1">Features</div>
                    <div class="flex flex-column justify-space-between md:flex-row">
                        <ul class="list-none p-0 m-0 flex-grow-1">
                            <li class="flex align-items-center my-3">
                                <i class="pi pi-check-circle text-green-500 mr-3"></i>
                                <span>Unlimitierte Wasseranschlüsse</span>
                            </li>
                            <li class="flex align-items-center my-3">
                                <i class="pi pi-check-circle text-green-500 mr-3"></i>
                                <span>Verwalten von Wasserzählern</span>
                            </li>
                            <li class="flex align-items-center my-3">
                                <i class="pi pi-check-circle text-green-500 mr-3"></i>
                                <span>Ein-/Ausgangsrechnungen</span>
                            </li>
                            <li class="flex align-items-center my-3">
                                <i class="pi pi-check-circle text-green-500 mr-3"></i>
                                <span>Wartungsbuch</span>
                            </li>
                        </ul>
                        <ul class="list-none p-0 md:pl-2 m-0 flex-grow-1">
                            <li class="flex align-items-center my-3">
                                <i class="pi pi-check-circle text-green-500 mr-3"></i>
                                <span>Dokumentenverwaltung</span>
                            </li>
                            <li class="flex align-items-center my-3">
                                <i class="pi pi-check-circle text-green-500 mr-3"></i>
                                <span>Automatische Wasserrechnung</span>
                            </li>
                            <li class="flex align-items-center my-3">
                                <i class="pi pi-check-circle text-green-500 mr-3"></i>
                                <span>Offene Posten und Mahnwesen</span>
                            </li>
                            <li class="flex align-items-center my-3">
                                <i class="pi pi-check-circle text-green-500 mr-3"></i>
                                <span>Umsatzsteuerauswertung</span>
                            </li>
                        </ul>
                    </div>
                    <hr class="my-3 mx-0 border-top-1 border-bottom-none surface-border" />
                    <button pButton pRipple class="w-full mt-2" label="Jetzt aktivieren"></button>
                </div>
            </div>
        </div>


      <div class="col-12 lg:col-6">
        <div class="h-full pl-3">
          <div class="surface-card shadow-2 p-5" style="border-radius: 6px">
            <div class="flex flex-column justify-content-between md:flex-row">
              <div class="flex-grow-1 lg:w-min">
                <div class="text-900 font-bold text-2xl mb-2">Standard</div>
                <div class="text-500 text-xl">Standard Version</div>
                <div class="text-500 text-xl">
                  1,50€ / Wasseranschluss / Jahr
                  <div class="text-500 text-xl">
                    + 100€ Bereitstellungsgebühr / Jahr
                  </div>
                </div>
                <div class="text-500 text-sm">
                  (Preise netto +20% MwSt.)
                </div>
              </div>

              <div class="flex align-items-center w-auto lg:w-12rem lg:justify-content-end mt-3 lg:mt-0">
                <span class="font-bold text-4xl text-900">1,50€</span>
                <span class="text-700 text-xl ml-2 line-height-3">pro Jahr</span>
              </div>
            </div>
            <hr class="my-5 mx-0 border-top-1 border-bottom-none surface-border" />
            <div class="text-900 font-medium text-xl pb-1">Features</div>
            <div class="flex flex-column justify-space-between md:flex-row">
              <ul class="list-none p-0 m-0 flex-grow-1">
                <li class="flex align-items-center my-3">
                  <i class="pi pi-check-circle text-green-500 mr-3"></i>
                  <span>Unlimitierte Wasseranschlüsse</span>
                </li>
                <li class="flex align-items-center my-3">
                  <i class="pi pi-check-circle text-green-500 mr-3"></i>
                  <span>Verwalten von Wasserzählern</span>
                </li>
                <li class="flex align-items-center my-3">
                  <i class="pi pi-check-circle text-green-500 mr-3"></i>
                  <span>Ein-/Ausgangsrechnungen</span>
                </li>
                <li class="flex align-items-center my-3">
                  <i class="pi pi-check-circle text-green-500 mr-3"></i>
                  <span>Wartungsbuch</span>
                </li>
              </ul>
              <ul class="list-none p-0 md:pl-2 m-0 flex-grow-1">
                <li class="flex align-items-center my-3">
                  <i class="pi pi-check-circle text-green-500 mr-3"></i>
                  <span>Dokumentenverwaltung</span>
                </li>
                <li class="flex align-items-center my-3">
                  <i class="pi pi-times-circle text-green-500 mr-3"></i>
                  <span>Automatische Wasserrechnung</span>
                </li>
                <li class="flex align-items-center my-3">
                  <i class="pi pi-times-circle text-green-500 mr-3"></i>
                  <span>Offene Posten und Mahnwesen</span>
                </li>
                <li class="flex align-items-center my-3">
                  <i class="pi pi-times-circle text-green-500 mr-3"></i>
                  <span>Umsatzsteuerauswertung</span>
                </li>
              </ul>
            </div>
            <hr class="my-3 mx-0 border-top-1 border-bottom-none surface-border" />
            <button pButton pRipple class="w-full mt-2" label="Jetzt aktivieren"></button>
          </div>
        </div>
      </div>
    </div>
</div>
