
<div class="min-h-screen flex surface-ground">
    <div id="app-sidebar" class="h-screen hidden lg:block flex-shrink-0 fixed lg:sticky left-0 top-0 z-1 border-right-1 border-top-1 surface-border w-full md:w-auto">


        <div class="flex h-full">

            <div class="flex flex-column surface-0 py-2 overflow-y-auto flex-shrink-0 flex-grow-1 md:flex-grow-0" style="width:280px">
                <div class="overflow-y-auto">
                    <ul class="list-none p-2 m-0">
                        <li>
                            <div class="p-3 text-500 font-medium">FINANZEN</div>
                        </li>

                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/finance/transactions']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-euro mr-2"></i>
                                <span class="font-medium">Umsätze</span>
                            </a>
                        </li>

                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                               [routerLink]="['/finance/open-invoices']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-folder-open mr-2"></i>
                                <span class="font-medium">Offene Posten</span>
                                <p-badge *ngIf="count > 0" [value]="count" styleClass="ml-2" severity="warning"></p-badge>
                            </a>
                        </li>

                        <li hidden>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/finance/cash-book']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-money-bill mr-2"></i>
                                <span class="font-medium">Kassabuch</span>
                            </a>
                        </li>
                    </ul>

                    <ul class="list-none p-2 m-0">
                        <li>
                            <div class="p-3 text-500 font-medium">WERKZEUGE</div>
                        </li>
                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/finance/balancing']" routerLinkActive="settings-item-selected" >
                                <i class="pi pi-sync mr-2"></i>
                                <span class="font-medium">Kontoauszug abgleichen</span>
                            </a>
                        </li>
                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/finance/monthly-closings']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-verified mr-2"></i>
                                <span class="font-medium">Buchungsabschluss</span>
                            </a>
                        </li>

                      <li>
                        <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/finance/dunnings']" routerLinkActive="settings-item-selected">
                          <i class="pi pi-bolt mr-2"></i>
                          <span class="font-medium">Mahnlauf durchführen</span>
                        </a>
                      </li>

                      <li>
                        <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/finance/templates']" routerLinkActive="settings-item-selected">
                          <i class="pi pi-clone mr-2"></i>
                          <span class="font-medium">Vorlagen</span>
                        </a>
                      </li>
                    </ul>

                    <ul class="list-none p-2 m-0">
                        <li>
                            <div class="p-3 text-500 font-medium">AUSWERTUNGEN</div>
                        </li>
                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/finance/tax-report']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-percentage mr-2"></i>
                                <span class="font-medium">Umsatzsteuer</span>
                            </a>
                        </li>
                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/finance/summary']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-chart-bar mr-2"></i>
                                <span class="font-medium">Ein-/Ausgaben</span>
                            </a>
                        </li>
                    </ul>

                </div>


            </div>
        </div>
    </div>

    <div class="min-h-screen flex flex-column relative flex-auto pl-2">


        <router-outlet></router-outlet>


    </div>

</div>
