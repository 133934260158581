import {Component, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {Subscription} from 'rxjs';

import {Gauge} from '../../../shared/gauge.model';
import {GaugeService} from '../gauge.service';
import {IDataService} from '../../../shared/dataservice.interface';
import {Globals} from "../../../globals";

@Component({
  selector: 'app-gauge-list',
  templateUrl: './gauge-list.component.html',
  styleUrls: ['./gauge-list.component.css'],
})
export class GaugeListComponent implements OnInit, OnDestroy, IDataService {
  subGauge: Subscription;
  gauges: Gauge[];
  isLoading = false;

  selectedGauge: Gauge;

  searchString: string = '';
  totalGauges: number = 1;

  constructor(public gaugeService: GaugeService, private router: Router, public globals: Globals) {
  }

  ngOnInit(): void {
    this.gaugeService.detailActive = '';
    this.gaugeService.listActive = 'active';

    this.gauges = this.gaugeService.gaugeArray;
    this.totalGauges = this.gaugeService.totalGauges;

    this.isLoading = true;

    this.subGauge = this.gaugeService.gauges.subscribe((p) => {
      this.totalGauges = this.gaugeService.totalGauges;
      this.gauges = p;
      this.isLoading = false;
      if (p.length) {
        this.gaugeService.selectedGauge = p[0];
      }
    });

    this.gaugeService.getGauges('', 15, 1, this.gaugeService.isStock, this.gaugeService.isActive);
  }

  ngOnDestroy(): void {
    this.subGauge.unsubscribe();
  }

  onSubmit(form: NgForm) {
    this.searchString = form.value.searchGauge;
    this.gaugeService.getGauges(this.searchString, 15, 1, this.gaugeService.isStock, this.gaugeService.isActive);
  }

  onSelected2(event) {
    this.gaugeService.selectedGauge = this.selectedGauge;
    this.router.navigate(['masterdata', 'gauges', event.data.id]);

    this.gaugeService.detailActive = 'active';
    this.gaugeService.listActive = '';
  }

  paginate(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    this.gaugeService.getGauges(this.searchString, 15, event.page + 1, this.gaugeService.isStock, this.gaugeService.isActive);
  }

  New() {
    this.router.navigate(['masterdata', 'gauges', 'new']);
  }

  Copy() {
    console.log("copy");
  };

  Save() {
    console.log("save");
  };

  Delete() {
    console.log("delete");
  };
}
