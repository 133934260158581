
<div class="min-h-screen flex surface-ground">
    <div id="app-sidebar" class="h-screen hidden lg:block flex-shrink-0 fixed lg:sticky left-0 top-0 z-1 border-right-1 border-top-1 surface-border w-full md:w-auto">


        <div class="flex h-full">

            <div class="flex flex-column surface-0 py-2 overflow-y-auto flex-shrink-0 flex-grow-1 md:flex-grow-0" style="width:280px">
                <div class="overflow-y-auto">
                    <ul class="list-none p-2 m-0">
                        <li>
                            <div class="p-3 text-500 font-medium">WARTUNG</div>
                        </li>
                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                               [routerLink]="['/services/maintenance/work']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-wrench mr-2"></i>
                                <span class="font-medium">Arbeit durchführen</span>
                            </a>
                        </li>

                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/services/maintenance/history']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-book mr-2"></i>
                                <span class="font-medium">Wartungsbuch</span>
                            </a>
                        </li>

                      <li>
                        <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/services/maintenance/worktime']" routerLinkActive="settings-item-selected">
                          <i class="pi pi-calendar-clock mr-2"></i>
                          <span class="font-medium">Arbeitszeiten</span>
                        </a>
                      </li>

                    </ul>

                    <ul class="list-none p-2 m-0 border-top-1 surface-border">
                        <li>
                            <div class="p-3 text-500 font-medium">VERWALTUNG</div>
                        </li>

                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/services/tasks']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-check-square mr-2"></i>
                                <span class="font-medium">Aufgaben</span>
                            </a>
                        </li>

                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/services/maintenance/plan']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-bookmark mr-2"></i>
                                <span class="font-medium">Wartungsplan</span>
                            </a>
                        </li>


                    </ul>

                    <ul class="list-none p-2 m-0 border-top-1 surface-border">
                        <li>
                            <div class="p-3 text-500 font-medium">DOKUMENTE</div>
                        </li>
                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/services/dms/editor']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-file-edit mr-2"></i>
                                <span class="font-medium">Editor</span>
                            </a>
                        </li>

                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/services/dms/postbox']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-inbox mr-2"></i>
                                <span class="font-medium">Postbox</span>
                            </a>
                        </li>
                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/services/dms/archive']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-server mr-2"></i>
                                <span class="font-medium">Archiv</span>
                            </a>
                        </li>
                        <li hidden>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/services/dms-archive']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-cloud-upload mr-2"></i>
                                <span class="font-medium">Ablage</span>
                            </a>
                        </li>
                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/services/dms/file-sharing']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-share-alt mr-2"></i>
                                <span class="font-medium">Freigaben</span>
                            </a>
                        </li>
                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150" [routerLink]="['/services/dms/recycle-bin']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-trash mr-2"></i>
                                <span class="font-medium">Papierkorb</span>
                            </a>
                        </li>

                    </ul>


                </div>


            </div>
        </div>
    </div>

    <div class="min-h-screen flex flex-column relative flex-auto pl-2" style="height: calc(~'100vh-150px') !important">


        <router-outlet></router-outlet>


    </div>

</div>
