
import { EntityBase } from "../../shared/entity.base";

export class FinancialYear extends EntityBase {
  name: string = '';
  year: number = 0;

  startDate: string = '';
  endDate: string = '';

  active: boolean = true;

  isApproved: boolean = false;

  startDate2: number = 0;
  endDate2: number = 0;
}
