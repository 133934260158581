<p-panel header="Meine Aufgaben" [toggleable]="false">
  


  <p-table [value]="items" selectionMode="single" [(selection)]="selectedItem" dataKey="id" responsiveLayout="scroll"
           (onRowSelect)="onSelected2()" styleClass="p-datatable-striped">
    <ng-template pTemplate="header">
      <tr>
        <th>Aufgabe</th>
        <th>Fällig am</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr [pSelectableRow]="item">
        <td class="table-users-id">{{ item.title  }}</td>
        <td class="table-users-id">{{ item.dueDate | customDate  }}</td>
      </tr>
    </ng-template>
  </p-table>
</p-panel>
