import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Globals} from "../globals";
import {MailServerModel} from "./mail-server.model";


@Injectable({providedIn: 'root'})
export class SendMessageService {
  constructor(private http: HttpClient, private globals: Globals) {
  }

  testConnection() {
    const url = this.globals.APP_URL + 'sendmessage/test-connection';
    return this.http.get(url);
  }

  getServerConfig() {
    const url = this.globals.APP_URL + 'sendmessage/server-config';
    return this.http.get(url);
  }

  putServerConfig(config: MailServerModel) {
    const url = this.globals.APP_URL + 'sendmessage/server-config';
    return this.http.put(url, config);
  }
}
