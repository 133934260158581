export class MaintenanceWorkEntry {

  id: number;

  planEntryId: number;

  completeDate: string;

  who: string;
  contactId: number;

  comment: string;

  textValue1: string;
  textValue2: string;
  textValue3: string;

  numberValue1: number;
  numberValue2: number;
  numberValue3: number;

  hours: number = 0.0;
  completeDate2: number;

  isLogbook: boolean = true;


  constructor() {
    this.id = 0;

    this.completeDate = "";

    this.who = "";
    this.contactId = 0;
    this.comment = "";

    this.textValue1 = '';
    this.textValue2 = '';
    this.textValue3 = '';

    this.numberValue1 = 0;
    this.numberValue2 = 0;
    this.numberValue3 = 0;

    this.completeDate2 = 0;

  }
}

export class MaintenanceWorkEntryViewModel {
  id: number;

  planEntryId: number;

  completeDate: string;

  who: string;
  contactId: number;

  comment: string;

  textValue1: string;
  textValue2: string;
  textValue3: string;

  numberValue1: number;
  numberValue2: number;
  numberValue3: number;

  equipmentId: number;

  // Plan entry fields
  shortText: string;
  description: string;

  textFieldName1: string;
  textFieldName2: string;
  textFieldName3: string;

  numberFieldName1: string;
  numberFieldName2: string;
  numberFieldName3: string;

  // Equipment fields
  equipmentShortText: string;
  equipmentType: string;


  constructor() {
    this.id = 0;

    this.completeDate = "";

    this.who = "";
    this.contactId = 0;
    this.comment = "";

    this.textValue1 = '';
    this.textValue2 = '';
    this.textValue3 = '';

    this.numberValue1 = 0;
    this.numberValue2 = 0;
    this.numberValue3 = 0;

    this.shortText = '';
    this.description = '';

    this.textFieldName1 = '';
    this.textFieldName2 = '';
    this.textFieldName3 = '';

    this.numberFieldName1 = '';
    this.numberFieldName2 = '';
    this.numberFieldName3 = '';

    this.equipmentShortText = '';
    this.equipmentType = '';
  }
}
