import { Component } from '@angular/core';
import {ButtonModule} from "primeng/button";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {RippleModule} from "primeng/ripple";

@Component({
  selector: 'app-dunnings',
  standalone: true,
    imports: [
        ButtonModule,
        FormsModule,
        InputTextModule,
        RippleModule
    ],
  templateUrl: './dunnings.component.html',
  styleUrl: './dunnings.component.css'
})
export class DunningsComponent {

}
