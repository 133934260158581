<div class="row">
  <div class="col-md-12">
      <div class="card">
          <div class="card-header d-md-flex justify-content-between align-items-center">
            <h6 class="card-title">Nach Dokumenten suchen, oder neues Dokument erstellen.</h6>
          </div>
      </div>
  </div>
</div>

