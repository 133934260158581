import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";


interface Message {
  channel: string;
  data: any;
}



/**  */
@Injectable({ providedIn: 'root' })
export class MessagingService {
  private channels: { [key: string]: Subject<Message> } = {};

  constructor(){

  }

  private getSubject(channel: string): Subject<Message> {
    if(!this.channels[channel]) {
      this.channels[channel] = new Subject<Message>();
    }
    return this.channels[channel];
  }

  public publish(message: Message): void {
    const channel = this.getSubject(message.channel);
    channel.next(message);
  }

  public subscribe(channel: string): Observable<Message> {
    return this.getSubject(channel).asObservable();
  }
}
