import { Component, ComponentRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDataService } from '../../shared/dataservice.interface';
import { ProductService } from './product.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
})
export class ProductsComponent implements OnInit {

  dataService: IDataService;

  constructor(private router: Router, public productsService: ProductService) { }

  ngOnInit(): void {
    this.productsService.detailActive = '';
    this.productsService.listActive = 'active';
  }

  onAddProduct() {
    this.router.navigate(['masterdata', 'products', 'new']);
    this.productsService.detailActive = 'active';
    this.productsService.listActive = '';
  }

  onListSelected() {
    this.router.navigate(['masterdata', 'products']);
    this.productsService.detailActive = '';
    this.productsService.listActive = 'active';
  }

  onActivate(componentRef: Component) {
    this.dataService = componentRef as IDataService;
  }

  onDetailSelected() {
    if (this.productsService.selectedProduct && this.productsService.selectedProduct.id) {
      this.router.navigate([
        'masterdata',
        'products',
        this.productsService.selectedProduct.id,
        'edit',
      ]);
      this.productsService.detailActive = 'active';
      this.productsService.listActive = '';
    } else {
      this.onAddProduct();
    }
  }
}
