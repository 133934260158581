<div class="row">
  <div class="col-md-12">
    <div class="card">
      <br>

      <form (ngSubmit)="onSubmit(f)" #f="ngForm">
        <div class="px-4">
          <div class="input-group mb-3">
            <input type="text" name="search" class="form-control input-lg" placeholder="Suchtext"
                   aria-label="Recipient's username" aria-describedby="basic-addon2" ngModel>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" [disabled]="!f.valid" type="submit">Suchen</button>
            </div>
          </div>
        </div>
      </form>
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th  *ngFor="let col of columnNames" scope="col">{{ col }} </th>
            </tr>
          </thead>
          <tbody class="table-users-body">
            <tr *ngFor="let item of items" style="cursor: pointer;" (click)="onSelected(item)">
              <td *ngFor="let e of dataNames" [ngClass]="e.class">{{ item[e.name] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p-paginator class="my-paginator" [rows]="10" [totalRecords]="totalItems" pageLinkSize="3"
                   (onPageChange)="paginate($event)"></p-paginator>
    </div>
  </div>
</div>
