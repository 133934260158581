import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDataService } from '../../shared/dataservice.interface';
import { GaugeService } from './gauge.service';


@Component({
  selector: 'app-gauges',
  templateUrl: './gauges.component.html',
  styleUrls: ['./gauges.component.css']
})
export class GaugesComponent implements OnInit {

  dataService: IDataService;

  constructor(private router: Router, public gaugeService: GaugeService) { }

  ngOnInit(): void {
    this.gaugeService.detailActive = '';
    this.gaugeService.listActive = 'active';
  }

  onAddProduct() {
    this.router.navigate(['masterdata', 'gauges', 'new']);
    this.gaugeService.detailActive = 'active';
    this.gaugeService.listActive = '';
  }

  onListSelected() {
    this.router.navigate(['masterdata', 'gauges']);
    this.gaugeService.detailActive = '';
    this.gaugeService.listActive = 'active';
  }

  onActivate(componentRef: Component) {
    this.dataService = componentRef as IDataService;
  }

  onDetailSelected() {
    if (this.gaugeService.selectedGauge) {
      this.router.navigate([
        'masterdata',
        'gauges',
        this.gaugeService.selectedGauge.id,
        'edit',
      ]);
      this.gaugeService.detailActive = 'active';
      this.gaugeService.listActive = '';
    }
  }
}
