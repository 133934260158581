<div class="mb-3 flex align-items-center justify-content-between">
  <span class="text-xl font-medium text-900">Artikel</span>
  <button pButton pRipple label="Hinzufügen" icon="pi pi-plus" (click)="New()"></button>
</div>

<form (ngSubmit)="onSubmit(f)" #f="ngForm">
  <div class="px-0">
    <div class="input-group mb-3">
      <input type="text" name="searchProduct" class="form-control input-lg" placeholder="Suchtext"
             aria-label="Recipient's username" aria-describedby="basic-addon2" ngModel/>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" [disabled]="!f.valid" type="submit">
          Suchen
        </button>
      </div>
    </div>
  </div>
</form>

<p-table [value]="products" selectionMode="single" [(selection)]="selectedProduct" dataKey="id"
         responsiveLayout="scroll"
         (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped">
  <ng-template pTemplate="header">
    <tr>
      <th>Artikelnummer</th>
      <th>Bezeichnung</th>
      <th>Produktgruppe</th>
      <th>Steuer</th>
      <th>VK</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-product>
    <tr [pSelectableRow]="product">
      <td>{{ product.productID }}</td>
      <td>{{ product.description }}</td>
      <td>{{ product.productGroup }}</td>
      <td>{{ product.tax / 100 }} %</td>
      <td>{{ (product.netSalesPrice / 100 * (1 + product.tax / 10000)) | currency: 'EUR' }}</td>
    </tr>
  </ng-template>
</p-table>
<p-paginator class="my-paginator" [rows]="10" [totalRecords]="totalProducts" pageLinkSize="3"
             (onPageChange)="paginate($event)"></p-paginator>
