import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Contact } from './contact.model';
import { Globals } from '../globals';

@Injectable({ providedIn: 'root' })
export class ContactService {
  constructor(private http: HttpClient, private globals: Globals) {}

  updateContact(c: Contact) {
    const url = this.globals.APP_URL + 'contacts';
    return this.http.put<Contact>(url, c);
  }

  readAllContacts(search: string, pageSize: number, page: number) {
    const url = this.globals.APP_URL + 'contacts';
    let params = new HttpParams();
    params = params.append('search', search);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('page', page.toString());
    return this.http.get<Contact[]>(url, {
      params: params,
      observe: 'response',
    });
  }

  readContacts(role: string, search: string, pageSize: number, page: number) {
    const url = this.globals.APP_URL + 'contacts/roles/' + role;
    let params = new HttpParams();
    params = params.append('search', search);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('page', page.toString());
    return this.http.get<Contact[]>(url, {
      params: params,
      observe: 'response',
    });
  }

  readContact(id: number) {
    const url = this.globals.APP_URL + 'contacts/' + id;
    return this.http.get<Contact>(url);
  }

  deleteContact(id: number) {
    const url = this.globals.APP_URL + 'contacts/' + id;
    return this.http.delete<{ 'value': '' }>(url);
  }

  addContact(c: Contact) {
    const url = this.globals.APP_URL + 'contacts';
    return this.http.post<Contact>(url, c);
  }

  importContacts(c: Contact[]) {
    const url = this.globals.APP_URL + 'contacts/import';
    return this.http.post<Contact[]>(url, c);
  }
}
