<p-toast position="bottom-right" key="tl"></p-toast>

<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header d-md-flex justify-content-between align-items-center">
        <h5 class="card-title">{{ title }}</h5>
        <h6 class="add-user-button d-flex align-items-center" (click)=onSaveTags()>
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M17 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V7L17 3M19 19H5V5H16.17L19 7.83V19M12 12C10.34 12 9 13.34 9 15S10.34 18 12 18 15 16.66 15 15 13.66 12 12 12M6 6H15V10H6V6Z" />
          </svg>
          <span>Speichern</span>
        </h6>
      </div>

      <div class="card-body card-sidebar-menu">
        <div class="row" *ngFor="let tag of tags; let i = index">

          <div class="col-md-4">
            <div class="form-group">
              <input type="text" name="memberid" placeholder="{{newTag}}" class="form-control"
                     (change)="onTagChanged(i)" [readonly]="tag.setting.readOnly" [(ngModel)]="tag.setting.key"
                     required />
            </div>
          </div>
          <div class="col-md-1" *ngIf="!tag.setting.readOnly">
              <div class="btn" (click)="onDeleteTag(i)">
                  <h6 class="add-user-button d-flex justify-content-center">
                      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />
                      </svg>
                      <span>entfernen</span>
                  </h6>
              </div>
          </div>

        </div>
        <div class="row">
          <h6 class="add-user-button d-flex align-items-center mx-3" (click)="onAddTag()">
            <svg width="25" height="24" viewBox="0 0 25 24" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M11,13.5V21.5H3V13.5H11M9,15.5H5V19.5H9V15.5M12,2L17.5,11H6.5L12,2M12,5.86L10.08,9H13.92L12,5.86M17.5,13C20,13 22,15 22,17.5C22,20 20,22 17.5,22C15,22 13,20 13,17.5C13,15 15,13 17.5,13M17.5,15A2.5,2.5 0 0,0 15,17.5A2.5,2.5 0 0,0 17.5,20A2.5,2.5 0 0,0 20,17.5A2.5,2.5 0 0,0 17.5,15Z" />
            </svg>
            <span>{{ titleNew }}</span>
          </h6>
        </div>
      </div>
    </div>
  </div>
</div>
