import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Globals} from "../globals";
import {AppStatisticData} from "./app-statistic-data.model";
import {WebReplyMessage} from "./web-reply-message.model";


@Injectable({providedIn: 'root'})
export class GeneralFunctionService {

  constructor(private http: HttpClient, private globals: Globals) {
  }

  getAppStatistics() {
    const url = this.globals.APP_URL + 'generalFunction/statistics';
    return this.http.get<AppStatisticData>(url);
  }

  createBackup() {
    const url = this.globals.APP_URL + 'generalFunction/backup';
    return this.http.get<WebReplyMessage>(url);
  }
}
