export class Equipment {

  id: number;

  active: boolean;

  shortText: string;
  description: string;
  equipmentType: string;
  serial: string;

  yearPurchased: number;
  purchasePrice: number;
  equipmentLife: number;
  replacementPrice: number;

  documentId: number;

  gpsCoordId: number;
  parentEquipmentId: number;
  isGroup: boolean;

  constructor() {
    this.id = 0;
    this.active = true;

    this.shortText = "";
    this.description = "";
    this.equipmentType = "";
    this.serial = "";

    this.yearPurchased = new Date().getFullYear();
    this.purchasePrice = 0;
    this.equipmentLife = 1;
    this.replacementPrice = 0;

    this.documentId = 0;

    this.gpsCoordId = 0;
    this.parentEquipmentId = 0;
    this.isGroup = false;
  }
}
