
<div class="surface-card  pt-4 pl-2 pr-2  shadow-2 border-round mb-2">
  <div class="surface-section p-4">
    <div class="grid">
      <div class="col-12 lg:col-2">
        <div class="mr-l">
          <div class="text-xl font-medium text-900 mb-3">Mahnungen</div>
          <p class="m-0 p-0 text-600 line-height-3 mr-3">
            Hier wird ein Mahnlauf durchgeführt. Es können für alle überfälligen Rechnungen Mahnungen erstellt werden.
          </p>

        </div>
      </div>

      <div class="col-12 lg:col-10">
        <div class="flex justify-content-between align-items-center mb-3">


          <div class="ml-4">
            <button pButton pRipple label="Mahnlauf durchführen" icon="pi pi-sync" ></button>
          </div>


          <div class="ml-auto">
            <button pButton pRipple label="Ausgewählte Mahnungen erzeugen" icon="pi pi-check-square" ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

