import {DatePipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog';


import {Globals} from '../../../../globals';
import {EquipmentService} from '../../../../masterdata/equipment/equipment.service';
import {ContactSelectComponent} from '../../../../shared/contact-select/contact-select.component';
import {Contact} from '../../../../shared/contact.model';
import {MaintenancePlanService} from '../../maintenance-plan.service';
import {MaintenanceWorkService} from '../../maintenance-work.service';
import {MaintenancePlanEntry} from '../../maintenance.planentry.model';
import {MaintenanceWorkEntry, MaintenanceWorkEntryViewModel} from '../../maintenance.workentry.model';

@Component({
  selector: 'app-maintenance-work-edit',
  templateUrl: './maintenance-work-edit.component.html',
  styleUrls: ['./maintenance-work-edit.component.css'],
  providers: [DialogService],
})
export class MaintenanceWorkEditComponent implements OnInit {

  workSelected: boolean = false;
  planEntry: MaintenancePlanEntry = new MaintenancePlanEntry();
  workEntry: MaintenanceWorkEntry = new MaintenanceWorkEntry();

  workEntryViewModel: MaintenanceWorkEntryViewModel;

  equipmentName: string;

  id: number;
  planId: number;
  editMode: boolean;

  workingDate: Date;

  ref: DynamicDialogRef;

  constructor(public globals: Globals,
              public dialogService: DialogService,
              private equipmentService: EquipmentService,
              private entityService: MaintenanceWorkService,
              private planService: MaintenancePlanService,
              private route: ActivatedRoute,
              private router: Router,
              public datepipe: DatePipe
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      if (params['id'] != null) {
        this.id = +params['id'];
        this.editMode = params['id'] != 'new';
        if (!this.editMode) {
          this.route.queryParams.subscribe((param: Params) => {
            this.planId = +param['plan'];
            this.readPlan();
          });
        } else {
          this.readWork();
        }
      }
    });

  }

  readPlan() {
    if (this.planId && this.planId > 0) {
      this.planService.readItem(this.planId).subscribe(e => {
        this.planEntry = e;
        this.equipmentService.readItem(this.planEntry.equipmentId).subscribe(eq => {
          this.equipmentName = eq.shortText;
        });
        this.initForm();
      })
    }
  }

  readWork() {
    this.entityService.readItem(this.id).subscribe(resp => {
      this.workEntry = resp;
      this.planId = this.workEntry.planEntryId;
      this.workingDate = new Date(this.workEntry.completeDate2 * 1000);
      this.readPlan();
    });
  }

  initForm() {
    if (!this.editMode) {
      this.workSelected = false;
      this.workingDate = new Date();

      this.workEntry = new MaintenanceWorkEntry();
      this.workEntry.isLogbook = this.planEntry.addToLogBook;
    }

  }


  onSelectContact() {
    this.ref = this.dialogService.open(ContactSelectComponent, {
      data: {
        role: 'members,customer,supplier'
      },
      header: 'Kontakt auswählen',
      width: '75%',
      contentStyle: {'max-height': '650px', overflow: 'auto'},
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((c: Contact) => {
      if (c) {
        this.workEntry.contactId = c.id;
        this.workEntry.who = c.orgName != null && c.orgName != '' ? c.orgName : c.firstName + ' ' + c.lastName;
      }
    });
  }

  onSave() {
    if (!this.editMode) {
      if (this.planEntry) {
        this.workEntry.planEntryId = this.planEntry.id;
      }
      this.workEntry.completeDate = this.datepipe.transform(this.workingDate, 'MM.dd.yyyy');
      this.workEntry.completeDate2 = Math.round(this.workingDate.getTime() / 1000);
      console.log(this.workEntry);
      this.entityService.addItem(this.workEntry);
    } else {
      this.workEntry.completeDate = this.datepipe.transform(this.workingDate, 'MM.dd.yyyy');
      this.workEntry.completeDate2 = Math.round(this.workingDate.getTime() / 1000);
      this.entityService.updateItem(this.workEntry);
    }
  }

  onAddAttachment() {

  }

  onAddLink() {

  }

  Back() {
    if (this.editMode)
      this.router.navigate(['services', 'maintenance', 'history']);
    else
      this.router.navigate(['services', 'maintenance', 'work']);
  }
}
