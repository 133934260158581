import { EntityBase } from "./entity.base";

export class PaymentTerms extends EntityBase {
  name: string = '';
  printText: string = '';
  cashDiscount: number = 0;
  cashDiscountDays: number = 0;
  dueDays: number = 0;
  paymentMethod: string = '';
}
