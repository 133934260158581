<div class="surface-card  pt-1 pl-1 shadow-2 border-round mb-2">
  <div class="surface-section p-4">
    <div class="grid">
      <div class="col-12 lg:col-2">
        <div class="text-xl font-medium text-900 mb-3">Auswertungen</div>
        <p class="m-0 p-0 text-600 line-height-3 mr-3">
          Hier können Auswertungen für Wasseranschlüsse erzeugt und angezeigt werden.
        </p>

      </div>

      <div class="col-12 lg:col-10">
        <div class="grid formgrid p-fluid">
        </div>
      </div>
    </div>
  </div>
</div>
