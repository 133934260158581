import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Globals } from '../../globals';

import { Contact } from '../../shared/contact.model';
import { ContactService } from '../../shared/contact.service';

@Injectable()
export class CustomerService {
  customers = new Subject<Contact[]>();
  customerArray = new Array<Contact>();

  totalCustomers: number = 0;

  selectedCustomer = new Contact();

  detailActive = '';
  listActive = 'active';

  isCopy = false;

  constructor(private contacts: ContactService, private router: Router, private globals: Globals, private http: HttpClient) {
    this.selectedCustomer.role = "Customer";
  }

  getContacts(search: string, pageSize: number, page: number) {
    this.contacts
      .readAllContacts(search, pageSize, page)
      .subscribe((respData) => {
        this.totalCustomers = parseInt(respData.headers.get('x-total-count'));
        console.log(this.totalCustomers);
        this.customerArray = respData.body.slice();
        this.customers.next(this.customerArray);
      });
  }

  getCustomers(search: string, pageSize: number, page: number) {
    this.contacts
      .readContacts('customer', search, pageSize, page)
      .subscribe((respData) => {
        this.totalCustomers = parseInt(respData.headers.get('x-total-count'));
        console.log(this.totalCustomers);
        this.customerArray = respData.body.slice();
        this.customers.next(this.customerArray);
      });
  }

  addCustomer(c: Contact) {
    this.contacts.addContact(c).subscribe((respData) => {
      console.log(respData);
      this.selectedCustomer = respData;
      this.customerArray.push(respData);
      this.router.navigate(['masterdata','customers', this.selectedCustomer.id]);
    });
  }

  updateCustomer(c: Contact) {
    this.contacts.updateContact(c).subscribe((respData) => {
      this.selectedCustomer = respData;
      const idx = this.customerArray.findIndex((x) => x.id === c.id);
      this.customerArray[idx] = respData;
      this.router.navigate(['masterdata','customers', this.selectedCustomer.id]);
    });
  }

  getCustomer(id: number): Contact {
    this.selectedCustomer = Object.assign(
      new Contact(), 
      this.customerArray.find((m) => m.id === id)
      );
    return this.selectedCustomer;
  }

  deleteCustomer(id: number) {
    return this.contacts.deleteContact(id);
  }

  removeContactFromList(c: Contact) {
    const index = this.customerArray.findIndex(mem => mem.id == c.id);
    if (index > -1) {
      this.customerArray.splice(index, 1);
      this.customers.next(this.customerArray);
    }
  }

  getNextNumber() {
    const url = this.globals.APP_URL + 'numberrange/customer/next-number';
    return this.http.get<{ id: string }>(url);
  }

}

