<p-dialog header="{{dialogTitle}}" [(visible)]="display" [modal]="true">
    
        <div class="flex">
            <input pInputText class="flex-auto mr-3" [(ngModel)]="newFolderName" (keydown.enter)="updateFolder()" [autofocus]=true onfocus="this.select()">
            <button pButton pRipple  label="{{buttonTitle}}" (click)="updateFolder()"></button>
        </div>
    
</p-dialog>

<div class="grid p-fluid" style="height: calc(~' 100vh - 150px');">
    <div class="col-12 md:col-5">
        <p-toolbar>
            <div class="p-toolbar-group-start">
                <p-button icon="pi pi-plus" styleClass="p-button-success" (click)="addFolder()"></p-button>
            </div>
            <div class="p-toolbar-group-middle">
                <span>Archiv</span>
            </div>

            <div class="p-toolbar-group-end">
                <p-button icon="pi pi-pencil" (click)="renameFolder()"></p-button>
                <p-button icon="pi pi-times" styleClass="p-button-danger"></p-button>
            </div>
        </p-toolbar>




        <p-tree [value]="files" emptyMessage="Keine Ordner vorhanden" selectionMode="single" [(selection)]="selectedFile" scrollHeight="63vh"
                (onNodeSelect)="nodeSelect($event)">

        </p-tree>

    </div>
    <div class="col-12 md:col-7">
        <p-toolbar>


            <div class="p-toolbar-group-start">
                <p-button icon="pi pi-arrow-left" (click)="onBack()"></p-button>
            </div>

            <div>
                <div>{{selectedFolder.name}}</div>
            </div>
            <div class="p-toolbar-group-end">
                <p-button icon="pi pi-share-alt"></p-button>
                <p-button icon="pi pi-eye"></p-button>
                <p-button icon="pi pi-download"></p-button>
            </div>

        </p-toolbar>
        <p-table [value]=fileList selectionMode="single"  responsiveLayout="scroll"
                 styleClass="p-datatable-striped">
            <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th>Datum</th>
                    <th>Typ</th>
                    <th>Größe</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-file>
                <tr [pSelectableRow]="file" (dblclick)="onRowDblClick($event, file)">
                    <td>
                        <i *ngIf="file.isFolder" class="pi pi-folder mr-1"></i>
                        <i *ngIf="!file.isFolder" class="pi pi-file mr-1"></i>
                        {{ file.name  }}
                    </td>

                    <td><div *ngIf="!file.isFolder">{{ file.created | customDate }}</div></td>
                    <td>{{ file.docType }}</td>
                    <td><div *ngIf="!file.isFolder">{{ file.fileSize | filesize }}</div></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

