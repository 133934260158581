import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Subscription} from 'rxjs';
import {Contact} from '../contact.model';
import {ContactService} from '../contact.service';

@Component({
  selector: 'app-contact-select',
  templateUrl: './contact-select.component.html',
  styleUrls: ['./contact-select.component.css'],
  providers: [DialogService],
})
export class ContactSelectComponent implements OnInit {

  contacts: Contact[];
  isLoading = false;
  totalContacts: number = 1;
  searchString: string = '';

  roles: string = 'member';

  constructor(private contactService: ContactService, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
  }

  ngOnInit(): void {
    this.roles = this.config.data.role;
    this.onSubmit(null);

    console.log("Select contact");
  }

  paginate(event) {
    this.contactService.readContacts(this.roles, this.searchString, 15, event.page + 1).subscribe((resp) => {
      this.contacts = resp.body;
    })
  }

  onSubmit(form: NgForm) {
    this.contacts = null;
    this.contactService.readContacts(this.roles, this.searchString, 15, 1).subscribe((resp) => {
      this.contacts = resp.body;
      this.totalContacts = parseInt(resp.headers.get('x-total-count'));
    })
  }

  onSelected(c: Contact) {
    this.ref.close(c);
  }

}
