import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Globals } from '../../../globals';
import { SupportTicket } from '../ticket.model';
import { TicketService } from '../ticket.service';

@Component({
  selector: 'app-ticket-edit',
  templateUrl: './ticket-edit.component.html',
  styleUrls: ['./ticket-edit.component.css'],
})
export class TicketEditComponent implements OnInit {

  editMode: boolean;
  id: string;
  entity: SupportTicket = new SupportTicket();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private globals: Globals,
    public entityService: TicketService,) {

  }

  ngOnInit(): void {
    this.entity = new SupportTicket();
    this.route.params.subscribe((params: Params) => {
      this.id = params['id'];
      this.editMode = params['id'] != 'new';
      this.initForm();
    });
  }

  initForm() {
    if (this.editMode) {
      this.entityService.readItem(this.id).subscribe(resp => {
        this.entity = Object.assign(new SupportTicket(), resp);
        this.entity.created_date = new Date(this.entity.created_date);
      });
    }
    else {
      this.entity = new SupportTicket();
      this.entity.created_date = new Date();
      this.entity.customer_email = this.globals.currentUser.email;
      this.entity.customer_name = this.globals.currentApp.displayName;
      this.entity.ticket_status = this.entityService.TicketStatus[0];
    }
  }

  Back() {
    this.router.navigate(['help', 'tickets']);
  }

  Delete() {
    this.confirmationService.confirm({
      message: 'Möchten Sie Ticket wirklich löschen?',
      header: 'Ticket löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      rejectVisible: true,
      key: 'confirm',
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        this.entityService.deleteItem(this.entity._id, this.entity._rev).subscribe(() => {
          this.router.navigate(['help', 'tickets']);
        });
      },
    });
  }

  Save() {
    if (this.editMode) {
      this.entityService.putItem(this.entity).subscribe(resp => {
        this.entity = resp;
        this.entity.created_date = new Date(this.entity.created_date);
      });
    }
    else {
      this.entityService.postItem(this.entity).subscribe(resp => {
        this.entity = resp;
        this.entity.created_date = new Date(this.entity.created_date);
        this.router.navigate(['help', 'tickets', this.entity._id]);
      });
    }
  }
}
