import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-water-access-point',
  templateUrl: './water-access-point.component.html',
  styleUrls: ['./water-access-point.component.css']
})
export class WaterAccessPointComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }

}
