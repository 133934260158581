<p-confirmDialog header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-dialog header="Dokument Archivieren" [(visible)]="displayArchive" [modal]="true">

  <div class="customDialog">
    <p-tree [value]="files" emptyMessage="Keine Ordner vorhanden" selectionMode="single" [(selection)]="selectedFile"
            scrollHeight="63vh"></p-tree>
    <button class="dialogButton" pButton pRipple icon="pi pi-save" (click)="OnArchiveSelected()"
            label="Jetzt Archivieren"></button>
  </div>

</p-dialog>


<p-dialog [(visible)]="displayShare" [modal]="true" appendTo="body"
          [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
          [style]="{width: '50vw'}"
>

  <ng-template pTemplate="header">
    <div class="flex align-items-center">
            <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle"
                  style="width:32px;height:32px">
                <i class="pi pi-info text-lg"></i>
            </span>
      <span class="font-medium text-2xl text-900">Datei '{{ doc.name }}' freigeben</span>
    </div>
  </ng-template>

  <div class="grid formgrid p-fluid">
    <div class="field mb-3 col-12 md:col-6">
      <label for="name" class="font-medium text-900">Beschreibung</label>
      <input id="name" type="text" pInputText [(ngModel)]="share.description" autocomplete="off">
    </div>
    <div class="field mb-3 col-12 md:col-6">
      <label for="end" class="font-medium text-900">Gültig bis</label>
      <p-calendar id="end" appendTo="body" dateFormat="dd.mm.yy" [(ngModel)]="expirationDate"></p-calendar>
    </div>

    <div class="field mb-3 col-12 md:col-6">
      <label for="accessCode" class="font-medium text-900">Zugriffscode</label>
      <input id="accessCode" type="text" pInputText [(ngModel)]="share.accessKey" autocomplete="off">
    </div>

    <div class="field mb-3 col-12 md:col-6">
      <label for="oneTime" class="font-medium text-900">Einmaliger Zugriff </label>
      <div class="flex align-items-center">
        <p-checkbox id="oneTime" inputId="oneTime" [binary]="true" [(ngModel)]="share.isOneTimeAccess"></p-checkbox>
        <span class="ml-2 text-900">Aktivieren</span>
      </div>
    </div>


  </div>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-between align-items-center">
      <button pButton pRipple label="Abbrechen" icon="pi pi-times" (click)="displayShare = false"></button>
      <button pButton pRipple label="Jetzt freigeben" icon="pi pi-save" (click)="onShareFile()"></button>
    </div>
  </ng-template>

</p-dialog>

<div class="mb-3 flex align-items-center justify-content-between">
  <div>
    <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
    <span class="text-xl font-medium text-900 mx-3">Dokument</span>
  </div>
  <div>
    <button pButton pRipple label="Archivieren" icon="pi pi-server" (click)="Archive()"></button>
    <button pButton pRipple label="Freigeben" icon="pi pi-share-alt" (click)="Share()"></button>
  </div>
  <div>

    <button pButton pRipple label="Löschen" icon="pi pi-trash" (click)="Delete()" class="mx-3"></button>
    <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="Save()"></button>
  </div>
</div>


<div class="row">
  <div class="col-md-8">
    <div class="form-group">
      <label>Name</label>
      <input type="text" name="fileName" placeholder="Name" [(ngModel)]="doc.name" class="form-control"/>
    </div>
  </div>

  <div class="col-md-4">
    <div class="form-group">
      <label>Dokumententyp</label>
      <p-dropdown [options]="categories" name="selectedCategory" optionLabel="key" optionValue="key"
                  [(ngModel)]="doc.docType"></p-dropdown>
    </div>
  </div>


</div>

<div class="row">
  <div class="col-md-4">
    <div class="form-group">
      <label>Nummer</label>
      <input type="text" name="docid" placeholder="z.B. Rechnungsnummer" [(ngModel)]="doc.documentID"
             class="form-control"/>
    </div>
  </div>

  <div class="col-md-4">
    <div class="form-group">
      <label>Datum</label><br>
      <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="true" [locale]="globals.de"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="docDate">
      </p-calendar>
    </div>
  </div>

  <div class="col-md-4">
    <div class="form-group">
      <label>Kennzeichen/Tags</label><br>
      <p-multiSelect [options]="tags" optionLabel="key" name="selectedTags" [(ngModel)]="selectedTags"
                     placeholder="Keine Auswahl"></p-multiSelect>
    </div>
  </div>


</div>


<div class="row" *ngIf="editMode">
  <div class="col-md-3 align-self-end">
    <div class="form-group">
      <a class="btn btn--primary mr-2" (click)="onPreview()">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z"/>
        </svg>
        <span>Vorschau</span>
      </a>
    </div>
  </div>

  <div class="col-md-3 align-self-end">
    <div class="form-group">
      <a class="btn btn--primary mr-2" (click)="onDownload()">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z"/>
        </svg>
        <span>Herunterladen</span>
      </a>
    </div>
  </div>
</div>

<div class="row" *ngIf="!editMode">

  <div class="card-body card-sidebar-menu">
    <h6>Dokument auswählen oder auf Schaltfläche ziehen.</h6>
    <p-fileUpload #uploader name="demo[]" (onUpload)="onUpload($event)"
                  accept="image/*,application/pdf,text/plain" maxFileSize="10000000" chooseLabel="Auswählen"
                  uploadLabel="Hochladen" cancelLabel="Abbrechen"
                  invalidFileTypeMessageSummary="{0}: Ungültiger Dateityp, "
                  invalidFileSizeMessageSummary="{0}: Datei zu groß, "
                  invalidFileSizeMessageDetail="maximale Dateigröße ist {0}." customUpload="True"
                  (uploadHandler)="onUpload($event)" (onSelect)="onSelect($event)">
      <ng-template pTemplate="content">
        <ul *ngIf="uploadedFiles.length">
          <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
        </ul>
      </ng-template>
    </p-fileUpload>
  </div>
</div>

<br>

<div class="card" *ngIf="pdfSrc != null || imgSrc != null">
  <div class="card-header d-md-flex justify-content-between align-items-center">
    <h6>Vorschau</h6>

  </div>
  <hr *ngIf="isPdf"/>


  <div *ngIf="isPdf">
    <ngx-extended-pdf-viewer [src]="pdfSrc"
                             [showHandToolButton]="true"
                             useBrowserLocale="true" height="80vh">
    </ngx-extended-pdf-viewer>
  </div>


  <div *ngIf="isImage">
    <p-image [src]="imgSrc" alt="Image" max-width="100vw" [preview]="true"></p-image>

  </div>
</div>



