import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-wap-start',
  templateUrl: './wap-start.component.html',
  styleUrls: ['./wap-start.component.css']
})
export class WapStartComponent implements OnInit {

  title: string = "Wasseranschluss";
  constructor(private router: Router) { }

  ngOnInit(): void {
    if (this.router.url.includes("childs")) {
      this.title = "Subzähler";
    }
  }

}
