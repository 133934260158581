import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-debit',
  templateUrl: './debit.component.html',
  styleUrls: ['./debit.component.css']
})
export class DebitComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
