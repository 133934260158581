<p-toast position="bottom-right" key="tl"></p-toast>
<div class="min-h-screen flex flex-column relative flex-auto pl-2">
  <div class="surface-card p-4 shadow-2 border-round">
    <div class="mb-3 flex align-items-center justify-content-between">
      <div>
        <span class="text-xl font-medium text-900">Zählerliste importieren</span>
      </div>
    </div>


    <div class="row">
      <input #FileSelectInputDialog type="file" hidden ([ngModel])="fileName" (change)="onFileSelected($event)"
             accept=".txt,text/csv"/>
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <p>Die Zählerliste muss folgendes Format haben, damit sie automatisch importiert werden kann (das
                  Ablesedatum ist optional).</p>
              </div>
              <div class="col-md-4">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th scope="col">Zählernummer</th>
                    <th>;</th>
                    <th scope="col">Zählerstand</th>
                    <th>;</th>
                    <th scope="col">[Ablesedatum]</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>S0815</td>
                    <td>;</td>
                    <td>125.4</td>
                    <td>;</td>
                    <td>10.10.2021</td>
                  </tr>
                  <tr>
                    <td>S4911</td>
                    <td>;</td>
                    <td>85.0</td>
                    <td></td>
                    <td></td>
                  </tr>
                  </tbody>
                </table>

              </div>
              <div class="col-md-4">
                <br/>
                <div class="row">
                  <div class="col-md-12 col-btn">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" name="isComment" [(ngModel)]="isComment" id="2">
                      <label class="form-check-label" for="2">Erste Zeile enthält Beschreibung</label>
                    </div>

                  </div>
                </div>

                <br/>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Ablesedatum (überschreiben)</label><br>
                      <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false"
                                  [locale]="globals.de"
                                  [ngModelOptions]="{standalone: true}" [(ngModel)]="readingDate">
                      </p-calendar>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/>


    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>1. Datei auswählen oder Daten direkt in das Feld kopieren</label>
                  <br>
                  <div class="row">
                    <div class="col-md-4">
                      <a class="btn btn--primary mr-2" (click)="onSelectFile()">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M6.1,10L4,18V8H21A2,2 0 0,0 19,6H12L10,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H19C19.9,20 20.7,19.4 20.9,18.5L23.2,10H6.1M19,18H6L7.6,12H20.6L19,18Z"/>
                        </svg>
                        <span>Auswählen ...</span>
                      </a>
                    </div>
                    <div class="col-md-8">
                      <div class="form-group">

                        <div class="row my-1">
                          <div class="col-md-2">
                            <input type="text" name="delimiter" placeholder="" [(ngModel)]="delimiter"
                                   class="form-control" *ngIf="!useTab"/>
                          </div>
                          <div class="col-md-10">
                            <div class="form-check my-1">
                              <input type="checkbox" class="form-check-input" name="useTabulator" [(ngModel)]="useTab"
                                     id="2">
                              <label class="form-check-label" for="2">Tabulator</label>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br>
                <div class="form-group">

                                    <textarea name="notes"
                                              placeholder="Zählernummer ; Zählerstand ; Ablesedatum"
                                              class="form-control mb-3"
                                              [(ngModel)]="text"
                                              style="height: 45vh;">
                                  </textarea>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>2. Eingelesene Daten prüfen</label><br>
                  <a class="btn btn--primary mr-2" (click)="onCheckInput()">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M4 3H18C19.11 3 20 3.9 20 5V12.08C18.45 11.82 16.92 12.18 15.68 13H12V17H13.08C12.97 17.68 12.97 18.35 13.08 19H4C2.9 19 2 18.11 2 17V5C2 3.9 2.9 3 4 3M4 7V11H10V7H4M12 7V11H18V7H12M4 13V17H10V13H4M17.75 21L15 18L16.16 16.84L17.75 18.43L21.34 14.84L22.5 16.25L17.75 21"/>
                    </svg>
                    <span>Überprüfen ...</span>
                  </a>
                </div>
              </div>
            </div>
            <br/>
            <div class="row">
              <p-table [value]="items" dataKey="serial" responsiveLayout="scroll" [scrollable]="true"
                       scrollHeight="calc(100vh - 530px)" styleClass="p-datatable-striped">
                <ng-template pTemplate="header">
                  <tr>
                    <th>Zählernummer</th>
                    <th>Zählerstand</th>
                    <th>Ablesedatum</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                  <tr style="height:40px">
                    <td pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input pInputText type="text" name="serial" class="form-control p-0" [(ngModel)]="item.serial"
                                 style="width:90px"/>
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{ item.serial }}
                        </ng-template>
                      </p-cellEditor>
                    </td>

                    <td pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <input pInputText type="number" name="count" class="form-control p-0" [(ngModel)]="item.count"
                                 style="width:80px"/>
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{ item.count }}
                        </ng-template>
                      </p-cellEditor>
                    </td>

                    <td pEditableColumn>
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false"
                                      [locale]="globals.de"
                                      [ngModelOptions]="{standalone: true}" [(ngModel)]="item.date" appendTo="body">
                          </p-calendar>
                        </ng-template>
                        <ng-template pTemplate="output">
                          {{ item.date  | date: 'dd.MM.yyyy' }}
                        </ng-template>
                      </p-cellEditor>
                    </td>

                    <td>
                      {{ item.state }}
                    </td>
                    <td>
                      <button pButton pRipple icon="pi pi-trash" class="p-button-warning"
                              (click)="deleteItem(item)"></button>
                    </td>

                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>3. Zählerstände importieren</label><br>
                  <a class="btn btn--primary mr-2" (click)="onImport()">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M8.84 12L3.92 16.92L2.5 15.5L5 13H0V11H5L2.5 8.5L3.92 7.08L8.84 12M12 3C8.59 3 5.68 4.07 4.53 5.57L5 6L6.03 7.07C6 7.05 6 7 6 7C6 6.5 8.13 5 12 5S18 6.5 18 7 15.87 9 12 9C9.38 9 7.58 8.31 6.68 7.72L9.8 10.84C10.5 10.94 11.24 11 12 11C14.39 11 16.53 10.47 18 9.64V12.45C16.7 13.4 14.42 14 12 14C11.04 14 10.1 13.9 9.24 13.73L7.59 15.37C8.91 15.77 10.41 16 12 16C14.28 16 16.39 15.55 18 14.77V17C18 17.5 15.87 19 12 19S6 17.5 6 17V16.96L5 18L4.54 18.43C5.69 19.93 8.6 21 12 21C16.41 21 20 19.21 20 17V7C20 4.79 16.42 3 12 3Z"/>
                    </svg>
                    <span>Importieren ...</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-btn">
                <div class="form-check">
                  <input type="checkbox" class="form-check-input" name="createMissing" [(ngModel)]="createMissing"
                         id="3">
                  <label class="form-check-label" for="2">Unbekannte Zähler anlegen</label>
                </div>

              </div>
            </div>

            <div class="row">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
