<div class="surface-card p-4 shadow-2 border-round">
  <div class="surface-section px-2">
    <div class="grid">
      <div class="col-12 lg:col-2">
        <div class="mr-l">
          <div class="text-xl font-medium text-900 mb-3">Arbeitszeiten</div>
          <p class="m-0 p-0 text-600 line-height-3 mr-3">
            Hier können die Arbeitszeiten ausgewertet werden.
          </p>

        </div>
      </div>

      <div class="col-12 lg:col-10">
      </div>
    </div>
  </div>
</div>
