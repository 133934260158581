import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {Globals, KVP_GROUPS} from 'src/app/globals';
import {MyDocument} from 'src/app/shared/document.model';
import {KvpService} from 'src/app/shared/kvp.service';
import {KvpSetting} from 'src/app/shared/kvpsetting.model';
import {DmsService} from '../dms.service'
import {DocumentViewModel} from '../document.viewmodel';

@Component({
  selector: 'app-dms-list',
  templateUrl: './dms-list.component.html',
  styleUrls: ['./dms-list.component.css']
})
export class DmsListComponent implements OnInit {

  isLoading: boolean = true;
  categories: any[];
  selectedCategory: string;
  searchString: string = "";
  searchTags: string = "";
  documents: MyDocument[];

  docs: DocumentViewModel[] = [];

  selectedDocument: DocumentViewModel;
  totalDocuments: number;

  constructor(private kvpService: KvpService, private globals: Globals, private router: Router, private dms: DmsService) {
  }

  ngOnInit(): void {
    this.kvpService.readGroup(KVP_GROUPS.DOC_TYPES).subscribe(resp => {
      this.categories = resp.map(({group, ...rest}) => rest);
      ;
    });

    this.dms.documents.subscribe(d => {
      this.documents = d;
      this.isLoading = false;
      this.totalDocuments = this.dms.totalDocuments;
    });


    if (this.dms.documentArray != null && this.dms.documentArray.length > 0) {
      this.documents = this.dms.documentArray;
      this.totalDocuments = this.dms.totalDocuments;
    } else {
      this.dms.getDocuments(this.searchString, 15, 1);
    }

    this.dms.search(this.searchString, -1).subscribe(d => {
      this.docs = d.slice();
      this.isLoading = false;
    })
  }

  onSubmit(form: NgForm) {
    this.docs = [];
    this.isLoading = true;
    this.dms.search(this.searchString, -1).subscribe(d => {
      this.docs = d.slice();
      this.isLoading = false;
    })
  }

  onAddDoc() {
    this.router.navigate(['services', 'dms', 'postbox', 'new']);
  }


  onSelected2(event) {
    //this.dms.selectedDocument = this.selectedDocument;
    this.router.navigate(['services', 'dms', 'postbox', this.selectedDocument.id], {queryParams: {size: this.selectedDocument.fileSize}});
  }

  paginate(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    console.log(event.page);
    this.dms.getDocuments(this.searchString, 15, event.page + 1);
  }

  New() {
    this.onAddDoc();
  }
}
