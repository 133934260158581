
<div class="surface-section px-4 py-6 md:px-6">
    <div class="text-2xl text-900 font-bold mb-2 text-center">Häufig gestellt Fragen - FAQ</div>
    <div class="text-700 line-height-3 mb-6 text-center">Hier finden Sie häufig gestellt Fragen, die den Einstieg in Headwater erleichtern.</div>
    <p-accordion styleClass="mb-6">
        <p-accordionTab header="Kann ich Headwater vorab testen?">
            <div class="line-height-3">
                JA. Sie können Headwater gerne 30 Tage kostenlos und unverbindlich testen.
            </div>
        </p-accordionTab>
        <p-accordionTab header="Welche Zahlungsmöglichkeiten habe ich?">
            <div class="line-height-3">
                Nach Vertragsabschluss erhalten Sie jährlich eine Rechnung die Sie innerhalbe der Zahlungsfrist überweisen müssen.
            </div>
        </p-accordionTab>
        <p-accordionTab header="Erhalte ich Softwareupdates?">
            <div class="line-height-3">
                JA. Da es sich bei Headwater um eine Online Softwareanwendung handelt, arbeiten Sie immer auf der aktuellsten Version.
            </div>
        </p-accordionTab>
        <p-accordionTab header="Kann ich Headwater auch am Tablet oder Handy nutzen?">
            <div class="line-height-3">
                Grundsätzlich JA. Headwater wurde aber als Desktopanwendung konzipiert. Daher können Inhalte am Tablet oder Handy verschoben oder nicht richtig dargestellt werden.
            </div>
        </p-accordionTab>
        <p-accordionTab header="Benötige ich eine Internetverbindung um Headwater zu nutzen?">
            <div class="line-height-3">
                JA. Da es sich bei Headwater um eine Online Softwareanwendung handelt, ist eine Internetverbindung notwendig.
            </div>
        </p-accordionTab>
    </p-accordion>
    <div class="border-1 border-round surface-border p-5 surface-50 flex flex-column align-items-center">
        <div class="font-medium text-xl mt-5 mb-3">Haben Sie weitere Fragen?</div>
        <p class="text-700 line-height-3 mt-0 mb-5">Érstellen Sie ein Support-Ticket. Wir kümmern uns umgehend darum</p>
        <button pButton pRipple label="Ticket" icon="pi pi-comments" (click)="onTickets()"></button>
    </div>
</div>
