import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppUser } from '../../../auth/appuser.model';
import { AppUserService } from '../../../auth/appuser.service';
import { Globals } from '../../../globals';
import { ContactSelectComponent } from '../../../shared/contact-select/contact-select.component';
import { Contact } from '../../../shared/contact.model';
import { ContactService } from '../../../shared/contact.service';
import { IDataService } from '../../../shared/dataservice.interface';
import { KvpService } from '../../../shared/kvp.service';
import { UserMember } from '../../../shared/usermember.model';

@Component({
  selector: 'app-access-settings-edit',
  templateUrl: './access-settings-edit.component.html',
  styleUrls: ['./access-settings-edit.component.css'],
  providers: [DialogService],
})
export class AccessSettingsEditComponent implements OnInit, IDataService {

  user: AppUser = new AppUser();

  ownerName: string;

  editMode: boolean;
  id: number;
  selectedRole: any;
  roles: any[];

  password1: string;
  password2: string;

  badPwd: boolean;
  pwdRequired: boolean;

  mappedContact: Contact;

  isLoading: boolean;

  ref: DynamicDialogRef;

  constructor(
    public dialogService: DialogService,
    public entityService: AppUserService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private contactService: ContactService,
    private settingsService: KvpService,
    private globals: Globals,
) { }

  ngOnInit(): void {
    this.initForm();

    this.roles = [{ key: 'Mitglied' }, { key: 'Verwaltung' }, { key: 'Vorstand' }];
    this.selectedRole = this.roles[0];

    this.password1 = '';
    this.password2 = '';

    this.badPwd = false;
    this.pwdRequired = false;

    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.editMode = params['id'] != 'new';
      this.initForm();
    });


  }

  initForm() {
    if (this.editMode) {
      this.user = this.entityService.getItem(this.id);
      this.password1 = this.user.password;
      this.password2 = this.user.password;
      this.selectedRole = this.roles[this.user.role];
      this.pwdRequired = false;
      this.readUserMember();
    }
    else {
      this.user = new AppUser();
      this.pwdRequired = true;
    }
  }

  readUserMember() {
    this.isLoading = true;
    this.settingsService.getUserMember(this.user.email).subscribe(resp => {
      if (resp && resp.contactId > 0) {
        this.contactService.readContact(resp.contactId).subscribe(c => {
          if (c) {
            this.mappedContact = c;
            this.ownerName = c.orgName ? c.orgName : c.firstName + ' ' + c.lastName;
          }
          this.isLoading = false;
        })
      }
      else {
        this.isLoading = false;
      }
    })
  }



  onSelectMember() {
    this.ref = this.dialogService.open(ContactSelectComponent, {
      data: {
        role: 'members'
      },
      header: 'Eigentümer auswählen',
      width: '75%',
      contentStyle: { 'max-height': '650px', overflow: 'auto' },
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((c: Contact) => {
      if (c) {
        this.mappedContact = c;
        this.ownerName = c.orgName ? c.orgName : c.firstName + ' ' + c.lastName;
      }
    });
  }


  onSave() {
    if (!this.badPwd && ((this.pwdRequired && this.password1.trim() != "") || !this.pwdRequired))
      this.doSave();
  }

  doSave() {
    this.user.role = this.roles.findIndex(r => r === this.selectedRole);
    this.user.password = this.password1.trim();
    if (this.editMode) {
      this.entityService.updateItem(this.user);
    }
    else {
      this.entityService.addItem(this.user);
    }

    if (this.mappedContact) {
      var um = new UserMember();
      um.email = this.user.email;
      um.contactId = this.mappedContact.id;
      this.settingsService.setUserMember(um).subscribe(resp => {
        console.log(resp);
      });
    }
  }

  onDelete() {
    if (this.editMode) {
      this.confirmationService.confirm({
        message: 'Möchten Sie diesen Benutzer wirklich löschen?',
        header: 'Benutzer löschen',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        key: 'confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.entityService.deleteItem(this.user.id);
          this.entityService.itemArray = new Array();
          this.router.navigate(['settings', 'access']);
        },
      });
    }
  }

  onPasswordChange() {
    if (this.password1 != '' || this.password2 != '') {
      this.badPwd = this.password1 != this.password2;
    }
    else {
      this.badPwd = false;
    }
  }

  New() { }
  Copy() { };

  Save() {
    this.onSave();
  };
  Delete() {
    this.onDelete();
  };

  Back() {
    this.router.navigate(['settings', 'access']);
  }
}
