import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common'

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent {
  constructor(private router: Router, private _location: Location) { }

  goToHome() {
    this.router.navigate(['home']);
  }

  goBack() {
    this._location.back();
  }
}
