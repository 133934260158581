import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Product } from '../../../shared/product.model';
import { ProductService } from '../product.service';
import { IDataService } from '../../../shared/dataservice.interface';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css'],
})
export class ProductListComponent implements OnInit, OnDestroy, IDataService {
  subProduct: Subscription;
  products: Product[];
  isLoading = false;

  selectedProduct: Product;

  searchString: string = '';
  totalProducts: number = 1;

  constructor(
    private productsService: ProductService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.productsService.detailActive = '';
    this.productsService.listActive = 'active';

    this.products = this.productsService.productArray;

    if (!this.products?.length) this.isLoading = true;

    this.subProduct = this.productsService.products.subscribe((p) => {
      this.totalProducts = this.productsService.totalProducts;
      this.products = p;
      this.isLoading = false;
      if (p.length) {
        this.productsService.selectedProduct = p[0];
      }
    });

    if (!this.products?.length) this.productsService.getProducts('', 10, 1);
  }

  ngOnDestroy(): void {
    this.subProduct.unsubscribe();
  }

  onSubmit(form: NgForm) {
    this.searchString = form.value.searchProduct;
    this.productsService.getProducts(this.searchString, 10, 1);
  }


  onSelected2(event) {
    this.productsService.selectedProduct = this.selectedProduct;
    this.router.navigate(['masterdata', 'products', event.data.id]);

    this.productsService.detailActive = 'active';
    this.productsService.listActive = '';
  }

  paginate(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    this.productsService.getProducts(this.searchString, 10, event.page + 1);
  }

  New() {
    this.router.navigate(['masterdata', 'products', 'new']);
  }
  Copy() {
    console.log("copy");
  };
  Save() {
    console.log("save");
  };
  Delete() {
    console.log("delete");
  };
}
