import {EntityBase} from "../../shared/entity.base";

export class MaintenanceWorkResp extends EntityBase {
  contactId: number = 0;
  workEntryId: number = 0;
  contactName: string = "";
  respOrder: number = 0;  // Order of responsible persons - smallest number main responsible
  active: boolean = true;
  startDate: number = -5364662400;
  endDate: number = -5364662400;
}
