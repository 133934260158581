<p-panel header="Headwater Statistik" [toggleable]="true" [collapsed]="true">

  <p-table responsiveLayout="scroll" styleClass="p-datatable-striped" [value]="stats">
    <ng-template pTemplate="header">
      <tr>
        <th>Name</th>
        <th>Wert</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-stat>
      <tr>
        <td>{{ stat.key }}</td>
        <td>{{ stat.value | number }}</td>
      </tr>
    </ng-template>
  </p-table>


</p-panel>
