import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDataService } from '../../shared/dataservice.interface';
import { EquipmentService } from './equipment.service';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.css']
})
export class EquipmentComponent implements OnInit {

  dataService: IDataService;

  constructor(private router: Router, public entityService: EquipmentService) { }

  ngOnInit(): void {
    this.entityService.detailActive = '';
    this.entityService.listActive = 'active';
  }

  onListSelected() {
    this.router.navigate(['masterdata', 'equipment']);
    this.entityService.detailActive = '';
    this.entityService.listActive = 'active';
  }

  onDetailSelected() {
    if (this.entityService.selectedItem && this.entityService.selectedItem.id) {
      this.router.navigate([
        'masterdata',
        'equipment',
        this.entityService.selectedItem.id,
        'edit',
      ]);
      this.entityService.detailActive = 'active';
      this.entityService.listActive = '';
    } else {
      this.onAddEquipment();
    }
  }

  onActivate(componentRef: Component) {
    this.dataService = componentRef as IDataService;
  }

  onAddEquipment() {
    this.router.navigate(['masterdata', 'equipment', 'new']);
    this.entityService.detailActive = 'active';
    this.entityService.listActive = '';
  }
}
