import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Contact } from '../../../shared/contact.model';
import { IDataService } from '../../../shared/dataservice.interface';
import { CustomerService } from '../customer.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerListComponent implements OnInit, OnDestroy, IDataService {

  subCustomer: Subscription;
  customers: Contact[];
  isLoading = false;
  selectedCustomer: Contact;

  searchString: string = '';
  totalCustomers: number = 1;

  constructor(
    private customerService: CustomerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.customerService.detailActive = '';
    this.customerService.listActive = 'active';

    this.totalCustomers = this.customerService.totalCustomers;
    this.customers = this.customerService.customerArray;

    if (!this.customers?.length) this.isLoading = true;

    this.subCustomer = this.customerService.customers.subscribe((m) => {
      this.customers = m;
      this.totalCustomers = this.customerService.totalCustomers;
      this.isLoading = false;
      if (m.length) {
        this.customerService.selectedCustomer = m[0];
      }
    });

    if (!this.customers?.length) this.customerService.getCustomers('', 10, 1);
  }

  ngOnDestroy() {
    this.subCustomer.unsubscribe();
  }

  onSelected2(event) {
    this.customerService.selectedCustomer = this.selectedCustomer;
    this.router.navigate(['masterdata', 'customers', event.data.id]);

    this.customerService.detailActive = 'active';
    this.customerService.listActive = '';
  }

  onAddMember() {
    this.router.navigate(['masterdata', 'customers', 'new']);
  }

  onSubmit(form: NgForm) {
    this.searchString = form.value.searchCustomer;
    this.customerService.getCustomers(this.searchString, 10, 1);
    //form.reset();
  }

  paginate(event) {
    this.customerService.getCustomers(this.searchString, 10, event.page + 1);
  }

  New() {
    this.router.navigate(['masterdata', 'customers', 'new']);
  }
  Copy() {
    console.log("copy");
  };
  Save() {
    console.log("save");
  };
  Delete() {
    console.log("delete");
  };

}
