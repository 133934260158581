import {Component, OnInit} from '@angular/core';
import {PanelModule} from "primeng/panel";
import {Globals} from "../../globals";
import {GeneralFunctionService} from "../../shared/general-function.service";
import {AppStatisticData} from "../../shared/app-statistic-data.model";

@Component({
  selector: 'app-appstats',
  standalone: false,

  templateUrl: './appstats.component.html',
  styleUrl: './appstats.component.css'
})
export class AppstatsComponent implements OnInit {

  stats: { key: string, value: number }[];

  constructor(private generalFunctionService: GeneralFunctionService,
              private globals: Globals) {
  }

  ngOnInit(): void {
    this.generalFunctionService.getAppStatistics().subscribe(resp => {
      var data = Object.assign(new AppStatisticData(), resp);

      this.stats = Object.keys(data).map(key => {
        return {key: this.formatKey(key), value: data[key]};
      });
      console.log(this.stats);
    });
  }

  formatKey(key: string): string {
    if (key === 'diskUsage') {
      return 'Speicherplatz';
    } else if (key === 'databaseSize') {
      return 'Datenbankgröße';
    } else if (key === 'totalRecords') {
      return 'Gesamtzahl der Datensätze';
    } else if (key === 'totalMembers') {
      return 'Mitglieder gesamt';
    } else if (key === 'activeMembers') {
      return 'Aktive Mitglieder';
    } else if (key === 'totalWaps') {
      return 'Wasseranschlüsse gesamt';
    } else if (key === 'activeWaps') {
      return 'Aktive Wasseranschlüsse';
    } else if (key === 'totalGauges') {
      return 'Aktive Wasserzähler';
    } else if (key === 'totalDocuments') {
      return 'Anzahl der Dokumente';
    }
    return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
  }
}
