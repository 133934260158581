<div class="row">
  <div class="col-md-12">
    <div class="card">

      <br>

      <form (ngSubmit)="onSubmit(f)" #f="ngForm">
        <div class="px-4">
          <div class="input-group mb-3">
            <input type="text" name="searchContactSelect" class="form-control input-lg" placeholder="Suchtext"
                   aria-label="Recipient's username" aria-describedby="basic-addon2" [(ngModel)]="searchString">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" [disabled]="!f.valid" type="submit">Suchen</button>
            </div>
          </div>
        </div>
      </form>


      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Nummer</th>
              <th scope="col">Name</th>
              <th scope="col">Adresse</th>


            </tr>
          </thead>
          <tbody class="table-users-body">
            <tr *ngFor="let contact of contacts" style="cursor: pointer;" (click)="onSelected(contact)">
              <td class="table-users-id">{{ contact.contactID }}</td>
              <td class="table-users-name">
                {{ contact.orgName ? contact.orgName : contact.firstName + ' ' + contact.lastName}}
              </td>
              <td class="table-users-name">{{ contact.address1}}</td>

            </tr>
          </tbody>
        </table>
      </div>

      <p-paginator class="my-paginator" [rows]="10" [totalRecords]="totalContacts" pageLinkSize="3"
                   (onPageChange)="paginate($event)"></p-paginator>
    </div>
  </div>
</div>
