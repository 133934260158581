<div class="row">
  <p-confirmDialog header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>
  <input #FileSelectInputDialog type="file" hidden ([ngModel])="fileName" (change)="onFileSelected($event)"
         accept=".txt,text/csv"/>
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <h6>Daten importieren</h6>
          </div>
        </div>

        <br/>
        <div class="row">
          <div class="col-md-6">

            <div class="form-group">
              <label>1. Zieltabelle auswählen</label>
              <select class="form-control" name="destination" id="destination" [(ngModel)]="index" style="height:40px">
                <option *ngFor="let d of destinationList" [value]="d.key">{{ d.text }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="myCheckbox">Daten updaten</label>
              <p-checkbox class="my-2" id="myCheckbox"></p-checkbox>
            </div>
          </div>
        </div>

        <br/>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label>2. Datei auswählen oder Daten direkt in das Feld kopieren</label><br>
              <a class="btn btn--primary mr-2" (click)="onSelectFile()">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M6.1,10L4,18V8H21A2,2 0 0,0 19,6H12L10,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H19C19.9,20 20.7,19.4 20.9,18.5L23.2,10H6.1M19,18H6L7.6,12H20.6L19,18Z"/>
                </svg>
                <span>Auswählen ...</span>
              </a>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Trennzeichen</label>
              <div class="row my-2">
                <div class="col-md-1">
                  <input type="text" name="delimiter" placeholder="" [(ngModel)]="delimiter" class="form-control"
                         *ngIf="!useTab"/>
                </div>
                <div class="col-md-10">
                  <div class="form-check my-1">
                    <input type="checkbox" class="form-check-input" name="useTabulator" [(ngModel)]="useTab" id="2">
                    <label class="form-check-label" for="2">Tabulator</label>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="row">
            <div class="form-group">

              <textarea name="notes"
                        placeholder="Spalte1 ; Spalte2 ; Spalte3 ; ..."
                        class="form-control mb-3"
                        [(ngModel)]="text"
                        style="height: 200px;"
                        (ngModelChange)="onTextChanged()">
                                  </textarea>
            </div>
          </div>
        </div>

        <br/>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>3. Spalten zuordnen (-1 bedeutet nicht zugeordnet)</label><br>

            </div>

            <p-table dataKey="fieldName" [value]="fieldList[index]" responsiveLayout="scroll" [scrollable]="true"
                     scrollHeight="200px" styleClass="p-datatable-striped">
              <ng-template pTemplate="header">
                <tr>
                  <th>Spaltenname</th>
                  <th>Spaltennummer</th>
                  <th>Standardwert</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-item>
                <tr style="height:40px">
                  <td>{{ item.displayName }}</td>
                  <td pEditableColumn>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="number" name="csvColumn" class="form-control p-0"
                               [(ngModel)]="item.csvColumn" style="width:80px" (ngModelChange)="onTextChanged()"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{ item.csvColumn }}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                  <td pEditableColumn>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" name="defaultColumn" class="form-control p-0"
                               [(ngModel)]="item.default" style="width:80px"/>
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{ item.default }}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label>Vorschau</label><br>

            </div>

            <p-table dataKey="fieldName" [value]="fieldList[index]" responsiveLayout="scroll" [scrollable]="true"
                     scrollHeight="200px" styleClass="p-datatable-striped">
              <ng-template pTemplate="header">
                <tr>
                  <th>Spaltenname</th>
                  <th>Wert</th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-item>
                <tr style="height:40px" *ngIf="item.csvColumn >= 0">
                  <td>{{ item.displayName }}</td>
                  <td>{{ sampleValues[item.csvColumn] }}</td>
                </tr>
              </ng-template>
            </p-table>

          </div>
        </div>

        <br/>
        <br/>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>5. Importieren</label><br>
              <a class="btn btn--primary mr-2" (click)="onImport()">
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M6.1,10L4,18V8H21A2,2 0 0,0 19,6H12L10,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H19C19.9,20 20.7,19.4 20.9,18.5L23.2,10H6.1M19,18H6L7.6,12H20.6L19,18Z"/>
                </svg>
                <span>Importieren ...</span>
              </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
