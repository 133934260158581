import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { tap } from 'rxjs/operators'
import { BehaviorSubject, Subject } from 'rxjs';

import { Globals } from '../globals'
import { User, App } from './user.model'


export interface AuthResponseData {
  id: string,
  userName: string,
  email: string,
  password?: string,
  isConfirmed: string,
  token: AppToken[],
  expiresIn: Date,
  appUser?: string

}

export interface AppToken {
  id: number,
  app: string,
  instance: string,
  token: string,
  role: number;
  isAdmin: boolean;
}

export interface AppResponseData {
  id: number,
  displayName: string,
  role: number,
  isAdmin: boolean
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  private tokenExpirationTimer: any;

  user = new BehaviorSubject<User>(null);
  app = new BehaviorSubject<App>(null);

  currentApp: App;
  currentUser: User;

  appId : number = 0;

  constructor(private http: HttpClient, private router: Router, private globals: Globals) { 
    this.appId = parseInt(localStorage.getItem('appId'));

  }

  autologin() {
    let userData: User;
    userData = JSON.parse(localStorage.getItem('userData'));

    if (!userData) {
      return;
    }

    this.currentUser = new User(userData.userName, userData.token, new Date(userData._tokenExpireDate), userData.email);
    this.globals.currentUser = this.currentUser;

    if (this.currentUser.token[0]) {
      this.user.next(this.currentUser);
      const expirationDuration = new Date(userData._tokenExpireDate).getTime() - new Date().getTime();
      this.autologout(expirationDuration);
      this.readApps();
    }
  }

  login(email: string, pwd: string) {
    const url = this.globals.AUTH_URL + 'Users/authenticate';

    return this.http.post<AuthResponseData>(url,
      {
        Username: email,
        Password: pwd,
        App: this.globals.APP_NAME
      }).pipe(tap(resData => {
        console.log(resData);
        this.currentUser = new User(resData.userName, resData.token, resData.expiresIn, email);

        console.log(this.currentUser);

        this.user.next(this.currentUser);
        const expirationDuration = new Date(resData.expiresIn).getTime() - new Date().getTime();

        this.autologout(expirationDuration);
        localStorage.setItem('userData', JSON.stringify(this.currentUser));

        this.appId = this.currentUser.token[0].id;
        localStorage.setItem('appId', this.appId.toString());

        this.currentApp = new App(this.currentUser.token[0].id, this.currentUser.token[0].instance, this.currentUser.token[0].role, this.currentUser.token[0].isAdmin);

        this.globals.currentApp = this.currentApp;
        this.globals.currentUser = this.currentUser;

        this.app.next(this.currentApp);




      }));
  }

  logout() {
    this.user.next(null);
    this.router.navigate(['/login'])
    localStorage.removeItem('userData');

    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
      this.tokenExpirationTimer = null;
    }
    //location.reload();
  }

  autologout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration)
  }

  readApps() {
    console.log('readApps');
    const url = this.globals.AUTH_URL + 'users/apps';
    this.http.get<AppResponseData[]>(url).subscribe(respData => {
      console.log(respData);
      this.currentApp = new App(respData[0].id, respData[0].displayName, respData[0].role, respData[0].isAdmin);

      this.appId = this.currentApp.app;
      localStorage.setItem('appId', this.appId.toString());

      this.globals.currentApp = this.currentApp;


      this.app.next(this.currentApp);


    });


  }
}
