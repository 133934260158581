import {Component, OnInit} from '@angular/core';
import {MemberService} from '../member.service';
import {MemberListViewModel} from '../memberlist.viewmodel';
import {Globals} from "../../globals";

interface ListCol {
  name: string
}

@Component({
  selector: 'app-member-export-list',
  templateUrl: './member-export-list.component.html',
  styleUrls: ['./member-export-list.component.css']
})
export class MemberExportListComponent implements OnInit {
  isLoading: boolean = false;

  title: string = "Mitgliederliste";

  columns: ListCol[] = new Array();

  memberList: MemberListViewModel[] = new Array();

  cols: any[];

  exportColumns: any[];

  constructor(private memberService: MemberService, private globals: Globals) {
  }

  ngOnInit(): void {
    this.cols = [
      {field: 'memberID', header: 'Nummer'},
      {field: 'name', header: 'Name'},
      {field: 'address', header: 'Adresse'},
      {field: 'wapCount', header: 'Anschlüsse'},

    ];

    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));

  }

  setColumns() {
    this.cols = [
      {field: 'memberID', header: 'Nummer'},
      {field: 'name', header: 'Name'},
      {field: 'address', header: 'Adresse'},
      {field: 'wapCount', header: 'Anschlüsse'},

    ];


    this.columns.forEach(col => {
      this.cols.push({field: '', header: col.name});
    });


    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  onAddColumn() {
    this.columns.push({name: "Neue Spalte"});
  }

  onDeleteColumn(index: number) {
    this.columns.splice(index, 1);
  }

  onCreateList() {
    this.memberService.getMemberList().subscribe(resp => {
      this.memberList = resp.slice();
    });
  }

  onPrint() {
    this.setColumns();
    this.exportPdf();
  }

  onSave() {
    this.setColumns();
    var csv = this.generateCSV(this.memberList, this.cols, ";");
    var BOM = "\uFEFF";
    var csvData = BOM + csv;
    var blob = new Blob([csvData], {type: 'text/csv;charset=utf-8'});
    var url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', "mitgliederliste_" + this.globals.getCurrentTimestamp() + ".csv");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    console.log(csv);

  }

  customSort(event) {
    console.log(event);
    var o1 = 1;
    var o2 = -1;

    if (event.order === -1) {
      o1 = -1;
      o2 = 1;
    }

    this.memberList.sort((a, b) => a[event.field] > b[event.field] ? o1 : o2);

  }

  exportPdf() {
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        //@ts-ignore
        const doc = new jsPDF.default('landscape'); //.default(0, 0);


        doc.text(this.title, 14, 15)

        var totalPagesExp = '{total_pages_count_string}';

        //@ts-ignore
        doc.autoTable(this.exportColumns, this.memberList,
          {
            startY: 20,
            afterPageContent: function (data) {
              doc.setFontSize(10);
              doc.text("Seite " + data.pageCount + '/' + totalPagesExp, 14, doc.internal.pageSize.height - 10);
            }
          });


        // Total page number plugin only available in jspdf v1.0+
        if (typeof doc.putTotalPages === 'function') {
          doc.putTotalPages(totalPagesExp)
        }

        doc.save('mitgliederliste_' + this.globals.getCurrentTimestamp() + '.pdf');
      })
    })
  }

  generateCSV(data: any[], cols: { field: string, header: string }[], delimiter: string = ','): string {
    const headers = cols.map(col => col.header).join(delimiter);

    const rows = data.map(item => {
      const values = cols.map(col => {
        const value = item[col.field];
        if (value instanceof Date) {
          return this.globals.getGermanDateString(value);
        }

        return value;
      })
        .join(delimiter);
      return values;
    });

    return `${headers}\n${rows.join('\n')}`;
  }
}
