import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDataService } from '../../shared/dataservice.interface';
import { AccountService } from './account.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})
export class AccountsComponent implements OnInit {

  dataService: IDataService;

  constructor(private router: Router, public entityService: AccountService) { }

  ngOnInit(): void {
    this.entityService.detailActive = '';
    this.entityService.listActive = 'active';
  }

  onAddEntity() {
    this.router.navigate(['settings', 'accounts', 'new']);
    this.entityService.detailActive = 'active';
    this.entityService.listActive = '';
  }

  onDetailSelected() {

  }

  onListSelected() {
    this.router.navigate(['settings', 'accounts']);
    this.entityService.detailActive = '';
    this.entityService.listActive = 'active';
  }

  onActivate(componentRef: Component) {
    this.dataService = componentRef as IDataService;
  }
}
