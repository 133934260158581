import {DocBase} from "../../../shared/doc.base";


export class CounterListTemplateModel extends DocBase {

  headerText: string;

  constructor() {
    super();

    this.table = 'CounterListTemplate';
    this.headerText = 'Sehr geehrtes Mitglied,';
  }
}
