import { Component } from '@angular/core';

@Component({
  selector: 'app-app-help',
  templateUrl: './app-help.component.html',
  styleUrls: ['./app-help.component.css']
})
export class AppHelpComponent {

}
