<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmDialog key="info" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="mb-3 flex align-items-center justify-content-between">
  <div>
    <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
    <span class="text-xl font-medium text-900 mx-3">Rechnung</span>
  </div>
  <div *ngIf="invoice.id > 0">
    <button pButton pRipple label="Rechnung erzeugen" icon="pi pi-file-pdf" (click)="onPrint()"></button>
    <p-checkbox [(ngModel)]="doAll" [binary]="true" label="Alle ausgewählten Rechnungen erzeugen"
                class="mb-1 ml-3"></p-checkbox>
  </div>
  <div>
    <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="Save()"></button>
  </div>
</div>


<div class="card">
  <div class="card-header d-md-flex justify-content-between align-items-center">
    <h5 class="card-title">Kopfdaten</h5>
  </div>
  <div class="card-body card-sidebar-menu">
    <form #headerData="ngForm">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">

            <label>Kundennummer</label>

            <p-inputGroup>
              <input type="text" name="customerID" placeholder="Kundennummer" readonly
                     class="form-control" [value]='this.customer.contactID'/>
              <button type="button" pButton icon="pi pi-search" class="p-button-success"
                      (click)="onSelectCustomer()"></button>
            </p-inputGroup>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <div class="d-md-flex justify-content-between align-items-center">
                  <label>Empfängeraddresse</label>

                </div>
                <textarea name="address"
                          placeholder="Name und Anschrift des Empfängers"
                          class="form-control mb-3"
                          [(ngModel)]="invoice.completeAddress"
                          style="height:100px;">
                                  </textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Rechnungsnummer</label>
                <input type="text" name="customerID" placeholder="Rechnungsnummer"
                       [(ngModel)]="invoice.invoiceID"
                       class="form-control"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Rechnungsdatum</label>
                <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="true" [locale]="globals.de"
                            name="invoiceDate" [(ngModel)]="invoiceDate">
                </p-calendar>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Liefertermin</label>
                <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false" [locale]="globals.de"
                            name="deliveryStart" [(ngModel)]="deliveryDate">
                </p-calendar>
              </div>
            </div>

            <div class="col-md-6" *ngIf="invoice.isStorno">
              <div class="form-group">
                <label style="color: red; font-weight: 600">Storniert am</label>
                <input type="text" name="stornoDate" placeholder=""
                       [(ngModel)]="invoice.stornoDate"
                       readonly
                       class="form-control"
                       style="color:red"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Vermerk</label>
                <input type="text" name="note" placeholder="Rechnungsvermerk" class="form-control"
                       [(ngModel)]="invoice.note"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<br>
<p-toast></p-toast>
<div class="card">
  <div class="card-header d-md-flex justify-content-between align-items-center">
    <h5 class="card-title">Positionen</h5>
  </div>
  <div class="card-body card-sidebar-menu">
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive">
          <p-table [value]="invoice.invoiceItems" dataKey="id" editMode="row" styleClass="p-datatable-striped">
            <ng-template pTemplate="header">
              <tr>
                <!-- <th style="border-top:0px; width:8rem;"></th> -->
                <th style="border-top:0px;border-bottom:1px;width:40px"></th>
                <th style="border-top:0px;border-bottom:1px;width:65px;">
                  <div class="primeNgTableHeaderCell">Pos.</div>
                </th>
                <th style="border-top:0px;border-bottom:1px;width:350px;">
                  <div class="primeNgTableHeaderCell">Bezeichnung</div>
                </th>
                <th style="border-top:0px;border-bottom:1px;width:60px;">
                  <div style="width: 70px;" class="primeNgTableHeaderCell">Menge</div>
                </th>
                <th style="border-top:0px;border-bottom:1px;width:60px;">
                  <div class="primeNgTableHeaderCell">Einheit</div>
                </th>
                <th style="border-top:0px;border-bottom:1px;width:60px;">
                  <div class="primeNgTableHeaderCell">€/Einheit</div>
                </th>
                <th style="border-top:0px;border-bottom:1px;width:80px;">
                  <div class="primeNgTableHeaderCell">Rabatt</div>
                </th>
                <th style="border-top:0px;border-bottom:1px;width:80px;">
                  <div class="primeNgTableHeaderCell">MwSt.</div>
                </th>
                <th style="border-top:0px;border-bottom:1px;width:60px;">
                  <div class="primeNgTableHeaderCell">Gesamt</div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
              <tr [pEditableRow]="product" style="border-collapse: separate; border-spacing: 0 15px; ">
                <!-- <td style="text-align:center">
                    <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(product)" class="p-button-rounded p-button-text"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(product)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                    <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(product, ri)" class="p-button-rounded p-button-text p-button-danger"></button>
                </td> -->
                <td>
                  <div class="d-md-flex justify-content-between align-items-center">
                    <h6 class="add-user-button d-flex align-items-center"
                        (click)="onDeleteItem(ri)">
                      <svg width="24" height="24" viewBox="0 -3 24 24"
                           xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"/>
                      </svg>
                    </h6>
                  </div>
                </td>
                <td pEditableColumn style="padding:0px;" class="align-top">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input class="form-control form-control-sm" style="width:100%;padding:0px;" pInputText type="text"
                             [(ngModel)]="product.position">
                    </ng-template>
                    <ng-template pTemplate="output">
                      <div style="word-wrap: break-word;padding:0px 16px">{{ product.position }}</div>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="padding:0px;">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <div class="d-md-flex justify-content-between align-items-center">

                        <h6 class="add-user-button d-flex justify-content-left" (click)="onSelectProduct(ri)">
                          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M19 6V5A2 2 0 0 0 17 3H15A2 2 0 0 0 13 5V6H11V5A2 2 0 0 0 9 3H7A2 2 0 0 0 5 5V6H3V20H11.81A6.59 6.59 0 0 1 10.5 18H5V8H19V9.5A6.59 6.59 0 0 1 21 10.81V6M20.31 17.9A4.5 4.5 0 1 0 18.88 19.32L22 22.39L23.39 21M16.5 18A2.5 2.5 0 1 1 19 15.5A2.5 2.5 0 0 1 16.5 18Z"/>
                          </svg>
                          <span></span>
                        </h6>
                        <!-- <input class="form-control form-control-sm" style="width:100%;padding:0px;" pInputText type="text" [(ngModel)]="product.description" (blur)="onBlur($event,ri)"required > -->
                        <textarea class="form-control form-control-sm" style="width:100%;padding:0px;" pTextArea
                                  type="text" [(ngModel)]="product.text"
                                  (keypress)="onKeyPress($event,ri)" required name="" id="" cols="30" rows="2">
                                                    </textarea>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="output">
                                            <textarea class="form-control form-control-sm"
                                                      style="width:100%;padding:0px;" pTextArea type="text"
                                                      [(ngModel)]="product.text"
                                                      readonly required name="" id="" cols="30"
                                                      rows="{{ product.Rows }}">
                                                    </textarea>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="padding:0px;">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input class="form-control form-control-sm" style="width:100%;padding:0px;" pInputText
                             type="number" [(ngModel)]="product.quantity" (focus)="onFocus($event)">
                    </ng-template>
                    <ng-template pTemplate="output">
                      <div style="word-wrap: normal;padding:0px 16px;text-align: right;">{{ product.quantity }}</div>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="padding:0px;">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input class="form-control form-control-sm" style="width:100%;padding:0px;" pInputText type="text"
                             [(ngModel)]="product.unit">
                    </ng-template>
                    <ng-template pTemplate="output">
                      <div style="word-wrap: normal;padding:0px 16px;">{{ product.unit }}</div>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="padding:0px;">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input class="form-control form-control-sm" style="width:100%;padding:0px;" pInputText
                             type="number" [(ngModel)]="product.ListPricePerUnitExcl">
                    </ng-template>
                    <ng-template pTemplate="output">
                      <div
                        style="word-wrap: normal;padding:0px 16px;text-align: right;">{{ product.ListPricePerUnitExcl | currency: 'EUR' }}
                      </div>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="padding:0px;">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input class="form-control form-control-sm" style="width:100%;padding:0px;" pInputText
                             type="number" [(ngModel)]="product.Discount">
                    </ng-template>
                    <ng-template pTemplate="output">
                      <div
                        style="word-wrap: normal;padding:0px 16px;text-align: right;">{{ product.discount ? product.Discount + '%' : '' }}
                      </div>
                      <div
                        style="word-wrap: normal;padding:0px 16px;text-align: right;">{{ product.discount ? (product.DiscountAmount | currency:'EUR') : '' }}
                      </div>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="padding:0px;">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input class="form-control form-control-sm" style="width:100%;padding:0px;" pInputText
                             type="number" [(ngModel)]="product.Tax">
                    </ng-template>
                    <ng-template pTemplate="output">
                      <div
                        style="word-wrap: normal;padding:0px 16px;text-align: right;">{{ product.Tax ? product.Tax + '%' : '' }}
                      </div>
                      <div
                        style="word-wrap: normal;padding:0px 16px;text-align: right;">{{ product.Tax ? (product.VatAmount | currency:'EUR') : '' }}
                      </div>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td pEditableColumn style="padding:0px;">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <div style="word-wrap: normal;padding:0px 16px;">{{ product.PriceExcl | currency: 'EUR' }}</div>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <div style="word-wrap: normal;padding:0px 16px;">{{ product.PriceExcl | currency: 'EUR' }}</div>
                    </ng-template>
                  </p-cellEditor>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <div class="row" *ngFor="let t of Total; let i = index;">
      <div class="col-md-12">
        <div class="container-fluid">
          <div style="text-align: right;">{{ t.name }}</div>
          <div style="text-align: right;">{{ t.amount | currency:'EUR' }}</div>
        </div>
        <!-- <p style="text-align: right;">{{t.name}}</p> -->
      </div>
    </div>
  </div>
</div>

<br>
<div class="card">
  <form #footerData="ngForm">
    <div class="card-header d-md-flex justify-content-between align-items-center">
      <h5 class="card-title">Fußdaten</h5>
    </div>
    <div class="card-body card-sidebar-menu">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label>Skonto [%]</label>
            <input type="number" name="scontoPercent" placeholder="%" class="form-control"
                   [(ngModel)]='this.invoice.skonto'/>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Skonto [Tage]</label>
            <input type="number" name="scontoDate" placeholder="Tage" class="form-control"
                   [(ngModel)]='this.invoice.skontoDays'/>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Zahlungsziel [Tage]</label>
            <input type="number" name="paymentDate" placeholder="Tage" class="form-control"
                   [(ngModel)]='this.invoice.paymentDays'/>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Zahlungsmethode</label>
            <p-dropdown name="paymentMethod" [options]="paymentMethods" [(ngModel)]="invoice.paymentMethode"
                        optionLabel="key" optionValue="key"></p-dropdown>

          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Fußtext</label>
            <textarea name="address"
                      class="form-control mb-3"
                      [(ngModel)]="invoice.footerText"
                      style="height:100px;">
                                  </textarea>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

