import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDataService } from '../../shared/dataservice.interface';
import { SupplierService } from './supplier.service';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.css']
})
export class SupplierComponent implements OnInit {
  dataService: IDataService;

  constructor(private router: Router, public supplierService: SupplierService) { }

  ngOnInit(): void {
    this.supplierService.detailActive = '';
    this.supplierService.listActive = 'active';
  }

  onAddSupplier() {
    this.router.navigate(['masterdata', 'suppliers', 'new']);
    this.supplierService.detailActive = 'active';
    this.supplierService.listActive = '';
  }

  onListSelected() {
    this.router.navigate(['masterdata', 'suppliers']);
    this.supplierService.detailActive = '';
    this.supplierService.listActive = 'active';
  }

  onActivate(componentRef: Component) {
    this.dataService = componentRef as IDataService;
  }

  onDetailSelected() {
    if (this.supplierService.selectedSupplier && this.supplierService.selectedSupplier.id) {
      this.router.navigate([
        'masterdata',
        'suppliers',
        this.supplierService.selectedSupplier.id,
        'edit',
      ]);
      this.supplierService.detailActive = 'active';
      this.supplierService.listActive = '';
    } else {
      this.onAddSupplier();
    }
  }
}
