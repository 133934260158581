<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-dialog [(visible)]="display" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}">
    <ng-template pTemplate="header">
        <div class="flex align-items-center">
            <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style="width:32px;height:32px">
                <i class="pi pi-info text-lg"></i>
            </span>
            <span class="font-medium text-2xl text-900">Hinzufügen</span>
        </div>
    </ng-template>

    <div class="grid formgrid p-fluid">

        <div class="field mb-3 col-12 md:col-6">
            <label for="name" class="font-medium text-900">Name</label>
            <input id="name" type="text" pInputText [(ngModel)]="selectedEntity.name" autocomplete="off">
        </div>


        <div class="field mb-3 col-12 md:col-6">
            <label for="skonto" class="font-medium text-900">Jahr</label>
            <p-inputNumber id="skonto" [(ngModel)]="selectedEntity.year" [useGrouping]="false"></p-inputNumber>
        </div>
        <div class="field mb-3 col-12 md:col-6">
            <label for="begin" class="font-medium text-900">Beginn</label>
            <p-calendar id="begin" appendTo="body" dateFormat="dd.mm.yy" [(ngModel)]="startDate"></p-calendar>
        </div>
        <div class="field mb-3 col-12 md:col-6">
            <label for="end" class="font-medium text-900">Ende</label>
            <p-calendar id="end" appendTo="body" dateFormat="dd.mm.yy" [(ngModel)]="endDate"></p-calendar>
        </div>
        <div class="field mb-3 col-12 md:col-6 ml-2">
            <label for="nr_pad" class="font-medium text-900"> </label>
            <div class="flex align-items-center">
                <p-checkbox inputId="privacy" [binary]="true" [(ngModel)]="selectedEntity.active"></p-checkbox>
                <span class="ml-2 text-900">Aktiv</span>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <div class=" surface-border">
            <button pButton pRipple icon="pi pi-times" (click)="display = false" label="Abbrechen"></button>
            <button pButton pRipple icon="pi pi-check" (click)="onSave()" label="Speichern"></button>
        </div>
    </ng-template>
</p-dialog>

<p-toast position="bottom-right" key="tl"></p-toast>
<div class="surface-card p-4 shadow-2 border-round">

    <div class="mb-3 flex align-items-center justify-content-between">
        <span class="text-xl font-medium text-900">Wirtschaftsjahre</span>
        <button pButton pRipple label="Hinzufügen" icon="pi pi-plus" (click)="showDialog()"></button>
    </div>

    <p-table [value]="entities" selectionMode="single" [(selection)]="selectedEntity" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
            <tr>
                <th>#</th>
                <th>Name</th>
                <th>Jahr</th>
                <th>Beginn</th>
                <th>Ende</th>
                <th>Aktiv</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-entity>
            <tr>
                <td>{{entity.id}}</td>
                <td>{{entity.name}}</td>
                <td>{{entity.year}}</td>
                <td>{{entity.startDate2 * 1000 | customDate }}</td>
                <td>{{entity.endDate2 * 1000 | customDate }}</td>
                <td>{{entity.active}}</td>
                <td style="text-align:right;">
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" (click)="onEdit(entity)"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-success" (click)="onDelete(entity)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>

</div>
