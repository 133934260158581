import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Globals } from '../../globals';
import { Equipment } from '../../shared/equipment.model';

@Injectable({ providedIn: 'root' })
export class EquipmentService {

  itemList = new Subject<Equipment[]>();
  itemArray = new Array<Equipment>();

  totalItems: number = 0;

  selectedItem: Equipment = null;

  detailActive = '';
  listActive = 'active';

  documentId: number;

  constructor(private globals: Globals,
    private router: Router,
    private http: HttpClient) {

  }

  getItems(search: string, pageSize: number, page: number) {
    const url = this.globals.APP_URL + 'equipment';
    let params = new HttpParams();
    params = params.append('search', search);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('page', page.toString());
    this.http
      .get<Equipment[]>(url, {
        params: params,
        observe: 'response',
      })
      .subscribe((respData) => {
        this.totalItems = parseInt(respData.headers.get('x-total-count'));
        this.itemArray = respData.body.slice();
        this.itemList.next(this.itemArray);
      });
  }

  updateItem(p: Equipment) {
    const url = this.globals.APP_URL + 'equipment';
    this.http.put<Equipment>(url, p).subscribe((respData) => {
      this.selectedItem = respData;
      const idx = this.itemArray.findIndex((x) => x.id === p.id);
      this.itemArray[idx] = respData;
      this.router.navigate([
        'masterdata',
        'equipment',
        this.selectedItem.id
      ]);
    });
  }

  addItem(p: Equipment) {
    const url = this.globals.APP_URL + 'equipment';
    this.http.post<Equipment>(url, p).subscribe((respData) => {

      this.itemArray.push(respData);
      this.selectedItem = respData;
      this.router.navigate(['masterdata', 'equipment', this.selectedItem.id]);

    });
  }

  readItem(id: number) {
    const url = this.globals.APP_URL + 'equipment/' + id;
    return this.http.get<Equipment>(url);
  }


  getItem(id: number) {
    //this.selectedProduct = this.productArray.find((p) => p.id === id);
    this.selectedItem = Object.assign(
      new Equipment(),
      this.itemArray.find((p) => p.id === id)
    );
    return this.selectedItem;
  }



  deleteItem(id: number) {
    const url = this.globals.APP_URL + 'equipment/' + id;
    return this.http.delete(url);
  }

}
