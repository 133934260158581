import {EntityBase} from "../shared/entity.base";

export enum TransactionType {
  Normal,
  Rebooking,
  Interest,
  Fee,
  TaxOffice,
  ManualCorrection
}


export class Transaction extends EntityBase {
  transactionType: TransactionType = TransactionType.Normal;
  transactionDate2: number = -5364662400;
  text: string = "";
  importText: string = "";
  accountId: number = 0;
  iban: string = "";
  bic: string = "";
  invoiceId: number = 0;
  invoiceNumber: string = "";
  amount: number = 0;
  financialYearId: number = 0;
  costCenter: string = "";

  taxRate1: number = 0;
  taxRate2: number = 0;

  taxAmount1: number = 0;
  taxAmount2: number = 0;


  isKnownTransaction(): boolean {
    return false;
  }

  hasTax(): boolean {
    return this.taxRate1 > 0 || this.taxRate2 > 0;
  }

}


export class TransactionBalancingViewModel extends Transaction {
  invoiceTotal: number = 0;
  transactionTemplateId: number = 0;
  infoText: string = "";
  taxSum: number = 0.0;

  isValidDate: boolean = true;

  override isKnownTransaction(): boolean {
    if ((this.taxRate1 > 0 && this.taxAmount1 == 0) || (this.taxRate2 > 0 && this.taxAmount2 == 0))
      return false;

    if (this.transactionType == TransactionType.Fee || this.transactionType == TransactionType.Interest || this.transactionType == TransactionType.TaxOffice)
      return true;

    if (this.transactionType == TransactionType.Normal && this.invoiceId > 0)
      return true;

    if (this.transactionTemplateId > 0)
      return true;

    return false;
  }
}

export class TaxReportEntry {
  taxRate: number = 0;
  amount: number = 0;
}

export class TaxReport {
  totalNetRevenue: number = 0;
  taxFreeRevenue: number = 0;
  totalRevenue: number = 0;
  taxEntries: TaxReportEntry[] = [];
  totalInputTax: number = 0;
  totalSalesTax: number = 0;
  taxAmount: number = 0;
}
