<div class="surface-card p-4 shadow-2 border-round">
    
    <!--
              <p-toolbar>
                <div class="p-toolbar-group-left">
                  <p-button label="Neu" icon="pi pi-file" (onClick)="onAddEntity()"></p-button>

                </div>

                <div class="p-toolbar-group-right" *ngIf="this.entityService.detailActive == 'active'">
                  <p-button label="Löschen" icon="pi pi-trash" (onClick)="dataService.Delete()"></p-button>
                  <p-button label="Speichern" icon="pi pi-save" (onClick)="dataService.Save()"></p-button>
                </div>

              </p-toolbar>

              <ul class="nav nav-tabs" style="margin: 10px; margin-bottom:0px">
                <li class="nav-item bd-highlight">
                  <a class="nav-link" style="cursor: pointer" (click)="onListSelected()"
                     [ngClass]="entityService.listActive">Abrechnungsarten</a>
                </li>

                <li class="nav-item bd-highlight">
                  <a class="nav-link" style="cursor: pointer" [ngClass]="entityService.detailActive"
                     (click)="onDetailSelected()">Daten</a>
                </li>

              </ul>
    -->

    <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>
      
