<p-panel header="Meine Wasseranschlüsse" [toggleable]="false">
  <p-table [value]="waps" selectionMode="single" [(selection)]="selectedWap" dataKey="id" responsiveLayout="scroll"
           (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped">
    <ng-template pTemplate="header">
      <tr>
        <th scope="col">Nummer</th>
        <th *ngIf="!wapService.isChild" scope="col">Adresse</th>
        <th *ngIf="!wapService.isChild" scope="col">Ort</th>
        <th *ngIf="wapService.isChild" scope="col">Anteil</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-wap>
      <tr [pSelectableRow]="wap">
        <td class="table-users-id">{{ wap.accessPointID }}</td>
        <td *ngIf="!wapService.isChild" class="table-users-name">{{ wap.address1 }}</td>
        <td *ngIf="!wapService.isChild" class="table-users-name">{{ wap.city }}</td>
        <td *ngIf="wapService.isChild" class="table-users-name">{{ wap.fragment }}</td>
      </tr>
    </ng-template>
  </p-table>
</p-panel>
