<div class="row">
  <div class="col-md-7">
    <div class="row mx-2">
      <div class="col-md-6">
        <app-mywaps [member]="mappedContact"></app-mywaps>
      </div>

      <div class="col-md-6">
        <app-myinvoices></app-myinvoices>
      </div>
    </div>
    <br/>
    <div class="row mx-2">
      <div class="col-md-6">
        <p-panel header="Nachrichten" [toggleable]="false">
        </p-panel>
      </div>

      <div class="col-md-6">
        <app-mytasks [member]="mappedContact"></app-mytasks>
      </div>
    </div>
    <br/>
    <div class="row mx-2">
      <div class="col-md-6">
        <app-appstats></app-appstats>
      </div>
      <div class="col-md-6" *ngIf="false">
        <google-map height="500px" width="100%" [center]="center" [zoom]="zoom" [options]="options">
          <map-marker [position]="center"></map-marker>
        </google-map>
      </div>

    </div>


  </div>

  <div class="col-md-5">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title header-nav-user my-2">Was gibt es neues bei Headwater?</h4>
          <h6 class="card-subtitle text-muted">Hier finden Sie Informationen über neue Funktionen und Erweiterungen
            rund um headwater.at.</h6>
        </div>

        <hr/>

        <div class="card-body">
          <h5 class="card-title my-2">Datensicherung erstellen <span class="badge bg-success">NEU</span><span></span>
          </h5>
          <h6 class="card-subtitle mb-2 text-muted">08.07.2024</h6>
          <p class="card-text">
            Unter <a href="/settings/backup">Einstellungen->Datensicherung</a> können Sie ab sofort eine
            Datensicherung erstellen. Die Datensicherung
            wird
            im DMS abgelegt und kann jederzeit heruntergeladen werden.


          </p>
          <p class="card-text">
            Das Wiederherstellen der Datensicherung wird erst in einer der nächsten Versionen möglich sein. Bitte
            beachten Sie, dass die
            Datensicherung nur die Datenbank sichert. Dateien im DMS müssen separat gesichert werden.
          </p>

        </div>


        <hr/>

        <div class="card-body">
          <h5 class="card-title my-2">Kontoauszug abgleichen <span
            class="badge bg-secondary">Kürzlich hinzugefügt</span><span></span>
          </h5>
          <h6 class="card-subtitle mb-2 text-muted">13.06.2024</h6>
          <p class="card-text">
            Mit dieser Funktion können Kontoauszüge von Banken eingelesen und mit den offenen Rechnungen oder
            Buchungsvorlagen abgeglichen werden.


          </p>
          <p class="card-text">
            Nach dem Einlesen des Kontoauszuges versucht Headwater die Buchungszeile zu erkennen und einer offenen
            Rechnung oder Buchungsvorlage zuzuordnen. Buchungsvorlagen können mit Schlagwörtern versehen werden, um die
            Zuordnung zu erleichtern.
          </p>

        </div>


        <hr/>

        <div class="card-body">
          <h5 class="card-title my-2">SEPA Lastschrift</h5>
          <h6 class="card-subtitle mb-2 text-muted">14.05.2024</h6>
          <p class="card-text">
            Ab dieser Version können Sie SEPA Lastschriften erstellen und als CSV herunter geladen werden. Die Funktion
            ist unter dem Menüpunkt <a href="/jobs/sepa-direct-debit">Aufträge->SEPA Lastschrift</a> zu finden.

          </p>
          <p class="card-text">
            Um diese Funktion nutzen zu können, müssen Sie die Bankverbindung der eigenen Organisation hinterlegen.
            Bitte diese Einstellungen unter Einstellungen->Organisation prüfen und abspeichern.
          </p>

        </div>


        <hr/>


        <div class="card-body">
          <h5 class="card-title my-2">Wartungsplan</h5>
          <h6 class="card-subtitle mb-2 text-muted">05.04.2024</h6>
          <p class="card-text">
            Zukünftig können hinter Wartungsarbeiten die verantwortlichen Personen hinterlegt werden. So können Sie
            einfach nachvollziehen, wer für die Wartung zuständig ist.
            <br>
            Es können auch Verwaltungsaufgaben hinterlegt werden. So können Sie z.B. die Jahresabrechnung oder die
            Umsatzsteuervoranmeldung hinterlegen und sehen, wer dafür zuständig ist. Diese Arbeiten werden nicht in das
            Logbuch eingetragen.
          </p>

        </div>

        <hr/>

        <div class="card-body">
          <h5 class="card-title my-2">Mahnungen <span class="badge bg-warning">In Arbeit</span></h5>
          <h6 class="card-subtitle mb-2 text-muted">10.03.2024</h6>
          <p class="card-text">
            Aktuell arbeiten wir an der Funktion, dass Mahnungen automatisch erstellt und versendet werden können. Wir
            hoffen, dass wir diese Funktion bald zur Verfügung stellen können.
          </p>

        </div>

        <hr/>

      </div>

    </div>
  </div>
</div>

