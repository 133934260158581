import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {NgForm} from '@angular/forms';

import {ProductsComponent} from '../products.component';
import {ProductService} from '..//product.service';
import {Product} from 'src/app/shared/product.model';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Contact} from '../../../shared/contact.model';
import {ContactSelectComponent} from '../../../shared/contact-select/contact-select.component';
import {ContactService} from '../../../shared/contact.service';
import {KvpService} from '../../../shared/kvp.service';
import {Globals, KVP_GROUPS} from '../../../globals';
import {KvpSetting} from '../../../shared/kvpsetting.model';
import {IDataService} from '../../../shared/dataservice.interface'
import {ConfirmationService, MessageService} from 'primeng/api';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.css'],
  providers: [DialogService],
})
export class ProductEditComponent implements OnInit, OnDestroy, IDataService {
  @ViewChild('productData', {static: true}) productForm: NgForm;

  editMode = false;

  productGroups: any[];
  selectedGroup: any;

  id: number;
  product: Product = new Product();
  newId: string = '';
  vendorName: string;

  sales2: string;
  plus: number = 15.0;

  purchasePrice: number = 0.0;
  tax: number = 0.0;
  salesPrice: number = 0.0;


  ref: DynamicDialogRef;

  constructor(
    private router: Router,
    public productsService: ProductService,
    private kvpService: KvpService,
    private route: ActivatedRoute,
    private contactService: ContactService,
    private confirmationService: ConfirmationService,
    public dialogService: DialogService,
    private messageService: MessageService,
  ) {
  }

  ngOnInit(): void {


    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.editMode = params['id'] != 'new';

      this.readProductGroups();
    });
  }

  ngOnDestroy(): void {

  }

  readProductGroups() {
    this.kvpService.readGroup(KVP_GROUPS.PRODUCTGROUP).subscribe(resp => {
      this.productGroups = resp.map(({group, ...rest}) => rest);
      if (this.editMode) {
        this.initForm();
      } else {
        this.productsService.getNextNumber().subscribe(id => {
          this.newId = id.id;
          this.initForm();
        })
      }
    });
  }

  fillForm() {
    this.productsService.detailActive = 'active';
    this.productsService.listActive = '';

    this.purchasePrice = this.product.netPurchasePrice / 100;
    this.tax = this.product.tax / 100;
    this.salesPrice = this.product.netSalesPrice / 100;

    if (this.product.productGroup != "" && this.productGroups != null) {
      var idx = this.productGroups.findIndex((i) => i.key === this.product.productGroup)
      if (idx >= 0) {
        this.selectedGroup = this.productGroups[idx];
      }
    }
    this.product.netSalesPrice = parseFloat(this.product.netSalesPrice.toFixed(2));
    this.updateSalesPrice(null);

    if (this.product.vendorID > 0) {
      this.contactService.readContact(this.product.vendorID).subscribe((c) => {
        c = Object.assign(new Contact(), c);
        this.vendorName = c.DiplayName;
      });
    }
  }

  initForm() {
    if (this.editMode) {
      setTimeout(() => {
        this.product = this.productsService.getProduct(this.id);

        if (this.product) {
          this.fillForm();

          if (this.product.netPurchasePrice > 0) {
            this.plus = (this.product.netSalesPrice * 100.0 / this.product.netPurchasePrice) - 100.0;
          } else {
            this.plus = 0;
          }

        } else {
          this.router.navigate(['masterdata', 'products']);
        }
      });
    } else {
      if (!this.productsService.isCopy) {
        this.product = new Product();
        console.log(this.product);
      } else {
        this.product = Object.assign(new Product(), this.productsService.selectedProduct);
        this.product.id = 0;
        this.product.description = this.product.description + ' - Kopie';
      }
      this.productsService.isCopy = false;
      this.product.productID = this.newId;


      this.fillForm();
    }
  }

  onCopy() {
    this.productsService.isCopy = true;
    this.router.navigate(['masterdata', 'products', 'new']);
    this.productsService.detailActive = 'active';
    this.productsService.listActive = '';
  }

  onSave() {
    this.productsService.isCopy = false;

    this.product.netSalesPrice = Math.round(this.salesPrice * 100);
    this.product.netPurchasePrice = Math.round(this.purchasePrice * 100);
    this.product.tax = Math.round(this.tax * 100);

    if (this.selectedGroup && this.selectedGroup.value != "Keine Auswahl") {
      this.product.productGroup = this.selectedGroup.key;
    } else {
      this.product.productGroup = '';
    }

    if (this.editMode) {
      this.productsService.updateProduct(this.product);
      this.messageService.add({key: 'tl', severity: 'info', summary: 'Artikel wurden gespeichert.'});
    } else {
      this.productsService.addProduct(this.product);
      this.messageService.add({key: 'tl', severity: 'info', summary: 'Neuen Artikel angelegt.'});
    }
  }

  updateSalesPrice(event) {
    var price = this.salesPrice * ((100.0 + this.tax) / 100.0);
    this.sales2 = price.toFixed(2);
  }

  updateSalesPrice2(event, value: number) {

    var price = value * ((100.0 + this.tax) / 100.0);
    this.sales2 = price.toFixed(2);

    if (this.purchasePrice > 0) {
      this.plus = (value * 100.0 / this.purchasePrice) - 100.0;
      console.log(this.plus);
    }
  }

  updateTax(event, value: number) {
    var price = this.salesPrice * ((100.0 + value) / 100.0);
    this.sales2 = price.toFixed(2);
  }

  updateSales(event, value: number) {
    this.salesPrice = this.purchasePrice * ((100.0 + value) / 100.0);
  }

  updatePurchase(event, value: number) {
    this.salesPrice = value * ((100.0 + this.plus) / 100.0);
  }

  atof(value: string): number {
    let str = value.replace('.', '');
    str = str.replace(',', '.');
    return parseFloat(str);
  }

  ftoa(value: number | string, fractionSize: number = 2): string {
    let [integer, fraction = ''] = (value || '').toString().split('.');

    fraction =
      fractionSize > 0
        ? ',' + (fraction + '000000').substring(0, fractionSize)
        : '';

    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    if (value == 0) {
      return "0";
    }
    return integer + fraction;
  }

  onSelectSupplier() {
    this.ref = this.dialogService.open(ContactSelectComponent, {
      data: {
        role: 'supplier'
      },
      header: 'Lieferanten auswählen',
      width: '75%',
      contentStyle: {'max-height': '650px', overflow: 'auto'},
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((c: Contact) => {
      if (c) {
        c = Object.assign(new Contact(), c);
        this.product.vendorID = c.id;
        this.vendorName = c.DiplayName;
      }
    });
  }

  onDelete() {
    this.confirmationService.confirm({
      message: 'Möchten Sie das Produkt wirklich löschen?',
      header: 'Produkt löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      key: 'confirm',
      rejectVisible: true,
      icon: 'pi pi-exclamation-triangle',
      accept: () => setTimeout(() => {
        this.productsService.deleteProduct(this.product.id).subscribe((r) => {
          console.log(r);
          if (r && r.value.length > 0) {
            this.confirmationService.confirm({
              message: r.value,
              header: 'Fehler beim Löschen!',
              acceptLabel: 'OK',
              key: 'info',
              rejectVisible: false,
              accept: () => {
                console.log('log');
              }
            });
          } else {
            this.productsService.productArray = new Array();
            this.router.navigate(['masterdata', 'products']);
          }
        });
      }),
    });
  }

  New() {

  }

  Copy() {
    this.onCopy();
  };

  Save() {
    this.onSave();
  };

  Delete() {
    this.onDelete();
  }

  Back() {
    this.router.navigate(['masterdata', 'products']);
  }
}
