<p-toast position="bottom-right" key="tl"></p-toast>
<div class="surface-card  pt-4 pl-2 pr-2  shadow-2 border-round mb-2">
  <div class="surface-section p-4">
    <div class="grid">
      <div class="col-12 lg:col-2">
        <div class="mr-l">
          <div class="text-xl font-medium text-900 mb-3">Ablesekarten erzeugen</div>
          <p class="m-0 p-0 text-600 line-height-3 mr-3">
            Hier können Ablesekarten für alle Zähler erzeugt werden. Die Karten können dann ausgedruckt und an die
            Mitglieder versendet werden. Unteranschlüsse werden nicht berücksichtigt.
          </p>

        </div>
      </div>

      <div class="col-12 lg:col-10">
        <p-toolbar>
          <div class="p-toolbar-group-left">
            <p-selectButton [options]="stateOptions"
                            [(ngModel)]="value"
                            optionLabel="label"
                            optionValue="value"
                            (onChange)="onSelectionChange($event)"
            ></p-selectButton>
          </div>

          <div class="p-toolbar-group-center">

            <div class="flex align-items-center">
              <p-checkbox inputId="privacy" [binary]="true" [(ngModel)]="onlyMissing"
                          (onChange)="onSelectionChange($event)"></p-checkbox>
              <span class="ml-2 text-900">Nur für fehlende Zählerstände</span>
            </div>
          </div>
          <div class="p-toolbar-group-right">
            <p-button pRipple label="Zähler suchen" icon="pi pi-search" (onClick)="onCreateCounterList()"></p-button>
          </div>
        </p-toolbar>
        <p-card class="p-0">
          <div class="grid formgrid p-fluid">
            <div class="field mb-3 col-12 md:col-12">
              <label class="font-medium text-600">Einleitungstext</label>
              <textarea [rows]="5" pInputTextarea [(ngModel)]="counterCardTemplate.headerText" [autoResize]="true">

              </textarea>
            </div>
          </div>
          <p-button pRipple label="Speichern" icon="pi pi-save" (onClick)="saveTemplate()"></p-button>
        </p-card>

      </div>
    </div>


  </div>

  <div class="surface-section p-4" *ngIf="isCounterList">
    <p-toolbar>
      <div class="p-toolbar-group-left">
        <p-button pRipple label="Vorschau" icon="pi pi-eye" (onClick)="onPreview()"></p-button>
      </div>

      <div class="p-toolbar-group-right">
        <p-button pRipple label="Zählerkarten erzeugen" icon="pi pi-file-pdf" (onClick)="onPrintAll()"></p-button>
        <p-button *ngIf="value=='email-only'" pRipple label="Zählerkarten erzeugen und versenden"
                  icon="pi pi-envelope" (onClick)="onSendAll()"></p-button>
      </div>
    </p-toolbar>
    <p-table [value]="items" responsiveLayout="scroll" [scrollable]="true" selectionMode="single"
             styleClass="p-datatable-striped">
      <ng-template pTemplate="header">
        <tr>
          <th>Nummer</th>
          <th>Beschreibung</th>
          <th>Adresse</th>
          <th>Ort</th>
          <th>Eigentümer</th>

          <th>Zählernummer</th>
          <th style="text-align: right">Letzter Zählerstand</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{ item.accessPointID }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.city }}</td>
          <td>{{ item.address }}</td>
          <td>{{ item.owner }}</td>
          <td>{{ item.gaugeSerial }}</td>
          <td style="text-align: right">{{ item.lastCount | number:'1.2' }}</td>
        </tr>
      </ng-template>

    </p-table>
  </div>
</div>
