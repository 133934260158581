<div class="min-h-screen flex flex-column relative flex-auto pl-2">
    <div class="surface-card p-4 shadow-2 border-round">
        <div class="mb-3 flex align-items-center justify-content-between">
            <div>
                <span class="text-xl font-medium text-900">Nachricht zum Zählerablesen versenden</span>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        

                        <br *ngIf="!sendEmail" />
                        <div class="row">
                            <div class="col-md-3 col-btn">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" name="sendSMS" [(ngModel)]="sendSMS" id="2">
                                    <label class="form-check-label" for="2">SMS Nachricht versenden</label>
                                </div>

                            </div>
                            <div class="col-md-3 col-btn">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" name="sendEmail" [(ngModel)]="sendEmail" id="3">
                                    <label class="form-check-label" for="2">E-Mail Nachricht versenden</label>
                                </div>

                            </div>
                            <div class="col-md-3">
                                <div class="form-group" *ngIf="sendEmail">
                                    <label>E-Mail Betreff</label>
                                    <input type="text" name="subject" class="form-control" [(ngModel)]="subject" />
                                </div>

                            </div>
                        </div>
                        <br *ngIf="!sendEmail" />
                        <div class="row">
                            <div class="col-md-3 col-btn">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" name="onlyMissing" [(ngModel)]="onlyMissing" id="5">
                                    <label class="form-check-label" for="2">Nur an Mitglieder mit fehlendem Zählerstand senden</label>
                                </div>

                            </div>


                        </div>


                        <br />

                        <div class="row">
                            <div class="form-group">
                                <label>Nachricht</label>
                                <textarea name="notes"
                                          placeholder="SMS Nachricht max. 100 Zeichen"
                                          class="form-control mb-3"
                                          [(ngModel)]="message"
                                          style="height: 150px">
                                  </textarea>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-3 col-btn">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" name="addLink" [(ngModel)]="addLink" id="4">
                                    <label class="form-check-label" for="2">Link für Webeintrag hinzufügen</label>
                                </div>

                            </div>
                            <div class="col-md-3 col-btn">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" name="allowImage" [(ngModel)]="allowImage" id="5">
                                    <label class="form-check-label" for="2">Foto Upload erlauben</label>
                                </div>

                            </div>
                            <div class="col-md-3">
                                <div class="form-group" *ngIf="allowImage || addLink">
                                    <label>Link gültig bis</label>
                                    <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false" [locale]="globals.de"
                                                [ngModelOptions]="{standalone: true}" [(ngModel)]="validUntil" appendTo="body">
                                    </p-calendar>
                                </div>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="form-group">
                                <a class="btn btn--primary mr-2" (click)="onSend()">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M13 17H17V14L22 18.5L17 23V20H13V17M20 4H4A2 2 0 0 0 2 6V18A2 2 0 0 0 4 20H11V18H4V8L12 13L20 8V14H22V6A2 2 0 0 0 20 4M12 11L4 6H20Z" />
                                    </svg>
                                    <span>Senden ...</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
