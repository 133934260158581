import {Component, ComponentFactoryResolver, OnInit, ViewChild} from '@angular/core';
import {FileUpload} from 'primeng/fileupload';
import {Globals, KVP_GROUPS} from 'src/app/globals';
import {KvpService} from 'src/app/shared/kvp.service';
import {KvpSetting} from 'src/app/shared/kvpsetting.model';
import {DmsService} from '../dms.service';
import {MyDocument} from 'src/app/shared/document.model';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
import {DialogService} from 'primeng/dynamicdialog';
import {ConfirmationService, TreeNode} from 'primeng/api';
import {Folder} from '../folder.model';
import {FolderService} from '../folder.service';
import {FileShare} from "../file-share.model";

@Component({
  selector: 'app-dms-edit',
  templateUrl: './dms-edit.component.html',
  styleUrls: ['./dms-edit.component.css'],
  providers: [DialogService],
})
export class DmsEditComponent implements OnInit {


  @ViewChild('uploader', {static: false}) uploader: FileUpload;
  uploadedFiles: any[] = [];

  fileName: string;

  file: File;

  categories: any[];
  selectedCategory: any;

  tags: any[];
  selectedTags: any[];

  zoomScale: string = "page-fit";
  pdfSrc: string;
  imgSrc: any;

  id: number;
  doc: MyDocument = new MyDocument();
  editMode = false;

  fileLink: string;

  docDate: Date = new Date();

  isPdf: boolean;
  isImage: boolean;

  displayArchive: boolean = false;
  folderList: Folder[] = new Array();
  folder: Folder = new Folder();
  files: TreeNode[];
  selectedFile: any;

  displayShare: boolean = false;
  share: FileShare = new FileShare();
  expirationDate: Date = new Date();


  constructor(private kvpService: KvpService,
              public globals: Globals,
              private route: ActivatedRoute,
              private router: Router,
              public datepipe: DatePipe,
              public dialogService: DialogService,
              private confirmationService: ConfirmationService,
              private sanitizer: DomSanitizer,
              private dmsService: DmsService,
              private folderService: FolderService) {
  }

  ngOnInit(): void {
    this.isPdf = false;
    this.isImage = false;

    this.kvpService.readGroup(KVP_GROUPS.DOC_TYPES).subscribe(resp => {
      this.categories = resp.map(({group, ...rest}) => rest);
      ;
    });

    this.kvpService.readGroup(KVP_GROUPS.TAGS).subscribe(resp => {
      this.tags = resp.map(({group, ...rest}) => rest);
      ;
    });

    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.editMode = params['id'] != 'new';


      if (this.editMode) {
        this.dmsService.readDocument(this.id).subscribe(doc => {
          this.doc = doc;
          console.log(this.doc);
          this.initForm();
        })
      } else {
        this.initForm();
      }
    });

    this.readFolders();
  }

  onUpload(event) {
    this.onSave();
  }

  onSelect(event) {
    this.fileName = this.uploader.files[0].name;

    const file = this.uploader.files[0];
    const fileType = file.type;

    if (this.doc.name && this.doc.name === "") {
      this.doc.name = this.fileName;
    }

    if (this.fileName.toUpperCase().includes(".PDF")) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.pdfSrc = e.target.result;
        this.isPdf = true;
      };

      reader.readAsArrayBuffer(event.files[0]);

      this.file = event.files[0];


    } else if (fileType.split('/')[0] === 'image') {
      const reader = new FileReader();
      reader.onloadend = (e: any) => {
        this.imgSrc = e.target.result;
        this.isImage = true;
      };
      reader.readAsDataURL(event.files[0]);

      this.file = event.files[0];

    } else {
      this.file = event.files[0];
      this.isPdf = false;
      this.isImage = false;
    }
  }

  onSave() {

    this.doc.documentDate2 = Math.round(this.docDate.getTime() / 1000);

    if (this.selectedTags) {
      var tags = "";
      this.selectedTags.forEach(element => {
        if (tags != "") {
          tags += ',';
        }
        tags += element.key;
      });
      this.doc.tags = tags;
    } else {
      this.doc.tags = "";
    }

    if (this.editMode) {
      console.log(this.doc);
      this.dmsService.updateDocument(this.doc);
    } else {
      this.dmsService.addDocument(this.doc, this.file);
    }
  }

  initForm() {
    this.pdfSrc = null;
    this.imgSrc = null;
    if (!this.editMode) {
      this.doc = new MyDocument();
      this.docDate = new Date();
    } else {
      this.docDate = new Date(this.doc.documentDate2 * 1000);
      if (this.doc.path.toUpperCase().includes(".PDF")) {
        this.isPdf = true;
        this.isImage = false;
      } else if (this.doc.path.toUpperCase().includes(".PNG") || this.doc.path.toUpperCase().includes(".JPG") || this.doc.path.toUpperCase().includes(".JPEG") || this.doc.path.toUpperCase().includes(".GIF")) {
        this.isImage = true;
        this.isPdf = false;
      } else {
        this.isPdf = false;
        this.isImage = false;
      }

    }
  }

  onShowFile() {
    this.dmsService.downloadDocument(this.doc.id).subscribe(resp => {
      console.log(resp.body);
      var downloadURL = URL.createObjectURL(resp.body);
      console.log(downloadURL);
      if (this.isPdf) {
        this.pdfSrc = downloadURL;
      } else if (this.isImage) {
        this.imgSrc = this.sanitizer.bypassSecurityTrustUrl(downloadURL);

      }
    });
  }

  onDownload() {
    this.dmsService.downloadDocument(this.doc.id).subscribe(resp => {
      var downloadURL = URL.createObjectURL(resp.body);

      var fileLink = document.createElement('a');
      fileLink.href = downloadURL;

      // it forces the name of the downloaded file
      fileLink.download = this.doc.path;

      // triggers the click event
      fileLink.click();
      //window.open(downloadURL,);
    })

  }

  onPreview() {
    this.fileLink = this.globals.APP_URL + "document/download/" + this.doc.path;
    this.onShowFile();
  }

  onDelete() {
    if (this.editMode && this.doc != null && this.doc.id > 0) {
      this.confirmationService.confirm({
        message: 'Möchten Sie dieses Dokument unwiderruflich löschen?',
        header: 'Dokument löschen',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.dmsService.deleteDocument(this.doc.id);
          this.router.navigate(['services', 'dms', 'postbox']);
        },
      });

    } else {
      this.editMode = false;
      this.initForm();
    }
  }

  Back() {
    this.router.navigate(['services', 'dms', 'postbox']);

  }

  Delete() {
    this.onDelete();
  }

  Save() {
    this.onSave();
  }

  Archive() {
    this.displayArchive = true;
  }

  OnArchiveSelected() {
    console.log(this.selectedFile);
    var folderId = Number.parseInt(this.selectedFile.key);
    this.dmsService.archiveDocument(this.doc.id, folderId).subscribe(resp => {
      console.log(resp);
    });
    this.displayArchive = false;
  }

  readFolders() {
    this.files = new Array();
    var node = {
      key: '0',
      label: 'Start',
      icon: 'pi pi-fw pi-home',
      expanded: true,
      children: [],
    };

    this.folderService.getItemsRaw("", 1000, 1).subscribe(resp => {
      this.folderList = resp.body.slice();
      console.log(this.folderList);

      this.folderList.filter(i => i.parentFolderID == 0).forEach(item => {
        var n = {
          key: item.id.toString(),
          label: item.name,
          icon: 'pi pi-fw pi-folder',
          data: item,
          children: this.foldersToTreeNodes(this.folderList, item.id),
        }
        node.children.push(n);
        //  this.fillFoldereRecursive(n);
      });

      this.files.push(node);

      //this.files = this.foldersToTreeNodes(this.folderList, null);
    });
  }

  fillFoldereRecursive(node: TreeNode) {
    this.folderList.filter(i => i.parentFolderID == node.data.id).forEach(item => {
      console.log(item);
      var n = {
        key: item.id.toString(),
        label: item.name,
        icon: 'pi pi-fw pi-folder',
        data: item,
        children: [],
      };
      node.children.push(n);
      this.fillFoldereRecursive(n);
    });


  }

  foldersToTreeNodes(folders: Folder[], parent_id: number | null = null): TreeNode[] {
    return folders
      .filter((folder) => folder.parentFolderID === parent_id)
      .map((folder) => ({
        key: folder.id.toString(),
        label: folder.name,
        data: folder,
        icon: 'pi pi-fw pi-folder',
        children: this.foldersToTreeNodes(folders, folder.id),
      }));
  }

  Share() {
    this.share = new FileShare();
    this.share.documentId = this.doc.id;
    this.share.description = this.doc.name;
    this.share.created2 = Math.round(new Date().getTime() / 1000);
    this.expirationDate = new Date();

    this.expirationDate.setDate(this.expirationDate.getDate() + 7);

    this.displayShare = true;
  }

  onShareFile() {
    this.displayShare = false;
    this.share.expirationDate2 = Math.round(this.expirationDate.getTime() / 1000);
    this.dmsService.postShare(this.share).subscribe(resp => {
      console.log(resp);
    });
  }
}
