<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmDialog key="info" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-toast position="bottom-right" key="tl"></p-toast>
<div class="mb-3 flex align-items-center justify-content-between">
  <div>
    <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
    <span class="text-xl font-medium text-900 mx-3">Artikel</span>
  </div>
  <div>
    <button pButton pRipple label="Löschen" icon="pi pi-trash" (click)="Delete()" class="mx-3"></button>
    <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="Save()"></button>
  </div>
</div>


<form #productData="ngForm">
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label>Artikelnummer</label>
        <input type="text" name="productid" class="form-control" [(ngModel)]="product.productID"/>
      </div>
    </div>

    <div class="col-md-9">
      <div class="form-group">
        <label>Bezeichnung</label>
        <input type="text" name="description" placeholder="" class="form-control" [(ngModel)]="product.description"/>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>Artikeltext</label>
        <textarea type="text" name="productText" class="form-control" style="resize: none; height: 100px" rows="3"
                  [(ngModel)]="product.productText"></textarea>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
        <label>Produktgruppe</label>
        <p-dropdown [options]="productGroups" name="selectedGroup" optionLabel="key"
                    [(ngModel)]="selectedGroup"></p-dropdown>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-1">
      <div class="form-group">
        <label>Einheit</label>
        <input type="text" name="unit" class="form-control" [(ngModel)]="product.unit"/>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>Einkaufspreis</label>
        <p-inputNumber [(ngModel)]="purchasePrice" mode="currency" currency="EUR" locale="de-DE"
                       (onInput)="updatePurchase($event.orignalEvent,$event.value)"
                       name="netPurchasePrice"></p-inputNumber>

      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>Plus %</label>
        <p-inputNumber [(ngModel)]="plus" suffix=" %" locale="de-DE"
                       (onInput)="updateSales($event.orignalEvent,$event.value)" name="plus"></p-inputNumber>

      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>= Netto VK</label>
        <p-inputNumber [(ngModel)]="salesPrice" mode="currency" currency="EUR" locale="de-DE"
                       (onInput)="updateSalesPrice2($event.orignalEvent,$event.value)"
                       name="netSalesPrice"></p-inputNumber>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>+ Steuer %</label>
        <p-inputNumber [(ngModel)]="tax" suffix=" %" locale="de-DE"
                       (onInput)="updateTax($event.orignalEvent,$event.value)" name="tax"></p-inputNumber>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>= Bruttopreis</label>
        <p-inputNumber [(ngModel)]="sales2" mode="currency" currency="EUR" locale="de-DE" name="sales2"
                       readonly="true"></p-inputNumber>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label>Bestellnummer</label>
        <input type="text" name="orderNumber" class="form-control" [(ngModel)]="product.orderNumber"/>
      </div>
    </div>

    <div class="col-md-5">
      <div class="form-group">
        <label>Lieferant</label>
        <input type="text" name="supplier" readonly class="form-control" [(ngModel)]="vendorName"/>
      </div>
    </div>

    <div class="col-md-4 col-btn">
      <div class="form-group my-2">
        <h6 class="add-user-button d-flex justify-content-left" (click)="onSelectSupplier()">
          <svg width="24" height="24" viewBox="0 4 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18 18.5C18.83 18.5 19.5 17.83 19.5 17C19.5 16.17 18.83 15.5 18 15.5C17.17 15.5 16.5 16.17 16.5 17C16.5 17.83 17.17 18.5 18 18.5M19.5 9.5H17V12H21.46L19.5 9.5M6 18.5C6.83 18.5 7.5 17.83 7.5 17C7.5 16.17 6.83 15.5 6 15.5C5.17 15.5 4.5 16.17 4.5 17C4.5 17.83 5.17 18.5 6 18.5M20 8L23 12V17H21C21 18.66 19.66 20 18 20C16.34 20 15 18.66 15 17H9C9 18.66 7.66 20 6 20C4.34 20 3 18.66 3 17H1V6C1 4.89 1.89 4 3 4H17V8H20M3 6V15H3.76C4.31 14.39 5.11 14 6 14C6.89 14 7.69 14.39 8.24 15H15V6H3M10 7L13.5 10.5L10 14V11.5H5V9.5H10V7Z"/>
          </svg>
          <span>Auswählen</span>
        </h6>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>Freifeld 1</label>
        <input type="text" name="field1" class="form-control" [(ngModel)]="product.field1"/>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
        <label>Freifeld 2</label>
        <input type="text" name="field2" placeholder="" class="form-control" [(ngModel)]="product.field2"/>
      </div>
    </div>
  </div>

  <br/>

</form>
