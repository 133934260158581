<p-dialog [(visible)]="previewDialog" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '60vw', height: '100vh'}">
    <ng-template pTemplate="header">
        <div class="flex align-items-center">
            <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style="width:32px;height:32px">
                <i class="pi pi-info text-lg"></i>
            </span>
            <span class="font-medium text-2xl text-900">{{ previewTitle }}</span>
        </div>
    </ng-template>

    <div>

            <div *ngIf="pdfSrc != null || imgSrc != null">
                
                <hr *ngIf="isPdf" />


                <div *ngIf="isPdf">
                    <ngx-extended-pdf-viewer [src]="pdfSrc" useBrowserLocale="true" height="65vh">
                    </ngx-extended-pdf-viewer>
                </div>


                <div *ngIf="isImage">
                    <img [src]="imgSrc" class="dms-img" />
                </div>
            </div>



    </div>
    <ng-template pTemplate="footer">
        <div class=" surface-border">
            <button pButton pRipple icon="pi pi-times" (click)="previewDialog = false" label="Schließen"></button>
        </div>
    </ng-template>
</p-dialog>




<div class="row">
    <input #FileSelectInputDialog type="file" hidden ([ngModel])="fileName" (change)="onFileSelected($event)" accept="image/*,application/pdf" />
    <div class="col-md-12">

        <div class="d-md-flex justify-content-between align-items-center">
            <h5 *ngIf="title.length > 0" class="card-title">{{ title }}</h5>
            <div *ngIf="title.length == 0"></div>
            
                <button pButton pRipple label="Dateianhänge speichern" icon="pi pi-save" (click)="onSaveAttachments()"></button>
            
        </div>

        <div class="card-body card-sidebar-menu">
            <div class="row" *ngFor="let a of attachments; let i = index">
                <div class="row mb-2" *ngIf="!a.isRemoved">
                    <div class="col-md-8">
                        <div class="form-group">
                            <input type="text" name="memberid" placeholder="Dateiname" class="form-control" style="cursor: pointer" (click)="onSelect(a)"
                                   [(ngModel)]="a.value.name"
                                   readonly
                                   required />
                        </div>
                    </div>
                    <div class="col-md-2">
                        <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-success" (click)="onDeleteAttachment(i)"></button>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="container d-flex my-2">
                    <button pButton pRipple label="{{ titleNew }}" icon="pi pi-cloud-upload" (click)="onAddAttachment()" class="mr-4"></button>
                    <button pButton pRipple label="Verknüpfung hinzufügen" icon="pi pi-link" (click)="onAddLink()"></button>
                </div>
            </div>
        </div>

    </div>
</div>
