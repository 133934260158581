import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Subscription } from 'rxjs';
import { take, map } from 'rxjs/Operators'
import { ObjectService } from '../shared/object.service';
import { OrganizationSettings } from '../shared/organization-settings.model';
import { DmsService } from '../services/dms/dms.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  collapsed = true;
  
  displayName : string = "DEMO";
  userName : string = "Unbekannt";
  isAutheticated : boolean  = false;
  private userSub : Subscription;
  private appSub: Subscription;

  imageSrc: any;

  constructor(private auth: AuthService,
    private objService: ObjectService,
    private dmsService: DmsService,
    private sanitizer: DomSanitizer  )
  {
    

  }

  ngOnInit(): void {
    this.userSub = this.auth.user.subscribe(user => {
      this.isAutheticated = !!user;
      if(user) {
        this.userName = user.userName;
      } else {
        this.userName = null;
      }
    });

    this.appSub = this.auth.app.subscribe(
      app => {
        if (app) {
          this.displayName = app.displayName;
          this.initLogo();
        }
      }
    );

    if (this.auth.app.value) {
      this.displayName = this.auth.app.value.displayName;
      this.initLogo();
    }

      

  }

  initLogo() {
    this.objService.load<OrganizationSettings>('organization').subscribe(resp => {
      if (resp) {
        if (resp.logoDocId > 0) {
          this.dmsService.readDocument(resp.logoDocId).subscribe((resp) => {
            if (resp != null && resp.path != null && resp.path != '') {
              this.dmsService.downloadDocument(resp.id).subscribe((r2) => {
                var downloadURL = URL.createObjectURL(r2.body);
                this.imageSrc = this.sanitizer.bypassSecurityTrustUrl(downloadURL);
              });
            }
          });
        }
      }
    });
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
    this.appSub.unsubscribe();
  }

  onLogout()
  {
    this.auth.logout();
  }
}
