<div class="min-h-screen flex surface-ground">
    <div id="app-sidebar" class="h-screen hidden lg:block flex-shrink-0 fixed lg:sticky left-0 top-0 z-1 border-right-1 border-top-1 surface-border w-full md:w-auto">


        <div class="flex h-full">

            <div class="flex flex-column surface-0 py-2 overflow-y-auto flex-shrink-0 flex-grow-1 md:flex-grow-0" style="width:280px">
                <div class="overflow-y-auto">
                    <ul class="list-none p-2 m-0">
                        <li>
                            <div class="p-3 text-500 font-medium">SUPPORT</div>
                        </li>
                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                               [routerLink]="['/help/tickets']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-comments mr-2"></i>
                                <span class="font-medium">Tickets</span>
                            </a>
                        </li>

                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                            [routerLink]="['/help/faq']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-question mr-2"></i>
                                <span class="font-medium">FAQ</span>
                            </a>
                        </li>
                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                            [routerLink]="['/help/videos']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-video mr-2"></i>
                                <span class="font-medium">Lernvideos</span>
                            </a>
                        </li>
              
                    </ul>
                    
                    
                    <ul class="list-none p-2 m-0 border-top-1 surface-border">
                        <li>
                            <div class="p-3 text-500 font-medium">ANWENDUNG</div>
                        </li>
                        <li>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                            [routerLink]="['/help/subscription']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-box mr-2"></i>
                                <span class="font-medium">Mein Abonnement</span>
                            </a>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                            [routerLink]="['/help/license']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-shield mr-2"></i>
                                <span class="font-medium">Lizenzvereinbarung</span>
                            </a>
                            <a pRipple class="flex align-items-center settings-item px-4 py-2 text-700 hover:surface-100 border-round transition-colors transition-duration-150"
                            [routerLink]="['/help/terms']" routerLinkActive="settings-item-selected">
                                <i class="pi pi-briefcase mr-2"></i>
                                <span class="font-medium">Allgemeine Geschäftsbedingungen</span>
                            </a>
                        </li>
                    </ul>
                </div>


            </div>
        </div>
    </div>

    <div id="sidebar-panel" class="min-h-screen flex flex-column relative flex-auto pl-2">


        <router-outlet></router-outlet>


    </div>

</div>
