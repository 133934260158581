<p-confirmDialog header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-toast position="bottom-right" key="tl"></p-toast>

<p-dialog [(visible)]="respDialogVisible" appendTo="body" [modal]="true"
          [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '60vw'}">

  <ng-template pTemplate="header">
    <div class="flex align-items-center">
            <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle"
                  style="width:32px;height:32px">
                <i class="pi pi-info text-lg"></i>
            </span>
      <span class="font-medium text-2xl text-900">{{ dialogTitle }}</span>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex align-items-center justify-content-between">
      <div>
        <p-button *ngIf="activeIndex===1 && !isEditPerson" icon="pi pi-arrow-circle-left" label="Zurück"
                  (click)="onStepBack()"></p-button>
      </div>

      <div>
        <p-paginator class="my-paginator" (onPageChange)="paginate($event)" [first]="firstPage"
                     [totalRecords]="totalContacts" rows="10" [hidden]="activeIndex != 0"></p-paginator>
      </div>

      <div>
        <p-button *ngIf="activeIndex===1" icon="pi pi-save" label="Speichern" (click)="onSavePerson()"></p-button>
        <p-button *ngIf="activeIndex===0" icon="pi pi-arrow-circle-right" label="Weiter" (click)="onNext()"
                  [disabled]="selectedContact==null"></p-button>
      </div>

    </div>
  </ng-template>
  <div *ngIf="!isEditPerson">
    <p-steps [model]="steps" [(activeIndex)]="activeIndex"></p-steps>

  </div>

  <div *ngIf="activeIndex === 0" class="p-fluid">
    <p-inputGroup class="mx-2">
      <input type="text" pInputText placeholder="Suchen" [(ngModel)]="searchMember" (keydown.enter)="onSearchMember()"/>
      <button type="button" pButton icon="pi pi-search" class="p-button-success" (click)="onSearchMember()"></button>
    </p-inputGroup>
    <p-table [value]="contacts" styleClass="p-datatable-striped" selectionMode="single"
             (onRowSelect)="onPersonRowSelect($event.data)" [(selection)]="selectedContact">
      <ng-template pTemplate="header">
        <tr>
          <th>Nachname</th>
          <th>Vorname</th>
          <th>Unternehmen</th>
          <th>Telefon</th>
          <th>Email</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-person>
        <tr [pSelectableRow]="person">
          <td>{{ person.lastName }}</td>
          <td>{{ person.firstName }}</td>
          <td>{{ person.orgName }}</td>
          <td>{{ person.mobile }}</td>
          <td>{{ person.email }}</td>
        </tr>
      </ng-template>
    </p-table>

  </div>

  <div *ngIf="activeIndex === 1">
    <div class="grid formgrid p-fluid">
      <div class="col-12">
        <div class="form-group">
          <label for="name">Name</label>
          <input type="text" id="name" pInputText [(ngModel)]="newResp.contactName" [disabled]="true"/>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label for="name">Reihenfolge</label>
          <p-inputNumber id="order" [(ngModel)]="newResp.respOrder"></p-inputNumber>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label></label>
          <p-checkbox binary="true" label="Aktiv" [(ngModel)]="newResp.active"></p-checkbox>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label>Beginn</label>
          <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false" [locale]="globals.de"
                      [ngModelOptions]="{standalone: true}" appendTo="body"
                      [(ngModel)]="beginDate"></p-calendar>
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label>Ende</label>
          <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false" [locale]="globals.de"
                      [ngModelOptions]="{standalone: true}" appendTo="body"
                      [(ngModel)]="endDate"></p-calendar>
        </div>
      </div>

    </div>
  </div>
</p-dialog>


<div class="mb-3 flex align-items-center justify-content-between">
  <div>
    <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
    <span class="text-xl font-medium text-900 mx-3">Wartungsarbeit: {{ entry.shortText }}</span>
  </div>
  <div>
    <button pButton pRipple label="Löschen" icon="pi pi-trash" (click)="Delete()" class="mx-3"></button>
    <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="Save()"></button>
  </div>
</div>

<div>
  <ul class="surface-card p-0 m-0 list-none flex overflow-x-auto select-none my-3">
    <li class="px-0">
      <a pRipple
         class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150"
         [ngClass]="{'tab-selected': active1 === 0, 'text-700 border-transparent no-ul': active1 !== 0}"
         (click)="active1 = 0">
        <i class="pi pi-book mr-2"></i>
        <span class="font-medium">Allgemeine Daten</span>
      </a>
    </li>
    <li class="flex align-items-center">
      <div style="width:1px; height: 50%" class="border-right-1 surface-border"></div>
    </li>
    <li class="px-3" *ngIf="editMode">
      <a pRipple
         class="cursor-pointer px-4 py-3 flex align-items-center border-bottom-2 hover:border-500 transition-colors transition-duration-150"
         [ngClass]="{'tab-selected': active1 === 1, 'text-700 border-transparent no-ul': active1 !== 1}"
         (click)="active1 = 1">
        <i class="pi pi-users mr-2"></i>
        <span class="font-medium">Verantwortliche Personen</span>
      </a>
    </li>

  </ul>
</div>

<div *ngIf="active1 == 0">
  <form #f="ngForm">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Anlagenteil</label>
          <p-inputGroup>
            <input type="text" name="eqName" class="form-control" [(ngModel)]="equipmentName" required readonly/>
            <button type="button" pButton icon="pi pi-search" class="p-button-success"
                    (click)="onSelectEquipment()"></button>
          </p-inputGroup>
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label>Intervall</label>
          <p-dropdown [options]="globals.serviceIntevals" name="eqIntervall" optionLabel="key"
                      [(ngModel)]="selectedInterval"></p-dropdown>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Wartungsbuch</label>
          <p-checkbox name="isLogbook" label="Eintragen" binary="true" [(ngModel)]="entry.addToLogBook"></p-checkbox>

        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Aktiv</label>
          <p-checkbox name="active" binary="true" [(ngModel)]="entry.active"></p-checkbox>

        </div>
      </div>
    </div>
    <div class="row">

      <div class="col-md-2">
        <div class="form-group">
          <label>Erinnerung</label>
          <p-checkbox name="reminder" label="Senden" binary="true" [(ngModel)]="entry.reminder"></p-checkbox>

        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label>Tage davor</label>
          <p-inputNumber name="reminderInDays" [(ngModel)]="entry.reminderInDays"
                         [disabled]="!entry.reminder"></p-inputNumber>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Bezeichnung</label>
          <input type="text" name="eqShortName" class="form-control" [(ngModel)]="entry.shortText" required/>
        </div>

      </div>

    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Beschreibung</label>
          <textarea type="text" name="equipmentText" class="form-control" style="resize: none; height: 200px" rows="6"
                    [(ngModel)]="entry.description"></textarea>
        </div>
      </div>

    </div>

    <div class="row">

      <div class="col-md-4">
        <div class="form-group">
          <label>Name Zahlenfeld 1</label>
          <input type="text" name="eqNumberField1" class="form-control" [(ngModel)]="entry.numberFieldName1" required/>
        </div>

      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label>Name Zahlenfeld 2</label>
          <input type="text" name="eqNumberField2" class="form-control" [(ngModel)]="entry.numberFieldName2" required/>
        </div>

      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label>Name Zahlenfeld 3</label>
          <input type="text" name="eqNumberField3" class="form-control" [(ngModel)]="entry.numberFieldName3" required/>
        </div>

      </div>

    </div>

    <div class="row">

      <div class="col-md-4">
        <div class="form-group">
          <label>Name Textfeld 1</label>
          <input type="text" name="eqTextField1" class="form-control" [(ngModel)]="entry.textFieldName1" required/>
        </div>

      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label>Name Textfeld 2</label>
          <input type="text" name="eqTextField2" class="form-control" [(ngModel)]="entry.textFieldName2" required/>
        </div>

      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label>Name Textfeld 3</label>
          <input type="text" name="eqTextField3" class="form-control" [(ngModel)]="entry.textFieldName3" required/>
        </div>

      </div>

    </div>
  </form>

</div>

<div *ngIf="active1 == 1">
  <div class="surface-card">

    <p-table [value]="responsible" styleClass="p-datatable-striped"
             (onRowSelect)="onPersonRowSelect($event.data)">
      <ng-template pTemplate="header">
        <tr>
          <th>Name</th>
          <th>Reihenfolge</th>
          <th>Aktiv</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-person>
        <tr [pSelectableRow]="person">
          <td>{{ person.contactName }}</td>
          <td>{{ person.respOrder }}</td>
          <td *ngIf="person.active">
            <i class="pi pi-check"></i>
          </td>
          <td *ngIf="!person.active">
            <i class="pi pi-times"></i>
          </td>

          <td>
            <button pButton pRipple icon="pi pi-pencil" (click)="onEditPerson(person)"></button>
            <button pButton pRipple icon="pi pi-trash" (click)="onDeletePerson(person)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>


    <p-button class="mt-4" label="Hinzufügen" icon="pi pi-plus" (click)="onNewPerson()"></p-button>
  </div>
</div>

