import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Contact } from '../../shared/contact.model';
import { MemberService } from '../member.service';

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MemberListComponent implements OnInit, OnDestroy {
  subMember: Subscription;
  members: Contact[];
  selectedMember: Contact;
  isLoading = false;
  totalMembers: number = 1;
  searchString: string = '';

  constructor(private memberService: MemberService, private router: Router) { }

  ngOnInit() {
    this.totalMembers = this.memberService.totalMembers;
    this.members = this.memberService.memberArray;

    if (!this.members?.length) this.isLoading = true;

    this.subMember = this.memberService.members.subscribe((m) => {
      this.members = m;
      this.totalMembers = this.memberService.totalMembers;
      this.isLoading = false;

    });

    if (!this.members?.length) this.memberService.getMembers('', 15, 1);
  }

  ngOnDestroy() {
    this.subMember.unsubscribe();
  }


  onSelected2(e) {
    this.router.navigate(['members-module', 'members', e.data.id]);
  }

  onAddMember() {
    this.router.navigate(['members-module', 'members', 'new']);
  }

  onSubmit(form: NgForm) {
    this.searchString = form.value.searchMember;
    this.memberService.getMembers(this.searchString, 15, 1);
    //form.reset();
  }

  paginate(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    this.memberService.getMembers(this.searchString, 15, event.page + 1);
  }

  New() {
    this.onAddMember();
  }
}
