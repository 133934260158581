export class MailServerModel {
  name: string = '';
  email: string = '';

  server: string = '';
  port_tls: number = 587;
  port_ssl: number = 465;

  maxMailsPerDay: number = 400;

  password: string = '';

  isBlocked: boolean = false;

  blockingReason: string = '';

  isVerified: boolean = false;

}
