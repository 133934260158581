import { Component, OnInit, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { Globals } from 'src/app/globals';
import { KvpService } from 'src/app/shared/kvp.service';
import { KvpSetting } from 'src/app/shared/kvpsetting.model';



@Component({
  selector: 'app-dms',
  templateUrl: './dms.component.html',
  styleUrls: ['./dms.component.css']
})
export class DmsComponent implements OnInit {

  categories: any[];
  selectedCategory: string;

  @ViewChild('uploader', { static: false }) uploader: FileUpload;
  uploadedFiles: any[] = [];

  constructor(private kvpService: KvpService, private globals: Globals) { }

  ngOnInit(): void {


  }

  onUpload(event) {
    console.log(event.files);
    console.log(this.uploader);
    this.uploader.clear();
  }

  onSelect(event) {
    console.log(event);
  }

  onSubmit(f) {

  }

  onAddDoc() {

  }
}
