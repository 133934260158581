export class DocumentViewModel {
  id: number;
  name: string = "";
  docType: string = "";
  virtualPath: string = "";
  fileSize: number = 0;
  folderId: number = 0;
  folderName: string = "";
  created: number = 0;
  isFolder: boolean = false;
  externalLink: string = "";
}
