import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MessageService, SelectItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';

import { Globals } from '../../../globals';
import { CustomerSelectComponent } from '../../../masterdata/customer/customer-select/customer-select.component';
import { Contact } from 'src/app/shared/contact.model';
import { Invoice } from '../../billing/invoice.model';
import { WapService } from '../../../water-access-point/wap.service';
import { Wap } from '../../../shared/wap.model';
import { ContactService } from '../../../shared/contact.service';
import { EntitySelectComponent } from '../../../shared/entity-select/entity-select.component';
import { WaterbillSettingsService } from '../../../settings/waterbill-settings/waterbill-settings.service';
import { WaterbillSetting } from '../../../settings/waterbill-settings/waterbill-settings.model';
import { InvoiceService } from '../../billing/invoice.service';
import { Waterbill } from '../../../shared/waterbill.model';
import { WaterbillService } from '../waterbill.service';


@Component({
  selector: 'app-waterbill-create',
  templateUrl: './waterbill-create.component.html',
  styleUrls: ['./waterbill-create.component.css'],
  providers: [DialogService, MessageService]
})
export class WaterbillCreateComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    public dialogService: DialogService,
    public contactService: ContactService,
    public wapService: WapService,
    public waterbillSettingsService: WaterbillSettingsService,
    private invoiceService: InvoiceService,
    private waterbillService: WaterbillService,
    private router: Router,
    public globals: Globals)
  { }

  ref: DynamicDialogRef;
  invoice: Invoice = new Invoice();
  wapId: number;
  currentWap: Wap;

  deliveryStart: Date;
  deliveryEnd: Date;
  invoiceDate: Date;

  selectedValue: string;

  runs: any[];
  selectedRun: any;

  billType: string;
  billTypeOverride: string;

  invoiceAddress: string = '';

  autoDetectDate: boolean = false;
  

  ngOnInit(): void {
    this.deliveryStart = new Date();
    this.deliveryStart.setFullYear(this.deliveryStart.getFullYear() - 1);
    this.deliveryEnd = new Date();
    this.invoiceDate = new Date();

    this.selectedValue = 'single';

    this.runs = [{ key: 'Rechnungslauf 1' }, { key: 'Rechnungslauf 2' }, { key: 'Vorlage auswählen' }];
    this.selectedRun = this.runs[0].key;

    this.route.queryParams
      .subscribe(params => {
        this.wapId = parseInt(params.wapid);
        this.readWap();
      });
  }

  readWap() {
    this.wapService.readWap(this.wapId).subscribe((resp) => {
      
      this.currentWap = resp;
      this.readBillingAddress();
      this.readBillingTemplate();
    });
  }

  readBillingAddress() {
    let contactId = 0;
    if (this.currentWap.invoiceContactID > 0) {
      contactId = this.currentWap.invoiceContactID;
    }
    else if (this.currentWap.ownerContactID > 0) {
      contactId = this.currentWap.ownerContactID;
    }
    if (contactId > 0) {
      this.contactService.readContact(contactId).subscribe((c) => {
        var contact = Object.assign(new Contact(), c);
        this.invoiceAddress = contact.AddressString;
      })
    } else {
      this.invoiceAddress = "Keine Rechnungsadresse hinterlegt!";
    }
  }

  readBillingTemplate() {
    let id = 0;
    if (this.selectedRun == "Rechnungslauf 1" && this.currentWap.waterbillTermsID1 > 0) {
      id = this.currentWap.waterbillTermsID1;
    } else if (this.selectedRun == "Rechnungslauf 2" && this.currentWap.waterbillTermsID2 > 0) {
      id = this.currentWap.waterbillTermsID2;
    }
    if (id > 0) {
      this.waterbillSettingsService.readItem(id).subscribe((bill) => {
        this.billType = bill.name;
      });
    }
    else {
      this.billType = '';
    }
  }

  onSelectChanged() {
    this.readBillingTemplate();
  }

  onCreateInvoice() {
    if (this.selectedRun == 'Vorlage auswählen') {
      this.ref = this.dialogService.open(EntitySelectComponent, {

        data: {
          entityService: this.waterbillSettingsService,
          columnNames: ['Name', 'Zahlungsart'],
          dataNames: [{ 'name': 'name', 'class': 'table-col-50' }, { 'name': 'paymentMethod', 'class': 'table-col-50' }]
        },
        header: 'Abrechnungsvorlage auswählen',
        width: '50%',
        contentStyle: { 'max-height': '650px', overflow: 'auto' },
        baseZIndex: 10000,
      });

      this.ref.onClose.subscribe((c: WaterbillSetting) => {
        if (c) {
          this.billType = c.name;
          this.billTypeOverride = c.name;
          this.doCreateInvoices(c.id, 0);
        }
      });
    }
    else {
      let run = 0;
      if (this.selectedRun == "Rechnungslauf 1")
        run = 1;
      else if (this.selectedRun == "Rechnungslauf 2")
        run = 2;
      this.doCreateInvoices(0, run);
    }
  }

  doCreateInvoices(template: number, run: number) {
    if (this.selectedValue == 'single' && this.waterbillService.selectedWaterbill) {
      let waterbills: Waterbill[] = new Array();
      waterbills.push(this.waterbillService.selectedWaterbill);
      this.invoiceService.createWaterbills(waterbills, template, this.autoDetectDate, this.deliveryStart, this.deliveryEnd, this.invoiceDate, run).subscribe((resp) => {
        console.log('invoice created');
        this.router.navigate(['jobs', 'waterbill']);
      });
    }
    else {
      this.invoiceService.createWaterbills(this.waterbillService.waterbills, template, this.autoDetectDate, this.deliveryStart, this.deliveryEnd, this.invoiceDate, run).subscribe((resp) => {
        console.log('invoices created');
        this.router.navigate(['jobs', 'waterbill']);
      });
    }
    
  }

}
