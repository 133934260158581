import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { EntityBase } from '../entity.base';
import { EntityService } from '../entity.service';

@Component({
  selector: 'app-entity-select',
  templateUrl: './entity-select.component.html',
  styleUrls: ['./entity-select.component.css']
})
export class EntitySelectComponent implements OnInit, OnDestroy {

  //@Input('entityService') entityService: EntityService<EntityBase>;
  entityService: EntityService<EntityBase>;

  columnNames: string[];
  dataNames: string[];

  subEntity: Subscription;
  items: EntityBase[];
  totalItems: Number = 0;
  isLoading = false;
  searchString: string = '';

  constructor(public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) { }

  ngOnInit(): void {
    this.entityService = this.config.data.entityService;
    this.columnNames = this.config.data.columnNames;
    this.dataNames = this.config.data.dataNames;

    this.items = this.entityService.itemArray;
    this.totalItems = this.entityService.totalItems;

    if (!this.items?.length) this.isLoading = true;

    this.subEntity = this.entityService.itemList.subscribe((m) => {
      this.items = m;
      this.totalItems = this.entityService.totalItems;
      this.isLoading = false;
    });

    if (!this.items?.length) this.entityService.getItems('', 10, 1);
  }

  ngOnDestroy(): void {
    this.subEntity.unsubscribe();
  }

  onSubmit(form: NgForm) {
    this.searchString = form.value.search;
    this.entityService.getItems(this.searchString, 20, 1);
  }

  onSelected(equ: EntityBase) {
    this.ref.close(equ);
  }

  paginate(event) {
    this.entityService.getItems(this.searchString, 10, event.page + 1);
  }
}
