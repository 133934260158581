<div class="mb-3 flex align-items-center justify-content-between">
  <div>
    <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
    <span class="text-xl font-medium text-900 mx-3">Rechnung</span>
  </div>
  <div *ngIf="invoice.id > 0">
    <button pButton pRipple label="Drucken" icon="pi pi-print" (click)="print2()" class="mx-3"></button>
    <button pButton pRipple label="Versenden" icon="pi pi-send" (click)="onPrint()" class="mx-3"></button>
    <p-checkbox [(ngModel)]="doAll" [binary]="true" label="Aktion für alle ausgewählten Rechnungen durchführen"
                class="mb-1 ml-3"></p-checkbox>
  </div>
  <div>

  </div>
</div>


<div class="card">
  <div class="card-header d-md-flex justify-content-between align-items-center">
    <h5 class="card-title">Rechnung</h5>


    <div class="btn btn-delete" (click)="onStorno()">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M11.5,10C14,10 16,12 16,14.5C16,17 14,19 11.5,19C9,19 7,17 7,14.5C7,12 9,10 11.5,10M11.5,11.5C10.94,11.5 10.42,11.65 10,11.92L14.08,16C14.35,15.58 14.5,15.06 14.5,14.5A3,3 0 0,0 11.5,11.5M8.5,14.5A3,3 0 0,0 11.5,17.5C12.06,17.5 12.58,17.35 13,17.08L8.92,13C8.65,13.42 8.5,13.94 8.5,14.5Z"/>
      </svg>
      <span>Rechnung stornieren</span>
    </div>


  </div>

  <div>
    <ngx-extended-pdf-viewer [src]="pdfSrc" useBrowserLocale="true"
                             (pagesLoaded)="onPagesLoaded($event)"
                             (afterPrint)="onPagesPrinted($event)">
    </ngx-extended-pdf-viewer>
  </div>


</div>
