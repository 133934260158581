<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
<p-toast position="bottom-right" key="tl"></p-toast>

<div class="mb-3 flex align-items-center justify-content-between">
  <div>
    <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
    <span class="text-xl font-medium text-900 mx-3">Abrechnungsvorlage</span>
  </div>
  <div>
    <button pButton pRipple label="Löschen" icon="pi pi-trash" (click)="Delete()" class="mx-3"></button>
    <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="Save()"></button>
  </div>
</div>

<form #f="ngForm">


  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>Name</label>
        <input type="text" name="waterbillName" class="form-control" required [(ngModel)]="entity.name"/>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>ID</label>
        <input type="text" name="id" class="form-control" required [(ngModel)]="entity.id" readonly/>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>Einleitungstext</label>
        <textarea name="headerText"
                  class="form-control mb-3"
                  [(ngModel)]="entity.header"
                  style="height:70px;"></textarea>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>Fußtext</label>
        <textarea name="footerText"
                  class="form-control mb-3"
                  [(ngModel)]="entity.footer"
                  style="height:70px;"></textarea>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label>Artikel für Verbrauchsmenge</label>
        <input type="text" name="waterArticle" class="form-control" required readonly [(ngModel)]="waterProduct"/>
      </div>
    </div>

    <div class="col-md-2 col-btn ">
      <div class="form-group">
        <h6 class="add-user-button d-flex justify-content-left" (click)="onSelectProduct(1)">
          <svg width="24" height="24" viewBox="0 4 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M19 6V5A2 2 0 0 0 17 3H15A2 2 0 0 0 13 5V6H11V5A2 2 0 0 0 9 3H7A2 2 0 0 0 5 5V6H3V20H11.81A6.59 6.59 0 0 1 10.5 18H5V8H19V9.5A6.59 6.59 0 0 1 21 10.81V6M20.31 17.9A4.5 4.5 0 1 0 18.88 19.32L22 22.39L23.39 21M16.5 18A2.5 2.5 0 1 1 19 15.5A2.5 2.5 0 0 1 16.5 18Z"/>
          </svg>
          <span>Auswählen</span>
        </h6>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>Freimenge [m³]</label>
        <input type="number" name="freeUsage" class="form-control" required [(ngModel)]="entity.freeUsage"/>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>Rabatt [%]</label>
        <input type="number" name="discount" class="form-control" required [(ngModel)]="entity.waterDiscount"/>
      </div>
    </div>


  </div>

  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label>Artikel für Kontingent Überschreitung</label>
        <input type="text" name="QuotaArticle" class="form-control" required readonly [(ngModel)]="quotaProduct"/>
      </div>
    </div>

    <div class="col-md-2 col-btn ">
      <div class="form-group">
        <h6 class="add-user-button d-flex justify-content-left" (click)="onSelectProduct(4)">
          <svg width="24" height="24" viewBox="0 4 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M19 6V5A2 2 0 0 0 17 3H15A2 2 0 0 0 13 5V6H11V5A2 2 0 0 0 9 3H7A2 2 0 0 0 5 5V6H3V20H11.81A6.59 6.59 0 0 1 10.5 18H5V8H19V9.5A6.59 6.59 0 0 1 21 10.81V6M20.31 17.9A4.5 4.5 0 1 0 18.88 19.32L22 22.39L23.39 21M16.5 18A2.5 2.5 0 1 1 19 15.5A2.5 2.5 0 0 1 16.5 18Z"/>
          </svg>
          <span>Auswählen</span>
        </h6>
      </div>
    </div>


    <div class="col-md-2">
      <div class="form-group">
        <label>Rabatt [%]</label>
        <input type="number" name="discountQuota" class="form-control" required
               [(ngModel)]="entity.exeedQuotaDiscount"/>
      </div>
    </div>


  </div>

  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label>Artikel für Bereitstellungsgebühr</label>
        <input type="text" name="feeArticle" class="form-control" required readonly [(ngModel)]="feeProduct"/>
      </div>
    </div>

    <div class="col-md-2 col-btn ">
      <div class="form-group">
        <h6 class="add-user-button d-flex justify-content-left" (click)="onSelectProduct(2)">
          <svg width="24" height="24" viewBox="0 4 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M19 6V5A2 2 0 0 0 17 3H15A2 2 0 0 0 13 5V6H11V5A2 2 0 0 0 9 3H7A2 2 0 0 0 5 5V6H3V20H11.81A6.59 6.59 0 0 1 10.5 18H5V8H19V9.5A6.59 6.59 0 0 1 21 10.81V6M20.31 17.9A4.5 4.5 0 1 0 18.88 19.32L22 22.39L23.39 21M16.5 18A2.5 2.5 0 1 1 19 15.5A2.5 2.5 0 0 1 16.5 18Z"/>
          </svg>
          <span>Auswählen</span>
        </h6>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>Menge</label>
        <input type="number" name="feeCount" class="form-control" required [(ngModel)]="entity.feeCount"/>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>Rabatt [%]</label>
        <input type="number" name="feeDiscount" class="form-control" required [(ngModel)]="entity.feeDiscount"/>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label>Zusätzliche Artikelposition</label>
        <input type="text" name="additionalArticle" class="form-control" required readonly [(ngModel)]="addProduct"/>
      </div>
    </div>

    <div class="col-md-2 col-btn ">
      <div class="form-group">
        <h6 class="add-user-button d-flex justify-content-left" (click)="onSelectProduct(3)">
          <svg width="24" height="24" viewBox="0 4 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M19 6V5A2 2 0 0 0 17 3H15A2 2 0 0 0 13 5V6H11V5A2 2 0 0 0 9 3H7A2 2 0 0 0 5 5V6H3V20H11.81A6.59 6.59 0 0 1 10.5 18H5V8H19V9.5A6.59 6.59 0 0 1 21 10.81V6M20.31 17.9A4.5 4.5 0 1 0 18.88 19.32L22 22.39L23.39 21M16.5 18A2.5 2.5 0 1 1 19 15.5A2.5 2.5 0 0 1 16.5 18Z"/>
          </svg>
          <span>Auswählen</span>
        </h6>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>Menge</label>
        <input type="number" name="addCount" class="form-control" required [(ngModel)]="entity.addCount"/>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>Rabatt [%]</label>
        <input type="number" name="addDiscount" class="form-control" required [(ngModel)]="entity.addDiscount"/>
      </div>
    </div>
  </div>

  <br/>
  <div class="row">
    <div class="col-md-3">
      <div class="form-group">
        <label>Skonto [%]</label>
        <input type="number" name="scontoPercent" placeholder="%" class="form-control" [(ngModel)]="entity.skonto"/>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Skonto [Tage]</label>
        <input type="number" name="scontoDays" placeholder="Tage" class="form-control" [(ngModel)]="entity.skontoDays"/>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Zahlungsziel [Tage]</label>
        <input type="number" name="paymentDays" placeholder="Tage" class="form-control"
               [(ngModel)]="entity.paymentDays"/>
      </div>
    </div>
    <div class="col-md-3">
      <div class="form-group">
        <label>Zahlungsart</label>
        <select class="form-control" name="paymentMethode" id="paymentMethode" [(ngModel)]="entity.paymentMethod">
          <option *ngFor="let pm of this.paymentMethods" [value]="pm.key">{{ pm.key }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row my-2">
    <div class="col-md-6 col-btn">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" name="active" [(ngModel)]="entity.printGaugeData" id="2">
        <label class="custom-control-label" for="2">Zählerdaten auf Rechnung drucken</label>

      </div>
    </div>
  </div>

</form>

<div class="mx-4">
  <hr/>
  <app-dms-attachments #attachments titleNew="Anhang hinzufügen" title="Rechnungsanhang" tableName="WaterbillTerms"
                       [PK]="id"></app-dms-attachments>
</div>


