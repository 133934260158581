<div class="surface-card  pt-4 pl-2 shadow-2 border-round mb-2" *ngIf="!isLoading">
  <div class="surface-section p-4">
    <div class="grid">
      <div class="col-12 lg:col-2">
        <div class="text-900 font-medium text-xl mb-3">Buchungsabschluss</div>
        <p class="m-0 p-0 text-600 line-height-3 mr-3">
          Hier wird monatlich der Buchungsabschluss erstellt.
        </p>
      </div>
      <div class="col-12 lg:col-10">
        <div class="grid formgrid p-fluid">
          <div class="field mb-3 col-12 md:col-4">
            <label for="transactionTypes" class="font-medium text-900">Konto auswählen</label>
            <p-dropdown [options]="items" [(ngModel)]="selectedItem" optionLabel="name" appendTo="body"
                        (onChange)="onAccountChanged()"></p-dropdown>
          </div>

          <div class="field mb-3 col-12 md:col-4">
            <label for="name" class="font-medium text-900">Monat</label>
            <input id="name" type="text" pInputText [(ngModel)]="currentMec.month" autocomplete="off" readonly>
          </div>

          <div class="field mb-3 col-12 md:col-4">
            <label for="name" class="font-medium text-900">Geschäftsjahr</label>
            <input id="name" type="text" pInputText [(ngModel)]="currentMec.financialYear" autocomplete="off" readonly>
          </div>

          <div class="field mb-0 col-12 md:col-4">
            <label for="name" class="font-medium text-900">Gutschriften</label>
            <p-inputNumber [ngClass]="'pos-value'" id="earnings" ngModel="{{currentMec.earnings / 100}}"
                           [minFractionDigits]="2"></p-inputNumber>
          </div>

          <div class="field mb-0 col-12 md:col-4">
            <label for="name" class="font-medium text-900">Lastschriften</label>
            <p-inputNumber [ngClass]="'neg-value'" id="expenses" ngModel="{{currentMec.expenses / 100}}"
                           [minFractionDigits]="2"></p-inputNumber>
          </div>

          <div class="field mb-0 col-12 md:col-4">
            <label for="name" class="font-medium text-900">Kontostand per</label>
            <p-inputNumber [ngClass]="{'neg-value': currentMec.balance < 0, 'pos-value': currentMec.balance >= 0}"
                           id="balance" ngModel="{{currentMec.balance / 100 }}" [minFractionDigits]="2"
            ></p-inputNumber>
          </div>

          <div class="field mt-4 mb-0 col-4 md:col-4">

            <button *ngIf="currentMec.id == 0" pButton pRipple label="Erstellen" icon="pi pi-save"
                    (click)="onCreate()"></button>

          </div>

          <div class="field mt-4 col-4 md:col-4">


            <button *ngIf="currentMec.id != 0 && !currentMec.accepted && !currentMec.completed" pButton pRipple
                    label="Schließen" icon="pi pi-lock" (click)="onClose()"></button>
            <button *ngIf="currentMec.id != 0 && !currentMec.accepted && currentMec.completed" pButton pRipple
                    label="Öffnen" icon="pi pi-lock" (click)="onOpen()"></button>

          </div>

          <div class="field mt-4 col-4 md:col-4">


            <button *ngIf="currentMec.id != 0 && currentMec.completed && !currentMec.accepted" pButton pRipple
                    label="Abnehmen" icon="pi pi-verified" (click)="onAccept()"></button>
            <button *ngIf="currentMec.id != 0 && currentMec.completed && currentMec.accepted" pButton pRipple
                    label="Zurückgeben" icon="pi pi-verified" (click)="onReturn()"></button>

          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="px-4 pb-4">
    <div class="text-900 font-medium text-l mb-3">Letzten Buchungsabschlüsse</div>
    <p-table [value]="mecList" selectionMode="single" [(selection)]="selectedEntity" styleClass="p-datatable-striped">
      <ng-template pTemplate="header">
        <tr>
          <th>Geschäftsjahr</th>
          <th>Monat</th>
          <th>Gutschriften</th>
          <th>Lastschriften</th>
          <th>Kontostand</th>
          <th>Geschlossen</th>
          <th>Abgenommen</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-entity let-i="rowIndex">
        <tr>
          <td>{{ entity.financialYear }}</td>
          <td>{{ entity.month }}</td>
          <td>{{ entity.earnings / 100 | currency:'EUR' }}</td>
          <td>{{ entity.expenses / 100 | currency:'EUR' }}</td>
          <td [style.color]="entity.balance < 0 ? 'red' :  'green' ">{{ entity.balance / 100  | currency:'EUR' }}</td>
          <td>
            <p-tag *ngIf="entity.completed" icon="pi pi-check" severity="success"></p-tag>
            <p-tag *ngIf="!entity.completed" icon="pi pi-times" severity="danger"></p-tag>
          </td>
          <td>
            <p-tag *ngIf="entity.accepted" icon="pi pi-check" severity="success"></p-tag>
            <p-tag *ngIf="!entity.accepted" icon="pi pi-times" severity="danger"></p-tag>
          </td>
          <td style="text-align:right;">
            <button pButton pRipple
                    *ngIf="i==editIndex"
                    icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                    (click)="onEdit(entity)"></button>
          </td>

        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
