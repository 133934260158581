<div class="mb-3 flex align-items-center justify-content-between">
  <div>
    <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
    <span class="text-xl font-medium text-900 mx-3">Arbeit erfassen</span>
  </div>
  <div>
    <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="onSave()"></button>
  </div>
</div>


<form #workData="ngForm">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>Anlagenteil</label>
        <input type="text" name="eqipment" placeholder="" class="form-control" [ngModel]="equipmentName" readonly/>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
        <label>Wartungsabeit</label>
        <input type="text" name="eqshortText" placeholder="" class="form-control" [ngModel]="planEntry.shortText"
               readonly/>
      </div>
    </div>
  </div>

  <hr/>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>Durchgeführt von</label>
        <p-inputGroup>
          <input type="text" name="worker" placeholder="" class="form-control" [ngModel]="workEntry.who"/>
          <button type="button" pButton icon="pi pi-search" class="p-button-success"
                  (click)="onSelectContact()"></button>
        </p-inputGroup>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>am</label>
        <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="true" [locale]="globals.de"
                    [ngModelOptions]="{standalone: true}"
                    [(ngModel)]="workingDate">
        </p-calendar>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>Zeitaufwand in Stunden</label>
        <p-inputNumber name="hours" [(ngModel)]="workEntry.hours" mode="decimal" [minFractionDigits]="2"
                       [maxFractionDigits]="5"></p-inputNumber>

      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>Wartungsbuch</label>
        <p-checkbox name="isLogbook" binary="true" label="Eintragen" [(ngModel)]="workEntry.isLogbook"></p-checkbox>

      </div>
    </div>


  </div>

  <div class="row">
    <div class="col-md-3" *ngIf="planEntry.numberFieldName1 && planEntry.numberFieldName1 != ''">
      <div class="form-group">
        <label>{{ planEntry.numberFieldName1 }}</label>
        <input type="number" name="number1" placeholder="" class="form-control" [(ngModel)]="workEntry.numberValue1"/>
      </div>
    </div>

    <div class="col-md-3" *ngIf="planEntry.numberFieldName2 && planEntry.numberFieldName2 != ''">
      <div class="form-group">
        <label>{{ planEntry.numberFieldName2 }}</label>
        <input type="number" name="number2" placeholder="" class="form-control" [(ngModel)]="workEntry.numberValue2"/>
      </div>
    </div>

    <div class="col-md-3" *ngIf="planEntry.numberFieldName3 && planEntry.numberFieldName3 != ''">
      <div class="form-group">
        <label>{{ planEntry.numberFieldName3 }}</label>
        <input type="number" name="number3" placeholder="" class="form-control" [(ngModel)]="workEntry.numberValue3"/>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col-md-3" *ngIf="planEntry.textFieldName1 && planEntry.textFieldName1 != ''">
      <div class="form-group">
        <label>{{ planEntry.textFieldName1 }}</label>
        <input type="text" name="text1" placeholder="" class="form-control" [(ngModel)]="workEntry.textValue1"/>
      </div>
    </div>

    <div class="col-md-3" *ngIf="planEntry.textFieldName2 && planEntry.textFieldName2 != ''">
      <div class="form-group">
        <label>{{ planEntry.textFieldName2 }}</label>
        <input type="text" name="text2" placeholder="" class="form-control" [(ngModel)]="workEntry.textValue2"/>
      </div>
    </div>

    <div class="col-md-3" *ngIf="planEntry.textFieldName3 && planEntry.textFieldName3 != ''">
      <div class="form-group">
        <label>{{ planEntry.textFieldName3 }}</label>
        <input type="text" name="text3" placeholder="" class="form-control" [(ngModel)]="workEntry.textValue3"/>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>Notizen</label>
        <textarea type="text" name="workComment" class="form-control" style="resize: none; height: 200px" rows="6"
                  [(ngModel)]="workEntry.comment"></textarea>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
        <label>Information/Beschreibung</label>
        <textarea type="text" name="planDescription" class="form-control" style="resize: none; height: 200px" rows="6"
                  readonly
                  [ngModel]="planEntry.description"></textarea>
      </div>
    </div>

  </div>

  <div class="mx-0" *ngIf="workEntry.id != 0">
    <hr/>
    <app-dms-attachments #attachments titleNew="Datei hinzufügen" title="Dokumente" tableName="MaintenanceWorkEntry"
                         [PK]="id"></app-dms-attachments>
  </div>

</form>

