export class AppStatisticData {
  totalMembers: number;

  activeMembers: number;

  totalWaps: number;
  activeWaps: number;

  totalGauges: number;

  totalDocuments: number;

  diskUsage: number;

  databaseSize: number;

  totalRecords: number;


}
