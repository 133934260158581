
<div class="surface-section px-2">
    <div class="grid">
        <div class="col-12 lg:col-2">
            <div class="mr-l">
                <div class="text-xl font-medium text-900 mb-3">Freigaben</div>
                <p class="m-0 p-0 text-600 line-height-3 mr-3">
                    Hier können Dateien für Mitglieder freigegeben werden und Freigaben verwalten werden.
                </p>

            </div>
        </div>

        <div class="col-12 lg:col-10">
        </div>
    </div>
</div>

