<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmDialog key="info" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="surface-card p-4 shadow-2 border-round">
  <div class="mb-3 flex align-items-center justify-content-between">
    <div>
      <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
      <span class="text-xl font-medium text-900 mx-3">Nebenanschluss: {{ wap.accessPointID }}
        (Hauptanschluss: {{ wapService.parentWap.accessPointID }})</span>
    </div>
    <div>
      <button pButton pRipple label="Löschen" icon="pi pi-trash" (click)="Delete()" class="mx-3"></button>
      <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="Save()"></button>
    </div>
  </div>
  <div class="col-md-12">

    <div class="card">
      <div class="card-header d-md-flex ">
        <h5 class="card-title">Hauptanschluss:</h5>
        <h5 class="card-title mx-3">
          {{ wapService.parentWap.accessPointID }} ({{ wapService.parentWap.address1 }})
        </h5>

      </div>
    </div>
    <br/>

    <div class="card">
      <div class="card-header d-md-flex justify-content-end align-items-right">
        <h6 class="add-user-button d-flex align-items-center" (click)="onSave()">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M17 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V7L17 3M19 19H5V5H16.17L19 7.83V19M12 12C10.34 12 9 13.34 9 15S10.34 18 12 18 15 16.66 15 15 13.66 12 12 12M6 6H15V10H6V6Z"/>
          </svg>
          <span>Speichern</span>
        </h6>
      </div>
      <div class="card-body card-sidebar-menu" style="margin-top:-40px;">
        <form>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Nummer</label>
                <input type="text" name="wapid" placeholder="TOP" [(ngModel)]="wap.accessPointID" class="form-control"/>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>Anteil (m², %, ...)</label>
                <input type="text" name="fragment" placeholder="1" [(ngModel)]="wap.fragment" class="form-control"/>
              </div>
            </div>

            <div class="col-md-2 col-btn">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" name="drain" [(ngModel)]="wap.drainAccess" id="2">
                <label class="form-check-label" for="2">Kanalanschluss</label>
              </div>
            </div>
            <div class="col-md-1  col-btn">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" name="active" [(ngModel)]="wap.active" id="1">
                <label class="form-check-label" for="2">Aktiv</label>
              </div>

            </div>


            <hr/>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label>Beschreibung</label>
                <input type="text" name="description" placeholder="" class="form-control"
                       [(ngModel)]="wap.description"/>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Eigentümer</label>
                <div class="row">
                  <div class="col-md-8">
                    <input type="text" name="owner" placeholder="" readonly="true" class="form-control"
                           value="{{ ownerName
          }}"/>
                  </div>

                  <div class="col-md-4">
                    <div class="row justify-content-left my-2">
                      <h6 class="add-user-button d-flex justify-content-left" (click)="onSelectMember()">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M10,13C9.65,13.59 9.36,14.24 9.19,14.93C6.5,15.16 3.9,16.42 3.9,17V18.1H9.2C9.37,18.78 9.65,19.42 10,20H2V17C2,14.34 7.33,13 10,13M10,4A4,4 0 0,1 14,8C14,8.91 13.69,9.75 13.18,10.43C12.32,10.75 11.55,11.26 10.91,11.9L10,12A4,4 0 0,1 6,8A4,4 0 0,1 10,4M10,5.9A2.1,2.1 0 0,0 7.9,8A2.1,2.1 0 0,0 10,10.1A2.1,2.1 0 0,0 12.1,8A2.1,2.1 0 0,0 10,5.9M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14Z"/>
                        </svg>
                        <span>Auswählen</span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label>Rechnungsadresse</label>
                <div class="row">
                  <div class="col-md-8">
                    <input type="text" name="invoice" placeholder="" readonly="true" class="form-control"
                           value="{{ invoiceName }}"/>
                  </div>

                  <div class="col-md-4">
                    <div class="row justify-content-left my-2">
                      <h6 class="add-user-button d-flex justify-content-left" (click)="onSelectInvoice()">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M10,13C9.65,13.59 9.36,14.24 9.19,14.93C6.5,15.16 3.9,16.42 3.9,17V18.1H9.2C9.37,18.78 9.65,19.42 10,20H2V17C2,14.34 7.33,13 10,13M10,4A4,4 0 0,1 14,8C14,8.91 13.69,9.75 13.18,10.43C12.32,10.75 11.55,11.26 10.91,11.9L10,12A4,4 0 0,1 6,8A4,4 0 0,1 10,4M10,5.9A2.1,2.1 0 0,0 7.9,8A2.1,2.1 0 0,0 10,10.1A2.1,2.1 0 0,0 12.1,8A2.1,2.1 0 0,0 10,5.9M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14Z"/>
                        </svg>
                        <span>Auswählen</span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-11">
                    <input type="text" name="owner" placeholder="" readonly="true" class="form-control"
                           value="{{ ownerAddress }}"/>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-11">
                    <input type="text" name="invoice" placeholder="" readonly="true" class="form-control"
                           value="{{ invoiceAddress }}"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>


    <br/>
    <div class="card">
      <div class="card-header d-md-flex justify-content-between align-items-center">
        <h5 class="card-title">Wasserzähler</h5>
        <h6 class="add-user-button d-flex align-items-center" (click)="onSave()">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M4,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4M4,6V18H11V6H4M20,18V6H18.76C19,6.54 18.95,7.07 18.95,7.13C18.88,7.8 18.41,8.5 18.24,8.75L15.91,11.3L19.23,11.28L19.24,12.5L14.04,12.47L14,11.47C14,11.47 17.05,8.24 17.2,7.95C17.34,7.67 17.91,6 16.5,6C15.27,6.05 15.41,7.3 15.41,7.3L13.87,7.31C13.87,7.31 13.88,6.65 14.25,6H13V18H15.58L15.57,17.14L16.54,17.13C16.54,17.13 17.45,16.97 17.46,16.08C17.5,15.08 16.65,15.08 16.5,15.08C16.37,15.08 15.43,15.13 15.43,15.95H13.91C13.91,15.95 13.95,13.89 16.5,13.89C19.1,13.89 18.96,15.91 18.96,15.91C18.96,15.91 19,17.16 17.85,17.63L18.37,18H20M8.92,16H7.42V10.2L5.62,10.76V9.53L8.76,8.41H8.92V16Z"/>
          </svg>
          <span>Neuen Zählerstand speichern</span>
        </h6>
      </div>
      <div class="card-body card-sidebar-menu">
        <form #counterData="ngForm">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Zählertyp</label>
                <input type="text" name="counterType" placeholder="" readonly="true" class="form-control" ngModel/>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>Zählerstand alt</label>
                <input type="text" name="counterCounts" placeholder="" readonly="true" class="form-control" ngModel/>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>Ablesedatum</label>
                <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="true" [locale]="globals.de"
                            [ngModelOptions]="{standalone: true}"
                            [disabled]="!isGauge"
                            [(ngModel)]="readingDate">
                </p-calendar>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label>Zählerstand neu</label>
                <input type="text" name="newcounts" [readonly]="!isGauge" placeholder="" class="form-control" ngModel/>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>Seriennummer</label>
                <input type="text" name="serial" placeholder="" readonly="true" class="form-control" ngModel/>
              </div>
            </div>
            <div class="col-md-9 align-self-end align-items-right">
              <div class="form-group">
                <a class="btn btn--primary mr-2" (click)="onSelectCounter()">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M15 6.5V9H11V11H15V13.5L18.5 10L15 6.5M9 10.5L5.5 14L9 17.5V15H13V13H9V10.5Z"/>
                  </svg>
                  <span>Tauschen</span>
                </a>
              </div>
            </div>


          </div>
        </form>
      </div>
    </div>


  </div>
</div>
