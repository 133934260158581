export class MaintenancePlanEntry {

  id: number;

  equipmentId: number;
  interval: string;

  active: boolean;

  shortText: string;
  description: string;

  textFieldName1: string;
  textFieldName2: string;
  textFieldName3: string;

  numberFieldName1: string;
  numberFieldName2: string;
  numberFieldName3: string;

  addToLogBook: boolean = true;
  reminder: boolean = false;
  reminderInDays: number = 0;

  constructor() {
    this.id = 0;
    this.active = true;

    this.shortText = "Neue Aufgabe";
    this.description = "";

    this.textFieldName1 = '';
    this.textFieldName2 = '';
    this.textFieldName3 = '';

    this.numberFieldName1 = '';
    this.numberFieldName2 = '';
    this.numberFieldName3 = '';
  }
}

export class MaintenancePlanEntryViewModel {
  planEntryId: number = 0;
  taksId: number = 0;
  text: string = '';
  intervall: string = '';
  equipmentName: string = '';
  dueDate: number = 0;
  lastDone: number = 0;
  isTask: boolean = false;
}
