<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle">

</p-confirmDialog>
<p-confirmPopup/>
<p-toast position="bottom-right" key="tl"></p-toast>

<p-dialog [(visible)]="display" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
          [style]="{width: '50vw'}">
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
            <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle"
                  style="width:32px;height:32px">
                <i class="pi pi-info text-lg"></i>
            </span>
      <span class="font-medium text-2xl text-900">{{ dialogTitle }}</span>
    </div>
  </ng-template>

  <div class="grid formgrid p-fluid">
    <div class="field mb-3 col-6" [hidden]="editMode">
      <label for="transactionTypes" class="font-medium text-900">Transaktionstyp</label>
      <p-dropdown [options]="globals.transactionTypes" [(ngModel)]="selectedTransaction.transactionType"
                  optionLabel="name" optionValue="value" appendTo="body"></p-dropdown>
    </div>
    <div class="field mb-3 col-6" [hidden]="editMode || selectedTransaction.transactionType != 1">
      <label for="destAccount" class="font-medium text-900">Konto</label>
      <p-dropdown [options]="items" optionLabel="name" optionValue="id" appendTo="body"
                  [(ngModel)]="destAccountId"></p-dropdown>
    </div>
    <div class="field mb-3 col-6" [hidden]="editMode || selectedTransaction.transactionType == 1">
    </div>


    <div class="field mb-3 col-6">
      <label for="tart" class="font-medium text-900">Art</label>
      <div class="">
        <input id="tart" type="text"
               pInputText value="{{transactionType ? 'Ausgang' : 'Eingang'}}"
               autocomplete="off"
               readonly>
      </div>
    </div>
    <div class="field mb-3 col-6">
      <label for="costCenters" class="font-medium text-900">Kostenstelle</label>
      <p-dropdown id="costCenters" [options]="costCenters" optionLabel="key" optionValue="key" appendTo="body"
                  [(ngModel)]="selectedTransaction.costCenter"></p-dropdown>
    </div>
    <div class="field mb-3 col-4">
      <label for="name" class="font-medium text-900">Buchungsdatum</label>
      <input id="date" type="text" pInputText value="{{selectedTransaction.transactionDate2*1000 | customDate}}"
             autocomplete="off"
             readonly>
    </div>
    <div class="field mb-3 col-8">
      <label for="name" class="font-medium text-900">Buchungstext</label>
      <input id="name" type="text" pInputText [(ngModel)]="selectedTransaction.text" autocomplete="off">
    </div>

    <div class="field mb-3 col-4">
      <div>
        <label for="amount" class="font-medium text-900">Betrag</label>
        <p-inputNumber id="tax" [ngClass]="{'neg-value': transactionType, 'pos-value': !transactionType}"
                       [minFractionDigits]="2" suffix=" %" [(ngModel)]="amount"
                       readonly="true"></p-inputNumber>

      </div>
    </div>
    <div class="field mb-3 col-4">
      <div *ngIf="isSalesTax()">
        <label for="tax" class="font-medium text-900">MwSt. [%]</label>
        <p-inputNumber id="tax" [ngClass]="{'neg-value': transactionType, 'pos-value': !transactionType}"
                       [minFractionDigits]="2" suffix=" %" [(ngModel)]="selectedTransaction.taxRate1"
                       (onInput)="onInputTax()"></p-inputNumber>
      </div>
    </div>
    <div class="field mb-3 col-4">
      <div *ngIf="isSalesTax()">
        <label for="salesTax" class="font-medium text-900">MwSt. [EUR]</label>
        <p-inputNumber [ngClass]="{'neg-value': transactionType, 'pos-value': !transactionType}" id="salesTax"
                       [minFractionDigits]="2" [(ngModel)]="selectedTransaction.taxAmount1"></p-inputNumber>
      </div>
    </div>
    <div class="field col-4">
      <div *ngIf="isSalesTax()">
        <p-checkbox label="2. Steuersatz" [(ngModel)]="secondVat" [binary]="true"></p-checkbox>
      </div>
    </div>
    <div class="field col-4">
      <div *ngIf="secondVat">
        <label for="tax" class="font-medium text-900">2. MwSt. [%]</label>
        <p-inputNumber id="tax2" [ngClass]="{'neg-value': transactionType, 'pos-value': !transactionType}"
                       [minFractionDigits]="2" suffix=" %" [(ngModel)]="selectedTransaction.taxRate2"
                       (onInput)="onInputTax()"></p-inputNumber>
      </div>
    </div>
    <div class="field col-4">
      <div *ngIf="secondVat">
        <label for="salesTax" class="font-medium text-900">2. MwSt. [EUR]</label>
        <p-inputNumber [ngClass]="{'neg-value': transactionType, 'pos-value': !transactionType}" id="salesTax2"
                       [minFractionDigits]="2" [(ngModel)]="selectedTransaction.taxAmount2"></p-inputNumber>
      </div>
    </div>


    <div class="field mb-3 col-6" hidden>
      <label for="iban" class="font-medium text-900">IBAN</label>
      <input id="iban" type="text" pInputText [(ngModel)]="selectedTransaction.iban" autocomplete="off">
    </div>
    <div class="field mb-3 col-6" hidden>
      <label for="bic" class="font-medium text-900">BIC</label>
      <input id="bic" type="text" pInputText [(ngModel)]="selectedTransaction.bic" autocomplete="off">
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class=" surface-border">
      <button pButton pRipple icon="pi pi-times" (click)="display = false" label="Abbrechen"></button>
      <button pButton pRipple icon="pi pi-check" (click)="onSave()" label="Jetzt buchen"></button>
    </div>
  </ng-template>

</p-dialog>


<div class="surface-card  pt-4 pl-2 shadow-2 border-round mb-2" *ngIf="!isLoading">
  <div class="surface-section px-4 pt-4">
    <div class="grid">
      <div class="col-12 lg:col-2">
        <div *ngIf="transactionService.isBalancingStart" class="mr-l">
          <div class="text-xl font-medium text-900 mb-3">Kontoauszug abgleichen</div>
          <p class="m-0 p-0 text-600 line-height-3 mr-3">
            Hier werden Kontoauszüge importiert und offenen Posten zugeordnet.
          </p>

        </div>
      </div>

      <div class="col-12 lg:col-10">
        <div *ngIf="transactionService.isBalancingStart" class="grid formgrid p-fluid">
          <div class="field mb-3 col-12">
            <label for="transactionTypes" class="font-medium text-900">1. Konto auswählen</label>
            <p-dropdown [options]="items" optionLabel="name" appendTo="body" [(ngModel)]="selectedItem"
                        (onChange)="onSelect()"></p-dropdown>
          </div>

          <div class="field mb-3 col-6">
            <label class="font-medium text-900">2. Datei auswählen oder Daten direkt in das Feld kopieren</label><br>
            <a class="btn btn--primary mr-2" (click)="onSelectFile()">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M6.1,10L4,18V8H21A2,2 0 0,0 19,6H12L10,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H19C19.9,20 20.7,19.4 20.9,18.5L23.2,10H6.1M19,18H6L7.6,12H20.6L19,18Z"/>
              </svg>
              <span>Auswählen ...</span>
            </a>
          </div>
          <div class="field mb-3 col-6">
            <label>Trennzeichen</label>
            <div class="row">
              <div class="col-md-1">
                <input type="text" name="delimiter" placeholder="" [(ngModel)]="delimiter" class="form-control"
                       *ngIf="!useTab"/>
              </div>
              <div class="col-md-10">
                <div class="form-check my-1">
                  <input type="checkbox" class="form-check-input" name="useTabulator" [(ngModel)]="useTab" id="2">
                  <label class="form-check-label" for="2">Tabulator</label>
                </div>
              </div>

            </div>
          </div>

          <div class="field mb-3 col-12">
                        <textarea name="notes"
                                  placeholder="Spalte1 ; Spalte2 ; Spalte3 ; ..."
                                  class="form-control mb-3"
                                  [(ngModel)]="transactionService.balancingImportText"
                                  style="height: 200px;"
                                  (ngModelChange)="onTextChanged()">
                                  </textarea>
          </div>

          <div class="field col-12">
            <label>CSV-Importeinstellungen (Spaltennummern)</label>
          </div>
          <div class=" field col-2">
            <div class="form-group">
              <label>Buchungsdatum</label>
              <input type="number" name="map0" class="form-control" [(ngModel)]="csvMap[0]" autocomplete="off"/>
            </div>
          </div>
          <div class=" field col-2">
            <div class="form-group">
              <label>Buchungstext</label>
              <input type="number" name="map1" class="form-control" [(ngModel)]="csvMap[1]" autocomplete="off"/>
            </div>
          </div>
          <div class=" field col-2">
            <div class="form-group">
              <label>Betrag</label>
              <input type="number" name="map2" class="form-control" [(ngModel)]="csvMap[2]" autocomplete="off"/>
            </div>
          </div>
          <div class=" field col-2">
            <div class="form-group">
              <label>IBAN</label>
              <input type="number" name="map3" class="form-control" [(ngModel)]="csvMap[3]" autocomplete="off"/>
            </div>
          </div>
          <div class=" field col-2">
            <div class="form-group">
              <label>BIC</label>
              <input type="number" name="map4" class="form-control" [(ngModel)]="csvMap[4]" autocomplete="off"/>
            </div>
          </div>

          <div class=" flex align-items-center justify-content-between">
            <button *ngIf="billingLoaded && debitLoaded" pButton pRipple label="Daten übernehmen..." icon="pi pi-filter"
                    (click)="onImport()"></button>

            <p *ngIf="!billingLoaded || !debitLoaded" class="m-0 p-0 text-800 line-height-3 mr-3 font-medium">
              Lade offene Posten...
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div *ngIf="!transactionService.isBalancingStart" class="surface-section px-4">
    <div class="mb-3 flex align-items-center justify-content-between">
      <div>
        <button pButton pRipple label="Zurück zum Import" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
        <span class="text-xl font-medium text-900 mx-3">Kontoauszug abgleichen</span>
      </div>
      <div>
        <span class="text-xl font-bold text-900 mx-3">{{ selectedItem.name }} - {{ selectedItem.iban }}</span>
      </div>
      <div>
        <button pButton pRipple label="Alle übernehmen" icon="pi pi-check-circle" (click)="onConfirmAll()"></button>
      </div>

    </div>

    <div class="grid formgrid p-fluid">
      <div class="col-12">
        <p-table [value]="transactionService.importItems" responsiveLayout="scroll" [scrollable]="true"
                 scrollHeight="calc(100vh - 250px)" styleClass="p-datatable-striped">
          <ng-template pTemplate="header">
            <tr>
              <th>Buchungsdatum</th>
              <th>Buchungstext</th>
              <th>Betrag</th>
              <th>MwSt</th>
              <th>Kostenstelle</th>
              <th>Typ</th>
              <th>Info</th>
              <th style="width: 10rem"></th>
              <th style="width: 10rem"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr style="height:40px">
              <td>{{ item.transactionDate2 * 1000 | customDate }}</td>
              <td pEditableColumn [pEditableColumnDisabled]="!item.isValidDate">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <textarea pInputTextarea type="text" name="count" class="form-control p-0 editable-textarea"
                              [(ngModel)]="item.text"></textarea>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ item.text }}
                  </ng-template>
                </p-cellEditor>
              </td>

              <td [style.color]="item.amount < 0 ? 'red' :  'green' ">{{ item.amount | currency:'EUR' }}</td>
              <td>
                <div *ngIf="item.hasTax()"
                     [style.color]="item.taxSum < 0 ? 'red' :  'green' ">{{ item.taxSum | currency:'EUR' }}
                </div>
              </td>
              <td pEditableColumn [pEditableColumnDisabled]="!item.isValidDate">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown [options]="costCenters" optionLabel="key" optionValue="key" appendTo="body"
                                [(ngModel)]="item.costCenter"></p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">{{ item.costCenter }}</ng-template>
                </p-cellEditor>
              </td>
              <td>{{ globals.transactionTypeStrings[item.transactionType] }}</td>
              <td>{{ item.infoText }}</td>
              <td style="text-align:right;" *ngIf="item.isValidDate">
                <p-button *ngIf="!item.isKnownTransaction()" icon="pi pi-plus" class="p-button-rounded p-button-success"
                          (click)="onAddTransaction(item)" pTooltip="Neu"></p-button>
                <p-button *ngIf="!item.isKnownTransaction()" icon="pi pi-link"
                          class="p-button-rounded  p-button-success"
                          (click)="onLink(item)" pTooltip="Mit OP verknüpfen"></p-button>
                <p-button *ngIf="!item.isKnownTransaction()" icon="pi pi-code" class="p-button-rounded p-button-success"
                          (click)="onAddTemplate(item)" pTooltip="Aus Vorlage"></p-button>
              </td>
              <td style="text-align:right;" *ngIf="item.isValidDate">


                <p-button *ngIf="item.isKnownTransaction()" icon="pi pi-check-circle"
                          class="p-button-rounded p-button-success"
                          pTooltip="Übernehmen" (onClick)="onConfirm(item)"></p-button>
                <p-button *ngIf="item.isKnownTransaction()" icon="pi pi-times" class="p-button-rounded p-button-success"
                          pTooltip="Zuweisung aufheben" (click)="onResolveLink(item)"></p-button>
                <p-button icon="pi pi-trash" class="p-button-rounded p-button-success"
                          (click)="onDelete(item)" pTooltip="Zeile löschen"></p-button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
