import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';

import { Globals } from '../../globals';
import { Task } from '../../shared/task.model';
import { MessageService } from 'primeng/api';


@Injectable({ providedIn: 'root' })
export class TasksService {

  itemList = new Subject<Task[]>();
  itemArray = new Array<Task>();

  totalItems: number = 0;

  selectedItem: Task = null;

  detailActive = '';
  listActive = 'active';



  isCopy = false;

  constructor(
    private globals: Globals,
    private router: Router,
    private http: HttpClient,
    public datepipe: DatePipe,
    private messageService: MessageService,
  ) { }


  getItems(search: string, pageSize: number, page: number) {
    const url = this.globals.APP_URL + 'tasks';
    let params = new HttpParams();
    params = params.append('search', search);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('page', page.toString());
    this.http
      .get<Task[]>(url, {
        params: params,
        observe: 'response',
      })
      .subscribe((respData) => {
        this.totalItems = parseInt(respData.headers.get('x-total-count'));
        this.itemArray = respData.body.slice();
        this.itemList.next(this.itemArray);
      });
  }

  updateItem(p: Task) {
    const url = this.globals.APP_URL + 'tasks';
    this.http.put<Task>(url, p).subscribe((respData) => {
      this.selectedItem = respData;
      const idx = this.itemArray.findIndex((x) => x.id === p.id);
      this.itemArray[idx] = respData;

      
      this.router.navigate([
        'services',
        'tasks',
        this.selectedItem.id,
      ]);
      
    });
  }

  addItem(p: Task) {
    const url = this.globals.APP_URL + 'tasks';
    this.http.post<Task>(url, p).subscribe((respData) => {

      this.itemArray.push(respData);
      this.selectedItem = respData;
      this.router.navigate(['services', 'tasks', this.selectedItem.id]);

    });
  }

  readItems(search: string) {
    const url = this.globals.APP_URL + 'tasks';
    let params = new HttpParams();
    params = params.append('search', search);
    return this.http
      .get<Task[]>(url, {
        params: params,
        observe: 'response',
      });
  }

  readItem(id: number) {
    const url = this.globals.APP_URL + 'tasks/' + id;
    return this.http.get<Task>(url);
  }

  getMyTasks(contactId: number) {
    const url = this.globals.APP_URL + 'tasks/my-tasks';
    let params = new HttpParams();
    params = params.append('id', contactId);
    return this.http.get<Task[]>(url,
      {
        params: params,
      });
  }


  getItem(id: number) {
    //this.selectedProduct = this.productArray.find((p) => p.id === id);
    this.selectedItem = Object.assign(
      new Task(),
      this.itemArray.find((p) => p.id === id)
    );
    return this.selectedItem;
  }

  deleteItem(id: number) {
    const url = this.globals.APP_URL + 'tasks/' + id;
    return this.http.delete(url).subscribe(() => {
      this.itemArray = this.itemArray.filter(item => item.id !== this.selectedItem.id);
      this.selectedItem = null;
      this.itemList.next(this.itemArray);
      this.router.navigate(['services', 'tasks']);
    });
  }

}
