import { Component } from '@angular/core';

@Component({
  selector: 'app-worktime',
  standalone: true,
  imports: [],
  templateUrl: './worktime.component.html',
  styleUrl: './worktime.component.css'
})
export class WorktimeComponent {

}
