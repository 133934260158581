import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {NgxExtendedPdfViewerService, PagesLoadedEvent, PDFPrintRange} from 'ngx-extended-pdf-viewer';
import {DmsService} from '../../../services/dms/dms.service';
import {MyDocument} from '../../../shared/document.model';
import {InvoiceItem} from '../invoice-item.model';
import {Invoice} from '../invoice.model';
import * as signalR from '@microsoft/signalr';

import {InvoiceService} from '../invoice.service';
import {Globals} from '../../../globals';
import {IHttpConnectionOptions} from '@microsoft/signalr';

interface EventBus {
  dispatch(eventName: string): void;
}

@Component({
  selector: 'app-invoice-view',
  templateUrl: './invoice-view.component.html',
  styleUrls: ['./invoice-view.component.css']
})
export class InvoiceViewComponent implements OnInit {

  invoice: Invoice = new Invoice();
  id: number;
  zoomScale: string = "page-fit";
  pdfSrc: string;
  doc: MyDocument;

  connectionId: string = '';
  signalConnection: any;

  doAll: boolean;

  private _eventBus: EventBus;

  constructor(private invoiceServcice: InvoiceService,
              private route: ActivatedRoute,
              private router: Router,
              private dmsService: DmsService,
              private globals: Globals,
              private printService: NgxExtendedPdfViewerService) {
    this.signalConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(this.globals.APP_URL + 'progressHub', {withCredentials: false})

      .build();
  }


  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.onShowInvoice();
    });


    this.signalConnection.start().then(function () {
      //console.log();
      //var id = this.signalConnection.connectionId;
      console.log('SignalR Connected! ');

    }).catch(function (err) {
      return console.error(err.toString());
    });

    this.signalConnection.on("initProgressBar", () => {
      console.log("initProgressBar received");
    });
  }

  onStorno() {
    this.invoiceServcice.stornoInvoice(this.invoice);
  }

  onShowInvoice() {
    this.invoiceServcice.readInvoice(this.id).subscribe((i: Invoice) => {
      this.invoice = new Invoice();
      Object.assign(this.invoice, i);
      let j = 0;
      for (j = 0; j < this.invoice.invoiceItems.length; j++) {
        let item = new InvoiceItem();
        Object.assign(item, this.invoice.invoiceItems[j])
        this.invoice.invoiceItems[j] = item;
      }
      this.loadDoc();
    });
  }

  loadDoc() {
    this.dmsService.readDocument(this.invoice.documentId).subscribe((r) => {
      this.doc = r;
      this.onShowFile();
    });
  }

  onShowFile() {
    this.dmsService.downloadDocument(this.doc.id).subscribe(resp => {
      var downloadURL = URL.createObjectURL(resp.body);
      this.pdfSrc = downloadURL;
    });
  }

  onPagesLoaded(event: PagesLoadedEvent) {
    //this._eventBus = event.source['eventBus'];
    //this.print2();
  }

  onPagesPrinted(event) {
    console.log(event);
    console.log('document printed');
  }

  print() {
    if (this.doAll) {
      this.printAll();
    } else {
      this._eventBus.dispatch('print');
    }
  }

  printAll() {
    this.connectionId = this.signalConnection.connectionId;
    this.invoiceServcice.printAll(this.connectionId).subscribe(resp => {
      var downloadURL = URL.createObjectURL(resp.body);
      this.downloadDataUrlFromJavascript('rechnungen.pdf', downloadURL);
    });
  }

  downloadDataUrlFromJavascript(filename, dataUrl) {

    // Construct the 'a' element
    var link = document.createElement("a");
    link.download = filename;
    link.target = "_blank";

    // Construct the URI
    link.href = dataUrl;
    document.body.appendChild(link);
    link.click();

    // Cleanup the DOM
    document.body.removeChild(link);

  }

  public print2(): void {
    if (this.doAll) {
      this.printAll()
    } else {


      let range = {
        excluded: new Array(),
        from: 1,
        to: 1,
        included: new Array()
      };


      range.included.push(1);
      this.printService.print(range);
    }

  }

  Back() {
    this.router.navigate(['jobs', 'invoices']);
  }
}
