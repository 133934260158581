import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-myinvoices',
  templateUrl: './myinvoices.component.html',
  styleUrls: ['./myinvoices.component.css']
})
export class MyinvoicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
