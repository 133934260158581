import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Globals } from '../../globals';
import { FinancialYear } from './financial-year.model';
import { FinancialYearsService } from './financial-years.service';

@Component({
  selector: 'app-financial-years',
  templateUrl: './financial-years.component.html',
  styleUrls: ['./financial-years.component.css'],
  providers: [MessageService]
})
export class FinancialYearsComponent {
  display: boolean;
  entities: FinancialYear[];

  selectedEntity: FinancialYear = new FinancialYear(); t
  startDate: Date = new Date();
  endDate: Date = new Date();

  paymentMethods: any;

  subItem: Subscription;

  editMode: boolean = false;

  constructor(public globals: Globals,
    private entityService: FinancialYearsService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private datePipe: DatePipe
    ) { }

  ngOnDestroy(): void {
    this.subItem.unsubscribe();
  }

  ngOnInit(): void {
    this.endDate = new Date();
    this.startDate.setDate(this.endDate.getDate() - 1);
    this.startDate.setFullYear(this.startDate.getFullYear() - 1);

    this.subItem = this.entityService.itemList.subscribe(d => {
      d.forEach(e => {
        e.startDate = this.datePipe.transform(this.globals.parseDate(e.startDate, "mm dd yyyy"), "dd.MM.yyyy");
        e.endDate = this.datePipe.transform(this.globals.parseDate(e.endDate, "mm dd yyyy"), "dd.MM.yyyy");
      });
      this.entities = d;
    });

    this.readEntities();

  }

  readEntities() {
    this.entityService.getItems('', 25, 1);
  }
  showDialog() {
    this.selectedEntity = new FinancialYear();

    if (this.entities && this.entities.length > 0) {
      var end = new Date(this.entities[0].endDate2 * 1000);
      console.log(end);
      this.startDate = new Date(end);
      this.startDate.setDate(end.getDate() + 1);
      console.log(this.startDate);
      this.endDate = end;
      this.endDate.setFullYear(end.getFullYear() + 1);
      this.selectedEntity.year = this.startDate.getFullYear();
      this.selectedEntity.name = this.startDate.getFullYear().toString();
    }
    else {
      this.endDate = new Date();
      this.startDate.setDate(this.endDate.getDate() - 1);
      this.startDate.setFullYear(this.startDate.getFullYear() - 1);
    }
    this.editMode = false;
    this.display = true;
  }

  onSave() {
    this.display = false;
    this.selectedEntity.startDate2 = this.startDate.getTime() / 1000; // this.datePipe.transform(this.startDate, 'MM.dd.yyyy');
    this.selectedEntity.endDate2 = this.endDate.getTime() / 1000; // this.datePipe.transform(this.endDate, 'MM.dd.yyyy');
    if (this.editMode) {

      this.saveEntity();
    }
    else {
      this.selectedEntity.startDate = null;
      this.selectedEntity.endDate = null;
      this.createEntity();
    }
  }

  saveEntity() {
    this.entityService.putItem(this.selectedEntity).subscribe(resp => {
      this.readEntities();
    })
  }

  createEntity() {
    var e = this.entities.find(e => e.year === this.selectedEntity.year);
    if (!e) {
      this.entityService.postItem(this.selectedEntity).subscribe(resp => {
        this.readEntities();

        this.addInfoMessage('Finanzjahr wurde hinzugefügft');
      });
    }
    else {
      console.log('Fehler');
      this.addErrorMessage('Geschäftsjahr existiert bereits!')
    }

  }

  onEdit(entity) {
    this.selectedEntity = entity;
    this.startDate = new Date(this.selectedEntity.startDate2*1000);
    this.endDate = new Date(this.selectedEntity.endDate2*1000);

    this.editMode = true;
    this.display = true;
  }

  onDelete(entity) {
    this.confirmationService.confirm({
      message: `'Möchten Sie das Finanzjahr '${entity.name}' wirklich löschen?'`,
      header: 'Finanzjahr löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      rejectVisible: true,
      key: 'confirm',
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        this.entityService.deleteItem(entity.id).subscribe(() => {
          this.readEntities();
        });
        this.addInfoMessage('Finanzjahr wurde gelöscht');
      },
    });

  }

  addErrorMessage(message: string) {
    this.messageService.add({ key: 'tl', severity: 'error', summary: 'Fehler', detail: message });
  }

  addInfoMessage(message: string) {
    this.messageService.add({ key: 'tl', severity: 'info', summary: 'Information', detail: message });
  }

}
