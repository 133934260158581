import {DatePipe} from '@angular/common';
import {Component, ElementRef, OnInit, ViewChild, ɵbypassSanitizationTrustResourceUrl} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Globals, KVP_GROUPS} from '../../../globals';
import {CustomerSelectComponent} from '../../../masterdata/customer/customer-select/customer-select.component';
import {DmsAttachmentsComponent} from '../../../services/dms/dms-attachments/dms-attachments.component';
import {DmsSelectComponent} from '../../../services/dms/dms-select/dms-select.component';
import {PaymentTermsService} from '../../../settings/paymentterms/payment-terms.service';
import {ContactSelectComponent} from '../../../shared/contact-select/contact-select.component';
import {Contact} from '../../../shared/contact.model';
import {ContactService} from '../../../shared/contact.service';
import {MyDocument} from '../../../shared/document.model';
import {KvpService} from '../../../shared/kvp.service';
import {Invoice} from '../../billing/invoice.model';
import {InvoiceService} from '../../billing/invoice.service';

@Component({
  selector: 'app-debit-edit',
  templateUrl: './debit-edit.component.html',
  styleUrls: ['./debit-edit.component.css'],
  providers: [DialogService, MessageService]
})
export class DebitEditComponent implements OnInit {

  @ViewChild('FileSelectInputDialog') FileSelectInputDialog: ElementRef;
  @ViewChild('invoiceID') invoiceID: ElementRef;
  @ViewChild('attachments') attachmentComp: DmsAttachmentsComponent;

  invoice: Invoice = new Invoice();
  contact: Contact = new Contact();
  selectedDoc: MyDocument;

  deliveryDate: Date;
  invoiceDate: Date;

  skontoDate: Date;
  dueDate: Date;
  payDate: Date;

  tax: number;

  ref: DynamicDialogRef;
  editMode: Boolean = false;
  id: number;

  paymentMethods: any[];
  selectedPaymentMethod: any;

  fileName: string;
  selectedFile: File;
  isNewDoc: boolean;

  isPdf: boolean;
  isImage: boolean;

  zoomScale: string = "page-fit";
  pdfSrc: string;
  imgSrc: any;

  payWithCode: string = "BCD\n001\n1\nSCT\n";

  constructor(public globals: Globals,
              private kvpService: KvpService,
              private route: ActivatedRoute,
              private router: Router,
              private contactService: ContactService,
              private invoiceServcice: InvoiceService,
              private paymentTermService: PaymentTermsService,
              private confirmationService: ConfirmationService,
              public datepipe: DatePipe,
              public dialogService: DialogService,) {
  }

  ngOnInit(): void {
    this.invoice.isIncoming = true;
    this.invoice.paymentDays = 30;
    this.invoiceDate = new Date();
    this.deliveryDate = new Date();
    this.payDate = new Date();
    this.dueDate = new Date();
    this.dueDate.setDate(this.dueDate.getDate() + this.invoice.paymentDays);
    this.tax = 20;

    this.kvpService.readGroup(KVP_GROUPS.PAY_METHOD).subscribe(resp => {
      this.paymentMethods = resp;
      this.selectedPaymentMethod = this.paymentMethods[0].key;
      this.invoice.paymentMethode = this.paymentMethods[0].key;

    });

    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.editMode = params['id'] != 'new';

      if (!this.editMode) {
        this.onNewInvoice();
      } else {
        this.onShowInvoice();
      }


    });
  }

  onNewInvoice() {

  }

  onShowInvoice() {
    this.invoiceServcice.readInvoice(this.id).subscribe((i: Invoice) => {
      this.invoice = new Invoice();
      Object.assign(this.invoice, i);

      this.invoiceDate = new Date(this.invoice.invoiceDate2 * 1000);
      this.deliveryDate = new Date(this.invoice.deliveryDate2 * 1000);
      this.dueDate = new Date(this.invoice.dueDate2 * 1000);

      //this.payWithCode = this.payWithCode + this.invoice.bic + '\n' + this.invoice.completeAddress.split('\n')[0] + '\n' + this.invoice.iban + '\nEUR' + this.invoice.total.toString() + '\n\n\n' + this.invoice.invoiceID + '\n';
      this.updateQrCode();
      //console.log(this.payWithCode);
      if (this.dueDate < this.invoiceDate) {
        this.dueDate = new Date(this.invoiceDate);
        this.dueDate.setDate(this.invoiceDate.getDate() + this.invoice.paymentDays);
      }

      if (this.invoice.contactId > 0) {
        this.readContact(this.invoice.contactId);
      }

    });
  }

  updateQrCode() {
    this.payWithCode = "BCD\n001\n1\nSCT\n" + this.invoice.bic + '\n' + this.invoice.completeAddress.split('\n')[0] + '\n' + this.invoice.iban + '\nEUR' + this.invoice.total.toString() + '\n\n\n' + this.invoice.invoiceID + '\n';
  }

  readContact(id: number) {
    this.contactService.readContact(id).subscribe((c) => {
      Object.assign(this.contact, c);
    })
  }

  onSelectSupplier() {
    this.ref = this.dialogService.open(ContactSelectComponent, {
      data: {
        role: 'supplier'
      },
      header: 'Lieferant auswählen',
      width: '50%',
      contentStyle: {'max-height': '650px', overflow: 'auto'},
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((c: Contact) => {
      if (c) {
        this.contact = c;
        this.invoice.setCustomer(this.contact);
        this.invoice.contactId = c.id;
        this.invoice.bic = c.bic;
        this.invoice.iban = c.iban;
        this.invoice.vat = c.vat;

        setTimeout(() => { // this will make the execution after the above boolean has changed
          this.invoiceID.nativeElement.focus();
        }, 0);
        this.readPaymentTerms();

      }
    });

  }

  readPaymentTerms() {
    if (this.contact && this.contact.paymentTermsId > 0) {
      this.paymentTermService.readItem(this.contact.paymentTermsId).subscribe(p => {
        this.invoice.paymentMethode = p.paymentMethod;
        this.invoice.skonto = p.cashDiscount;
        this.invoice.skontoDays = p.cashDiscountDays;
        this.invoice.paymentDays = p.dueDays;
      });
    }
  }

  onSave() {
    this.invoice.isIncoming = true;
    this.invoice.isBooked = true;
    this.invoice.invoiceDate = null;
    this.invoice.deliveryDate = null;
    this.invoice.invoiceDate2 = Math.round(this.invoiceDate.getTime() / 1000);
    this.invoice.deliveryDate2 = Math.round(this.deliveryDate.getTime() / 1000);
    this.invoice.dueDate2 = Math.round(this.dueDate.getTime() / 1000);
    if (this.editMode) {
      this.attachmentComp.save();
      this.invoiceServcice.updateInvoice(this.invoice, true);
      this.updateQrCode();
    } else {
      console.log(this.invoice);
      this.invoiceServcice.addInvoiceSub(this.invoice, true).subscribe((resp) => {
        this.id = resp.id;
        //this.attachmentComp.save();
        this.invoiceServcice.addInvoiceItem(resp);
      });
    }

  }


  taxChanged() {
    console.log("change");
    if (this.invoice.netSum > 0) {
      this.invoice.total = this.invoice.netSum + this.invoice.taxSum;
    } else if (this.invoice.total > 0) {
      this.invoice.netSum = this.invoice.total - this.invoice.taxSum;
    }
    if (this.invoice.skonto > 0) {
      this.invoice.skontoSum = this.globals.round2(this.invoice.total * (this.invoice.skonto / 100));
    }
  }

  taxPercChanged() {
    if (this.invoice.netSum > 0) {
      this.invoice.taxSum = this.globals.round2(this.invoice.netSum * (this.tax / 100));
      this.invoice.total = this.invoice.netSum + this.invoice.taxSum;
    } else if (this.invoice.total > 0) {
      this.invoice.netSum = this.invoice.total / ((100 + this.tax) / 100);
      this.invoice.taxSum = this.globals.round2(this.invoice.netSum * (this.tax / 100));
    }
    if (this.invoice.skonto > 0) {
      this.invoice.skontoSum = this.globals.round2(this.invoice.total * (this.invoice.skonto / 100));
    }
  }

  netChanged() {
    if (this.tax > 0) {
      this.invoice.taxSum = this.globals.round2(this.invoice.netSum * (this.tax / 100));
      this.invoice.total = this.invoice.netSum + this.invoice.taxSum;
    } else {
      this.invoice.total = this.invoice.netSum;
      this.invoice.taxSum = 0;
    }
    if (this.invoice.skonto > 0) {
      this.invoice.skontoSum = this.globals.round2(this.invoice.total * (this.invoice.skonto / 100));
    }
  }

  totalChanged() {
    if (this.tax > 0) {
      this.invoice.netSum = this.invoice.total / ((100 + this.tax) / 100);
      this.invoice.taxSum = this.globals.round2(this.invoice.netSum * (this.tax / 100));
    } else {
      this.invoice.netSum = this.invoice.total;
      this.invoice.taxSum = 0;
    }

    if (this.invoice.skonto > 0) {
      this.invoice.skontoSum = this.globals.round2(this.invoice.total * (this.invoice.skonto / 100));
    }
  }

  onAddAttachment() {
    const elem: HTMLElement = this.FileSelectInputDialog.nativeElement;
    elem.click();
  }

  onAddLink() {
    this.isNewDoc = false;

    this.ref = this.dialogService.open(DmsSelectComponent, {
      data: {
        role: 'supplier'
      },
      header: 'Dokument auswählen',
      width: '75%',
      //contentStyle: { 'max-height': '650px', overflow: 'auto' },
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((doc: MyDocument) => {
      if (doc) {
        console.log(doc);
        this.selectedDoc = doc;
        this.fileName = doc.name;
      }
    });
  }

  onFileSelected(e) {
    this.fileName = e.target.files[0].name;

    this.selectedFile = e.target.files[0];
    const fileType = this.selectedFile.type;

    if (this.fileName.toUpperCase().includes(".PDF")) {
      this.isPdf = true;
      this.isImage = false;
    } else if (fileType.split('/')[0] === 'image') {
      this.isImage = true;
      this.isPdf = false;


    }

    const reader = new FileReader();
    reader.onloadend = (e: any) => {
      if (this.isImage) {
        this.imgSrc = e.target.result;
      } else {
        this.pdfSrc = e.target.result;
      }

      this.isNewDoc = true;

      this.selectedDoc = new MyDocument();
      this.selectedDoc.docType = "Rechnung";
      this.selectedDoc.name = this.fileName;
      this.selectedDoc.documentDate2 = Math.round(this.invoiceDate.getTime() / 1000); // this.datepipe.transform(this.invoiceDate, 'MM.dd.yyyy')
      this.selectedDoc.documentID = this.invoice.invoiceID;
    };
    reader.readAsDataURL(this.selectedFile);

  }

  Back() {
    this.router.navigate(['jobs', 'debit']);
  }

  Delete() {
    this.confirmationService.confirm({
      message: 'Möchten Sie diese Rechnung wirklich löschen?',
      header: 'Rechnung löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      key: 'confirm',
      rejectVisible: true,
      icon: 'pi pi-question-circle',
      accept: () => {
        this.invoiceServcice.deleteInvoice(this.invoice.id).subscribe((resp) => {
          this.router.navigate(['jobs', 'debit']);
        });
      }

    });
  }

  Save() {
    this.onSave();
  }

}
