import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDataService } from '../../shared/dataservice.interface';
import { WaterbillSettingsService } from './waterbill-settings.service';

@Component({
  selector: 'app-waterbill-settings',
  templateUrl: './waterbill-settings.component.html',
  styleUrls: ['./waterbill-settings.component.css']
})
export class WaterbillSettingsComponent implements OnInit {

  dataService: IDataService;

  constructor(private router: Router, public entityService: WaterbillSettingsService) { }

  ngOnInit(): void {
    this.entityService.detailActive = '';
    this.entityService.listActive = 'active';
  }

  onAddEntity() {
    this.router.navigate(['settings', 'waterbillsettings', 'new']);
    this.entityService.detailActive = 'active';
    this.entityService.listActive = '';
  }

  onDetailSelected() {

  }

  onListSelected() {
    this.router.navigate(['settings', 'waterbillsettings']);
    this.entityService.detailActive = '';
    this.entityService.listActive = 'active';
  }

  onActivate(componentRef: Component) {
    this.dataService = componentRef as IDataService;
  }
}
