import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { MessageService } from 'primeng/api';
import { KvpService } from '../kvp.service';
import { KvpSetting } from '../kvpsetting.model';

interface TagEdit {
  setting: KvpSetting,
  isNew: boolean,
  isChanged: boolean,
  errorMsg: string
}


@Component({
  selector: 'app-kvp-settings',
  templateUrl: './kvp-settings.component.html',
  styleUrls: ['./kvp-settings.component.css']
})
export class KvpSettingsComponent implements OnInit {

  @Input('title') title: string;
  @Input('titleNew') titleNew: string;
  @Input('newTag') newTag: string;
  @Input('kvp_type') kvp_type: string;

  tags: TagEdit[];

  constructor(private kvpService: KvpService, private route: ActivatedRoute, private messageService: MessageService) { }

  ngOnInit(): void {
    this.route.data.subscribe((data: Data) => {
      this.title = data['title'] ? data['title'] : 'Gruppenname!'
      this.titleNew = data['titleNew'] ? data['titleNew'] : 'Gruppenname!'
      this.kvp_type = data['kvp_type'];
      this.newTag = data['newTag'] ? data['newTag'] : 'Name'
      this.loadTags();
    });

    
  }

  onAddTag() {
    let t: TagEdit = { setting: new KvpSetting(), isNew: true, isChanged: false, errorMsg: '' };
    t.setting.group = this.kvp_type;
    t.setting.readOnly = false;
    this.tags.push(t);
  }

  onDeleteTag(index: number) {
    this.kvpService.deleteSettings(this.tags[index].setting).subscribe({
      next: data => { this.tags.splice(index, 1); }
    })
  }

  onSaveTags() {
    this.tags.forEach(element => {
      if (element.isNew && element.setting.key !== "") {
        this.kvpService.addSettings(element.setting).subscribe({
          next: () => { }, error: () => { }, complete: () => {
            
          }
        });
      }
      if (element.isChanged && element.setting.key !== "" && !element.isNew && !element.setting.readOnly) {
        this.kvpService.updateSettings(element.setting).subscribe({
          next: () => { }, error: () => { }, complete: () => {
            
          }
        });
      }
    });
    this.messageService.add({ key: 'tl', severity: 'info', summary: 'Daten wurden gespeichert.' });
  }

  loadTags() {
    this.kvpService.readGroup(this.kvp_type).subscribe((respData) => {
      const loadedGroups: KvpSetting[] = respData.slice();
      this.tags = [];
      loadedGroups.forEach(element => {
        const p: TagEdit = { setting: element, isNew: false, isChanged: false, errorMsg: '' };
        this.tags.push(p);
      });
    });
  }

  onTagChanged(index: number) {
    this.tags[index].isChanged = true;
  }


}
