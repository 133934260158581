import {EntityBase} from "../../shared/entity.base";

export class Account extends EntityBase {
  name: string = '';

  bankName: string = '';
  accountType: string = '';

  accountNumber: string = '';
  iban: string = '';
  bic: string = '';

  fiscalYear: number;

  startBalance: number;    // the start amount of the current fiscal year
  currentBalance: number;

  csvImportMap: string = '';

  completedUntil: number = 0;

}
