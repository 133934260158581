import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {MaintenancePlanEntry, MaintenancePlanEntryViewModel} from '../../maintenance.planentry.model';
import {MaintenanceWorkService} from '../../maintenance-work.service';
import {MaintenancePlanService} from '../../maintenance-plan.service';
import {MaintenanceWorkEntryViewModel} from '../../maintenance.workentry.model';
import {Globals} from '../../../../globals';
import {TasksService} from '../../../tasks/tasks.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-maintenance-plan-list',
  templateUrl: './maintenance-work-list.component.html',
  styleUrls: ['./maintenance-work-list.component.css']
})
export class MaintenanceWorkListComponent implements OnInit {

  items: MaintenancePlanEntryViewModel[];
  selectedItem: MaintenancePlanEntryViewModel;
  isLoading = false;

  searchString: string = '';

  today: number;

  totalItems: number;

  @Output() selected = new EventEmitter<MaintenancePlanEntry>();

  @Input('titleString') title: string = 'Wartungsarbeit durchführen';
  @Input() showAddButton: boolean = true;

  constructor(private router: Router, public entityService: MaintenancePlanService, private workService: MaintenanceWorkService, private taskService: TasksService, public global: Globals, private datePipe: DatePipe) {
    this.today = Math.round(new Date(Date.now()).getTime() / 1000);

  }

  ngOnInit(): void {
    this.entityService.detailActive = '';
    this.entityService.listActive = 'active';

    this.isLoading = true;

    this.workService.readPlan('').subscribe(resp => {
      this.items = resp;
      this.isLoading = false;
      this.readTasks();
    });

  }

  readTasks() {
    this.taskService.readItems(this.searchString).subscribe(resp => {
      resp.body.forEach(t => {
        if (!t.done) {
          var e = new MaintenancePlanEntryViewModel;
          e.equipmentName = '';
          e.dueDate = t.dueDate2;
          e.lastDone = this.global.MIN_DATE;
          e.text = t.title;
          e.isTask = true;
          e.taksId = t.id;
          this.items.push(e);
        }
      })
    });
  }


  onSubmit(form: NgForm) {
    this.entityService.getItems(this.searchString, 10, 1);
  }


  paginate(event) {
    this.entityService.getItems(this.searchString, 10, event.page + 1);
  }


  onSelected2(event) {
    if (this.selectedItem.isTask) {
      this.router.navigate(['services', 'tasks', this.selectedItem.taksId]);
      return;
    } else {
      this.router.navigate(['services', 'maintenance', 'work', 'new'], {queryParams: {plan: this.selectedItem.planEntryId}});

      this.entityService.detailActive = 'active';
      this.entityService.listActive = '';
    }

  }

  New() {

  }

}
