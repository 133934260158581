<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="mb-3 flex align-items-center justify-content-between">
    <div>
        <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
        <span class="text-xl font-medium text-900 mx-3">Benutzer</span>
    </div>
    <div>
        <button pButton pRipple label="Löschen" icon="pi pi-trash" (click)="Delete()" class="mx-3"></button>
        <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="Save()"></button>
    </div>
</div>
        <form #f="ngForm">

            <div style="height:0; overflow:hidden">
                <input style="opacity:0;" type="email" value="" class="" />
                <input style="opacity:0;" type="password" value="" class="d-" />
            </div>


            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Name</label>
                        <input type="text" name="usrName" class="form-control" [(ngModel)]="user.userName" required />
                    </div>

                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>Email</label>
                        <input type="search" name="usrEmail" class="form-control" [(ngModel)]="user.email" required />
                    </div>

                </div>





            </div>

            <div class="row">

                <div class="col-md-6">
                    <div class="form-group">
                        <label>Rolle</label>
                        <p-dropdown [options]="roles" name="selectRole" optionLabel="key" [(ngModel)]="selectedRole"></p-dropdown>
                    </div>
                </div>

                <div class="col-md-2 col-btn">
                    <div class="form-check">


                        <input type="checkbox" class="form-check-input" name="admin" [(ngModel)]="user.isAdmin"
                               id="2">
                        <label class="custom-control-label" for="2">Administrator</label>

                    </div>
                </div>
            </div>
            <div class="row">

                <div class="col-md-6">
                    <div class="form-group">
                        <label>Kennwort</label>
                        <input name="pwd1" type="password" class="form-control" placeholder="Neues Kennwort" [(ngModel)]="password1" (ngModelChange)="onPasswordChange()" matInput />
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label>Kennwort bestätigen</label>
                        <input name="pwd2" type="password" class="form-control" placeholder="Neues Kennwort" [(ngModel)]="password2" (ngModelChange)="onPasswordChange()" matInput />
                    </div>
                </div>
            </div>
            <div class="row justify-content-center" *ngIf="badPwd">
                <div class="col-md-12">
                    <div class="text-center">
                        <div class="alert alert-danger alert-dismissible fade show">
                            <strong>Fehler!</strong> Kennwort ist nicht identischt.
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center" *ngIf="pwdRequired">
                <div class="col-md-12">
                    <div class="text-center">
                        <div class="alert alert-warning alert-dismissible fade show">
                            <strong>Achtung!</strong> Kennwort muss eingegeben werden.
                        </div>
                    </div>
                </div>
            </div>

            <hr />
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Mitglied</label>

                        <input type="text" name="owner" placeholder="" readonly class="form-control"
                               value="{{ ownerName  }}" />
                    </div>
                </div>

                <div class="col-md-2  col-btn">
                    <div class="form-group">
                        <div class="row justify-content-left">
                            <h6 class="add-user-button d-flex justify-content-left" (click)="onSelectMember()">
                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M10,13C9.65,13.59 9.36,14.24 9.19,14.93C6.5,15.16 3.9,16.42 3.9,17V18.1H9.2C9.37,18.78 9.65,19.42 10,20H2V17C2,14.34 7.33,13 10,13M10,4A4,4 0 0,1 14,8C14,8.91 13.69,9.75 13.18,10.43C12.32,10.75 11.55,11.26 10.91,11.9L10,12A4,4 0 0,1 6,8A4,4 0 0,1 10,4M10,5.9A2.1,2.1 0 0,0 7.9,8A2.1,2.1 0 0,0 10,10.1A2.1,2.1 0 0,0 12.1,8A2.1,2.1 0 0,0 10,5.9M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14Z" />
                                </svg>
                                <span class="my-1">Auswählen</span>
                            </h6>
                        </div>
                    </div>
                </div>

            </div>

        </form>

        <br />

