<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header d-md-flex justify-content-between align-items-center">
              <h6 class="card-title">Bitte ein Mitglied auswählen!</h6>
            </div>
        </div>
    </div>
</div>

