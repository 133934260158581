import {Component, OnInit} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {TableModule} from "primeng/table";
import {ToolbarModule} from "primeng/toolbar";
import {CheckboxModule} from "primeng/checkbox";
import {Counterlist} from "../../../water-access-point/counterlist.model";
import {WapService} from "../../../water-access-point/wap.service";
import {DomSanitizer} from "@angular/platform-browser";
import {DocDbService} from "../../../shared/doc-db.service";
import {CounterListTemplateModel} from "./counter-list-template.model";
import {MessageService} from "primeng/api";


@Component({
  selector: 'app-counter-list-card',

  templateUrl: './counter-list-card.component.html',
  styleUrl: './counter-list-card.component.css',
  providers: [MessageService]
})
export class CounterListCardComponent implements OnInit {

  query = {
    "selector": {
      "table": {
        "$eq": "CounterListTemplate"
      },
    },
    "limit": 100,
  };

  stateOptions: any[] = [
    {label: 'Nur Email', value: 'email-only'},
    {label: 'Nur Post', value: 'mail-only'},
    {label: 'Alle', value: 'all'}
  ];

  value: string = 'all';
  headerText: string = 'Sehr geehrtes Mitglied,';

  onlyMissing: boolean = false;
  isCounterList: boolean = false;

  items: Counterlist[] = [];

  counterCardTemplate: CounterListTemplateModel = new CounterListTemplateModel();

  constructor(private wapService: WapService, private sanitizer: DomSanitizer,
              private messageService: MessageService,
              private docDbService: DocDbService
  ) {


  }

  ngOnInit(): void {
    this.readTemplate();
  }

  readTemplate() {
    this.docDbService.queryItems<CounterListTemplateModel>(JSON.stringify(this.query)).subscribe((respo) => {
      if (respo && respo.length > 0) {
        this.counterCardTemplate = respo[0];
      } else {
        this.docDbService.postItem<CounterListTemplateModel>(this.counterCardTemplate, this.counterCardTemplate.table).subscribe((respo) => {
          this.counterCardTemplate = Object.assign(new CounterListTemplateModel(), respo[0]);
        });

      }
    });
  }

  saveTemplate() {
    this.docDbService.putItem<CounterListTemplateModel>(this.counterCardTemplate, this.counterCardTemplate.table).subscribe((respo) => {
      this.counterCardTemplate = respo;
    });
  }

  onCreateCounterList() {
    this.items = [];
    this.isCounterList = false;
    this.wapService.getCounterList(true, this.value == 'email-only' ? true : false, this.value == 'mail-only' ? true : false).subscribe((respo) => {
      this.items = respo;
      this.isCounterList = true;

    });

  }

  onSelectionChange(e) {
    this.isCounterList = false;
  }

  onPreview() {
    if (this.items && this.items.length > 0) {
      this.wapService.counterCardPreview(this.items[0].wapId, this.counterCardTemplate).subscribe((respo) => {


        var file = new Blob([respo], {type: 'application/pdf'})
        var fileURL = URL.createObjectURL(file);

        //window.open(fileURL);
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_self';
        a.download = 'Vorschau_Zaehlerkarte.pdf';
        document.body.appendChild(a);
        a.click();


      });
    }
  }

  onPrintAll() {
    // Create array of gauge ids from items
    let gaugeIds = this.items.map(item => item.gaugeId);
    if (gaugeIds.length > 0) {
      this.wapService.counterCardPrintAll(true, this.value == 'email-only' ? true : false, this.value == 'mail-only' ? true : false, this.counterCardTemplate).subscribe((respo) => {
        var file = new Blob([respo], {type: 'application/pdf'})
        var fileURL = URL.createObjectURL(file);

        //window.open(fileURL);
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_self';
        a.download = 'Zaehlerkarten.pdf';
        document.body.appendChild(a);
        a.click();
      });
    }

  }

  onSendAll() {
    this.wapService.counterCardSendAll(this.counterCardTemplate).subscribe((respo) => {
      console.log(respo);
      this.messageService.add({
        key: 'tl',
        severity: 'success',
        summary: respo.message

      });
    });
  }
}
