import {DatePipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ConfirmationService, MessageService, PrimeNGConfig} from 'primeng/api';
import {first} from 'rxjs/operators';
import {Globals} from 'src/app/globals';
import {Reading, ReadingViewModel} from 'src/app/shared/reading.model';
import {WaterUsage} from 'src/app/shared/usage.model';
import {Wap} from 'src/app/shared/wap.model';
import {WapService} from '../wap.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-wap-usage',
  templateUrl: './wap-usage.component.html',
  styleUrls: ['./wap-usage.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class WapUsageComponent implements OnInit {

  fromDate: Date = new Date();
  toDate: Date = new Date();

  wap: Wap;

  readings: ReadingViewModel[];
  hasReadings: boolean = false;
  isPart: boolean = false;

  usages: WaterUsage[];

  validUsage: boolean = true;

  sum: number = 0;
  free: number = 0;

  wasCopied: boolean = false;


  constructor(public globals: Globals, private wapService: WapService, public datepipe: DatePipe,
              private confirmationService: ConfirmationService,
              private router: Router,
              private primengConfig: PrimeNGConfig) {
    this.fromDate.setFullYear(this.fromDate.getFullYear() - 1);
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    if (this.wapService.isChild) {
      this.wap = this.wapService.selectedSubWap;
    } else {
      this.wap = this.wapService.selectedWap;
    }

    this.getReadings();
    this.getUsages();
  }

  getReadings() {
    this.wapService.getReadings(this.wap).subscribe(resp => {
      this.readings = resp;
      this.hasReadings = this.readings.length > 0;
      this.isPart = !this.hasReadings;

      // Order array this.readings by readingDate
      this.readings = this.readings.sort((a, b) => {
        return a.readingDate - b.readingDate;
      });

      if (this.readings.length > 0) {
        this.fromDate = new Date(this.readings[0].readingDate * 1000);
        this.toDate = new Date(this.readings[this.readings.length - 1].readingDate * 1000);
      }
    });
  }

  getUsages() {
    this.wapService.getUsages(this.wap).subscribe(resp => {
      this.usages = resp;
    });

  }

  onCopyReadings(event: Event) {
    var first: boolean = true;
    this.sum = 0;
    this.readings.forEach(r => {
      if (r.selected) {
        this.sum += (r.count - r.prevCount);
        if (first) {
          this.fromDate = new Date(r.readingDate * 1000);
        }
        first = false;
        this.toDate = new Date(r.readingDate * 1000);
      }
    });
    this.free = this.free;
    this.wasCopied = true;

  }

  onCreateUsage(event: Event) {
    if (this.sum <= 0 || this.sum < this.free) {
      this.validUsage = false;
    } else {
      this.validUsage = true;
      let usage = new WaterUsage();
      usage.usage = this.sum;
      usage.freeUsage = this.free;
      usage.wapID = this.wap.id;
      usage.isPartPayment = this.isPart;
      usage.isBilled = false;
      usage.isCleared = false;

      usage.from2 = Math.round(this.fromDate.getTime() / 1000);
      usage.to2 = Math.round(this.toDate.getTime() / 1000);

      if (this.wasCopied) {
        this.readings.forEach(r => {
          if (r.selected) {
            var reading = Object.assign(new ReadingViewModel(), r).toReading();
            reading.readingDate = "";
            reading.readingDate2 = r.readingDate;
            usage.readings.push(reading);
          }
        });
      }
      console.log(usage);

      this.wapService.addUsage(usage, this.wap).subscribe(resp => {
        this.getUsages();
        if (this.wasCopied) {
          this.getReadings();
        }
        this.wasCopied = false;
      });

    }
  }

  onDeleteReading(idx: number, event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Möchten Sie diesen Eintrag wirklich löschen?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      accept: () => {
        this.wapService.deleteReading(this.readings[idx].id, this.wap).subscribe(resp => {
          this.getReadings();
        });
      }
    });
  }

  onDeleteUsage(idx: number, event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Möchten Sie diesen Eintrag wirklich löschen?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      accept: () => {
        this.wapService.deleteUsage(this.usages[idx].id, this.wap).subscribe(resp => {
          this.getUsages();
          this.getReadings();
        });
      }
    });
  }

  Back() {
    console.log(this.wap);
    this.router.navigate(['wap-module', 'wap', this.wap.id, 'edit']);
  }
}
