<div class="my-2">

  <div class="px-0">
    <div class="input-group mb-3">
      <input type="text" name="searchProduct" class="form-control input-lg" placeholder="Suchtext" [autofocus]="true"
             aria-label="Recipient's username" aria-describedby="basic-addon2" [(ngModel)]="searchString"
             (keydown.enter)="onRead()"/>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" (click)="onRead()">
          Suchen
        </button>
      </div>
    </div>
  </div>


  <p-table [value]="entities" selectionMode="single" [(selection)]="invoice" styleClass="p-datatable-striped"
           (onRowSelect)="onRowSelect($event)">
    <ng-template pTemplate="header">
      <tr>
        <th>Re-Nr.</th>
        <th>Name</th>
        <th>Re-Dat.</th>
        <th>Re-Art</th>
        <th>Betrag</th>
        <th>Fällig</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-entity>
      <tr [pSelectableRow]="entity">
        <td>{{ entity.invoiceID }}</td>
        {{
          entity.orgName || entity.firstName ?
            entity.orgName
              ? entity.orgName
              : entity.firstName + ((entity.firstName && entity.lastName) ? " " : "") + entity.lastName
            : entity.completeAddress.substring(0, entity.completeAddress.indexOf('\n'))
        }}
        <td>{{ entity.invoiceDate2 * 1000 | customDate }}</td>
        <td>{{ entity.isIncoming ? "Eingang" : "Ausgang" }}</td>
        <td>{{ entity.total | currency : 'EUR' }}</td>
        <td>{{ entity.dueDate2 * 1000 | customDate }}</td>

      </tr>
    </ng-template>


  </p-table>

</div>
