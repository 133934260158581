<div class="surface-card px-4 py-8 md:px-6 lg:px-8">



  <div class="py-3 px-5 flex flex-column align-items-start">
    <div class="text-900 font-medium mb-2 text-xl">Wir arbeiten daran!</div>
    <p class="mt-0 mb-4 p-0 line-height-3">Diese Funktion steht leider in dieser Version noch nicht zur Verfügung.</p>
    <button pButton pRipple label="Zurück" class="mt-auto" (click)="goBack()" ></button>
  </div>
</div>
