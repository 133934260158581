
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Globals } from "../../globals";
import { EntityService } from "../../shared/entity.service";
import { NumberRange } from "./../../shared/number-range.model";

@Injectable({ providedIn: 'root' })
export class NumberRangeService extends EntityService<NumberRange>
{

  constructor(protected override globals: Globals,
    protected override router: Router,
    protected override http: HttpClient) {
    super(globals, router, http);

  }



  getResourceUrl(): string {
    return 'numberrange';
  }

  getEditRoute(id: number): any[] {
    return ['settings', 'number-range', id, 'edit']
  }

  getSearchFields() {
    return 'Name';
  }
}
