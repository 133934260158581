import { Component, OnInit } from '@angular/core';
import { InvoiceService } from '../jobs/billing/invoice.service';
import {MessagingService} from "../shared/messaging.service";

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.css']
})
export class FinanceComponent implements OnInit {

  count: number = 0;

  constructor(private invoiceService: InvoiceService, private messagingService: MessagingService) { }

  ngOnInit(): void {
    this.invoiceService.getInvoiceCount().subscribe(resp => {
      this.count = resp.count;
    });

    this.messagingService.subscribe('invoice-count').subscribe(message => {
      this.count = message.data;
    });
  }

}
