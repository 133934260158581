<div class="mb-3 flex align-items-center justify-content-between">
  <span class="text-xl font-medium text-900">Wasserzähler</span>
  <div class="flex flex-wrap gap-3">
    <div class="flex align-items-center gap-1">
      <p-checkbox inputId="isStock" [binary]="true" name="group1" [(ngModel)]="gaugeService.isStock"></p-checkbox>
      <label for="isStock">Lagerbestand</label>
    </div>

    <div class="flex align-items-center gap-1">
      <p-checkbox inputId="isActive" [binary]="true" [(ngModel)]="gaugeService.isActive"></p-checkbox>
      <label for="isActive">Eingebaut</label>
    </div>
  </div>
  <button pButton pRipple label="Hinzufügen" icon="pi pi-plus" (click)="New()"></button>
</div>

<form (ngSubmit)="onSubmit(f)" #f="ngForm">
  <div class="px-0">
    <div class="input-group mb-3">
      <input type="text" name="searchGauge" class="form-control input-lg" placeholder="Suchtext"
             aria-label="Recipient's username" aria-describedby="basic-addon2" ngModel/>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" [disabled]="!f.valid" type="submit">
          Suchen
        </button>
      </div>
    </div>
  </div>
</form>

<p-table [value]="gauges" selectionMode="single" [(selection)]="selectedGauge" dataKey="id" responsiveLayout="scroll"
         (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped">
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="serial" scope="col">Seriennummer
        <p-sortIcon field="serial"></p-sortIcon>
      </th>
      <th pSortableColumn="description" scope="col">Type
        <p-sortIcon field="description"></p-sortIcon>
      </th>
      <th pSortableColumn="calibrationDate2" scope="col">Eichdatum
        <p-sortIcon field="calibrationDate2"></p-sortIcon>
      </th>
      <th pSortableColumn="installCount" scope="col">Zählerstand
        <p-sortIcon field="installCount"></p-sortIcon>
      </th>
      <th pSortableColumn="isStock" scope="col">Lagerbestand
        <p-sortIcon field="isStock"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-gauge>
    <tr [pSelectableRow]="gauge">
      <td>{{ gauge.serial }}</td>
      <td>{{ gauge.description }}</td>
      <td>
        <div *ngIf="gauge.calibrationDate2 > globals.MIN_DATE">{{ gauge.calibrationDate2 * 1000 | date:'MM.yyyy' }}
        </div>
      </td>
      <td>{{ gauge.installCount }}</td>
      <td>{{ gauge.isStock | yesnoConverter }}</td>
    </tr>
  </ng-template>
</p-table>
<p-paginator class="my-paginator" [rows]="10" [totalRecords]="totalGauges" pageLinkSize="3"
             (onPageChange)="paginate($event)"></p-paginator>
