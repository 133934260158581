
<div class="surface-card p-4 shadow-2 border-round">

    <div class="mb-3 flex align-items-center justify-content-between">
        <div>
            <button class="mr-3" pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()" *ngIf="wapService.isChild"></button>
            <span class="text-xl font-medium text-900">{{ title }}</span>
        </div>
        <button pButton pRipple label="Hinzufügen" icon="pi pi-plus" (click)="New()"></button>
    </div>




    <form (ngSubmit)="onSubmit(f)" #f="ngForm" [hidden]="wapService.isChild">
        <div class="">
            <div class="input-group mb-3">
                <input type="text"
                       name="searchWap"
                       class="form-control input-lg"
                       placeholder="Suchtext"
                       aria-label="Recipient's username"
                       aria-describedby="basic-addon2"
                       [(ngModel)]="searchString" />
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary"
                            [disabled]="!f.valid"
                            type="submit">
                        Suchen
                    </button>
                </div>
            </div>
        </div>
    </form>


    <div class="text-center">
        <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
    </div>

    <p-table *ngIf="!isLoading" [value]="waps" selectionMode="single" [(selection)]="userSelectedWap" dataKey="id" responsiveLayout="scroll"
             (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
            <tr>
                <th scope="col">Nummer</th>
                <th *ngIf="!wapService.isChild" scope="col">Eigentümer</th>
                <th *ngIf="!wapService.isChild" scope="col">Adresse</th>
                <th *ngIf="!wapService.isChild" scope="col">Ort</th>
                <th *ngIf="!wapService.isChild" scope="col">Pauschale</th>
                <th *ngIf="wapService.isChild" scope="col">Anteil</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-wap>
            <tr [pSelectableRow]="wap">
                <td>{{ wap.accessPointID }}</td>
                <td *ngIf="!wapService.isChild" >{{ wap.ownerName }}</td>
                <td *ngIf="!wapService.isChild" >{{ wap.address }}</td>
                <td *ngIf="!wapService.isChild" >{{ wap.city }}</td>
                <td *ngIf="!wapService.isChild && wap.isFlatRate">
                    <i class="pi pi-check"></i>
                </td>
                <td *ngIf="!wapService.isChild && !wap.isFlatRate"></td>
                <td *ngIf="wapService.isChild" >{{ wap.fragment }}</td>
            </tr>
        </ng-template>
    </p-table>


    <p-paginator class="my-paginator"
                 [hidden]="wapService.isChild"
                 [rows]="15"
                 [totalRecords]="totalWaps"
                 pageLinkSize="3"
                 (onPageChange)="paginate($event)"></p-paginator>


</div>


