import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {IDataService} from '../../../shared/dataservice.interface';
import {Account} from '../account.model';
import {AccountService} from '../account.service';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.css']
})
export class AccountListComponent implements OnInit, OnDestroy, IDataService {

  totalItems: number;
  searchString: string = '';

  subItem: Subscription;
  items: Account[];
  selectedItem: Account;
  isLoading = false;


  constructor(private router: Router, public entityService: AccountService) {
  }

  ngOnInit(): void {
    this.entityService.detailActive = '';
    this.entityService.listActive = 'active';

    this.totalItems = this.entityService.totalItems;
    this.items = this.entityService.itemArray;

    if (!this.items?.length) this.isLoading = true;

    this.subItem = this.entityService.itemList.subscribe(d => {
      this.items = d;
      this.isLoading = false;
      this.totalItems = this.entityService.totalItems;
    });

    if (!this.items?.length) this.entityService.getItems('', 10, 1);
  }


  ngOnDestroy(): void {
    this.subItem.unsubscribe();
  }

  onSubmit(form) {
    this.entityService.getItems(this.searchString, 10, 1);
  }

  paginate(event) {
    this.entityService.getItems(this.searchString, 10, event.page + 1);
  }

  onSelected(entity) {
    this.selectedItem = entity;
    this.router.navigate(['settings', 'accounts', this.selectedItem.id, 'edit']);

    this.entityService.detailActive = 'active';
    this.entityService.listActive = '';
  }

  onSelected2(event) {
    this.entityService.selectedItem = this.selectedItem;
    this.router.navigate(['settings', 'accounts', this.selectedItem.id]);

    this.entityService.detailActive = 'active';
    this.entityService.listActive = '';
  }

  New() {
    this.router.navigate(['settings', 'accounts', 'new']);

  }

  Copy() {
    console.log("copy");
  };

  Save() {
    console.log("save");
  };

  Delete() {
    console.log("delete");
  };


}
