import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {GaugeService} from '../../masterdata/gauges/gauge.service';
import {Contact} from '../../shared/contact.model';
import {Gauge, GaugeImport} from '../../shared/gauge.model';
import {ContactService} from '../../shared/contact.service';
import {WapService} from '../../water-access-point/wap.service';
import {WapImport} from '../../shared/wap.model';
import {Globals} from '../../globals';
import {DatePipe} from '@angular/common';
import {ConfirmationService} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {Product, ProductImport} from '../../shared/product.model';
import {ProductService} from '../../masterdata/products/product.service';

@Component({
  selector: 'app-data-import',
  templateUrl: './data-import.component.html',
  styleUrls: ['./data-import.component.css'],
  providers: [DialogService]
})
export class DataImportComponent implements OnInit {

  @ViewChild('FileSelectInputDialog') FileSelectInputDialog: ElementRef;
  fileName: string;

  destinationList: any[] = [
    {key: 0, text: 'Mitglieder'},
    {key: 1, text: 'Wasserzähler'},
    {key: 2, text: 'Wasseranschlüsse'},
    {key: 3, text: 'Lieferanten'},
    {key: 4, text: 'Kunden'},
    {key: 5, text: 'Produkte'},
  ];

  index: string;
  text: string = '';
  useTab: boolean = false;
  delimiter: string = ';';

  sampleValues: string[];

  fieldList: any[] = [
    [
      {
        fieldName: 'contactID',
        displayName: 'Nummer',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 20
      },
      {
        fieldName: 'firstName',
        displayName: 'Vorname',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 50
      },
      {
        fieldName: 'lastName',
        displayName: 'Nachname',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 50
      },
      {
        fieldName: 'orgName',
        displayName: 'Organisation',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 50
      },
      {
        fieldName: 'name2',
        displayName: 'Name 2',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 50
      },
      {
        fieldName: 'address1',
        displayName: 'Adresse 1',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 50
      },
      {
        fieldName: 'address2',
        displayName: 'Adresse 2',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 50
      },
      {
        fieldName: 'zip',
        displayName: 'PLZ',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 10
      },
      {
        fieldName: 'city',
        displayName: 'Ort',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 50
      },
      {
        fieldName: 'mobile',
        displayName: 'Handy',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 20
      },
      {
        fieldName: 'mobile2',
        displayName: 'Handy 2',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 20
      },
      {
        fieldName: 'telephone',
        displayName: 'Telefon',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 20
      },
      {
        fieldName: 'telephone2',
        displayName: 'Telefon 2',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 20
      },
      {
        fieldName: 'email',
        displayName: 'E-Mail',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 50
      },
      {
        fieldName: 'email2',
        displayName: 'E-Mail (geschäftlich)',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 50
      },
      {
        fieldName: 'emailInvoice',
        displayName: 'E-Mail Rechnung',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 50
      },
      {
        fieldName: 'isEInvoice',
        displayName: 'Elektronischer Rechnungsversand',
        default: false,
        csvColumn: -1,
        isMeta: false,
        dataType: 'boolean'
      },
      {
        fieldName: 'created2',
        displayName: 'Mitglied seit',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'date'
      },
      {
        fieldName: 'bankeName',
        displayName: 'Bankname',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 50
      },
      {
        fieldName: 'iban',
        displayName: 'IBAN',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 50
      },
      {
        fieldName: 'bic',
        displayName: 'BIC',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 20
      },
      {
        fieldName: 'vat',
        displayName: 'UID',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string',
        maxLenth: 50
      },
      {
        fieldName: 'isDebit',
        displayName: 'SEPA-Lastschrift',
        default: false,
        csvColumn: -1,
        isMeta: false,
        dataType: 'boolean'
      },
      {
        fieldName: 'directDebitMandate',
        displayName: 'Mandatsreferenz',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string'
      },
      {
        fieldName: 'mandateIssued2',
        displayName: 'Ausstellungsdatum',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'date'
      },
      {fieldName: 'active', displayName: 'Aktiv', default: true, csvColumn: -1, isMeta: false, dataType: 'boolean'},
    ],

    [
      {fieldName: 'serial', displayName: 'Seriennummer', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {
        fieldName: 'description',
        displayName: 'Beschreibung',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string'
      },
      {
        fieldName: 'installDate2',
        displayName: 'Einbaudatum',
        default: '',
        csvColumn: -1,
        isMeta: true,
        dataType: 'date'
      },
      {
        fieldName: 'lastCount',
        displayName: 'Zählerstand',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'number'
      },
      {
        fieldName: 'lastReading',
        displayName: 'Ablesedatum',
        default: '',
        csvColumn: -1,
        isMeta: true,
        dataType: 'date'
      },
      {
        fieldName: 'calibrationDate2',
        displayName: 'Eichdatum',
        default: '',
        csvColumn: -1,
        isMeta: true,
        dataType: 'date'
      },
    ],

    [
      {
        fieldName: 'accessPointID',
        displayName: 'Nummer',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string'
      },
      {fieldName: 'yearCreated', displayName: 'Baujahr', default: '', csvColumn: -1, isMeta: false, dataType: 'number'},
      {
        fieldName: 'drainAccess',
        displayName: 'Kanalanschluss',
        default: 'true',
        csvColumn: -1,
        isMeta: false,
        dataType: 'boolean'
      },
      {
        fieldName: 'description',
        displayName: 'Beschreibung',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string'
      },
      {fieldName: 'address1', displayName: 'Adresse', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'zip', displayName: 'PLZ', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'city', displayName: 'Ort', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {
        fieldName: 'waterbillTermsID1',
        displayName: 'Abrechnungsvorlage 1',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'number'
      },
      {
        fieldName: 'waterbillTermsID2',
        displayName: 'Abrechnungsvorlage 2',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'number'
      },
      {
        fieldName: 'propertyNumber',
        displayName: 'Liegenschaft/Parzellen',
        default: '',
        csvColumn: -1,
        isMeta: true,
        dataType: 'string'
      },
      {
        fieldName: 'pipeNumber',
        displayName: 'Leitungsnummer',
        default: '',
        csvColumn: -1,
        isMeta: true,
        dataType: 'string'
      },
      {
        fieldName: 'memberId',
        displayName: 'Mitglied Nummer',
        default: '',
        csvColumn: -1,
        isMeta: true,
        dataType: 'string'
      },
      {
        fieldName: 'gaugeSerial',
        displayName: 'Wasserzähler SN',
        default: '',
        csvColumn: -1,
        isMeta: true,
        dataType: 'string'
      },

      {
        fieldName: 'needText1',
        displayName: 'Bedarfseinheit Text 1',
        default: '',
        csvColumn: -1,
        isMeta: true,
        dataType: 'string'
      },
      {
        fieldName: 'needQuantity1',
        displayName: 'Bedarfseinheit Menge 1',
        default: '0',
        csvColumn: -1,
        isMeta: true,
        dataType: 'number'
      },

      {
        fieldName: 'needText2',
        displayName: 'Bedarfseinheit Text 2',
        default: '',
        csvColumn: -1,
        isMeta: true,
        dataType: 'string'
      },
      {
        fieldName: 'needQuantity2',
        displayName: 'Bedarfseinheit Menge 2',
        default: '0',
        csvColumn: -1,
        isMeta: true,
        dataType: 'number'
      },

      {
        fieldName: 'needText3',
        displayName: 'Bedarfseinheit Text 3',
        default: '',
        csvColumn: -1,
        isMeta: true,
        dataType: 'string'
      },
      {
        fieldName: 'needQuantity3',
        displayName: 'Bedarfseinheit Menge 3',
        default: '0',
        csvColumn: -1,
        isMeta: true,
        dataType: 'number'
      },

      {
        fieldName: 'needText4',
        displayName: 'Bedarfseinheit Text 4',
        default: '',
        csvColumn: -1,
        isMeta: true,
        dataType: 'string'
      },
      {
        fieldName: 'needQuantity4',
        displayName: 'Bedarfseinheit Menge 4',
        default: '0',
        csvColumn: -1,
        isMeta: true,
        dataType: 'number'
      },

      {
        fieldName: 'needText5',
        displayName: 'Bedarfseinheit Text 5',
        default: '',
        csvColumn: -1,
        isMeta: true,
        dataType: 'string'
      },
      {
        fieldName: 'needQuantity5',
        displayName: 'Bedarfseinheit Menge 5',
        default: '0',
        csvColumn: -1,
        isMeta: true,
        dataType: 'number'
      },
    ],

    [
      {fieldName: 'contactID', displayName: 'Nummer', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'firstName', displayName: 'Vorname', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'lastName', displayName: 'Nachname', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {
        fieldName: 'orgName',
        displayName: 'Organisation',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string'
      },
      {fieldName: 'name2', displayName: 'Name 2', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'address1', displayName: 'Adresse 1', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'address2', displayName: 'Adresse 2', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'zip', displayName: 'PLZ', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'city', displayName: 'Ort', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'mobile', displayName: 'Handy', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'mobile2', displayName: 'Handy 2', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'telephone', displayName: 'Telefon', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {
        fieldName: 'telephone2',
        displayName: 'Telefon 2',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string'
      },
      {fieldName: 'email', displayName: 'E-Mail', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'email2', displayName: 'E-Mail', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'created2', displayName: 'Erstellt', default: '', csvColumn: -1, isMeta: false, dataType: 'date'},
      {fieldName: 'bankeName', displayName: 'Bankname', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'iban', displayName: 'IBAN', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'bic', displayName: 'BIC', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'vat', displayName: 'UID', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {
        fieldName: 'directDebitMandate',
        displayName: 'Mandatsreferenz',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string'
      },
      {
        fieldName: 'mandateIssued2',
        displayName: 'Ausstellungsdatum',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'date'
      },
    ],

    [
      {fieldName: 'contactID', displayName: 'Nummer', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'firstName', displayName: 'Vorname', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'lastName', displayName: 'Nachname', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {
        fieldName: 'orgName',
        displayName: 'Organisation',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string'
      },
      {fieldName: 'name2', displayName: 'Name 2', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'address1', displayName: 'Adresse 1', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'address2', displayName: 'Adresse 2', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'zip', displayName: 'PLZ', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'city', displayName: 'Ort', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'mobile', displayName: 'Handy', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'mobile2', displayName: 'Handy 2', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'telephone', displayName: 'Telefon', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {
        fieldName: 'telephone2',
        displayName: 'Telefon 2',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string'
      },
      {fieldName: 'email', displayName: 'E-Mail', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'email2', displayName: 'E-Mail', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'created2', displayName: 'Erstellt', default: '', csvColumn: -1, isMeta: false, dataType: 'date'},
      {fieldName: 'bankeName', displayName: 'Bankname', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'iban', displayName: 'IBAN', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'bic', displayName: 'BIC', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {fieldName: 'vat', displayName: 'UID', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {
        fieldName: 'directDebitMandate',
        displayName: 'Mandatsreferenz',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string'
      },
      {
        fieldName: 'mandateIssued2',
        displayName: 'Ausstellungsdatum',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'date'
      },
    ],

    [
      {
        fieldName: 'productID',
        displayName: 'Artikelnummer',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string'
      },
      {
        fieldName: 'description',
        displayName: 'Bezeichnung',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string'
      },
      {
        fieldName: 'productText',
        displayName: 'Artikeltext',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string'
      },
      {
        fieldName: 'productGroup',
        displayName: 'Produktgruppe',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string'
      },
      {fieldName: 'unit', displayName: 'Einheit', default: '', csvColumn: -1, isMeta: false, dataType: 'string'},
      {
        fieldName: 'netSalesPrice',
        displayName: 'Netto VK',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'number'
      },
      {
        fieldName: 'netPurchasePrice',
        displayName: 'Einkaufspreis',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'number'
      },
      {fieldName: 'tax', displayName: 'Steuer %', default: '', csvColumn: -1, isMeta: false, dataType: 'number'},

      {
        fieldName: 'orderNumber',
        displayName: 'Bestellnummer',
        default: '',
        csvColumn: -1,
        isMeta: false,
        dataType: 'string'
      },
      {
        fieldName: 'supplierId',
        displayName: 'Lieferantennummer',
        default: '',
        csvColumn: -1,
        isMeta: true,
        dataType: 'string'
      },

      {fieldName: 'field1', displayName: 'Freifeld 1', default: '', csvColumn: -1, isMeta: true, dataType: 'string'},
      {fieldName: 'field2', displayName: 'Freifeld 2', default: '', csvColumn: -1, isMeta: true, dataType: 'string'},
    ],
  ];

  constructor(private contactService: ContactService,
              private gaugeService: GaugeService,
              private wapService: WapService,
              private productService: ProductService,
              private globals: Globals,
              private confirmationService: ConfirmationService,
              public datepipe: DatePipe) {
    this.refreshSampleValues();
  }

  ngOnInit(): void {
    this.index = '0';
  }

  onSelectFile() {
    const elem: HTMLElement = this.FileSelectInputDialog.nativeElement;
    elem.click();
  }

  onFileSelected(e) {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = (event) => {
      this.text = event.target.result.toString();
    };
    reader.readAsText(file);
  }

  onCheck() {

  }

  onTextChanged() {
    this.refreshSampleValues();
  }

  refreshSampleValues() {
    this.sampleValues = [];
    for (let i = 0; i < 100; i++) {
      this.sampleValues.push("Ungültige Spaltennummer");
    }

    const del = this.useTab ? '\t' : this.delimiter;
    let fields: string[] = this.text.slice(0, this.text.indexOf("\n")).split(del);

    console.log(fields);
    console.log(this.fieldList[this.index]);

    if (fields && this.fieldList[this.index]) {
      for (let i = 1; i <= fields.length; ++i) {
        this.sampleValues[i] = fields[i - 1];
        //this.sampleValues[i-1] = this.setField(this.fieldList[this.index][i-1], fields);
      }
    }
  }

  onImport() {
    console.log(this.index);
    console.log(typeof (this.index));

    let ct = this.checkImportData();
    if (ct > 0) {
      this.showMessage("Importdaten überprüfen. Die Anzahl der Spalten stimmt nicht überein. (Zeile " + ct.toString() + ")");
      return;
    }

    switch (this.index) {
      case '0':
        this.ImportMember("Member", "Mitglieder");
        break;

      case '1':
        this.ImportCounter()
        break;

      case '2':
        this.ImportWap();
        break;

      case '3':
        this.ImportMember("Supplier", "Lieferanten");
        break;

      case '4':
        this.ImportMember("Customer", "Kunden");
        break;

      case '5':
        this.ImportProducts();
        break;

    }
  }

  checkImportData() {
    // check if all lines in this.text have the same number of fields
    const del = this.useTab ? '\t' : this.delimiter;
    const lines: string[] = this.text.split('\n');
    let fields: string[] = lines[0].split(del);
    let fieldCount = fields.length;
    let valid = true;
    for (let i = 1; i < lines.length; ++i) {
      if (lines[i].length > 0) {
        fields = lines[i].split(del);
        if (fields.length != fieldCount) {
          valid = false;
          return i;
        }
      }
    }
    return 0;
  }

  ImportMember(role: string, title: string) {
    const del = this.useTab ? '\t' : this.delimiter;
    const lines: string[] = this.text.split('\n');

    let im: Contact[] = new Array();

    if (lines) {
      for (let i = 0; i < lines.length; ++i) {
        if (lines[i].length > 0) {
          const fields = lines[i].split(del);

          let m = new Contact();
          m.role = role;
          for (let j = 0; j < this.fieldList[this.index].length; ++j) {
            m[this.fieldList[this.index][j].fieldName] = this.setField(this.fieldList[this.index][j], fields);
          }
          if (!im.some(e => e.contactID === m.contactID)) {
            im.push(m);
          }
        }
      }

      if (im.length > 0) {
        this.contactService.importContacts(im).subscribe((r) => {
          this.showMessage("Es wurden " + r.toString() + " " + title + " erfolgreich importiert!");
          console.log('Imported ' + r.toString() + ' members');
        }, (error) => {
          this.showMessage("Daten konnten nicht importiert werden. Prüfen Zurodnung und das Datenformat.");
        });
        console.log(im);
      }
    }
  }

  ImportProducts() {
    const del = this.useTab ? '\t' : this.delimiter;
    const lines: string[] = this.text.split('\n');

    let im: ProductImport[] = new Array();

    console.log('import products');

    if (lines) {
      for (let i = 0; i < lines.length; ++i) {
        if (lines[i].length > 0) {
          const fields = lines[i].split(del);

          let m = new ProductImport();

          for (let j = 0; j < this.fieldList[this.index].length; ++j) {
            m[this.fieldList[this.index][j].fieldName] = this.setField(this.fieldList[this.index][j], fields);
          }
          if (!im.some(e => e.productID === m.productID)) {
            im.push(m);
          }
        }
      }
      if (im.length > 0) {
        this.productService.importProducts(im).subscribe((r) => {
          this.showMessage("Es wurden " + r.toString() + " Produkte erfolgreich importiert!");
          console.log('Imported ' + r.toString() + ' products');
        }, (error) => {
          this.showMessage("Daten konnten nicht importiert werden. Prüfen Zurodnung und das Datenformat.");
        });
        console.log(im);
      }
    }
  }

  ImportWap() {
    const del = this.useTab ? '\t' : this.delimiter;
    const lines: string[] = this.text.split('\n');

    let im: WapImport[] = new Array();

    console.log('import wap');

    if (lines) {
      for (let i = 0; i < lines.length; ++i) {
        if (lines[i].length > 0) {
          const fields = lines[i].split(del);

          let m = new WapImport();

          for (let j = 0; j < this.fieldList[this.index].length; ++j) {
            m[this.fieldList[this.index][j].fieldName] = this.setField(this.fieldList[this.index][j], fields);
          }
          if (!im.some(e => e.accessPointID === m.accessPointID)) {
            im.push(m);
          }
        }
      }
      if (im.length > 0) {
        this.wapService.importWaps(im).subscribe((r) => {
          this.showMessage("Es wurden " + r.toString() + " Wasseranschlüsse erfolgreich importiert!");
          console.log('Imported ' + r.toString() + ' waps');
        }, (error) => {
          this.showMessage("Daten konnten nicht importiert werden. Prüfen Zurodnung und das Datenformat.");
        });
        console.log(im);
      }
    }
  }

  ImportCounter() {
    const del = this.useTab ? '\t' : this.delimiter;
    const lines: string[] = this.text.split('\n');

    let im: GaugeImport[] = new Array();

    console.log('import counter');

    if (lines) {
      for (let i = 0; i < lines.length; ++i) {
        if (lines[i].length > 0) {
          const fields = lines[i].split(del);

          let m = new GaugeImport();

          for (let j = 0; j < this.fieldList[this.index].length; ++j) {
            m[this.fieldList[this.index][j].fieldName] = this.setField(this.fieldList[this.index][j], fields);
          }
          if (!im.some(e => e.serial === m.serial)) {
            im.push(m);
          }
        }
      }
      if (im.length > 0) {
        this.gaugeService.importGauges(im).subscribe((r) => {
          this.showMessage("Es wurden " + r.toString() + " Wasserzähler erfolgreich importiert!");
          console.log('Imported ' + r.toString() + ' gauges');
        }, (error) => {
          this.showMessage("Daten konnten nicht importiert werden. Prüfen Zurodnung und das Datenformat.");
        });
        console.log(im);
      }
    }
  }

  setField(field, fields): any {
    let value = '';
    if (field.csvColumn > 0) {
      value = fields[field.csvColumn - 1];
    } else {
      value = field.default;
    }

    if (field.dataType == 'date') {
      if (value == null || value == '') {
        return this.globals.MIN_DATE;
      } else {
        //return this.globals.transformDateToDatabase(value);
        return Math.round(this.globals.parseDate(value, 'dd.mm.yyyy').getTime() / 1000);
      }
    } else if (field.dataType == 'boolean') {
      return value && value != '' ? this.parseBoolean(value.toString()) : false;
      //return value;
    } else if (field.dataType == 'number') {
      return value && value != '' ? Number.parseFloat(value.toString().replace(',', '.')) : 0;
      //return value;
    } else if (field.dataType == 'string') {
      if (field.maxLenth)
        return value.length > field.maxLenth ? value.substring(0, field.maxLenth) : value
      else
        return value;
    } else
      return value;

  }


  // parse string containing "true", "1", "yes" "aktiv" to boolean true else false
  parseBoolean(value: string): boolean {
    if (value == null || value == '') {
      return false;
    } else {
      return value.toLowerCase() === 'true' || value.toLowerCase() === '1' || value.toLowerCase() === 'yes' || value.toLowerCase() === 'ja' || value.toLowerCase() === 'aktiv' || value.toLowerCase() === 'wahr';
    }
  }

  showMessage(msg: string) {

    this.confirmationService.confirm({
      message: msg,
      header: 'Daten importieren',
      acceptLabel: 'OK',
      rejectVisible: false,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        console.log('ok');
      },
    });

  }

}
