import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Subject} from 'rxjs';

import {Wap, WapImport, WapViewModel} from '../shared/wap.model';
import {Gauge} from '../shared/gauge.model'
import {Globals} from '../globals';
import {GaugeChange} from 'src/app/water-access-point/gaugechange.model';
import {Reading, ReadingViewModel} from '../shared/reading.model';
import {WaterUsage} from '../shared/usage.model';
import {Counterlist} from './counterlist.model'
import {WaterAccessPointInfo} from './wapinfo.model'
import {WaterAccessPointOwner} from './wapowner.model'
import {WapCounterSearch} from './wapcountersearch.model';
import {WapCounterListViewModel} from './wapcounterlistviewmodel.model'
import {WapOwnerViewModel} from './wapowner.viewmodel'
import {CreateUsageData} from './create-usage.model';
import {CounterListTemplateModel} from "../services/counter-list/counter-list-card/counter-list-template.model";
import {WebReplyMessage} from "../shared/web-reply-message.model";

@Injectable({providedIn: 'root'})
export class WapService {
  waps = new Subject<WapViewModel[]>();
  wapArray = new Array<WapViewModel>();

  selectedWap = null;
  selectedSubWap = null;
  wap = new Subject<Wap>();

  isChild: boolean;
  parentWap: Wap;

  totalWaps: number = 1;

  searchString: string = '';

  constructor(
    private http: HttpClient,
    private globals: Globals,
    private router: Router
  ) {
  }

  getNextNumber() {
    const url = this.globals.APP_URL + 'numberrange/wateraccesspoint/next-number';
    return this.http.get<{ id: string }>(url);
  }

  updateWap(wap: Wap) {
    const url = this.globals.APP_URL + 'wap';
    this.http.put<Wap>(url, wap).subscribe((respData) => {

      const idx = this.wapArray.findIndex((x) => x.id === wap.id);
      this.wapArray[idx] = new WapViewModel(respData);
      if (this.isChild) {
        this.selectedSubWap = respData;
        this.router.navigate(['wap-module', 'wap', this.parentWap.id, 'childs', this.selectedSubWap.id, 'edit']);
      } else {
        this.selectedWap = respData;
        this.router.navigate(['wap-module', 'wap', this.selectedWap.id, 'edit']);
      }
    });
  }

  putWap(wap: Wap) {
    const url = this.globals.APP_URL + 'wap';
    return this.http.put<Wap>(url, wap);
  }

  readWaps(search: string, pageSize: number, page: number, memberId: number) {
    this.searchString = search;
    const url = this.globals.APP_URL + 'wap';
    let params = new HttpParams();
    if (memberId > 0) {
      params = params.append('memberid', memberId);
    }
    params = params.append('search', search);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('page', page.toString());
    this.http
      .get<WapViewModel[]>(url, {
        params: params,
        observe: 'response',
      })
      .subscribe((respData) => {
        this.totalWaps = parseInt(respData.headers.get('x-total-count'));
        this.wapArray = respData.body.slice();
        this.waps.next(this.wapArray);
      });
  }

  getWaps(search: string, pageSize: number, page: number, memberId: number) {
    const url = this.globals.APP_URL + 'wap';
    let params = new HttpParams();
    if (memberId > 0) {
      params = params.append('memberid', memberId);
    }
    params = params.append('search', search);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('page', page.toString());
    return this.http
      .get<WapViewModel[]>(url, {
        params: params,
      });
  }

  addWap(wap: Wap) {
    const url = this.globals.APP_URL + 'wap';
    this.http.post<Wap>(url, wap).subscribe((respData) => {

      this.wapArray.push(new WapViewModel(respData));
      if (this.isChild) {
        this.selectedSubWap = respData;
        this.router.navigate(['wap-module', 'wap', this.parentWap.id, 'childs', this.selectedWap.id, 'edit']);
      } else {
        this.selectedWap = respData;
        this.router.navigate(['wap-module', 'wap', this.selectedWap.id, 'edit']);
      }
    });
  }

  postWap(wap: Wap) {
    const url = this.globals.APP_URL + 'wap';
    return this.http.post<Wap>(url, wap);
  }

  readWap(id: number) {
    const url = this.globals.APP_URL + 'wap/' + id;
    return this.http.get<Wap>(url);
  }

  findByGaugeId(gaugeId: number) {
    const url = this.globals.APP_URL + 'wap/find-by-gaugeid';
    let params = new HttpParams();
    params = params.append('gaugeid', gaugeId.toString());
    return this.http.get<Wap>(url, {params: params});
  }

  deleteWap(id: number) {
    const url = this.globals.APP_URL + 'wap/' + id;
    return this.http.delete<{ 'value': '' }>(url);
  }

  removeWapFromList(w: Wap) {
    const index = this.wapArray.findIndex(wap => wap.id == w.id);
    if (index > -1) {
      this.wapArray.splice(index, 1);
      this.waps.next(this.wapArray);
    }
  }

  getWap(id: number) {
    if (this.isChild) {
      this.selectedSubWap = Object.assign(new Wap(), this.wapArray.find((m) => m.id === id));
      return this.selectedSubWap;
    } else {
      this.selectedWap = Object.assign(new Wap(), this.wapArray.find((m) => m.id === id));
      return this.selectedWap;
    }
  }

  getWapCountByMember(memberId: number) {
    const url = this.globals.APP_URL + 'wap/wapcount';
    let params = new HttpParams();
    params = params.append('memberid', memberId);
    return this.http
      .get<number>(url, {
        params: params,
        observe: 'response',
      });

  }

  changeGauge(wap: Wap, gaugeChange: GaugeChange) {
    const url = this.globals.APP_URL + 'wap/' + wap.id.toString() + "/changegauge";
    this.http.post<Wap>(url, gaugeChange).subscribe((respData) => {
      const idx = this.wapArray.findIndex((x) => x.id === wap.id);
      this.wapArray[idx] = new WapViewModel(respData);

      if (this.isChild) {
        this.selectedSubWap = respData;
        this.wap.next(this.selectedSubWap);
      } else {
        this.selectedWap = respData;
        this.wap.next(this.selectedWap);
      }

    });
  }

  addReading(r: Reading, wap: Wap) {
    const url = this.globals.APP_URL + 'wap/' + wap.id.toString() + "/addreading";
    return this.http.post<Gauge>(url, r);
  }

  deleteReading(rid: number, wap: Wap) {
    const url = this.globals.APP_URL + 'wap/' + wap.id.toString() + "/readings/" + rid.toString();
    return this.http.delete<Gauge>(url);
  }

  getReadings(wap: Wap) {
    const url = this.globals.APP_URL + 'wap/' + wap.id.toString() + "/readings";
    return this.http.get<ReadingViewModel[]>(url);
  }

  addUsage(r: WaterUsage, wap: Wap) {
    const url = this.globals.APP_URL + 'wap/' + wap.id.toString() + "/addusage";
    return this.http.post<Gauge>(url, r);
  }

  deleteUsage(uid: number, wap: Wap) {
    const url = this.globals.APP_URL + 'wap/' + wap.id.toString() + "/usages/" + uid.toString();
    return this.http.delete<Gauge>(url);
  }

  getUsages(wap: Wap) {
    const url = this.globals.APP_URL + 'wap/' + wap.id.toString() + "/usages";
    return this.http.get<WaterUsage[]>(url);
  }

  getChilds(wap: Wap) {
    const url = this.globals.APP_URL + 'wap/' + wap.id.toString() + "/childs";
    return this.http.get<WapViewModel[]>(url);
  }


  getCounterList(noSubWap: boolean, onlyEmail: boolean, onlyMail: boolean) {
    const url = this.globals.APP_URL + 'wap/counterlist';
    let params = new HttpParams();
    params = params.append('noSubWap', noSubWap.toString());
    params = params.append('onlyEmail', onlyEmail.toString());
    params = params.append('onlyMail', onlyMail.toString());
    return this.http.get<Counterlist[]>(url, {params: params});
  }

  sendCounterList(list: Counterlist[]) {
    const url = this.globals.APP_URL + 'wap/counterlist';
    return this.http.post<number>(url, list);
  }

  importWaps(waps: WapImport[]) {
    const url = this.globals.APP_URL + 'wap/import';
    return this.http.post<WapImport[]>(url, waps);
  }

  getWapCounterList(searchParam: WapCounterSearch) {
    const url = this.globals.APP_URL + 'wap/wapcounterlist';
    let params = this.globals.createHttpParamsFromObject(searchParam);
    return this.http
      .get<WapCounterListViewModel[]>(url, {
        params: params,
      });
  }

  getWapInfo(wap: Wap) {
    const url = this.globals.APP_URL + 'wap/' + wap.id.toString() + "/info";
    return this.http.get<WaterAccessPointInfo[]>(url);
  }

  getWapOwner(wap: Wap) {
    const url = this.globals.APP_URL + 'wap/' + wap.id.toString() + "/owner";
    return this.http.get<WapOwnerViewModel[]>(url);
  }

  addWapInfo(wap: Wap, wapInfo: WaterAccessPointInfo) {
    const url = this.globals.APP_URL + 'wap/' + wap.id.toString() + "/addinfo";
    return this.http.post<WaterAccessPointInfo>(url, wapInfo);
  }

  updateWapInfo(wap: Wap, wapInfo: WaterAccessPointInfo) {
    const url = this.globals.APP_URL + 'wap/' + wap.id.toString() + "/updateinfo";
    return this.http.put<WaterAccessPointInfo>(url, wapInfo);
  }

  deleteWapInfo(wapInfoId: number, wap: Wap) {
    const url = this.globals.APP_URL + 'wap/' + wap.id.toString() + "/info/" + wapInfoId.toString();
    return this.http.delete(url);
  }


  createUsage(data: CreateUsageData) {
    const url = this.globals.APP_URL + 'wap/createusage';
    return this.http.post<number>(url, data);
  }

  counterCardPreview(wapId: number, data: CounterListTemplateModel) {
    const url = this.globals.APP_URL + 'wap/' + "countercard-preview/" + wapId.toString();
    return this.http.post<any>(url, data, {responseType: 'blob' as 'json'});
  }

  counterChangeForm(gaugeIds: number[]) {
    const url = this.globals.APP_URL + 'wap/counter-change-form';
    return this.http.post<any>(url, gaugeIds, {responseType: 'blob' as 'json'});
  }

  counterCardPrintAll(noSubWap: boolean, onlyEmail: boolean, onlyMail: boolean, data: CounterListTemplateModel) {
    const url = this.globals.APP_URL + 'wap/counter-card-print-all';
    let params = new HttpParams();
    params = params.append('noSubWap', noSubWap.toString());
    params = params.append('onlyEmail', onlyEmail.toString());
    params = params.append('onlyMail', onlyMail.toString());
    return this.http.post<any>(url, data, {responseType: 'blob' as 'json', params: params});
  }

  counterCardSendAll(data: CounterListTemplateModel) {
    const url = this.globals.APP_URL + 'wap/counter-card-send-all';
    return this.http.post<WebReplyMessage>(url, data, {responseType: 'json'});
  }

}
