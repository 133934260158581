<div class="text-center">
  <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
</div>
<div class="surface-card p-4 shadow-2 border-round" *ngIf="!isLoading">

    <div class="mb-3 flex align-items-center justify-content-between">
        <span class="text-xl font-medium text-900">Mitglieder</span>
        <button pButton pRipple label="Hinzufügen" icon="pi pi-plus" (click)="New()"></button>
    </div>


    <form (ngSubmit)="onSubmit(f)" #f="ngForm">
        <div class="">
            <div class="input-group mb-3">
                <input type="text"
                       name="searchMember"
                       class="form-control input-lg"
                       placeholder="Suchtext"
                       aria-label="Recipient's username"
                       aria-describedby="basic-addon2"
                       ngModel />
                <div class="input-group-append">
                    <button class="btn btn-outline-secondary"
                            [disabled]="!f.valid"
                            type="submit">
                        Suchen
                    </button>
                </div>
            </div>
        </div>
    </form>



    <p-table [value]="members" selectionMode="single" [(selection)]="selectedMember" dataKey="id" responsiveLayout="scroll"
             (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="contactID">Nummer <p-sortIcon field="contactID"></p-sortIcon></th>
                <th>Name</th>
                <th pSortableColumn="address1">Adresse <p-sortIcon field="address1"></p-sortIcon></th>
              <th pSortableColumn="zip">PLZ <p-sortIcon field="zip"></p-sortIcon></th>
              <th pSortableColumn="city">Ort <p-sortIcon field="city"></p-sortIcon></th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-member>
            <tr [pSelectableRow]="member">
                <td>{{ member.contactID }}</td>
                <td >
                    {{
                    member.orgName
                    ? member.orgName
                    : member.firstName + " " + member.lastName
                    }}
                </td>
                <td >{{ member.address1 }}</td>
              <td>{{ member.zip }}</td>
              <td>{{ member.city }}</td>
            </tr>
        </ng-template>
    </p-table>


    <p-paginator class="my-paginator"
                 [rows]="15"
                 [totalRecords]="totalMembers"
                 pageLinkSize="3"
                 (onPageChange)="paginate($event)"></p-paginator>


</div>
