import {Component, OnInit, OnDestroy} from '@angular/core';
import {AuthService} from './auth/auth.service'
import {filter, Subscription} from 'rxjs';
import {PrimeNGConfig} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {PaymentTermsService} from './settings/paymentterms/payment-terms.service';
import * as signalR from '@microsoft/signalr';
import {Globals} from './globals';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {


  private routerSubscription: Subscription;

  private userSub: Subscription;

  isAutheticated: Boolean = false;

  isPublic: Boolean = false;

  constructor(private auth: AuthService,
              private translateService: TranslateService,
              private paymentTermsService: PaymentTermsService,
              private config: PrimeNGConfig,
              private router: Router,
              private globals: Globals) {
  }


  ngOnInit() {
    this.userSub = this.auth.user.subscribe(user => {
      this.isAutheticated = !!user;
      this.paymentTermsService.getItems('', 100, 0);
    });

    this.routerSubscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        console.log(event.url);
        if (event.url.startsWith('/public/')) {
          this.isPublic = true;
        }
      });

    this.auth.autologin();

    this.translateService.setDefaultLang('de');
    this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));

  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
  }

}
