import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-task-start',
  templateUrl: './task-start.component.html',
  styleUrls: ['./task-start.component.css']
})
export class TaskStartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
