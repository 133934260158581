import { Component } from '@angular/core';
import {ModulePermissionModel} from "../../shared/roles-service/modulePermissionModel";
import {RoleModel} from "../../shared/roles-service/role.model";
import {FunctionModulePermissionModel} from "../../shared/roles-service/functionPermissionModel";

// @ts-ignore
interface TreeNode {
  label: string;
  data?: any;
  children?: TreeNode[];
}
@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.css']
})
export class UserRolesComponent {

  data =
    [
      {
        "data": {
          "id": 1,
          "name": "Mitglieder",
          "access": true
        },
        "children": [
          {
            "data": {
              "id": 1,
              "parent": 1,
              "name": "Verwaltung",
              "access": "READ"
            }
          },
          {
            "data": {
              "id": 2,
              "parent": 1,
              "name": "Zählerstände",
              "access": "WRITE"
            }
          },
          {
            "data": {
              "id": 3,
              "parent": 1,
              "name": "Abrechnung",
              "access": "WRITE"
            },
            "children": [
              {
                "data": {
                  "id": 7,
                  "parent": 3,
                  "name": "Unterkategorie 1",
                  "access": "READ"
                }
              },
              {
                "data": {
                  "id": 8,
                  "parent": 3,
                  "name": "Unterkategorie 2",
                  "access": "WRITE"
                }
              }
            ]
          }
        ]
      },
      {
        "data": {
          "id": 2,
          "name": "Anschlüsse",
          "access": false
        },
        "children": [
          {
            "data": {
              "id": 4,
              "parent": 2,
              "name": "Verwaltung",
              "access": "READ"
            }
          },
          {
            "data": {
              "id": 5,
              "parent": 2,
              "name": "Zählerstände",
              "access": "READ"
            }
          },
          {
            "data": {
              "id": 6,
              "parent": 2,
              "name": "Abrechnung",
              "access": "WRITE"
            }
          }
        ]
      }
    ]

  rolesList = [
    new RoleModel(1, "Administrator",  this.convertToTreeNodeArray(this.data)),
    new RoleModel(2, "Benutzer",  this.convertToTreeNodeArray(this.data))

  ]

  dropdownOptions = [
    {
      value: 'DISABLED',
      label: 'Kein Zugriff'
    },
    {
      value: 'READ',
      label: 'Lesen'
    },
    {
      value: 'WRITE',
      label: 'Lesen und Schreiben'
    }
  ]

  selectedRole: RoleModel = new RoleModel(-1, "Test", []);

  showEditRoleDialog = false;
  showNewRoleDialog = false;

  dialogTitle = "";

  newRoleName = "";

  editRole = new RoleModel(-1, "Test", []);

  onEditRoleClicked(selectedRole: RoleModel) {
    this.dialogTitle = selectedRole.roleName;
    this.selectedRole = selectedRole;
    this.editRole = new RoleModel(selectedRole.userId, selectedRole.roleName, this.deepCopyTreeNodes(selectedRole.permissions));
    this.showEditRoleDialog = true;
  }

  onSaveClicked(){
    this.showEditRoleDialog = false;
    this.selectedRole.permissions = this.deepCopyTreeNodes(this.editRole.permissions);
  }

  onCanceledClicked(){
    console.log(    this.convertToOriginalFormat(this.editRole.permissions));
    this.showEditRoleDialog = false;
  }

  onNewRoleClicked() {
    this.showNewRoleDialog = true;
  }

  onAddRoleClicked() {
    this.rolesList.push(new RoleModel(this.rolesList.length, this.newRoleName, this.convertToTreeNodeArray(this.data)));
    this.showNewRoleDialog = false;
  }

  deepCopyTreeNodes(treeNodes: TreeNode[]): TreeNode[] {
    return treeNodes.map(treeNode => {
      const item: TreeNode = {
        label: treeNode.label,
        data: { ...treeNode.data }, // Shallow copy of the data object
      };

      if (treeNode.children && treeNode.children.length > 0) {
        item.children = this.deepCopyTreeNodes(treeNode.children);
      }

      return item;
    });
  }

  convertToOriginalFormat(treeNodes: TreeNode[]): any[] {
    return treeNodes.map(treeNode => {
      const item: any = {
        data: { ...treeNode.data }, // Shallow copy of the data object
      };

      if (treeNode.children && treeNode.children.length > 0) {
        item.children = this.convertToOriginalFormat(treeNode.children);
      }

      return item;
    });
  }

  convertToTreeNodeArray(data: any[]): TreeNode[] {
    if (!data || data.length === 0) {
      return [];
    }

    return data.map(item => {
      const treeNode: TreeNode= {
        label: item.data.name,
        data: { ...item.data },
      };

      if (item.children && item.children.length > 0) {
        treeNode.children = this.convertToTreeNodeArray(item.children);
      }

      return treeNode;
    });
  }

  isParent(node): boolean { //if it is a parent it cant have a parent in this strucute. needed to decide if to set a checkbox or a dropdown
    return node.parent == undefined;
  }

}
