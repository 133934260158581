<p-confirmDialog header="Info" icon="pi pi-exclamation-triangle" key="confirm"></p-confirmDialog>
<p-toast position="bottom-right" key="tl"></p-toast>
<div class="mb-3 flex align-items-center justify-content-between">
    <div>
        <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
        <span class="text-xl font-medium text-900 mx-3">Aufgabe</span>
    </div>
    <div>
        <button pButton pRipple label="Löschen" icon="pi pi-trash" (click)="Delete()" class="mx-3"></button>
        <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="Save()"></button>
    </div>
</div>
<form #docData="ngForm">
    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <label>Titel</label>
                <input type="text" name="title" placeholder="Neue Aufgabe" [(ngModel)]="task.title" class="form-control" />
            </div>
        </div>
    </div>
    <div class="row">

      <div class="col-md-6">
        <div class="form-group">
          <label>Verantwortlich</label><br>
          <p-inputGroup>
            <input type="text" name="who" placeholder="Name" [(ngModel)]="task.who" class="form-control" />
            <button type="button" pButton icon="pi pi-search" class="p-button-success" (click)="onSelectMember()"></button>
          </p-inputGroup>
        </div>
      </div>

        <div class="col-md-2">
            <div class="form-group">
                <label>Fällig am</label><br>
                <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="true"
                            [ngModelOptions]="{standalone: true}" [(ngModel)]="dueDate">
                </p-calendar>
            </div>
        </div>
        <div class="col-md-2 col-btn">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" name="done" [(ngModel)]="task.done" id="2">
                <label class="form-check-label" for="2">Erledigt</label>
            </div>

        </div>




    </div>


    <div class="row">
        <div class="col-md-12">
            <div class="form-group">
                <div class="d-md-flex justify-content-between align-items-center">
                    <label>Notizen</label>

                </div>
                <textarea name="notes"
                          placeholder="Notizen"
                          class="form-control mb-3"
                          [(ngModel)]="task.notes"
                          style="height:100px;">
                                  </textarea>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-2" *ngIf="task.reminder">
            <div class="form-group">
                <label>Erinnern am</label><br>
                <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false" [locale]="globals.de"
                            [ngModelOptions]="{standalone: true}" [(ngModel)]="reminderDate">
                </p-calendar>
            </div>
        </div>

        <div class="col-md-2 col-btn">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" name="remind" [(ngModel)]="task.reminder" id="3">
                <label class="form-check-label" for="2">Wiedervorlage</label>
            </div>

        </div>

    </div>

</form>




