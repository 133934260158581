
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import {Contact} from "./contact.model";

@Pipe({
  name: 'ContactNameConverter'
})
export class ContactNameConverter  implements PipeTransform {
  transform(value: Contact): string {
    return (value.orgName != '' ? value.orgName : value.firstName + ' ' + value.lastName) + (value.name2 != '' ? ' ' + value.name2 : '');
  }
}
