
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header d-md-flex ">
        <h5 class="card-title">Neue Wasserrechnung erstellen</h5>
      </div>
      <div class="card-body card-sidebar-menu">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>Rechnungslauf auswählen</label>
              <select class="form-control" name="paymentMethode" id="paymentMethode" [(ngModel)]="selectedRun" style="height:35px;" (change)="onSelectChanged()">
                <option *ngFor="let pm of this.runs" [value]="pm.key">{{pm.key}}</option>
              </select>

            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>Abrechnungszeitraum von</label>
              <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false" [locale]="globals.de"
                          name="deliveryStart" [(ngModel)]="deliveryStart">
              </p-calendar>
            </div>
          </div>
          <div class="col-md-3" >
            <div class="form-group">
              <label>Abrechnungszeitraum bis</label>
              <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false" [locale]="globals.de"
                          name="deliveryEnd" [(ngModel)]="deliveryEnd">
              </p-calendar>
            </div>
          </div>

          <div class="col-md-3 col-btn">
            <div class="form-check">
              <input type="checkbox" class="form-check-input" name="detectDate" [(ngModel)]="autoDetectDate" id="2">
              <label class="form-check-label" for="2">Zeitraum automatisch ermitteln</label>
            </div>
          </div>
        </div>
        <div class="row my-4">

          <div class="col-md-3 col-btn">
            <div class="form-group">
              <p-radioButton name="groupname" value="single" label="Ausgewählte Abrechnung" [(ngModel)]="selectedValue"></p-radioButton>
            </div>
          </div>
          <div class="col-md-3 col-btn">
            <div class="form-group">
              <p-radioButton name="groupname" value="all" label="Gesamte Auswahl" [(ngModel)]="selectedValue"></p-radioButton>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label>Rechnungsdatum</label>
              <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="true" [locale]="globals.de"
                          name="invoiceDate" [(ngModel)]="invoiceDate">
              </p-calendar>
            </div>
          </div>
            <div class="col-md-3 col-btn">
              <div class="form-group">
                <button class="btn btn-outline-secondary"
                        (click)="onCreateInvoice()">
                  Rechnung(en) erzeugen
                </button>
              </div>
            </div>


          </div>

      </div>
    </div>
  </div>
</div>
<br />
<div class="row">
  <div class="col-md-12">
    <div class="card">

      <div class="card-body card-sidebar-menu my-4">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <div class="d-md-flex justify-content-between align-items-center">
                <label>Rechnungsadresse</label>
              </div>
              <textarea name="address"
                        placeholder="Name und Anschrift des Empfängers"
                        class="form-control mb-3"
                        readonly
                        style="height:100px; resize:none;">{{invoiceAddress}}</textarea>

            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Abrechnungsart</label>
              <input type="text" name="billType" placeholder="" class="form-control" [(ngModel)]='billType' readonly />
            </div>

            </div>
          </div>

      </div>
    </div>
  </div>
</div>
