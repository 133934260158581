import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { DatePipe } from '@angular/common';

import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

import { MenuItem } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

import { Gauge } from '../../../shared/gauge.model';
import { GaugeChange } from 'src/app/water-access-point/gaugechange.model';
import { GaugeService } from '../gauge.service';
import { Globals } from 'src/app/globals';
import { Wap } from 'src/app/shared/wap.model';
import { Reading } from 'src/app/shared/reading.model';

@Component({
  selector: 'app-gauge-select',
  templateUrl: './gauge-select.component.html',
  styleUrls: ['./gauge-select.component.css'],
})
export class GaugeSelectComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('newData', { static: false }) newCounterForm: NgForm;
  @ViewChild('oldData', { static: false }) oldCounterForm: NgForm;

  subGauge: Subscription;
  gauges: Gauge[];
  totalGauges: number = 1;
  isLoading = false;
  searchString: string = '';
  installDate: Date;
  readingDate: Date;

  items: MenuItem[];

  activeIndex: number = 0;
  maxIndex: number = 0;

  firstCounter: boolean = false;
  gaugeLoaded: string = 'disabled';

  wap: Wap;
  newGauge: Gauge;
  firstReading: Reading;

  oldGauge: Gauge = null;
  lastReading: Reading;

  constructor(
    private gaugeService: GaugeService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public globals: Globals,
    public datepipe: DatePipe,
  ) { }

  ngOnInit(): void {

    this.wap = this.config.data.wap;

    if (!this.wap || this.wap.gaugeID == 0) {
      this.firstCounter = true;
      this.gaugeLoaded = '';
    }
    else {
      this.gaugeService.readGauge(this.wap.gaugeID).subscribe(g => {
        this.oldGauge = g;
        this.fillForm1(g);
      });
    }

    this.items = [
      {
        label: 'Alten Zähler ablesen',
        command: (event: any) => {
          this.activeIndex = 0;
        },
      },
      {
        label: 'Neuen Zähler auswählen',
        command: (event: any) => {
          if (this.maxIndex >= 1) {
            this.activeIndex = 1;
          }
        },
      },
      {
        label: 'Bestätigen',
        command: (event: any) => {
          if (this.maxIndex >= 2) {
            this.activeIndex = 2;
          }
        },
      },
    ];

    //this.gauges = this.gaugeService.gaugeArray;

    if (!this.gauges?.length) this.isLoading = true;

    this.subGauge = this.gaugeService.gauges.subscribe((m) => {
      this.gauges = m;
      this.totalGauges = this.gaugeService.totalGauges;
      this.isLoading = false;
    });

    if (!this.gauges?.length) this.gaugeService.getGauges('', 10, 1);
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.subGauge.unsubscribe();
  }

  onSubmit(form: NgForm) {
    this.searchString = form.value.search;
    this.gaugeService.getGauges(this.searchString, 20, 1);
    //form.reset();
  }

  onSelected(c: Gauge) {
    //this.ref.close(c);
    this.newGauge = c;
    this.activeIndex = 2;
    if (this.maxIndex < this.activeIndex) {
      this.maxIndex = this.activeIndex;
    }
    this.fillForm2();
  }

  fillForm1(g: Gauge) {
    this.oldCounterForm.setValue({
      newcounts: g.lastCount,
      serial: g.serial,
      counterCounts: g.lastCount,

      lastReadingDate: new Date().toLocaleDateString(),
      readingDate: new Date()
    });
  }

  fillForm2() {
    this.newCounterForm.setValue({
      serial: this.newGauge.serial,
      newcounts: this.newGauge.installCount,
      installDate: new Date(),
      oldSerial: this.firstCounter ? '' : this.oldGauge.serial,
      oldCounterCounts: this.firstCounter ? 0 : this.oldGauge.lastCount,
      lastReadingDate: this.firstCounter ? '' : new Date().toLocaleDateString(),
    });
  }

  onNext() {
    this.activeIndex = 1;
    if (this.maxIndex < this.activeIndex) {
      this.maxIndex = this.activeIndex;
    }
  }

  onSave() {
    this.firstReading = new Reading();
    this.firstReading.gaugeID = this.newGauge.id;
    this.firstReading.readingDate = this.datepipe.transform(new Date(this.installDate.getFullYear(), this.installDate.getMonth(), this.installDate.getDate()), 'MM.dd.yyyy');
    this.firstReading.readingDate2 = Math.round(this.installDate.getTime() / 1000);
    this.firstReading.count = this.newCounterForm.value.newcounts;

    this.newGauge.installDate = this.datepipe.transform(new Date(this.installDate.getFullYear(), this.installDate.getMonth(), this.installDate.getDate()), 'MM.dd.yyyy');
    this.newGauge.installDate2 = Math.round(this.installDate.getTime() / 1000);

    if (this.oldGauge) {
      this.lastReading = new Reading();
      this.lastReading.gaugeID = this.oldGauge.id;
      this.lastReading.readingDate = this.datepipe.transform(new Date(this.readingDate.getFullYear(), this.readingDate.getMonth(), this.readingDate.getDate()), 'MM.dd.yyyy');
      this.lastReading.readingDate2 = Math.round(this.readingDate.getTime() / 1000);
      this.lastReading.count = this.oldCounterForm.value.newcounts;
    }


    var changeGauge = new GaugeChange();
    changeGauge.oldGauge = this.oldGauge;
    changeGauge.lastReading = this.lastReading;
    changeGauge.newGauge = this.newGauge;
    changeGauge.firstReading = this.firstReading;

    this.ref.close(changeGauge)
  }

  paginate(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    this.gaugeService.getGauges(this.searchString, 10, event.page + 1);
  }
}
