import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Globals, KVP_GROUPS} from '../../../../globals';
import {EquipmentSelectComponent} from '../../../../masterdata/equipment/equipment-select/equipment-select.component';
import {Equipment} from '../../../../shared/equipment.model';
import {KvpService} from '../../../../shared/kvp.service';
import {KvpSetting} from '../../../../shared/kvpsetting.model';
import {MaintenancePlanEntry} from '../../maintenance.planentry.model';
import {MaintenancePlanService} from '../../maintenance-plan.service';
import {EquipmentService} from '../../../../masterdata/equipment/equipment.service';
import {Contact} from "../../../../shared/contact.model";
import {ContactSelectComponent} from "../../../../shared/contact-select/contact-select.component";
import {ContactService} from "../../../../shared/contact.service";
import {MaintenanceWorkResp} from "../../maintenance-work-resp.model";
import {PaginatorState} from "primeng/paginator";
import {ConfirmationService, MessageService} from "primeng/api";
import {WebReplyMessage} from "../../../../shared/web-reply-message.model";

@Component({
  selector: 'app-maintenance-plan-edit',
  templateUrl: './maintenance-plan-edit.component.html',
  styleUrls: ['./maintenance-plan-edit.component.css'],
  providers: [DialogService, MessageService],
})
export class MaintenancePlanEditComponent implements OnInit {

  entry: MaintenancePlanEntry = new MaintenancePlanEntry();
  equipmentName: string;

  selectedInterval: any;

  editMode: boolean;
  id: number;

  active1: number = 0;

  contacts: Contact[] = [];
  selectedContact: Contact;
  responsible: MaintenanceWorkResp[] = [];
  newResp: MaintenanceWorkResp = new MaintenanceWorkResp();

  ref: DynamicDialogRef;

  activeIndex: number = 0;

  respDialogVisible: boolean = false;
  dialogTitle: string = 'Neue Person hinzufügen';
  isEditPerson: boolean = false;
  searchMember: string = '';
  firstPage: number = 0;
  beginDate: Date = new Date();
  endDate: Date;

  steps = [{
    label: 'Mitglied auswählen', command: () => {
      this.activeIndex = 0;
    },
  }, {
    label: 'Details festlegen', command: () => {
      this.activeIndex = 1;
    },
  },];
  totalContacts: number;

  constructor(
    public entityService: MaintenancePlanService,
    private route: ActivatedRoute,
    private equipmentService: EquipmentService,
    private router: Router,
    public globals: Globals,
    public dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private contactService: ContactService
  ) {
  }

  ngOnInit(): void {


    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
      this.editMode = params['id'] != 'new';
      this.initForm();
    });


  }

  initForm() {
    if (this.editMode) {
      this.entityService.readItem(this.id).subscribe((resp) => {
        this.entry = resp;
        var idx = this.globals.serviceIntevals.findIndex((i) => i.key === this.entry.interval)
        if (idx >= 0) {
          this.selectedInterval = this.globals.serviceIntevals[idx];
        } else {
          this.selectedInterval = this.globals.serviceIntevals[0];
        }

        this.readResp();

        if (this.entry.equipmentId > 0) {
          this.equipmentService.readItem(this.entry.equipmentId).subscribe((e) => {
            this.equipmentName = e.shortText;
          });
        }

      });


    } else {
      this.entry = new MaintenancePlanEntry();
    }
  }

  readResp() {
    this.entityService.getRespForWorkEntry(this.entry.id).subscribe((resp) => {
      this.responsible = resp;
    });
  }


  onSave() {
    this.entry.interval = this.selectedInterval.key;
    this.doSave();
  }

  doSave() {
    if (this.editMode) {
      console.log(this.entry);
      this.entityService.putItem(this.entry).subscribe((resp) => {
        this.entry = resp;
        this.messageService.add({
          severity: 'success', summary: 'Info', detail: 'Eintrag gespeichert', key: 'tl'
        });
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: 'Eintrag konnte nicht gespeichert werden'
        });

      });
    } else {
      this.entityService.postItem(this.entry).subscribe((resp) => {
        this.entry = resp;
        this.messageService.add({severity: 'success', summary: 'Erfolg', detail: 'Eintrag gespeichert'});
        this.router.navigate(['services', 'maintenance', 'plan', this.entry.id]);
      }, error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Fehler',
          detail: 'Eintrag konnte nicht gespeichert werden'
        });
      });
    }
  }


  onSelectEquipment() {
    this.ref = this.dialogService.open(EquipmentSelectComponent, {
      header: 'Analgenteil auswählen',
      width: '75%',
      contentStyle: {'max-height': '650px', overflow: 'auto'},
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((e: Equipment) => {
      console.log(e);
      if (e) {
        this.entry.equipmentId = e.id;
        this.equipmentName = e.shortText;
      }
    });
  }

  Back() {
    this.entityService.itemArray = [];
    this.router.navigate(['services', 'maintenance', 'plan']);
  }

  Delete() {
    this.confirmationService.confirm(
      {
        message: 'Wollen Sie diesen Eintrag wirklich löschen?',
        acceptLabel: 'Ja',
        rejectLabel: 'Nein',
        accept: () => {
          this.entityService.deleteItem(this.entry.id).subscribe(() => {
            this.entityService.itemArray = [];
            this.router.navigate(['services', 'maintenance', 'plan']);
            this.messageService.add({key: 'tl', severity: 'info', summary: 'Eintrag wurde gelöscht.'});
          }, error => {
            var msg: WebReplyMessage = error.error;
            this.messageService.add({key: 'tl', severity: 'error', summary: 'Fehler! ' + msg.message});
          });
        },
      });

  }

  Save() {
    this.onSave();
  }

  onNewPerson() {
    if (this.contacts.length == 0)
      this.onSearchMember();
    this.isEditPerson = false;
    this.activeIndex = 0;
    this.dialogTitle = 'Neue Person hinzufügen';
    this.beginDate = new Date();
    this.endDate = null;

    this.respDialogVisible = true;
  }

  onEditPerson(person: any) {
    this.isEditPerson = true;
    this.activeIndex = 1;
    this.dialogTitle = 'Person bearbeiten';
    this.newResp = Object.assign(new MaintenanceWorkResp(), person);
    if (this.newResp.startDate > this.globals.MIN_DATE)
      this.beginDate = new Date(this.newResp.startDate * 1000);
    if (this.newResp.endDate > this.globals.MIN_DATE)
      this.endDate = new Date(this.newResp.endDate * 1000);

    this.respDialogVisible = true;
  }

  onNext() {
    this.newResp = new MaintenanceWorkResp();
    this.newResp.contactName = this.selectedContact.orgName != '' ? this.selectedContact.orgName : this.selectedContact.firstName + ' ' + this.selectedContact.lastName;
    this.newResp.contactId = this.selectedContact.id;
    this.newResp.workEntryId = this.entry.id;
    this.activeIndex++;
  }

  onStepBack() {
    this.activeIndex--;
  }

  onSavePerson() {
    this.respDialogVisible = false;
    if (this.beginDate)
      this.newResp.startDate = Math.round(this.beginDate.getTime() / 1000);
    if (this.endDate)
      this.newResp.endDate = Math.round(this.endDate.getTime() / 1000);

    if (!this.isEditPerson) {
      this.entityService.addResp(this.newResp).subscribe((resp) => {
        this.responsible.push(resp);
        this.messageService.add({
          severity: 'success',
          summary: 'Info',
          detail: 'Verantwortliche Person hinzugefügt',
          key: 'tl'
        });
      });

    } else {
      this.entityService.updateResp(this.newResp).subscribe((resp) => {
        var idx = this.responsible.findIndex((r) => r.id === resp.id);
        this.responsible[idx] = resp;
        this.messageService.add({
          severity: 'success',
          summary: 'Info',
          detail: 'Änderungen wurden gespeichert',
          key: 'tl'
        });
      });
    }

  }

  onDeletePerson(person: any) {

    this.confirmationService.confirm({
      message: 'Wollen Sie diese Person wirklich löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      accept: () => {
        this.entityService.deleteResp(person.id).subscribe(() => {
          this.responsible = this.responsible.filter((r) => r.id !== person.id);
          this.messageService.add({severity: 'success', summary: 'Info', detail: 'Person wurde gelöscht', key: 'tl'});
        });
      },
    });


  }

  onPersonRowSelect(data: any) {
    this.onNext();
  }

  onSearchMember() {
    this.contactService.readAllContacts(this.searchMember, 10, 1).subscribe((data) => {
      this.totalContacts = parseInt(data.headers.get('x-total-count'));
      this.contacts = data.body;
    });
  }

  paginate(event: PaginatorState) {

    this.firstPage = event.first;
    this.contactService.readAllContacts(this.searchMember, 10, event.page + 1).subscribe((data) => {
      this.contacts = data.body;
    });
  }
}
