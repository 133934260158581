
export class WapCounterSearch
{
  search: string = "";
  zone: string = "";
  pipe: string = "";
  address: string = "";
  city: string = "";
  calib: boolean = false;
  until: Date = new Date();
}
