import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Globals } from '../../globals'
import { Wap, WapViewModel } from '../../shared/wap.model';
import { WapService } from '../wap.service';
import { Router, ActivatedRoute, Event, NavigationEnd, Params } from '@angular/router';

@Component({
  selector: 'app-wap-list',
  templateUrl: './wap-list.component.html',
  styleUrls: ['./wap-list.component.css'],
})
export class WapListComponent implements OnInit, OnDestroy {
  subWap: Subscription;
  rSub: Subscription;
  totalWaps: number = 1;
  searchString: string = '';

  isLoading = false;
  waps: WapViewModel[];
  selectedWap: Wap;
  selectedSubWap: Wap;

  userSelectedWap: Wap;

  parentid: number = 0;

  memberid: number;

  prevWaps: WapViewModel[];

  title: string = 'Wasseranschlüsse';

  constructor(public wapService: WapService, private router: Router, private route: ActivatedRoute, private global: Globals) { }

  ngOnInit(): void {

    this.searchString = this.wapService.searchString;
    this.waps = this.wapService.wapArray;
    this.totalWaps = this.wapService.totalWaps;


    if (!this.waps?.length) {
      this.isLoading = true;
      this.wapService.isChild = false;
    }





    this.route.params.subscribe((params: Params) => {
      if (this.router.url.includes("childs")) {
        this.parentid = +params['id'];
        this.selectedWap = this.wapService.selectedWap;

        this.wapService.isChild = true;
        this.title = "Hauptanschluss: " + this.selectedWap.accessPointID + '(' + this.selectedWap.address1 + ')';
        this.wapService.parentWap = this.selectedWap;
        this.selectedWap = null;
        this.prevWaps = this.waps;
        this.waps = new Array();
        this.wapService.getChilds(this.wapService.parentWap).subscribe((resp) => {
          this.waps = resp;
        })

        this.isLoading = false;

      }
      else {
        this.parentid = 0;
      }
    });

    this.memberid = +this.route.snapshot.queryParams["memberid"];

    if (this.wapService.isChild && this.router.url.endsWith("wap")) {
      this.title = 'Wasseranschlüsse';
      this.wapService.isChild = false;
      if (this.wapService.parentWap != null && this.selectedWap == null) {
        this.selectedWap = this.wapService.parentWap;
        this.wapService.parentWap = null;
        this.wapService.wapArray = this.prevWaps;
        this.waps = this.prevWaps;
      }
    }

    this.rSub = this.router.events.subscribe((e: Event) => {
      if (e instanceof NavigationEnd) {

        console.log(this.route.snapshot);

        // Show loading indicator
        if (this.route.snapshot.children[0].url.length === 2) {
          if (this.route.snapshot.children[0].url[1].path === "edit" || this.route.snapshot.children[0].url[1].path === "usage") {

            this.title = 'Wasseranschlüsse';
            this.wapService.isChild = false;
            if (this.wapService.parentWap != null && this.selectedWap == null) {
              this.selectedWap = this.wapService.parentWap;
              this.wapService.parentWap = null;
              this.wapService.wapArray = this.prevWaps;
              this.waps = this.prevWaps;
            }
          }
          else {
            this.wapService.isChild = true;
            this.title = "Anschluss " + this.selectedWap.accessPointID;
            this.wapService.parentWap = this.selectedWap;
            this.selectedWap = null;
            this.prevWaps = this.waps;
            this.waps = [];
            this.wapService.getChilds(this.wapService.parentWap).subscribe(resp => {
              this.waps = resp;
            });

            this.isLoading = false;
          }
        }
        else if (this.route.snapshot.children[0].url.length === 3 || this.route.snapshot.children[0].url.length === 4) {
        }
        else {
          this.title = 'Wasseranschlüsse';
          this.wapService.isChild = false;
          if (this.wapService.parentWap != null && this.selectedWap == null) {
            this.selectedWap = null;
            this.wapService.parentWap = null;
            this.wapService.wapArray = this.prevWaps;
            this.waps = this.prevWaps;
          }
        }
      }

    });

    this.subWap = this.wapService.waps.subscribe((w) => {

      this.waps = w;
      this.totalWaps = this.wapService.totalWaps;
      this.isLoading = false;
      if (this.memberid > 0 && this.wapService.wapArray.length > 0) {
        this.navigateToWap();
      }
      //else if (this.wapService.isChild && this.wapService.wapArray.length > 0) {
      //  this.onSelected(this.wapService.wapArray[0]);
      //}
    });

    if ((!this.waps?.length && !this.wapService.isChild) || this.memberid > 0) {

      this.wapService.readWaps('', 15, 1, this.memberid > 0 ? this.memberid : 0);
    }
  }

  navigateToWap() {
    /*
    if (this.wapService.selectedWap) {

      //var wap = this.wapService.wapArray.find((m) => m.id === this.wapService.selectedWap.id);

      this.wapService.readWap(this.wapService.selectedWap.id).subscribe((resp) => {
        var wap = resp;
      })

      this.userSelectedWap = wap;
      if (wap) {
        this.onSelected2(null);
      }
      else {
        this.userSelectedWap = this.wapService.wapArray[0];
        this.onSelected2(null);
      }
    }
    else {
      this.userSelectedWap = this.wapService.wapArray[0];
      this.onSelected2(null);
    }
    */
  }

  ngOnDestroy(): void {
    this.subWap.unsubscribe();
    this.rSub.unsubscribe();
  }

  onAddWap() {
    if (this.wapService.isChild) {
      this.router.navigate(['wap-module', 'wap', this.wapService.parentWap.id, 'childs', 'new']);
    }
    else
      this.router.navigate(['wap-module', 'wap', 'new']);
  }


  onSelected2(event) {

    if (this.wapService.isChild) {
      this.selectedSubWap = this.userSelectedWap;
      this.router.navigate(['wap-module', 'wap', this.wapService.parentWap.id, 'childs', event.data.id, 'edit']);
    }
    else {
      this.selectedWap = this.userSelectedWap;
      this.router.navigate(['wap-module', 'wap', event.data.id, 'edit']);
    }

  }

  onSubmit(form: NgForm) {
    const value = form.value;
    this.memberid = 0;
    this.wapService.readWaps(value.searchWap, 15, 1, this.memberid > 0 ? this.memberid : 0);
  }

  onBack() {
    this.router.navigate(['wap-module', 'wap', this.wapService.selectedWap.id, 'edit']);
  }
  paginate(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    this.wapService.readWaps(this.searchString, 15, event.page + 1, this.memberid > 0 ? this.memberid : 0);
  }

  New() {
    this.onAddWap();
  }

  Back() {
    this.selectedWap = this.wapService.parentWap;
    this.wapService.parentWap = null;
    this.wapService.wapArray = this.prevWaps;
    this.waps = this.prevWaps;
    this.wapService.isChild = false;
    this.onBack();
  }
}
