
<div class="surface-section px-4 py-6 md:px-6 lg:px-8">
    <div class="text-600 text-sm mb-3">
        <span>Stand </span>
        <span class="font-bold">November 2020</span>
        <span> von </span>
        <span class="font-bold">Reisinger Technology GmbH</span>
    </div>
    <div class="border-blue-600 font-medium text-2xl p-3 mb-3 text-900" style="border-left: 8px solid">Allgemeine Geschäftsbedingungen</div>

    <div class="line-height-3 text-xl text-700 mb-5">1. Anwendungsbereich</div>
    <div class="line-height-3 text-lg text-700 mb-4">
        1.1. Diese Allgemeinen Geschäftsbedingungen (im Folgenden „AGB“ genannt) gelten für alle
        Lieferungen von Waren und Erbringung von Dienstleistungen, welche durch uns (Reisinger
        Technology GmbH) aufgrund von Bestellungen erfolgen, in ihrer zum Zeitpunkt der jeweiligen
        Bestellung geltenden Fassung einsehbar unter www.headwater.at. Mit Bestellung anerkennt der
        Kunde diese Allgemeinen Geschäftsbedingungen.
    </div>

    <div class="line-height-3 text-lg text-700 mb-4">
        1.2. Abänderungen oder Nebenabreden zu diesen AGB bedürfen zu ihrer Gültigkeit der Schriftlichkeit.
    </div>

    <div class="line-height-3 text-lg text-700 mb-4">
        1.3. Den abschließenden Leistungsinhalt des zwischen uns und dem Kunden zu Stande gekommenen Vertrages regelt die Auftragsbestätigung und / oder ein gesondert dazu geschlossener
        Vertrag, samt den jeweiligen Spezifikationen. Vertragsbedingungen sowie AGB des Kunden wird
        ausdrücklich zur Gänze widersprochen.
    </div>

    <div class="line-height-3 text-lg text-700 mb-4">
        1.4. Sollten einzelne Punkte dieser Allgemeinen Geschäftsbedingungen unwirksam sein, so be-rührt
        dies die Gültigkeit der übrigen Bestimmungen und der unter ihrer Zugrundelegung ge-schlossenen
        Verträge nicht. Die Parteien werden anstelle der unwirksamen Bestimmung eine wirksame, die ihr
        dem Sinn und Zweck nach am nächsten kommt, vereinbaren. Widerspre-chende oder zusätzliche
        Klauseln des Kunden gelten nur, soweit diese von uns ausdrücklich schriftlich anerkannt wurden.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">2. Angebote</div>
    <div class="line-height-3 text-lg text-700 mb-4">
        2.1. Unsere Angebote sind unverbindlich und freibleibend.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        2.2. Abbildungen, Aussagen, Beschreibungen und Spezifikationen in technischen Blättern,
        Werbeunterlagen und sonstigen Darstellungen sind unverbindlich; Gewichtsangaben sind circaWerte. Die Zusicherung von Eigenschaften ist damit nicht verbunden. Diese bedarf stets unserer
        ausdrücklichen schriftlichen Erklärung.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        2.3. An Abbildungen, Zeichnungen, Kalkulationen und sonstigen Dateien oder Unterlagen behalten wir uns alle Rechte vor. Die Weitergabe an Dritte ist nur mit unserer ausdrücklichen
        vorherigen schriftlichen Zustimmung zulässig.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">3. Vertragsabschluss</div>
    <div class="line-height-3 text-lg text-700 mb-4">
        3.1. Unsere Angebote sind unverbindlich. Jeder Auftrag des Kunden bedarf zum Vertragsabschluss
        einer Auftragsbestätigung durch uns. Das Absenden oder Übergeben der vom Kunden bestellten
        Ware bewirkt ebenfalls den Vertragsabschluss.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        3.2. Dem Kunden ist bekannt, dass das Internet kein sicheres Kommunikationsmittel ist und das
        Daten, die über das Internet versandt werden, einerseits bekannt werden können und andererseits
        von Dritten verändert werden können. Der Kunde trägt das Risiko, dass Daten nicht oder nicht in
        der von ihm gesandten Form bei uns ankommen. Wir dürfen darauf vertrauen, dass die Daten in der
        Form, in der sie erhalten werden, vom Kunden gesandt wurden.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">4. Preise</div>
    <div class="line-height-3 text-lg text-700 mb-4">
        4.1. Soweit nichts anderes vereinbart ist, verstehen sich unsere Preise ab Werk (EXW) einschließlich Verladung im Werk ohne Lieferkosten und ohne jegliche Nebenleistungen.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        4.2. Bei Lieferungen außerhalb Österreich fallen zusätzlich die jeweils geltenden Aus- und Einfuhrabgaben an. Für Verpackung, Fracht, Versicherung, Zölle oder andere Nebenleistungen
        anfallende Kosten sowie die Mehrwertsteuer in der jeweils geltenden Höhe werden zusätzlich in
        Rechnung gestellt.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        4.3. Als Währung wird der EURO vereinbart.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">5. Zahlungsbedingungen</div>
    <div class="line-height-3 text-lg text-700 mb-4">
        5.1. Soweit nicht anders vereinbart ist, sind die von uns zu liefernden Waren bzw. zu erbringenden
        Dienstleistungen wie folgt ohne Abzug auf das von uns genannte Bankkonto zu bezahlen:
        <ul class="mb-4 text-lg ml-6 p-0">
            <li class="mb-3">
                40 % Anzahlung innerhalb von 14 Tagen ab dem Datum unserer Auftragsbestätigung bzw. des
                Liefervertrages
            </li>
            <li>
                60 % innerhalb von 14 Tagen ab Meldung der Versandbereitschaft durch uns, jedenfalls vor
                Verladung der Liefergegenstände.
                Die Annahme von Schecks oder Wechseln erfolgt stets erfüllungshalber. Sie werden von uns bis
                zur Einlösung als Sicherheit verwahrt.
            </li>
        </ul>
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        5.2. Ein Skonto wird gewährt, wenn es bei Rechnungsstellung ausdrücklich eingeräumt wird.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        5.3. Kommt der Kunde bei Teilzahlungen mit mindestens zwei Raten in Verzug, sind wir berechtigt, die gesamte offene Forderung aus dem Kundenkontokorrent fällig zu stellen und Verzugszinsen in gesetzlicher Höhe gemäß § 456 UGB geltend zu machen.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        5.4. Im Falle einer wesentlichen Verschlechterung der Vermögensverhältnisse des Kunden, insbesondere bei Aufnahme von Vergleichsgesprächen, Vollstreckungsmaßnahmen Dritter, Wechseloder Scheckprotest, Insolvenzantrag können wir bis zur Bewirkung der Gegenleistung unsere
        Arbeit an dem Auftrag einstellen und unsere Leistung verweigern. Dies gilt auch, wenn eine solche
        Lage des Kunden bei Vertragsschluss bestand, uns aber nicht bekannt war, ab Kenntniserlangung.
        Sofern die Gegenleistung trotz Fristsetzung nicht erbracht wird, haben wir ein Rücktrittsrecht.
        Schadensersatzansprüche bleiben ausdrücklich vorbehalten.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        5.5. Bei Verzug des Kunden mit Zahlung oder seinen sonstigen Leistungen sind wir unbeschadet
        sonstiger Rechte berechtigt, die Lieferung bis zur Erbringung der vereinbarten Gegenleistung unter
        Wahrung der noch offenen Lieferfrist zurückzubehalten oder nach Verstreichen der angemessenen
        Nachfrist vom Vertrag zurückzutreten und/oder Schadenersatz wegen Nichterfüllung zu verlangen.
        In diesem Fall hat der Kunde die gelieferten Waren unverzüglich auf seine Kosten an uns
        zurückzustellen. Die Geltendmachung von Schadenersatzansprüchen für Entwertung, Abnützung,
        Entschädigung für eigene Transportspesen und anderes mehr, bleibt uns vorbehalten.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        5.6. Verzugszinsen, Mahnspesen: Bei Zahlungsverzug verrechnen wir Verzugszinsen in gesetzlicher Höhe gemäß § 456 UGB. Mahnspesen und Verzugszinsen in der gesetzlichen Höhe wer-den
        ab der ersten Mahnung verrechnet. Wird eine zweite Mahnung erforderlich, erfolgen alle künftigen
        Lieferungen nur mehr gegen Vorauszahlung oder Nachnahme. Nach erfolgloser zweiter Mahnung
        erfolgt die Übergabe der Forderung an ein Inkassobüro oder einen Rechtsanwalt. Der Kunde
        verpflichtet sich bei Verletzung seiner vertraglichen Verpflichtungen, alle zur
        zweckentsprechenden Verfolgung der Ansprüche notwendigen Kosten zu ersetzen. Pro Mahnung
        sind € 40,00 und weiters für die Evidenthaltung des Schuldverhältnisses im Mahnwesen € 25,00
        vom Kunden zu ersetzen. Darüber hinaus sind die Kosten des Inkassobüros bis zu den in der jeweils
        geltenden Verordnung für Höchstgebühren im Inkassowesen vorgesehenen Höchstgebühren und
        die Kosten von Rechtsanwälten nach dem Rechtsanwaltstarifgesetz zu er-setzen.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        5.7. Aufrechnung: Grundsätzlich gilt das Verbot der Aufrechnung. Der Kunde kann nur im Falle
        unserer Zahlungsunfähigkeit oder mit konnexen, unbestrittenen oder rechtskräftig festgestellten
        Forderungen aufrechnen. Ein Zurückbehaltungsrecht kann nur hinsichtlich derartiger Forderungen
        geltend gemacht werden.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        5.8. Für den Fall, dass der Kunde nicht oder nicht vollständig innerhalb der vereinbarten Fristen
        bezahlt, sind wir berechtigt, die befristet eingeräumte Softwarelizenz nicht zu verlängern, dies bis
        zur Vollzahlung durch den Kunden.
    </div>


    <div class="line-height-3 text-xl text-700 mb-5">6. Liefertermin, Lieferverzug, Annahmeverzug</div>
    <div class="line-height-3 text-lg text-700 mb-4">
        6.1. Die Lieferzeit beginnt mit dem Datum der vorbehaltlosen Gutschrift der Anzahlung (Ziffer 5.1
        a) auf unserem Bankkonto. Teillieferungen sind zulässig, soweit sie zumutbar sind.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        6.2. Die Lieferfrist wird durch alle vom Parteiwillen unabhängigen Umständen, wie zB Fälle
        höherer Gewalt, unvorhersehbare Betriebsstörungen, behördliche Eingriffe, Transport- und
        Verzollungsverzug, Transportschäden, Ausschuss wichtiger Fertigungsteile, Arbeitskonflikte,
        Naturkatastrophen und Krieg, um die Dauer der Hinderung verlängert.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        6.3. Für den Fall unseres Verzuges gilt folgendes:
        <ul class="mb-4 text-lg ml-6 p-0">
            <li class="mb-3">
                Grundsätzlich ist unsere Haftung auf maximal 5 % des Lieferwertes begrenzt.
            </li>
            <li>
                Die verspätete Erfüllung des Vertrages berechtigt den Vertragspartner nicht zur Geltendmachung von Pönalen oder Schadenersatzansprüchen, es sei denn dies ist ausdrücklich schriftlich
                zwischen den Vertragsparteien vereinbart.
            </li>


        </ul>
        Eine ausdrücklich schriftlich vereinbarte Pönaleverpflichtung ist jedenfalls mit 5% der Auftragssumme begrenzt; sie unterliegt jedenfalls auch dem
        richterlichen Mäßigungsrecht.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        6.4. Im Falle des Abnahmeverzuges des Kunden sind wir berechtigt, die Liefergegenstände auf
        Kosten und Risiko des Kunden einzulagern. Das Gleiche gilt, wenn der Kunde eine spätere als die
        vereinbarte Lieferung wünscht. Die Fälligkeit der bei Versandbereitschaft zu zahlenden
        Kaufpreisrate wird durch die Einlagerung nicht berührt.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">7. Gefahrenübergang </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        Sofern sich aus der Auftragsbestätigung nichts anderes ergibt, gilt Lieferung und Gefahrenübergang „ab Werk“ (EXW) als vereinbart. In den in Ziffer 6.4 genannten Fällen geht die Gefahr im
        Zeitpunkt der Versandbereitschaft auf den Kunden über.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">8. Montage, Inbetriebnahme, Abnahme </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        8.1. Die Montage oder Inbetriebnahme der von uns gelieferten Gegenstände führen wir nur durch,
        soweit diese gesondert schriftlich vereinbart worden ist. Dabei sind Umfang, Ort und Zeit unserer
        Leistungen einvernehmlich festzulegen. Für von uns entsandtes Personal zahlt der
        Kunde/Vertragspartner die bei uns jeweils gültigen Sätze für Arbeitsaufwand, Verpflegung und
        Unterkunft sowie die anfallenden Reisekosten. Reisezeiten gelten als Arbeitszeiten.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        8.2. Die zu montierenden oder in Betrieb zu nehmenden Gegenstände sind vom Kunden zeit-gerecht
        im Aufstellungsraum zur Verfügung zu stellen, und für das von uns entsandte Personal jederzeit
        frei zugänglich zu halten. Der Raum muss für die durchzuführenden Arbeiten unter
        Berücksichtigung der für die Gegenstände notwendigen Umgebungsbedingungen hergerichtet sein.
        Die erforderlichen Werkzeuge (ausgenommen unsere Spezialwerkzeuge) und Vorrichtungen,
        Messgeräte, Energien, Materialien und Betriebsmittel stellt der Kunde in ausreichender Menge und
        Qualität rechtzeitig und für uns kostenlos zur Verfügung.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        8.3. Ist eine Abnahme vereinbart, erfolgt diese in Anwesenheit von Vertretern beider Vertragspartner nach im Voraus zu vereinbarenden Bedingungen. Soweit keine Bedingungen festgelegt
        wurden, bestimmen wir sie nach den branchenüblichen Standards. Beginn, Dauer und Ergebnis der
        Abnahme werden von uns protokolliert. Das Protokoll ist von den Vertretern beider Vertragspartner zu unterschreiben. Bei Meinungsverschiedenheiten sind die unterschiedlichen
        Auffassungen zu protokollieren. Nimmt der Kunde die Gegenstände ohne Abnahme in Betrieb oder
        ist deren Abnahme aus von uns nicht zu vertretenden Gründen nicht innerhalb von sechs Wochen
        ab Lieferung erfolgt, so gelten sie als abgenommen.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        8.4. Die von uns verkaufte/lizenzierte Software wird vorerst befristet eingeräumt. Die Software
        wird eine unbefristete, wenn der Kunde seinen Zahlungsverpflichtungen vollständig und fristgereicht nachgekommen ist.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">9. Sach- und Rechtsmängel </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        9.1 Sachmängel:
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        9.1.1 Wir leisten Gewähr für Sachmängel in der Weise, dass wir für alle Teile (ausgenommen
        Verschleißteile), die sich auf Grund eines vor Gefahrenübergang liegenden Umstandes als mangelhaft herausstellen, nach unserer Wahl durch Beseitigung des Mangels, Preisminderung oder
        Lieferung eines mangelfreien, Teiles Nacherfüllung leisten. Mängel und die voraussichtlichen
        Auswirkungen sind uns vom Kunden unverzüglich, schriftlich und spezifiziert zu rügen. Er-setzte
        Teile werden unser Eigentum. Der Kunde hat uns nach Verständigung mit uns die erforderliche Zeit
        und Gelegenheit zur Nacherfüllung zu geben und uns den ungehinderten Zugang zum
        Liefergegenstand zu ermöglichen. Nur in dringenden Fällen der Gefährdung der Betriebs-sicherheit
        oder zur Abwehr unverhältnismäßig großer, unmittelbar bevorstehender Schäden ist der Kunde
        berechtigt, den Mangel in Abstimmung mit uns selbst oder durch Dritte beheben zu lassen und von
        uns Ersatz der notwendigen Aufwendungen zu verlangen. In anderen Fällen von Eingriffen in den
        Liefergegenstand durch den Kunden oder Dritte erlöschen die Gewährleistungsansprüche des
        Kunden. Von den durch die Nacherfüllung entstehenden Kosten tragen wir, soweit sich die
        Beanstandung als berechtigt erweist, die Kosten des Ersatzstückes, einschließlich der Kosten des
        Versandes, und die angemessenen Kosten des Aus- und Einbaus. Der Kunde stellt uns zum Zwecke
        der Nacherfüllung vorhandene Werk- und Hebezeuge sowie Monteure und Hilfskräfte kostenlos
        zur Verfügung. Für Leistungen im Rahmen der Nacherfüllung über-nehmen wir in gleicher Weise
        Gewähr wie für den Liefergegenstand, höchstens jedoch bis zum Ablauf von sechs Monaten nach
        Ablauf der ursprünglichen Gewährleistungsfrist. § 933b ABGB findet keine Anwendung.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        9.1.2 Die Gelieferte Ware ist vom Kunden unverzüglich nach Ablieferung zu untersuchen. Et-waige
        erkennbare Mängel sind unverzüglich schriftlich und spezifiziert zu rügen. Andernfalls gelten diese
        Mängel als nicht vorhanden.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        9.1.3 Im Fall der Nacherfüllung bei Mängeln erstatten wir keine Aufwendungen, wie Transport-,
        Wege,- Arbeits- und Materialkosten, die darauf beruhen, dass die Sache an einen anderen Ort als
        den Sitz oder die gewerbliche Niederlassung des Kunden, an die geliefert wurde, verbracht wurde.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        9.1.4 Die Mängelansprüche des Kunden einschließlich der Schadensersatzansprüche verjähren in
        einem Jahr ab Lieferung. Abweichend davon gelten für Schadensersatzansprüche wegen Verletzung
        des Lebens, des Körpers oder der Gesundheit oder aufgrund einer grob fahrlässigen oder
        vorsätzlichen Pflichtverletzung durch uns oder unsere Erfüllungsgehilfen die gesetzlichen Fristen.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        9.2. Rechtsmängel
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        9.2.1 Führt die Benutzung des Liefergegenstandes zur Verletzung von gewerblichen Schutz-rechten
        oder Urheberrechten im Inland, werden wir dem Kunden unter Ausschluss weiterer Ansprüche,
        vorbehaltlich Ziffer 9, auf unsere Kosten ein Recht zum weiteren Gebrauch verschaffen oder den
        Liefergegenstand in einer für den Kunden zumutbaren Weise derart ändern, dass die
        Rechtsverletzung nicht mehr besteht. Ist dies zu wirtschaftlich angemessenen Bedingungen oder in
        angemessener Frist nicht möglich, ist der Kunde zum Rücktritt vom Vertrag berechtigt. Unter den
        genannten Bedingungen steht auch uns ein Recht zum Rücktritt vom Ver-trag zu. Außerdem werden
        wir den Kunden von unbestrittenen oder rechtskräftig festgestellten Schadensersatzansprüchen des
        Rechtsinhabers freistellen.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        9.2.2 Unsere in Ziffer 9.2.1 niedergelegten Verpflichtungen bestehen nur, wenn die Rechtsverletzung nicht dadurch entstanden ist, dass der Kunde den Liefergegenstand geändert oder in einer
        nicht vertragsgemäßen Weise verändert hat, der Rechtsmangel nicht auf einer Anweisung des
        Kunden beruht, uns der Kunde unverzüglich über ihm bekannt gewordene Rechtsverletzungen und
        die Androhung von Ansprüchen durch den Rechtsinhaber informiert, der Kunde uns in
        angemessenem Umfang bei der Abwehr der geltend gemachten Ansprüche unterstützt bzw. uns die
        Durchführung von Änderungsarbeiten ermöglicht und uns alle außergerichtlichen und gerichtlichen
        Verteidigungs- und Verhandlungsmöglichkeiten vorbehalten bleiben.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        9.3. Weitere Ansprüche wegen Sach- oder Rechtmängel sind ausgeschlossen.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">10. Schadensersatz </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        10.1. Wir übernehmen keine Haftung für Sach- und Vermögensschäden aus welchem Rechts-grund
        auch immer, insbesondere wegen Verzugs, Unmöglichkeit der Leistung, positiver Forderungsverletzung, Verschulden bei Vertragsabschluss, Mangelfolgeschadens, Mängeln oder
        wegen unerlaubter Handlungen, welche in Folge leichter Fahrlässigkeit durch uns oder Personen,
        die für uns einzustehen haben, verursacht werden. Der Kunde/Vertragspartner hat das Vor-liegen
        von grober Fahrlässigkeit oder Vorsatz zu beweisen.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        10.2. Sind wir zum Schadenersatz verpflichtet, ist die Höhe des zu ersetzenden Schadens in jedem
        Einzelfall mit 30% des Auftragswertes begrenzt. Für mittelbare Schäden, entgangenen Gewinn,
        Zinsverluste, unterbliebene Einsparungen, Folge- und Vermögensschäden, Schäden aus
        Ansprüchen Dritter sowie für den Verlust von Daten und Programmen und deren Wiederherstellung ist unsere Haftung ausdrücklich ausgeschlossen. Sofern, in welchem Fall auch immer,
        eine Pönale zu unseren Lasten vereinbart wurde, unterliegt diese dem richterlichen Mäßigungsrecht und die Geltendmachung von über die Pönale hinausgehenden Schadenersatzes ist
        ausgeschlossen.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        10.3. Schadenersatzansprüche verjähren nach Ablauf eines Jahres ab Übergabe bzw. Übernahme
        der Ware.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">11. Vertragsrücktritt </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        11.1. Neben den allgemeinen gesetzlichen Bestimmungen sind wir auch bei Annahmeverzug (Punkt
        6.) oder anderen wichtigen Gründen, wie insbesondere Eröffnung des Konkursverfahrens über das
        Vermögen eines Vertragspartners oder Abweisung eines Konkursantrages man-gels
        kostendeckenden Vermögens, zum Rücktritt vom Vertrag berechtigt. Für den Fall des Rücktrittes
        haben wir bei Verschulden des Kunden die Wahl, einen pauschalierten Schadenersatz von 30% des
        Bruttorechnungsbetrages oder den Ersatz des tatsächlichen entstandenen Schadens zu begehren.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        11.2. Bei Zahlungsverzug des Kunden sind wir von allen weiteren Leistungs- und Lieferungsverpflichtungen entbunden und berechtigt, noch ausstehende Lieferungen oder Leistungen zurückzuhalten und Vorauszahlungen bzw. Sicherstellungen zu fordern oder – gegebenenfalls nach
        Setzung einer angemessenen Nachfrist – vom Vertrag zurückzutreten.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        11.3. Voraussetzung für den Rücktritt des Kunden vom Vertrag ist, sofern keine speziellere
        Regelung getroffen wurde, ein Lieferverzug, der auf grobes Verschulden von uns zurückzuführen
        ist sowie der erfolglose Ablauf einer gesetzten, angemessenen Nachfrist. Der Rücktritt ist jedenfalls
        schriftlich bei uns geltend zu machen.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        11.4. Tritt der Kunde – ohne dazu berechtigt zu sein – vom Vertrag zurück oder begehrt er
        unberechtigt seine Aufhebung, so haben wir die Wahl, auf Erfüllung des Vertrages zu bestehen oder
        der Aufhebung des Vertrages zuzustimmen; im letzteren Fall ist der Kunde verpflichtet, nach
        unserer Wahl einen pauschalierten Schadenersatz in Höhe von 30% des Bruttorechnungs-betrages
        oder den tatsächlich entstandenen Schaden zu bezahlen.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">12. Eigentumsvorbehalt, Freigabe erhaltener Sicherheiten</div>
    <div class="line-height-3 text-lg text-700 mb-4">
        12.1. Alle Waren werden von uns unter Eigentumsvorbehalt geliefert und bleiben bis zur vollständigen Bezahlung unser Eigentum. Der Kunde wurde darüber informiert und stimmt bei
        Vertragsunterzeichnung ausdrücklich dem Eigentumsvorbehalt zu. Im Fall des auch nur teil-weisen
        Zahlungsverzuges sind wir berechtigt, die Ware auch ohne Zustimmung des Käufers/Vertragspartners abzuholen.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        12.2. Der Kunde ist verpflichtet, die Vorbehaltsware als unser Eigentum zu kennzeichnen, sie
        pfleglich zu behandeln, insbesondere fachgerecht zu lagern; er ist ferner verpflichtet, sie auf eigene
        Kosten gegen Feuer-, Wasser- und Diebstahlschäden ausreichend zum Neuwert zu versichern.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        12.3. Sofern der Kunde die von uns gelieferten Waren vor Erfüllung sämtlicher unserer Forderungen untrennbar mit anderen Sachen verbindet, erwirbt er dadurch nicht Eigentum daran. Wir
        erwerben Miteigentum an der dadurch entstandenen neuen Sache im Verhältnis des Wertes der von
        uns gelieferten Waren zu den anderen verarbeiteten Waren im Zeitpunkt der Verbindung.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        12.4. Die unter Eigentumsvorbehalt stehenden Waren darf der Kunde weder verpfänden noch
        sicherungshalber übereignen. Bei etwaigen Pfändungen oder sonstiger Inanspruchnahme durch
        dritte Personen ist der Kunde verpflichtet, unser Eigentumsrecht geltend zu machen und uns
        unverzüglich zu verständigen.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        12.5. Ohne unsere Zustimmung ist der Kunde nicht berechtigt, Vorbehaltsware zu veräußern. Er
        tritt uns bereits jetzt die Forderungen, die ihm aus einer Weiterveräußerung von Vorbehalts-ware
        gegen seine Abnehmer oder Dritte erwachsen, in Höhe des Wertes der Vorbehaltsware ab. Als Wert
        der Vorbehaltsware gilt der mit uns vereinbarte Faktura-Endbetrag einschließlich Umsatzsteuer.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        12.6. Befindet sich der Kunde in Verzug, umfasst der Eigentumsvorbehalt auch das Recht, die unter
        Eigentumsvorbehalt gelieferte Ware jederzeit gegen angemessene Vorankündigung zu besichtigen
        und abzuholen. Der Kunde ist verpflichtet, bei Änderung der Örtlichkeit der unter
        Eigentumsvorbehalt stehenden Ware, dem Kunden jeweils schriftlich davon in Kenntnis zu setzen.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">13. Immaterialgüterrechte</div>
    <div class="line-height-3 text-lg text-700 mb-4">
        Sämtliche mit unseren Produkten, Entwicklungen, Unterlagen etc. verbundenen Immaterialgüterrechte, wie Erfindungen, Know How etc., bleiben ausschließlich unser Eigentum.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">14. Geheimhaltung</div>
    <div class="line-height-3 text-lg text-700 mb-4">
        Unsere Vertragspartner verpflichten sich zur Geheimhaltung des ihnen aus der Geschäftsbeziehung
        zugegangenen Wissens gegenüber Dritten. Bei Verletzung dieser Verpflichtung ist eine Pönale in
        Höhe von € 10.000,00 zu bezahlen, welche dem richterlichen Mäßigungsrecht unter-liegt.
    </div>

    <div class="line-height-3 text-xl text-700 mb-5">15. Datenschutz </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        15.1. Personenbezogene Daten der Kunden werden lediglich im Rahmen der gesetzlichen Bestimmungen über den Datenschutz behandelt. Der Kunde stimmt zu, dass die im Rahmen der
        Bestellung und der Bestellabwicklung bekanntgegebenen Daten für Zwecke unserer Buchhaltung
        sowie zu internen Marktforschungs- und Marketingzwecken erhoben, bearbeitet, gespeichert und
        genützt werden. Die Daten werden von uns zur Erfüllung von gesetzlichen Vorschriften, zur
        Abwicklung des Zahlungsverkehrs und zu Werbezwecken verwendet, jedoch nicht an Dritte
        weitergegeben.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        15.2. Reisinger Technology ist berechtigt, Unternehmenskennzeichen von Kunden (Logo, etc.) im
        Rahmen von Präsentationen bei Kunden, Investoren, etc., zu verwenden.
    </div>


    <div class="line-height-3 text-xl text-700 mb-5">16. Anzuwendendes Recht, Erfüllungsort, Gerichtsstand</div>
    <div class="line-height-3 text-lg text-700 mb-4">
        16.1. Es gilt österreichisches Recht unter Ausschluss der Internationalen Privatrechtsbestimmungen und des Übereinkommens über Verträge über den internationalen Warenkauf (UNKaufrecht).
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        16.2. Zahlungs- und Erfüllungsort für alle sich aus diesem Vertrag ergebenden Verpflichtungen ist
        der Unternehmenssitz von Reisinger Technology.
    </div>
    <div class="line-height-3 text-lg text-700 mb-4">
        16.3. Zur Entscheidung für alle sich mittelbar oder unmittelbar aus dem Vertragsverhältnis mit uns
        ergebenden Streitigkeiten – einschließlich solcher über sein Bestehen der Nichtbestehen – wird
        die ausschließliche Zuständigkeit der sachlich in Betracht kommenden Gericht am Sitz von
        Reisinger Technology als vereinbart.
    </div>
</div>
