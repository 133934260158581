import { Component, OnInit } from '@angular/core';
import { MenuItem, TreeNode } from 'primeng/api';
import { Folder } from '../folder.model';
import { FolderService } from '../folder.service';
import { DmsService } from '../dms.service';
import { DocumentViewModel } from '../document.viewmodel';

@Component({
  selector: 'app-dms-archive',
  templateUrl: './dms-archive.component.html',
  styleUrls: ['./dms-archive.component.css']
})
export class DmsArchiveComponent implements OnInit {

  display: boolean = false;
  public items: MenuItem[];
  files: TreeNode[];
  selectedFile: any;

  folderList: Folder[] = new Array();
  folder: Folder = new Folder();

  selectedFolder: Folder = new Folder();

  fileList: DocumentViewModel[] = new Array();

  editMode: boolean = false;

  newFolderName: string = '';
  buttonTitle: string = '';
  dialogTitle: string = '';

  constructor(private folderService: FolderService, private dmsService: DmsService) { }

  ngOnInit() {
    this.items = [
      { label: 'Categories' },
      { label: 'Sports' },
      { label: 'Football' },
      { label: 'Countries' },
      { label: 'Spain' },
      { label: 'F.C. Barcelona' },
      { label: 'Squad' },
      { label: 'Lionel Messi', url: 'https://en.wikipedia.org/wiki/Lionel_Messi' }
    ];


    this.readFolders();
  }

  readFolders() {
    this.files = new Array();
    var node = {
      key: '0',
      label: 'Start',
      icon: 'pi pi-fw pi-home',
      expanded: true,
      children: [],
    };
    
    this.folderService.getItemsRaw("", 1000, 1).subscribe(resp => {
      this.folderList = resp.body.slice();
      
      this.folderList.filter(i => i.parentFolderID == 0).forEach(item => {
        var n = {
          key: item.id.toString(),
          label: item.name,
          icon: 'pi pi-fw pi-folder',
          data: item,
          children: this.foldersToTreeNodes(this.folderList, item.id),
        }
        node.children.push(n);
      //  this.fillFoldereRecursive(n);
      });
      
      this.files.push(node);
      
      //this.files = this.foldersToTreeNodes(this.folderList, null);
    });
  }

  fillFoldereRecursive(node: TreeNode) {
    this.folderList.filter(i => i.parentFolderID == node.data.id).forEach(item => {
      var n = {
        key: item.id.toString(),
        label: item.name,
        icon: 'pi pi-fw pi-folder',
        data: item,
        children: [],
      };
      node.children.push(n);
      this.fillFoldereRecursive(n);
    });

    
    
  }


  foldersToTreeNodes(folders: Folder[], parent_id: number | null = null): TreeNode[] {
    return folders
      .filter((folder) => folder.parentFolderID === parent_id)
      .map((folder) => ({
        key: folder.id.toString(),
        label: folder.name,
        data: folder,
        icon: 'pi pi-fw pi-folder',
        children: this.foldersToTreeNodes(folders, folder.id),
      }));
  }

  addFolder() {
    this.editMode = false;
    this.buttonTitle = 'Erstellen';
    this.dialogTitle = 'Neuen Ordner erstellen';
    this.newFolderName = 'Neuer Ordner';

    this.folder = new Folder();

    this.display = true;
  }

  renameFolder() {
    if (this.selectedFile) {
      this.folder = this.selectedFile.data;
      this.editMode = true;
      this.buttonTitle = 'Umbenennen';
      this.dialogTitle = 'Ordnername ändern';
      this.newFolderName = this.selectedFile.data.name;
      this.display = true;
    }
  }

  updateFolder() {
    this.display = false;

    this.folder.name = this.newFolderName;

    if (this.editMode) {
      this.selectedFile.data = this.folder;
      this.selectedFile.data.label = this.folder.name;
      this.folderService.putItem(this.folder).subscribe(resp => {
        this.readFolders();
      });
    }
    else {
      if (this.selectedFile && this.selectedFile.data) {
        this.folder.parentFolderID = this.selectedFile.data.id;
      }
      this.folderService.postItem(this.folder).subscribe(resp => {
        this.readFolders();
      })
      
    }


  }

  nodeSelect(event) {
    var f = Object.assign(new Folder, event.node.data);
    this.showFolder(f); 
  }

  showFolder(f: Folder) {
    this.selectedFolder = f;
    this.fileList = new Array();
    var folder = this.folderList.filter(i => i.parentFolderID == f.id).forEach(item => {
      var n = new DocumentViewModel();
      n.isFolder = true;
      n.name = item.name;
      n.docType = 'Dateiordner';
      n.folderId = item.parentFolderID;
      n.id = item.id;
      this.fileList.push(n);
    });

    if (f.id > 0) {
      this.dmsService.search("", f.id).subscribe(resp => {
        this.fileList = this.fileList.concat(resp.slice());

      });
    }
  }

  onRowDblClick($event, file) {
    if (file.isFolder && file.id > 0) {
      this.showFolder(this.folderList.find(i => i.id == file.id));
      this.selectedFile = this.findNodeById(this.files, file.id);
    }
    console.log(file);
  }

  onBack() {
    if (this.selectedFolder.parentFolderID > 0) {
      this.showFolder(this.folderList.find(i => i.id == this.selectedFolder.parentFolderID));
      this.selectedFile = this.findNodeById(this.files, this.selectedFolder.id);
    }
  }

  findNodeById(nodes, id) {
  let result = null;

  for (let node of nodes) {
    if (node.data && node.data.id === id) {
      return node;
    } else if (node.children && node.children.length) {
      result = this.findNodeById(node.children, id);
      if (result) {
        return result;
      }
    }
  }

  return result;
}

}
