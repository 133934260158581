
export class Task {
  id: number;
  title: string;
  notes: string;
  contactId: number;
  who: string;
  dueDate: string;

  done: boolean;
  reminder: boolean;
  reminderDate: string;

  isRecurring: boolean;

  dueDate2: number = -5364662400;
  reminderDate2: number = -5364662400;


  constructor() {
    this.title = "";
    this.notes = "";
    this.contactId = 0;
    this.who = "";
    this.dueDate = "";
    this.done = false;
    this.reminder = false;
    this.reminderDate = null;
    this.isRecurring = false;
  }
}
