import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Globals, KVP_GROUPS } from '../../globals';
import { KvpService } from '../../shared/kvp.service';
import { PaymentTerms } from '../../shared/payment-terms.model'
import { PaymentTermsService } from './payment-terms.service';

@Component({
  selector: 'app-paymentterms',
  templateUrl: './paymentterms.component.html',
  styleUrls: ['./paymentterms.component.css']
})
export class PaymenttermsComponent implements OnInit, OnDestroy {
  display: boolean;
  entities: PaymentTerms[];

  selectedEntity: PaymentTerms = new PaymentTerms();

  paymentMethods: any;

  subItem: Subscription;

  editMode: boolean = false;

  constructor(public globals: Globals,
    private entityService: PaymentTermsService,
    private confirmationService: ConfirmationService,
    private kvpService: KvpService) { }

  ngOnDestroy(): void {
    this.subItem.unsubscribe();
  }

  ngOnInit(): void {
    this.subItem = this.entityService.itemList.subscribe(d => {
      this.entities = d;
    });

    this.kvpService.readGroup(KVP_GROUPS.PAY_METHOD).subscribe(resp => {
      if (resp.length > 0) {
        this.paymentMethods = resp;
        this.selectedEntity.paymentMethod = resp[0].key;
      }
      this.readEntities();
    });
  }

  readEntities() {
    this.entityService.getItems('', 25, 1);
  }
  showDialog() {
    this.selectedEntity = new PaymentTerms();
    this.editMode = false;
    this.display = true;
  }

  onSave() {
    this.display = false;
    if (this.editMode) {
      this.saveEntity();
    }
    else {
      this.createEntity();
    }
  }

  saveEntity() {
    this.entityService.putItem(this.selectedEntity).subscribe(resp => {
      this.readEntities();
    })
  }

  createEntity() {
    this.entityService.postItem(this.selectedEntity).subscribe(resp => {
      this.readEntities();
    });
    
  }

  onEdit(entity) {
    console.log(entity);
    this.selectedEntity = entity;
    this.editMode = true;
    this.display = true;
  }

  onDelete(entity) {
    this.confirmationService.confirm({
      message: `'Möchten Sie die Zahlungsbedingung '${entity.name}' wirklich löschen?'`,
      header: 'Zahlungsbedingung löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      rejectVisible: true,
      key: 'confirm',
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        this.entityService.deleteItem(entity.id).subscribe(() => {
          this.readEntities();
        });
      },
    });

  }
}
