import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { Globals } from "../../globals";
import { EntityService } from "../../shared/entity.service";
import { Account } from './account.model'

@Injectable({ providedIn: 'root' })
export class AccountService extends EntityService<Account> {

  constructor(protected globals: Globals,
    protected router: Router,
    protected http: HttpClient) {
    super(globals, router, http);

  }

  getResourceUrl(): string {
    return 'account';
  }

  getEditRoute(id: number): any[] {
    return ['settings', 'accounts', id]
  }
}
