import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[numeric]'
})

export class NumericDirective   {

  @Input('decimals') decimals: number = 0;


  @HostListener('ngModelChange') ngOnChanges() {
    console.log('test');
  }

  @HostListener("change", ["$event.target.value"])
  onBlur(value) {
    console.log("terst");
  }

}
