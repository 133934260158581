import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SupportTicket } from '../ticket.model';
import { TicketService } from '../ticket.service';

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.css']
})
export class TicketListComponent implements OnInit {

  tickets: SupportTicket[] = new Array();
  selectedItem: SupportTicket;

  searchString: string = '';

  isLoading: boolean = true;

  constructor(private router: Router, private ticketService: TicketService) {

  }

  ngOnInit(): void {
    this.isLoading = true;
    this.readTickets();
  }

  readTickets() {
    var query = `
{
   "selector": {
      "$and": [
         {
            "table": {
               "$eq": "SupportTicket"
            },
            "app": {
               "$eq": "$$$$$$"
            }
         }
      ]
   }
}
`
    query = JSON.stringify(JSON.parse(query));

    this.tickets = new Array();
    this.ticketService.get(query).subscribe((res) => {
      res.forEach((t) => {
        this.tickets.push(Object.assign(new SupportTicket(), t));
      });
      console.log(res);
      this.isLoading = false;
    });

  }

  onSubmit(form) {
    this.readTickets();
  }

  New() {
    this.router.navigate(['help', 'tickets', 'new']);
  }

  onSelected2(event) {
    this.router.navigate(['help', 'tickets', this.selectedItem._id]);
  }
}
