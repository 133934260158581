import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dms-start',
  templateUrl: './dms-start.component.html',
  styleUrls: ['./dms-start.component.css']
})
export class DmsStartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
