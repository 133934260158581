import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeIcons } from 'primeng/api';
import { Globals } from '../../../../globals';
import { MaintenanceWorkService } from '../../maintenance-work.service';
import { MaintenanceWorkEntryViewModel } from '../../maintenance.workentry.model';

@Component({
  selector: 'app-maintenance-work-history',
  templateUrl: './maintenance-work-history.component.html',
  styleUrls: ['./maintenance-work-history.component.css']
})
export class MaintenanceWorkHistoryComponent implements OnInit {

  fromDate: Date;
  toDate: Date;

  events: MaintenanceWorkEntryViewModel[];
  selectedItem: MaintenanceWorkEntryViewModel;

  icon: any;
  color: any;

  constructor(public globals: Globals,
    private entityService: MaintenanceWorkService,
    private router: Router
  ) {
    this.toDate = new Date();
    this.fromDate = new Date();
    this.fromDate.setMonth(this.toDate.getMonth() - 3);
  }

  ngOnInit(): void {

    this.icon = PrimeIcons.CHECK;
    this.color = "#607D8B";

    this.entityService.itemList.subscribe(() => {
      this.events = this.entityService.itemArray;
    });

    this.entityService.getItems("", this.fromDate, this.toDate);
  }

  onRefresh() {

    this.events = null;
    this.entityService.getItems("", this.fromDate, this.toDate);
  }

  onShowDetails(e: MaintenanceWorkEntryViewModel) {
    this.router.navigate(['services', 'maintenance', 'work', e.id.toString()]);
  }

  onSelected2(e) {
    this.router.navigate(['services', 'maintenance', 'work', this.selectedItem.id]);
  }

}
