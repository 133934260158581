import {Component, OnInit} from '@angular/core';
import {Account} from '../../settings/accounts/account.model';
import {AccountService} from '../../settings/accounts/account.service';
import {MonthEndClosing} from '../monthEndClosing.model';
import {TransactionService} from '../transaction.service';

@Component({
  selector: 'app-monthly-closings',
  templateUrl: './monthly-closings.component.html',
  styleUrls: ['./monthly-closings.component.css']
})
export class MonthlyClosingsComponent implements OnInit {
  isLoading: boolean = true;

  items: Account[];
  selectedItem: Account;

  currentMec: MonthEndClosing = new MonthEndClosing();

  mecList: MonthEndClosing[] = new Array();
  selectedEntity: MonthEndClosing = new MonthEndClosing();

  editIndex: number = 0;

  constructor(public entityService: AccountService, private transactionService: TransactionService) {
  }

  ngOnInit(): void {

    this.entityService.getItemsRaw('', 100, 0).subscribe(a => {
      this.items = a.body;
      if (this.items.length > 0) {
        this.selectedItem = this.items[0];
        this.onAccountChanged(null);
      }
      this.isLoading = false;

    });

  }

  findEditIndex() {
    for (let i = 0; i < this.mecList.length; ++i) {
      if (!this.mecList[i].accepted && !this.mecList[i].completed) {
        this.editIndex = i + 1;
      } else if (!this.mecList[i].accepted || !this.mecList[i].completed) {
        this.editIndex = i;
      }
    }

    if (this.editIndex >= this.mecList.length) {
      this.editIndex = this.mecList.length - 1;
    }

    console.log(this.editIndex);

    if (this.editIndex == 0 && (!this.mecList[this.editIndex].accepted || !this.mecList[this.editIndex].completed)) {
      this.currentMec = this.mecList[this.editIndex];
    } else if (this.editIndex > 0 && this.mecList[this.editIndex].accepted && this.mecList[this.editIndex].completed) {
      this.currentMec = this.mecList[this.editIndex - 1];
    }

    console.log(this.currentMec);
  }

  loadLastEntries() {
    this.mecList = new Array();
    if (this.selectedItem != null) {
      this.transactionService.getMonthEndClosings(this.selectedItem.id).subscribe(e => {
        this.mecList = e;
        var idx = this.mecList.findIndex(e => e.id == this.currentMec.id);
        if (idx >= 0) {
          this.mecList[idx] = this.currentMec;
        }
        this.findEditIndex();
      })
    }
  }

  loadNextEntry() {
    if (this.selectedItem != null) {
      this.transactionService.getNextMonthEndClosing(this.selectedItem.id).subscribe(e => {
        this.currentMec = Object.assign(new MonthEndClosing(), e);
        console.log(this.currentMec);

        this.loadLastEntries();
      })
    }
  }

  onAccountChanged(e) {
    this.loadNextEntry();

  }

  createMec() {
    this.transactionService.createMonthEndClosing(this.currentMec).subscribe(e => {
      this.currentMec = Object.assign(new MonthEndClosing(), e);
      this.loadLastEntries();
    });
  }

  saveMec() {
    this.transactionService.updateMonthEndClosing(this.currentMec).subscribe(e => {
      this.currentMec = Object.assign(new MonthEndClosing(), e);
      if (this.currentMec.accepted) {
        this.loadNextEntry();
      }
      this.loadLastEntries();
    });
  }

  onCreate() {
    if (this.currentMec.id == 0) {
      this.createMec();
    } else {
      this.saveMec();
    }
  }

  onClose() {
    this.currentMec.completed = true;
    this.saveMec();
  }

  onOpen() {
    this.currentMec.completed = false;
    this.saveMec();
  }

  onAccept() {
    this.currentMec.accepted = true;
    this.saveMec();
  }

  onReturn() {
    this.currentMec.accepted = false;
    this.saveMec();
  }

  onEdit(entity: MonthEndClosing) {
    this.currentMec = entity;
  }
}
