<div class="surface-card  pt-4 pl-2 shadow-2 border-round mb-2">
  <div class="mb-3 flex align-items-center justify-content-between">
    <div>
      <span class="text-xl font-medium text-900 mx-3">Zählerliste erstellen</span>
    </div>


  </div>

  <div class="grid formgrid p-fluid p-4">

    <div class="field mb-3 col-12">
      <div class="input-group mb-3">
        <input type="text"
               name="search"
               class="form-control input-lg"
               placeholder="Suchtext"
               aria-label="Recipient's username"
               aria-describedby="basic-addon2"
               [(ngModel)]="searchParam.search"/>
        <div class="input-group-append">
          <button pButton pRipple icon="pi pi-search" label="Suchen" style="height: 3rem !important"
                  (click)="onSearch()"></button>

        </div>
      </div>
    </div>

    <div class="field mb-3 col-6">
      <label>Versorgungsgebiet</label>
      <input type="text" name="supplyZone" placeholder="" class="form-control" [(ngModel)]="searchParam.zone"/>
    </div>
    <div class="field mb-3 col-6">
      <label>Leitungsnummer</label>
      <input type="text" name="pipeId" placeholder="" class="form-control" [(ngModel)]="searchParam.pipe"/>
    </div>

    <div class="field mb-3 col-6">
      <label>Adresse</label>
      <input type="text" name="address" placeholder="" class="form-control" [(ngModel)]="searchParam.address"/>
    </div>
    <div class="field mb-3 col-6">
      <label>Ort</label>
      <input type="text" name="city" placeholder="" class="form-control" [(ngModel)]="searchParam.city"/>
    </div>

    <div class="field mb-3 col-3 mt-2">
      <label for="nr_pad" class="font-medium text-900"> </label>
      <div class="flex align-items-center">
        <p-checkbox inputId="privacy" [binary]="true" [(ngModel)]="searchParam.calib"></p-checkbox>
        <span class="ml-2 text-900">Zählertausch bis </span>
      </div>
    </div>

    <div class="field mb-3 col-3" *ngIf="searchParam.calib">
      <label>Tauschdatum</label>
      <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false"
                  appendTo="body" [(ngModel)]="searchParam.until">
      </p-calendar>
    </div>


  </div>
</div>

<div class="surface-card  pt-4 pl-4 pr-4 shadow-2 border-round mb-2" *ngIf="items.length > 0">
  <div class="mb-3 flex align-items-center justify-content-between pl-2 pr-2">
    <div>
      <span class="text-xl font-medium text-900">Zählerliste ({{ items.length }})</span>
    </div>
    <div class="flex">
      <p-multiSelect [options]="cols" optionLabel="header" selectedItemsLabel="{0} Spalten ausgewählt"
                     [(ngModel)]="selectedColumns"/>

    </div>
    <div>
      <button pButton pRipple label="Tauschformular Drucken" icon="pi pi-tags" (click)="onPrintForm()"></button>
      <button pButton pRipple label="Liste Drucken" icon="pi pi-print" (click)="onPrint()" class="mx-3"></button>
      <button pButton pRipple label="CSV Speichern" icon="pi pi-save" (click)="onSave()"></button>
    </div>
  </div>

  <div class="m-2">
    <p-table [value]="items" dataKey="wapId" responsiveLayout="scroll" [scrollable]="true"
             styleClass="p-datatable-striped"
             (sortFunction)="customSort($event)" [customSort]="true">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="accessPointID">Wasseranschluss
            <p-sortIcon field="accessPointID"></p-sortIcon>
          </th>

          <th pSortableColumn="address1">Adresse
            <p-sortIcon field="address1"></p-sortIcon>
          </th>
          <th pSortableColumn="city">Ort
            <p-sortIcon field="city"></p-sortIcon>
          </th>

          <th pSortableColumn="address1">Eigentümer
            <p-sortIcon field="address1"></p-sortIcon>
          </th>

          <th pSortableColumn="supplyZone">Versorgungsgebiet
            <p-sortIcon field="supplyZone"></p-sortIcon>
          </th>
          <th pSortableColumn="pipeNumber">Leitungsnummer
            <p-sortIcon field="pipeNumber"></p-sortIcon>
          </th>
          <th pSortableColumn="gaugeSerial">Zählernummer
            <p-sortIcon field="gaugeSerial"></p-sortIcon>
          </th>
          <th>Zählerstand</th>
          <th pSortableColumn="calibrationData">Eichdatum
            <p-sortIcon field="calibrationData"></p-sortIcon>
          </th>

          <th pSortableColumn="gaugeSerial">Subzähler
            <p-sortIcon field="gaugeSerial"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td>{{ item.accessPointID }}</td>

          <td>{{ item.address1 }}</td>
          <td>{{ item.city }}</td>
          <td>{{ item.owner }}</td>

          <td>{{ item.supplyZone }}</td>
          <td>{{ item.pipeNumber }}</td>
          <td>{{ item.gaugeSerial }}</td>
          <td>{{ item.lastCount }}</td>
          <td>
            <div>{{ item.calibrationDate | customDate }}
            </div>
          </td>
          <td>{{ item.subGaugeSerial }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
