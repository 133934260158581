import { Component } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Globals } from '../../globals';
import { NumberRange } from '../../shared/number-range.model';
import { NumberRangeService } from './number-range.service';

@Component({
  selector: 'app-numberrange',
  templateUrl: './numberrange.component.html',
  styleUrls: ['./numberrange.component.css']
})
export class NumberrangeComponent {

  display: boolean;
  entities: NumberRange[];

  selectedEntity: NumberRange = new NumberRange();

  paymentMethods: any;

  subItem: Subscription;

  editMode: boolean = false;

  constructor(public globals: Globals,
    private entityService: NumberRangeService,
    private confirmationService: ConfirmationService,
    ) { }

  ngOnDestroy(): void {
    this.subItem.unsubscribe();
  }

  ngOnInit(): void {
    this.subItem = this.entityService.itemList.subscribe(d => {
      console.log(d);
      this.entities = d;
    });
    this.readEntities();
  }

  readEntities() {
    this.entityService.getItems('', 25, 1);
  }
  showDialog() {
    this.selectedEntity = new NumberRange();
    this.editMode = false;
    this.display = true;
  }

  onSave() {
    this.display = false;
    if (this.editMode) {
      this.saveEntity();
    }
    else {
      this.createEntity();
    }
  }

  saveEntity() {
    this.entityService.putItem(this.selectedEntity).subscribe(resp => {
      this.readEntities();
    })
  }

  createEntity() {
    this.entityService.postItem(this.selectedEntity).subscribe(resp => {
      this.readEntities();
    });

  }

  onEdit(entity) {
    console.log(entity);
    this.selectedEntity = entity;
    this.editMode = true;
    this.display = true;
  }

  onDelete(entity) {
    this.confirmationService.confirm({
      message: `'Möchten Sie die Zahlungsbedingung '${entity.name}' wirklich löschen?'`,
      header: 'Zahlungsbedingung löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      rejectVisible: true,
      key: 'confirm',
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        this.entityService.deleteItem(entity.id).subscribe(() => {
          this.readEntities();
        });
      },
    });

  }

}
