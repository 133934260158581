import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MaintenancePlanEntry } from '../../maintenance.planentry.model';
import { MaintenancePlanService } from '../../maintenance-plan.service';

@Component({
  selector: 'app-maintenance-plan-list',
  templateUrl: './maintenance-plan-list.component.html',
  styleUrls: ['./maintenance-plan-list.component.css']
})
export class MaintenancePlanListComponent implements OnInit {

  subItem: Subscription;
  items: MaintenancePlanEntry[];
  selectedItem: MaintenancePlanEntry;
  isLoading = false;

  searchString: string = '';

  totalItems: number;

  @Output() selected = new EventEmitter<MaintenancePlanEntry>();

  @Input('titleString') title: string = 'Wartungsarbeiten';
  @Input() showAddButton: boolean = true;

  constructor(private router: Router, public entityService: MaintenancePlanService) { }

  ngOnInit(): void {
    this.entityService.detailActive = '';
    this.entityService.listActive = 'active';

    this.totalItems = this.entityService.totalItems;
    this.items = this.entityService.itemArray;

    if (!this.items?.length) this.isLoading = true;

    this.subItem = this.entityService.itemList.subscribe(d => {
      this.items = d;
      this.isLoading = false;
      this.totalItems = this.entityService.totalItems;
    });

    if (!this.items?.length) this.entityService.getItems('', 10, 1);
  }

  ngOnDestroy() {
    this.subItem.unsubscribe();
  }

  onSubmit(form: NgForm) {
    this.entityService.getItems(this.searchString, 10, 1);
  }


  paginate(event) {
    this.entityService.getItems(this.searchString, 10, event.page + 1);
  }

  onSelected(item: MaintenancePlanEntry) {
    if (this.selected.observers != null && this.selected.observers.length > 0) {
      this.selected.emit(item);
    }
    else {
      this.selectedItem = item;
      this.router.navigate(['services', 'maintenance', 'plan', this.selectedItem.id]);

      this.entityService.detailActive = 'active';
      this.entityService.listActive = '';
    }
  }

  onSelected2(event) {
    this.entityService.selectedItem = this.selectedItem;
    if (this.selected.observers != null && this.selected.observers.length > 0) {
      this.selected.emit(this.selectedItem);
    }
    else {
      
      this.router.navigate(['services', 'maintenance', 'plan', this.selectedItem.id]);

      this.entityService.detailActive = 'active';
      this.entityService.listActive = '';
    }
  }

  New() {
    this.router.navigate(['services', 'maintenance', 'plan', 'new']);
  }

}
