import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router, ActivatedRoute, Params} from '@angular/router';

import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog';

import {WapService} from '../../wap.service';
import {Wap} from 'src/app/shared/wap.model';
import {GaugeService} from 'src/app/masterdata/gauges/gauge.service';
import {Gauge} from '../../../shared/gauge.model';
import {Globals} from 'src/app/globals';
import {Contact} from 'src/app/shared/contact.model';
import {ContactService} from 'src/app/shared/contact.service';
import {GaugeSelectComponent} from 'src/app/masterdata/gauges/gauge-select/gauge-select.component';
import {MemberSelectComponent} from 'src/app/members/member-select/member-select.component';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'app-subwap-edit',
  templateUrl: './subwap-edit.component.html',
  styleUrls: ['./subwap-edit.component.css'],
  providers: [DialogService],
})
export class SubwapEditComponent implements OnInit {
  id: number;
  pid: number;
  editMode = false;
  wap: Wap = new Wap();
  isGauge: boolean;
  readingDate: Date = new Date();

  ownerId: number = 0;
  ownerName: string = '';
  ownerAddress: string = '';

  invoiceId: number = 0;
  invoiceName: string = '';

  invoiceAddress: string = '';

  ref: DynamicDialogRef;

  @ViewChild('counterData', {static: true}) counterForm: NgForm;

  constructor(public wapService: WapService,
              public dialogService: DialogService,
              private router: Router,
              private route: ActivatedRoute,
              private gaugeService: GaugeService,
              public globals: Globals,
              private confirmationService: ConfirmationService,
              private contactService: ContactService,
  ) {
  }

  ngOnInit(): void {

    this.isGauge = false;

    this.route.params.subscribe((params: Params) => {
      this.id = +params['cid'];
      this.pid = +params['id'];
      this.editMode = params['cid'] != null;

      if (this.wap || !this.editMode) {
        this.initForm();
      } else {
        this.wapService.readWap(this.id).subscribe(w => {
          this.initForm();
        });
      }
    });
  }

  initForm() {
    if (this.editMode) {
      setTimeout(() => {

        this.wapService.readWap(this.id).subscribe(w => {
          this.wap = w;

          this.fillForm();

          if (this.wap.gaugeID != 0) {
            this.gaugeService.readGauge(this.wap.gaugeID).subscribe(g => {
              this.isGauge = g.id != 0;
              this.fillGauge(g);
            });
          } else {
            this.isGauge = false;
            this.counterForm.reset();
          }
        });

      });


    } else {
      this.wap = new Wap();
      this.wap.parentWapID = this.pid;
      setTimeout(() => {
        this.fillForm();
      });
    }
  }

  fillForm() {


    if (this.wap.ownerContactID) {
      this.contactService
        .readContact(this.wap.ownerContactID)
        .subscribe((c) => {
          this.ownerId = c.id;
          this.ownerName = c.orgName
            ? c.orgName
            : c.firstName + ' ' + c.lastName;
          this.ownerAddress = c.zip + ' ' + c.city + ', ' + c.address1;
        });
    } else {
      this.ownerName = '';
      this.ownerId = 0;
      this.ownerAddress = '';
    }

    if (this.wap.invoiceContactID) {
      this.contactService
        .readContact(this.wap.invoiceContactID)
        .subscribe((c) => {
          this.invoiceId = c.id;
          this.invoiceName = c.orgName
            ? c.orgName
            : c.firstName + ' ' + c.lastName;
          this.invoiceAddress = c.zip + ' ' + c.city + ', ' + c.address1;
        });
    } else {
      this.invoiceId = 0;
      this.invoiceName = '';
      this.invoiceAddress = '';
    }
  }

  fillGauge(g: Gauge) {
    this.counterForm.form.patchValue({
      counterType: g.description,
      counterCounts: g.lastCount,
      newcounts: g.lastCount,
      serial: g.serial
    });
  }

  onSave() {
    this.wap.parentWapID = this.wapService.parentWap.id;
    this.wap.yearCreated = this.wapService.parentWap.yearCreated;
    this.wap.gpsCoord = this.wapService.parentWap.gpsCoord;
    this.wap.address1 = this.wapService.parentWap.address1;
    this.wap.zip = this.wapService.parentWap.zip;
    this.wap.city = this.wapService.parentWap.city;
    this.wap.ownerContactID = this.ownerId;
    this.wap.invoiceContactID = this.invoiceId;


    if (!this.wap.active) {
      this.wap.active = false;
    }
    if (!this.wap.drainAccess) {
      this.wap.drainAccess = false;
    }

    if (this.editMode) {
      this.wapService.putWap(this.wap).subscribe(w => {
        this.wap = w;
      });
    } else {
      this.wapService.postWap(this.wap).subscribe(w => {
        this.wap = w;
      });
    }
  }

  onSelectMember() {
    this.ref = this.dialogService.open(MemberSelectComponent, {
      header: 'Eigentümer auswählen',
      width: '75%',
      contentStyle: {'max-height': '650px', overflow: 'auto'},
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((c: Contact) => {
      if (c) {
        this.ownerId = c.id;
        this.ownerName = c.orgName ? c.orgName : c.firstName + ' ' + c.lastName;
        this.ownerAddress = c.zip + ' ' + c.city + ', ' + c.address1;
      }
    });
  }

  onSelectInvoice() {
    this.ref = this.dialogService.open(MemberSelectComponent, {
      header: 'Rechnungsadresse auswählen',
      width: '75%',
      contentStyle: {'max-height': '650px', overflow: 'auto'},
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((c: Contact) => {
      if (c) {
        this.invoiceId = c.id;
        this.invoiceName = c.orgName
          ? c.orgName
          : c.firstName + ' ' + c.lastName;
        this.invoiceAddress = c.zip + ' ' + c.city + ', ' + c.address1;
      }
    });
  }

  onSelectCounter() {
    this.ref = this.dialogService.open(GaugeSelectComponent, {
      data: {
        wap: this.wap,
      },
      header: 'Wasserzähler tauschen',
      width: '60%',
      contentStyle: {'max-height': '650px', overflow: 'auto'},
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe(data => {
      setTimeout(() => {
        this.wapService.changeGauge(this.wap, data);
      });
    });
  }

  onDelete() {
    this.confirmationService.confirm({
      message: 'Möchten Sie den Subzähler wirklich löschen?',
      header: 'Subzähler löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      key: 'confirm',
      rejectVisible: true,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.wapService.deleteWap(this.wap.id).subscribe((r) => {
          if (r && r.value.length > 0) {
          } else {
            this.wapService.removeWapFromList(this.wap);
            this.router.navigate(['wap', this.wapService.parentWap.id, 'childs']);
          }
        });
      },
    });
  }

  Back() {
    this.router.navigate(['wap-module', 'wap', this.wapService.parentWap.id, 'childs']);
  }

  Delete() {
    this.onDelete();
  }

  Save() {
    this.onSave();
  }
}
