<div class="mb-3 flex align-items-center justify-content-between">
    <span class="text-xl font-medium text-900">Meine Tickets</span>
    <button pButton pRipple label="Hinzufügen" icon="pi pi-plus" (click)="New()"></button>
</div>

<form (ngSubmit)="onSubmit(f)" #f="ngForm">
    <div class="px-0">
        <div class="input-group mb-3">
            <input type="text" name="searchTickets" class="form-control input-lg" placeholder="Suchtext"
                   aria-label="text" aria-describedby="basic-addon2" [(ngModel)]="searchString" />
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" [disabled]="!f.valid" type="submit">
                    Suchen
                </button>
            </div>
        </div>
    </div>
</form>

<p-table [value]="tickets" selectionMode="single" [(selection)]="selectedItem" dataKey="id" responsiveLayout="scroll"
         (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped">
    <ng-template pTemplate="header">
        <tr>
            <th>Betreff</th>
            <th>Erstellt am</th>
            <th>Kategorie</th>
            <th>Status</th>
            <th>Nachrichten</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-ticket>
        <tr [pSelectableRow]="ticket">
            <td class="table-users-name">{{ ticket.ticket_subject }}</td>
            <td class="table-users-id">{{ ticket.created_date | customDate }}</td>
            <td class="table-users-id">{{ ticket.ticket_category }}</td>
            <td class="table-users-id">{{ ticket.ticket_status }}</td>
            <td class="table-users-id">{{ ticket.messages.length }}</td>
        </tr>
    </ng-template>
</p-table>
