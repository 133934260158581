export class KvpSetting {
  public id: number;

  public key: string;
  public value: string;
  public group: string;

  public readOnly: boolean;

  constructor() {
    this.key = "";
    this.value = "";
    this.group = "";
    this.readOnly = false;
  }
}
