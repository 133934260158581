import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Subject} from 'rxjs';

import {Globals} from '../../globals';
import {MyDocument} from '../../shared/document.model';
import {DatePipe} from '@angular/common';
import {Attachment} from '../../shared/attachment.model';
import {DocumentViewModel} from './document.viewmodel'
import {FileShare} from "./file-share.model";

@Injectable({providedIn: 'root'})
export class DmsService {
  documents = new Subject<MyDocument[]>();
  documentArray = new Array<MyDocument>();

  totalDocuments: number = 0;

  selectedDocument: MyDocument = null;

  detailActive = '';
  listActive = 'active';

  isCopy = false;

  constructor(
    private globals: Globals,
    private router: Router,
    private http: HttpClient,
    public datepipe: DatePipe
  ) {
  }


  getDocuments(search: string, pageSize: number, page: number) {
    const url = this.globals.APP_URL + 'document';
    let params = new HttpParams();
    params = params.append('search', search);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('page', page.toString());
    this.http
      .get<MyDocument[]>(url, {
        params: params,
        observe: 'response',
      })
      .subscribe((respData) => {
        this.totalDocuments = parseInt(respData.headers.get('x-total-count'));
        this.documentArray = respData.body.slice();
        this.documents.next(this.documentArray);
      });
  }

  updateDocument(p: MyDocument) {
    const url = this.globals.APP_URL + 'document';
    this.http.put<MyDocument>(url, p).subscribe((respData) => {
      this.selectedDocument = respData;
      const idx = this.documentArray.findIndex((x) => x.id === p.id);
      this.documentArray[idx] = respData;
      this.router.navigate([
        'services',
        'dms',
        'postbox',
        this.selectedDocument.id,
      ]);
    }, (errorData) => {
      console.log(errorData);
    });
  }

  addDocument(p: MyDocument, f: File) {
    this.uploadDocument(p, f).subscribe((respData) => {
      this.selectedDocument = respData;
      this.documentArray.push(respData);
      this.documents.next(this.documentArray);
      this.selectedDocument = respData;
      this.router.navigate([
        'services',
        'dms',
        'postbox',
        this.selectedDocument.id,
      ]);
    });
  }

  uploadDocument(p: MyDocument, f: File) {
    const url = this.globals.APP_URL + 'document/upload';
    const formData = new FormData();

    p.created2 = Math.round(new Date().getTime() / 1000); // this.datepipe.transform(new Date(), 'MM.dd.yyyy');

    formData.append("file", f, f.name);
    formData.append("document", JSON.stringify(p));

    console.log(formData);

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const requestOptions = {
      headers: headers,
    };

    return this.http.post<MyDocument>(url, formData, {headers});
  }

  addLink(link: Attachment) {
    const url = this.globals.APP_URL + 'document/link';
    this.http.post<MyDocument>(url, link).subscribe((resp) => {
      console.log("Link created");
    });
  }

  deleteLink(id: number) {
    const url = this.globals.APP_URL + 'document/link/' + id.toString();
    return this.http.delete(url);
  }

  getLink(tableName: string, id: number) {
    const url = this.globals.APP_URL + 'document/link';

    let params = new HttpParams();
    params = params.append('tablename', tableName);
    params = params.append('id', id.toString());

    return this.http.get<Attachment[]>(url, {params: params});
  }

  readDocument(id: number) {
    const url = this.globals.APP_URL + 'document/' + id;
    return this.http.get<MyDocument>(url);
  }

  downloadDocument(id: number) {
    const url = this.globals.APP_URL + "document/download/" + id;
    return this.http.get(url, {observe: 'response', responseType: 'blob'});
  }

  getDocument(id: number) {
    //this.selectedProduct = this.productArray.find((p) => p.id === id);
    this.selectedDocument = Object.assign(
      new Document(),
      this.documentArray.find((p) => p.id === id)
    );
    return this.selectedDocument;
  }

  deleteDocument(id: number) {
    const url = this.globals.APP_URL + 'document/' + id;
    return this.http.delete(url).subscribe(() => {
      this.documentArray = this.documentArray.filter(item => item.id !== this.selectedDocument.id);
      this.selectedDocument = null;
      this.documents.next(this.documentArray);
      this.router.navigate(['services', 'dms', 'postbox']);
    });
  }

  deleteInternalDocument(id: number) {
    const url = this.globals.APP_URL + 'document/' + id;
    return this.http.delete(url);
  }

  addInternalDocument(p: MyDocument, f: File) {
    const url = this.globals.APP_URL + 'document/upload';
    const formData = new FormData();

    p.created2 = Math.round(new Date().getTime() / 1000); // this.datepipe.transform(new Date(), 'MM.dd.yyyy');
    p.internal = true;

    formData.append("file", f, f.name);
    formData.append("document", JSON.stringify(p));

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    const requestOptions = {
      headers: headers,
    };

    return this.http.post<MyDocument>(url, formData, {headers});
  }

  archiveDocument(id: number, folderId: number) {
    const url = this.globals.APP_URL + 'document/archive';

    let params = new HttpParams();
    params = params.append('docID', id.toString());
    params = params.append('folderID', folderId.toString());

    return this.http.get(url, {params: params});

  }

  fileSizeToString(bytes: number): string {
    if (bytes == 0) {
      return "0.00 B";
    }
    var e = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B';
  }

  search(search: string, folderId: Number) {
    const url = this.globals.APP_URL + 'document/search';
    let params = new HttpParams();
    params = params.append('search', search);
    params = params.append('folderId', folderId.toString());
    return this.http.get<DocumentViewModel[]>(url, {
      params: params,
    });
  }

  getByType(docType: string) {
    const url = this.globals.APP_URL + 'document/search';
    let params = new HttpParams();
    params = params.append('docType', docType);
    return this.http.get<DocumentViewModel[]>(url, {
      params: params
    });
  }

  postShare(share: FileShare) {
    const url = this.globals.APP_URL + 'document/share';
    return this.http.post<FileShare>(url, share);
  }

  getShares() {
    const url = this.globals.APP_URL + 'document/share';
    return this.http.get<FileShare[]>(url);
  }

}
