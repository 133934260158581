<div class="min-h-screen flex flex-column relative flex-auto pl-2">
    <div class="surface-card p-4 shadow-2 border-round">
        <div class="mb-3 flex align-items-center justify-content-between">
            <div>
                <span class="text-xl font-medium text-900">Wartungsbuch</span>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="card">


                    <br />
                    <div class="card-body card-sidebar-menu">
                        <form #workData="ngForm">
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label>Durchgeführt von</label>
                                        <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [locale]="globals.de"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="fromDate">
                                        </p-calendar>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label>bis</label>
                                        <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [locale]="globals.de"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="toDate">
                                        </p-calendar>
                                    </div>
                                </div>

                                <div class="col-md-2 col-btn">
                                    <div class="form-group">
                                        <button class="btn btn-outline-secondary"
                                                (click)="onRefresh()">
                                            Aktualisieren
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <br />

                <div class="card">
                    <div class="card-header d-md-flex justify-content-between align-items-center">
                        <h5 class="card-title">Durchgeführte Arbeiten</h5>
                    </div>
                    <div class="card-body">

                        <p-table [value]="events" selectionMode="single" [(selection)]="selectedItem" dataKey="id" responsiveLayout="scroll"
                                 (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 5rem"></th>
                                    <th>Anlage</th>
                                    <th>Beschreibung</th>
                                    <th>Datum</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item let-expanded="expanded">
                                <tr [pSelectableRow]="item">
                                    <td>
                                        <button type="button" pButton pRipple [pRowToggler]="item" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                                    </td>
                                    <td class="td.table-col-25">{{ item.equipmentShortText }}</td>
                                    <td class="td.table-col-25">{{ item.shortText }}</td>
                                    <td class="td.table-col-25">{{ item.completeDate2*1000 | customDate }}</td>
                                </tr>
                            </ng-template>

                            <ng-template pTemplate="rowexpansion" let-item>
                                <tr>
                                    <td colspan="7">
                                        <div class="flex flex-column relative flex-auto pl-2">
                                            <div class="surface-card p-4 shadow-2 border-round">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>Durchgeführt von</label>
                                                            <input type="text" name="who" class="form-control" [(ngModel)]="item.who" readonly />
                                                        </div>

                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-3" *ngIf="item.numberFieldName1 && item.numberFieldName1 != ''">
                                                        <div class="form-group">
                                                            <label>{{ item.numberFieldName1 }}</label>
                                                            <input type="number" name="number1" placeholder="" class="form-control" [(ngModel)]="item.numberValue1" />
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3" *ngIf="item.numberFieldName2 && item.numberFieldName2 != ''">
                                                        <div class="form-group">
                                                            <label>{{ item.numberFieldName2 }}</label>
                                                            <input type="number" name="number2" placeholder="" class="form-control" [(ngModel)]="item.numberValue2" />
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3" *ngIf="item.numberFieldName3 && item.numberFieldName3 != ''">
                                                        <div class="form-group">
                                                            <label>{{ item.numberFieldName3 }}</label>
                                                            <input type="number" name="number3" placeholder="" class="form-control" [(ngModel)]="item.numberValue3" />
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="row">
                                                    <div class="col-md-3" *ngIf="item.textFieldName1 && item.textFieldName1 != ''">
                                                        <div class="form-group">
                                                            <label>{{ item.textFieldName1 }}</label>
                                                            <input type="text" name="text1" placeholder="" class="form-control" [(ngModel)]="item.textValue1" />
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3" *ngIf="item.textFieldName2 && item.textFieldName2 != ''">
                                                        <div class="form-group">
                                                            <label>{{ item.textFieldName2 }}</label>
                                                            <input type="text" name="text2" placeholder="" class="form-control" [(ngModel)]="item.textValue2" />
                                                        </div>
                                                    </div>

                                                    <div class="col-md-3" *ngIf="item.textFieldName3 && item.textFieldName3 != ''">
                                                        <div class="form-group">
                                                            <label>{{ item.textFieldName3 }}</label>
                                                            <input type="text" name="text3" placeholder="" class="form-control" [(ngModel)]="item.textValue3" />
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Notizen</label>
                                                            <textarea type="text" name="workComment" class="form-control" style="resize: none; height: 200px" rows="6"
                                                                      [(ngModel)]="item.comment"></textarea>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>Information/Beschreibung</label>
                                                            <textarea type="text" name="planDescription" class="form-control" style="resize: none; height: 200px" rows="6" readonly
                                                                      [ngModel]="item.description"></textarea>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                            </p-table>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
