import {Component} from '@angular/core';

@Component({
  selector: 'app-wap-reporting',
  templateUrl: './wap-reporting.component.html',
  styleUrl: './wap-reporting.component.css'
})
export class WapReportingComponent {

}
