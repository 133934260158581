import {Component, OnInit} from '@angular/core';
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from "primeng/paginator";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {ToastModule} from "primeng/toast";
import {DialogModule} from "primeng/dialog";
import {Globals, KVP_GROUPS} from "../../globals";
import {KvpService} from "../../shared/kvp.service";
import {SelectButtonModule} from "primeng/selectbutton";
import {AccountService} from "../../settings/accounts/account.service";
import {Account} from "../../settings/accounts/account.model";
import {InputGroupModule} from "primeng/inputgroup";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {ConfirmationService, MessageService} from "primeng/api";
import {ContactSelectComponent} from "../../shared/contact-select/contact-select.component";
import {Contact} from "../../shared/contact.model";
import {NgClass, PercentPipe} from "@angular/common";
import {TransactionTemplate} from "../transaction-template.model";
import {ContactService} from "../../shared/contact.service";
import {TransactionService} from "../transaction.service";
import {PaymentTerms} from "../../shared/payment-terms.model";
import {TableModule} from "primeng/table";
import {ConfirmDialogModule} from "primeng/confirmdialog";

@Component({
  selector: 'app-transaction-template',
  standalone: true,
  imports: [
    InputTextModule,
    PaginatorModule,
    ButtonModule,
    RippleModule,
    ToastModule,
    DialogModule,
    SelectButtonModule,
    InputGroupModule,
    NgClass,
    TableModule,
    ConfirmDialogModule,
    PercentPipe
  ],
  templateUrl: './transaction-template.component.html',
  styleUrl: './transaction-template.component.css',
  providers: [DialogService, MessageService]
})
export class TransactionTemplateComponent implements OnInit {
  searchString: string = '';

  display: boolean = false;

  dialogTitle: string = '';

  costCenters: any;

  editMode: boolean = false;

  transactionOption: any[];

  accounts: Account[] = new Array();

  ref: DynamicDialogRef;

  entities: TransactionTemplate[] = new Array();
  template: TransactionTemplate = new TransactionTemplate();

  tax1: number = 0.0;
  tax2: number = 0.0;
  option: number = 0;

  contactString: string = '';

  constructor(
    public dialogService: DialogService,
    public accountService: AccountService,
    private contactService: ContactService,
    private transactionService: TransactionService,
    public globals: Globals,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private kvpService: KvpService
  ) {
    this.transactionOption = [{label: 'Eingang', value: 0}, {label: 'Ausgang', value: 1}, {
      label: 'Ein-/Ausgang',
      value: 2
    }];
  }

  ngOnInit(): void {
    this.kvpService.readGroup(KVP_GROUPS.COST_CENTER).subscribe(resp => {

      if (resp.length > 0) {
        // for some reason we have to remove the group property
        this.costCenters = resp.map(({group, ...rest}) => rest);
      } else {
        this.costCenters = new Array();
      }
    });

    this.accountService.getItemsRaw('', 100, 0).subscribe(resp => {
      this.accounts = resp.body.slice();
    });

    this.readTemplates();
  }

  readTemplates() {
    this.transactionService.getTemplates(this.searchString).subscribe(resp => {
      this.entities = resp;
    });
  }

  onNew() {
    this.template = new TransactionTemplate();

    this.editMode = false;
    this.dialogTitle = 'Neue Vorlage erstellen';
    this.tax1 = 0.0;
    this.tax2 = 0.0;
    this.option = 0;
    this.display = true;
  }

  onSearch() {
    this.readTemplates();
  }

  onSave() {
    console.log(this.template);
    this.display = false;

    this.template.taxRate1 = Math.round(this.tax1 * 100);
    this.template.taxRate2 = Math.round(this.tax2 * 100);

    if (this.option == 0) {
      this.template.isIncoming = true;
      this.template.isOutgoing = false;
    } else if (this.option == 1) {
      this.template.isIncoming = false;
      this.template.isOutgoing = true;
    } else {
      this.template.isIncoming = true;
      this.template.isOutgoing = true;
    }

    if (!this.editMode) {
      this.transactionService.postTemplate(this.template).subscribe(resp => {
        this.template = resp;
        this.messageService.add({key: 'tl', severity: 'info', summary: 'Vorlage wurde erzeugt.'});
        this.readTemplates();
      });
    } else {
      this.transactionService.putTemplate(this.template).subscribe(resp => {
        this.template = resp;
        this.messageService.add({key: 'tl', severity: 'info', summary: 'Vorlage wurde gepseichert.'});
        this.readTemplates();
      });
    }
  }

  onRemoveContact() {
    this.template.contactId = 0;
    this.contactString = '';
  }

  onSelectContact() {
    this.ref = this.dialogService.open(ContactSelectComponent, {
      data: {
        role: 'members,customer,supplier'
      },
      header: 'Kontakt/Adresse auswählen',
      width: '75%',
      contentStyle: {'height': '800px', overflow: 'auto', 'resizeable': 'false'},
      baseZIndex: 10000,
    });

    this.ref.onClose.subscribe((c: Contact) => {
      if (c) {
        this.template.contactId = c.id;
        this.contactString = c.orgName != '' ? c.orgName : c.firstName + ' ' + c.lastName;
        if (this.template.text == '')
          this.template.text = this.contactString;
      }
    });
  }

  readContact() {
    if (this.template.contactId > 0) {
      this.contactService.readContact(this.template.contactId).subscribe(resp => {
        if (resp) {
          this.contactString = resp.orgName != '' ? resp.orgName : resp.firstName + ' ' + resp.lastName;
        }
      });
    }
  }

  onEdit(t: TransactionTemplate) {
    this.template = t;
    this.tax1 = t.taxRate1 / 100;
    this.tax2 = t.taxRate2 / 100;

    if (t.isIncoming && t.isOutgoing)
      this.option = 2;
    else if (t.isOutgoing)
      this.option = 1;
    else
      this.option = 0;

    this.editMode = true;
    this.dialogTitle = 'Vorlage bearbeiten';
    this.display = true;
  }

  onDelete(t: TransactionTemplate) {
    this.confirmationService.confirm({
      message: 'Möchten Sie diese Vorlage wirklich löschen?',
      header: 'Vorlage löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      rejectVisible: true,
      key: 'confirm',
      icon: 'pi pi-exclamation-triangle',

      accept: () => {
        this.transactionService.deleteTemplate(t.id).subscribe(() => {
          this.messageService.add({key: 'tl', severity: 'error', summary: 'Vorlage wurde gelöscht.'});
          this.readTemplates();
        });
      },
    });


  }

  onRowSelect($event: any) {
    this.onEdit($event.data);
  }
}
