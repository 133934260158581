<p-confirmDialog key="confirm" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-confirmDialog key="info" header="Info" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<div class="mb-3 flex align-items-center justify-content-between">
  <div>
    <button pButton pRipple label="Zurück" icon="pi pi-arrow-circle-left" (click)="Back()"></button>
    <span class="text-xl font-medium text-900 mx-3">Rechnung</span>
  </div>
  <div>
    <button *ngIf="editMode" pButton pRipple label="Löschen" icon="pi pi-trash" (click)="Delete()"
            class="mx-3"></button>
    <button pButton pRipple label="Speichern" icon="pi pi-save" (click)="Save()"></button>
  </div>
</div>

<form #headerData="ngForm">
  <fieldset [disabled]="invoice.isPayed">
    <div class="row">
      <div class="col-md-6">


        <label>Lieferantennummer</label>

        <p-inputGroup>
          <input type="text" name="supplierID" placeholder="Lieferantennummer" readonly class="form-control"
                 [value]='this.contact.contactID' pInputText/>
          <button type="button" pButton icon="pi pi-search" class="p-button-success"
                  (click)="onSelectSupplier()"></button>
        </p-inputGroup>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <div class="d-md-flex justify-content-between align-items-center">
                <label>Empfängeraddresse</label>

              </div>
              <textarea name="address"
                        placeholder="Name und Anschrift des Empfängers"
                        class="form-control mb-3"
                        [(ngModel)]="invoice.completeAddress"
                        style="height:100px;">
                                  </textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Rechnungsnummer</label>
              <input #invoiceID type="text" name="invoiceID" placeholder="Rechnungsnummer"
                     [(ngModel)]="invoice.invoiceID"
                     class="form-control" autocomplete="off"/>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>Rechnungsdatum</label>
              <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false" [locale]="globals.de"
                          name="invoiceDate" [(ngModel)]="invoiceDate">
              </p-calendar>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Liefertermin</label>
              <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="false" [locale]="globals.de"
                          name="deliveryStart" [(ngModel)]="deliveryDate">
              </p-calendar>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label>UID-Nummer</label>
              <input type="text" name="uid" placeholder="ATU" class="form-control" [(ngModel)]="invoice.vat"
                     autocomplete="off"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Vermerk</label>
              <input type="text" name="note" placeholder="Rechnungsvermerk" class="form-control"
                     [(ngModel)]="invoice.note" autocomplete="off"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label>Rechnungsbetrag netto</label>
          <p-inputNumber mode="currency" [minFractionDigits]="2" [maxFractionDigits]="2" name="netSum"
                         placeholder="Euro"
                         [(ngModel)]='this.invoice.netSum' currency="EUR" locale="de-DE" (ngModelChange)="netChanged()"
                         onclick="this.select();">
          </p-inputNumber>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>MwSt. [%]</label>
          <p-inputNumber mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" name="tax" suffix=" %"
                         [(ngModel)]='this.tax' (ngModelChange)="taxPercChanged()">
          </p-inputNumber>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>MwSt. gesamt</label>
          <p-inputNumber mode="currency" [minFractionDigits]="2" [maxFractionDigits]="2" name="taxSum"
                         placeholder="Euro"
                         [(ngModel)]="this.invoice.taxSum" (ngModelChange)="taxChanged()" currency="EUR" locale="de-DE">
          </p-inputNumber>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Gesamtbetrag inkl. MwSt.</label>
          <p-inputNumber mode="currency" [minFractionDigits]="2" [maxFractionDigits]="2" name="total" placeholder="Euro"
                         [(ngModel)]='this.invoice.total' currency="EUR" locale="de-DE"
                         (ngModelChange)="totalChanged()">
          </p-inputNumber>
        </div>
      </div>


    </div>

    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label>Skonto [%]</label>
          <p-inputNumber mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="1" name="scontoPercent" min="0"
                         suffix=" %" [(ngModel)]='this.invoice.skonto'>
          </p-inputNumber>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" *ngIf="this.invoice.skonto > 0">
          <label>Skontobetrag</label>
          <p-inputNumber mode="currency" [minFractionDigits]="2" [maxFractionDigits]="2" name="sokontoSum"
                         placeholder="Tage" [(ngModel)]='this.invoice.skontoSum' currency="EUR" locale="de-DE">
          </p-inputNumber>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group" *ngIf="this.invoice.skonto > 0">
          <label>Skonto bis</label>
          <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="true" [locale]="globals.de"
                      name="skontoDate" [(ngModel)]="skontoDate">
          </p-calendar>
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label>Zahlungsziel [Tage]</label>
          <input type="number" name="paymentDays" placeholder="Tage" class="form-control"
                 [(ngModel)]='this.invoice.paymentDays'/>
        </div>
      </div>

    </div>

    <div class="row">

      <div class="col-md-3">
        <div class="form-group">
          <label>Zahlbar bis</label>
          <p-calendar class="my-datepicker" dateFormat="dd.mm.yy" [readonlyInput]="true" [locale]="globals.de"
                      name="dueDate" [(ngModel)]="dueDate">
          </p-calendar>
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label>Zahlungsart</label>
          <p-dropdown [options]="paymentMethods" optionLabel="key" optionValue="key" name="paymentMethode"
                      [(ngModel)]="invoice.paymentMethode"></p-dropdown>
        </div>
      </div>


      <div class="col-md-3">
        <div class="form-group">
          <label>IBAN</label>
          <input type="text" name="iban" placeholder="AT__" class="form-control" [(ngModel)]="this.invoice.iban"/>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>BIC</label>
          <input type="text" name="bic" placeholder="BIC" class="form-control" [(ngModel)]="this.invoice.bic"/>
        </div>
      </div>
    </div>
  </fieldset>
</form>


<div *ngIf="editMode">
  <qrcode [qrdata]="payWithCode" [width]="150" [errorCorrectionLevel]="M"></qrcode>
</div>

<hr/>
<app-dms-attachments *ngIf="editMode" #attachments titleNew="Beleg hinzufügen" tableName="Invoice"
                     [PK]="id"></app-dms-attachments>

<div class="row justify-content-center" *ngIf="!editMode">
  <div class="col-md-12">
    <div class="text-center">
      <div class="alert alert-warning alert-dismissible fade show">
        <strong>Info!</strong> Speichern um Beleg hinzufügen zu können.
      </div>
    </div>
  </div>
</div>


<div class="card" *ngIf="pdfSrc != null || imgSrc != null">
  <div class="card-header d-md-flex justify-content-between align-items-center">
    <h6>Vorschau</h6>

  </div>
  <hr *ngIf="isPdf"/>


  <div *ngIf="isPdf">
    <ngx-extended-pdf-viewer [src]="pdfSrc" useBrowserLocale="true" height="80vh">
    </ngx-extended-pdf-viewer>
  </div>


  <div *ngIf="isImage">
    <img [src]="imgSrc" class="dms-img"/>
  </div>
</div>
