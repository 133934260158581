export class CreateUsageData {
  isFlatRate: boolean;
  isPartPayment: boolean;

  isFixedAmount: boolean;
  amount: number;

  from2: number;
  to2: number;
}
