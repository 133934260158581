import { InvoiceItem } from './invoice-item.model';
import { OrganizationSettings } from '../../shared/organization-settings.model';
import { TimeInterval } from 'rxjs';
import { Contact } from '../../shared/contact.model';

export class Invoice {
  id: number;
  invoiceNumber: number;
  invoiceID: string;
  contactId: number;
  invoiceDate: string;
  deliveryDate: string;
  note: string;

  isIncoming: boolean;

  completeAddress: string;
  firstName: string;
  lastName: string;
  orgName: string;
  address1: string;
  address2: string;
  zip: string;
  city: string;
  iban: string;
  bic: string;
  vat: string;

  skonto: number;
  skontoSum: number;
  skontoDays: number;
  dueDateSkonto: string;

  paymentDays: number;
  dueDate: string;
  paymentMethode: string;

  isBooked: boolean;
  isPayed: boolean;
  payDay: string;

  netSum: number;
  taxSum: number;
  total: number;

  isStorno: boolean;
  stornoDate: string;

  documentId: number;

  isTemplate: boolean;

  payedAmount: number;
  bankAccount: string;

  headerText: string;
  footerText: string;

  isPrinted: boolean = false;
  isSent: boolean = false;
  dunningLevel: number = 0;

  taxRate1 : number = 0.0;
  taxRate2 : number = 0.0;

  taxAmount1 : number = 0.0;
  taxAmount2 : number = 0.0;

  internalNote: string = '';

  invoiceDate2: number = -5364662400;
  deliveryDate2: number = -5364662400;
  dueDate2: number = -5364662400;
  dueDateSkonto2: number = -5364662400;
  payDay2: number = -5364662400;
  stornoDate2: number = -5364662400;


    invoiceItems: InvoiceItem[];



    constructor(){
      this.invoiceNumber = 0;
      this.invoiceID = '';

      this.contactId = 0;
      this.invoiceDate = '';
      this.deliveryDate = '';
      this.note = '';

      this.isIncoming = false;

      this.completeAddress = '';
      this.firstName = '';
      this.lastName = '';
      this.orgName = '';
      this.address1 = '';
      this.address2 = '';
      this.zip = '';
      this.city = '';
      this.iban = '';
      this.bic = '';
      this.vat = '';

      this.skonto = 0;
      this.skontoSum = 0;
      this.skontoDays = 0;
      this.dueDateSkonto = null;

      this.paymentDays = 14;
      this.dueDate = null;

      this.isBooked = false;
      this.isPayed = false;
      this.payDay = null;

      this.netSum = 0;
      this.taxSum = 0;
      this.total = 0;

      this.isStorno = false;
      this.stornoDate = null;

      this.documentId = 0;

      this.isTemplate = false;

      this.invoiceItems = [];
      this.paymentMethode = '';

      this.bankAccount = '';
      this.headerText = '';
      this.footerText = '';
    }

  setCustomer(c: Contact) {
    this.contactId = c.id;
    this.firstName = c.firstName;
    this.lastName = c.lastName;
    this.orgName = c.orgName;
    this.address1 = c.address1;
    this.address2 = c.address2;
    this.zip = c.zip;
    this.city = c.city;
    this.iban = c.iban ? c.iban : '';
    this.bic = c.bic ? c.bic : '';
    this.vat = c.vat ? c.vat : '';


    this.completeAddress = (this.orgName ? this.orgName : ((this.firstName && this.lastName) ? this.firstName + ' ' + this.lastName : "")) +
      (c.name2 && c.name2 != "" ? "\r\n" + c.name2 : "") +
      (this.address1 ? "\r\n" + this.address1 : "") +
      (this.address2 ? "\r\n" + this.address2 : "") +
      ((this.zip || this.city) ? "\r\n" : "") + this.zip + (this.zip && this.city ? " " : "") + this.city;
  }

  get ScontoFactor() { return this.skonto }
    get ScontoPercentage(){ return this.skonto === null ? null : this.skonto * 100.0 }
    set ScontoPercentage(value:number){ this.skonto =  value === null ? null : value / 100.0 }
}


export class InvoiceSearchParam {
  isDebit: boolean;
  from: Date;
  to: Date;
  isPayed: boolean;
  isOpen: boolean;
  isBooked: boolean;
  isPrinted: boolean;
  paymentMethode: string;
  constructor() {
    this.isDebit = false;
    //this.from = new Date();
    //this.from.setFullYear(this.from.getFullYear() - 1);
    //this.to = new Date();
    this.isPayed = false;
    this.isOpen = true;
    this.isPrinted = false;
    this.paymentMethode = '';
    this.isBooked = false;
  }
}
