import { Component } from '@angular/core';
import { AuthService } from './auth.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
})
export class AuthComponent {
  isLoading: boolean = false;

  error: string = null; //"Ungültige Anmeldedaten!";

  constructor(private auth: AuthService, private router: Router) {}

  onLogin(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const email = form.value.email;
    const password = form.value.password;

    this.isLoading = true;
    this.auth.login(email, password).subscribe(
      (resData) => {
        this.error = null;
        this.isLoading = false;
        this.router.navigate(['/home']);
      },
      (errorRes) => {
        this.error = 'Ungültige Anmeldedaten!';
        this.isLoading = false;
      }
    );
    form.reset();
  }
}
