export class Gauge {
  id: number;

  serial: string;
  description: string;
  productId: number;
  vendorId: number;

  installDate: string;
  installCount: number;
  lastCount: number;

  calibrationDate: string;

  isStock: boolean;
  isActive: boolean;
  isRemoved: boolean;

  wapID: number;

  installDate2: number = -5364662400;
  calibrationDate2: number = -5364662400;

  constructor() {
    this.id = 0;

    this.serial = '';
    this.description = '';
    this.productId = 0;
    this.vendorId = 0;

    this.installDate = null;
    this.installCount = 0;
    this.lastCount = 0;

    this.calibrationDate = null;

    this.isStock = true;
    this.isActive = false;
    this.isRemoved = false;

    this.wapID = 0;
  }
}

export class GaugeImport extends Gauge {
  lastReading: number = 0;

  constructor() {
    super();
  }
}
