import {CurrencyPipe, DatePipe, DecimalPipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ConfirmationService, MessageService} from 'primeng/api';
import {Globals, KVP_GROUPS} from '../../globals';
import {Invoice, InvoiceSearchParam} from '../../jobs/billing/invoice.model';
import {InvoiceService} from '../../jobs/billing/invoice.service';
import {Account} from '../../settings/accounts/account.model';
import {AccountService} from '../../settings/accounts/account.service';
import {KvpService} from '../../shared/kvp.service';
import {Transaction, TransactionType} from '../transaction.model';
import {TransactionService} from '../transaction.service';
import {MessagingService} from "../../shared/messaging.service";

@Component({
  selector: 'app-open-invoices',
  templateUrl: './open-invoices.component.html',
  styleUrls: ['./open-invoices.component.css']
})
export class OpenInvoicesComponent implements OnInit {
  active1: number = 0;
  isLoading: boolean = false;

  debitSearchParam: InvoiceSearchParam;
  billingSearchParam: InvoiceSearchParam;

  accounts: Account[] = new Array();

  debit: Invoice[];
  billing: Invoice[];

  costCenters: any;

  transaction: Transaction;

  display: boolean = false;

  title: string = '';

  payDate: Date = new Date();
  amount: Number;
  text: string = "";
  accountId: number = 0;
  costCenter: '';

  selectedEntity: Invoice = new Invoice();


  constructor(public invoiceService: InvoiceService,
              private router: Router,
              public globals: Globals,
              public accountService: AccountService,
              private transactionService: TransactionService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              public decinmalPipe: DecimalPipe,
              public datePipe: DatePipe,
              public currencyPipe: CurrencyPipe,
              private kvpService: KvpService,
              private messageingService: MessagingService
  ) {

    this.debitSearchParam = new InvoiceSearchParam();
    this.debitSearchParam.isDebit = true;
    this.debitSearchParam.isOpen = true;
    this.debitSearchParam.isPrinted = true;
    this.debitSearchParam.isBooked = true;

    this.billingSearchParam = new InvoiceSearchParam();
    this.billingSearchParam.isDebit = false;
    this.billingSearchParam.isOpen = true;
    this.billingSearchParam.isPrinted = true;
    this.billingSearchParam.isBooked = true;

    this.debit = new Array();
    this.billing = new Array();
  }

  ngOnInit(): void {
    this.invoiceService.readInvoices(100, 0, this.debitSearchParam).subscribe(resp => {
      this.debit = resp.body.slice();

      this.invoiceService.readInvoices(100, 0, this.billingSearchParam).subscribe(resp => {
        this.billing = resp.body.slice();
        this.isLoading = false;

        if (this.debit.length == 0 && this.billing.length > 0)
          this.active1 = 1;
      });
    });


    this.accountService.getItemsRaw('', 100, 0).subscribe(resp => {
      this.accounts = resp.body.slice();
    });

    this.kvpService.readGroup(KVP_GROUPS.COST_CENTER).subscribe(resp => {
      if (resp.length > 0) {
        this.costCenters = resp.map(({group, ...rest}) => rest);
      } else {
        this.costCenters = new Array();
      }
    });
  }


  onBook(inv: Invoice) {


    this.transaction = new Transaction();
    this.transaction.amount = inv.total;
    this.transaction.text = 'RE ' + inv.invoiceID + ' vom ' + this.datePipe.transform(inv.invoiceDate, "dd.MM.yyyy");
    this.transaction.importText = '';
    this.transaction.invoiceId = inv.id;
    this.transaction.invoiceNumber = inv.invoiceID;
    this.transaction.bic = inv.bic;
    this.transaction.iban = inv.iban;
    this.transaction.transactionType = TransactionType.Normal;
    this.transaction.accountId = this.accountId;
    this.transaction.costCenter = this.costCenter ? this.costCenter : '';

    this.selectedEntity = inv;
    this.amount = inv.total;

    this.text = 'RE ' + inv.invoiceID + ' vom ' + this.datePipe.transform(inv.invoiceDate, "dd.MM.yyyy");

    if (inv.isIncoming) {
      this.title = "Zahlungsausgang";
    } else {
      this.title = "Zahlungseingang";
    }
    this.display = true;
  }

  updateCounter() {
    this.isLoading = true;
    if (this.title == 'Zahlungsausgang') {
      this.invoiceService.readInvoices(100, 0, this.debitSearchParam).subscribe(resp => {
        this.debit = resp.body.slice();
        this.isLoading = false;
        this.messageingService.publish({channel: 'invoice-count', data: this.debit.length + this.billing.length});
      });
    } else {
      this.invoiceService.readInvoices(100, 0, this.billingSearchParam).subscribe(resp => {
        this.billing = resp.body.slice();
        this.isLoading = false;
        this.messageingService.publish({channel: 'invoice-count', data: this.debit.length + this.billing.length});
      });
    }

  }

  onSave() {
    if (this.accountId > 0) {
      this.transaction.accountId = this.accountId;
      this.transactionService.postItem(this.transaction).subscribe(resp => {
        this.display = false;
        this.messageService.add({key: 'tl', severity: 'info', summary: this.title + ' wurde verbucht.'});
        this.updateCounter();
      }, error => {
        this.display = false;
        this.messageService.add({key: 'tl', severity: 'error', summary: this.title + ' konnte nicht verbucht werden.'});
      })
    } else {
      this.messageService.add({key: 'tl', severity: 'error', summary: 'Konto muss ausgewählt werden.'});
    }
  }

  onDelete(inv: Invoice) {
    this.confirmationService.confirm({
      message: 'Wollen Sie diese Rechnung wirklich als bezahlt markieren?',
      header: 'Offenen Posten löschen?',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      rejectVisible: true,
      key: 'confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        inv.isPayed = true;
        this.invoiceService.putInvoice(inv).subscribe(resp => {
          this.messageService.add({key: 'tl', severity: 'info', summary: 'Rechnung wurde als bezahlt markiert.'});
          this.updateCounter();
        });
      }
    });

  }

}
