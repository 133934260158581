<div class="text-center">
  <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
</div>

<div class="row" *ngIf="!isLoading">
  <div class="col-md-12">
    <div class="card">
      <div class="pt-4 px-4 d-flex justify-content-between">
        <h5 class="card-title">{{ title }}</h5>
      </div>

      <hr/>

      <form (ngSubmit)="onSubmit(f)" #f="ngForm" hidden>
        <div class="px-4">
          <div class="input-group mb-3">
            <input type="text"
                   name="search"
                   class="form-control input-lg"
                   placeholder="Suchtext"
                   aria-label="Recipient's username"
                   aria-describedby="basic-addon2"
                   ngModel/>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary"
                      [disabled]="!f.valid"
                      type="submit">
                Suchen
              </button>
            </div>
          </div>
        </div>
      </form>


      <p-table [value]="waterbills" selectionMode="single" [(selection)]="selectedWaterbill" class="m-4"
               (onRowSelect)="onSelected2($event)" styleClass="p-datatable-striped" [scrollable]="true"
               scrollHeight="67vh">
        <ng-template pTemplate="header">
          <tr>
            <th>Nummer</th>
            <th>Adresse</th>
            <th>Ort</th>
            <th>[m³]</th>
            <th>[BE]</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-wb>
          <tr [pSelectableRow]="wb">
            <td class="table-users-id">{{ wb.accessPointID }}</td>
            <td class="table-users-id">{{ wb.address1 }}</td>
            <td class="table-users-id">{{ wb.city }}</td>
            <td class="table-users-id">
              <div *ngIf="!wb.isNeedsUnit">
                {{ wb.usage }}
              </div>
            </td>
            <td class="table-users-id">
              <div *ngIf="wb.isNeedsUnit">
                {{ wb.usage }}
              </div>
            </td>

          </tr>
        </ng-template>
      </p-table>


    </div>
  </div>
</div>
