import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Subject} from 'rxjs';

import {Globals} from '../../globals';
import {Gauge, GaugeImport} from '../../shared/gauge.model';
import {WebReplyMessage} from "../../shared/web-reply-message.model";

@Injectable({providedIn: 'root'})
export class GaugeService {
  gauges = new Subject<Gauge[]>();
  gaugeArray = new Array<Gauge>();

  totalGauges: number = 0;

  selectedGauge: Gauge = null;

  detailActive = '';
  listActive = 'active';

  isStock: boolean = true;
  isActive: boolean = false;

  isCopy = false;

  constructor(
    private globals: Globals,
    private router: Router,
    private http: HttpClient
  ) {
  }

  getGauges(search: string, pageSize: number, page: number, isStock: boolean = true, isActive: boolean = false) {
    const url = this.globals.APP_URL + 'gauges';
    let params = new HttpParams();
    if (!isStock && !isActive) {
      isStock = false;
      isActive = false;
    }
    params = params.append('search', search);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('page', page.toString());
    params = params.append('isStock', isStock.toString());
    params = params.append('isActive', isActive.toString());
    this.http
      .get<Gauge[]>(url, {
        params: params,
        observe: 'response',
      })
      .subscribe((respData) => {
        this.totalGauges = parseInt(respData.headers.get('x-total-count'));
        this.gaugeArray = respData.body.slice();
        this.gauges.next(this.gaugeArray);
      });
  }

  updateGauge(p: Gauge) {
    const url = this.globals.APP_URL + 'gauges';
    this.http.put<Gauge>(url, p).subscribe((respData) => {
      this.selectedGauge = respData;
      const idx = this.gaugeArray.findIndex((x) => x.id === p.id);
      this.gaugeArray[idx] = respData;
      this.router.navigate([
        'masterdata',
        'gauges',
        this.selectedGauge.id,
      ]);
    });
  }

  saveGauge(p: Gauge) {
    const url = this.globals.APP_URL + 'gauges';
    return this.http.put<Gauge>(url, p);
  }

  addGauge(p: Gauge) {
    const url = this.globals.APP_URL + 'gauges';
    this.http.post<Gauge>(url, p).subscribe((respData) => {
      this.selectedGauge = respData;
      this.gaugeArray.push(respData);
      this.router.navigate([
        'masterdata',
        'gauges',
        this.selectedGauge.id,

      ]);
    });
  }

  postGauge(p: Gauge) {
    const url = this.globals.APP_URL + 'gauges';
    return this.http.post<Gauge>(url, p);
  }

  importGauges(g: GaugeImport[]) {
    const url = this.globals.APP_URL + 'gauges/import';
    return this.http.post<Gauge>(url, g);
  }

  readGauge(id: number) {
    const url = this.globals.APP_URL + 'gauges/' + id;
    return this.http.get<Gauge>(url);
  }

  deleteGauge(id: number) {
    const url = this.globals.APP_URL + 'gauges/' + id;
    return this.http.delete(url);
  }

  getGauge(id: number) {
    this.selectedGauge = this.gaugeArray.find((p) => p.id === id);
    this.selectedGauge = Object.assign(
      new Gauge(),
      this.gaugeArray.find((p) => p.id === id)
    );
    return this.selectedGauge;
  }
}
