import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-dms-editor',
  templateUrl: './dms-editor.component.html',
  styleUrls: ['./dms-editor.component.css']
})
export class DmsEditorComponent implements OnInit {
  
  items: MenuItem[] | undefined;
  text: string = "";


  ngOnInit(): void {
    this.items = [
      {
        label: 'Datei',
        icon: 'pi pi-fw pi-file',
        items: [
          {
            label: 'Neu',
            icon: 'pi pi-fw pi-file',
          },
          {
            label: 'Öffnen',
            icon: 'pi pi-fw pi-folder-open',
          },
          {
            label: 'Speichern',
            icon: 'pi pi-fw pi-save',
          },
          {
            label: 'Kopieren',
            icon: 'pi pi-fw pi-copy',
          },
          {
            label: 'Löschen',
            icon: 'pi pi-fw pi-trash'
          },
          {
            separator: true
          },
          {
            label: 'Als PDF speichern',
            icon: 'pi pi-fw pi-file-pdf'
          },
          {
            label: 'PDF Vorschau',
            icon: 'pi pi-fw pi-external-link'
          }
        ]
      },
      {
        label: 'Einfügen',
        icon: 'pi pi-fw pi-file-edit',
        items: [
          {
            label: 'Adresse',
            icon: 'pi pi-fw pi-id-card'
          },
          {
            label: 'Feld',
            icon: 'pi pi-fw pi-ticket'
          },
          
          {
            label: 'Anrede',
            icon: 'pi pi-fw pi-user'
          },
          {
            label: 'Datum',
            icon: 'pi pi-fw pi-calendar'
          },
          {
            separator: true
          },
          {
            label: 'Link',
            icon: 'pi pi-fw pi-link'
          },
          {
            label: 'QR-Code',
            icon: 'pi pi-fw pi-qrcode'
          },
        ]
      },
      {
        label: 'Automatisieren',
        icon: 'pi pi-fw pi-box',
        items: [
          {
            label: 'Serienbrief',
            icon: 'pi pi-fw pi-users'
          },
          {
            label: 'Email versenden',
            icon: 'pi pi-fw pi-at'
          }
          
        ]
      },
      
    ];
  }
}
