import {Component, OnInit} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {FormsModule} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {CalendarModule} from "primeng/calendar";
import {TableModule, TableSelectAllChangeEvent} from "primeng/table";
import {InvoiceService} from "../invoice.service";
import {DirectDebitInvoiceViewModel} from "../direct-debit-invoice-viewmodel";
import {CustomDatePipe} from "../../../shared/custom.datepipe";
import {CurrencyPipe, DatePipe, NgIf} from "@angular/common";
import {Globals} from "../../../globals";
import {DocDbService} from "../../../shared/doc-db.service";
import {OrganizationSettings} from "../../../shared/organization-settings.model";

@Component({
  selector: 'app-sepa-direct-debit',
  standalone: true,
  imports: [
    ButtonModule,
    RippleModule,
    FormsModule,
    InputTextModule,
    CalendarModule,
    TableModule,
    DatePipe,
    CurrencyPipe,
    NgIf,
  ],
  templateUrl: './sepa-direct-debit.component.html',
  styleUrl: './sepa-direct-debit.component.css'
})
export class SepaDirectDebitComponent implements OnInit {

  OrgQuery = {
    "selector": {
      "table": {
        "$eq": "OrganizationSettings"
      },
    },
    "limit": 100,
  };

  searchString: string = '';

  settings: OrganizationSettings = new OrganizationSettings();

  from: Date = new Date();
  to: Date = new Date();

  title: string = "";

  invoices: DirectDebitInvoiceViewModel[] = [];
  selectedInvoices: DirectDebitInvoiceViewModel[] = [];

  constructor(private invoiceService: InvoiceService, private globals: Globals, private datePipe: DatePipe, private docDbService: DocDbService) {
  }

  ngOnInit() {
    this.readInvoices();

    this.docDbService.queryItems<OrganizationSettings>(JSON.stringify(this.OrgQuery)).subscribe(resp => {
      if (resp && resp.length > 0) {
        this.settings = Object.assign(new OrganizationSettings(), resp[0]);
      }
    });
  }

  readInvoices() {
    this.invoices = [];
    this.selectedInvoices = [];
    this.title = "";
    this.invoiceService.getDirectDebitInvoices(this.searchString, this.from, this.to).subscribe(invoices => {
      this.invoices = invoices;
      this.selectedInvoices = this.invoices;
      if (this.invoices.length > 0)
        this.title = "Ausgewählte Rechnungen (" + this.invoices.length + ")";
    });
  }

  onSearch() {
    this.readInvoices();
  }


  onSelectAllChange($event: TableSelectAllChangeEvent) {
    console.log($event);
    this.title = "Ausgewählte Rechnungen (" + this.selectedInvoices.length + ")";
  }


  // method to create and download invoices as csv file
  downloadInvoices() {
    if (this.selectedInvoices && this.selectedInvoices.length > 0) {
      let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
      csvContent += "Auftragsart;Fälligkeitsdatum;Auftragswährung;Betrag;Auftraggeber Name;Auftraggeber IBAN; Auftraggeber BIC;Empfänger (Zahlungspflichtiger)Name;Empfänger (Zahlungspflichtiger) Anschrift;Empfänger (Zahlungspflichtiger) IBAN;Empfänger (Zahlungspflichtiger) BIC;Verwendungszweck;Creditor ID;Mandatsreferenz;Mandatsausstellungsdatum\n";
      this.selectedInvoices.forEach(invoice => {
        csvContent += "EZ" + ";"
          + this.datePipe.transform(new Date(invoice.dueDate * 1000), "dd.MM.YYYY") + ";"
          + "EUR" + ";"
          + (invoice.total / 100).toLocaleString("de-DE", {
            useGrouping: false,
            minimumFractionDigits: 2
          }) + ";"
          + this.settings.name + ";"
          + this.settings.iban + ";"
          + this.settings.bic + ";"
          + invoice.recipient + ";"
          + invoice.address + ";"
          + invoice.iban + ";"
          + invoice.bic + ";"
          + invoice.invoiceID + ";"
          + this.settings.creditorId + ";"
          + invoice.directDebitMandate + ";"
          + this.datePipe.transform(new Date(invoice.mandateIssued * 1000), "dd.MM.YYYY") + "\n";
      });
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "SEPA-Liste_" + this.globals.getCurrentTimestamp() + ".csv");
      document.body.appendChild(link); // Required for FF
      link.click();
    }
  }

}
