import {Injectable, IterableDiffers} from '@angular/core';
import {Router} from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Subject} from 'rxjs';

import {Globals} from 'src/app/globals';
import {Invoice, InvoiceSearchParam} from 'src/app/jobs/billing/invoice.model';
import {Waterbill} from '../../shared/waterbill.model';
import {DatePipe} from '@angular/common';
import {DirectDebitInvoiceViewModel} from "./direct-debit-invoice-viewmodel";

@Injectable()
export class InvoiceService {
  invoices = new Subject<Invoice[]>();
  invoiceArray = new Array<Invoice>();

  totalInvoices: number = 0;

  selectedInvoice: Invoice = null;

  detailActive = '';
  listActive = 'active';

  isCopy = false;

  searchParam: InvoiceSearchParam = new InvoiceSearchParam();
  searchString: string = '';

  constructor(
    private globals: Globals,
    private router: Router,
    private http: HttpClient,
    private datePipe: DatePipe
  ) {
  }


  getNewId() {
    const url = this.globals.APP_URL + 'invoice/newid';
    return this.http.get<{ id: string }>(url);
  }

  getInvoiceCount() {
    const url = this.globals.APP_URL + 'invoice/invoice-count';
    return this.http.get<{ count: number }>(url);
  }


  getNextNumber() {
    const url = this.globals.APP_URL + 'numberrange/invoices/next-number';
    return this.http.get<{ id: string }>(url);
  }

  getInvoices(pageSize: number, page: number) {
    const url = this.globals.APP_URL + 'invoice';
    let params = new HttpParams();
    params = params.append('search', this.searchString);
    params = params.append('pageSize', pageSize.toString());
    params = params.append('page', page.toString());
    params = params.append('isDebit', this.searchParam.isDebit.toString());
    params = params.append('isOpen', this.searchParam.isOpen.toString());
    params = params.append('isPayed', this.searchParam.isPayed.toString());
    params = params.append('isPrinted', this.searchParam.isPrinted.toString());
    params = params.append('isBooked', this.searchParam.isBooked.toString());
    params = params.append('paymentMethod', this.searchParam.paymentMethode);
    if (this.searchParam.from) {
      params = params.append('from', this.datePipe.transform(this.searchParam.from, "dd.MM.yyyy"));
    }
    if (this.searchParam.to) {
      params = params.append('to', this.datePipe.transform(this.searchParam.to, "dd.MM.yyyy"));
    }
    this.http
      .get<Invoice[]>(url, {
        params: params,
        observe: 'response',
      })
      .subscribe((respData) => {
        this.totalInvoices = parseInt(respData.headers.get('x-total-count'));
        this.invoiceArray = respData.body.slice();
        this.invoices.next(this.invoiceArray);
      });
  }

  readInvoices(pageSize: number, page: number, param: InvoiceSearchParam) {
    const url = this.globals.APP_URL + 'invoice';
    let params = new HttpParams();
    params = params.append('search', '');
    params = params.append('pageSize', pageSize.toString());
    params = params.append('page', page.toString());
    params = params.append('isDebit', param.isDebit.toString());
    params = params.append('isOpen', param.isOpen.toString());
    params = params.append('isPayed', param.isPayed.toString());
    params = params.append('isPrinted', param.isPrinted.toString());
    params = params.append('isBooked', param.isBooked.toString());
    params = params.append('paymentMethod', param.paymentMethode);
    if (this.searchParam.from) {
      params = params.append('from', this.datePipe.transform(this.searchParam.from, "dd.MM.yyyy"));
    }
    if (this.searchParam.to) {
      params = params.append('to', this.datePipe.transform(this.searchParam.to, "dd.MM.yyyy"));
    }
    return this.http
      .get<Invoice[]>(url, {
        params: params,
        observe: 'response',
      });
  }

  updateInvoice(i: Invoice, isDebit: boolean) {
    console.log(i);
    const url = this.globals.APP_URL + 'invoice';
    this.http.put<Invoice>(url, i).subscribe((respData) => {
      this.selectedInvoice = respData;
      const idx = this.invoiceArray.findIndex((x) => x.id === i.id);
      this.invoiceArray[idx] = respData;
      if (isDebit) {
        this.router.navigate([
          'jobs',
          'debit',
          this.selectedInvoice.id,
        ]);
      } else {
        this.router.navigate([
          'jobs',
          'invoices',
          this.selectedInvoice.id,
          'edit',
        ]);
      }
    });
  }

  putInvoice(i: Invoice) {
    const url = this.globals.APP_URL + 'invoice';
    return this.http.put<Invoice>(url, i);
  }

  printInvoice(i: Invoice) {
    console.log(i);
    const url = this.globals.APP_URL + 'invoice/' + i.id.toString() + '/print';
    this.http.put<Invoice>(url, i).subscribe((respData) => {
      console.log(respData);
      this.selectedInvoice = respData;
      const idx = this.invoiceArray.findIndex((x) => x.id === i.id);
      this.invoiceArray[idx] = respData;
      this.router.navigate([
        'jobs',
        'invoices',
        this.selectedInvoice.id,
        'view',
      ]);
    });
  }

  createAll() {
    const url = this.globals.APP_URL + 'invoice/auto_create';
    let params = new HttpParams();
    params = params.append('search', this.searchString);
    params = params.append('isDebit', this.searchParam.isDebit.toString());
    params = params.append('isOpen', this.searchParam.isOpen.toString());
    params = params.append('isPayed', this.searchParam.isPayed.toString());
    params = params.append('isPrinted', this.searchParam.isPrinted.toString());
    params = params.append('isBooked', this.searchParam.isBooked.toString());
    params = params.append('paymentMethod', this.searchParam.paymentMethode);
    if (this.searchParam.from) {
      params = params.append('from', this.datePipe.transform(this.searchParam.from, "dd.MM.yyyy"));
    }
    if (this.searchParam.to) {
      params = params.append('to', this.datePipe.transform(this.searchParam.to, "dd.MM.yyyy"));
    }

    return this.http
      .get<{ 'value': '' }>(url, {
        params: params,
      });
  }

  printAll(id: string = '') {
    const url = this.globals.APP_URL + 'invoice/print_all';
    let params = new HttpParams();
    params = params.append('search', this.searchString);
    params = params.append('isDebit', this.searchParam.isDebit.toString());
    params = params.append('isOpen', this.searchParam.isOpen.toString());
    params = params.append('isPayed', this.searchParam.isPayed.toString());
    params = params.append('isPrinted', this.searchParam.isPrinted.toString());
    params = params.append('isBooked', this.searchParam.isBooked.toString());
    params = params.append('paymentMethod', this.searchParam.paymentMethode);
    params = params.append('id', id);
    if (this.searchParam.from) {
      params = params.append('from', this.datePipe.transform(this.searchParam.from, "dd.MM.yyyy"));
    }
    if (this.searchParam.to) {
      params = params.append('to', this.datePipe.transform(this.searchParam.to, "dd.MM.yyyy"));
    }

    return this.http.get(url, {params: params, observe: 'response', responseType: 'blob'});
  }

  stornoInvoice(i: Invoice) {
    console.log(i);
    const url = this.globals.APP_URL + 'invoice/' + i.id.toString() + '/storno';
    this.http.put<Invoice>(url, i).subscribe((respData) => {
      console.log(respData);
      this.selectedInvoice = respData;
      const idx = this.invoiceArray.findIndex((x) => x.id === i.id);
      this.invoiceArray[idx] = respData;
      this.router.navigate([
        'jobs',
        'invoices',
        this.selectedInvoice.id,
        'edit',
      ]);
    });
  }

  addInvoice(i: Invoice, isDebit: boolean) {
    console.log(i);
    const url = this.globals.APP_URL + 'invoice';
    this.http.post<Invoice>(url, i).subscribe((respData) => {
      this.selectedInvoice = respData;
      this.invoiceArray.push(respData);
      if (isDebit) {
        this.router.navigate([
          'jobs',
          'debit',
          this.selectedInvoice.id,
        ]);
      } else {
        this.router.navigate([
          'jobs',
          'invoices',
          this.selectedInvoice.id,
          'edit',
        ]);
      }
    });
  }

  addInvoiceSub(i: Invoice, isDebit: boolean) {
    const url = this.globals.APP_URL + 'invoice';
    return this.http.post<Invoice>(url, i);
  }

  addInvoiceItem(i: Invoice) {
    this.selectedInvoice = i;
    this.invoiceArray.push(i);
    this.router.navigate([
      'jobs',
      i.isIncoming ? 'debit' : 'invoices',
      this.selectedInvoice.id
    ]);
  }

  readInvoice(id: number) {
    const url = this.globals.APP_URL + 'invoice/' + id;
    return this.http.get<Invoice>(url);
  }

  deleteInvoice(id: number) {
    const url = this.globals.APP_URL + 'invoice/' + id;
    return this.http.delete(url);
  }

  getInvoice(id: number): Invoice {
    this.selectedInvoice = Object.assign(
      new Invoice(),
      this.invoiceArray.find((i) => i.id === id)
    );
    return this.selectedInvoice;
  }

  createWaterbills(list: Waterbill[], template: number, autoDetect: boolean, from: Date, to: Date, invoiceDate: Date, run: number) {
    const url = this.globals.APP_URL + 'invoice/waterbills';

    let params = new HttpParams();
    params = params.append('template', template.toString());
    params = params.append('run', run.toString());
    params = params.append('autoDetect', autoDetect.toString());
    params = params.append('from', this.datePipe.transform(from, 'dd.MM.yyyy'));
    params = params.append('to', this.datePipe.transform(to, 'dd.MM.yyyy'));
    params = params.append('date', this.datePipe.transform(invoiceDate, 'dd.MM.yyyy'));


    return this.http.post<number>(url, list, {params: params});
  }

  getDirectDebitInvoices(search: string = '', from: Date = null, to: Date = null) {
    const url = this.globals.APP_URL + 'invoice/direct-debit-invoices';
    let params = new HttpParams();
    params = params.append('search', search);
    if (from) {
      params = params.append('from', Math.round(from.getTime() / 1000).toString());
    }
    if (to) {
      params = params.append('to', Math.round(to.getTime() / 1000).toString());
    }
    return this.http.get<DirectDebitInvoiceViewModel[]>(url, {params: params});
  }


}
