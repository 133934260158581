import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Globals } from '../../../globals';
import { Invoice, InvoiceSearchParam } from '../../billing/invoice.model';
import { InvoiceService } from '../../billing/invoice.service';

@Component({
  selector: 'app-debit-list',
  templateUrl: './debit-list.component.html',
  styleUrls: ['./debit-list.component.css']
})
export class DebitListComponent implements OnInit, OnDestroy {

  subInvoice: Subscription;
  invoices: Invoice[];
  isLoading = false;
  totalInvoices: number = 1;
  selectedInvoice: Invoice;

  fromDate: Date;
  toDate: Date;

  constructor(public invoiceService: InvoiceService,
    private router: Router,
    public globals: Globals,
    public decinmalPipe: DecimalPipe
  ) {

    this.invoiceService.searchParam = new InvoiceSearchParam();
    this.invoiceService.searchParam.isDebit = true;
  }

  ngOnInit(): void {
    this.subInvoice = this.invoiceService.invoices.subscribe((m) => {
      this.invoices = m;
      this.totalInvoices = this.invoiceService.totalInvoices;
      this.isLoading = false;
    });

    this.invoiceService.searchParam.isDebit = true;
    if (!this.invoices?.length) {
      this.invoiceService.searchString = '';
      this.invoiceService.getInvoices(10, 1);
    }
  }

  ngOnDestroy() {
    this.subInvoice.unsubscribe();
  }

  
  onSelected2(e) {
    this.invoiceService.selectedInvoice = this.selectedInvoice;
    this.router.navigate(['jobs', 'debit', this.selectedInvoice.id]);
  }

  onCreateInvoice() {
    this.router.navigate(['jobs', 'debit', 'new']);
  }

  onSubmit(form: NgForm) {
    this.invoiceService.searchString = form.value.search;
    this.invoiceService.getInvoices(10, 1);
    //form.reset();
  }

  paginate(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    this.invoiceService.getInvoices(10, event.page + 1);
  }

  New() {
    this.onCreateInvoice();
  }

}
