import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Globals } from '../../../globals';
import { MyDocument } from '../../../shared/document.model';
import { DmsService } from '../dms.service';

@Component({
  selector: 'app-dms-preview',
  templateUrl: './dms-preview.component.html',
  styleUrls: ['./dms-preview.component.css']
})
export class DmsPreviewComponent implements OnInit {

  isPdf: boolean;
  isImage: boolean;

  zoomScale: string = "page-fit";
  pdfSrc: string;
  imgSrc: any;

  doc: MyDocument;

  constructor(private sanitizer: DomSanitizer,
    private dmsService: DmsService,
    public globals: Globals,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.doc = this.config.data.document;

    if (this.doc != null && this.doc.id > 0) {
      this.onPreview();
    }
  }

  onPreview() {
    let fileLink = this.globals.APP_URL + "document/download/" + this.doc.path;

    this.dmsService.downloadDocument(this.doc.id).subscribe(resp => {
      console.log(resp.body);
      var downloadURL = URL.createObjectURL(resp.body);
      console.log(downloadURL);
      if (this.isPdf) {
        this.pdfSrc = downloadURL;
      }
      else if (this.isImage) {
        this.imgSrc = this.sanitizer.bypassSecurityTrustUrl(downloadURL);

      }
    });
  }

}
